import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AppService, Domain } from '../app.service';
import { OrganizationsService } from './organizations.service';
import { mergeWith, get, isArray } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
        private organizationsService: OrganizationsService,
    ) { }

    public getDomain() {
        let url = `${this.appService.appConfig.tabitBridge}/app-domain`;
        return this.httpClient.get<{ domain: Domain }>(url, this.appService.appHttpOptions);
    }

    public getOrganizationConfig(orgId: any, path: string): Observable<any> {
        return new Observable(observer => {
            let orgSubscription: Subscription;
            let domainSubscription: Subscription = this.appService.domain
            .subscribe(domain => {
                if (!domain) return;
                // if (!get(domain.defaults, path)) return observer.next(null);
                return this.organizationsService.getFullOrganizationById(orgId)
                .subscribe(org => {
                    if (org) observer.next(this.organizationConfigValue(domain.defaults, org, path));
                    if (orgSubscription) orgSubscription.unsubscribe();
                    orgSubscription = this.organizationsService.specificOrgChange(orgId)
                    .subscribe(org => {
                        observer.next(this.organizationConfigValue(domain.defaults || {}, org, path));
                    });
                });
            });

            // Provide a way of canceling and disposing the above subscriptions:
            return function unsubscribe() {
                // console.log(`=== CONFIGURATIONS/SERVICE === Unsubscribing '${path}'`);
                if (domainSubscription) domainSubscription.unsubscribe();
                if (orgSubscription) orgSubscription.unsubscribe();
            };
        });

    }

    private organizationConfigValue(defaults: any, org: any, path: string) {
        let config = {};
        Object.keys(defaults).forEach(key => {
            config[key] = mergeWith({}, defaults[key], org[key], (defaultField, orgField) => {
                if (isArray(defaultField) && isArray(orgField)) return orgField;
            });
        });
        return get(config, path, null);
    }

}
