<ng-container>
    <div class="icon" [style.backgroundImage]="icon | safeStyle"></div>
    <div class="details-container flex-grow-1">
        <ng-container *ngIf="points">
            <h3>{{'accumulated' | translate}} {{points}} {{pointsNickname}}</h3>
            <h4 class="points">{{'immediate_realization' | translate}}!</h4>
        </ng-container>
        <ng-container *ngIf="loyaltyService.checkPrepaidFlow(prePaidPoints)">
            <h3>{{'you_have' | translate}} {{prePaidPoints}} {{currencySign}}</h3>
            <h4 class="points">{{getPrePaidCaption() | translate}}!</h4>
        </ng-container>
        <ng-container *ngIf="benefit && (!points && !loyaltyService.checkPrepaidFlow(prePaidPoints))">
            <h4 class="_description">{{description}}</h4>
        </ng-container>
    </div>
    <div *ngIf="isWhiteLabel">
        <mat-icon>{{appService.nextIcon}}</mat-icon>
    </div>
</ng-container>
