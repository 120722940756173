import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { each, some, compact } from 'lodash-es';

import { LocationLabeled } from './location.service';
import { AppService } from '../app.service';
import { GoogleMapsLoaderService } from './google-maps-loader.service';

declare const google: any;

export interface Address {
    _id: string;
    addressType: 'apartment' | 'office' | 'house';
    city: string;
    street: string;
    house: number;
    entrance: string;
    floor: string;
    apartment: string;
    formatted_address?: string;
    location: {
        lat: number;
        lng: number;
    },
    notes?: string;
    default: boolean;
}

export class AddressesSearch {

    private httpClient: HttpClient;
    private googleKey: string;

    private addressTypes: string[];
    private localeId: string;
    private translationLanguage: string;
    private bridgeUrl: string;

    private googleSessionToken = new Date().getTime();

    private mapGoogleAddressResults: UnaryFunction<Observable<any>, Observable<Address[]>>[] = [

        map((googleAddressResult: { results?: any[] }) => {
// console.log('=== ADDRESSES/SERVICE === search for placeId, results:', googleAddressResult.results);
            if (googleAddressResult?.results?.length) return googleAddressResult.results;
            return [];
        }),

        map((googleAddressSuggestions: any[]) => googleAddressSuggestions.filter((suggestion: any) => {
            return (
                suggestion.geometry && suggestion.types && suggestion.types.length &&
                some(this.addressTypes, type => suggestion.types.indexOf(type) >= 0) &&
                suggestion.address_components && suggestion.address_components.length
            );
        })),

        map((googleAddressSuggestions: any[]) => {
            return googleAddressSuggestions.map(suggestion => {
                let city: string, street: string, house: number;
                suggestion.address_components.forEach(component => {
                    if (!component.types || !component.types.length) return;
                    if (!house && component.types.indexOf('street_number') >= 0) house = parseInt(component.long_name);
                    if (!street && component.types.indexOf('route') >= 0) street = this.fixSpecialChars(component.long_name);
                    if (!city && component.types.indexOf('locality') >= 0) city = this.fixSpecialChars(component.long_name);
                });

                return {
                    formatted_address: this.fixSpecialChars(suggestion.formatted_address),
                    city,
                    street,
                    house,
                    location: suggestion.geometry.location
                };
            });
        }),

    ];

    constructor(
        addressTypes: string[],
        deps: { httpClient: HttpClient, googleKey: string, localeId: string, bridgeUrl: string, translationLanguage: string },
        private googleMapsLoaderService: GoogleMapsLoaderService
    ) {
        this.addressTypes = addressTypes;
        this.httpClient = deps.httpClient;
        this.googleKey = deps.googleKey;
        this.localeId = deps.localeId;
        this.translationLanguage = deps.translationLanguage;
        this.bridgeUrl = deps.bridgeUrl;

        this.googleMapsLoaderService.loadScript();
    }

    private fixSpecialChars(str) {
        if (!str) return "";
        let regexSpecialChars = /[\uD83D\uFFFD\uFE0F\u203C\u3010\u3011\u300A\u166D\u200C\u202A\u202C\u2049\u20E3\u300B\u300C\u3030\u065F\u0099\u0F3A\u0F3B\uF610\uFFFC]/g;
        return String(str).replace(regexSpecialChars, "");
    }

    getGoogleAddressPredictions(addressFreeText: string, location?: LocationLabeled['location']): Observable<{ formatted_address: string, place_id: string, types: [any] }[]> {
        return new Observable(observer => {
            this.googleMapsLoaderService.loadScript().then(() => {
                const addressesAutoComplete = new google.maps.places.AutocompleteService();
                const googleSessionToken = new google.maps.places.AutocompleteSessionToken();
                const country = this.localeId.toLowerCase() === 'he-il' ? 'il' : ['en-us', 'en-au'].includes(this.localeId.toLowerCase()) ? 'us' : '';
                addressesAutoComplete.getPlacePredictions({
                    sessionToken: googleSessionToken,
                    input: addressFreeText,
                    componentRestrictions: { country },
                }, (predictions, status) => {
                    if (
                        status !== google.maps.places.PlacesServiceStatus.OK &&
                        status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
                    ) {
                        return observer.error(`Error from google address predictions: ${status}`);
                    }

// Filtering the results by the types that we want to use only.
                    // Note: we tried to use the 'types' argument as part of the getPlacePredictions request - however it is only possible to pass one type, which doesn't cover what we need.
                    // See https://developers.google.com/places/supported_types#table3
                    predictions = (predictions || []).filter((suggestion: any) => suggestion.types && suggestion.types.length && some(this.addressTypes, type => suggestion.types.indexOf(type) >= 0));
                    observer.next(predictions || []);
                    observer.complete();
                });
            }).catch(err => {
                console.error('=== ADDRESSES/SERVICE === error loading maps api:', err.message);
                observer.error(`Error from google address predictions: ${err.message}`);
            });

        }).pipe(
            map((predictions: any[]) => compact(predictions.map(prediction => {
                if (!prediction.description || !prediction.place_id) return;
                return {
                    formatted_address: prediction.description,
                    place_id: prediction.place_id,
                    types: prediction.types
                };
            }))),
        );

    }

    getAddressForLocation(location: LocationLabeled['location']): Observable<any> {
        let params = {
            latlng: `${location.lat},${location.lng}`,
            sensor: 'false',
            language: this.localeId.toLowerCase() === 'he-il' ? 'iw' : 'en',
            key: this.googleKey
        }
        return this.httpClient.get('https://maps.googleapis.com/maps/api/geocode/json', { params }).pipe(
            this.mapGoogleAddressResults[0],
            this.mapGoogleAddressResults[1],
            this.mapGoogleAddressResults[2],
        );
    }

    getLocationForPlaceId(placeId: string, googleAddressDescription?: string): Observable<any> {
        let params = {
            place_id: placeId,
            sensor: 'false',
            language: this.localeId.toLowerCase() === 'he-il' ? 'iw' : 'en',
            key: this.googleKey
        }

        let guessHouseNumber: number;
        if (googleAddressDescription) {
            let guessHouseMatch = googleAddressDescription.match(/(\d+)/);
            if (guessHouseMatch && guessHouseMatch[0]) guessHouseNumber = parseInt(guessHouseMatch[0]);
        }

        return new Observable(observer => {
            this.httpClient.get('https://maps.googleapis.com/maps/api/geocode/json', { params }).pipe(
                this.mapGoogleAddressResults[0],
                this.mapGoogleAddressResults[1],
                this.mapGoogleAddressResults[2],
            ).subscribe((addresses: Address[]) => {
                if (!addresses || addresses.length === 0) {
                    observer.error(`Could not find address by its place id: ${placeId}`);
                } else {
                    const transformedAddresses = addresses.map((address: Address) => {
                        if (!address.house && guessHouseNumber > 0) {
                            return {
                                ...address,
                                house: guessHouseNumber,
                                formatted_address: this.fixSpecialChars(googleAddressDescription),
                            };
                        }
                        return address;
                    });

                    observer.next(transformedAddresses);
                    observer.complete();
                }
            }, err => observer.error(err));
        });
    }

    getTabitOrderAddressPredictions(addressFreeText: string, location?: LocationLabeled['location']): Observable<{ formatted_address: string, place_id: string, types: [any] }[]> {
        const args = {
            input: addressFreeText,
            sessionToken: this.googleSessionToken,
            types: ['address'],
            gMapLanguage: this.translationLanguage.toLowerCase() === 'he-il' ? 'iw' : 'en',
            currentPosition: {}
        }

        try {
            navigator.geolocation.getCurrentPosition(location => {
                var lat = location.coords.latitude;
                var lng = location.coords.longitude;
                if (lat && lng) {
                    args.currentPosition = new google.maps.LatLng(lat, lng);
                }
            }, function () { });
        } catch (e) {};

        if (args.currentPosition) {
            args['location'] = args.currentPosition;
            args['radius'] = 100;
        }

        let payload = `input=${args.input}&location=${args.currentPosition}&language=${args.gMapLanguage}&types=address&key=${this.googleKey}`;
        if (this.localeId !== 'he-IL') payload += `&sessiontoken=${args.sessionToken}`;
        else payload += '&components=country:il';

        return new Observable(observer => {
            this.httpClient.get(`${this.bridgeUrl}/maps-google-apis/maps/api/place/autocomplete/json?${payload}`)
            .subscribe((response: any) => {
                if (
                    response?.status !== google.maps.places.PlacesServiceStatus.OK &&
                    response?.status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
                ) {
                    return observer.error(`Error from google address predictions: ${status}`);
                }

                let predictions = [];
                each(response.predictions, prediction => {
//if (prediction.types.indexOf("street_address") != -1) {
                    predictions.push({
                        description: this.fixSpecialChars(prediction.description),
                        label: prediction.structured_formatting.main_text,
                        place_id: prediction.place_id,
                        isPlace: true,
                    });
//}
                });
                observer.next(predictions || []);
                observer.complete();
            }, err => observer.error(err));
        });
    }

    getLocationForTabitOrderPlaceId(placeId: string, googleAddressDescription?: string): Observable<any> {
        const args = {
            place_id: placeId,
            sensor: 'false',
            language: this.translationLanguage.toLowerCase() === 'he-il' ? 'iw' : 'en',
            key: this.googleKey
        }

        let sURL, payload;
        if (this.translationLanguage.toLowerCase() === 'he-il') {
            payload = `place_id=${args.place_id}&language=${args.language}&fields=geometry,formatted_address,address_component&key=${args.key}`;
            sURL = `${this.bridgeUrl}/maps-google-apis/maps/api/geocode/json?${payload}`;
        } else {
            payload = `place_id=${args.place_id}&language=${args.language}&fields=geometry,formatted_address,address_component&key=${args.key}&sessiontoken=${args.key}`;
            sURL = `${this.bridgeUrl}/maps-google-apis/maps/api/place/details/json?${payload}`;
        };

        return new Observable(observer => {
            this.httpClient.get(sURL)
            .subscribe((response: any) => {
                if (
                    response?.status !== google.maps.places.PlacesServiceStatus.OK &&
                    (response?.status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS ||
                    (response?.status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS && response?.error_message))
                ) {
                    return observer.error(`Error from google address predictions: ${response?.status}`);
                }
// Assign the results
                const googleResponse = response.result || response.results[0];
                const address = {
                    house: '',
                    street: '',
                    locality: '',
                    postalCode: '',
                    city: '',
                    state: '',
                    location: {},
                    formatted_address: '',
                    partial: false
                };

                each(googleResponse.address_components, component => {
                    const val = this.fixSpecialChars(component.long_name);

                    switch (component.types[0]) {
                        case 'street_number':
                            address.house = val;
                            break;
                        case 'route':
                            address.street= val;
                            break;
                        case 'locality':
                            address.locality = val;
                            address.city = val;
                            break;
                        case 'postal_code':
                            address.postalCode = val;
                            break;
                        case 'administrative_area_level_1':
                            address.state = component.short_name;
                            break;
                    }
                });

                address.location = {
                    lat: googleResponse.geometry.location.lat,
                    lng: googleResponse.geometry.location.lng
                }

                if (!address.house) {
                    const matches = googleAddressDescription.match(/(\d+)/);
                    if (address.street && matches && matches[0]) {
                        address.house = matches[0];
                        address.formatted_address = `${address.street} ${address.house}, ${address.locality}`;
                    } else {
                        address.partial = true;
                    }
                } else address.formatted_address = this.fixSpecialChars(googleResponse.formatted_address)

                observer.next(address || {});
                observer.complete();
            }, err => observer.error(err));
        });
    }

}

@Injectable({
    providedIn: 'root'
})
export class AddressesService {

    private addressesSubject: BehaviorSubject<Address[] | []> = new BehaviorSubject([]);

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public loading = this.loadingSubject.asObservable();

    public addresses = this.addressesSubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private appService: AppService,
        private googleMapsLoaderService: GoogleMapsLoaderService,
    ) {
        this.googleMapsLoaderService.loadScript();
    }

    private uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).replace(/-/g, '');
    }

// This is private because you always have to subscribe:
    private getAddresses() {
        return this.addressesSubject.getValue().slice(); // This because I'm nervous if getValue returns ref or new Array. Must be new
    }

    private makeAddresses(addresses: Address[]): Address[] {
        let defaultAddress = addresses.find(address => address.default);
        return addresses.map((address, i) => {
            let readyAddress = {
                ...address,
                default: !!address.default,
                _id: address._id || this.uuidv4(),
                formatted_address: address.formatted_address || this.getFormattedAddress(address),
            };
            if (i === 0 && !defaultAddress) return ({ ...readyAddress, default: true });
            return readyAddress;
        })
    }

    private getFormattedAddress(address: any): string {
        if (!address) return '';
        let formattedAddress = address.street == 'Unnamed Road' ? address.city : (address.city + ' ' + address.street + ' ' + address.house);
        return formattedAddress;
    }

    private setAddresses(addresses: Address[]) {
        this.addressesSubject.next(addresses);
    }

    private saveAddresses(addresses: Address[]): Observable<any> {
        return new Observable(observer => {
            if (!localStorage) return observer.error('Error saving addresses, no localStorage');

            try {
                localStorage.setItem('cachedAddresses', JSON.stringify(addresses));
            } catch (err) {
                return observer.error(err);
            }

            new Promise(r => setTimeout(r, 0)).then(() => {  // To simulate server loading
                observer.next(addresses);
                observer.complete();
            });
        });
    }

    loadAddresses(): Observable<any> {
        return new Observable(observer => {
            let addresses;
            try {
                addresses = JSON.parse((localStorage.getItem('cachedAddresses')) || '[]')
            } catch (err) {
                observer.error(new Error('Bad addresses at local cache: ' + err.message));
            }

            this.stopLoading();
            this.setAddresses(this.makeAddresses(addresses));

            observer.next();
        });
    }

    deleteAddress(address: Address): Address[] {
        let addresses = this.getAddresses();
        let removed = addresses.find(existingAddress => existingAddress._id === address._id);
        if (!removed) return;
        addresses = addresses.filter(existingAddress => existingAddress._id !== address._id);
        if (removed.default && addresses.length) addresses[0] = { ...addresses[0], default: true };
        this.saveAddresses(addresses).subscribe(() => {
            this.addressesSubject.next(addresses);
        }, err => {
            console.error('Error deleting address:', err);
        })
        return addresses;
    }

    saveAddress(address: Address, enrichStoredAddress?: boolean): Observable<Address> {
        let addresses = this.getAddresses();
        let index = addresses.findIndex(o => {
            return o.formatted_address == address.formatted_address;
        })

        let _id = this.uuidv4();
        if (index === -1) {
            addresses.push({ ...address, _id });
        } else {
            _id = addresses[index]._id;
        }

// enrich and store additional address details only in localStorage
        if (index > -1 && enrichStoredAddress) {
            addresses[index] = address;
        }

        addresses = this.makeAddresses(addresses);
// console.log('=== ADDRESSES/SERVICE === addresses to save now:', addresses);
        return this.saveAddresses(addresses).pipe(
            map((addresses) => {
                this.addressesSubject.next(addresses);
                return addresses.find((newAddress: Address) => newAddress._id === _id);
            })
        );
    }

    setDefault(address: Address): Address[] {
        let addresses = this.getAddresses();
        let newDefaultAddress = addresses.find(existingAddress => existingAddress._id === address._id);
// console.log('=== ADDRESSES/SERVICE === Address found to make it default:', newDefaultAddress);
        if (!newDefaultAddress) return;
        addresses = addresses.map(existingAddress => {
            if (existingAddress === newDefaultAddress) return { ...existingAddress, default: true };
            else return { ...existingAddress, default: false };
        });
        this.saveAddresses(addresses).subscribe(() => {
            this.addressesSubject.next(addresses);
        }, err => {
            console.error('Error saving default address:', err);
        });
        return addresses;
    }

    stopLoading(): void {
        this.loadingSubject.next(false);
    }

    newAddressesSearch(addressTypes: string[]): AddressesSearch {
        return new AddressesSearch(addressTypes, {
            httpClient: this.httpClient,
            googleKey: this.appService.appConfig.googleKey,
            localeId: this.appService.appConfig.locale,
            bridgeUrl: this.appService.appConfig.tabitBridge,
            translationLanguage: this.appService.localeId,
        }, this.googleMapsLoaderService);
    }

    getAddressFromBridgeByName(query: string): Observable<any> {
        let url = `${this.appService.appConfig.tabitBridge}/configuration/addresses-query`;
        return this.httpClient.post(url, { query }, this.appService.appHttpOptions).pipe(map((addresses: any) => {
// console.log('*** AddressessService ***:', addresses);
            return addresses.map(address => {
                return {
                    ...address,
                    formatted_address: this.assignHouseNumberInAddress(address.formatted_address, query),
                    house: this.setAddressHouse(this.fixSpecialChars(address.house), query),
                    pattern: this.fixSpecialChars(address.pattern)
                }
            });
        }));
    }

    getAddressFromBridgeById(addressId: string, selectedHouseNumber?: number): Observable<any> {
        let url = `${this.appService.appConfig.tabitBridge}/configuration/addresses/${addressId}`;
        return this.httpClient.get(url, this.appService.appHttpOptions).pipe(map((address: Address) => {
console.debug('*** AddressessService - getAddressFromBridgeById ***:', address);
            return {
                ...address,
                house: this.setAddressHouse(this.fixSpecialChars(address.house), selectedHouseNumber),
                formatted_address: this.fixSpecialChars(address.formatted_address)
            }
        }));
    }

    private fixSpecialChars(str) {
        if (!str) return '';
        return str.replaceAll(/[^a-zA-Z0-9]/g, '');
    }

    private setAddressHouse(house, query) {
        if (!house && !query) return '';
// Validate that query is a number
        if (!/\d/g.test(query)) return house;
        let houseNumber = query.match(/\d/g);

        return parseInt(houseNumber.join(''));
    }

    private assignHouseNumberInAddress(formatted_address, query) {
        if (!formatted_address) return '';
        if (!query) return formatted_address;
        let houseNumber = query.match(/\d/g);
        if (!houseNumber) return formatted_address;
        houseNumber = houseNumber.join("");
        let addressWithNumber;
        if (formatted_address.match(/,/g)) {
            addressWithNumber = formatted_address.replace(/,/g, ` ${houseNumber},`);
        } else {
            addressWithNumber = `${formatted_address} ${houseNumber}`;
        }
        return addressWithNumber || formatted_address;
    }

}
