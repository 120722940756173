<div class="footer-content">
    <div class="footer-middle-section" [class.wl]="appService.skin">
        <div class="footer-topic articles" *ngIf="showArticles">
            <div class="footer-topic-title" accessible role="heading" aria-level="6">{{'articles' | tmpTranslate}}</div>
            <!-- <a class="footer-topic-link" (click)="open([['/article/אמריקן-אקספרס-מנה-עלינו'], {}])">
                מנה עלינו מהמשלוחים הכי טובים בעיר | מיוחד ללקוחות אמריקן אקספרס
            </a> -->
            <a class="footer-topic-link" (click)="open([['/article/גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית'], {}])" accessible>
                הכירו את המסעדות שמגיעות אליכם עד הבית
            </a>
            <a class="footer-topic-link" (click)="open([['/article/לפעמים-לא-חייבים-לטוס-רחוק-בכדי-להרגיש-חול'], {}])" accessible>
                אם אי אפשר לטוס לחו"ל, נביא את החו"ל אלינו
            </a>
            <a class="footer-topic-link" (click)="open([['/article/קבלו-את-המסעדות-שהכי-כיף-לשבת-בהן-בחוץ-בחודשים-החמים'], {}])" accessible>
                המסעדות שהכי כיף לשבת בהן בחוץ
            </a>
            <a class="footer-topic-link" (click)="open([['/article/המסעדות-שהכי-התגעגענו-אליהן-וסוף-סוף-נפתחות-מחדש'], {}])" accessible>
                המסעדות שהכי התגעגענו אליהן
            </a>
            <a class="footer-topic-link" (click)="open([['/article/יצירתיות-וטכנולוגיה-הם-שם-המשחק'], {}])" accessible>
                יצירתיות וטכנולוגיה הם שם המשחק
            </a>
            <a class="footer-topic-link" (click)="open([['/article/שלוש-ארוחות-ביום'], {}])" accessible>
                שלוש ארוחות ביום
            </a>
            <a class="footer-topic-link" (click)="open([['/article/משלוחים-לימי-קורונה'], {}])" accessible>
                משלוחים לימי קורונה
            </a>
            <a class="footer-topic-link" (click)="open([['/article/המקומות-הכי-טעימים-בשוק-הכרמל'], {}])" accessible>
                המקומות הכי טעימים בשוק הכרמל
            </a>
            <a class="footer-topic-link" (click)="open([['/article/איפה-לחגוג-את-הוולנטיין'], {}])" accessible>
                איפה לחגוג את הוולנטיין?
            </a>
            <a class="footer-topic-link" (click)="open([['/article/מסעדות-חורף'], {}])" accessible>
                המסעדות שהכי כיף לאכול בהן בחורף
            </a>
        </div>

        <div class="footer-topic other_links">
            <div class="footer-topic-title" accessible role="heading" aria-level="6">{{ 'FOOTER.other_links' | translate }}</div>
            <ng-container *ngFor="let area of mapAreas">
                <a class="footer-topic-link" (click)="open(null, null, area)" accessible>{{ 'FOOTER.area_link_prefix' | translate }}{{ 'areas.' + area.key | translate }}</a>
            </ng-container>
        </div>

        <div *ngIf="appService.skin" class="footer-topic">
            <div class="footer-topic-links-container">
                <div class="links-container">
                    <a class="footer-topic-link" (click)="open('privacy', true)">{{ 'FOOTER.privacy_policy' | translate }}</a>
                    <a class="footer-topic-link" (click)="open('terms', true)">{{ 'terms_of_service' | translate }}</a>
                </div>
            </div>
        </div>

        <div *ngIf="!appService.skin" class="footer-topic contact">
            <div class="footer-topic-title" accessible role="heading" aria-level="6">{{ 'FOOTER.contact' | translate }}</div>
            <a class="footer-topic-link" (click)="open('https://tabit.cloud/', true)" accessible>{{ 'FOOTER.to_tabit_site' | translate }}</a>
            <a class="footer-topic-link" (click)="open('https://www.restaurants.tabitisrael.co.il/jobs', true)" accessible>{{ 'FOOTER.career' | translate }}</a>
            <!-- Removed (but just comment out in case they want it back..) -->
            <!-- <a class="footer-topic-link" (click)="open('mailto:support-il-app@tabit.cloud', true)">{{ 'FOOTER.contact_us' | translate }}</a> -->
            <a class="footer-topic-link" (click)="open('privacy', true)" accessible>{{ 'FOOTER.privacy_policy' | translate }}</a>
            <a class="footer-topic-link" (click)="open('terms', true)" accessible>{{ 'terms_of_service' | translate }}</a>
            <a class="footer-topic-link" (click)="open('accessabilityPolicy', true)" accessible [attr.aria-label]="'ACCESS.accessibility_statement' | translate ">{{ 'ACCESS.ACCESSIBILITY_STATEMENT' | translate }}</a>
        </div>

        <div class="footer-topic footer-social-buttons">
            <div class="footer-topic-title" accessible role="heading" aria-level="6">{{ 'FOOTER.join_us' | translate }}</div>
            <div class="social-container">
                <button *ngIf="facebookURL" role="link" aria-label="facebook" mat-mini-fab class="footer-topic-link social-button" (click)="open(facebookURL, true)">
                    <mat-icon svgIcon="facebook"></mat-icon>
                </button>

                <button *ngIf="instagramURL" role="link" aria-label="instagram" mat-mini-fab class="footer-topic-link social-button" (click)="open(instagramURL, true)">
                    <mat-icon svgIcon="instagram"></mat-icon>
                </button>

                <button *ngIf="linkedinURL" role="link" aria-label="linkedin" mat-mini-fab class="footer-topic-link social-button" (click)="open(linkedinURL, true)">
                    <mat-icon svgIcon="linkedin"></mat-icon>
                </button>

                <!--
                <a href="" target="_blank">
                    <button mat-mini-fab class="footer-topic-link social-button">
                        <mat-icon svgIcon="twitter"></mat-icon>
                    </button>
                </a>
                -->
            </div>
        </div>

        <img class="footer-image" (click)="appService.redirect(['/'])" [class.wl]="appService.skin" [src]="getMediaPath()"/>

    </div>

</div>

<div class="footer-bottom-section">
    <div class="version-container">
        <span class="version">
            v{{appService.appConfig.version}}
        </span>
        <span class="separator"> | </span>
        <span class="trademark">
            Copyright © 2019 Tabit Technologies Ltd.
        </span>
    </div>
    <div class="grecaptcha-text">
        <div>This site is protected by reCAPTCHA and the Google <a (click)="open('https://policies.google.com/privacy', true)" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a (click)="open('https://policies.google.com/terms', true)" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</div>
    </div>
</div>
<div class="footer-spacer"></div>