import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { AppService } from '../../app.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { DialogsService } from '../../_core/dialogs.service';

@Component({
    selector: 'site-meta-data',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './site-meta-data.component.html',
    styleUrls: ['./site-meta-data.component.scss']
})
export class SiteMetaDataComponent implements OnInit, OnDestroy {

    @Input() orgId: any;
    @Input() service: string;


    public showDistance: Boolean = false;
    public org: any;
    public unit: string = this.appService.getUnitByLocale();

    private locationSubscription: Subscription;

    constructor(
        public appService: AppService,
        public locationService: LocationService,
        public dialogsService: DialogsService,
        public organizationsService: OrganizationsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.locationSubscription = combineLatest([
            this.locationService.actualLocationAvailable,
            this.locationService.location,
        ]).subscribe(([actualLocationAvailable, locationLabeled]: [boolean, LocationLabeled]) => {
            this.showDistance = actualLocationAvailable && locationLabeled.actual;

            this.organizationsService.getFullOrganization(this.orgId).subscribe(org => {
                this.org = org;

                this.changeDetectorRef.detectChanges();
            });

            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
    }

    callPhone() {
        if (window['cordova']) {
            window.open('tel:' + (this.org.phone || this.org.reservation.methodValue), '_system');
        } else {
            window.location.href = 'tel:' + (this.org.phone || this.org.reservation.methodValue);
        }
    }
}
