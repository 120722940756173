import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { isUndefined, each } from 'lodash-es';
import { take } from 'rxjs/operators';

export interface SpecialMessage {
    type: 'info' | 'promo' | 'alert' | 'warning' | 'success' | 'plain';
    message: string;
    remove?: boolean;
    translations?: any;
}

@Injectable({
  providedIn: 'root'
})
export class SpecialMessagesService {

    private showMessages: BehaviorSubject<SpecialMessage[]> = new BehaviorSubject<SpecialMessage[]>([]);
    public showMessages$ = this.showMessages.asObservable();

    public showImages: boolean = false;

    constructor() { }

    showSpecialMessages(messages: SpecialMessage[], timer: number = 12000) {
        this.showMessages.next(this.showMessages.getValue().concat(messages));
        if (timer) {
            messages.forEach((message: SpecialMessage) => {
                setTimeout(() => {
                    message.remove = true;
                    setTimeout(() => {
                        this.removeMessage(message);
                    }, 800);
                }, timer);
            });
        }
    }

    removeMessages(messages: SpecialMessage[]) {
        return messages.forEach((message: SpecialMessage) => {
            message.remove = true;
        });
    }

    removeAllMessages() {
        this.showMessages$
        .pipe(take(1))
        .subscribe(messages => {
            messages.forEach((message: SpecialMessage) => {
                message.remove = true;
            });
            // clear privately-stored messages
            this.showMessages.next([]);
        })
    }

    removeMessage(message: SpecialMessage) {
        if (!this.showMessages.getValue()?.length) return;
        const index = this.showMessages.getValue().indexOf(message);
        if (index > -1) this.showMessages.getValue().splice(index, 1);
    }

    getPrepedMessagesForTO(placement, storage, scope?, session?, isFutureOrder?): {imagesMessages, prepedMessages} {
        const prepedMessages = [];
        const imagesMessages = [];

        each(storage.messages, (message) => {
            if (message.$wasDisplayed) return;
            if (session && message.session === session) return;
            if (!isUndefined(isFutureOrder) && message.orderTime) {
                let prop = storage.forceDelay ? 'future' : 'sameDay'
                if (prop != message.orderTime) return;
            }
            if (message.active && message.placement === placement) {
                if (!scope || message.scope === scope || message.scope === 'general') {
                    if (session) message.session = session;
                    // let type: string = 'success';
                    // switch (message.type) {
                    //     case 'alert':
                    //         type = 'error';
                    //         break;
                    //     case 'warning':
                    //         type = 'warning';
                    //         break;
                    //     case 'info':
                    //         type = 'info';
                    //         break;
                    // }
                    message.$wasDisplayed = true;
                    message.displayType === 'image' ? imagesMessages.push(message) : prepedMessages.push(message);
                }
            }
        });

        return {
            prepedMessages: prepedMessages,
            imagesMessages: imagesMessages
        };
    }

    popSpecialMessage(message: SpecialMessage, timer: number) {
        if (!message) return;
        let specialMessage = [];
        specialMessage.push(message);
        this.showSpecialMessages(specialMessage, timer);
    }

}
