
<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 scroll-y">
        <div #ticketRipple matRippleDisabled="true">
            <tabitpay-ticket-seated [$storage]="$storage"></tabitpay-ticket-seated>
        </div>
    </div>

    <!-- Payment & add-items buttons -->
    <!-- If order restricted, don't show payment buttons -->
    <ng-container *ngIf="!$storage.orderRestricted">
        <div class="p-3 pb-4" *ngIf="canAddItems; else disableAddItems">
            <!-- Only if show prices if off -->
            <div *ngIf="!$storage.showPrices" class="mat-single-button lg rounded shadow">
                <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="addItemsToOrder()">
                    {{'add-items' | translate}}
                </button>
            </div>
            <div *ngIf="$storage.showPrices" class="mat-split-button lg rounded">
                <button
                    mat-raised-button
                    color="primary"
                    class="add-items-button lg rounded"
                    type="button"
                    (click)="addItemsToOrder()"
                >
                    {{'add-items' | translate}}
                </button>
                <button
                    *ngIf="(tabitPayService.enablePay | async)"
                    mat-raised-button color="accent"
                    class="payment-button lg rounded"
                    type="button"
                    (click)="_continue()"
                >
                    {{'goto-payment' | translate}}
                </button>
                <button
                    *ngIf="!(tabitPayService.enablePay | async)"
                    mat-raised-button color="accent"
                    class="payment-button lg rounded"
                    type="button"
                    (click)="_continue('full_tip')"
                >
                    {{'goto-tip' | translate}}
                </button>
            </div>
        </div>

        <!-- Payment buttons -->
        <ng-template #disableAddItems>
            <!-- If payment allowed -->
            <div class="p-3 pb-4" *ngIf="(tabitPayService.enablePay | async) == true">
                <div class="mat-single-button lg rounded shadow">
                    <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="_continue()">
                        {{'goto-payment' | translate}}
                    </button>
                </div>
            </div>

            <!-- Homepage redirect -->
            <div class="p-3 pb-4" *ngIf="(tabitPayService.enablePay | async) == false">
                <ng-container *ngIf="!$storage.showPrices">
                    <div class="mat-single-button lg rounded shadow">
                        <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="appService.redirect(['/home/dashboard'])">
                            {{'TO_HOMEPAGE' | translate}}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="$storage.showPrices">
                    <div class="mat-split-button lg rounded shadow" *ngIf="$storage.hasOffers">
                        <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="appService.redirect(['/home/dashboard'])">
                            {{'TO_HOMEPAGE' | translate}}
                        </button>
                        <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="_continue('full_tip')">
                            {{'goto-tip' | translate}}
                        </button>
                    </div>
                    <!-- Single button -->
                    <div class="mat-single-button lg rounded shadow" *ngIf="!$storage.hasOffers">
                        <button mat-flat-button color="primary" class="lg rounded" type="button" (click)="appService.redirect(['/home/dashboard'])">
                            {{'TO_HOMEPAGE' | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </ng-container>

</div>