import { AfterViewInit, HostListener, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Stripe, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure'; // Stripe.js will not be loaded until `loadStripe` is called
import { get, concat } from 'lodash-es';
import { take } from 'rxjs/operators';

// import { GiftCardsService } from '../../../gift-cards.service';
import { AppService } from '../../../app/app.service';

declare const Heartland: any;

@Component({
    selector: 'app-payment-dialog',
    templateUrl: './payment-dialog.component.html',
    styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements AfterViewInit {

    // relevant only for CardPointe iframe
    embeddedForm: {
        submitButtonEnabled?: boolean,
        tokenizedForm?: {
            message: any,
            expiry: string | number
        },
        errorText?: string,
        formReference?: any,
        formInputsStatus?: any,
        giftCardServiceInstance?: any
    } = {
        submitButtonEnabled: null,
        tokenizedForm: {
            message: null,
            expiry: null
        },
        errorText: null,
        formReference: null,
        formInputsStatus: {},
        giftCardServiceInstance: null
    }

    loadingBraintree: boolean = true; // Only for braintree

    stripeInstance: Stripe;
    stripeElements: StripeElements;
    brainTreeInstance: any;
    gotTokenFromCardPointe: boolean = false;

    // listen for iframe messages (relevant only for CardPointe iframe)
    @HostListener('window:message', ['$event']) onMessage(event) {
        // url is within tabit-app domain
        if (event.data?.iframeEvent == 'sameHostIframeLoaded') {
            this.dialogRef.close({ redirectUrl: event.data?.fullUrl });
        }

        try {
            const eventToken = JSON.parse(event.data);
            if (!eventToken.message) throw 'no token found';

            if (this.dialogData.formType == 'cardPointeForm') {
                this.gotTokenFromCardPointe = true;
                this.embeddedForm.submitButtonEnabled = true;
            }
            // Must pass the eventToken
            this.embeddedForm.tokenizedForm = eventToken;

        } catch (error) {
            // Prevent disabling the page even though a message was received
            if (this.gotTokenFromCardPointe) return;
            if (this.dialogData.formType == 'cardPointeForm') this.embeddedForm.submitButtonEnabled = false;
            this.embeddedForm.tokenizedForm = null;
        }
    }

    constructor(
        public dialogRef: MatDialogRef<PaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public appService: AppService,
    ) { }

    ngAfterViewInit(): void {
        // Heartland and Stripe trigger remote rendering of an iframe nested in a local form
        if (this.dialogData.formType == 'heartlandForm') this.initializeHeartlandForm();
        if (this.dialogData.formType == 'stripeForm') this.initializeStripeForm();
        if (this.dialogData.formType == 'braintreeForm') this.initializeBraintreeForm(this.dialogData.clientConfiguration);
    }

    // listen for url change events inside the iframe
    iframeSrcChange(iframeInstance) {
        try {
            const iframeUrl = iframeInstance?.contentWindow?.location?.href;
            if (iframeUrl.includes('gift-cards/create-card')) {
                this.dialogRef.close({ redirectUrl: iframeUrl });
            }
        } catch (error) {
            console.debug('iframeSrcChange', error)
        }
    }

    // relevant only for CardPointe & Stripe iframe
    async embeddedFormSubmit(paymentProvider?: string, event?: any) {
        const that = this;

        if (paymentProvider == 'StripePaymentProvider') {

            that.embeddedForm.submitButtonEnabled = false;

            await that.stripeInstance.confirmPayment({
                elements: that.stripeElements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: window.location.href,
                },
                redirect: "if_required"
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
        }

        if (that.embeddedForm?.tokenizedForm) {

            console.log(`${paymentProvider || that.dialogData.formType} response`, that.embeddedForm.tokenizedForm?.message?.payload || that.embeddedForm.tokenizedForm?.message);

            if (paymentProvider == 'StripePaymentProvider' ) {

                const responseObject = that.embeddedForm.tokenizedForm?.message?.payload?.response;

                // success response - close the dialog and return the response
                if (['requires_capture', 'succeeded'].includes(responseObject?.object?.status)) {
                    that.dialogRef.close({
                        stripeForm: responseObject.object
                    });

                // error response - show the error text
                } else if (responseObject?.error?.message) {
                    that.embeddedForm.errorText = responseObject?.error?.message;
                }

                that.embeddedForm.submitButtonEnabled = true;

            } else {
                try {
                    // manually build the payment callback redirect link (not an actual URI manipulation)
                    let windowQuery = String(window.location) + `&token=${that.embeddedForm?.tokenizedForm.message}&expiry=${that.embeddedForm?.tokenizedForm.expiry}`;
                    let payParams = new URL(windowQuery.replace('/#', '')).search;

                    that.dialogRef.close({
                        tokenizedForm: that.embeddedForm.tokenizedForm,
                        redirectUrl: payParams
                    });
                } catch (error) {
                    that.dialogRef.close();
                }
            }
        }

        if (paymentProvider == 'BraintreePaymentProvider') {
            if (!that.brainTreeInstance) return;
                getPaymentMethod()
                .then(res => {
                    this.dialogRef.close(res);
                }).catch(err => {
                    console.error(err);
                });

                function getPaymentMethod() {
                    return new Promise((resolve, reject) => {
                        that.brainTreeInstance.requestPaymentMethod(function (err, payload) {
                            if (err) {
                                reject(err);
                            } else {
                                getDeviceData()
                                .then(deviceData => {
                                    payload.deviceData = deviceData;
                                    resolve(payload);
                                }).catch(err => {
                                    if (payload.type == "Venmo") {
                                        reject(err)
                                    } else {
                                        resolve(payload);
                                    }
                                });
                            }
                        });
                    })
                }

                function getDeviceData() {
                    return new Promise((resolve, reject) => {
                        that.brainTreeInstance.client.create({
                            authorization: that.dialogData.clientConfiguration.clientToken
                        }, (err, clientInstance) => {
                            if (err) {
                                reject();
                                return;
                            }
                            that.brainTreeInstance.dataCollector.create({
                                client: clientInstance,
                                kount: true
                            }, (err, dataCollectorInstance) => {
                                if (err) {
                                    reject();
                                    return;
                                }
                                resolve(dataCollectorInstance.deviceData);
                            });
                        });
                    })
                }
        }

        that.embeddedForm.submitButtonEnabled = true;
    }

    // relevant only for CardPointe iframe
    nonIframeFormSubmit(fieldModel) {
        if (fieldModel) {
            try {
                this.dialogRef.close({
                    cibusForm: { cardNum: fieldModel.value }
                });
            } catch (error) {
                this.dialogRef.close();
            }
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    initializeHeartlandForm() {

        const self = this;

        this.embeddedForm.formReference = new Heartland.HPS({
            publicKey: self.dialogData?.paymentDialogInitToken,
            type: 'iframe',
            fields: {
                cardNumber: {
                    target: 'iframesCardNumber',
                    placeholder: '•••• •••• •••• ••••'
                },
                cardExpiration: {
                    target: 'iframesCardExpiration',
                    placeholder: 'MM / YYYY'
                },
                cardCvv: {
                    target: 'iframesCardCvv',
                    placeholder: 'CVV',
                    required: true
                },
                // iframesBillingZip: {
                //     target: 'iframesBillingZip',
                //     placeholder: 'Zip',
                // },
                submit: {
                    value: self.appService.translate('general_credit_card.form.amount_to_pay', {amount: self.appService.currency + self.dialogData.amount}),
                    target: 'iframesSubmit'
                }
            },
            style: {
				'input': {
					'background': '#fff',
					'border': '1px solid',
					'border-color': '#bbb3b9 #c7c1c6 #c7c1c6',
					'box-sizing': 'border-box',
					'font-family': 'serif',
					'font-size': '16px',
					'line-height': '1',
					'margin': '0 .5em 0 0',
					'max-width': '100%',
					'outline': '0',
					'padding': '0.5278em',
					'vertical-align': 'baseline',
					'height': '50px',
					'width': '100% !important'
				},
				'#heartland-field': {
					'font-family': 'sans-serif',
					'box-sizing': 'border-box',
					'display': 'block',
					'height': '50px',
					'padding': '6px 12px',
					'font-size': '14px',
					'line-height': '1.42857143',
					'color': '#555',
					'background-color': '#fff',
					'border': '1px solid #ccc',
					'border-radius': '0px',
					'-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
					'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
					'-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
					'-o-transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
					'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
					'width': '100%'
				},
				'#heartland-field[name=submit]': {
					'background-color': '#01BCD4',
					'font-family': 'sans-serif',
					'font-size': '20px',
					'text-transform': 'uppercase',
					'color': '#ffffff',
					'border': '0px solid transparent'
				},
				'#heartland-field[name=submit]:focus': {
					'color': '#ffffff',
					'background-color': '#01BCD4',
					'outline': 'none'
				},
				'#heartland-field[name=submit]:hover': {
					'background-color': '#01BCD4',
				},
				'#heartland-field-wrapper #heartland-field:focus': {
					'border': '1px solid #3989e3',
					'outline': 'none',
					'box-shadow': 'none',
					'height': '50px'
				},
				'heartland-field-wrapper #heartland-field': {
					'height': '50px'
				},
				'input[type=submit]': {
					'box-sizing': 'border-box',
					'display': 'inline-block',
					'padding': '6px 12px',
					'margin-bottom': '0',
					'font-size': '14px',
					'font-weight': '400',
					'line-height': '1.42857143',
					'text-align': 'center',
					'white-space': 'nowrap',
					'vertical-align': 'middle',
					'-ms-touch-action': 'manipulation',
					'touch-action': 'manipulation',
					'cursor': 'pointer',
					'-webkit-user-select': 'none',
					'-moz-user-select': 'none',
					'-ms-user-select': 'none',
					'user-select': 'none',
					'background-image': 'none',
					'border': '1px solid transparent',
					'border-radius': '4px',
					'color': '#fff',
					'background-color': '#337ab7',
					'border-color': '#2e6da4'
				},
				'#heartland-field[placeholder]': {
					'letter-spacing': '3px'
				},
				'#heartland-field[name=cardCvv]': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
					'background-size': '63px 40px',
				},
				'input#heartland-field[name=cardNumber]': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
					'background-size': '55px 35px'
				},
				'#heartland-field.invalid.card-type-visa': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right',
					'background-size': '83px 88px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-visa': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top',
					'background-size': '82px 86px'
				},
				'#heartland-field.invalid.card-type-discover': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
					'background-size': '85px 90px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-discover': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
					'background-size': '85px 90px',
					'background-position-y': '1px'
				},
				'#heartland-field.invalid.card-type-amex': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right',
					'background-size': '50px 90px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-amex': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top',
					'background-size': '50px 90px'
				},
				'#heartland-field.invalid.card-type-mastercard': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
					'background-size': '62px 105px',
					'background-position-y': '-52px'
				},
				'#heartland-field.valid.card-type-mastercard': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
					'background-size': '62px 105px',
					'background-position-y': '-1px'
				},
				'#heartland-field.invalid.card-type-jcb': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right',
					'background-size': '55px 94px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-jcb': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top',
					'background-size': '55px 94px',
					'background-position-y': '2px'
				},
				'input#heartland-field[name=cardNumber]::-ms-clear': {
					'display': 'none'
				}
			},
            // Callback when a token is received from the service
            onTokenSuccess: function (response) {
                // invalid expiry
                if (!response.exp_month || !response.exp_year) {
                    self.embeddedForm.errorText = self.appService.translate('general_credit_card.errors.invalid_exp');
                    return;
                }
                // invalid CVV
                if (!self.embeddedForm.formInputsStatus?.cardCvv) {
                    self.embeddedForm.errorText = self.appService.translate('general_credit_card.errors.invalid_cvv');
                    return;
                }
                // successful tokenization
                self.dialogRef.close({
                    heartlandForm: response
                });
            },
            // Callback when an error is received from the service
            onTokenError: function (response) {
                if (response.error?.message) self.embeddedForm.errorText = response.error.message;
            },
            // Callback when an event is fired within an iFrame
			onEvent: function (event) {
				self.embeddedForm.formInputsStatus[event.source] = get(event.classes, '0') == 'valid';
			}
        });
    }

    async initializeStripeForm() {
        // can't proceed without stripe key / providerTransactionToken
        if (!this.dialogData.providerTransactionToken || !this.dialogData.stripePublishableKey) return this.dialogRef.close();

        // Gift card shop flow
        if (this.dialogData.giftCardServiceInstance) {
            this.dialogData.giftCardServiceInstance.publicStore.giftCardConfig$
            .pipe(take(1))
            .subscribe(async (giftCardConfig) => {

                if (!giftCardConfig) {
                    this.appService.stopBlock();
                    this.embeddedForm.giftCardServiceInstance.showErrorDialog();
                    return this.dialogRef.close();
                }

                const fullAddressStrategy = giftCardConfig.configuration?.paymentMethods[0]?.paymentVerificationStrategy == 'fullAddress';

                this.generateStripeElement(fullAddressStrategy);

            }, error => this.dialogData.giftCardServiceInstance.showErrorDialog())
        // Generic flow (Tabit Order)
        } else {
            const paymentVerificationStrategy = !!this.dialogData.actualPaymentVerificationStrategy ? this.dialogData.actualPaymentVerificationStrategy : this.dialogData.paymentVerificationStrategy;
            const fullAddressStrategy = paymentVerificationStrategy == 'fullAddress';
            this.generateStripeElement(fullAddressStrategy);
        }
    }

    async generateStripeElement(fullAddressStrategy) {
        // initialize stripe
        // loadStripe.setLoadParameters({advancedFraudSignals: false});
        this.stripeInstance = await loadStripe(this.dialogData.stripePublishableKey, {
            stripeAccount: this.dialogData.providerAccountId
        });

        this.embeddedForm.submitButtonEnabled = true;

        this.stripeElements = this.stripeInstance.elements({
            appearance: { theme: 'stripe'},
            clientSecret:  this.dialogData?.providerTransactionToken
        });

        const paymentElement = this.stripeElements.create('payment');
        paymentElement.mount('#stripe-payment-element');

        // Create the Address Element in billing mode
        if (fullAddressStrategy) {
            const addressElement = this.stripeElements.create('address', {
                mode: 'billing',
            });
            addressElement.mount('#stripe-address-element');
        }
    }

    initializeBraintreeForm(clientConfiguration) {
        if (!clientConfiguration) return this.dialogRef.close({ mode: 'cancel' });
        window["braintree"].dropin.create(clientConfiguration, (createErr, instance) => {
            if (createErr) {
                console.error(createErr);
                this.dialogRef.close({ mode: 'cancel' });
            } else {
                this.loadingBraintree = false;
                this.brainTreeInstance = instance;
                console.debug(instance);

                instance['on']('paymentOptionSelected', (event) => {
                    this.embeddedFormSubmit('BraintreePaymentProvider')
                    .then(() => {
                        this.dialogData['paymentOption'] = event.paymentOption;
                    })
                });

                instance['on']('paymentMethodRequestable', (event) => {
                    this.embeddedFormSubmit('BraintreePaymentProvider')
                    .then(() => {
                        if (this.dialogData['paymentOption'] == 'card') return;
                        this.embeddedFormSubmit('BraintreePaymentProvider');
                    });
                });

                instance['on']('noPaymentMethodRequestable', (event) => {
                    this.embeddedFormSubmit('BraintreePaymentProvider');
                });
            }
        })
    }

}
