<h3 mat-dialog-title>תנאי שימוש ומדיניות הפרטיות</h3>


<div mat-dialog-content dir="rtl" style="white-space: pre-wrap; font-size:14px" class="border-bottom">
האתר Tabit ("האתר") מנוהל ומופעל על ידי חברת טאביט טכנולוגיות בע"מ ("החברה"). השימוש באתר כפוף לתנאי השימוש באתר המפורטים מטה ("תנאי השימוש") ובמדיניות הפרטיות המפורסמת להלן ("מדיניות הפרטיות").

תנאי השימוש מנוסחים בלשון זכר אך מיועדים לכל אדם הגולש באתר, נשים וגברים כאחד ("אתה", "המשתמש/ים" או "הגולש/ים").

גלישה ו/או שימוש על ידך באתר מכל סוג, לרבות ההסתמכות על המחירים ורכישת מוצרים ו/או שירותים, מהווה את הסכמתך ואישורך לאמור בתנאי השימוש והתחייבותך על פיהם. החברה שומרת לעצמה את הזכות לעדכן את תנאי השימוש מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך בהודעה מראש. תוקפו של שינוי תנאי השימוש יחל מרגע פרסומו באתר. חובתך להתעדכן בתקנון בכל כניסה לאתר. אם אינך מסכים לתנאי השימוש של האתר, כולם או חלקם, אינך רשאי לעשות שימוש באתר לכל מטרה שהיא ולא תהיה לך כל טענה, תביעה ו/או דרישה כלפי החברה.

אם הנך מתחת לגיל 18, אנא קרא תנאים אלה בעיון ובקפידה יחד עם הוריך או אפוטרופוס אחר. אם אתה או הוריך אינכם מסכימים לתנאי השימוש של האתר, כולם או חלקם, אינך רשאי לעשות שימוש באתר זה לכל מטרה שהיא.

<br><b>1 שירותי האתר</b>
1.1  בכפוף לתנאי השימוש, תוכל לבצע חיפוש ולהזמין משלוחי מזון ממגוון מסעדות, אתרים ובתי עסק ("בתי העסק"), כמו כן, תוכל לצפות בתפריטי המסעדות של בתי העסק (ככל שיהיו) (להלן: "השירותים").
1.2  באתר תוכל למצוא תכנים ומידע לרבות קישורים, וכל מידע אחר שיועמד לרשות המשתמשים באתר בכל אמצעי שהוא, הקיים כיום או שיהיה קיים בעתיד, בנוגע לבתי עסק ונותני שירותים שונים ("התכנים").
1.3  באתר מתפרסמים הן תכנים שמקורם בחברה והן תכנים שמקורם בצדדים שלישיים. החברה רשאית לסרב לפרסם תכנים, אשר לדעתה מפרים את תנאי השימוש באתר ו/או העלולים לפגוע בגולשים האחרים באתר ו/או באתר ו/או בחברה ו/או בצד שלישי כלשהו, וכן רשאית החברה למחוק תכנים אלו בכל עת. החברה לא תהא אחראית לטעויות, השמטת פרטים, דעות ו/או לאי-דיוקים העלולים להתרחש ו/או להתפרסם באתר ו/או כתוצאה משימוש באתר.
1.4  האתר נועד לשימוש פרטי בלבד וכל שימוש מסחרי בו ללא קבלת הסכמתה מראש ובכתב של החברה הינו אסור. בנוסף, אינך רשאי להשתמש בתכנים שמקורם באתר ו/או בצד שלישי. החברה תהא רשאית להפסיק את שימושך באתר בכל עת אם לא תעמוד באחד או יותר מתנאי השימוש ו/או מכל סיבה שהיא, ללא הודעה מוקדמת ולפי שיקול דעתה הבלעדי.
1.5  השימוש בשירותים והגישה אליהם בטלים ככל ששימוש או גישה זו אסורה לפי החוק. השימוש בשירותים יחשב כהצהרתך והתחייבותך כי (א) כל מידע לרישום שהוגש הוא אמיתי ומדויק; (ב) תשמור על עדכניות ואמיתות מידע זה; (ג) אתה בן 18 שנים ומעלה, או לחילופין, קיבלת אישור מהורה ו/או אפוטרופוס להשתמש בשירותים של האתר (ד) השימוש שלך בשירותים אינו מפר כל חוק או תקנה או כל התחייבות אחרת.
1.6  צורתם וטבעם של השירותים והתוכנה עשויים להשתנות מעת לעת, ללא הודעה מוקדמת.
<br><b>2 הרשמה לאתר</b>
2.1  כדי להשתמש בשירותים באתר כמשתמש רשום (כהגדרתו להלן) ו/או לצורך ביצוע הזמנה ומשלוח ללא רישום לאתר ("משתמש לא רשום"), תתבקש לספק לחברה מספר פרטים אודותיך בטופס רישום, בין היתר את שמך המלא, כתובת דואר אלקטרוני, כתובת מגוריך ומספר טלפון ("טופס רישום"), חלק מהנתונים שתתבקש למסור הינם פרטי חובה אשר יסומנו באופן מפורש כהיותם פרטי חובה ("פרטי חובה"). לא ניתן להירשם לאתר ו/או לבצע הזמנה ומשלוח מבלי למסור פרטים אלו. רישום כמשתמש רשום יתבצע על גבי "טופס הרשמה למשתמשים רשומים" ויכלול אפשרות להוסיף פרטי כרטיס האשראי. גולש אשר מילא טופס זה יקרא להלן: "משתמש רשום".
2.2  הנך מתחייב: (א) לספק מידע נכון, אמיתי, מלא ומדויק אודותיך, כפי שתתבקש בטופס הרישום ו/או בטופס הרשמה למשתמשים רשומים; ו – (ב) לעדכן את המידע כפי שנמסר בטופס הרישום ו/או בטופס הרשמה למשתמשים רשומים ולשמור על דיוקו ונכונותו.
2.3  על מנת להשתמש בשירותי האתר כמשתמש לא רשום תתבקש למסור את פרטי החובה על טופס הרישום ולבחור סיסמה, אשר תשמש בזיהויך לצורך קבלת השירותים. הנך מתחייב: (א) כי ידוע לך שאתה אחראי באופן מלא לשמירת סודיות הסיסמה; ו - (ב) להודיע לחברה באופן מיידי על כל שימוש שאינו מורשה בסיסמה שלך ו/או על כל הפרת סודיות אחרת.
2.4  לחברה לא תהא כל אחריות לשימוש בלתי מורשה של כל צד ג' בנתונים שנמסרו על ידי משתמש לא רשום ו/או על ידי משתמש רשום (להלן: "המשתמש"). במידה שתבחר לחלוק מידע זה עם צד ג' כלשהו, תישא באחריות לכל הפעולות שיתבצעו באמצעות הנתונים שלך.
<br><b>3 ביצוע הזמנה</b>
3.1  במסגרת השירותים באתר, תוכל לבצע הזמנות ומשלוחים מבתי עסק שונים.
3.2  כחלק משירותי האתר תוכל לבחור לבצע את התשלום לבית העסק בגין הזמנת השירות באמצעות כרטיס אשראי תקף או באמצעות תשלום במזומן או באמצעות כרטיסי הסדר אשר מאושרים לשימוש על ידי בית העסק ("כרטיס הסדר"), בהתאם לנוהלי בית העסק.
3.3  החברה אינה אחראית ולא תהיה אחראית לכל טענה בדבר אי נכונות ו/או אי דיוק ו/או פגמים בפרטים שנמסרו על ידיך בעת ביצוע הזמנת השירות ו/או לגבי פרטים שלא נקלטו במערכת המחשב של החברה ולכל נזק, חיסרון כיס, וכל טענה אחרת בקשר עם ביצוע משלוח ו/או הזמנה.
3.4  החברה אינה ולא תהיה צד, ישיר או עקיף, לשירותי הזמנת המשלוחים. כמו כן, החברה לא תנקוט עמדה במחלוקות, אם וכאשר יתעוררו, בין צדדים שונים בעסקה כאמור. הנך מצהיר בזאת כי ידוע לך שהחברה לא תהא אחראית בשום מקרה להזמנת משלוח שלא כובדה במלואה ו/או חלקה ו/או לכל איחור, ביטול, טעות ו/או נזק או חסרון כיס אשר יגרמו לך בקשר עם שירות הזמנת משלוח ו/או הזמנת משלוח אשר איננה תואמת את הפרסום ו/או התנאים שסוכמו בין בית העסק לבינך, ו/או בכל מקרה של תקלה בשירותי הזמנת המשלוחים ו/או בסליקה ו/או בתשלום ו/או בתמורה שהתקבלה.
<br><b>4 מחירים</b>
4.1  החברה מציגה מחירים של מוצרים ושירותים שונים ואת מחירי דמי המשלוח, כפי שהתקבלו מבתי עסק, לפי שיקול דעתה הבלעדי ("מחירים"). החברה אינה מתחייבת לכך שהמחירים שמוצגים באתר ישקפו בהכרח את מחיר המוצר כפי שיימכר בפועל על ידי בית העסק וייתכנו מוצרים אשר יימכרו במחירים נמוכים או גבוהים יותר מהמחירים המוצגים באתר. החברה לא תהא אחראית לטעויות,לפערים ו/או לאי- דיוקים אשר יהיו בין המחירים המוצגים באתר לבין המחיר אשר נגבה בפועל על ידי בית העסק ולא תחוב בכל נזק ו/או חסרון כיס שיגרם לך או לצד שלישי כתוצאה מהסתמכות על המוצג במחירים.
4.2  החברה אינה מתחייבת לכך שהמחירים משקפים בהכרח את מחיר השוק המתאים לכל סוג של מוצר או שירות. לחברה אין ולא תהיה כל אחריות, ישירה או עקיפה, לנתונים המופיעים במחירים ולכל שימוש שיעשה בהם על ידך. ייתכנו מוצרים ושירותים מקבילים שמחירי השוק שלהם נמוכים או גבוהים יותר מהמחירים המוצגים באתר.
<br><b>5 קניין רוחני</b>
כל הנתונים והמידע המצויים באתר, למעט מידע המצוי בקישורים לאתרים האחרים שהאתר מפנה ו/או יכול להפנות אליהם, הם רכושה הבלעדי של החברה ואין לראות במידע ובשירותים המסופקים על ידה באתר כמקנים רישיון כלשהו או זכויות כלשהן בקניינה הרוחני של החברה או של צדדים שלישיים, לפי העניין. זכויות היוצרים ברעיון העומד ביסוד האתר, לרבות סימניו המסחריים, עיצוב האתר, וכן בכל פעולה אחרת באתר הייחודית והבלעדית לחברה, שמורים לחברה. אין להעתיק או לפרסם את דפי האתר במלואם או בחלקם ו/או לעשות בהם או במידע שבתוכם שימוש כלשהו, לרבות שימוש מסחרי, ללא הסכמתה המפורשת מראש ובכתב של החברה. כמו כן, אין להכניס באתר שינויים, להעתיק, להפיץ, לשדר, להציג בפומבי, לשכפל, לפרסם או למסור לצד שלישי כל חלק מן האתר בלא קבלת הסכמתה של החברה מראש ובכתב. פרסומת המוצגת באתר מטעם גורם שאינו החברה הינה קניינו של בית העסק ו/או המפרסם ואין לעשות בה שימוש הפוגע בזכות המפרסם.

<br><b>6 הגבלת אחריות ושיפוי</b>
הנך מתחייב ומצהיר כי ידוע לך, ואתה מסכים, כי:

6.1 השימוש באתר ובשירותים המוצעים בו הינו על אחריותך בלבד. השימוש באתר ובתכנים ניתן לך חינם כמות שהוא (AS IS) בהתאם להחלטת החברה, ולא תהיה לך כל טענה, תביעה או דרישה כלפי החברה בגין תכונות השימוש או השירות, יכולותיו ומגבלותיו, התאמתו לצרכיך או התגובות שיעורר (אם בכלל) הפרסום, אם וככל שיהיה, באתר.
6.2 החברה אינה מתחייבת ששירותי האתר יינתנו כסדרם או בלא הפסקות, יהיו איכותיים, יתקיימו בבטחה וללא טעויות, ו/או הפרעות ויהיו חסינים מפני גישה בלתי-מורשית למחשבי ו/או לתוכנות החברה או מפני נזקים, קלקולים, תקלות או כשלים בין היתר בחומרה, בתוכנה, בקווי ובמערכות תקשורת, אצל החברה או אצל מי מספקיה או אצלך, ולא תהיה לך כל טענה כלפי החברה בקשר לכך. והנך מודע לכך כי יתכן ובעתיד לא יהיה השירות זמין בשל צרכי תחזוקה, שדרוג או מכל סיבה אחרת.
6.3 הצבת מידע מסחרי ו/או מידע על בתי עסק ו/או מידע פרסומי באתר נועדו לספק אינפורמציה בלבד ולא תתפרש כהצעה של האתר להשתמש ו/או המלצה, ו/או עידוד, ו/או הסכמה, ו/או הבעת תמיכה, ו/או חוות דעת מקצועית ו/או מתן חסות של האתר, לתכנים ו/או לשירותים המוצעים על ידי המפרסמים באתר אם וככל שיהיו. בעצם פרסום התכנים החברה אינה מביעה דעתה לגבי נכונותם ו/או דיוקם ו/או רמתם ואיכותם של התכנים ו/או של כל מידע, פרסומות, קישורים, שירותים, ומוצרים המוצגים באתר. החברה לא תישא בכל אחריות לתוכן המודעות אם וככל שיהיה והמידע המסחרי שיפורסמו באתר.
6.4 כל עסקה שתבצע בעקבות תכנים, מודעה או מידע המתפרסמים באתר או באמצעות קישור (Link) לאתר צד שלישי, לרבות אתרים האחרים, וכן כל רכישה או משלוח, בין שבאמצעות כרטיס אשראי, בין שבכרטיס הסדר, בין שבמזומן ובין שבכל אמצעי תשלום אחר, בהתאם למדיניות בית העסק, וכן כל תשלום דמי משלוח ו/או תשלום עבור רכישה ו/או סליקת כרטיסי אשראי, אינה כוללת את החברה כצד בעסקה, ותסוכם ישירות בין המשתמש לבין בית העסק הנוגע בדבר. מובהר בזאת כי החברה לא תישא בכל אחריות לשירותים ולמוצרים, לרבות טיב המוצרים ו/או השירותים, למשלוח, לתשלום ולהעברתו, לאחריות בגין אספקת המוצר ו/או אחריות לשלמותו תקינותו ותיקונו, ואלו יסוכמו ישירות בין הגולש לבין בית העסק או המפרסם הנוגע בדבר.
6.5 החברה איננה מתחייבת כי כל הקישורים שימצאו באתר יהיו תקינים ויובילו את הגולשים לאתר אינטרנט פעיל. החברה רשאית למחוק מהאתר קישורים שנכללו בו בעבר, או להימנע מהוספת קישורים חדשים - הכול לפי שיקול דעתה הבלעדי. העובדה שימצא באתר קישור לאתר מסוים אינה מהווה אישור לכך שהמידע באתר זה מלא, אמין, עדכני או מהימן.
6.6 החברה לא תיקח חלק במחלוקות אם וכאשר יתעוררו בין צדדים שונים בעסקה כאשר הצדדים אינם כוללים את החברה. במקרה בו החברה ו/או מי מטעמה ניסו (או ינסו) מרצונם הטוב ולפנים משורת הדין, לסייע בפתרון בעיה ו/או פנייה של גולש שבחר לבצע פעולה באתר אחר, סיוע זה לא יתפרש בשום אופן כמטיל אחריות כלשהי על החברה ו/או מי מטעמה.
6.7 לא תהא לך כל טענה או דרישה כלפי החברה בקשר עם האתר, החברה תהא רשאית לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו וזמינותם של השירותים והתכנים הניתנים בו וזאת ללא צורך להודיע לגולשים מראש על כך. שימוש באתר לאחר שינוי כלשהו באתר מהווה את הסכמתך לתנאי האתר, כפי ששונו.
6.8 ידוע לך כי, בשום מקרה, החברה ו/או מי מטעמה אינה נושאת ולא תישא אחריות כלפיך ו/או כלפי כל צד שלישי, לכל נזק (כולל אך לא רק נזק בגין אובדן של בסיס נתונים, אובדן תוכנות, עלויות הספקה של שירות חלופי, או הפרעות בשירות) בין אם ישיר, עקיף, משני, נלווה, מיוחד או תוצאתי הנובע מהשימוש באתר ו/או בשירותים, חשיפה לאתר, גלישה באתר, מאי יכולת להשתמש באתר, מרכישת טובין, רכישת שירותים, ביצוע עסקה, או כתוצאה משימוש לא מורשה באתר או כתוצאה משינוי המידע שנמסר על-ידו, לרבות נזק הנגרם כתוצאה מהפסד ברווחים, שימוש והפסד במידע ושאר נכסים בלתי מוחשים וזאת גם אם האתר קיבל הודעה על האפשרות לאותם הפסדים.
6.9 ידוע לך ואתה מתחייב כי הנך אחראי לכל תוצאה ישירה או עקיפה הנובעת משימוש באתר ובשירותיו ואתה מסכים לשפות את החברה עובדיה, מנהליה או מי מטעמה ולהגן עליה, בגין כל תביעה, טענה, דרישה הפסד, אבדן-רווח, תשלום, הוצאה או נזק, לרבות שכר טרחת עורך דין והוצאות משפט (כולל של צד ג' כלשהו), כתוצאה מאופן שימושך ו/או מגלישה ו/או מחוסר יכולת שימוש באתר ו/או מהפרת תנאי השימוש באתר ובשירותים.
6.10 ידוע לך כי השימוש באתר כפוף לכל החוקים והתקנות המקומיים, מדינתיים והבינלאומיים ואתה מתחייב שלא להפר כל חוק ו/או תקנה בקשר עם השימוש באתר ובשירותיו, לרבות הפרת חוקי מדינת ישראל, הנוגעים להעברת מידע או תוכנה ממדינת ישראל. כמו כן, הנך מתחייב שלא לבצע ו/או לנסות לבצע בדיקה ו/או בחינה של רגישות של המערכת, או לפצח אמצעי אבטחה או אימות, או להתערב או להפריע למערכות ו/או רשתות המחוברות לשירות או להפר את החוקים, תקנות, מדיניות או תהליכים של מערכות ו/או רשתות אחרות והנך מתחייב שלא להשיג או לנסות להשיג גישה לא מורשית לשירות, לחשבונות אחרים באתר, מערכות מחשבים ורשתות תקשורת המחוברות לשירות על-ידי פיצוח סיסמה או כל אמצעי אחר.
<br><b>7 התחייבות הגולשים באתר</b>
בנוסף לכל האמור בתנאי השימוש, אתה מתחייב כי לא תעשה אחד או יותר מהדברים הבאים:

7.1 תתחזה לאדם או גוף כלשהו, לרבות נציג החברה ו/או תמסור תיאור מסולף או הצגה כוזבת של קשר לאדם או גוף כלשהו ו/או תעלה לאתר, תעביר, תשלח או תשדר כל חומר שקרי ומטעה ו/או חומר אשר נועד להערים על גולש או על גוף כלשהו. תעשה שימוש שנועד להתחקות אחר אדם אחר או תטריד אדם אחר בכל דרך שהיא.
7.2 תעלה לאתר, תעביר, תשלח או תשדר כל חומר המכיל כל סוג של קוד מחשב המתוכנן להרוס, להפריע, או להגביל את השימוש (ובכלל זה כל שימוש אחר מלבד שימוש הוגן וסביר בשירות) בכל אחד מהמחשבים, השרתים, החומרה והתוכנה אשר מצויות בשימוש החברה לצורך אספקת השירותים המוצעים באתר.
7.3 תאסוף או תאגור מידע אישי על משתמשים אחרים לשם מטרות כלכליות או בלתי חוקיות או תפיץ דואר זבל (SPAM) לשרתי האתר ו/או לגולשים באתר. כמו כן תמכור, תפיץ או תעשה כל שימוש כלכלי במידע באתר או כל שימוש אחר באופן העלול לפגוע באדם אשר המידע רלוונטי לו.
7.4 תציב את האתר, או כל חלק ממנו, בתוך מסגרת של אתר אחר או כחלק מאתר אחר ללא הסכמתה הכתובה המוקדמת של החברה.
7.5 תשנה, תערוך, תעתיק, תשאב מידע, תתאים, תיתן רישיון משנה, תתרגם, תמכור, תבצע פעולות של הנדסה חוזרת, תפרק או תרכיב מחדש כל אחד מחלקי הקוד המרכיבים את האתר, לרבות בסיס המידע, וכן פעולות כאלה לגבי החומרה והתוכנה אשר מצויות בשימוש עבור השירות.
7.6 תשתמש בכל רובוט, מנוע אחזור וחיפוש מידע, או בכל כלי אוטומטי או ידני אחר אשר מתוכנן לאנדקס, לאתר ולאחזר מידע באתר או כלי כאמור אשר מתוכנן לחשוף את מבנה מאגר הנתונים והקוד באתר זה.
7.7 תפריע או תקטע את פעולת האתר (לרבות על ידי הפרעה לפעולות השרת ורשת המחשבים המחוברים לאתר או של רשת המחשבים המחוברת אליה).
<br><b>8 פרסומות של צדדים שלישיים, קישורים לאתרים אחרים ושימוש ב- Cookies</b>
8.1 החברה משתמשת ב-Cookies לצורך תפעול שוטף ותקין של האתר, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, כדי להתאים את האתר להעדפותיך האישיות, לצורכי אבטחת מידע ולאימות פרטים.
דפדפנים מודרניים כוללים אפשרות להימנע מקבלת Cookies. אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
8.2 החברה רשאית להתיר לחברות אחרות לנהל את מערך הפרסומות באתר. יתכן שהמודעות שבהן תצפה בעת הביקור באתר מגיעות ממחשביהן של אותן חברות. כדי לנהל את הפרסומות שלהן, חברות אלה תהינה רשאיות להציב קבצי Cookies במחשבך. ה-Cookies מאפשרים להן לאסוף מידע על האתרים שבהם צפית בפרסומות שהציבו ועל אילו פרסומות הקשת. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן ולא למדיניות הפרטיות של החברה.
8.3 בנוסף, האתר מכיל קישורים לאתרים אחרים (בין היתר של בתי העסק), אשר אינם קשורים או שייכים לחברה ("האתרים האחרים"). החברה אינה אחראית לתקינות האתרים האחרים, ו/או לשימוש באתרים האחרים ואין ולא תהיה לחברה אחריות, שליטה או אפשרות פיקוח על התכנים המצויים באתרים האחרים, ו/או על מדיניות הפרטיות של האתרים האחרים ואין בהימצאות הקישורים באתר משום המלצה של החברה לבקר בהם. ביקורך באתרים האחרים ו הינו לפי שיקול דעתך ועל אחריותך בלבד.
<br><b>9 שונות</b>
9.1 החברה אינה מפלה על בסיס גיל, מין, גזע, לאום, דת, העדפה מינית או כל סטאטוס אחר.
9.2 החברה שומרת לעצמה את הזכות להשעות, לבטל או לסרב לספק את השירות, וזאת על פי שיקול דעתה הבלעדי ומבלי למסור הודעה על כך מראש, לרבות, אך לא רק במקרים הבאים: (1) מסירת מידע שאינו אמיתי, מעודכן, שלם או נכון, או אם יש לחברה את היסוד הסביר להניח כי כך קרה; (2) כרטיס אשראי ו/או כרטיס הסדר של משתמש נחסם או הוגבל לשימוש בדרך כלשהי.
9.3 החברה רשאית להמחות ו/או להעביר ו/או להסב את זכויותיה לפי תנאי שימוש אלה, או חלק מהן, לכל תאגיד או גוף צד שלישי לפי שיקול דעתה הבלעדי וללא צורך להודיע על כך מראש.
9.4 על תנאי שימוש אלו יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע להסכם זה ולשימוש באתר הינו בבתי המשפט המוסמכים באזור תל-אביב יפו.
<br><b>10 מדיניות הפרטיות</b>
הנהלים אשר על פיהם פועלת החברה באיסוף ובשימוש במידע אישי ("מדיניות הפרטיות") מפורטים להלן:

10.1 החברה רואה חשיבות בשמירה על פרטיות הגולשים ועושה מאמצים להגן ולשמור כיאות על המידע האישי על הגולשים ועל פרטיותם.
10.2 מדיניות הפרטיות חלה על הנתונים שנמסרים, כחלק מתהליך ההרשמה לאתר, על התכנים והמידע המועברים על ידי הגולשים בזמן השימוש באתר, וכן על מידע שנאסף אודות הגולשים בזמן הגלישה באתר ("מידע על הגולש").
10.3 החברה תהא רשאית לנהוג במאגרי המידע של הגולשים בהתאם להוראות הדין ולעשות במאגרי המידע של הגולשים שימוש, בין היתר, לצורך דיוור מידע ותכנים אל הגולשים (בדואר ו/או בדואר אלקטרוני ו/או בפקסימיליה ו/או בהודעות טקסט לטלפון הנייד), לרבות דברי פרסומת של בתי העסק השונים ו/או מפרסמים שונים.
10.4 הנך מסכים כי פרטים מסוימים אשר תמסור באתר יישמרו במאגר מידע המנוהל על ידי החברה, כולל שם פרטי, שם משפחה ומספר טלפון. מידע זה יהיה זמין ונגיש עבור בתי העסק אשר עובדים עם החברה. במידה והנך משתמש רשום, הנך מסכים שפרטי כרטיס האשראי שלך, אם וככל שמסרת, יאוחסנו על ידי החברה באופן מוטמע, כך שלא תצטרך להכניס את פרטי הכרטיס כל פעם שתבצע הזמנה דרך אתרה של החברה.
10.5 הנתונים שתמסור בעת ההרשמה לשירותים באתרים ו/או בעת עדכון פרטיך (כפי שיעשה מעת לעת), יישמרו במאגר המידע של החברה. אינך נדרש על-פי חוק למסור את המידע. בכל עת תוכל לפנות לחברה בדואר אלקטרוני כתובת דואר אלקטרוני: support&#64;tabit.cloud ולבקש להסיר את הנתונים ממאגר המידע (לרבות אך לא מוגבל לפרטי כרטיס האשראי). החברה תסיר את הנתונים ממאגר המידע תוך זמן סביר. הנך מצהיר כי ידוע לך כי בלא מסירת הפרטים המופיעים בשדות החובה, לא תוכל להשתמש בשירותים הטעונים הרשמה. המידע שתמסור בעת ההרשמה לא יימסר לצדדים שלישיים למעט כמפורט במדיניות הפרטיות.
10.6 החברה רשאית להשתמש במידע על הגולשים לצורך שיפור האתר, השירותים והתכנים שהיא מציעה, וכן לצורך יצירת קשר עם הגולשים. הנך מסכים ומאשר, בעצם מסירת פרטים, כי החברה תהא רשאית לשמור במאגריה את הפרטים שנמסרו על-ידך ומידע נוסף שיצטבר אודותיך, לשם ביצוע, מעת לעת, שימוש כאמור בתנאי שימוש אלה, למטרות סטטיסטיות ו/או לעשיית עסקאות מכל סוג עם לקוחות וצדדים שלישיים, ולצרכים מסחריים אחרים, זאת ללא תשלום כל תמורה.
10.7 החברה רשאית לשלוח אליך מדי פעם בדואר אלקטרוני מידע פרסומי - בין אם מידע שהיא עצמה תפרסם ובין אם מידע שתקבלו לצורך משלוח מידי מפרסמים אחרים. החברה לא תמסור את פרטיכם האישיים למפרסמים, למעט אם הסכמתם לכך במפורש.
10.8 החברה תימנע ככל האפשר מלמסור את הנתונים שלך לצדדים שלישיים נוספים, אלא במקרים בהם תמצא החברה כי איזו מפעולותיך באתר מפרות את תנאי השימוש, או נעשו לשם ביצוע תרמית מכל סוג שהוא, או אם היא תהיה מחויבת לעשות כן על פי צו שיפוטי או אם תעמוד בפני איום שיינקטו כנגדה צעדים משפטיים (פליליים או אזרחיים) בגין פעולות שביצעת באתר. במקרים אלו רשאית החברה למסור את פרטיך לצד הטוען כי נפגע מכם או בהתאם להוראות צו שיפוטי.
10.9 החברה אינה נושאת בכל אחריות בגין שימוש במידע אודותיך המתקבל שלא באמצעותה כי אם על-ידי צדדים שלישיים לרבות גופים מסחריים, אתרים או סוחרים מקוונים אשר משתמשים או מופיעים באתר (כגון בתי עסק), ואשר אינם בשליטתה של החברה. בכל מקרה של ספק עליך לבדוק את הנהלים לשמירת הפרטיות ואת תנאי השימוש של אותו בית עסק.
10.10 החברה מאבטחת את המידע המצוי באתר באמצעות מערכות מתקדמות וחדישות ביותר. הנך מצהיר כי ידוע לך שאבטחת המידע המוזן לאתר נעשית באמצעות פרוטוקול אבטחה SSL הנהוג באתרי מסחר אלקטרוני. על אף האמור, אין החברה יכולה להתחייב בפני המשתמשים כי לא תתאפשר חדירה בלתי חוקית למאגרי המידע הכלולים באתר.
            </div>


<div mat-dialog-actions>
    <div class="flex-grow-1">

    </div>
    <button type="button" mat-flat-button [mat-dialog-close]="true">
        {{'OK' | translate}}
    </button>
</div>