<block-ui>
    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="close()">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate">{{'SERVICE_BUTTON.pay' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
            <button class="menu" mat-icon-button (click)="close()">
                <mat-icon class="close">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="preloader-scan-wrapper" (contextmenu)="simulateScanner($event)">
        <div class="preloader-scan" [style.background-image]="'url('+ appService.images.trans_mask +')' | safeStyle" *ngIf="scanning">
            <div class="_info-top">{{'MESSAGES.QR_HELPER' | translate}}</div>
            <div class="_info-bottom">
                <button mat-flat-button color="primary" (click)="toggleFlash()">
                    <mat-icon aria-label="Flash">{{toggleFlashIcon}}</mat-icon>
                </button>
                <button class="wl-cards-background-color" mat-flat-button (click)="gotoManualEntry()">{{'MESSAGES.TABIT_PAY_MANUAL_ENTER_FOR_SITE' | translate}}</button>
            </div>
            <!--
            <div class="_info-bottom" (click)="gotoManualEntry()">
                <div>{{(site ? 'MESSAGES.TABIT_PAY_MANUAL_ENTER_FOR_SITE' : 'MESSAGES.TABIT_PAY_MANUAL_ENTER') | translate}}</div>
                <a>{{'press here' | translate}}</a>
            </div>
            --> 
            <div class="diode">
                <div class="laser"></div>
            </div>
        </div>
    </div>
</block-ui>