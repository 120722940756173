import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { pickBy, identity } from 'lodash-es';

@Component({
  selector: 'app-generic-item-image',
  templateUrl: './generic-item-image.component.html',
  styleUrls: ['./generic-item-image.component.scss']
})
export class GenericItemImageComponent implements OnInit {


    // optional inputs
    @Input() customFallbackImage?: string;
    @Input() imageUrl?: string;
    @Input() imageAlt?: string;
    @Input() imageCustomCss?: any = {};

    // hide the image until we set a stable src
    bootSequenceDone: boolean = false;
    // indicates an image loading error occurred
    invalidImageUrlFlag: boolean = false;

    constructor(
        private appService: AppService
    ) {}

    ngOnInit(): void {
        // filter non valid css properties
        this.imageCustomCss = pickBy(this.imageCustomCss, identity);
    }

    // image loaded successfully
    imageLoadSuccessEvent() {
        this.bootSequenceDone = true;
    }

    // error loading the image
    imageLoadErrorEvent() {
        // first image loading-error: try to load a custom fallbackImage (if was passed as Input)
        if (this.customFallbackImage && !this.invalidImageUrlFlag) {
            this.invalidImageUrlFlag = true;
            this.imageUrl = this.customFallbackImage;

        // no custom fallback-image passed, or a second image-loading-error event: show the app's main fallback-image
        } else {
            if (this.imageUrl != this.appService.images.meal) this.imageUrl = this.appService.images.meal;
        }
    }

}