
<div 
    class="panel panel-default wl-cards-background-color" 
    [class.has-access]="hasAccess"
    [class.dialog-mode]="dialogViewMode"
>

    <button type="button" class="btn-clear-access" (click)="removeAccess()" aria-label="{{'ACCESS.CLEAR_ACCESSIBILITY_FEATURES' | translate}}" *ngIf="hasAccess">
        <mat-icon>refresh</mat-icon>
    </button>

    <div
        class="panel-heading wl-cards-background-color"
    >
        <span class="panel-title">
            {{'ACCESS.ACCESSIBILITY' | translate}}
        </span>

        <!-- <span class="declaration-link-container" *ngIf="declarationURL">
            <a 
                (click)="openLink()"
                accessible
            >
                {{'ACCESS.ACCESSIBILITY_STATEMENT' | translate}}
            </a>
        </span> -->
    </div>

    <div class="panel-body wl-cards-background-color">
        <div class="btn-access-container" *ngFor="let feature of features" [ngClass]="{'last': feature.last}">
            <button type="button" class="btn btn-access wl-app-primary-background-color" [ngClass]="{'active': feature.active}" (click)="toggleFeature(feature)">
                <mat-icon [svgIcon]="feature.icon"></mat-icon>
                {{'ACCESS.' + feature.id | translate}}
            </button>
        </div>
    </div>

    <!-- <div class="panel-footer">
        <mat-icon [svgIcon]="'accessibility-declaration'"></mat-icon>
        {{'ACCESS.ACCESSIBILITY_STATEMENT' | translate}}
    </div> -->

</div>
