import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppService } from '../../app.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-selected-tag',
    templateUrl: './selected-tag.component.html',
    styleUrls: ['./selected-tag.component.scss']
})
export class SelectedTagComponent {

    @Input() filteredTags: any[];

    @Output() remove = new EventEmitter<any>();


    constructor(private appService: AppService) { }

    removeTag(tag: any): void {
        if(tag) this.remove.emit(tag);
    }

    getTagLabel(tag: any): string {
        if (tag.lang[this.appService?.localeId.toLocaleLowerCase()]?.label) {
            return tag.lang[this.appService?.localeId.toLocaleLowerCase()]?.label;
        }
        return tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()]?.label;
    }

}
