<mat-tab-group>

    <mat-tab [label]="'site_details.tab_label_menu' | translate" *ngIf="site.menus && site.menus.length">
        <div class="menus-container">
            <div *ngFor="let menu of site.menus">
                <app-menu-item [menuItem]="menu"></app-menu-item>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="על המסעדה" *ngIf="siteDetailsPage.about || siteDetailsPage.videoLink">
        <div class="description">
            <ng-container *ngTemplateOutlet="site._id == '57b010e4cb82f61e009fe53a' ? exampleTemplate : mainTemplate;"></ng-container>
        </div>
    </mat-tab>

    <mat-tab [label]="'site_details.tab_label_reviews' | translate" *ngIf="(site.googlePlaceDetails?.reviews?.length || site.tabitReviews?.length) && (appService.googlePlaceDetailsSubject | async).showReviews">
        <div class="reviews-container">
            <div class="review" *ngFor="let tabitReview of (site.tabitReviews?.length ? site.tabitReviews : null) | orderBy:created">
                <div class="author-details layout-row">
                    <div class="author-animation">
                        <!-- <img *ngIf="!site.external" [src]="appService.base('assets/images/svg/tabit-badge.svg')"/> -->
                        <ng-lottie [options]="{path: tabitReview.review.animationPath, autoplay: true, loop: true}"></ng-lottie>
                    </div>
                    <div>{{tabitReview.user.firstName}}</div>
                </div>
                <div class="review-details layout-row">
                    <div class="review-stars" dir="ltr">
                        <mat-icon [svgIcon]="starIcon" *ngFor="let starIcon of utilsService.getRatingStars(tabitReview.review.rating)"></mat-icon>
                    </div>
                    <div class="review-time">({{tabitReview.relative_description_date}})</div>
                </div>
                <div class="review-text" *ngIf="tabitReview.review?.comment?.length">{{tabitReview.review.comment}}</div>
            </div>
            <div class="review" *ngFor="let review of site?.googlePlaceDetails?.reviews">
                <div class="author-details layout-row">
                    <div class="author-photo">
                        <img [src]="review.profile_photo_url" />
                    </div>
                    <div class="author-name">{{review.author_name}}</div>
                </div>
                <div class="review-details layout-row">
                    <div class="review-stars" dir="ltr">
                        <mat-icon [svgIcon]="starIcon" *ngFor="let starIcon of utilsService.getRatingStars(review.rating)">
                        </mat-icon>
                    </div>
                    <div class="review-time">({{review.relative_time_description}})</div>
                </div>
                <div class="review-text">{{review.text}}</div>
            </div>
        </div>
    </mat-tab>

    <mat-tab [label]="'photos' | translate" *ngIf="sitePhotos?.length">
        <div class="photo-gallery">
            <ng-container *ngFor="let sitePhoto of sitePhotos">
                <img [src]="sitePhoto?.photo_url" [alt]="sitePhoto?.alt || 'Site photo'" (click)="photoClicked.emit(sitePhoto.index)" />
            </ng-container>
        </div>
    </mat-tab>

</mat-tab-group>

<ng-template #mainTemplate>
    <p *ngIf="siteDetailsPage?.about">{{ siteDetailsPage.about }}</p>
    <iframe *ngIf="site.siteDetailsPage?.videoLink" width="100%" height="432" [src]="trustedIframeUrl" frameborder="0"
        allow="accelerometer; autoplay; gyroscope; picture-in-picture" allowfullscreen
    ></iframe>
</ng-template>