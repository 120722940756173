


<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <div class="h4">
        {{data.name}}
        <small class="d-block text-base" style="margin-top:2px;">{{data.date | date:'MMM d, y, HH:mm': '': appService.localeId}}</small>
    </div>
    <button aria-label="close" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content style="min-height:300px; min-width:300px; ">
    <block-ui>



    </block-ui>
</div>
