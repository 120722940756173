
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../../_core/SiteListsBase';

import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { ORGANIZATION_BUCKET_TYPES, SearchDetails } from '../../_core/OrganizationBucket';
import { LocationService } from '../../_core/location.service';
import { DialogsService } from '../../_core/dialogs.service';
import { MetaService } from '../../_core/meta.service';
import { EntityService } from '../../_core/entity.service';
import { WebContainerService } from '../web-container.service';
import { ActivatedRoute } from '@angular/router';

declare const $: any;

@Component({
    selector: 'app-web-delivery',
    templateUrl: './web-delivery.component.html',
    styleUrls: ['./web-delivery.component.scss']
})
export class WebDeliveryComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.order) implements OnInit, OnDestroy {

    private baseSubscription: Subscription;
    private webSelectionBarSearchStateSubscription: Subscription;
    private loadMoreSitesSubscription: Subscription;
    private coreSubscription: Subscription;

    public selectedService: SearchDetails['service'] = 'delivery';

    private searchInitiatedByUser: boolean = false;
    public showMoreResultsButton: boolean = false;
    public usingExtendedResults: boolean = false;

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        private locationService: LocationService,
        protected organizationsService: OrganizationsService,
        protected liveAnnouncer: LiveAnnouncer,
        private webContainerService: WebContainerService,
        private dialogsService: DialogsService,
        private metaService: MetaService,
        private activatedRoute: ActivatedRoute,
    ) { super(); }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        let orderSearchType = this.getSearchType();

        this.webSelectionBarSearchStateSubscription = this.webContainerService.webSelectionSearchEvent.subscribe((searching: boolean) => {
            this.searchInitiatedByUser = searching;
        });

        // Subscribing to the loadMoreSites Obsevrable Subject (which decides that once the web-container reaches the bottom, on MOBILE devices, we need to load more sites)
        this.loadMoreSitesSubscription = this.webContainerService.loadMoreSitesObservable.subscribe(loadMoreSites => loadMoreSites ? this.loadMore() : false);

        this.initialize();

        // Subscribe to all of the things which could affect changes:
        this.baseSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location,
            this.activatedRoute.queryParams,
            this.webContainerService.getSharedSearch(orderSearchType),
            this.organizationsService.usingExtendedResults(orderSearchType)
        ]).subscribe(([
            locationInitialized,
            newLocation,
            queryParams,
            searchDetailsFromShared,
            usingExtendedResults
        ]) => {
            // console.log('=== WEB/DELIVERY === News:', 'location:', location, 'queryParams:', queryParams, 'searchDetailsFromShared:', searchDetailsFromShared, 'searchInitiatedByUser:', this.searchInitiatedByUser);
            if (!locationInitialized || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails(searchDetailsFromShared, 'delivery');

            // TODO: Get that out from the appService:
            this.usingExtendedResults = usingExtendedResults;

            this.selectedService = nextSearchDetails.service;

            if (queryParams?.tag) {
                // When query params, the search will managed only by it (on related fields):
                if (nextSearchDetails.tags) delete nextSearchDetails.tags;

                let tagId = queryParams.tag;
                if (tagId) nextSearchDetails.tags = [tagId];
            }

            if (this.orgs?.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, orderSearchType)) {

                this.manageExtendedResultsButton(this.orgs);
                this.searching = false;
                this.manageScroll();

            } else {

                this.newSearch(nextSearchDetails, orgsFromSearch => {
                    this.manageExtendedResultsButton(orgsFromSearch);
                    this.manageScroll();
                });
            }

        });

        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO(this.activatedRoute.pathFromRoot);

    }

    protected manageScroll(): void {

        // TODO: Bring the timeout to here:

        if (this.organizationsService.getLastVisited(ORGANIZATION_BUCKET_TYPES.order)) {
            // Scroll to last visited org:
            this.webContainerService.scrollToLastVisitedOrg(this.organizationsService.getLastVisited(ORGANIZATION_BUCKET_TYPES.order));
        } else {
            // Scrolling the results into View
            this.webContainerService.scrollToResults(this.searchInitiatedByUser);
        }
    }

    public loadMore(): void {
        super.loadMore(orgsFromSearch => {
            this.manageExtendedResultsButton(orgsFromSearch);
        });
    }

    siteClick(siteId: string) {
        // console.log('=== WEB/DELIVERY === selected service:', this.selectedService);
        let site = this.organizationsService.getOrganization(siteId);
        this.organizationsService.setLastVisited(siteId, ORGANIZATION_BUCKET_TYPES.order);

        // If the service is available but also begin at the future - this is then a "pre order" availability:
        // 2020-05-13: Because Tabit Order also shows a similar alert, it has been decided to remove this alert for now
        /*
        if (this.appService.isPreorderAvailableNow(site, this.selectedService)) {
            this.dialogsService.showPreorderDialog({
                serviceAvailableTimestamp: this.appService.getServiceAvailableTimestamp(site, this.selectedService),
            }).subscribe(confirmed => {
                if (confirmed) this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
            }, err => {
                console.error('Error at confirm preorder dialog:', err);
            });
        } else {
            this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
        }
        */
        this.dialogsService.toggleActionFrame(this.selectedService, site, null, this.appService.cordovaPlatform);
    }

    ngOnDestroy() {
        this.baseSubscription.unsubscribe();
        this.webSelectionBarSearchStateSubscription.unsubscribe();
        this.loadMoreSitesSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        this.cleanUp();
    }

    extendedResultsClick() {

        let orderSearchType = this.getSearchType();

        this.organizationsService.setIfUsingExtendedResults(true, orderSearchType);

        let searchDetails: SearchDetails = {
            ...this.organizationsService.getSearchDetails(orderSearchType),
            skip: 0,
            externalDeliveryLink: true,
        };

        this.newSearch(searchDetails, orgsFromSearch => {
            this.manageExtendedResultsButton(orgsFromSearch);
            this.manageScroll();
        });

    }

    private showExtendedResultsButton(active: boolean) {
        this.showMoreResultsButton = active;
    }

    private manageExtendedResultsButton(orgs: any[]) {
        if (this.selectedService === 'delivery' && (!orgs || !orgs.length || orgs.length < this.organizationsService.SEARCH_LIMIT)) {
            if (this.usingExtendedResults) {
                this.showExtendedResultsButton(false);
            } else {
                this.showExtendedResultsButton(true);
            }
        }
    }
}
