import { Injectable, NgZone } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { EntityUtilsService } from '../_core/entity.utils.service';
import { EntityService } from './entity.service';
import { ExternalDialogComponent } from '../dialogs/external-dialog/external-dialog.component';
import { BillDialogComponent } from '../dialogs/bill-dialog/bill-dialog.component';
import { GalleryDialogComponent } from '../dialogs/gallery-dialog/gallery-dialog.component';
import { PreferencesSelectionDialogComponent } from '../dialogs/preferences-selection-dialog/preferences-selection-dialog.component';
import { MenuSelectionDialogComponent } from '../dialogs/menu-selection-dialog/menu-selection-dialog.component';
import { KeypadDialogComponent } from '../dialogs/keypad-dialog/keypad-dialog.component';
import { BottomSheetDialogComponent } from '../dialogs/bottom-sheet-dialog/bottom-sheet-dialog.component';
import { SmsDialogComponent } from '../dialogs/sms-dialog/sms-dialog.component';
import { AddressDialogComponent } from '../dialogs/address-dialog/address-dialog.component';
import { PreorderDialogComponent } from '../dialogs/preorder-dialog/preorder-dialog.component';
import { AccessibilityDialogComponent } from '../dialogs/accessibility-dialog/accessibility-dialog.component';
import { VerifyCodeDialogComponent } from '../sign-in/verify-code-dialog/verify-code-dialog.component';
import { BenefitDetailsComponent } from '../loyalty/benefit-details/benefit-details.component';
import { MultipleChoiceComponent } from '../dialogs/multiple-choice/multiple-choice.component';
import { TabitpayOfferSelectComponent } from '../order/tabit-pay/dialogs/tabitpay-offer-select/tabitpay-offer-select.component';

import { get, concat, filter, isObject } from 'lodash-es';

@Injectable()
export class DialogsService {

    appConfig: any = environment.appConfig;

	constructor(
		public appService: AppService,
		public dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
		private utilsService: EntityUtilsService,
		private entityService: EntityService,
        private ngZone: NgZone,
	) { }

	public toggleActionFrame(action, siteDetails?, event?, isCordova?, link?) {
        if (event) event.stopPropagation();
        let type: string;
        let params: any;

        switch (action) {
            case "marketplace":{
                params = {
                    redirectURL: link,
                }
                type = '_system';
            }
            break;
            case "link":
            case "menu":
                params = {
                    redirectURL: link,
                }
                break;
            case "delivery":
                // if (this.appService.appConfig['isDev']) {
                //     params = { site: siteDetails._id, service: action, isFuture: siteDetails.isFuture ? siteDetails.isFuture : false, isWeb: window['cordova'] ? false : true };
                //     return this.appService.redirect(['/tabit-order'], { queryParams: params });
                // } 

                if (!get(siteDetails, `servicesInDetails.${action}.active`) && !get(siteDetails, `servicesInDetails.${action}.enabled`) && get(siteDetails, `${action}.method`) == 'url' && get(siteDetails, `${action}.methodValue`)) {
                    // We use the External Delivery link ONLY if the delivery service is OFF.
                    params = {
                        redirectURL: action == 'delivery' ? siteDetails.delivery.methodValue : siteDetails.takeaway.methodValue,
                        enableCloseButton: true,
                    }
                    break;
                } else if (window['cordova'] || (!window['cordova'] && this.appService.skin)) {
                    params = { site: siteDetails._id, service: action, isFuture: siteDetails.isFuture ? siteDetails.isFuture : false, isWeb: window['cordova'] ? false : true };
                    // For now, we let the 'back' lead to the main Tabit Order screen (because of several issues where the user is sent "two steps back" in some cases)
                    //return this.appService.redirect(['/tabit-order'], { queryParams: params, replaceUrl: true });
                    return this.appService.redirect(['/tabit-order'], { queryParams: params });
                } else if (!!siteDetails.servicesInDetails.redirectToTo2 && !siteDetails.servicesInDetails.redirectToTo2) {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitOrderURL}?site=${siteDetails._id}&service=delivery&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                } else {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitWebURL}/tabit-order?site=${siteDetails._id}&service=delivery&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                }

            case "takeaway":
                // if (this.appService.appConfig['isDev']) {
                //     params = { site: siteDetails._id, service: 'takeaway', isFuture: siteDetails.isFuture ? siteDetails.isFuture : false, isWeb: window['cordova'] ? false : true };
                //     return this.appService.redirect(['/tabit-order'], { queryParams: params });
                // }

                if (get(siteDetails, 'takeaway.method') == 'url' && get(siteDetails, 'takeaway.methodValue')) {
                    // We use the External Takeaway link ONLY if we have an external method value.
                    params = {
                        redirectURL: siteDetails.takeaway.methodValue,
                        enableCloseButton: true,
                    }
                    break;
                } else if (window['cordova'] || (!window['cordova'] && this.appService.skin)) {
                    params = { site: siteDetails._id, service: 'takeaway', isFuture: siteDetails.isFuture ? siteDetails.isFuture : false, isWeb: window['cordova'] ? false : true };
                    // For now, we let the 'back' lead to the main Tabit Order screen (because of several issues where the user is sent "two steps back" in some cases)
                    //return this.appService.redirect(['/tabit-order'], { queryParams: params, replaceUrl: true });
                    return this.appService.redirect(['/tabit-order'], { queryParams: params });
                } else if (!!siteDetails.servicesInDetails.redirectToTo2 && !siteDetails.servicesInDetails.redirectToTo2) {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitOrderURL}?site=${siteDetails._id}&service=takeaway&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                } else {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitWebURL}/tabit-order?site=${siteDetails._id}&service=takeaway&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                }

            case "eatin":
                if (window['cordova'] || (!window['cordova'] && this.appService.skin)) {
                    params = { site: siteDetails._id, service: 'eatin', isFuture: siteDetails.isFuture ? siteDetails.isFuture : false, isWeb: window['cordova'] ? false : true };
                    // For now, we let the 'back' lead to the main Tabit Order screen (because of several issues where the user is sent "two steps back" in some cases)
                    //return this.appService.redirect(['/tabit-order'], { queryParams: params, replaceUrl: true });
                    return this.appService.redirect(['/tabit-order'], { queryParams: params });
                } else if (!!siteDetails.servicesInDetails.redirectToTo2 && !siteDetails.servicesInDetails.redirectToTo2) {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitOrderURL}?site=${siteDetails._id}&service=eatin&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                } else {
                    type = '_system';
                    params = {
                        redirectURL: `${this.appConfig.tabitWebURL}/tabit-order?site=${siteDetails._id}&service=eatin&channel=TAW${siteDetails.isFuture ? '&isFuture=' + siteDetails.isFuture : ''}`,
                    }
                    break;
                }

			case "bill":
                if(siteDetails?.orderId) {
                    params = {
                        redirectURL: `${this.appConfig.tabitDocumentsURL}/receipt/?o=${siteDetails.organization}&oid=${siteDetails.orderId}&minimal_layout=1`,
                    }
                } else {
                    params = {
                        redirectURL: `${this.appConfig.tabitDocumentsURL}/receipt/?o=${siteDetails.organization}&t=${siteDetails.billId}&minimal_layout=1`,
                    }
                }
                params.enableCloseButton = true;
                break;

			case "book":
				if (get(siteDetails, 'reservation.method') === 'onlineBooking') {
                    return this.appService.redirect([this.appService.getTranslatedRoute('reservation')], { queryParams: {
                        orgId: siteDetails._id,
                        source: this.appService.skin ? this.appService.skin : 'tabit',
                        type: 'future_reservation'
                    } });
                }

                if (get(siteDetails, 'reservation.method') === 'url' && get(siteDetails, 'reservation.methodValue')) {
                    params = {
                        redirectURL: siteDetails.reservation.methodValue,
                        enableCloseButton: true,
                    }
                    break;
                }

                if (get(siteDetails, 'reservation.method') === 'phone' && get(siteDetails, 'reservation.methodValue')) {
                    if (this.appService.isDesktop()) {
                        return this.appService.animDialog({ hideTitle: true, confirmText: siteDetails.reservation.methodValue, animation: this.appService.base('/assets/animations/phone_call.json'), loop:true }).then(approveCall => {
                            //if (approveCall) window.open('tel:' + siteDetails.reservation.methodValue, '_system');
                        });
                    } else {
                        type = window['cordova'] ? '_system' : '_self';
                        params = {
                            redirectURL: `tel:${siteDetails.reservation.methodValue}`,
                        }
                    }
                    break;
                }

			case "rsv":
				params = {
                    redirectURL: `${this.appConfig.tabitGuestURL}/${siteDetails.organization}/${siteDetails._id}?locale=${this.appService.localeId}`,
					enableCloseButton: true,
					closeCallback: this.entityService.getCustomerReservations.bind(this.entityService),
				}
				break;

			case "pay":
                const orgId = siteDetails._id || siteDetails.id;
                let tabitPayUrl = `${this.appService.appConfig.tabitPayURL}?site=${orgId}`;

                if (siteDetails?.oid) tabitPayUrl += `&oid=${siteDetails.oid}`;
                if (siteDetails?.price) tabitPayUrl += `&price=${siteDetails.price}`;
                // Loyalty Tokens across platform
                if (siteDetails?.token) tabitPayUrl += `&token=${siteDetails.token}`;
                if (siteDetails?.refreshToken) tabitPayUrl += `&refreshToken=${siteDetails.refreshToken}`;
                if (siteDetails?.loyaltyToken) tabitPayUrl += `&loyaltyToken=${siteDetails.loyaltyToken}`;
                if (siteDetails?.loyaltyRefreshToken) tabitPayUrl += `&loyaltyRefreshToken=${siteDetails.loyaltyRefreshToken}`;

                params = {
                    redirectURL: tabitPayUrl,
                    enableCloseButton: false
                }

                // Only for Cordova
                if (window['cordova']) {
                    if (this.appService.cordovaPlatform !== 'ios') {
                        window.open(params.redirectURL, '_system');
                    } else {
                        // Only for iOS
                        if (window['cordova']?.InAppBrowser) {
                            window['cordova'].InAppBrowser.open(encodeURI(params.redirectURL), '_system');
                        }
                    }
                    return;
                }
                break;

            case "tpay":
                params = {
                    redirectURL: `${this.appConfig.tabitOrderURL}?hosted=1&oid=${siteDetails.oid}&site=${siteDetails.site}`,
                    enableCloseButton: false
                }
				break;

            case "quickpay":
                this.appService.redirect(['/pay/quick-pay'], { queryParams: { _id: siteDetails._id } });
				return;

            case "site":
                this.appService.redirect(['/home/sites/' + siteDetails.key]);
				return;

            case "navigate":
                type = '_system';
                params = {
                    enableCloseButton: true
                }

                if (this.appService.isIOS) {
                    const data = {
                        options: ['Apple maps', 'Waze']
                    };
                    return this.showNavigationOptionsDialog(data)
                    .then(res => {
                        params.redirectURL = this.handleNavigationRedirect(siteDetails, res);
                        return this.handleActualRedirect(type, params);
                    }).catch(() => {
                        return;
                    });
                } else {
                    params.redirectURL = this.handleNavigationRedirect(siteDetails, 'Waze');
                }

                break;

            case "rsv-navigate":
                type = '_system';
                params = {
                    enableCloseButton: true,
                    redirectURL: siteDetails
                }

                break;

            case "call":
                type = isCordova ? '_system' : '_self';
				params = {
                    redirectURL: `tel:${siteDetails.phone}`,
                }
                break;

            case "issues-feedback":
                params = {
                    redirectURL: link,
                    enableCloseButton: true,
                }
                break;

            case "waitingList":
                    return this.appService.redirect([this.appService.getTranslatedRoute('reservation')], { queryParams: {
                        orgId: siteDetails._id, source: this.appService.skin ? this.appService.skin : 'tabit',
                        type: 'walked_in'
                    } });

            default:
                console.error(`action: '${action}' is not supported by dialogs.service.toggleActionFrame`);
                return;
        }

        this.handleActualRedirect(type, params);
    }

    private handleActualRedirect(type, params) {
        // Google Analytics Cross Domain Tracking Client ID (_ga)
        // References:
        // https://developers.google.com/analytics/devguides/collection/gtagjs/cross-domain
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/linker
        // https://www.simoahava.com/analytics/troubleshooting-cross-domain-tracking-in-google-analytics/
        if (window['ga'] && /http/.test(params.redirectURL)) { // We want the gt addon only for "real" redirect and not phone call
            window['ga'](() => {
                console.debug('=== Google Analytics Cross Domain - Initialized')

                this.appService.googleAnalyticsTracker = window['ga'].getAll()[0];

                if (this.appService.googleAnalyticsTracker) {
                    // Note: we MUST generate the Linker Param here, right before we append it to the destination URL (because it expires after 2 minutes).
                    this.appService.googleAnalayticsLinkerParam = this.appService.googleAnalyticsTracker.get('linkerParam');
                    console.debug('=== Google Analytics Cross Domain Linker Param: ', this.appService.googleAnalayticsLinkerParam);
                }
                if (this.appService.googleAnalayticsLinkerParam) {
                    // Appending the Google Analytics Client ID (_ga) for Cross Domain Tracking
                    let delimeter = '?';
                    if (/\?/.test(params.redirectURL)) {
                        delimeter = '&';
                    }
                    params.redirectURL += delimeter+this.appService.googleAnalayticsLinkerParam;
                    console.debug('=== Google Analytics Cross Domain Destination URL: ', params.redirectURL);
                }
            });
        }

        if (type == '_self') {
            window.location.href = params.redirectURL;
        } else if (type == '_system') {
            if (window['cordova']) {
                window.open(params.redirectURL, type);
            } else {
                // When opening a window on a browser (especially in Safari Mobile), we must not specify the second argument ("name") because otherwise, the second time the user clicks the button - the window.open won't switch to the relevant tab (in Safari Mobile).
                window.open(params.redirectURL);
            }
		// } else if (type == 'iframe') {
		// 	this.openExternalDialog(params, 'full');
		} else if (window['SafariViewController'] && this.appService.cordovaPlatform == 'ios') {
			// For iOS we use the SafariViewController
			this.appService.safariViewController(params.redirectURL, params.closeCallback);
        } else if (window['cordova']) {
            window.open(params.redirectURL, '_system');
        } else {
            // When opening a window on a browser (especially in Safari Mobile), we must not specify the second argument ("name") because otherwise, the second time the user clicks the button - the window.open won't switch to the relevant tab (in Safari Mobile).
            window.open(params.redirectURL);
        }
    }

    private handleNavigationRedirect(siteDetails, navigationOptions) {
        if (!siteDetails || !navigationOptions) return '';
        if (siteDetails.location?.lat && siteDetails.location?.lng) {
            if (navigationOptions == 'Apple maps') return `http://maps.apple.com/?ll=${siteDetails.location.lat},${siteDetails.location.lng}&q=${siteDetails.name}`;
            return `https://waze.com/ul?q=${siteDetails.name}, ${siteDetails.address}&ll=${siteDetails.location.lat}, ${siteDetails.location.lng}&navigate=yes`;
        } else {
            if (navigationOptions == 'Apple maps') return `http://maps.apple.com/?address=1,Infinite+Loop,${encodeURIComponent(siteDetails.address)}`;
            return `https://waze.com/ul?q=${siteDetails.name},${siteDetails.address}`;
        }
    }

    public openExternalDialog(data: any, panelClass?: string): void {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            let dialogRef = this.dialog.open(ExternalDialogComponent, { panelClass, data });

            dialogRef.afterClosed().subscribe(result => {
                if (data.closeCallback) data.closeCallback();
            });
        });
	}

	public openBillDialog(data: object) {
		return new Promise((resolve, reject) => {

            this.toggleActionFrame('bill', data, null);
			return;
            // may be returned
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(BillDialogComponent, {
                    //panelClass: 'big-dialog-panel',
                    data: data
                });
                dialogRef.afterClosed().subscribe(result => {
                    resolve(result);
                });
            });
		})
	}

	public showSiteActionsDialog(site, ev, router?, web?: boolean) {

		let actions = [{
			id: 'goToSite',
			icon: 'home unactive',
			text: 'goto-rest-page'
		}];

        actions = concat(actions, filter(this.appService.serviceActions, (o) => {
            if (o.id === 'order' && site.trainingMode) return false;
            if (o.id === 'book') return this.utilsService.siteCanBook(site);
            return !!site.services[o.id];
        }));

		this.openBottomSheet({ actions: actions }).then(response => {
			if (response) {
				switch (response["id"]) {
					case "goToSite":
						if (router) {
                            this.appService.redirect([web ? '/site' : '/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
						}
						break;
					default:
						this.toggleActionFrame(response['id'], site);
				}
			}
		});
		if (ev) {
			ev.preventDefault();
			if (ev.stopPropagation)ev.stopPropagation();
		}
	}

	public showGalleryDialog(data: object) {
		return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(GalleryDialogComponent, {
                    panelClass: 'fullscreen-no-background-dialog',
                    data
                });
                dialogRef.afterClosed()
                .subscribe(result => {
                    resolve(result);
                });
            });
		})

	}

	public showPreferencesSelectionDialog(data: object) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(PreferencesSelectionDialogComponent, {
                    panelClass: 'rounded-dialog',
                    data: data
                });
                dialogRef.afterClosed()
                .subscribe(result => {
                    resolve(result);
                });
            });
		})
	}

	public showMenuSelectionDialog(data?) {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.dialog.open(MenuSelectionDialogComponent, {
                panelClass: ['rounded-dialog','menu-selection-dialog'],
                data: data
            });
        });
	}

    public showAddressDialog(data?: object): Observable<any> {
        if (!data) data = {};

        const dialogRef = this.dialog.open(AddressDialogComponent, { data });
        return dialogRef.afterClosed();
    }

    public showVerifyCodeDialog(data?: object): Promise<any> {
        if (!data) data = {};

        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(VerifyCodeDialogComponent, {
                width: "300px",
                panelClass: 'rounded-dialog',
                direction: this.appService.direction,
                autoFocus: false,
                data
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result?.IsSuccess) {
                    resolve(result);
                } else {
                    reject(result);
                }
            });
        })
    }

    public showPreorderDialog(data?: object): Observable<any> {
        if (!data) data = {};

        const dialogRef = this.dialog.open(PreorderDialogComponent, { data: data });
        return dialogRef.afterClosed();
    }

	public showKeypadDialog(data: object) {
		return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(KeypadDialogComponent, {
                    data: data,
                    width: '300px',
                    panelClass: 'keypad-dialog',
                });
                dialogRef.afterClosed()
                .subscribe((result: any) => {
                    if (isObject(result) && result['wasChanged'] && (result['amount'] || result['amount'] == 0)) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                });
            });
		})
	}

	public openBottomSheet(data: object) {
		return new Promise((resolve, reject) => {
			const bottomSheetRef = this.bottomSheet.open(BottomSheetDialogComponent, {
                panelClass: 'mat-bottom-sheet-container-medium',
				data,
				disableClose: true
			});
			bottomSheetRef.afterDismissed()
            .subscribe(result => {
				resolve(result);
			});
			let canClose = false;

			bottomSheetRef.afterOpened()
            .subscribe(result => {
				window.setTimeout(() => {
					canClose = true;
				}, 500);
			});

			bottomSheetRef.backdropClick()
            .subscribe(result => {
				if (canClose) {
					bottomSheetRef.dismiss();
				}
			});

		})
    }

    public showSMSDialog(args) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(SmsDialogComponent, {
                    width: "300px",
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    data: args,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result != "") {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                });
            });
        });
    }

    public showAccessibilityDialog() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.dialog.open(AccessibilityDialogComponent, {
                panelClass: ['wl-cards-background-color', 'accessibility-dialog-container'],
                disableClose: true
            });
        });
	}

    public showBenefitDetailsDialog() {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.dialog.open(BenefitDetailsComponent, {
                    panelClass: 'fullscreen-no-background-dialog',
                    direction: this.appService.direction,
                    data: this.appService.benefitDetailsDataSubject.getValue(),
                });
            });
        });
    }

    public showNavigationOptionsDialog(data) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(MultipleChoiceComponent, {
                    width: '300px',
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    data,
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (!['', null].includes(result)) {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                });
            });
        });
    }

    public payByOffersDialog(args?) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { 
                const dialogRef = this.dialog.open(TabitpayOfferSelectComponent, {
                    panelClass: 'fullscreen-background-dialog',
                    data: args || {}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result != "") {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                });
            });
        })
    }

    public showCreditGuardDialog(req) {
        const args: any = this.utilsService.getURLParams({ search: `?${req.paymentUrl.split('?')[1]}` });
        return this.openExternalPayDialog({ redirectURL: req.paymentUrl, provider: 'CreditGuard' }).then(result => {
            const mode = get(result, 'mode');
            switch (mode) {
                case "success":
                    return {
                        transactionId: args.txId,
                        mode
                    };
                case "error":
                    throw({
                        type: 'PAYMENT_VALIDATION_ERROR',
                        message: this.appService.translate("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
                        serverError: {}
                    });
                default:
                    return { mode: 'cancel'};
            };
        });
    }

    public openExternalPayDialog(args) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                const dialogRef = this.dialog.open(ExternalDialogComponent, {
                    width: '340px',
                    maxWidth: '98vw',
                    disableClose: true,
                    panelClass: 'ex-pay-dialog',
                    direction: this.appService.direction,
                    data: args,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    resolve(result);
                });
            });
        });
    }

}