import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash-es';

import { AppService } from '../../app.service';

@UntilDestroy()
@Component({
    selector: 'web-header',
    templateUrl: './web-header.component.html',
    styleUrls: ['./web-header.component.scss']
})
export class WebHeaderComponent implements OnInit, AfterViewInit {
    @Input() webSearchType: string = ''; // booking / order / search
    @Input() host: string = '';

    domain: any;
    bookSelectionBarImageBackground: string;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            this.domain = domain;
            this.bookSelectionBarImageBackground = this.setBackgroundImage(domain);
        });
    }

    ngAfterViewInit() { }

    disableClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    private setBackgroundImage(domain): string {
        if (get(domain, 'dashboardImagePath', null)) return `url(${get(domain, `dashboardImagePath`)}) !important`;
        const imagePath = this.appService.isDesktop() ? 'desktop' : 'mobile' ;
        return `url(${this.appService.base(`assets/images/dashboard-${imagePath}.jpg`)})`;
    }

}
