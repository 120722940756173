



<form #detailsForm="ngForm" style="display:inline;">
    <div class="d-flex flex-column h-100 scroll-y spacer-bottom">
        <div class="pb-3 px-2">
            <tabitbook-selection-bar [go-to-start]="gotoStart" [$storage]="$storage"></tabitbook-selection-bar>
        </div>
        <div *ngIf="($storage.site && $storage.site.intro_localized && $storage.site.intro_localized[appService.localeId])" class="site-promo">
            <div>{{$storage.site.intro_localized[appService.localeId]}}</div>
        </div>
        <div class="standby-reservation-indication" *ngIf="$storage.reservation && $storage.reservation.standby_reservation">
            {{'TGM.form_description_' + ($storage.reservation.pending_approval ? 'pending_approval' : 'standby') | translate}}
        </div>
        <div class="flex-grow-1">
            <div class="accordion-container">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="step === 'start'" (opened)="step = 'start'" hideToggle="true">
                        <div>
                            <div class="font-bold py-4">{{($storage.request_deposit ? 'TGM.DEPOSIT_TITLE' : 'TGM.DETAILS_TITLE') | translate}}</div>

                            <div>
                                <div class="row">
                                    <!-- First Name -->
                                    <div class="col-6" *ngIf="customerDetailsFormFields.first_name.enabled">
                                        <mat-form-field>
                                            <input [(ngModel)]="$storage.bookDetails.customer.first_name" name="first_name" matInput placeholder="{{'first_name' | translate}}" nonEmpty [required]="customerDetailsFormFields.first_name.required">
                                        </mat-form-field>
                                    </div>
                                    <!-- Last Name -->
                                    <div class="col-6" *ngIf="customerDetailsFormFields.last_name.enabled">
                                        <mat-form-field>
                                            <input [(ngModel)]="$storage.bookDetails.customer.last_name" name="last_name" matInput placeholder="{{'last_name' | translate}}" nonEmpty [required]="customerDetailsFormFields.last_name.required">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- Phone -->
                                    <div class="col-6" *ngIf="customerDetailsFormFields.phone.enabled">
                                        <mat-form-field>
                                            <input [(ngModel)]="$storage.bookDetails.customer.phone" name="phone" type="tel" matInput placeholder="{{'mobile' | translate}}" [required]="customerDetailsFormFields.phone.required">
                                        </mat-form-field>
                                    </div>
                                    <!-- Email -->
                                    <div class="col-6" *ngIf="customerDetailsFormFields.email.enabled">
                                        <mat-form-field>
                                            <input [(ngModel)]="$storage.bookDetails.customer.email" name="email" type="email" email matInput placeholder="{{'email' | translate}}" class="ltr-field" [required]="customerDetailsFormFields.email.required">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- Notes / Tags -->
                                <mat-form-field *ngIf="customerDetailsFormFields.reservation_notes.enabled">
                                    <textarea *ngIf="!($storage && $storage.site && $storage.site.tags && $storage.site.tags.length && customerDetailsFormFields && customerDetailsFormFields.reservation_notes && customerDetailsFormFields.reservation_notes.use_tags)" class="reservation-notes-textarea" [(ngModel)]="$storage.bookDetails.notes" name="notes" placeholder="{{'notes' | translate}}" [required]="customerDetailsFormFields.reservation_notes.required" rows="2" matInput></textarea>
                                    <mat-select *ngIf="$storage && $storage.site && $storage.site.tags && $storage.site.tags.length && customerDetailsFormFields && customerDetailsFormFields.reservation_notes && customerDetailsFormFields.reservation_notes.use_tags" class="reservation-notes-select-list" name="tags" placeholder="{{'notes' | translate}}" [(ngModel)]="$storage.bookDetails.tags" [required]="customerDetailsFormFields.reservation_notes.required" multiple>
                                        <mat-option *ngFor="let tag of $storage.site.tags" [value]="tag">{{ tag.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div *ngIf="$storage.request_deposit">
                                <mat-form-field>
                                    <mat-label>{{'credit_card_number' | translate}}</mat-label>
                                    <input [(ngModel)]="$storage.deposit.number" name="pan" matInput type="tel" required pattern="[0-9]*" inputmode="numeric" validCreditCard minlength="8" [ccInfo]="$storage.deposit" #cc="ngModel">
                                    <mat-icon [svgIcon]="$storage.deposit && $storage.deposit.brand || 'cc'" matSuffix></mat-icon>
                                    <mat-error *ngIf="cc.dirty && cc.errors?.creditCard">{{cc.errors.creditCard | translate}}</mat-error>
                                </mat-form-field>

                                <div class="row small-gutters">
                                    <div class="col-5">
                                        <mat-form-field>
                                            <mat-label>{{'expiration' | translate}}</mat-label>
                                            <input [(ngModel)]="$storage.deposit.expiration" name="expiration" matInput placeholder="mm/yy" type="tel"
                                                   pattern="^\d{2}-\d{2}$" maxlength="5" required validCardExpiration ccinfo="ccinfo" class="ltr-field text-center" [ccInfo]="$storage.deposit" #ccexp="ngModel">
                                            <mat-error *ngIf="ccexp.dirty && ccexp.errors?.creditCardExpiration">{{ccexp.errors.creditCardExpiration | translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-7">
                                        <mat-form-field>
                                            <input [(ngModel)]="$storage.deposit.cvv" name="cvv" matInput placeholder="{{'cvv' | translate}}" type="number" required pattern="[0-9]*" inputmode="numeric" class="text-center">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-form-field *ngIf="$storage.depositConfig.id_number && $storage.depositConfig.id_number.enabled">
                                    <input [(ngModel)]="$storage.deposit.id_number" name="id_number" matInput placeholder="{{'id_card' | translate}}" type="tel" pattern="[0-9]*" required="{{$storage.depositConfig.id_number.required}}" inputmode="numeric">
                                </mat-form-field>
                            </div>

                            </div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="text-center h6 p-3">
                <a (click)="bookService.showLicense()">{{'TGM.LICENSE_AGREEMENT' | translate}}</a>
            </div>

        </div>

        <!--START BOTTOM ACTION-->
        <div class="p-3 py-4">
            <button mat-flat-button color="primary" class="lg rounded btn-block" type="submit" (click)="submit(detailsForm, $event)">
                {{'save' | translate}}
            </button>
        </div>
        <!--END BOTTOM ACTION-->
    </div>

</form>



