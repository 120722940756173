<div class="d-flex flex-column h-100 justify-content-between">
    <div></div>
    <div class="text-center">
        <div class="text-center" class="reservation-completed">
            <ng-lottie [options]="{path: checkmarkAnimation, autoplay: true, loop: false}" [height]="200"></ng-lottie>
            <div class="title font-bold mb-3" [ngClass]="{'alert': ($storage.reservation && $storage.reservation.standby_reservation)}">
                {{ title | translate }}
            </div>
        </div>

        <div class="px-2">
            <tabitbook-selection-bar [$storage]="$storage"></tabitbook-selection-bar>
        </div>
    </div>

    <div class="p-3 py-4">
        <button mat-flat-button color="primary" class="lg rounded btn-block" type="button" (click)="close()">
            {{'close' | translate}}
        </button>
    </div>
</div>





