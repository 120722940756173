<div class="header-tab-content-row selection-bar">
    <div class="header-tab-titles" accessible>
        <div class="header-tab-title">
            {{ 'WEB_CONTAINER.deliveries.main_title' | translate }}
        </div>
        <div class="header-tab-subtitle">
            {{ 'WEB_CONTAINER.deliveries.main_subtitle' | translate }}
        </div>
    </div>
    <web-selection-bar webSearchType="order" [customSelectionFields]="webSelectionBarFields"></web-selection-bar>
</div>