import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserReviewsService {
    private reviewsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public reviews = this.reviewsSubject.asObservable();
    
    public appConfig: any = environment.appConfig;
    public reviewReasons = [
        { _id: '5e9db48a774ad93bbf7d9f3e', caption: { 'he-IL': 'user_review_food', 'en-US': 'user_review_food', 'en-AU': 'user_review_food' }, onlySeated: true },
        { _id: '5e9db616774ad93bbf7d9f3f', caption: { 'he-IL': 'user_review_service', 'en-US': 'user_review_service', 'en-AU': 'user_review_service' }, onlySeated: true },
        { _id: '5fbb9fbd0f5a757f13ff403c', caption: { 'he-IL': 'user_review_atmosphere', 'en-US': 'user_review_atmosphere', 'en-AU': 'user_review_atmosphere' }, onlySeated: true },
        { _id: '5e9db621774ad93bbf7d9f40', caption: { 'he-IL': 'user_review_package', 'en-US': 'user_review_package', 'en-AU': 'user_review_package' }, onlySeated: false },
        { _id: '5e9db62c774ad93bbf7d9f41', caption: { 'he-IL': 'user_review_times', 'en-US': 'user_review_times', 'en-AU': 'user_review_times' }, onlySeated: false },
        { _id: '5e9db633774ad93bbf7d9f42', caption: { 'he-IL': 'user_review_order_process', 'en-US': 'user_review_order_process', 'en-AU': 'user_review_order_process' }, onlySeated: false },
    ]

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    public getUserReviews(phone: string | number): Observable<any> {
        let url = `${this.appConfig.tabitBridge}/user-reviews/${phone}`;
        return this.httpClient.get(url, this.appService.appHttpOptions).pipe(map((reviews: any[]) => {
            this.reviewsSubject.next(reviews);
            return reviews;
        }));
    }

    public saveUserReview(reviewData: any) {
        let url = `${this.appConfig.tabitBridge}/user-reviews`;
        return this.httpClient.post(url, { reviewData }, this.appService.appHttpOptions).pipe(map(review => {
            // console.log('*** UserReviewsService *** review saved:', review, 'existing:', this.reviewsSubject.getValue());
            let reviews = [...this.reviewsSubject.getValue(), review];
            this.reviewsSubject.next(reviews);
            return review;
        }));
    }

}
