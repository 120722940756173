import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../app.service';

@Component({
	selector: 'app-keypad-dialog',
	templateUrl: './keypad-dialog.component.html',
	styleUrls: ['./keypad-dialog.component.scss']
})
export class KeypadDialogComponent {
	keys: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'back'];
	wasFirstClick: boolean = false;
	response: any = {};

	constructor(
		public dialogRef: MatDialogRef<KeypadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public appService: AppService
	) {
		this.response = this.data.response || {}
		if (!this.data.enableDecimals) {
			this.keys[9] = null;
		}
	}


	setAmaountKey(key) {
		let newVal = this.response.amountText;
		let hasDecimal = newVal.indexOf(".") != -1;
		let precision = hasDecimal && newVal.split(".")[1].length || 0;

		switch (key) {
			case 'back':
				if (!newVal.length) return;
				newVal = newVal.slice(0, -1);
				break;
			case '.':
				if (hasDecimal) return;
				newVal += key;
				break;
			default:
				if (!this.wasFirstClick) newVal = key + "";
				else {
					if (precision == 2) return;
					newVal += key;
				}
		}
		this.wasFirstClick = true;
		this.response.amountText = newVal;
		delete this.response.invalid;
		if (Number(newVal) > 9999999) {
			this.response.amount = this.data.maxVal;
			this.response.amountText = this.data.maxVal + "";
		} else if (Number(newVal) > this.data.maxVal) {
			this.response.invalid = true;
		} else {
			this.response.amount = Number(newVal);
		}
		this.response.wasChanged = true;
	}

}
