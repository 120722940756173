<div @clubCardAnimation class="club-card-container wl-cards-background-color" [class.animate]="index <= 3">
    <div class="club-card">
        <div class="card-container">
            <div class="_title">{{club?.clubName}}</div>
            <div class="card-img" class="wl-cards-background-color" [style.background-image]="appService.base('/assets/images/benefit-background.png') | safeStyle"></div>
            <div class="benefits-container">
                <benefit-card [ngStyle]="loyaltyService.setBorderByType('Points')" [club]="club" [points]="club?.points[0]?.points" [benefit]="pointsBenefit" *ngIf="club?.points[0]?.points > 0"></benefit-card>
                <benefit-card [ngStyle]="loyaltyService.setBorderByType('PointsPrePaid')" [club]="club" [prePaidPoints]="club?.points[0]?.prePaid" [benefit]="pointsBenefit" *ngIf="loyaltyService.checkPrepaidFlow(club?.points[0]?.prePaid)"></benefit-card>
                <benefit-card [ngStyle]="loyaltyService.setBorderByType(benefit.programBenefitTypeAlias)" *ngFor="let benefit of benefits" [club]="club" [benefit]="benefit"></benefit-card>
            </div>
        </div>
    </div>
</div>