export const bookingStrings = {
	"he-IL": {
		"rsv_management": {
			"guests": "אורחים",
			"description": {
				"future_reservation_created": "ההזמנה שלכם נשמרה אצלנו בהצלחה.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
				"future_reservation_standby_created": "בקשתך לשמירת מקום התקבלה אצלנו בהצלחה, ואנו נחזור אליך במידה ויתפנה מקום.",
				"future_reservation_saved": "ההזמנה שלכם נשמרה אצלנו בהצלחה.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
				"future_reservation_reminded": "אנא אשרו את הזמנתכם.\nשולחנכם ישמר למשך 15 דקות ממועד ההזמנה.\nנשמח לראותכם!",
				"future_reservation_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!",
				"future_reservation_checked_in": "ההגעה שלכם נקלטה. נודיע לכם כשהשולחן יהיה מוכן.",
				"future_reservation_deleted": "ההזמנה שלכם בוטלה.",
				"future_reservation_verified": "אישרתם את הגעתכם. תודה!",
				"future_reservation_will_be_late": "תודה על העדכון שתגיעו באיחור",
				"future_reservation_undo_late": "תודה על העדכון",
				"walked_in_created": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
				"walked_in_saved": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
				"walked_in_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!",
				"called_in_created": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
				"called_in_saved": "הנכם רשומים אצלנו בתור. נודיע לכם ברגע שהשולחן מוכן.",
				"called_in_notified": "השולחן שלכם מוכן. אתם מוזמנים להכנס!"
			},
			"buttons": {
				"confirm_label": "אשר הגעה",
				"cancel_label": "בטל הזמנה",
				"will_be_late_label": "אנו נאחר",
				"undo_late_label": "נגיע בזמן"
			},
			"cancel_reservation_dialog": {
				"title": "אישור מחיקה",
				"body": "הפעולה תמחוק את ההזמנה. האם אתם בטוחים?",
				"confirm": "אישור",
				"cancel": "ביטול"
			},
			"failure_reason": {
				"reservation_not_found": "לא נמצאה הזמנה",
				"reservation_in_progress": "ההזמנה יושבת",
				"organization_not_found": "לא נמצא אירגון",
				"feedback_module_disabled": "מצטערים, מערכת המשובים מושבתת עבור המסעדה הזאת"
			},
			"place_in_queue": "מיקומכם ברשימה"
		},
		"splash_screen": {
			"failure_dialog": {
				"title": "משהו לא עבד :(",
				"content": "המערכת נתקלה בבעיה לא צפויה.\n{{error_message}}.",
				"ok_button": "סגור"
			}
		},
		"booking": {
			"button_label": {
				"called_in": "הוספה לתור",
				"future_reservation": "הזמן מקום במסעדה"
			},
			"search": {
				"person": "אורח אחד",
				"people": "{{value}} אורחים",
				"larger_party": "{{value}}+ אורחים",
				"minutes": "{{value}} דקות",
				"no_results": "הממ... נראה שאין שולחן פנוי בשעה זו.\nאנא בחרו מועד אחר או פשוט התקשרו למסעדה.",
				"results_info": "הממ... בשעה זו המסעדה בתפוסה מלאה - אבל הנה כמה אפשרויות נוספות עבורכם!",
				"call_restaurant_large_party": "היי! נראה שאתם קבוצה ממש גדולה - אנא צרו עמנו קשר בטלפון ונשמח לשמור לכם מקום",
				"call_restaurant_error": "לצערנו המסעדה בתפוסה מלאה - אנא התקשרו אלינו",
				"form_description_future_reservation": "לשמירת שולחן אנא בחרו את כמות האורחים, תאריך ושעה הרצויים.",
				"form_description_called_in": "אנא בחרו את כמות האורחים ותוך כמה זמן תגיעו.",
				"today": "היום",
				"first_available": "ללא העדפה",
				"choose_preference": "בחרו איזור",
				"inside": "בפנים",
				"outside": "בחוץ",
				"vip": "VIP",
				"bar": "בר",
				"gallery": "גלריה",
				"balcony": "מרפסת",
				"smoking": "מעשנים",
				"private_room": "חדר פרטי",
				"patio": "פטיו",
				"tv_view": "פונה למסך",
				"stage": "במה",
				"with_stroller": "עם עגלה",
				"with_power": "עם חשמל",
				"standby_reservation_description": "אינני מעוניין באף אחת מהאפשרויות הנ''ל, אנא הוסיפו אותי לרשימת ההמתנה",
				"field_label": {
					"seats_count": "מספר אורחים",
					"date": "תאריך",
					"time": "שעה",
					"preference": "העדפה",
					"arriving_within": "הגעה תוך"
				},
				"button_label": {
					"search": "מצאו לי שולחן",
					"standby_reservation": "הוסף אותי לרשימת ההמתנה",
					"phone": "התקשרו אלינו"
				}
			},
			"details": {
				"description": "להשלמת התהליך אנא מלאו את הפרטים הבאים.",
				"future_reservation_info": "שימו לב, השולחן נשמר עבורכם ל-{{value}} דקות הקרובות",
				"future_standby_reservation_info": "אנא מלאו את הפרטים הבאים ואנו נשלח לכם הודעה כאשר יתפנה מקום.",
				"called_in_info": "הוספנו אתכם לתור.\nאנא הגיעו למסעדה ב-{{minutes}} דקות הקרובות.",
				"disclaimer_description": "ע\"י לחיצה על \"שמור\" הנכם מסכימים לתנאי השימוש ומדיניות הפרטיות. לפרטים לחצו כאן ⟩",
				"button_label": {
					"confirm": "שמירה",
					"cancel": "ביטול"
				},
				"field_label": {
					"first_name": "שם פרטי",
					"last_name": "שם משפחה",
					"phone": "טלפון",
					"email": "אימייל",
					"notes": "הערות"
				},
				"field_error": {
					"phone": "מספר שגוי"
				}
			},
			"modal": {
				"confirmation": {
					"disclaimer_title": "תנאי שימוש ומדיניות הפרטיות",
					"time_limit_title": "שימו לב",
					"time_limit_body": "ההזמנה שלכם תהיה מוגבלת בזמן עד השעה {{reserved_until}} ({{duration}} בלבד).\nהאם תרצו להמשיך?",
					"queue_addition_title": "הוספה לתור",
					"queue_addition_body": "האם תרצו שנוסיף אתכם לתור?"
				},
				"alert": {
					"unsuccessful_attempt_to_reserve_title": "אופס...",
					"unsuccessful_attempt_to_reserve_body": "נראה שאין לכם כרגע חיבור לאינטרנט.\nאנא התחברו ונסו שנית.",
					"reservation_not_found_tilte": "אופס...",
					"reservation_not_found_body": "עברו מעל {{minutes}} דקות מאז ששמרנו עבורכם את ההזמנה ולכן תוקף ההזמנה שלכם פג. אנא הזינו אותה מחדש.",
					"fill_mandatory_fields_title": "שדות חובה",
					"fill_mandatory_fields_body": "אנא מלא את כל שדות החובה",
					"online_booking_blocked_title": "הזמנה מקוונת אינה פעילה",
					"online_booking_blocked_body": "לצערנו הזמנה מקוונת אינה פעילה למסעדה זו"
				},
				"confirm": "אישור",
				"cancel": "ביטול",
				"retry": "נסה שוב"
			},
			"user_abandon_warning": "שימו לב - אתם עוזביםא תה עמוד והשינויים שביצעתם לא ישמרו",
			"online_booking_blocked_description": "בשלב זה לא ניתן לבצע הזמנות מקוונות. אנא צרו עמנו קשר בטלפון ע\"י לחיצה על כפתור הטלפון המופיע למטה."
		},
		"reservation_details": {
			"date_label": "יום {{value}}",
			"time_label": "בשעה",
			"people": "{{value}}"
		},
		"feedback": {
            "feedback_form":"טופס משוב",
			"possible_answers":"תשובות אפשריות",
			"mandatory_questions":"שאלת חובה",
			"guest": "אורח",
			"description": "שלום {{name}},\nנשמח אם תענו על השאלות הבאות",
			"conclusion": {
				"title": "תודה על המשוב!",
				"satisfied_customer_sub_title": "נשמח לראותכם שוב בקרוב",
				"unsatisfied_customer_sub_title": "מתנצלים על אי-הנעימות.\nפנייתך הועברה למנהל המסעדה, ונמשיך לעשות כמיטב יכולתנו לשפר את חוויתכם."
			},
			"button_label": {
				"confirm": "שלח משוב",
				"poor": "לא טוב",
				"fair": "כמעט טוב",
				"good": "טוב",
				"very_good": "טוב מאוד",
				"excellent": "מצוין"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "תשובה חסרה",
					"fill_mandatory_fields_body": "אנא ענו על כל השאלות"
				},
				"confirm": "אישור"
			}
		},
		"deposit": {
			"description": "על מנת לסיים את תהליך שמירת ההזמנה - אנא מלאו את פרטי האשראי לצורך פיקדון.",
			"card_number": "מספר כרטיס",
			"cvv": "CVV",
			"year": "שנה",
			"month": "חודש",
			"id_number": "ת.ז.",
			"zip_code": "מיקוד",
			"date_passed": "התאריך עבר",
			"invalid_cc_number": "מספר כרטיס שגוי",
			"invalid_id_number": "מספר ת.ז. שגוי",
			"disclaimer_description": "ע\"י לחיצה על \"שמור\" הנכם מסכימים לתנאי השימוש ומדיניות הפרטיות. לפרטים לחצו כאן ⟩",
			"button_label": {
				"save": "שמירה",
				"cancel": "בטל הזמנה"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "שדות חובה",
					"fill_mandatory_fields_body": "אנא מלא את כל שדות החובה",
					"disclaimer_title": "תנאי שימוש ומדיניות הפרטיות",
					"disclaimer_body": "פרטי האשראי מוצפנים ונשמרים בצורה מאובטחת בהתאם לתקנים הבינלאומיים של חברות האשראי.",
				},
				"confirm": "אישור"
			}
		}
	},
	"en-US": {
		"rsv_management": {
			"guests": "Guests",
			"description": {
				"future_reservation_saved": "Your reservation has been booked successfully.",
				"future_reservation_created": "Your reservation has been booked successfully.",
				"future_reservation_reminded": "Please confirm your reservation.",
				"future_reservation_notified": "Your table is ready. You are invited to come in.",
				"future_reservation_checked_in": "Your are now checked-in. We will notify you as soon as your table is ready.",
				"future_reservation_deleted": "Your reservation has been cancelled.",
				"future_reservation_verified": "Your reservation has been verified.",
				"future_reservation_standby_created": "Your request has been submitted successfully.",
				"future_reservation_will_be_late": "Thank you for letting us know that you will be late",
				"future_reservation_undo_late": "Thank you for the update",
				"walked_in_saved": "You are now in-line and we will notify you as soon as your table is ready.",
				"walked_in_created": "You are now in-line and we will notify you as soon as your table is ready.",
				"walked_in_notified": "Your table is ready. You are invited to come in.",
				"called_in_saved": "You are now in-line and we will notify you as soon as your table is ready.",
				"called_in_created": "You are now in-line and we will notify you as soon as your table is ready.",
				"called_in_notified": "Your table is ready. You are invited to come in."
			},
			"buttons": {
				"confirm_label": "Confirm",
				"cancel_label": "Cancel",
				"will_be_late_label": "We’ll be Late",
				"undo_late_label": "We'll arrive on time"
			},
			"cancel_reservation_dialog": {
				"title": "Delete Confirmation",
				"body": "You are about to delete this reservation. Are you sure?",
				"confirm": "Confirm",
				"cancel": "Keep"
			},
			"failure_reason": {
				"reservation_not_found": "Reservation not found",
				"reservation_in_progress": "Reservation has been seated",
				"organization_not_found": "Organization not found",
				"feedback_module_disabled": "Sorry, but feedbacks are disabled for this restaurant"
			},
			"place_in_queue": "Your No. in Queue"
		},
		"splash_screen": {
			"failure_dialog": {
				"title": "Something went wrong :(",
				"content": "An unexpected error occured.\n{{error_message}}",
				"ok_button": "Close"
			}
		},
		"booking": {
			"button_label": {
				"called_in": "Add me to the queue",
				"future_reservation": "Reserve a table"
			},
			"search": {
				"person": "{{value}} Guest",
				"people": "{{value}} Guests",
				"larger_party": "{{value}}+ Guests",
				"minutes": "{{value}} Minutes",
				"no_results": "Hmm... It appears we don't have any available table at this time.\nPlease choose an alternative date or call the restaurant.",
				"results_info": "No exact match, showing closest results:",
				"call_restaurant_large_party": "For large party reservations please call the restaurant",
				"call_restaurant_error": "An Error Has Occured - Please Call the Restaurant",
				"form_description_future_reservation": "To reserve a table simply choose the number of guests, date and time.",
				"form_description_called_in": "Please choose the number of guests and arrival time.",
				"today": "Today",
				"first_available": "No Preference",
				"choose_preference": "Choose",
				"inside": "Inside",
				"outside": "Outside",
				"vip": "VIP",
				"bar": "Bar",
				"gallery": "Gallery",
				"balcony": "Balcony",
				"smoking": "Smoking",
				"private_room": "Private Room",
				"patio": "Patio",
				"tv_view": "TV View",
				"stage": "Stage",
				"with_stroller": "With Stroller",
				"with_power": "With Power",
				"standby_reservation_description": "I don't like any of the suggested options, please add me to the waiting list",
				"field_label": {
					"seats_count": "Party Size",
					"date": "Date",
					"time": "Time",
					"preference": "Preference",
					"arriving_within": "Arriving Within"
				},
				"button_label": {
					"search": "Search",
					"standby_reservation": "Add Me To The Waiting List",
					"phone": "Call Us"
				}
			},
			"details": {
				"description": "To complete the reservation, please fill-in the following details.",
				"future_reservation_info": "The table is currently reserved for you for the next {{value}} minutes",
				"future_standby_reservation_info": "Leave us your contact information and we'll get back to you as soon as a table becomes available",
				"called_in_info": "You've been added to the waiting list\nPlease come to the restaurant in the next {{minutes}} minutes",
				"disclaimer_description": "By clicking the \"Submit\" button you agree to our Terms and Conditions and Privacy Policy. Click here for details ⟩",
				"button_label": {
					"confirm": "Submit",
					"cancel": "Cancel"
				},
				"field_label": {
					"first_name": "First Name",
					"last_name": "Last Name",
					"phone": "Phone",
					"email": "Email",
					"notes": "Notes"
				},
				"field_error": {
					"phone": "Invalid Number"
				}
			},
			"modal": {
				"confirmation": {
					"disclaimer_title": "Privacy Policy and Terms of Use",
					"time_limit_title": "Attention",
					"time_limit_body": "Please note that your reservation will be limited until {{reserved_until}} ({{duration}}).\nWould you like to proceed?",
					"queue_addition_title": "Waiting Line",
					"queue_addition_body": "Would you like to be added to the waiting line?"
				},
				"alert": {
					"unsuccessful_attempt_to_reserve_title": "Attention",
					"unsuccessful_attempt_to_reserve_body": "An Unexpected Error Occurred",
					"reservation_not_found_tilte": "Oops...",
					"reservation_not_found_body": "More than {{minutes}} minutes have passed since your reservation was created and it has expired. Please fill a new reservation",
					"fill_mandatory_fields_title": "Required Fields",
					"fill_mandatory_fields_body": "Please fill in all required fields",
					"online_booking_blocked_title": "Online Reservations Unavailable",
					"online_booking_blocked_body": "We're sorry, but online Reservations is currently unavailable."
				},
				"confirm": "Confirm",
				"cancel": "Cancel",
				"retry": "Retry"
			},
			"user_abandon_warning": "Please note - you are about to leave the page - the changes you've made will be lost",
			"online_booking_blocked_description": "Online Reservations is currently unavailable. Please call the restaurant."
		},
		"reservation_details": {
			"date_label": "On {{value}}",
			"time_label": "At",
			"people": "{{value}}"
		},
		"feedback": {
			"feedback_form":"Feedback Form",
			"possible_answers":"Possible Answers",
			"mandatory_questions":"Mandatory Questions",
			"guest": "Guest",
			"description": "Hello {{name}},\nWe would appreciate your feedback",
			"conclusion": {
				"title": "Thank you for your feedback!",
				"satisfied_customer_sub_title": "We're looking forward to seeing you again soon",
				"unsatisfied_customer_sub_title": "Sorry for the inconvenience.\nYour feedback has been sent to the restaurant manager, and we will continue to do our best to improve your experience."
			},
			"button_label": {
				"confirm": "Send Feedback",
				"poor": "Poor",
				"fair": "Fair",
				"good": "Good",
				"very_good": "Very Good",
				"excellent": "Excellent"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "Answer Missing",
					"fill_mandatory_fields_body": "Please answer all the questions"
				},
				"confirm": "Confirm"
			}
		},
		"deposit": {
			"title": "Credit Card Details",
			"card_number": "Card Number",
			"cvv": "CVV",
			"year": "Year",
			"month": "Month",
			"id_number": "ID Number",
			"zip_code": "ZIP Code",
			"date_passed": "Invalid Date",
			"invalid_cc_number": "Invalid Card Number",
			"invalid_id_number": "Invalid ID Number",
			"disclaimer_description": "By clicking the \"Submit\" button you agree to our Terms and Conditions and Privacy Policy. Click here for details ⟩",
			"button_label": {
				"save": "Save",
				"cancel": "Cancel Reservation"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "Required Fields",
					"fill_mandatory_fields_body": "Please fill in all required fields",
					"disclaimer_title": "Terms and Conditions and Privacy Policy",
					"disclaimer_body": "The Credit Card details are encrypted and safely secured per the international guidelines of the Credit Card companies."
				},
				"confirm": "Confirm"
			}
		}
	},
    "en-AU": {
		"rsv_management": {
			"guests": "Guests",
			"description": {
				"future_reservation_saved": "Your reservation has been booked successfully.",
				"future_reservation_created": "Your reservation has been booked successfully.",
				"future_reservation_reminded": "Please confirm your reservation.",
				"future_reservation_notified": "Your table is ready. You are invited to come in.",
				"future_reservation_checked_in": "Your are now checked-in. We will notify you as soon as your table is ready.",
				"future_reservation_deleted": "Your reservation has been cancelled.",
				"future_reservation_verified": "Your reservation has been verified.",
				"future_reservation_standby_created": "Your request has been submitted successfully.",
				"future_reservation_will_be_late": "Thank you for letting us know that you will be late",
				"future_reservation_undo_late": "Thank you for the update",
				"walked_in_saved": "You are now in-line and we will notify you as soon as your table is ready.",
				"walked_in_created": "You are now in-line and we will notify you as soon as your table is ready.",
				"walked_in_notified": "Your table is ready. You are invited to come in.",
				"called_in_saved": "You are now in-line and we will notify you as soon as your table is ready.",
				"called_in_created": "You are now in-line and we will notify you as soon as your table is ready.",
				"called_in_notified": "Your table is ready. You are invited to come in."
			},
			"buttons": {
				"confirm_label": "Confirm",
				"cancel_label": "Cancel",
				"will_be_late_label": "We’ll be Late",
				"undo_late_label": "We'll arrive on time"
			},
			"cancel_reservation_dialog": {
				"title": "Delete Confirmation",
				"body": "You are about to delete this reservation. Are you sure?",
				"confirm": "Confirm",
				"cancel": "Keep"
			},
			"failure_reason": {
				"reservation_not_found": "Reservation not found",
				"reservation_in_progress": "Reservation has been seated",
				"organization_not_found": "Organization not found",
				"feedback_module_disabled": "Sorry, but feedbacks are disabled for this restaurant"
			},
			"place_in_queue": "Your No. in Queue"
		},
		"splash_screen": {
			"failure_dialog": {
				"title": "Something went wrong :(",
				"content": "An unexpected error occured.\n{{error_message}}",
				"ok_button": "Close"
			}
		},
		"booking": {
			"button_label": {
				"called_in": "Add me to the queue",
				"future_reservation": "Reserve a table"
			},
			"search": {
				"person": "{{value}} Guest",
				"people": "{{value}} Guests",
				"larger_party": "{{value}}+ Guests",
				"minutes": "{{value}} Minutes",
				"no_results": "Hmm... It appears we don't have any available table at this time.\nPlease choose an alternative date or call the restaurant.",
				"results_info": "No exact match, showing closest results:",
				"call_restaurant_large_party": "For large party reservations please call the restaurant",
				"call_restaurant_error": "An Error Has Occured - Please Call the Restaurant",
				"form_description_future_reservation": "To reserve a table simply choose the number of guests, date and time.",
				"form_description_called_in": "Please choose the number of guests and arrival time.",
				"today": "Today",
				"first_available": "No Preference",
				"choose_preference": "Choose",
				"inside": "Inside",
				"outside": "Outside",
				"vip": "VIP",
				"bar": "Bar",
				"gallery": "Gallery",
				"balcony": "Balcony",
				"smoking": "Smoking",
				"private_room": "Private Room",
				"patio": "Patio",
				"tv_view": "TV View",
				"stage": "Stage",
				"with_stroller": "With Stroller",
				"with_power": "With Power",
				"standby_reservation_description": "I don't like any of the suggested options, please add me to the waiting list",
				"field_label": {
					"seats_count": "Party Size",
					"date": "Date",
					"time": "Time",
					"preference": "Preference",
					"arriving_within": "Arriving Within"
				},
				"button_label": {
					"search": "Search",
					"standby_reservation": "Add Me To The Waiting List",
					"phone": "Call Us"
				}
			},
			"details": {
				"description": "To complete the reservation, please fill-in the following details.",
				"future_reservation_info": "The table is currently reserved for you for the next {{value}} minutes",
				"future_standby_reservation_info": "Leave us your contact information and we'll get back to you as soon as a table becomes available",
				"called_in_info": "You've been added to the waiting list\nPlease come to the restaurant in the next {{minutes}} minutes",
				"disclaimer_description": "By clicking the \"Submit\" button you agree to our Terms and Conditions and Privacy Policy. Click here for details ⟩",
				"button_label": {
					"confirm": "Submit",
					"cancel": "Cancel"
				},
				"field_label": {
					"first_name": "First Name",
					"last_name": "Last Name",
					"phone": "Phone",
					"email": "Email",
					"notes": "Notes"
				},
				"field_error": {
					"phone": "Invalid Number"
				}
			},
			"modal": {
				"confirmation": {
					"disclaimer_title": "Privacy Policy and Terms of Use",
					"time_limit_title": "Attention",
					"time_limit_body": "Please note that your reservation will be limited until {{reserved_until}} ({{duration}}).\nWould you like to proceed?",
					"queue_addition_title": "Waiting Line",
					"queue_addition_body": "Would you like to be added to the waiting line?"
				},
				"alert": {
					"unsuccessful_attempt_to_reserve_title": "Attention",
					"unsuccessful_attempt_to_reserve_body": "An Unexpected Error Occurred",
					"reservation_not_found_tilte": "Oops...",
					"reservation_not_found_body": "More than {{minutes}} minutes have passed since your reservation was created and it has expired. Please fill a new reservation",
					"fill_mandatory_fields_title": "Required Fields",
					"fill_mandatory_fields_body": "Please fill in all required fields",
					"online_booking_blocked_title": "Online Reservations Unavailable",
					"online_booking_blocked_body": "We're sorry, but online Reservations is currently unavailable."
				},
				"confirm": "Confirm",
				"cancel": "Cancel",
				"retry": "Retry"
			},
			"user_abandon_warning": "Please note - you are about to leave the page - the changes you've made will be lost",
			"online_booking_blocked_description": "Online Reservations is currently unavailable. Please call the restaurant."
		},
		"reservation_details": {
			"date_label": "On {{value}}",
			"time_label": "At",
			"people": "{{value}}"
		},
		"feedback": {
			"feedback_form":"Feedback Form",
			"possible_answers":"Possible Answers",
			"mandatory_questions":"Mandatory Questions",
			"guest": "Guest",
			"description": "Hello {{name}},\nWe would appreciate your feedback",
			"conclusion": {
				"title": "Thank you for your feedback!",
				"satisfied_customer_sub_title": "We're looking forward to seeing you again soon",
				"unsatisfied_customer_sub_title": "Sorry for the inconvenience.\nYour feedback has been sent to the restaurant manager, and we will continue to do our best to improve your experience."
			},
			"button_label": {
				"confirm": "Send Feedback",
				"poor": "Poor",
				"fair": "Fair",
				"good": "Good",
				"very_good": "Very Good",
				"excellent": "Excellent"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "Answer Missing",
					"fill_mandatory_fields_body": "Please answer all the questions"
				},
				"confirm": "Confirm"
			},
		},
		"deposit": {
			"title": "Credit Card Details",
			"card_number": "Card Number",
			"cvv": "CVV",
			"year": "Year",
			"month": "Month",
			"id_number": "ID Number",
			"zip_code": "ZIP Code",
			"date_passed": "Invalid Date",
			"invalid_cc_number": "Invalid Card Number",
			"invalid_id_number": "Invalid ID Number",
			"disclaimer_description": "By clicking the \"Submit\" button you agree to our Terms and Conditions and Privacy Policy. Click here for details ⟩",
			"button_label": {
				"save": "Save",
				"cancel": "Cancel Reservation"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "Required Fields",
					"fill_mandatory_fields_body": "Please fill in all required fields",
					"disclaimer_title": "Terms and Conditions and Privacy Policy",
					"disclaimer_body": "The Credit Card details are encrypted and safely secured per the international guidelines of the Credit Card companies.",
				},
				"confirm": "Confirm"
			}
		}
	},
	"ar-IL": {
		"rsv_management": {
			"guests": "ضيوف",
			"description": {
				"future_reservation_created": "تم حفظ طلبيتكم لدينا بنجاح.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
				"future_reservation_standby_created": "طلبك بحجز المكان تم استلامها لدينا بنجاح، وسنعاود الاتصال بك في حال أصبح هناك مكان فارغ.",
				"future_reservation_saved": "تم حفظ طلبيتكم لدينا بنجاح.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
				"future_reservation_reminded": "يرجى تأكيد طلبيتكم.\nسيتم حفظ طاولتكم لمدة 15 دقيقة من موعد الطلبية.\nسيسعدنا لقاؤكم!",
				"future_reservation_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!",
				"future_reservation_checked_in": "تم استيعاب وصولكم. سنبلغكم عندما تكون الطاولة جاهزة.",
				"future_reservation_deleted": "تم إلغاء طلبيتكم.",
				"future_reservation_verified": "لقد أكدتم وصولكم. شكرًا!",
				"future_reservation_will_be_late": "شكرًا على إبلاغنا بوصولكم متأخرين",
				"future_reservation_undo_late": "شكرًا على إبلاغنا",
				"walked_in_created": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
				"walked_in_saved": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
				"walked_in_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!",
				"called_in_created": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
				"called_in_saved": "إنكم مسجلون لدينا في الدور. سنخبركم بمجرد أن تصبح الطاولة جاهزة.",
				"called_in_notified": "طاولتكم جاهزة. أنت مدعوون للدخول!"
			},
			"buttons": {
				"confirm_label": "يرجى تأكيد الوصول",
				"cancel_label": "يرجى إلغاء الطلبية",
				"will_be_late_label": "سنكون متأخرين",
				"undo_late_label": "سنصل في الوقت المحدد"
			},
			"cancel_reservation_dialog": {
				"title": "تاكيد الحذف",
				"body": "العملية ستحذف الطلبية. هل أنتم متأكدون؟",
				"confirm": "التأكيد",
				"cancel": "الإلغاء"
			},
			"failure_reason": {
				"reservation_not_found": "لم يتم العثور على طلبية",
				"reservation_in_progress": "الطلبية معلقة",
				"organization_not_found": "لم يتم العثور على منظمة",
				"feedback_module_disabled": "للأسف نظام التعليقات معطل بالنسبة لهذا المطعم"
			},
			"place_in_queue": "موقعكم في القائمة"
		},
		"splash_screen": {
			"failure_dialog": {
				"title": "حدث خلل ما :(",
				"content": "النظام واجه مشكلة غير متوقعة.\n{{error_message}}.",
				"ok_button": "مغلق"
			}
		},
		"booking": {
			"button_label": {
				"called_in": "الإضافة إلى الدور",
				"future_reservation": "احجز مكانًا في المطعم"
			},
			"search": {
				"person": "ضيف واحد",
				"people": "{{value}} ضيوف",
				"larger_party": "{{value}}+ ضيوف",
				"minutes": "{{value}} دقائق",
				"no_results": "همم... يبدو أنه لا توجد هناك طاولة فارغة في هذا الوقت.\nيرجى تحديد موعد آخر أو ببساطة اتصلوا بالمطعم.",
				"results_info": "همم... في هذا الوقت المطعم محجوز بالكامل - ولكن فيما يلي بعض الخيارات الأخرى لكم!",
				"call_restaurant_large_party": "مرحبًا! يبدو أنكم مجموعة كبيرة للغاية - يرجى الاتصال بنا على الهاتف وسنكون سعداء لحجز مكان لكم.",
				"call_restaurant_error": "للأسف المطعم ممتلئ - يرجى الاتصال بنا",
				"form_description_future_reservation": "لحجز طاولة، يرجى تحديد عدد الضيوف والتاريخ والوقت المطلوبة.",
				"form_description_called_in": "يرجى تحديد عدد الضيوف والفترة الزمنية التي ستصلون خلالها.",
				"today": "اليوم",
				"first_available": "بدون تفضيل",
				"choose_preference": "حددوا المنطقة",
				"inside": "في الداخل",
				"outside": "في الخارج",
				"vip": "VIP",
				"bar": "بار",
				"gallery": "غاليريا",
				"balcony": "شرفة",
				"smoking": "مدخنون",
				"private_room": "غرفة خاصة",
				"patio": "الفناء",
				"tv_view": "التوجه للشاشة",
				"stage": "المنصة",
				"with_stroller": "مع عربة",
				"with_power": "مع الكهرباء",
				"standby_reservation_description": "لست معنيًا بأحد الخيارات المذكورة أعلاه، يرجى إضافتي إلى قائمة الانتظار",
				"field_label": {
					"seats_count": "عدد الضيوف",
					"date": "التاريخ",
					"time": "الوقت",
					"preference": "التفضيل",
					"arriving_within": "الوصول خلال"
				},
				"button_label": {
					"search": "اعثروا لي على طاولة",
					"standby_reservation": "قوموا بإضافتي إلى قائمة الانتظار",
					"phone": "اتصلوا بنا"
				}
			},
			"details": {
				"description": "لاستكمال العملية يرجى تعبئة التفاصيل التالية.",
				"future_reservation_info": "نلفت انتباهكم إلى حفظ الطاولة لكم خلال الدقائق الـ {{value}} المقبلة",
				"future_standby_reservation_info":"يرجى تعبئة التفاصيل التالية على أن نبعث لكم رسالة بمجرد خلو مكان.",
				"called_in_info": "أضفناكم إلى الدور.\nيرجى الوصول إلى المطعم خلال الدقائق الـ {{minutes}} المقبلة.",
				"disclaimer_description": "من خلال الضغط على \"حفظ\" أنتم توافقون على شروط الاستخدام وسياسة الخصوصية. للمزيد من التفاصيل اضغطوا هنا ⟩",
				"button_label": {
					"confirm": "الحفظ",
					"cancel": "الإلغاء"
				},
				"field_label": {
					"first_name": "الاسم الشخصي",
					"last_name": "اسم العائلة",
					"phone": "الهاتف",
					"email": "البريد الإلكتروني",
					"notes": "ملاحظات"
				},
				"field_error": {
					"phone": "رقم خاطئ"
				}
			},
			"modal": {
				"confirmation": {
					"disclaimer_title": "شروط الاستخدام وسياسة الخصوصية",
					"time_limit_title": "نلفت انتباهكم",
					"time_limit_body": "طلبيتكم ستكون محدودة زمنيًا حتى الساعة {{reserved_until}} ({{duration}} فقط).\nهل تريدون المتابعة؟",
					"queue_addition_title": "الإضافة إلى الدور",
					"queue_addition_body": "هل تريدون أن تتم إضافتكم إلى الدور؟"
				},
				"alert": {
					"unsuccessful_attempt_to_reserve_title": "أوبس...",
					"unsuccessful_attempt_to_reserve_body": "يبدو أنكم غير متصلين بالإنترنت حاليًا.\nيرجى الاتصال وتكرار المحاولة.",
					"reservation_not_found_tilte": "أوبس...",
					"reservation_not_found_body": "لقد مرت أكثر من {{minutes}} دقائق منذ حفظ طلبيتكم وبالتالي فانتهت صلاحية الطلبية. يرجى إعادة إدخالها.",
					"fill_mandatory_fields_title": "حقول إلزامية",
					"fill_mandatory_fields_body": "يرجى تعبئة كافة الحقول الإلزامية",
					"online_booking_blocked_title": "الطلب عبر الإنترنت غير متاح",
					"online_booking_blocked_body": "للأسف الطلب عبر الإنترنت غير متاح لهذا المطعم"
				},
				"confirm": "التأكيد",
				"cancel": "الإلغاء",
				"retry": "كرر المحاولة"
			},
			"user_abandon_warning": "نلفت انتباهكم إلى أنكم تغادرون الصفحة وعدم حفظ التعديلات التي قمتم بها",
			"online_booking_blocked_description": "في هذه المرحلة لا يمكن إنجاز طلبيات عبر الإنترنت. يرجى الاتصال بنا عن طريق الضغط على زر الهاتف أدناه."
		},
		"reservation_details": {
			"date_label": "יום {{value}}",
			"time_label": "عند الساعة",
			"people": "{{value}}"
		},
		"feedback": {
			"feedback_form":"استمارة التعليق",
			"possible_answers":"الإجابات المحتملة",
			"mandatory_questions":"سؤال إلزامي",
			"guest": "ضيف",
			"description": "مرحبًا {{name}},\nسيسرنا إذا أجبتم على الأسئلة التالية",
			"conclusion": {
				"title": "شكرًا على تعليقاتكم!",
				"satisfied_customer_sub_title": "سيسرنا مشاهدتكم مجددًا عما قريب",
				"unsatisfied_customer_sub_title": "نعتذر على الإزعاج.\nتم تحويل استفسارك إلى مدير المطعم، وسنواصل بذل قصارى جهودنا من أجل تحسين تجربتكم."
			},
			"button_label": {
				"confirm": "أرسل تعليقاتك",
				"poor": "غير جيد",
				"fair": "جيد تقريبًا",
				"good": "جيد",
				"very_good": "جيد جدًا",
				"excellent": "ممتاز"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "جواب ناقص",
					"fill_mandatory_fields_body": "يرجى الإجابة عن كافة الأسئلة"
				},
				"confirm": "التأكيد"
			}
		},
		"deposit": {
			"description": "من أجل استكمال عملية حفظ الطلبية - يرجى تعبئة تفاصيل الائتمان لغرض الإيداع.",
			"card_number": "رقم البطاقة",
			"cvv": "CVV",
			"year": "العام",
			"month": "الشهر",
			"id_number": "رقم الهوية",
			"zip_code": "الرمز البريدي",
			"date_passed": "التاريخ فات",
			"invalid_cc_number": "رقم البطاقة خاطئ",
			"invalid_id_number": "رقم الهوية خاطئ",
			"disclaimer_description": "من خلال الضغط على \"حفظ\" أنتم توافقون على شروط الاستخدام وسياسة الخصوصية. للمزيد من التفاصيل اضغطوا هنا ⟩",
			"button_label": {
				"save": "الحفظ",
				"cancel": "يرجى إلغاء الطلبية"
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": "حقول إلزامية",
					"fill_mandatory_fields_body": "يرجى تعبئة كافة الحقول الإلزامية",
					"disclaimer_title": "شروط الاستخدام وسياسة الخصوصية",
					"disclaimer_body": "تفاصيل الائتمان مرمزة ويتم حفظها بشكل مؤمّن حسب المعايير الدولية المتبعة لدى شركات الائتمان."
				},
				"confirm": "التأكيد"
			}
		}	
	},
	"ru-RU": {
		"rsv_management": {
			"guests": " Гости ",
			"description": {
				"future_reservation_created": "Ваш заказ успешно сохранен.\n  Ваш столик будет ждать Вас в течение 15 минут с момента, на который оформлен  заказ.\n  Мы будем рады Вас видеть!",
				"future_reservation_standby_created": " Мы получили Ваш запрос на бронирование места и свяжемся с вами, если место освободится.",
				"future_reservation_saved": "Ваш заказ успешно сохранен.\n  Ваш столик будет ждать Вас в течение 15 минут с момента, на который оформлен  заказ.\n  Мы будем рады Вас видеть!",
				"future_reservation_reminded": " Пожалуйста, подтвердите свой заказ.\n  Ваш столик будет ждать Вас в течение 15 минут с момента, на который оформлен  заказ.\n  Мы будем рады Вас видеть!",
				"future_reservation_notified": " Ваш столик готов. Добро пожаловать!",
				"future_reservation_checked_in": " Спасибо , что Вы подтвердили свое прибытие. Мы сообщим Вам, когда столик будет готов",
				"future_reservation_deleted": " Ваш заказ отменен.",
				"future_reservation_verified": " Вы подтвердили свое прибытие. Спасибо!",
				"future_reservation_will_be_late": " Спасибо, что предупредили об опоздании",
				"future_reservation_undo_late": " Спасибо, что предупредили ",
				"walked_in_created": " Вы у нас зарегистрированы. Мы сообщим Вам, когда столик будет готов",
				"walked_in_saved": " Вы у нас зарегистрированы. Мы сообщим Вам, когда столик будет готов",
				"walked_in_notified": " Ваш столик готов. Добро пожаловать!",
				"called_in_created": " Вы у нас зарегистрированы. Мы сообщим Вам, когда столик будет готов",
				"called_in_saved": " Вы у нас зарегистрированы. Мы сообщим Вам, когда столик будет готов",
				"called_in_notified": " Ваш столик готов. Добро пожаловать!",
			},
			"buttons": {
				"confirm_label": " Подтверждение прибытия ",
				"cancel_label": " Отмена заказа ",
				"will_be_late_label": " Мы будем позже ",
				"undo_late_label": " Мы приедем вовремя "
			},
			"cancel_reservation_dialog": {
				"title": " Подтверждение отмены ",
				"body": " Это действие приведет к удалению заказа. Вы уверены?",
				"confirm": " Подтверждение ",
				"cancel": " Отмена "
			},
			"failure_reason": {
				"reservation_not_found": " Заказ не найден ",
				"reservation_in_progress": " Заказ приостановлен ",
				"organization_not_found": " Организация не найдена ",
				"feedback_module_disabled": " К сожалению, у этого ресторана отключена система обратной связи"
			},
			"place_in_queue": " Ваша очередь "
		},
		"splash_screen": {
			"failure_dialog": {
				"title": " Что-то не сработало :(",
				"content": " В системе возникла непредвиденная проблема.\n{{error_message}}.",
				"ok_button": " Закрыть "
			}
		},
		"booking": {
			"button_label": {
				"called_in": " Добавить в очередь ",
				"future_reservation": " Закажите место в ресторане"
			},
			"search": {
				"person": " Один гость ",
				"people": "{{value}} гостей ",
				"larger_party": "+{{value}} гостей",
				"minutes": "{{value}} минут",
				"no_results": " Кажется, что в этом ресторане нет свободных мест,\n  Пожалуйста, выберите другую дату или позвоните в ресторан",
				"results_info": " Хммм... в это время ресторан полнон - но вот еще несколько вариантов для Вас!",
				"call_restaurant_large_party": " Здравствуйте! Кажется, вас действительно много - свяжитесь с нами по телефону и мы будем рады зарезервировать для вас столик.",
				"call_restaurant_error": " К сожалению, в ресторане нет свободных мест. Пожалуйста, позвоните нам",
				"form_description_future_reservation": " Чтобы забронировать столик, выберите количество гостей, желаемую дату и время.",
				"form_description_called_in": " Пожалуйста, выберите количество гостей и время вашего прибытия.",
				"today": " Сегодня ",
				"first_available": " Без предпочтений ",
				"choose_preference": " Выберите место ",
				"inside": " Внутри ",
				"outside": " Снаружи ",
				"vip": "VIP",
				"bar": " Бар ",
				"gallery": " Галерея ",
				"balcony": " Терраса ",
				"smoking": " Курение ",
				"private_room": " Отдельная комната ",
				"patio": " Патио ",
				"tv_view": " Лицом к экрану ",
				"stage": " Сцена ",
				"with_stroller": " С тележкой ",
				"with_power": " С электричеством ",
				"standby_reservation_description": " Меня не интересует ни один из вышеперечисленных вариантов, пожалуйста, добавьте меня в лист ожидания",
				"field_label": {
					"seats_count": " Количество гостей ",
					"date": " Дата ",
					"time": " Время ",
					"preference": " Место ",
					"arriving_within": " Прибытие в "
				},
				"button_label": {
					"search": " Найдите мне столик ",
					"standby_reservation": " Добавьте меня в лист ожидания",
					"phone": " Позвоните нам "
				}
			},
			"details": {
				"description": " Чтобы завершить процесс, пожалуйста, введите следующие данные",
				"future_reservation_info": "Обратите внимание: столик зарезервирован для вас на {{value}} минут",
				"future_standby_reservation_info": " Пожалуйста, введите следующие данные, и мы отправим Вам сообщение, когда место освободится",
				"called_in_info": " Мы добавили Вас в очередь.\n  Пожалуйста, приходите в ресторан в ближайшие {{minutes}} минут.",
				"disclaimer_description": " Нажимая Сохранить Вы соглашаетесь с Условиями использования и Политикой конфиденциальности. Подробности здесь ⟩",
				"button_label": {
					"confirm": " Сохранить ",
					"cancel": " Отмена "
				},
				"field_label": {
					"first_name": " Имя ",
					"last_name": " Фамилия ",
					"phone": " Телефон ",
					"email": " Электронная почта ",
					"notes": " Примечания "
				},
				"field_error": {
					"phone": " Неправильный номер "
				}
			},
			"modal": {
				"confirmation": {
					"disclaimer_title": " Условия использования  и Политика конфиденциальности ",
					"time_limit_title": " Внимание ",
					"time_limit_body": " Ваш заказ будет ограничен по времени до {{reserved_until}} ({{duration}}).\nродолжить?",
					"queue_addition_title": " Добавить в очередь ",
					"queue_addition_body": " Хотите, чтобы мы добавили Вас в очередь?"
				},
				"alert": {
					"unsuccessful_attempt_to_reserve_title": " упс...",
					"unsuccessful_attempt_to_reserve_body": " Кажется, в данный момент Вы не подключены к Интернету.\n одключитесь и повторите попытку",
					"reservation_not_found_tilte": " упс...",
					"reservation_not_found_body": " Прошло более {{minutes}} минут, поэтому срок действия вашего бронирования истек. Пожалуйста, введите его еще раз.\n ля получения дополнительной помощи Вы можете связаться с рестораном",
					"fill_mandatory_fields_title": " Обязательные поля ",
					"fill_mandatory_fields_body": " Пожалуйста, заполните все обязательные поля",
					"online_booking_blocked_title": " Онлайн-заказ не возможен",
					"online_booking_blocked_body": " К сожалению, в этом ресторане нет возможности сделать онлайн-заказ"
				},
				"confirm": " Подтвердить ",
				"cancel": " Отменить ",
				"retry": " Попробуйте еще раз "
			},
			"user_abandon_warning": " Обратите внимание – Вы покидаете страницу и внесенные вами изменения не будут сохранены",
			"online_booking_blocked_description": " В настоящее время онлайн-заказы не принимаются. Пожалуйста, свяжитесь с нами по телефону, нажав кнопку телефона ниже",
		},
		"reservation_details": {
			"date_label": " День {{value}}",
			"time_label": " в ",
			"people": "{{value}}"
		},
		"feedback": {
			"feedback_form": " Форма для обратной связи ",
			"possible_answers": " Возможные ответы ",
			"mandatory_questions": " Обязательный вопрос ",
			"guest": " Гость ",
			"description": " Здравствуйте, {{name}}!\n Мы будем признательны, если вы ответите на следующие вопросы.",
			"conclusion": {
				"title": " Спасибо за ответ!",
				"satisfied_customer_sub_title": " Мы хотели бы увидеть Вас снова в ближайшее время ",
				"unsatisfied_customer_sub_title": " Приносим извинения за неудобства. Ваш запрос был передан менеджеру ресторана, и мы продолжим делать для Вас все возможное.",
			},
			"button_label": {
				"confirm": " Отправить отзыв ",
				"poor": " Плохо ",
				"fair": " Удовлетворительно ",
				"good": " Хорошо ",
				"very_good": " Очень хорошо ",
				"excellent": " Отлично "
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": " Нет ответа ",
					"fill_mandatory_fields_body": " Пожалуйста, ответьте на все вопросы",
					"confirm": " Подтвердить "
				}
			}
		},
		"deposit": {
			"description": " Для завершения процесса сохранения заказа - пожалуйста, укажите информацию для внесения депозита",
			"card_number": " Номер кредитной карты ",
			"cvv": "CVV",
			"year": " Год ",
			"month": " Месяц ",
			"id_number": " Удостоверение личности.",
			"zip_code": " Почтовый индекс ",
			"date_passed": " Дата прошла ",
			"invalid_cc_number": " Ошибка в  номере карты ",
			"invalid_id_number": " Ошибка в  номере  удостоверения личности ",
			"disclaimer_description": "Нажимая Сохранить Вы соглашаетесь с Условиями использования и Политикой конфиденциальности. Подробности здесь",
			"button_label": {
				"save": " Сохранить ",
				"cancel": " Отменить заказ "
			},
			"modal": {
				"alert": {
					"fill_mandatory_fields_title": " Обязательные поля ",
					"fill_mandatory_fields_body": " Пожалуйста, заполните все обязательные поля",
					"disclaimer_title": " Условия использования  и Политика конфиденциальности ",
					"disclaimer_body": " Информация о кредитной карте  зашифрована и надежно хранится в соответствии с международными стандартами кредитных компаний."
				},
				"confirm": " Подтвердить "
			}
		}
	}
};
