import { Injectable, NgZone } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable } from 'rxjs';
import { take, filter, catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../_core/auth.service';

import { AppService } from '../app.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    private unhandledLoyaltyApis: string[] = ['pos/get-customer', 'pos/get-benefits', 'pos/get-money-balance', 'pos/get-points-balance', 'pos/pay-money'];

    constructor(
        public authService: AuthService,
        public appService: AppService,
        private ngZone: NgZone,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError(err => {
                if (this.throwErrorForOTP(req, err)) return throwError(err);
                console.debug('Refresh Token Interceptor > Catch Error - The Request: ', req, err);

                // Submitting the error to logger
                if (!req.url.includes('/logger')) {
                    this.sendLogger(req, err, (err instanceof HttpErrorResponse ? err.status : null));
                }

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 400) {
                        return this.handle400Error(err);
                    } else if (err.status === 403) {
                        return this.handle403Error(err);
                    } else if (err.status === 401) {
                        return this.handle401Error(req, next, err);
                    } else if (err.status === 503) {
                        return this.handle503Error(err);
                    } else if (err.status && ![404, 409, 422, 425, 429, 500].includes(err.status)) {
                        console.debug('Refresh Token Interceptor - Caught error other than 400, 401, 403: ', err);

                        // For Tabit Order Endpoint we don't trigger the handleUnknownError function, and instead throw a regular error to the log.
                        if (req.url.indexOf('online-shopper/orders/') >= 0 || req.url.indexOf('occasions') >= 0 || req.url.indexOf('assets') >= 0) return throwError(err);

                        return this.handleUnknownError(err);
                    } else {
                        return throwError(err);
                    }
                } else {
                    return err;
                }
            }));
    }

    handleUnknownError(error) {
        console.debug('Refresh Token Interceptor > handleUnkownError: ', error);

        // Shiran requested that for the RSV the width of the 'No Internet' dialog will be auto
        let width = '80vw';
        if (window.location.href.indexOf('online-reservations') > -1) width = 'auto';

        if (!this.appService.isMessageDialogOpen && !this.appService.paused) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    primaryButtonText: 'just_a_moment',
                    secondaryButtonText: 'wait_on',
                    dialogText: 'MESSAGES.NO_INTERNET'
                }).then(response => {
                    window.location.reload();
                }).catch(err => {

                });
            });
        }
        return throwError(error);
    }

    handle400Error(error) {
        if (error?.status === 400 && error?.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.appService.signOut();
        }

        return throwError(error);
    }

	handle403Error(error) {
		if (error?.status === 403 && error.url.indexOf("/current") != -1) {
			//this.refreshTokenInProgress = false;
			//this.appService.signOut();
        }
        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any) {
        console.debug('Refresh Token Interceptor > handle401Error:', req, this.authService.refreshTokenInProgress);

        // The following endpoins should return a simple error (and not throw the user to the login screen) because either we are already in the login part, or we want to let the user see the dashboard and browse through restaurants, even before login.
        // (/tabit-order/.test(window.location.href) && !this.appService.isMessageDialogOpen) - is for preventing redirects in tabit-order
        if (this.preventTokenHandler(req.url)) {
            return throwError(error);
        }

        if (!this.authService.refreshTokenInProgress) {
            this.authService.refreshTokenInProgress = true;

            // Reset the tokenSubject so that the following requests wait until the token comes back from the refreshToken call.
            this.authService.tokenSubject.next(null);

            if (req.url.includes(this.appService.appConfig.tabitLoyaltyAPI) || req.url.includes(`${this.appService.appConfig.tabitAPI}/services/loyalty/`)) {
                // Loyalty API
                console.debug('Attempt to refresh Loyalty token: ', req);
                return this.authService.refreshLoyaltyToken$()
                    .pipe(
                        switchMap(response => {
                            if (response?.IsSuccess && response?.ResponseData?.Access_token && response?.ResponseData?.Refresh_token) {
                                console.log('Refresh Loyalty Token Succeeded!');
                                this.authService.setHttpHeadersLoyaltyAccessToken(response.ResponseData.Access_token, response.ResponseData.Refresh_token);
                                this.authService.refreshTokenInProgress = false;
                                this.authService.tokenSubject.next(response.ResponseData.Access_token);
                                return next.handle(this.authService.addToken(req, response.ResponseData.Access_token));
                            } else {
                                console.debug('Refresh Loyalty Token Failed!');
                                this.authService.refreshTokenInProgress = false;
                                this.appService.signOut();
                                return throwError(response);
                            }
                        }),
                        catchError(error => {
                            console.debug('Refresh Loyalty Token Failed!');
                            this.authService.refreshTokenInProgress = false;
                            this.appService.signOut();
                            return throwError(error);
                        })
                );
            } else {
                // ROS API
                console.debug('Attempt to refresh ROS token: ', req);

                if (!this.authService.isAnonymousTokenGenerated()) {
                    // Admin token refresh atempt
                    return this.authService.refreshToken$()
                        .pipe(
                            switchMap(response => {
                                if (response && response.access_token) {
                                    console.debug('Refresh Token Succeeded!');
                                    this.authService.setHttpHeadersAccessToken(response.access_token, response.refresh_token);
                                    this.authService.refreshTokenInProgress = false;
                                    this.authService.tokenSubject.next(response.access_token);
                                    return next.handle(this.authService.addToken(req, response.access_token));
                                } else {
                                    console.debug('Refresh Token Failed!');
                                    this.authService.refreshTokenInProgress = false;
                                    this.appService.signOut();
                                    return throwError(response);
                                }
                            }),
                            catchError(error => {
                                console.debug('Refresh Token Failed!');
                                this.authService.refreshTokenInProgress = false;
                                this.appService.signOut();
                                return throwError(error);
                            })
                        );
                } else {
                    // Public (anonymous) token refresh atempt
                    return this.authService.refreshPublicToken$()
                        .pipe(
                            switchMap(response => {
                                if (response?.access_token) {
                                    console.debug('authService - Anonymous Token Authorization Generated:', response.access_token);
                                    this.authService.setHttpHeadersAccessToken(response.access_token);
                                    this.authService.refreshTokenInProgress = false;
                                    this.authService.tokenSubject.next(response.access_token);
                                    return next.handle(this.authService.addToken(req, response.access_token));
                                } else {
                                    console.debug('Refresh Public Token Failed!');
                                    this.authService.refreshTokenInProgress = false;
                                    return throwError(response);
                                }
                            }),
                            catchError(error => {
                                console.debug('Refresh Public Token Failed!');
                                this.authService.refreshTokenInProgress = false;
                                return throwError(error);
                            })
                        );
                }
            }
        } else {
            return this.authService.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.authService.addToken(req, token));
                }));
        }
    }

    handle503Error(error) {
        console.debug('Refresh Token Interceptor > handle503Error: ', error);
        if (!this.appService.isMessageDialogOpen && !this.appService.paused) {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogTitle: 'error_title',
                    dialogText: 'MESSAGES.SERVICE_DOWN',
                    primaryButtonText: 'try_again',
                }).then(response => {
                    window.location.reload();
                }).catch(err => {

                });
            });
        }
        return throwError(error);
    }

    sendLogger(req, err, status) {
        if (!req || !err) return;

        const auditData = {
            request: req,
            // We handle it like so to prevent logger to fail reading the error messages
            error: err.message || err.stack || err.error || err,
            status
        }

        this.appService.logger({ message: this.setLoggerMessage(status, auditData), auditData });
    }

    setLoggerMessage(status?: number, auditData?: any) {
        if (!status) return 'Unknown status error' + `: ${auditData?.error ? auditData?.error : ''}`;

        switch (status) {
            case 400:
            case 401:
            case 403:
            case 503:
                return `Refresh Token Interceptor > handle${status}Error`;
            default:
                return `Unhandled error: ${status}`;
        }
    }

    preventTokenHandler(url): boolean {
        // 'mobile/pincode': Ensures user remains connected despite sms code errors.
        if (
            url.includes('/auth') ||
            url.includes('mobile/pincode') ||
            url.includes('/oauth') ||
            url.includes('/logzio') ||
            url.includes('/logger') ||
            url.includes('/customers/current/history') ||
            url.includes('/customers/reservations') ||
            this.checkIfLoyaltyApiNeedToExclude(url)
        ) return true; 
        return false;
    }

    checkIfLoyaltyApiNeedToExclude(url): boolean {
        if (!url.includes(this.appService.appConfig.tabitLoyaltyAPI) && !url.includes(`${this.appService.appConfig.tabitAPI}/services/loyalty/`)) return false;
        if (url.match(/pos\S+/g)) {
            if (this.unhandledLoyaltyApis.includes(url.match(/pos\S+/g).toString())) return true;
        };
        return false;
    }

    throwErrorForOTP(req: HttpRequest<any>, err) {
        // when the service return loyalty verification error or when the get-customer service return otp error. -- To prevent sending 2 sms -- 
        if (req?.body?.loyaltyVerification || err.status === 401 && err?.error?.Key == 'auth_otpSent') return true;
        return false;
    }

}

// Http interceptor providers in outside-in order
export const HttpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
];




// References:
// https://angular.io/guide/http#intercepting-requests-and-responses
// https://stackblitz.com/angular/amjnolgxeav?file=src%2Fapp%2Fhttp-interceptors%2Findex.ts
// https://medium.com/@alexandrubereghici/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
// https://github.com/IntertechInc/http-interceptor-refresh-token/blob/master/src/app/request-interceptor.service.ts
