<form (ngSubmit)="_signIn(signInForm)" #signInForm="ngForm">

    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
                <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div class="title">{{'MESSAGES.sign_in_by_email_message' | translate}}</div>
            <mat-form-field appearance="outline">
                <mat-label> {{'email' | translate}} </mat-label>
                <input  autocomplete="off"
                        matInput
                        [readonly]="disabled"
                        [(ngModel)]="formModel.email"
                        [formControl]="email"
                        name="email"
                        type="email"
                        email
                        required
                        class="ltr-field">
                <mat-icon matSuffix (click)="formModel.email = null" *ngIf="formModel.email">close</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> {{'password' | translate}} </mat-label>
                <input
                    matInput
                    autocomplete="new-password"
                    [readonly]="disabled"
                    [type]="hide ? 'password' : 'text'"
                    #password="ngModel"
                    [(ngModel)]="formModel.password" name="password" required class="ltr-field">
                <mat-icon matSuffix (click)="formModel.password = null" *ngIf="formModel.password" class="mr-2">close</mat-icon>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <div class="pt-4">
                <button *ngIf="!disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{'login' | translate}}</button>
                <button *ngIf="disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                    <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </div>
            
            <div class="pt-4">
                <button type="button" class="btn btn-lg btn-link btn-tans btn-block" [class.dialog-button]="dialogMode" (click)="_signUp()" [disabled]="disabled">
                    {{'sign_up' | translate}}
                </button>
            </div>
            <!-- // 2020-04-24: Reset Password is not required / relevant anymore -->
            <!--
            <div class="pt-2">
                <button type="button" class="btn btn-lg btn-link btn-block" (click)="_resetPassword()" [disabled]="disabled">{{'forgot_your_password' | translate}}</button>
            </div>
            -->
        </div>
    </div>
</form>