<div class="app-bottom-links wl-footer-background-color">
    <!-- wait for the domain config to load -->
    <div 
        *ngIf="appService.defaultServiceButtonsLoaded" 
        [class.custom-icons-scheme]="(appService.domain | async)?.theme?.footerIconsColorScheme"
        [class]="(appService.domain | async)?.theme?.footerIconsColorScheme"
        class="icons-container"
    >
    <!-- home -->
    <footer-links-button [buttonName]="'home'" (redirect)="redirect($event)"></footer-links-button>

    <ng-container *ngFor="let link of links | orderBy: 'index'">
        <footer-links-button *ngIf="link?.visible" [buttonName]="link.name" (redirect)="redirect($event)"></footer-links-button>
    </ng-container>

    <footer-links-button [buttonName]="'profile'" (redirect)="redirect($event)"></footer-links-button>

    </div>
</div>