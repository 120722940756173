import {
    Directive,
    Input,
    ElementRef,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';


declare const $: any;
declare const window: Window;

@Directive({
	selector: '[ngxCollapse]',
	exportAs: 'ngxCollapse',
	//host: { '[class.collapse]': 'true', '[class.show]': '!collapsed' }
})
export class NgxCollapseDirective {
	collapsed: boolean = false;
	onload: boolean = true;

	constructor(private el: ElementRef) { }

	@Input()
	set ngxCollapse(val: boolean) {

		this.collapsed = val;
		let $el = $(this.el.nativeElement);
		if (this.onload) {
			this.collapsed ? $el.hide() : $el.show()
			this.onload = false;
		} else {
			this.collapsed ? $el.hide(200) : $el.show(200);
		}
	}

	get ngxCollapse(): boolean { return this.collapsed; }
}


@Directive({
	selector: '[ngxDetectScroll]',
	exportAs: 'ngxDetectScroll'
})
export class NgxDetectScrollDirective {
	@Output() public onScroll = new EventEmitter<{}>();
	@Input() public bottomOffset: number = 300;
	@Input() public topOffset: number = 100;
	@Input() public directionOffset: number = 30;

	elementScrollTop: number = 0;
	windowScrollTop: number = 0;

	constructor() {
	}

	// handle host scroll
	@HostListener('scroll', ['$event']) public scrolled($event: Event) {
		this.elementScrollEvent($event);
	}

	// handle window scroll
	@HostListener('window:scroll', ['$event']) public windowScrolled($event: Event) {
		this.windowScrollEvent($event);
	}

	protected windowScrollEvent($event: Event) {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		this.windowScrollTop = scrollTop;
	}

	protected elementScrollEvent($event: Event) {
		const target = <HTMLElement>$event.target;
		const scrollPosition = target.scrollHeight - target.scrollTop;
		const offsetHeight = target.offsetHeight;
		const isReachingTop = target.scrollTop < this.topOffset;
		const isReachingBottom = (scrollPosition - offsetHeight) < this.bottomOffset;
		const direction = target.scrollTop > this.elementScrollTop ? 'down' : 'up';
		this.elementScrollTop = target.scrollTop;
		const emitValue = { isReachingBottom, isReachingTop, originalEvent: $event, isWindowEvent: false, scrollTop: target.scrollTop, direction: direction };
		this.onScroll.emit(emitValue);
	}

}

// Thoses directives are only 'flags' to behave as selectors for <ng-content>s
@Directive({selector: '[isAddress]'}) export class IsAddressDirective {}

