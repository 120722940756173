<div class="media-container" [style.backgroundImage]="bookSelectionBarImageBackground">
    <div class="image-overlay"></div>

    <div *ngIf="webSearchType == 'home'" class="business-invite-container"></div>
</div>
<div class="header-tabs-content" [class.mobile]="!appService.isDesktop()">
    <div class="header-tab-content">
        <ng-content></ng-content>
    </div>
</div>
