import { Directive, forwardRef, Input, HostListener } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator, UntypedFormControl } from '@angular/forms';

import moment from 'moment';
import { padStart } from 'lodash-es';

function nonEmptyFactory() {
	return (c: UntypedFormControl) => {
		return !c.value || c.value.trim() !== '' ? null : {
			nonEmpty: {
				valid: false
			}
		};
	};
}

@Directive({
	selector: '[nonEmpty][ngModel],[nonEmpty][formControl]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NonemptyValidator), multi: true }
	]
})
export class NonemptyValidator {

	validator: Function;

	constructor() {
		this.validator = nonEmptyFactory();
	}

	validate(c: UntypedFormControl) {
		return this.validator(c);
	}
}

/*  Validate Credit Card PAN (Number) */

@Directive({
	selector: '[validCreditCard]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: CreditCardValidator, multi: true }
	]
})
export class CreditCardValidator implements Validator {
	@Input() public ccInfo: any = {};

	validate(c: UntypedFormControl): ValidationErrors | null {
		return CreditCardValidator.validateCcNumber(c, this.ccInfo);
	}
	static validateCcNumber(control: UntypedFormControl, ccInfo): ValidationErrors {
		let ccType = CreditCardValidator.GetCreditCardType(control.value);

		ccInfo.brand = ccType.type || 'cc';
		ccInfo.brandName = ccType.name;

		if (!ccType.type) {
			return { creditCard: 'VALIDATIONS.CREDIT_CARD' };
		}
		return null
	}

	static GetCreditCardType(number) {
		// visa
		if (number) number += ""
		else number = "";

        if (number.length >= 8 && number.length <= 9) {
            let on = (number.length == 8 ? '0' + number : number).split("");
            let sum = 0;
            for (let i = 0; i < 9; i++) {
                sum += Number(on[i]) * (9 - i);
            }
            if (sum % 11 == 0) return { name: "Isracard", type: "cc_mastercard" };
        }

		var re = new RegExp("^4");
		if (number.match(re) != null) return { name: "Visa", type: "cc_visa" };

		// Mastercard
		// Updated for Mastercard 2017 BINs expansion
		if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
			return { name: "Mastercard", type: "cc_mastercard" };

		// AMEX
		re = new RegExp("^3[47]");
		if (number.match(re) != null) return { name: "AMEX", type: "cc_mastercard" };


		// Discover
		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if (number.match(re) != null) return { name: "Discover", type: "cc_discover" };


		// Diners
		re = new RegExp("^36");
		if (number.match(re) != null) return { name: "Diners", type: "cc_diners" };


		// Diners - Carte Blanche
		re = new RegExp("^30[0-5]");
		if (number.match(re) != null) return { name: "Diners - Carte Blanche", type: "cc_diners" };


		// JCB
		re = new RegExp("^35(2[89]|[3-8][0-9])");
		if (number.match(re) != null) return { name: "JCB", type: "cc_jcb" };

		// Visa Electron
		re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
		if (number.match(re) != null) return { name: "Visa Electron", type: "cc_visa" };


		return {};
	}

}

/*  Validate Credit Card Expiration */

@Directive({
	selector: '[validCardExpiration]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: CreditCardExpirationValidator, multi: true }
	]
})
export class CreditCardExpirationValidator implements Validator {
	@Input() public ccInfo: any = {};

	@HostListener('keydown', ['$event']) onKeyDown(e) {
		this.isBackspace = e.code == 'Backspace' || e.key == "Backspace";
	}
	isBackspace: boolean = false;

	validate(c: UntypedFormControl): ValidationErrors | null {
		return this.validateExpiration(c);
	}
	validateExpiration(control: UntypedFormControl): ValidationErrors {
		if (this.isBackspace) return null;
        
        let value = control.value;
		if (!value) return null;

		var newValue = value.replace(/\D/g, '');

		let mm = newValue.substr(0, 2);
		if (mm.length < 2) return this.prepareValue(control, value, newValue);
		let yy = newValue.substr(2, 2);
		newValue = mm + "-" + yy;
		if (yy.length < 2) return this.prepareValue(control, value, newValue);

		yy = Number("20" + yy);
		mm = Number(mm);

		let _yy = moment().get('year');
		let _mm = moment().get('month') + 1;
		if (mm < 1 || mm > 12 || yy < _yy) return this.validationError();
		if (yy == _yy && mm < _mm) return this.validationError();

		this.ccInfo.expMonth = padStart(mm, 2, '0');
		this.ccInfo.expYear = yy;
		if (value != newValue) return this.prepareValue(control, value, newValue);
		return null;
	}

	prepareValue(control: UntypedFormControl, value, newValue) {
		if (value != newValue) control.setValue(newValue);
		return null;
	}

	validationError() {
		delete this.ccInfo.expMonth;
		delete this.ccInfo.expYear;
		return { creditCardExpiration: 'VALIDATIONS.CREDIT_CARD_EXPIRATION' }
	}
}

/*  Validate ID Number */
@Directive({
	selector: '[validIdCard]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: IdCardValidator, multi: true }
	]
})
export class IdCardValidator implements Validator {
	@Input() public IdCard: any = {};

	validate(c: UntypedFormControl): ValidationErrors | null {
		return this.validateIdCard(c);
    }
    
	validateIdCard(control: UntypedFormControl): ValidationErrors {
		const value = control.value;
        if (!value) return null;

        const isValidID = (id) => {
            id = id.toString();
            if (id == '000000000') return;
            if (id.length != 9) return;
            if (isNaN(id) || Number(id) === 0) return;
            let counter = 0, incNum;
            for (let i = 0; i < 9; i++) {
                incNum = parseInt(id[i]) * ((i % 2) + 1);
                counter += (incNum > 9) ? incNum - 9 : incNum;
            }
            return (counter % 10 == 0);
        };

        return isValidID(value) ? null : { IdCardValidationError: 'VALIDATIONS.INVALID_ID_NUNBER' };
    }
}
