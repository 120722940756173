<div
    *ngIf="locationInitialized"
    [@locationInitializedTrigger]
    class="location-container"
    (click)="selectLocation.open()"
>
    <!--
        !Important! look at the following: [ngModel]. You thinks it's a mistake, you expect [(ngModel)]! So, that one is by design.
        It's like that to make ONLY the compoment engine to set the value, but NOT the selection-click.
    -->
    <mat-select
        class="value-indicator"
        panelClass="mat-select-custom-class"
        [ngModel]="selectedLocationType"
        (selectionChange)="pickAtLocationDropdown($event)"
        [aria-label]="'location_select' | translate"
        role="listbox"
        #selectLocation
    >
        <mat-option
            [disabled]="!actualLocationAvailable"
            value="actual"
            class="wl-cards-background-color"
            role="option"
        >
            {{ (actualLocationAvailable ? 'my_location' : 'my_location_unavailable') | translate }}
        </mat-option>

        <mat-option
            [value]="'saved_address__'+savedAddress._id"
            *ngFor="let savedAddress of savedAddresses"
            class="wl-cards-background-color"
            role="option"
        >
            {{savedAddress.formatted_address}}
        </mat-option>

        <!-- If a house is required we don't show the list of areas as an otion -->
        <mat-option
            *ngFor="let area of mapAreas"
            class="wl-cards-background-color"
            [value]="'area__'+area.key"
            role="option"
        >
            {{('areas.' + area.key) | translate}}
        </mat-option>

        <mat-option
            value="manual-address"
            role="option"
        >
            <mat-icon aria-hidden="true">add</mat-icon>
            {{ (houseRequired ? 'please_type_delivery_address_short' : 'please_type_address_short') | translate }}
        </mat-option>

    </mat-select>

    <div class="location-selected-data">
        <div
            class="selected-data-value"
            [class.actual-location]="locationLabeled.actual"
        >{{ locationLabeled.label | translate }}</div>
        <button mat-stroked-button tabindex="-1">
            <mat-icon aria-hidden="true">my_location</mat-icon>
        </button>
    </div>
</div>