<div class="web-seo-header-container">
    <!-- <a class="restaurant-owner-teaser" accessible role="link" (click)="businessInviteRedirect()">
        {{ 'business_invite_restaurant_owner_seo' | translate }} {{ 'business_invite_join_us_seo' | translate }}
    </a> -->
    <div class="inner-container">
        <div class="seo-header">
            <h1 class="seo-header-text">
                {{ titleService.getTitle() }}
            </h1>
        </div>
        <div class="breadcrumbs-container">
            <a href="" [routerLink]="breadcrumbsHomeLink"
                class="breadcrumbs-link">{{'WEB_CONTAINER.restaurants.tab_title' | translate}}</a>
            <span class="breadcrumbs-divider"> &rsaquo; </span>
            <a href="" [routerLink]="breadcrumbsCurrentLink" class="breadcrumbs-link">{{breadcrumbsCurrentTitle}}</a>
        </div>
    </div>
</div>