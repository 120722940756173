<form (ngSubmit)="_update(updateForm)" #updateForm="ngForm" [formGroup]="formGroup">
    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
                <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div class="title">{{'MESSAGES.sign_up_message' | translate}}</div>

            <mat-form-field appearance="outline">
                <mat-label> {{'first_name' | translate}} </mat-label>
                <input  matInput
                        [readonly]="disabled"
                        type="text"
                        [(ngModel)]="formModel.firstName"
                        name="firstName"
                        formControlName="firstName"
                        required>
                <mat-icon matSuffix (click)="formModel.firstName = null" *ngIf="formModel.firstName">close</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label> {{'last_name' | translate}} </mat-label>
                <input  matInput
                        [readonly]="disabled"
                        type="text"
                        [(ngModel)]="formModel.lastName"
                        name="lastName"
                        formControlName="lastName"
                        required>
                <mat-icon matSuffix (click)="formModel.lastName = null" *ngIf="formModel.lastName">close</mat-icon>
            </mat-form-field>

            <div class="pt-2">
                <button *ngIf="!disabled" class="btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{'save' | translate}}</button>
                <button *ngIf="disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                    <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </div>

            <div class="disclaimer pt-2">
                <consent-text></consent-text>
            </div>

            <div class="pt-2" *ngIf="!dialogMode">
                <button type="button" class="btn btn-link btn-lg btn-block" [class.dialog-button]="dialogMode" (click)="_back()" [disabled]="disabled">
                    {{'back' | translate}}
                </button>
            </div>
        </div>
    </div>
</form>