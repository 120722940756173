<div class="summary-container">

    <div *ngIf="basket?.length" class="order-details">
        <span class="title">{{ 'order_details' | translate }}</span>
        <div class="order-items">
            <div class="order-item" *ngFor="let item of basket">
                <div class="item-qty">{{item.quantity}}</div>
                <div class="order-item-data">
                    <div class="name">{{item.name}}</div>
                    <!-- <div class="text-xs">
                        <div *ngIf="item._item?.summary">{{item._item.summary.text}}</div>
                        <div *ngIf="item.selectionSummary?.length">
                            <table>
                                <tbody>
                                    <tr *ngFor="let group of item.selectionSummary | filterBy:{ _visible: true}">
                                        <td class="font-bold v-top"><div class="mr-2 no-wrap">{{group.name}}:</div></td>
                                        <td class="v-top">
                                            <div *ngFor="let item of group.items">
                                                <div>
                                                    <span class="" *ngIf="item.count > 1">{{item.count}}*</span>
                                                    {{item.name || 'missing'}}
                                                    <span class="inline m-near-xs" *ngIf="item._item.total > 0">+{{item._item.total | toMoney}}</span>
                                                </div>
                                                <small class="block mb-1" *ngIf="item.$wasEdit && item._item.summary">{{item._item.summary.text}}</small>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="summary-offerbox-sub" *ngIf="item.offerRemrks">
                            <div class="m-t-sm" style="font-style:italic">
                                * {{item.offerRemrks}}
                            </div>
                        </div>
                    </div> -->
                </div>
                <div *ngIf="!item.onTheHouse">{{ (item.total * item.quantity) | lcurrency }}</div>
                <div *ngIf="item.onTheHouse">{{ 'on_the_house' | translate }}</div>
            </div>
            <div class="order-item" *ngIf="deliveryFee > 0">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <mat-icon [svgIcon]="'take away unactive'"></mat-icon>
                    <div class="name">{{'DELIVERY-PRICE' | translate}}</div>
                </div>
                <div>{{ deliveryFee | lcurrency}}</div>
            </div>
            <div class="order-item" *ngIf="tip > 0">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <mat-icon [svgIcon]="'money'"></mat-icon>
                    <div class="name">{{'gratuity' | translate}}</div>
                </div>
                <div>{{ tip | lcurrency}}</div>
            </div>
            <div class="order-item" *ngIf="fees">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <mat-icon [svgIcon]="'money'"></mat-icon>
                    <div class="name">{{'order_tracker.FEES' | translate}}</div>
                </div>
                <div>{{ fees | lcurrency}}</div>
            </div>
            <div class="order-item" *ngIf="showTax && exclusiveTaxes > 0">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <mat-icon [svgIcon]="'money'"></mat-icon>
                    <div class="name">{{'TAX_AMOUNT' | translate}}</div>
                </div>
                <div>{{ exclusiveTaxes | lcurrency}}</div>
            </div>
        </div>
        <div class="order-items" *ngIf="benefitsValue > 0 || discountsValue > 0 || pointsUsed > 0">
            <span class="title">{{ 'benefits_and_promotions' | translate }}</span>
            <!-- Club benefits -->
            <div *ngIf="orderBenefits" class="order-item">
                <div class="item-qty">{{ orderBenefits.length || '1' }}</div>
                <div class="order-item-data">
                    <div class="name benefit">
                        <mat-icon [svgIcon]="'gift'" class="sm mr-2"></mat-icon>
                        {{'club_benefits' | translate}}
                    </div>
                    <!-- <div class="text-xs">
                        <div *ngIf="Array.isArray(orderBenefits)">
                            <table>
                                <tbody>
                                    <tr *ngFor="let benefit of orderBenefits">
                                        <td class="v-top"><div class="mr-2 no-wrap">{{benefit.name}}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>
                <div class="benefits-value"> {{ benefitsValue | lcurrency }} -</div>
            </div>
            <!-- Points -->
            <div *ngIf="pointsUsed" class="order-item">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data">
                    <div class="name benefit">
                        <mat-icon [svgIcon]="'money'" class="mr-2"></mat-icon>
                        {{'realize_points' | translate}}
                    </div>
                </div>
                <div class="benefits-value"> {{ pointsUsed }} -</div>
            </div>
            <!-- More benefits -->
            <div *ngIf="discountsValue > 0" class="order-item">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data">
                    <div class="name benefit">{{'more_benefits' | translate}}</div>
                    <!-- <div class="text-xs">
                        <div *ngIf="order.orderedDiscounts?.length">
                            <table>
                                <tbody>
                                    <tr *ngFor="let discount of order.orderedDiscounts">
                                        <td class="v-top"><div class="mr-2 no-wrap">{{discount.name || discount.reason.name}}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>
                <div class="benefits-value"> {{ discountsValue | lcurrency }} -</div>
            </div>
        </div>
        <div class="order-items total" *ngIf="totalAmount">
            <!-- <span class="title">{{ 'ORDER-TOTAL' | translate }}</span> -->
            <div class="order-item total">
                <div class="icon-value">
                    <mat-icon [svgIcon]="'money'" class="mr-2"></mat-icon>
                </div>
                <div class="order-item-data">
                    <div class="name total">
                        {{'TOTAL' | translate}}
                    </div>
                </div>
                <div class="total-value"> {{ totalAmount | lcurrency }} </div>
            </div>
            <div class="order-item" *ngIf="showTax && inclusiveTaxes > 0">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <mat-icon [svgIcon]="'money'"></mat-icon>
                    <div class="name">{{'INCLUSIVE_TAXES' | translate}}</div>
                </div>
                <div>{{ inclusiveTaxes | lcurrency}}</div>
            </div>
            <div *ngIf="balance > 0" class="order-item">
                <div class="icon-value">
                    <mat-icon [svgIcon]="'money'" class="mr-2"></mat-icon>
                </div>
                <div class="order-item-data">
                    <div class="name total">
                        {{'REMAINED_PAY' | translate}}
                    </div>
                </div>
                <div class="total-value"> {{ balance | lcurrency }} </div>
            </div>
            <div *ngIf="cashBalance > 0" class="order-item">
                <div class="icon-value">
                    <mat-icon [svgIcon]="'money'" class="mr-2"></mat-icon>
                </div>
                <div class="order-item-data">
                    <div class="name total">
                        {{'REMAINED_PAY_CASH' | translate}}
                    </div>
                </div>
                <div class="total-value"> {{ cashBalance | lcurrency }} </div>
            </div>
            <div class="order-item" *ngIf="remainingAvailableDiscount > 0">
                <div class="item-qty">{{ '*' }}</div>
                <div class="order-item-data tip">
                    <div class="name">{{'order_tracker.CASH_DISCOUNT' | translate}}</div>
                </div>
                <div class="benefits-value">{{ remainingAvailableDiscount | lcurrency}} -</div>
            </div>
        </div>
        <!-- <div class="order-total">{{'TOTAL' | translate}} {{totalAmount | lcurrency}}</div>
        <div class="order-total balance">{{'REMAINED_PAY' | translate}} {{balance | lcurrency}}</div> -->
    </div>

    <div class="disclaimer-container">
        <span *ngIf="org?.phone">{{ 'order_tracker.disclaimer_with_phone' | translate: { name: org?.name } }}</span>
        <span *ngIf="!org?.phone">{{ 'order_tracker.disclaimer_without_phone' | translate: { name: org?.name } }}</span>
    </div>
</div>

