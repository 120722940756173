<div @siteCardAnimation class="card card-site mr-2 wl-cards-background-color" [class.animate]="index <= 3" accessible>
    <div class="card-img-top dark-theme" [style.background-image]="(site.largeImage || appService.images.default_site_image) | safeStyle">
        <!--
        This is the 'closed' overlay.
        Commenting out instead of deleting because a prophecy is that Tabit will want it back
        <div class="closed-now-overlay md" *ngIf="site.timeSlots && !site.activeSlot.slotFound">
            <div>
                {{'Closed Now' | translate}}
                <small *ngIf="site.tdSlot">{{'CAN_TD' | translate}}</small>
                <small *ngIf="!site.tdSlot && utilsService.siteCanBook(site)">{{'book' | translate}}</small>
            </div>
        </div>
        -->
    </div>
    <div class="card-body d-flex align-items-start">
        <div class="flex-grow-1 text-truncate">
            <!-- It's important that the image tag and the site name will be at the same line - to prevent white-spaces -->
            <div class="card-title text-truncate">{{site.name}}</div>
            <div class="card-categories" *ngIf="site.tags.length">
                <span class="category-name" *ngFor="let tag of getSiteTags(); index as i;">
                    {{tag}}{{ i !== (site.tags.length - 1) ? ' | ' : '' }}
                </span>
            </div>
            <div class="card-description text-truncate">{{site.description}}</div>
        </div>
        <div class="favorite-container" *ngIf="appService.isAuthUser() && appService.isApp && !appService.skin">
            <favorite-fab [site]="site"></favorite-fab>
        </div>
    </div>
    <div class="card-footer d-flex align-items-start">
        <ng-container *ngIf="showDistance && site.distance">
            <div class="card-distance">{{site.formattedDistance | translate}} {{unit | translate}}</div>
            <span class="separator">|</span>
        </ng-container>
        <div class="card-address flex-grow-1 text-truncate">{{site.address}}</div>
    </div>
</div>