import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-marketplace',
  templateUrl: './app-marketplace.component.html',
  styleUrls: ['./app-marketplace.component.scss']
})
export class AppMarketplaceComponent implements OnInit {

  constructor(
    public appService: AppService,
  ) { }

  ngOnInit(): void {
  }

}
