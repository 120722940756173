import { Component, OnInit, OnDestroy, Input, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {  MatSelectChange } from '@angular/material/select';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { BookService } from '../../_core/book.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { TagsService, Tag } from '../../_core/tags.service';
import { ORGANIZATION_BUCKET_TYPES, SearchDetails } from '../../_core/OrganizationBucket';
import { WebContainerService } from '../web-container.service';
import { AMPMConverterPipe } from '../../_core/pipes';

import { getMapAreas, MAP_AREA } from '../../_core/static/map-areas';

declare const $: any;

@UntilDestroy()
@Component({
    selector: 'web-selection-bar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './web-selection-bar.component.html',
    styleUrls: ['./web-selection-bar.component.scss']
})
export class WebSelectionBarComponent implements OnInit, OnDestroy {

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public entityUtilsService: EntityUtilsService,
        private webContainerService: WebContainerService,
        private bookService: BookService,
        private organizationsService: OrganizationsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private tagsService: TagsService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private ampmConverterPipe: AMPMConverterPipe,
    ) {
        this.mapAreas = getMapAreas(this.appService.appConfig.locale);
    }
    @Input() isHome: boolean = false;
    private initialized: boolean = false;

    mainSubscription: Subscription;

    searchByQueryParams: boolean = false;

    searching: boolean = false;

    dates: any[] = [];
    timeSlots: any[] = [];
    diners: any[] = [];
    tags: Tag[] = [];
    groupedTags: { name: string, tagsArray: Tag[] }[] = [];

    selectedDate: any;
    selectedTimeSlot: any;
    selectedDiners: any;
    selectedTag: any;
    selectedRating: any;
    selectedPrice: any;
    selectedService: SearchDetails['service'];

    mapAreas: MAP_AREA[] = [];

    addressInputVisible = false;

    public selectionFields: BehaviorSubject<any> = new BehaviorSubject ({
        text: false,
        area: false,
        date: true,
        hour: true,
        guests: true,
        service: false,
        tags: false,
        rating: false,
        price: false,
    });

    public stars = [
        {id: 5, value: []},
        {id: 4, value: []},
        {id: 3, value: []},
        {id: 2, value: []},
        {id: 1, value: []}
    ];
    public prices = [];

    // ============================================================================
    // booking / order / search
    // ============================================================================
    private searchType: ORGANIZATION_BUCKET_TYPES;
    private _webSearchType: string = '';
    @Input() set webSearchType(strSearchType) {

        this._webSearchType = strSearchType;

        let searchTypesMap: { [key: string]: ORGANIZATION_BUCKET_TYPES } = {
            booking: ORGANIZATION_BUCKET_TYPES.booking,
            order: ORGANIZATION_BUCKET_TYPES.order,
            search: ORGANIZATION_BUCKET_TYPES.search,
        };

        if (this.webSearchType == 'home' || this.webSearchType == 'booking') {
            this.searchType = searchTypesMap.booking;
        } else {
            this.searchType = searchTypesMap[this.webSearchType] || null;
        }
    };
    get webSearchType(): string {
        return this._webSearchType;
    }
    // ============================================================================

    @Input() searchButtonStringKey: string = 'search';
    @Input() customSelectionFields: {} = {};

    selectedLocationType: string = 'actual';

    public usingExtendedResults: boolean = false;

    ngOnInit() {
        this.appService.locale
        .pipe(untilDestroyed(this)) // Used to destroy subscription and prevent memory leaks
        .subscribe(() => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.changeDetectorRef.detectChanges();
                this.initialize();
            });
        });

        if (!this.searchType) throw new Error(`Cannot initialize web-selection-bar. No such search type: '${this.webSearchType}'`);

        this.webContainerService.webSelectionSearchEvent.subscribe((searching: boolean) => {
            this.searching = searching;
            setTimeout(() => {
                this.searching = false;
                this.changeDetectorRef.detectChanges();
            }, 1000);
        });

        this.mainSubscription = combineLatest([
            this.appService.loadingMetaData,
            this.activatedRoute.queryParams,
            this.organizationsService.usingExtendedResults(this.searchType),
            this.tagsService.tagsData$,
            this.appService.googlePlaceDetailsSubject,
        ]).subscribe(([loading, params, usingExtendedResults, tags, googlePlaceDetails]) => {
            this.usingExtendedResults = usingExtendedResults;

            if (loading) return;

            this.tags = (tags || []).filter(tag => tag.type !== 'occasions');

            if (params['tag'] && this.tags.length) {
                this.selectedTag = this.tags.find(tag => tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label === params['tag'])
                if (this.selectedTag) this.searchByQueryParams = true;
            }

            this.groupedTags = this.tagsService.makeGroupedTags(tags);

            if (this.customSelectionFields) {
                this.selectionFields.next(Object.assign(this.selectionFields.getValue(), this.customSelectionFields));
            }

            // Here we're assigning the details from the domain
            if (googlePlaceDetails && this._webSearchType !== 'booking') {
                const details = {
                    rating: googlePlaceDetails.showRating,
                    price: googlePlaceDetails.showPrice,
                };
                this.selectionFields.next(Object.assign(this.selectionFields.getValue(), details));
            };

            if (!this.initialized) this.initialize();

        });

        this.stars.forEach(star => {
            star.value = this.fillStars(star.id)
        });

        this.prices = this.appService.priceLevels;

    }

    private initialize() {

        if (this.searchType === ORGANIZATION_BUCKET_TYPES.booking) {
            // 2020-02-07: This MUST BE REVISED!
            // We execute the startWithoutSite() ONLY if we don't already have pre-stored data.
            // Otherwise this will cause the previous user selection to get lost!
            if (!(this.bookService.$storage && this.bookService.$storage.bookingDiners)) {
                this.bookService.startWithoutSite();
            }
            // console.log('=== web-selection-bar > ngOnInit > Search Type = Booking > bookService.$storage: ', this.bookService.$storage);

            this.dates = this.bookService.$storage.bookingDates;
            const selectedDate = this.organizationsService.getSearchDetails(ORGANIZATION_BUCKET_TYPES.booking)?.booking?.timestamp;
            this.timeSlots = this.bookService.generateFullDaySlots(this.isSelectedDateIsToday(selectedDate));
            this.diners = this.bookService.$storage.bookingDiners;
            if (
                !this.dates || !this.dates.length ||
                !this.timeSlots || !this.timeSlots.length ||
                !this.diners || !this.diners.length
            ) throw new Error('bookService.startWithoutSite() failed badly');

            this.initializeSelectionInputsBooking(this.organizationsService.getSearchDetails(ORGANIZATION_BUCKET_TYPES.booking));

        } else {

            this.initializeSelectionInputs(this.webContainerService.getSharedSearchValue());
        }

        this.initialized = true;
    }

    initializeSelectionInputsBooking(searchDetails: SearchDetails) {

        if (searchDetails?.booking) {

            let selectedBookTime = moment(searchDetails.booking.timestamp);

            this.selectedDate = this.dates.find(
                dateObj => moment(dateObj.date).startOf('day').isSame(moment(selectedBookTime).startOf('day'))
            );
        
            this.selectedTimeSlot = this.timeSlots.find(
                slotObj => slotObj.text === selectedBookTime.format('HH:mm')
            );

            this.selectedDiners = this.diners.find(dinerObj => dinerObj.value == searchDetails.booking.seats_count);
        }

        if (!this.selectedDate) this.selectedDate = this.dates[0];

        if (!this.selectedTimeSlot) {
            this.selectedTimeSlot = this.timeSlots.find(
                slotObj => slotObj.text === moment().add(90, 'minutes').format('HH:00')
            );
            if (!this.selectedTimeSlot) this.selectedTimeSlot = this.timeSlots[0];
        }
        if (!this.selectedDiners) this.selectedDiners = this.diners[1];
    }

    initializeSelectionInputs(searchDetails: SearchDetails) {

        //console.log('=== WEB/SELECTION-BAR === initial search type:', this.searchType, 'order:', this.searchType === ORGANIZATION_BUCKET_TYPES.order);

        let lastSearchDetails = this.organizationsService.getSearchDetails(this.searchType);
        //console.log('=== WEB/SELECTION-BAR === last search details:', JSON.stringify(lastSearchDetails));

        if (lastSearchDetails && lastSearchDetails.service) {
            this.selectedService = lastSearchDetails.service;
        } else if (this.searchType === ORGANIZATION_BUCKET_TYPES.order) {
            this.selectedService = this.appService.defaultServiceOrderType;
        }
        //console.log('=== WEB/SELECTION-BAR === selectedService:', this.selectedService);

        // If something is exist at query params, the tags were already managed ONLY by it:
        if (this.searchByQueryParams || !searchDetails) return;

        if (searchDetails.tags) {
            let selectedTag = this.tags.find(tag => tag._id === searchDetails.tags[0]);
            if (selectedTag) this.selectedTag = selectedTag;
        }

        if (searchDetails.rating) {
            let selectedRating = this.stars.find(star => star.id === searchDetails.rating);
            if (selectedRating) this.selectedRating = selectedRating;
        }

        if (searchDetails.price) {
            let selectedPrice = this.prices.find(price => price.id === searchDetails.price);
            if (selectedPrice) this.selectedPrice = selectedPrice;
        }

    }

    ngOnDestroy() {
        this.mainSubscription.unsubscribe();
    }

    webSearchSites($event: Event) {
        $event.stopPropagation();

        this.webContainerService.webSelectionSearchEvent.emit(true);

        if (this.searchType === ORGANIZATION_BUCKET_TYPES.booking) {

            if (this.isSelectedTimeInThePast()) return this.appService.mainMessage({
                dialogTitle: 'please_note',
                dialogType: 'error',
                dialogText: 'WEB_CONTAINER.book_a_table.select_future_date',
                hideSecondaryButton: true
            });

            this.setSearchDetailsAndGoToBooking();
            
        } else {
            
            this.setSearchDetails();

        }
    }

    private setSearchDetails() {
        this.organizationsService.setIfUsingExtendedResults(false, this.searchType);

        this.makeSearchDetails().subscribe(searchDetails => {
            this.webContainerService.updateSharedSearch(searchDetails);
        }, err => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: err.message,
                    hideSecondaryButton: true
                });
            });
        });
    }

    private setSearchDetailsAndGoToBooking() {

        let searchQuery = this.webContainerService.getMainSearchQuery();
        let route = this.appService.redirectValueByLocale('book-a-table', 'ROUTE');

        this.bookService.$storage.bookForm.date = this.selectedDate || this.dates[0];
        this.bookService.$storage.bookForm.diners = this.selectedDiners || this.diners[0];
        this.bookService.$storage.bookForm.time = this.selectedTimeSlot || this.timeSlots[0];

        console.debug('=== setSearchDetailsAndGoToBooking === book service storage: ', this.bookService.$storage);

        let searchDetails: SearchDetails = {
            skip: 0,
            booking: {
                timestamp: this.bookService.getCrossOrgsSearchTime().toISOString(),
                seats_count: this.bookService.getCrossOrgsSearchSeatCount().toString()
            }
        };
        
        if (searchQuery) searchDetails.query = searchQuery;

        this.webContainerService.updateSharedSearch(searchDetails);
        this.router.navigate([route]);

    }

    private makeSearchDetails(): Observable<SearchDetails> {

        // This was done as observable to allow async operation like searching addresses at google but not in use now:
        return new Observable<SearchDetails>(observer => {

            // Warning: Do not return specific service. Those searchDetails are for everyone!
            let searchDetails: SearchDetails = { skip: 0 };

            // Merge searchDetails with the current top main search:
            // 2020-02-06: We RESET the search query and respect only the filters...
            //let searchQuery = this.webContainerService.getMainSearchQuery();
            //if (searchQuery) searchDetails.query = searchQuery;

            if (this.selectedTag && this.selectedTag._id) searchDetails.tags = [this.selectedTag._id];
            if (this.selectedRating && this.selectedRating.id) searchDetails.rating = this.selectedRating.id;
            if (this.selectedPrice && this.selectedPrice.id) searchDetails.price = this.selectedPrice.id;
            if (this.selectedService) searchDetails.service = this.selectedService;

            // console.log('=== WEB/SELECTION-BAR === Making search details:', searchDetails, 'Is selectedTag:', this.selectedTag);
            observer.next(searchDetails);
            observer.complete();

        });
    }

    private fillStars(rating) {
		let starsCount = 5;
		let starsArray = new Array(rating > starsCount ? starsCount : Math.floor(rating));
		// Fill "full" stars
		starsArray.fill('star');
		// Add "empty" stars
		while (starsArray.length < starsCount) starsArray.push('star_border');

		return starsArray;
    };
    
    activeSlotsTextParse(text: string) {
        return text.toString().split(/\s/g).map(chunk => this.ampmConverterPipe.transform(chunk)).join(' ');
    }
    
    public onSelectedDayChange(event: MatSelectChange): void {
        this.timeSlots = this.bookService.generateFullDaySlots(this.isSelectedDateIsToday(event?.value?.date));
        
        // User change date for today with invalid time (relative to today), pick first time slot of today
        if (this.isSelectedDateIsToday(event?.value?.date) && moment(this.selectedTimeSlot?.text, 'HH:mm').isBefore(moment(this.timeSlots?.[0]?.text, 'HH:mm'))) {
            this.selectedTimeSlot = this.timeSlots?.[0];
        }
    }

    private isSelectedTimeInThePast(): boolean {
        const date = (this.selectedDate || this.dates[0])?.date;
        const [hours, minutes] = (this.selectedTimeSlot || this.timeSlots[0])?.text?.split(':');
        const time = moment(date).hours(hours).minutes(minutes).startOf('minute');

        return moment().isAfter(moment(time));
    }

    private isSelectedDateIsToday(date: any): boolean {
        return moment().isSame(moment(date), 'day');
    }
}
