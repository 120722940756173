<div 
    class="dialog-container wl-cards-background-color"
    cdkTrapFocusAutoCapture 
>
    <div class="dialog-top-section">
        <div class="close-dialog" (click)="closeDialog({ closeButtonClicked: true })"  *ngIf="!dialogData.hideXIcon" accessible>
            <button mat-icon-button [disableRipple]="true" [color]="styleService.isDarkMode ? 'accent' : 'primary'"><mat-icon>close</mat-icon></button>
        </div>

        <div *ngIf="!dialogData.dynamicContent" class="dialog-main-icon">
            <mat-icon 
                [ngClass]="dialogIconName"
                [svgIcon]="dialogIconName"
            ></mat-icon>
        </div>

        <div class="dialog-title" accessible cdkFocusInitial >
            {{dialogData.dialogTitle | translate}}
        </div>
        <div class="dialog-text text-truncate" *ngIf="dialogData.dialogText && !dialogData.dynamicContent && !dialogData.htmlContent" accessible>
            {{dialogData.dialogText | translate}}
        </div>
        <div *ngIf="dialogData.dynamicContent">
            <div class="dynamic-content" *ngFor="let item of dialogData.dialogText">
                <div class="item">
                    <markdown [data]="item.text">{{item.text}}</markdown>
                </div>
            </div>
        </div>
        <div *ngIf="dialogData.htmlContent" class="dialog-text flex-column" [innerHTML]="dialogData.dialogText" (click)="openLink($event)"></div>
    </div>

    <div class="dialog-bottom-section">

        <div
            class="page-actions"
            [class.primary-button-only]="!dialogData.primaryButtonHidden && dialogData.secondaryButtonHidden"
            [class.buttons-layout-row]="dialogData.buttonsLayout == 'row'"
            [class.buttons-top-margin]="!dialogData.dialogText"
        >
            <button
                class="primary-action-button action-button"
                (click)="primaryButtonClick()"
                *ngIf="!dialogData.primaryButtonHidden"
                mat-flat-button
                [color]="styleService.isDarkMode ? 'accent' : 'primary'"
            >
                {{dialogData.primaryButtonText | translate}}
            </button>

            <button
                class="secondary-action-button action-button"
                [ngClass]="dialogData.secondaryButtonAppearance == 'anchor' ? 'anchor-appearance' : 'button-appearance'"
                (click)="secondaryButtonClick()"
                *ngIf="!dialogData.secondaryButtonHidden"
                mat-flat-button
                [color]="styleService.isDarkMode ? 'accent' : 'primary'"
            >
                {{dialogData.secondaryButtonText | translate}}
            </button>
        </div>
    </div>

</div>
