export const urlParamsWhitelist = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    '_gl',
    'gclid',
    'dclid',
    '_ga',
];
