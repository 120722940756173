<div class="module-bar">
	<div class="_inner">
		<button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
			<mat-icon>{{appService.backIcon}}</mat-icon>
		</button>
        <span class="title flex-grow-1">{{'rank' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
	</div>
</div>


<div class="module-content">
	<div>
		<div class="rank-row d-flex align-items-center" [class.active]="rank.is" *ngFor="let rank of appService.user.ranks">
			<div class="_image mr-2">
				<img [src]="rank.image"/>
			</div>
			<div class="_title flex-grow-1">
				{{rank.name}}
			</div>
			<div class="_end _was" *ngIf="rank.was">
				<mat-icon>check</mat-icon>
			</div>
			<div class="_end _is" *ngIf="rank.is">
				<img [src]="appService.user.avatar" />
			</div>
			<div class="_end _will" *ngIf="rank.will">
				<div class="trans-chip no-margins">{{'more' | translate}} {{rank.diff}} {{'points' | translate}}</div>
			</div>
		</div>
	</div>
</div>