<div class="d-flex flex-column h-100" [class._LG]="isSizeLG">
    <div class="flex-grow-1 scroll-y p-3" style="padding-top:3px !important;">
        <div class="my-panel p-3 text-base mb-3">
            <table class="tbl-totals">
                <tr class="text-md">
                    <th class="font-weight-bold">{{'PO.ORDER_TOTAL' | translate}}</th>
                    <td class="font-weight-bold">{{$storage.order.printData.variables.TOTAL_AMOUNT | lcurrency:'1.2-2'}}</td>
                </tr>
                <tr class="text-md" *ngIf="$storage.order.printData.variables.TOTAL_PAYMENTS">
                    <th>{{'PO.PAID' | translate}}</th>
                    <td>{{($storage.order.printData.variables.TOTAL_PAYMENTS - $storage.order.printData.variables.CHANGE) | lcurrency:'1.2-2'}}</td>
                </tr>
                <tr class="text-md" *ngIf="$storage.order.printData.variables.CHANGE">
                    <th>{{'PO.CHANGE' | translate}}</th>
                    <td>{{$storage.order.printData.variables.CHANGE | lcurrency:'1.2-2'}}</td>
                </tr>

                <tr class="text-md" *ngIf="$storage.order.printData.variables.BAL_DUE !== $storage.order.printData.variables.TOTAL_AMOUNT">
                    <th>{{'PO.REMAINED_PAY' | translate}}</th>
                    <td>{{$storage.order.printData.variables.BAL_DUE | lcurrency:'1.2-2'}}</td>
                </tr>
            </table>

            <ng-container *ngIf="$storage.order.payments?.length">
                <hr />
                <div class="payers-carousel">
                    <button class="_near" type="button" mat-icon-button (click)="scrollPayers(-1)">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                    <div #widgetsContent class="middle">
                        <div class="pm-badge" *ngFor="let pm of $storage.order.payments">
                            <div class="_cap text-truncate">{{pm.name}}</div>
                            <div>{{pm.total | lcurrency:'1.1-1'}}</div>
                            <div *ngIf="pm.tip">
                                (<span class="inline">{{'gratuity' | translate}}: </span>
                                {{pm.tip | lcurrency:'1.1-1'}})
                            </div>
                        </div>

                    </div>
                    <button class="_far" type="button" mat-icon-button (click)="scrollPayers(1)">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <mat-accordion multi="true">
            <!--START SPLIT OPTIONS-->
            <mat-expansion-panel class="_expansion-panel" *ngIf="$storage.stepsMode == 'partial'" expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="_panel-title">
                        <mat-icon svgIcon="partial payment"></mat-icon>
                        <div class="flex-grow-1 text-truncate">{{'partial_payment' | translate}}</div>
                        <div *ngIf="partialPaymentActive" class="text-lighter">{{partialPayment.amount | lcurrency}}</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="text-center">
                    <div class="btn-group d-flex" role="group" aria-label="split options group">
                        <button type="button" class="btn btn-outline-tabit btn-gratuity w-100 px-0"
                                *ngFor="let opt of splitPaymentOptions"
                                [ngClass]="{'txt-btn': opt.isText ,'active': opt == partialPayment}"
                                (click)="setSplitPaymentOption(opt)"
                                [disabled]="opt.disabled">
                            <div class="d-flex flex-column justify-content-around" style="min-height:60px;">
                                <div>{{opt.text}}</div>
                                <div class="text-xs" *ngIf="!opt.isManual">{{opt.subText | lcurrency:currencyFormat}}</div>
                            </div>
                        </button>
                    </div>
                    <div class="mt-3" *ngIf="!$storage.check">
                        <button type="button" mat-stroked-button color="primary" (click)="payByOffers()" *ngIf="!payByOffersResult">{{'pay_by_items' | translate}}</button>
                        <button type="button" mat-flat-button color="primary" (click)="payByOffers()" *ngIf="payByOffersResult">{{'pay_by_items' | translate}}</button>
                    </div>
                </div>
            </mat-expansion-panel>
            <!--END SPLIT OPTIONS-->
            <!--START GRATUITY-->
            <mat-expansion-panel *ngIf="$storage.enableGratuity" expanded="true" class="_expansion-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title class="_panel-title">
                        <mat-icon svgIcon="tip"></mat-icon>
                        <div class="flex-grow-1">{{'gratuity' | translate}}</div>
                        <div *ngIf="gratuity.amount || gratuity.type == 'n'" class="text-lighter">
                            <span *ngIf="gratuity.type == 'n'" class="inline">{{'PO.WITHOUT' | translate}}&nbsp;|&nbsp;</span>
                            {{(gratuity.amount || 0) | lcurrency}}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="text-center">
                    <div class="btn-group d-flex" role="group" aria-label="gratuity group">
                        <button type="button" class="btn btn-outline-tabit btn-gratuity txt-btn w-100"
                                [ngClass]="{'active': gratuity.type == 'n'}"
                                (click)="setGratuity('n', 0)">
                            <div>{{'without_gratuity' | translate}}</div>
                        </button>
                        <!--SEATED GRATUITY-->
                        <button type="button" class="btn btn-outline-tabit btn-gratuity w-100"
                                *ngFor="let opt of $storage.gratuities"
                                [ngClass]="{'active': gratuity.type == 'p' && gratuity.percent == opt}"
                                (click)="setGratuity('p', opt)">
                            {{opt}}%
                        </button>
                        <!--end SEATED GRATUITY-->
                        <button type="button" class="btn btn-outline-tabit btn-gratuity w-100 txt-btn"
                                (click)="setManualGratuity('m')"
                                [ngClass]="{'active': gratuity.type == 'm'}">
                            {{'other' | translate}}
                        </button>
                    </div>
                </div>
                <div *ngIf="$storage.gratuityPAWarning && gratuity.amount" class="text-danger text-center font-semi-bold mt-3 text-md animated faster slideInUp">
                    {{$storage.gratuityPAWarning}}
                </div>
            </mat-expansion-panel>
            <!--END GRATUITY-->
        </mat-accordion>
    </div>

    <div class="text-center p-3" *ngIf="hasCCInfo">
        <div id="pm-select-btn" class="my-panel p-3 text-lg d-flex align-items-center" [matMenuTriggerFor]="pmsMenu">
            <mat-icon svgIcon="credit card"></mat-icon>
            <div class="flex-grow-1 d-flex justify-content-center  align-items-center">
                <div class="no-wrap">
                    {{('PMS.' + $storage.wallet.ccinfo.paymentType) | translate}}&nbsp;
                    {{$storage.wallet.ccinfo.displayPan}}
                </div>
                <span class="text-base ml-2" *ngIf="$storage.wallet.ccinfo.paymentType == 'creditCard'">{{$storage.wallet.ccinfo | paymentExp}}</span>
                <!--<span class="text-base ml-2 text-muted text-truncate">{{$storage.wallet.ccinfo.comment}}</span>-->
            </div>
            <mat-icon>more_vert</mat-icon>
        </div>

        <mat-menu #pmsMenu="matMenu" backdropClass="fwmenu-bdc" panelClass="fwmenu-pc" classList="fwmenu-pc" yPosition="above">
            <button mat-menu-item *ngFor="let pm of $storage.wallet.pms" [ngStyle]="{'width.px': menuWidth}" (click)="selectWalletPM(pm)">
                <mat-icon>credit_card</mat-icon>
                <span>
                    {{('PMS.' + pm.paymentType) | translate}}:&nbsp;
                    {{pm.displayPan}}
                    <span class="text-xs inline ml-1" *ngIf="pm.paymentType == 'creditCard'">{{pm | paymentExp}}</span>
                    <span class="text-xs inline ml-1 text-muted">&nbsp;{{pm.comment}}</span>
                </span>
            </button>
            <button mat-menu-item [ngStyle]="{'width.px': menuWidth}" (click)="submit(true)" class="border-top">
                <div class="font-bold text-center">
                    {{'SELECT_ALTERNATE_PYMENT' | translate}}...
                </div>
            </button>
        </mat-menu>
    </div>

    <div class="p-3 pb-4">
        <button
            mat-flat-button
            color="primary"
            class="lg rounded btn-block"
            type="button"
            [disabled]="options.subMode == 'tip' && !gratuity.amount"
            (click)="submit()"
        >
            <div class="d-flex justify-content-between align-items-center">
                <span>{{'PAY' | translate}}</span>
                <span *ngIf="total">{{ (partialPayment.amount || 0) + (gratuity.amount || 0) | lcurrency}}</span>
                <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
            </div>
        </button>
    </div>
</div>