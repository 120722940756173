<div class="tiles-container-wrapper" [ngClass]="tilesContainerType">
    <div class="tiles-container-header">
        <span class="header-spacer" *ngIf="appService.isDesktop() && showMoreButton"></span>
        <h1 [attr.aria-label]="screenReaderLabel || tilesContainerTitle" class="tiles-container-title" accessible>{{tilesContainerTitle}}</h1>
        <a class="tiles-container-link" [routerLink]="[tilesContainerLink]" accessible *ngIf="appService.isDesktop() && showMoreButton">
            {{'WEB_CONTAINER.book_a_table.show_more' | translate}}
            <span class="directional-arrows">
                <span class="directional-arrow">&rsaquo;</span>
                <span class="directional-arrow">&rsaquo;</span>
                <span class="directional-arrow">&rsaquo;</span>
            </span>
        </a>
    </div>
    
    <div #tilesContent id="tiles-content" class="tiles-container-content" [@tilesContentAnimation]="animateTilesContent">
        <ng-content></ng-content>
    </div>

    <a class="tiles-container-link" [routerLink]="[tilesContainerLink]" *ngIf="!appService.isDesktop() && showMoreButton">
        {{'WEB_CONTAINER.book_a_table.show_more' | translate}}
        <span class="directional-arrows">
            <span class="directional-arrow">&rsaquo;</span>
            <span class="directional-arrow">&rsaquo;</span>
            <span class="directional-arrow">&rsaquo;</span>
        </span>
    </a>
</div>
