export interface MAP_AREA {
    key: string;
    customLabel?: string;
    boundingbox: {
        south: number;
        west: number;
        north: number;
        east: number;
    },
    location: {
        lat: number;
        lng: number;
    }
}

let IL_MAP_AREAS: MAP_AREA[] = [
    {
        key: 'tel_aviv',
        boundingbox: { 'south': 32.030000, 'west': 34.730000, 'north': 32.1380000, 'east': 34.8400000 },
        location: { lat: 32.071374, lng: 34.787407 },
    },
    {
        key: 'jerusalem',
        boundingbox: { 'south': 31.6033282, 'west': 34.8208416, 'north': 31.8826655, 'east': 35.2650458 },
        location: { lat: 31.771122, lng: 35.211536 },
    },
    {
        key: 'haifa',
        boundingbox: { 'south': 32.3630705, 'west': 34.8624634, 'north': 32.8932183, 'east': 35.2013754 },
        location: { lat: 32.804236, lng: 34.985421 },
    },
    {
        key: 'center',
        boundingbox: { 'south': 31.7572168, 'west': 34.6604636, 'north': 32.4127459, 'east': 35.0516079 },
        location: { lat: 32.164094, lng: 34.830836 },
    },
    {
        key: 'north',
        boundingbox: { 'south': 32.3870989, 'west': 35.0288074, 'north': 33.3356317, 'east': 35.8950234 },
        location: { lat: 32.963869, lng: 35.489044 },
    },
    {
        key: 'south',
        boundingbox: { 'south': 29.4877892, 'west': 34.2674994, 'north': 31.8728243, 'east': 35.4769165 },
        location: { lat: 31.261055, lng: 34.798211 },
    }
];

let US_MAP_AREAS: MAP_AREA[] = [
    {
        key: 'dallas',
        boundingbox: { 'south': 32.641621, 'west': -96.927991, 'north': 33.006686, 'east': -96.589407 },
        location: { lat: 32.776700, lng: -96.797000 },
    },
    {
        key: 'fort_worth',
        boundingbox: { 'south': 32.641621, 'west': -97.490103, 'north': 32.861236, 'east': -97.208372 },
        location: { lat: 32.751016, lng: -97.330501 },
    },
    {
        key: 'irving_grapevine',
        boundingbox: { 'south': 32.761393, 'west': -97.208372, 'north': 33.006686, 'east': -96.927991 },
        location: { lat: 32.862085, lng: -97.028198 },
    },
    {
        key: 'burleson',
        boundingbox: { 'south': 32.462023, 'west': -97.490103, 'north': 32.641621, 'east': -97.208372 },
        location: { lat: 32.537540, lng: -97.321543 },
    },
    {
        key: 'plano',
        boundingbox: { 'south': 33.006686, 'west': -96.829025, 'north': 33.088689, 'east': -96.589407 },
        location: { lat: 33.053761, lng: -96.746241 },
    },
    {
        key: 'frisco',
        boundingbox: { 'south': 33.088689, 'west': -96.927991, 'north': 33.220520, 'east': -96.733223 },
        location: { lat: 33.148387, lng: -96.818747 },
    },
    {
        key: 'mckinney',
        boundingbox: { 'south': 33.088689, 'west': -96.733223, 'north': 33.258715, 'east': -96.558001 },
        location: { lat: 33.193257, lng: -96.651813 },
    }
];

let AU_MAP_AREAS: MAP_AREA[] = [
    {
        key: 'sydney',
        boundingbox: { 'south': -34.118347, 'west': 150.520929, 'north': -33.578140, 'east': 151.343021 },
        location: { lat: 32.776700, lng: -96.797000 },
    },
];

export function getMapAreas(locale: string): MAP_AREA[] {
    switch (locale.toLowerCase()) {
        case 'he-il':
            return getPreparedMapAreas(IL_MAP_AREAS);
        case 'en-us':
            return getPreparedMapAreas(US_MAP_AREAS);
        case 'en-au':
            return getPreparedMapAreas(AU_MAP_AREAS);
        default:
            return getPreparedMapAreas(IL_MAP_AREAS);
    }
}

export function setMapAreas(locale: string, mapAreas) {
    const preparedMapAreas = getPreparedMapAreas(mapAreas);
    switch (locale.toLowerCase()) {
        case 'he-il':
            IL_MAP_AREAS = preparedMapAreas;
            break;
        case 'en-us':
            US_MAP_AREAS = preparedMapAreas;
            break;
        case 'en-au':
            AU_MAP_AREAS = preparedMapAreas;
            break;
    }
}

// We want the locations to be randomized on each session
function getPreparedMapAreas(mapAreas) {
    if (!mapAreas?.length) return mapAreas;

    mapAreas.forEach((mapArea) => {
        const { south, west, north, east } = mapArea.boundingbox;
        const randomLat = getRandomCoordinate(south, north);
        const randomLng = getRandomCoordinate(west, east);

        mapArea.location.lat = randomLat;
        mapArea.location.lng = randomLng;
    });

    return mapAreas;
}

function getRandomCoordinate(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}