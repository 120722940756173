<mat-list-item
    class="generic-list-item site-item wl-app-primary-background-color"
    [class.with-time-slots]="timeSlots && timeSlots.length"
    [class.single]="single !== undefined"
    [class.last-clicked]="isLastOpened"
    [class.in-focus]="inFocus"
    [class.disabled]="showBadges && !isPreOrderBadge && (startFromBadge || notAvailableBadge)"
    tabindex="0"
>
<div matListItemAvatar>
    <app-generic-item-image
        [imageAlt]="site.name" 
        class="item-image" 
        [imageUrl]="site.image"
        [customFallbackImage]="appService.images.default_site_image"
        [imageCustomCss]="imageCustomCss"
    ></app-generic-item-image>
</div>

    <div matLine class="d-flex align-items-start" accessible [attr.aria-label]="site.name" role="link">
        <div class="flex-grow-1 text-truncate">
            <div class="_title text-truncate">{{site.name}}</div>
            <ng-container *ngIf="showBadges">
                <span *ngIf="isShowBadge('not_available_now')" class="site-badge">{{ 'not_available_now' | translate }}</span>
                <span *ngIf="isShowBadge('preorder')" class="site-badge preorder">{{ 'preorder' | translate }}</span>
                <span *ngIf="isShowBadge('available_from')" class="site-badge">{{ 'available_from' | translate }} {{ startFromBadge | date: 'H:mm' | AMPMConverter }}</span>
            </ng-container>
            <div class="rating-container" *ngIf="site.googlePlaceDetails && (appService.googlePlaceDetailsSubject | async).showRating">
                <rating [site]="site" [oneStar]="true"></rating>
            </div>
        </div>
        <div *ngIf="appService.isAuthUser() && appService.isApp && !appService.skin">
            <favorite-fab [site]="site"></favorite-fab>
        </div>
        <!-- <div *ngIf="showPepperPay && site.hasLeumiPayment" class="pay-span">
            <span>{{ 'pepper_pay.pepper_bonus' | translate }}</span>
        </div> -->
    </div>

    <div matLine class="_details" *ngIf="siteAddressAriaLabel" accessible role="link" [attr.aria-label]="siteAddressAriaLabel">
        <ng-container *ngIf="showDistance && site.distance">
            <span *ngIf="site.formattedDistance === 'far_away'" class="distance">{{site.formattedDistance | translate}}</span>
            <span *ngIf="site.formattedDistance !== 'far_away'" class="distance">{{site.formattedDistance | translate}} {{unit | translate}}</span>
            <span class="separator">|</span>
        </ng-container>
        <span>{{site.address}}</span>
    </div>

    <!-- This is the place if you want badges as a new line -->
    <!--
    <div *ngIf="showBadges && areThereBadges" matLine class="_details with-details">
        <span *ngIf="isPreOrderBadge" class="site-badge preorder">הזמנה מוקדמת</span>
        <span *ngIf="startFromBadge" class="site-badge preorder">החל מ {{ startFromBadge | date: 'H:mm' | AMPMConverter }}</span>
        <span *ngIf="notAvailableBadge" class="site-badge preorder">לא זמין כעת</span>
    </div>
    -->

    <time-slots matLine class="time-slots-container d-flex" *ngIf="timeSlots?.length" [timeSlots]="timeSlots" [areaDescriptions]="areaDescriptions" [siteId]="site._id"></time-slots>

</mat-list-item>