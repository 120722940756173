import { Component, Inject, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
//import { SignaturePad } from 'angular2-signaturepad';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']

})
export class MessageDialogComponent implements OnInit {
	mode: string;
    value: string;
    closeButtonActive: boolean = true;
    triedToSubmit: boolean = false;

    // @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;

    // public signaturePadOptions: Object = {
    //     'minWidth': 5,
    //     'canvasWidth': 300,
    //     'canvasHeight': 250
    // };

	constructor(
		public dialogRef: MatDialogRef<MessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.mode = this.data.mode || 'confirm';
        this.value = this.data.value;
        if (this.data.hasOwnProperty('closeButtonActive')) this.closeButtonActive = this.data.closeButtonActive;
    }

    ngOnInit() { }

    clear() {
        delete this.value;
        //this.signaturePad.clear();
    }

    // drawComplete() {
    //     this.value = this.signaturePad.toDataURL();
    // }

    confirmPrompt(form) {
        this.triedToSubmit = true;
        if (this.data.template == 'textInput' && !this.value?.length) return;
        if (form.valid) {
            this.dialogRef.close(this.value);
        }
    }

    get hasError() {
        if (this.data.template !== 'textInput') return false;
        return this.triedToSubmit && !this.value?.length;
    }
}
