<div
    class="payment-dialog-container"
    [ngSwitch]="dialogData.formType"
>
    <div mat-dialog-title class="d-flex justify-content-between align-items-center">
        <h1 tabindex="0" [attr.aria-label]="'ACCESS.payment-dialog-open' | translate" mat-dialog-title class="d-flex align-items-center" cdkFocusInitial>
            {{'EX_PAYMENT.dialog_title' | translate}}
        </h1>

        <div
            aria-label="close"
            class="close-dialog"
            (click)="closeDialog()"
        >
            <button mat-icon-button><mat-icon>close</mat-icon></button>
        </div>
    </div>

    <ng-container *ngSwitchCase="'genericIframeForm'">
        <div class="cc-validation-disclaimer" *ngIf="dialogData.selectedPaymentProviderType == 'CreditGuardPaymentProvider'">
            {{ 'MESSAGES.CREDIT_CARD_3DS_DISCLAIMER' | translate }}
            <br>
            <span class="transaction-acknowledge">{{ 'EX_PAYMENT.transaction_acknowledge_gcs' | translate }}</span>
        </div>
        <div class="cc-validation-disclaimer" *ngIf="dialogData.selectedPaymentProviderType == 'RsvCreditGuardPaymentProvider'">
            <span class="transaction-acknowledge">{{ 'EX_PAYMENT.transaction_acknowledge_rsv' | translate }}</span>
        </div>

        <iframe
            #iframeInstance
            id="ex_iframe"
            [src]="dialogData.trustedIframeUrl"
            (load)="iframeSrcChange(iframeInstance)"
        ></iframe>
    </ng-container>

    <!-- embed the iframe payment fields in a native form. relevant for Card-Pointe only. -->
    <ng-container *ngSwitchCase="'cardPointeForm'">
        <form
            name="tokenform"
            id="tokenform"
            class="embedded-payment-form"
        >
            <iframe
                #iframeInstance
                id="ex_iframe"
                [src]="dialogData.trustedIframeUrl"
                [scrolling]="false"
            ></iframe>

            <div class="embedded-payment-form-button-container">
                <button
                    class="embedded-payment-form-button rounded"
                    mat-flat-button
                    (click)="embeddedFormSubmit()"
                >
                    {{'SERVICE_BUTTON.pay_generic' | translate}}
                </button>
            </div>

            <div class="provider-logo">
                <img [src]="appService.base('assets/images/secured_by_cardconnect.png')" alt="cardpoint">
            </div>
        </form>
    </ng-container>

    <!-- embed the payment fields in a native form. relevant for Cibus only. -->
    <ng-container *ngSwitchCase="'cibusForm'">
        <form class="embedded-payment-form non-iframe-form">

            <div class="pay-form-title">
                {{dialogData.customFormTitle}}
            </div>

            <mat-form-field appearance="outline">
                <input
                    name="cardnumber"
                    id="cardNumber"
                    matInput
                    placeholder="{{'GIFT_CARD_SHOP.modals.pay.card_number' | translate}}"
                    type="tel"
                    required
                    pattern="[0-9]*"
                    #nonIframeField
                >
            </mat-form-field>

            <div class="embedded-payment-form-button-container">
                <button
                    class="embedded-payment-form-button rounded"
                    mat-flat-button
                    (click)="nonIframeFormSubmit(nonIframeField)"
                    [disabled]="!nonIframeField.validity?.valid"
                >
                    {{'SERVICE_BUTTON.pay_generic' | translate}}
                </button>
            </div>
        </form>
    </ng-container>

    <!-- remote-rendering of local form inputs. relevant for Heartland only. -->
    <ng-container *ngSwitchCase="'heartlandForm'">
        <div class="heartland-form form-wrapper">
            <form
                id="iframes"
                action=""
                method="GET"
            >
                <!-- <div id="ss-banner"></div> -->

                <div class="provider-logo">
                    <img [src]="appService.base('assets/images/heartland-logo.png')" alt="heartland">
                </div>

                <div class="form-row">
                    <label for="iframesCardNumber">{{'general_credit_card.form.card_number' | translate}}</label>
                    <div id="iframesCardNumber"></div>
                </div>

                <div class="form-row">
                    <label for="iframesCardExpiration">{{'general_credit_card.form.expiration' | translate}}</label>
                    <div id="iframesCardExpiration"></div>
                </div>

                <div class="form-row">
                    <label for="iframesCardCvv">{{'general_credit_card.form.cvv' | translate}}</label>
                    <div id="iframesCardCvv"></div>
                </div>

                <!-- <div class="form-row">
                    <label for="iframesBillingZip">Zip Code:</label>
                    <div id="iframesBillingZip"></div>
                </div> -->

                <br />
                <div class="error-text" *ngIf="embeddedForm.errorText">
                    {{embeddedForm.errorText}}
                </div>
                <div id="iframesSubmit"></div>

            </form>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'stripeForm'">
        <!-- Display a payment form -->
        <form
            name="tokenform"
            id="ex_iframe"
            class="embedded-payment-form stripe-form"
        >   
            <!--Stripe.js injects the Payment Element-->
            <div id="stripe-payment-element"></div>

            <!-- add extra address fields -->
            <div id="stripe-address-element"></div>

            <div class="error-text" *ngIf="embeddedForm.errorText">{{embeddedForm.errorText}}</div>

            <div class="embedded-payment-form-button-container">
                <button
                    class="embedded-payment-form-button rounded"
                    mat-flat-button
                    (click)="embeddedFormSubmit('StripePaymentProvider')"
                    [disabled]="!embeddedForm?.submitButtonEnabled"
                >
                    {{'SERVICE_BUTTON.pay_generic' | translate}}
                </button>
            </div>
            <div class="provider-logo">
                <img [src]="appService.base('assets/images/stripe.png')" alt="stripe">
            </div>
        </form>
    </ng-container>

    <ng-container *ngSwitchCase="'braintreeForm'">
        <!-- Display a payment form -->
        <form
            name="tokenform"
            id="ex_iframe"
            class="embedded-payment-form"
        >
            <div class="modal-inner-content">
                <div style="position:relative; min-height:170px; padding:1rem;">
                    <div id="braintree-dropin-container"></div>
                </div>
            </div>

            <div *ngIf="!brainTreeInstance" class="provider-logo">
                <img [src]="appService.base('assets/images/braintree-logo.png')" alt="braintree">
            </div>
        </form>
    </ng-container>
</div>