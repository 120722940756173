<!-- Tracked Order Template -->
<div *ngIf="order" class="tracked-order-item" (click)="_redirectToOrderTracker()">
    <div class="icon" [style.background-image]="image | safeStyle"></div>
    <div class="details-container flex-grow-1">
        <div class="tracked-site-name"><h3 *ngIf="siteName">{{ order.orgName }}</h3></div>
        <!-- Sorry Mark, my AMPMConverter is not strong enough to deal with all date: -->
        <div class="date">{{ order.created | date: 'd MMM y': '': appService.localeId }}, {{ order.created | date: 'HH:mm' | AMPMConverter }} </div>
        <div class="details">
            <span>{{ order.serviceType | translate }}</span>
            <span> | </span>
            <span>{{ amount | lcurrency }}</span>
        </div>
    </div>
    <div *ngIf="!order.onlySummaryDisplay" class="button-container">
        <mat-icon class="icon" [svgIcon]="'track-order'"></mat-icon>
        <span>{{'order_tracker.track' | translate}}</span>
    </div>
    <div *ngIf="order.onlySummaryDisplay" class="button-container">
        <img class="icon" [src]="appService.base('assets/images/svg/bill_new.svg')" />
        <span>{{'summary' | translate}}</span>
    </div>
</div>
