<button aria-label="close" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
    <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title class="text-center">{{ 'choose_navigation_option' | translate }}</h1>
<div mat-dialog-content>
    <mat-form-field class="w-100">
        <mat-label>{{ 'choose_option' | translate }}</mat-label>
        <mat-select [(value)]="selectedOption">
            <mat-option *ngFor="let opt of data.options" [value]="opt" (click)="chooseOption(opt)">
                {{opt | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>