import { Component, NgZone, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { AccountService } from '../../_core/account.service';

@UntilDestroy()
@Component({
    selector: 'app-my-wallet',
    templateUrl: './my-wallet.component.html',
    styleUrls: ['./my-wallet.component.scss'],
    host: {
        'class': 'host-default',
    },
})
export class MyWalletComponent implements OnInit, OnDestroy {

    constructor(
        public appService: AppService,
        public accountService: AccountService,
        private route: ActivatedRoute,
        public ngZone: NgZone,
    ) { }

    @ViewChild('addPMForm') myForm: NgForm;

    ngOnInit() {
        let step = this.route.snapshot.paramMap.get('step');
        if (step) this.accountService.pmStep = step;

        // Enable / Disable the Card.IO scan option
        // this.accountService.canScanPM();
        // fetch all user details only if empty
        if (!this.accountService.userDetails?._id) this.accountService.getUserInfo();

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.appService.redirect(['/profile/my-profile'])
                    });
                });
        }
    }

    ngOnDestroy() {
        this.accountService.setInitialCcInfoState();
    }

    submitAddPmForm() {
        this.accountService.addPM()
        .then(() =>{
            this.myForm.resetForm();
        })
    }

}
