<form (ngSubmit)="_signIn(signInForm)" #signInForm="ngForm" [formGroup]="formGroup">
    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
        <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div class="title" tabindex="0">{{'MESSAGES.sign_in_by_phone_message' | translate}}</div>
            <div class="subtitle" tabindex="0">{{'MESSAGES.sign_in_by_phone_description' | translate}}</div>

            <mat-form-field appearance="outline">
                <mat-label> {{'phone' | translate}} </mat-label>
                <input  autocomplete="off" 
                        matInput
                        [readonly]="disabled"
                        [(ngModel)]="formModel.phone"
                        formControlName="phoneControl"
                        type="tel"
                        #phoneInputElement
                        required
                        class="ltr-field">
                <mat-icon matSuffix (click)="formModel.phone = null" *ngIf="formModel.phone">close</mat-icon>
            </mat-form-field>

            <ng-container>
                <button *ngIf="!disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{'continue' | translate}}</button>
                <button *ngIf="disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                    <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </ng-container>

            <div class="back-btn pt-2" *ngIf="!dialogMode">
                <button type="button" class="btn btn-lg btn-link wl-ignore-theme" (click)="_back()">
                    &lsaquo; {{'back' | translate}}
                </button>
            </div>

        </div>
    </div>
</form>