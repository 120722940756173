<div class="module-bar trans wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{'kitchens' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<div id="sites-module-content" class="module-content" ngxDetectScroll (onScroll)="handleScroll($event)" [bottomOffset]="200" [topOffset]="200">
    <div style="padding:15px">
        <div class="row row-sm">
            <div class="col-6" *ngFor="let tag of tags | orderBy: 'lang.he-il.label'">
                <food-category-item [tagId]="tag._id" parentRoute="/home/tags" multiline></food-category-item>
            </div>
        </div>
    </div>
</div>