import { Component, OnInit, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { AppService } from '../../app.service';
import { LoyaltyService } from '../../_core/loyalty.service';
import { get } from 'lodash-es';

@Component({
    selector: 'club-card',
    templateUrl: './club-card.component.html',
    styleUrls: ['./club-card.component.scss'],
    animations: [
        trigger('clubCardAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.7)' }),
                animate('0.3s 0s ease-in-out', style({ opacity: 1, transform: 'scale(1.05)' })),
                animate('0.2s 0s ease-in', style({ transform: 'scale(1)' }))
            ]),
        ])
    ]
})
export class ClubCardComponent implements OnInit {

    @Input() index: number;
    @Input() club: any;

    public benefits: any;
    public pointsBenefit: any;
    public border: any = {'border-right': '0.5rem solid purple'};

    constructor(
        public appService: AppService,
        public loyaltyService: LoyaltyService,
    ) { }

    ngOnInit() {
        this.initData();
    }

    initData() {
        if (this.club?.benefits?.length) {
            let slice = 1;
            this.pointsBenefit = this.club.benefits.find(benefit => benefit.programBenefitTypeAlias === 'PointsBenefit');
            if ((this.club?.points[0]?.points > 0) && (this.club?.points[0]?.prePaid > 0) ||
                get(this.loyaltyService.domain, 'defaults.giftCardShop.topUpEnabled', false) && this.club?.points[0]?.points > 0
            ) slice = 0;
            if (!get(this.loyaltyService.domain, 'defaults.giftCardShop.topUpEnabled', false)) {
                if ((this.club?.points[0]?.points == 0 && this.club?.points[0]?.prePaid == 0) || !this.club?.points?.length) slice = 2;
            }
            this.benefits = this.club.benefits.slice(0, slice);
        }
    }

    _redirectToMyBenefits() {
        let queryParams: any = {clubName: this.club.clubName};
        this.appService.redirect(['profile/my-benefits'], { queryParams });
    }

}
