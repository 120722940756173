import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import hexRgb, { RgbaObject } from 'hex-rgb';
import { get } from 'lodash-es';

let styleSheet;
@Injectable({
    providedIn: 'root'
})
export class StyleService {

    public customStyleId: string = 'wl-custome-theme';
    public mainAppComponentTransparent: boolean = false;
    public isDarkMode: boolean = false;

    constructor(
        private platformService: Platform,
    ) {
        // track color scheme
        const darkSchemeMediaMatch = window.matchMedia('(prefers-color-scheme: dark)');
        if (darkSchemeMediaMatch.matches) {
            darkSchemeMediaMatch.addEventListener('change', e => this.trackBrowserColorScheme(e));
            this.trackBrowserColorScheme(darkSchemeMediaMatch);
        }
    }

    setDomainCustomTheme(theme) {

        const domainCustomTheme = theme;
        const loyaltyTheme = this.getLoyaltyTheme(domainCustomTheme);
        const tabitOrderTheme = this.getTabitOrderTheme(domainCustomTheme);
        this.addPermanentStylesheet(domainCustomTheme);

        // each custom WL theme-rule affects multiple nested elements
        const themeRules = {
            primaryColor: '',
            secondaryColor: '',
            appPrimaryBackgroundColor: '',
            appSecondaryBackgroundColor: '',
            appSecondaryTextColor: '',
            cardsBackgroundColor: '',
            footerFontColor: '',
        }

        // app primary theme color
        if (domainCustomTheme.appPrimaryColor) {
            themeRules.primaryColor = `
                .wl-app-primary-text-color-background { background: ${domainCustomTheme.appPrimaryColor} !important }
                .wl-app-primary-text-color { color: ${domainCustomTheme.appPrimaryColor} !important }

                .to-mobile-dialog svg g .dynamic-stroke { stroke: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog svg g .dynamic-fill { fill: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .to-summary-button { background-color: ${domainCustomTheme.appPrimaryColor} !important;}
                .to-mobile-dialog .modifiers-group-increment-box button { background-color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .quantity-actions .item-price { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .item-price { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item .quantity-action-button.add-item mat-icon { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item.active .item-title-summary { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.lines-design .modifiers-group-item .modifiers-group-item-content .item-edit mat-icon { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active { background: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container .modifiers-group-items-collapse { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .to-mobile-dialog .modifiers-group-container .modifiers-group-items-collapse .modifiers-group-items-collapse-title mat-icon { color: ${domainCustomTheme.appPrimaryColor} !important; }
                .scrollbar-container.tou-offer-scroll ::-webkit-scrollbar-thumb { background: ${domainCustomTheme.appPrimaryColor} !important; }
                .scrollbar-container.tou-offer-scroll ::-webkit-scrollbar-thumb:hover { background: ${domainCustomTheme.appPrimaryColor} !important; }
                .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow { color: ${domainCustomTheme.appPrimaryColor} !important; }

                .to-loyalty-dialog mat-dialog-container { background: ${domainCustomTheme.appPrimaryColor} !important}
                search-widget .list-container .list-items .list-item.selected { color: ${domainCustomTheme.appPrimaryColor} !important}
                search-widget .search-widget-container .reservation-details-item.selected * { color: ${domainCustomTheme.appPrimaryColor} !important; }
                app-tabitbook-start .editor-scroll .editor-list > div.active { color: ${domainCustomTheme.appPrimaryColor} !important}

                main-message-dialog .dialog-container .dialog-bottom-section .page-actions .primary-action-button,
                main-message-dialog .dialog-container .dialog-bottom-section .page-actions .secondary-action-button.button-appearance  { background-color: ${domainCustomTheme.appPrimaryColor} !important; }
                main-message-dialog .dialog-container .dialog-bottom-section .page-actions .secondary-action-button.anchor-appearance,
                main-message-dialog .dialog-container .dialog-top-section .close-dialog .mat-icon { color: ${domainCustomTheme.appPrimaryColor} !important; }

                app-to-delay-dialog form .mat-mdc-dialog-content mat-form-field mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper { color: ${domainCustomTheme.appPrimaryColor} !important; }
            `;
        }

        // app secondary theme color
        if (domainCustomTheme.appSecondaryColor) {
            themeRules.secondaryColor = `

            `;
        }

        // app primary bg color
        if (domainCustomTheme.appPrimaryBackgroundColor) {
            themeRules.appPrimaryBackgroundColor = `
                .wl-app-primary-background-color {background: ${domainCustomTheme.appPrimaryBackgroundColor} !important}
                .module-bar.splash::before {background: ${domainCustomTheme.appPrimaryBackgroundColor} !important}
                app-root .module-bar, #dashboard-module-content .book-selection-bar-section, .app-bottom-links {border-color: ${domainCustomTheme.appPrimaryBackgroundColor} !important;}
                .delay-dialog-select, .address-type-dialog-select {background: ${domainCustomTheme.appPrimaryBackgroundColor} !important} {background: ${domainCustomTheme.appPrimaryBackgroundColor} !important}
                app-out-of-use .message-container { background-color: ${domainCustomTheme.appPrimaryBackgroundColor} !important; }
            `;
        }

        // app scondary bg color
        if (domainCustomTheme.appSecondaryBackgroundColor) {
            themeRules.appSecondaryBackgroundColor = `
                .wl-app-secondary-background-color {background: ${domainCustomTheme.appSecondaryBackgroundColor} !important}
                search-widget .list-container .list-items .list-item.selected { background: ${domainCustomTheme.appSecondaryBackgroundColor} !important}
                search-widget .search-widget-container .reservation-details-item.selected { background: ${domainCustomTheme.appSecondaryBackgroundColor} !important}
                app-tabitbook-start .editor-scroll .editor-list > div.active { background: ${domainCustomTheme.appSecondaryBackgroundColor} !important}
            `;
        }

        // app secondary text color
        if (domainCustomTheme.appSecondaryTextColor) {

            const ignoreGiftCardSelectors = '.date-error-message, gift-cards, gift-cards * mat-label, gift-cards * .mat-mdc-form-field-label, gift-cards .module-bar.splash .title, .wheel-selection-list .amount-options-container .base-amount-with-bonus *';
            const ignoreFontColorForSelectors = `.secondary-action-button .mdc-button__label, app-to-delay-dialog *, ${ignoreGiftCardSelectors + ', '}.wl-ignore-theme, .wl-ignore-theme *, .wl-footer-font-color *, .module-bar .title, mat-form-field *:not(.mat-mdc-select-value-text *), .app-bottom-links *, .mat-mdc-datepicker-content *, .dialog-validation, .to-menu-navigation > div.active, .to-loyalty-club-name, .menu-item .item-info .item-info-section .order-modifiers .group-list *, .menu-item .item-info .item-info-section .order-modifiers > div, .menu-item .item-info .item-info-section .order-modifiers, .to-menu-navigation:not(.scorlling) > div.active .menu-category-name, .card > .card-footer .card-distance, to-future-dialog *, search-widget > .list-container *, search-widget .reservation-details-container *, .reservation-details-item.preference:not(.standby) *, .editor-scroll .editor-list *, .mat-mdc-error, .to-mobile-dialog app-tou-offer-itemgroup .quantity-action-button.add-item *, .to-mobile-dialog .modifiers-group-items-collapse-title *, .to-mobile-dialog .modifiers-group-items-collapse-title .title-text, .modifiers-group-item-content .header-title-container .edit-item-selection .item-price *, .modifiers-group-item-content .header-title-container .edit-item-selection .item-edit *, .to-mobile-dialog .group-item-quantity *, .to-mobile-dialog .group-item-quantity, .to-mobile-dialog .group-error-badge, .to-mobile-dialog .modifiers-group-container.rectangle-design .header-title-container .header-title, .to-mobile-dialog .add-remove-actions *, .title-info-text,  .title-info-text *, .to-offer-dialog *`;

            const initializeColorForSelectors = '.gratuity-option, app-tou-loyalty-dialog .to-loyalty-club-name';

            const enforceFontColorForSelectors =  'mat-form-field:not(.offer-remarks-input) mat-label, mat-form-field:not(.offer-remarks-input) .mat-mdc-form-field-label, mat-form-field:not(.offer-remarks-input) .mat-mdc-input-element::placeholder, mat-form-field:not(.offer-remarks-input) .mat-form-field-appearance-outline, mat-form-field:not(.offer-remarks-input) .mat-mdc-form-field-outline, .mat-mdc-select-arrow, .mat-mdc-select-value, .mat-mdc-expansion-panel-header-description, .mat-mdc-expansion-indicator::after, mat-form-field:not(.offer-remarks-input) .mat-mdc-tab-label-content, mat-form-field:not(.offer-remarks-input) .mat-mdc-input-element:disabled, .module-bar.splash .title, input, mat-datepicker-input';
            const enforceFontColorForInvalidSelectors = 'app-sign-up-form .mat-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick';
            const enforceBackgroundColorForSelectors = '.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline, .mat-mdc-radio-inner-circle';
            const enforceBorderColorForSelectors = '.mat-mdc-checkbox-frame, .mat-mdc-radio-outer-circle';

            themeRules.appSecondaryTextColor = `
                .wl-secondary-text-color {color: ${domainCustomTheme.appSecondaryTextColor} !important;}
                .wl-secondary-text-fill {fill: ${domainCustomTheme.appSecondaryTextColor} !important;}
                .wl-secondary-text-stroke {stroke: ${domainCustomTheme.appSecondaryTextColor} !important;}
                ${initializeColorForSelectors} {color: initial !important;}
                *:not(${ignoreFontColorForSelectors}), ${enforceFontColorForSelectors} {color: ${domainCustomTheme.appSecondaryTextColor} !important;}
                app-to4-gratuity .mobile-gratuity-options-container .gratuity-option {color: initial !important}
                ${enforceFontColorForInvalidSelectors} {color: ${domainCustomTheme.appPrimaryColor} !important}
                ${enforceBackgroundColorForSelectors} {background-color: ${domainCustomTheme.appSecondaryTextColor} !important;}
                ${enforceBorderColorForSelectors} {border-color: ${domainCustomTheme.appSecondaryTextColor} !important;}
                app-site-details .action-disabled span {border: 1px solid ${domainCustomTheme.appSecondaryTextColor} !important}
                */ app-my-wallet .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {background: ${domainCustomTheme.appSecondaryTextColor} !important} */
                app-my-wallet .mat-mdc-form-field-appearanc-legacy .mat-mdc-select-arrow {color: ${domainCustomTheme.appSecondaryTextColor} !important}
                app-my-wallet .mat-mdc-expansion-panel-header-description, app-my-wallet .mat-mdc-expansion-indicator::after {color: ${domainCustomTheme.appSecondaryTextColor} !important}
                search-widget .search-widget-container .reservation-details-item:not(.selected) .value {color: ${domainCustomTheme.appSecondaryTextColor} !important}
                search-widget .list-container .list-items .list-item {color: ${domainCustomTheme.appSecondaryTextColor}}

                .to-mobile-dialog .to-summary-button .offer-dialog-price { color: ${domainCustomTheme.appSecondaryTextColor} !important; }
                .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .header-title-items .header-title { color: ${domainCustomTheme.appSecondaryTextColor} !important; }
                .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .header-title-items .item-price { color: ${domainCustomTheme.appSecondaryTextColor} !important; }
                .to-mobile-dialog .modifiers-group-container.rectangle-design .modifiers-group-item.active .quantity-actions .item-price { color: ${domainCustomTheme.appSecondaryTextColor} !important; }
            `;
        }

        // app cards color
        if (domainCustomTheme.cardsBackgroundColor) {
            const enforceBackgroundColorForSelectors = '.mat-mdc-expansion-panel-content, mat-dialog-container';
            themeRules.cardsBackgroundColor = `
                ${enforceBackgroundColorForSelectors} {background: ${domainCustomTheme.cardsBackgroundColor} !important}
                .wl-cards-background-color:not(.mat-mdc-selected) {background: ${domainCustomTheme.cardsBackgroundColor} !important}
                body.light-mode .wl-cards-background-color.mat-mdc-selected span.mat-mdc-option-text {color: ${domainCustomTheme.cardsBackgroundColor} !important}
                .wl-cards-background-color mat-dialog-container {background: ${domainCustomTheme.cardsBackgroundColor} !important}
                .module-content > ._body ._links > ._link {border-bottom: 1px solid ${domainCustomTheme.cardsBackgroundColor} !important;}
                .split-payment .mat-form-field-appearance-outline .mat-mdc-form-field-outline {background-color: ${domainCustomTheme.cardsBackgroundColor} !important}
                to-future-dialog .title-container, to-future-dialog .data-container {background: ${domainCustomTheme.cardsBackgroundColor} !important}
                app-tabitbook-start .bar-wrapper .dv-btn {background: ${domainCustomTheme.cardsBackgroundColor} !important}
            `;
        }

        // app footer icons color
        if (domainCustomTheme.footerFontColor) {
            themeRules.footerFontColor = `
                .wl-footer-font-color {color: ${domainCustomTheme.footerFontColor} !important;}
            `;
        }

        const domainCustomCss = `
            <style id=${this.customStyleId}>
                ${themeRules.primaryColor}
                ${themeRules.secondaryColor}
                ${themeRules.appPrimaryBackgroundColor}
                ${themeRules.appSecondaryBackgroundColor}
                ${themeRules.cardsBackgroundColor}
                ${themeRules.footerFontColor}
                ${themeRules.appSecondaryTextColor}
                ${loyaltyTheme}
                ${tabitOrderTheme}
            </style>
        `;

        // We initialize it here to add the stylesheet again
        styleSheet = domainCustomCss;

        this.addCustomeStyleTheme();
    }

    getLoyaltyTheme(theme) {
        let loyaltyTheme = '';

        if (get(theme, 'loyalty')) {
            // Benefit details override
            const benefitDetailsTheme = theme.loyalty?.benefitDetails;
            let tabitOrderLoyaltyTheme;
            if (theme.loyalty?.tabitOrder) {
                tabitOrderLoyaltyTheme = `
                    .wl-loyalty-main-background { background: ${theme.loyalty?.tabitOrder.bannerMainBackgroundColor} !important }
                    to-loyalty-banner .to-club-login .club-login-background { background: linear-gradient(270deg, ${theme.appPrimaryColor} 39%, ${theme.loyalty?.tabitOrder.bannerMainBackgroundColor} 100%)!important }
                `
            }
            loyaltyTheme = `
                benefit-details .benefit-container { background-color: ${benefitDetailsTheme.mainBackgroundColor} !important }
                benefit-details .benefit-title-container { background-color: ${benefitDetailsTheme.mainTitleBackgroundColor} !important }
                benefit-details .benefit-description { background-color: ${benefitDetailsTheme.secondaryTitleBackgroundColor} !important }
                ${tabitOrderLoyaltyTheme}
            `
        };
        return loyaltyTheme;
    }

    getTabitOrderTheme(theme) {
        let tabitOrderTheme = '';

        if (get(theme, 'tabitOrder')) {
            // Benefit details override
            const mobilePaymentTheme = get(theme, 'tabitOrder.mobilePaymentButton');
            if (mobilePaymentTheme) {
                tabitOrderTheme = `
                    #btnCheckout * { color: ${mobilePaymentTheme.textColor} !important };
                `
            }
        };
        return tabitOrderTheme;
    }

    // add and remove color-scheme class from body
    trackBrowserColorScheme(event) {
        // For now we'd prevent dark-mode in Android devices
        const isAndroidApp = window['cordova'] && this.platformService.ANDROID;

        if (!isAndroidApp) {
            const classToAdd = event.matches ? "dark-mode" : "light-mode";
            const classToRemove = event.matches ? "light-mode" : "dark-mode";
            document.body.classList.add(classToAdd);
            document.body.classList.remove(classToRemove);
            this.isDarkMode = !!event.matches;
        }
    }

    addPermanentStylesheet(domainCustomTheme) {
        // app footer icons color scheme (light|dark):
        // assuming all icons are black by default, prevent or force inversion of colors according to footerIconsColorScheme
        let footerIconsColorScheme = '', appPrimaryColor = '', secondaryColor = '';
        if (domainCustomTheme.footerIconsColorScheme) {
            footerIconsColorScheme = `
                footer-links-button button mat-icon {filter: ${domainCustomTheme.footerIconsColorScheme == 'dark' ? 'none' : 'invert(1)'} !important;}
                /* cancel all other footer-icons inversion rules */
                .icons-container:not(.custom-icons-scheme) :not(._IOS) svg path, :not(._IOS) svg path, .icons-container:not(.custom-icons-scheme) :not(._IOS) svg rect, :not(._IOS) svg rect, .icons-container:not(.custom-icons-scheme) :not(._IOS) svg circle, :not(._IOS) svg circle {filter: none !important}
            `;
        }

        // app primary theme color
        if (domainCustomTheme.appPrimaryColor) {
            appPrimaryColor = `
                :root { --primary-theme-500: ${domainCustomTheme.appPrimaryColor} !important }
                .wl-primary-text-color { color: ${domainCustomTheme.appPrimaryColor} !important}
                .wl-primary-color-border { border-color: ${domainCustomTheme.appPrimaryColor} !important}
            `;

            // change the 'main-message-dialog' svg icons colors with feColorMatrix
            this.setSvgColorMatrix(domainCustomTheme.appPrimaryColor);
        }

        // app secondary theme color
        if (domainCustomTheme.appSecondaryColor) {
            secondaryColor = `
                :root { --primary-theme-100: ${domainCustomTheme.appSecondaryColor} !important}
                app-tabitbook-start .bar-wrapper .dv-btn.changed {background: ${domainCustomTheme.appSecondaryColor} !important}
            `;
        }

        const domainPermanentCss = `
            <style>
                ${footerIconsColorScheme}
                ${appPrimaryColor}
                ${secondaryColor}
                .wl-footer-font-color {color: ${domainCustomTheme?.appSecondaryTextColor} !important;}
                .wl-footer-background-color {background: ${domainCustomTheme?.footerBackgroundColor} !important}
                .icon { filter: grayscale(100%); }
                benefit-details .icon-separator { filter: saturate(0.1) }
                sites-search-tools button.search { display:none }
            </style>
        `;

        this.addCustomeStyleTheme(domainPermanentCss);
    }

    addCustomeStyleTheme(customStylesheet?) {
        this.insertStylesheet(customStylesheet || styleSheet)
    }

    insertStylesheet(sheet) {
        setTimeout(() => {
            // using $("style").append() ended in multiple css decelerations, so we use native DOM js (it's fully supported and quicker than jQuery)
            document.head.insertAdjacentHTML("beforeend", sheet);
            // add the main wl theme class
            document.body.classList.add("wl-custom-theme");
        }, 0)
    }

    removeCustomeStyleTheme() {
        const script = document.getElementById(this.customStyleId);
        if (script) document.head.removeChild(script);
    }

    // change svg icons colors with feColorMatrix filter
    setSvgColorMatrix(hexColor) {
        // transform hex color to feColorMatrix values
        try {
            const rgbColor: RgbaObject = hexRgb(hexColor, { format: 'object' })
            const newFeColorMatrix = `
                0 0 0 0 ${rgbColor.red / 255.0}
                0 0 0 0 ${rgbColor.green / 255.0}
                0 0 0 0 ${rgbColor.blue / 255.0}
                0 0 0 1 0
            `;

            // the feColorMatrix filter is applied to all svg icons with 'filter: url(#primary-app-color-svg-filter)'
            document.defaultView['primary-app-color-svg-filter-matrix']?.setAttribute('values', newFeColorMatrix);
        } catch (error) {
            console.error('setSvgColorMatrix error: ' + error)
        }
    };

    rgb2hex(color) {
        const hex = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        if (!hex) return color;
        return (hex && hex.length === 4) ? '#' +
            ('0' + parseInt(hex[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(hex[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(hex[3], 10).toString(16)).slice(-2) : '';
    }
}
