
<block-ui>
	<div class="module-bar wl-app-primary-background-color">
		<div class="_inner">
			<button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
				<mat-icon>{{appService.backIcon}}</mat-icon>
			</button>
            <span class="title flex-grow-1">{{'my_wallet' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
			<!--<button class="menu" mat-icon-button (click)="appService.toggleAppSidenav()">
				<mat-icon class="search">menu</mat-icon>
			</button>-->
		</div>
	</div>

	<div class="module-content wl-app-primary-background-color">
        <div class="accordion-container" *ngIf="!accountService.loading">
            <mat-accordion>
                <mat-expansion-panel class="no-pad wl-app-primary-background-color" [expanded]="accountService.pmStep === 'general'" (opened)="accountService.pmStep = 'general'">
                    <mat-expansion-panel-header class="wl-app-primary-background-color">
                        <mat-panel-title class="font-bold">{{appService.translate('my_wallet')}}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngIf="accountService?.userDetails?.wallet?.payments?.length">
                        <div class="text-far">
                            <div style="position:relative;opacity: 0.5; font-size:12px; font-weight:600; padding:0 16px; top:-15px">{{'default' | translate}}</div>
                        </div>
                        <div>
                            <div class="py-2 d-flex justify-content-start align-items-center border-top" *ngFor="let payment of accountService.userDetails.wallet.payments">
                                <button type="button" mat-icon-button (click)="accountService.deletePM(payment, $event);">
                                    <mat-icon>delete</mat-icon>
                                </button>

                                <div class="text-md pl-2 no-wrap">
                                    <div class="inline">{{ 'PMS.' + payment.paymentType | translate}}: </div>
                                    {{payment.displayPan}}
                                </div>
                                <div class="text-xs pl-2" *ngIf="accountService.showExpDate(payment)">
                                    <div class="text-xs pl-2">
                                        {{payment | paymentExp}}
                                    </div>
                                    <div class="text-xs pl-2" *ngIf="payment.expired">
                                        ({{ 'MESSAGES.CARD_EXPIRED' | translate }})
                                    </div>
                                </div>
                                <i class="px-1 text-xs pl-2 text-truncate flex-grow-1">
                                    {{payment.comment}}
                                </i>

                                <div class="px-1">
                                    <mat-radio-button [checked]="payment.isDefault" (change)="accountService.pmDefaultChanged(payment);" class="m-0"></mat-radio-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center text-md p-2" *ngIf="!accountService?.userDetails?.wallet?.payments?.length">
                        {{'MESSAGES.NO_PMS_FOUND' | translate}}
                    </div>

                    <!--
                    <div class="text-far mt-4"  *ngIf="!accountService.userDetails.wallet.payments.length">
                        <button type="button" mat-flat-button color="primary" (click)="accountService.pmStep = 'addpm'">
                            {{'add_pm'  | translate}}
                        </button>
                    </div>
                    -->

                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="accountService.pmStep === 'addpm'" (opened)="accountService.pmStep = 'addpm'" class="wl-app-primary-background-color">
                    <mat-expansion-panel-header class="wl-app-primary-background-color">
                        <mat-panel-title class="font-bold">{{'add_pm' | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <form (ngSubmit)="submitAddPmForm()" #addPMForm="ngForm">
                        <mat-form-field>
                            <mat-select [(value)]="accountService.ccinfo.paymentType" (selectionChange)="accountService.paymentMethodChanged()" placeholder="{{'credit_type' | translate}}" required>
                                <mat-option class="wl-cards-background-color" *ngFor="let pm of accountService.paymentMethods" [value]="pm">{{'PMS.' + pm | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="accountService.ccinfo.paymentType" [ngSwitch]="accountService.ccinfo.paymentTypeView" class="form-condensed">
                            <div *ngSwitchCase="'Cibus'">
                                <mat-form-field >
                                    <input [(ngModel)]="accountService.ccinfo.pan" name="pan" matInput placeholder="{{'credit_card_number' | translate}}" type="tel" required
                                           pattern="[0-9]*">
                                </mat-form-field>
                            </div>
                            <div *ngSwitchCase="'10bis'">
                                <mat-form-field >
                                    <input [(ngModel)]="accountService.ccinfo.pan" name="pan" matInput placeholder="{{'credit_card_number' | translate}}" type="tel" required
                                           pattern="[0-9]*">
                                </mat-form-field>
                            </div>

                            <mat-form-field *ngIf="accountService.ccinfo.paymentType !== 'creditCard'">
                                <input [(ngModel)]="accountService.ccinfo.comment" name="comment" matInput placeholder="{{'comments' | translate}}">
                            </mat-form-field>

                        </div>
                        <div class="buttons-container">
                            <!-- <button type="button" mat-flat-button color="primary" (click)="accountService.scanPM()" [disabled]="!accountService.canScan">
                                <mat-icon>camera_alt</mat-icon>
                                {{'scan_pm' | translate}}
                            </button> -->
                            <button type="submit" mat-flat-button color="primary" [disabled]="!addPMForm.valid || !accountService.ccinfo.paymentType">
                                {{'save' | translate}}
                            </button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <!-- This is required for Android small screen when the keyboard is open, because otherwise there will be no scroll down to the button (it will be covered by the keyboard) -->
        <div class="keyboard-spacer"></div>
	</div>
</block-ui>
