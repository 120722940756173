<div class="module-bar wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.goBack()">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{'my_addresses' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<div class="module-content wl-app-primary-background-color">
    <div class="accordion-container">
        <mat-accordion class="wl-app-primary-background-color">

            <mat-expansion-panel class="no-pad wl-app-primary-background-color" [disabled]="loadingAddresses || !addresses.length" #addressesPanel>
                <mat-expansion-panel-header class="wl-app-primary-background-color">
                    <mat-panel-title class="font-bold">
                        {{'my_addresses' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!loadingAddresses && addresses.length">
                    <div class="text-far">
                        <div class="default">{{'default' | translate}}</div>
                    </div>
                    <mat-list>
                        <mat-divider></mat-divider>
                        <ng-container *ngFor="let address of addresses; let i = index">
                            <mat-list-item>
                                <div matListIcon class="big">
                                    <button mat-icon-button (click)="deleteAddress(address);">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <h4 matLine>{{ address.city }}</h4>
                                <!-- Not pretty ngIf, but it's better than wait until the office would change something -->
                                <p *ngIf="address.street !== 'Unnamed Road'" matLine>
                                    {{ address.street }}
                                    {{ address.house }}
                                </p>
                                <p matLine>
                                    <small *ngIf="address.entrance">{{'entrance' | translate}} {{ address.entrance }}&nbsp;</small>
                                    <small *ngIf="address.floor">{{'floor' | translate}} {{ address.floor }}&nbsp;</small>
                                    <small *ngIf="address.apartment">{{'apartment' | translate}} {{ address.apartment }}&nbsp;</small>
                                </p>
                                <p matLine>
                                    <small *ngIf="address.notes">{{'notes' | translate}} - {{ address.notes }}&nbsp;</small>
                                </p>
                                <mat-radio-button name="default-selector" [value]="address" [checked]="address.default" (change)="changeDefault($event)"></mat-radio-button>
                            </mat-list-item>
                            <mat-divider *ngIf="i !== (addresses.length - 1)"></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel #addressFormPanel class="wl-app-primary-background-color">
                <mat-expansion-panel-header class="wl-app-primary-background-color">
                    <mat-panel-title class="font-bold">{{'new_address' | translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <address-form (addressSubmitted)="saveAddress($event)"></address-form>
            </mat-expansion-panel>

        </mat-accordion>
    </div>

    <!-- This is required for Android small screen when the keyboard is open, because otherwise there will be no scroll down to the button (it will be covered by the keyboard) -->
    <div class="keyboard-spacer"></div>
</div>
