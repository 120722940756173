import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatSelectChange } from '@angular/material/select';
import { Subscription, combineLatest } from 'rxjs';

import { MAP_AREA, getMapAreas } from '../../_core/static/map-areas';
import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { LocationLabeled, LocationService } from '../../_core/location.service';
import { Address, AddressesService } from '../../_core/addresses.service';

@Component({
    selector: 'location-widget',
    templateUrl: './location-widget.component.html',
    styleUrls: ['./location-widget.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('locationInitializedTrigger', [

            transition(':enter', [
                style({ opacity: 0 }),
                animate(500, style({ opacity: 1 })),
            ])

        ])
    ]
})
export class LocationWidgetComponent implements OnInit, OnDestroy {

    @Input() houseRequired: boolean = false;

    public selectedLocationType: string = 'actual';
    public locationInitialized: boolean = false;
    public actualLocationAvailable: boolean;
    public locationLabeled: LocationLabeled;

    public savedAddresses: any;

    public mapAreas: MAP_AREA[] = [];

    private locationSubscription: Subscription;

    constructor(
        private appService: AppService,
        private dialogsService: DialogsService,
        private locationService: LocationService,
        private addressesService: AddressesService,
    ) {
        this.appService.domain.subscribe(domain => {
            if (domain) {
                this.locationService.setMapAreas();
                this.mapAreas = getMapAreas(this.appService.appConfig.locale);
            }
        });
    }

    ngOnInit() {

        // If we have addresses stored in the local-storage, we set the default as the first one.
        try {
            this.savedAddresses = JSON.parse((localStorage && localStorage.getItem('cachedAddresses')) || '[]');
            if (this.savedAddresses) {
                this.savedAddresses = this.savedAddresses.reverse();
            }
        } catch (err) {
            console.debug('Bad addresses at local cache: ' + err.message);
        }

        this.locationSubscription = combineLatest([
            this.locationService.locationInitialized,
            this.locationService.location,
            this.locationService.actualLocationAvailable,
        ]).subscribe(([ locationInitialized, locationLabeled, available ] : [ boolean, LocationLabeled, boolean ]) => {
            // console.log('=== LOCATION/WIDGET === actualLocation is:', available ? 'available!' : 'not available!');
            this.locationInitialized = locationInitialized;
            this.locationLabeled = locationLabeled;
            this.actualLocationAvailable = available;

            // Choose the correct value at the hidden dropdown:
            if (locationLabeled.actual) this.selectedLocationType = 'actual';
            else if (locationLabeled.savedAddressId) this.selectedLocationType = 'saved_address__'+locationLabeled.savedAddressId;
            else if (locationLabeled.area) this.selectedLocationType = 'area__'+locationLabeled.area;
            else this.selectedLocationType = 'manual'; // It's different from the option value, by design!
        });
    }

    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
    }

    private chooseActualLocation() {
        this.locationService.chooseActualLocation();
    }

    private chooseSpecifiedLocation(areaKey: string) {
        let area = this.mapAreas.find(area => area.key === areaKey);
        if (!area || !area.location || !area.key) throw new Error(`Cannot choose area: '${areaKey}' as location`);
        this.locationService.chooseSpecifiedLocation(area.location, 'areas.' + area.key, areaKey);
    }

    private chooseSavedAddress(addressId: string) {
        let savedAddress = this.savedAddresses.find(address => address._id === addressId);
        if (!savedAddress || !savedAddress.location || !savedAddress.formatted_address) throw new Error(`Cannot choose saved address: '${addressId}.`);
        this.locationService.chooseSpecifiedLocation(savedAddress.location, savedAddress.formatted_address, null, savedAddress._id);
    }

    public chooseManualAddress() {
        this.dialogsService.showAddressDialog({houseRequired: this.houseRequired}).subscribe((address: Address) => {
            if (!address) return;
            if (!address.location || !address.formatted_address) throw new Error('Cannot choose address as location');
            if (address.house) {
                this.addressesService.saveAddress(address).subscribe(savedAddress => {
                    console.debug('=== LOCATION/WIDGET === Going to set address default:', savedAddress);
                    // this.addressesService.setDefault(savedAddress); // Currently not waiting for
                }, err => {
                    console.error('Error saving address:', err);
                });
            }
            this.locationService.chooseSpecifiedLocation(address.location, address.formatted_address);
        });
    }

    public pickAtLocationDropdown(matSelectChange: MatSelectChange) {
        //console.log('=== LOCATION/WIDGET === Location type picked:', matSelectChange.value);
        if (matSelectChange.value == 'actual') {
            this.chooseActualLocation();
        } else if (matSelectChange.value == 'manual-address') {
            this.chooseManualAddress();
        } else if (/^saved_address__/.test(matSelectChange.value)) {
            this.chooseSavedAddress(matSelectChange.value.replace('saved_address__', ''));
        } else if (/^area__/.test(matSelectChange.value)) {
            this.chooseSpecifiedLocation(matSelectChange.value.replace('area__', ''));
        }
    } 
}
