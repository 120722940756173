import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

import { SearchDetails, ORGANIZATION_BUCKET_TYPES } from '../_core/OrganizationBucket';
import { AppService } from '../app.service';
import { OrganizationsService } from '../_core/organizations.service';
import { Address, AddressesService } from '../_core/addresses.service';
import { LocationService } from '../_core/location.service';
import { DialogsService } from '../_core/dialogs.service';

declare const $: any;

const defaultSearchType = ORGANIZATION_BUCKET_TYPES.search;
const bookingSearchType = ORGANIZATION_BUCKET_TYPES.booking;
const orderSearchType = ORGANIZATION_BUCKET_TYPES.order;

const webSearchBucketPickMap = {

    init(webSearchDetails: SearchDetails): SearchDetails {
        let searchDetails: SearchDetails = {};
        if (typeof webSearchDetails.skip !== undefined) searchDetails.skip = webSearchDetails.skip;
        return searchDetails;
    },

    [defaultSearchType](webSearchDetails: SearchDetails): SearchDetails {
        if (!webSearchDetails) return {};
        let searchDetails: SearchDetails = this.init(webSearchDetails);
        if (typeof webSearchDetails.query !== undefined) searchDetails.query = webSearchDetails.query;
        if (webSearchDetails.tags && webSearchDetails.tags.length) searchDetails.tags = webSearchDetails.tags;
        if (webSearchDetails.rating && typeof webSearchDetails.rating !== undefined) searchDetails.rating = webSearchDetails.rating;
        if (webSearchDetails.price && typeof webSearchDetails.price !== undefined) searchDetails.price = webSearchDetails.price;
        if (webSearchDetails.externalDeliveryLink && webSearchDetails.externalDeliveryLink === true) searchDetails.externalDeliveryLink = webSearchDetails.externalDeliveryLink;
        return searchDetails;
    },

    [orderSearchType](webSearchDetails: SearchDetails): SearchDetails {
        if (!webSearchDetails) return {};
        let searchDetails: SearchDetails = this.init(webSearchDetails);
        if (webSearchDetails.tags && webSearchDetails.tags.length) searchDetails.tags = webSearchDetails.tags;
        if (webSearchDetails.rating && webSearchDetails.rating !== undefined) searchDetails.rating = webSearchDetails.rating;
        if (webSearchDetails.price && webSearchDetails.price !== undefined) searchDetails.price = webSearchDetails.price;
        if (webSearchDetails.service) searchDetails.service = webSearchDetails.service;
        if (webSearchDetails.externalDeliveryLink && webSearchDetails.externalDeliveryLink === true) searchDetails.externalDeliveryLink = webSearchDetails.externalDeliveryLink;
        return searchDetails;
    },

    [bookingSearchType](webSearchDetails: SearchDetails): SearchDetails {
        if (!webSearchDetails) return {};
        let searchDetails: SearchDetails = this.init(webSearchDetails);
        if (webSearchDetails.booking) searchDetails.booking = webSearchDetails.booking;
        return searchDetails;
    },
};

@Injectable({
    providedIn: 'root'
})
export class WebContainerService {

    private loadMoreSitesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loadMoreSitesObservable = this.loadMoreSitesSubject.asObservable();

    public mobileTabsSidenav: MatSidenav;
    public mobileTabsSidenavOpened = false;

    public webSelectionSearchEvent: EventEmitter<boolean> = new EventEmitter();

    private webSearchDetails: BehaviorSubject<SearchDetails> = new BehaviorSubject(null);

    constructor(
        private appService: AppService,
        private dialogsService: DialogsService,
        private organizationsService: OrganizationsService,
        private addressesService: AddressesService,
        private locationService: LocationService,
    ) { }

    public updateSharedSearch(searchDetails: SearchDetails): void {

        // When performing a search-query we ignore the filters, and vise-versa
        if (defaultSearchType) {
            if (searchDetails && searchDetails.query) {
                searchDetails.tags = null;
                searchDetails.rating = null;
                searchDetails.price = null;
                searchDetails.externalDeliveryLink = null;
            } else if (
                searchDetails &&
                (searchDetails.tags && searchDetails.tags.length > 0) ||
                (searchDetails.rating) ||
                (searchDetails.price) ||
                (searchDetails.externalDeliveryLink)
            )   {
                searchDetails.query = null;
            }
        }

        this.organizationsService.setPreSearchDetails(webSearchBucketPickMap[defaultSearchType](searchDetails), defaultSearchType);
        this.organizationsService.setPreSearchDetails(webSearchBucketPickMap[orderSearchType](searchDetails), orderSearchType);
        this.organizationsService.setPreSearchDetails(webSearchBucketPickMap[bookingSearchType](searchDetails), bookingSearchType);

        this.webSearchDetails.next(searchDetails);

    }

    public clearSharedSearch(): void {
        this.webSearchDetails.next(null);
    }

    public getSharedSearch(type: ORGANIZATION_BUCKET_TYPES): Observable<SearchDetails> {
        return this.organizationsService.getPreSearchDetails(type);
    }

    public getSharedSearchValue(): SearchDetails {
        return this.webSearchDetails.getValue();
    }

    public getMainSearchQuery(): string {
        let searchDetails = this.webSearchDetails.getValue();
        return (searchDetails && searchDetails.query) || '';
    }

    public setSidenav(sidenav: MatSidenav) {
        this.mobileTabsSidenav = sidenav;
    }

    public toggle(expression): void {
        this.mobileTabsSidenavOpened = typeof expression !== 'undefined' ? expression : !this.mobileTabsSidenavOpened;
        this.mobileTabsSidenav.toggle(this.mobileTabsSidenavOpened);
    }

    public scrollToResults(condition) {
        // 2020-04-12 - Decided that we do NOT want the screen to "jump" to top everytime the user clicks 'search'.
        //$('.web-container-inner-wrapper').scrollTop(0);

        /*
        if (!this.appService.isDesktop() && condition) {
            // The condition (webSearchDetails) is NOT null when the user initiates the filters / search.
            // We want this scroll to be executed only when the user is the one who initiated the search.

            //document.querySelector('.site-list').scrollIntoView({ behavior: 'smooth', block: 'start' });
            // The above scrollIntoView works nicely on Desktops, but doesn't work well at all on iOS... So had to switch to more "classic" way of handling the scroll behaviour here.
            // 2020-02-05: No need for this scroll anymore, since we changed the header height on Mobile, so that the spinner and the results are visible.
            // 2020-02-06: Note: We execute a scroll-down to the results only when clicking the web header search button.
            setTimeout(() => {
                let scrollTopValue = (600 - 5*16 - 16/2); // Header Height - Tabbar Height - the green separator at the bottom of the header
                $('.web-container-inner-wrapper').animate({ scrollTop: scrollTopValue }, 400);
            }, 200); // Required in order to let the browser render first > "breathe" > and then scroll
        }
        */
    }

    public scrollToLastVisitedOrg(lastVisitedOrgId) {
        setTimeout(() => {
            if (!lastVisitedOrgId) return;
            let siteIdSelector = '#site-id-' + lastVisitedOrgId;
            let siteElement = $(siteIdSelector);
            if (!siteElement.length) return;

            if (lastVisitedOrgId && document.querySelector(siteIdSelector) && document.querySelector(siteIdSelector+' .site-item')) {
                document.querySelector(siteIdSelector).scrollIntoView({ behavior: 'auto', block: 'center' });
                document.querySelector(siteIdSelector+' .site-item').classList.add('in-focus');
                setTimeout(() => {
                    if (document.querySelector(siteIdSelector + ' .site-item')) document.querySelector(siteIdSelector + ' .site-item').classList.remove('in-focus');
                }, 2500);
            }
        }, 0);
    }

    public loadMoreSites(loadMore: boolean) {
        this.loadMoreSitesSubject.next(loadMore);
    }

    redirectToBooking() {
        let route = this.appService.redirectValueByLocale('book-a-table', 'ROUTE');
        this.appService.redirect([route]);
    }

    searchDelivery(type: any, selectedLocationType?: any) {
        event.stopPropagation();
        this.webSelectionSearchEvent.emit(true);

        let webSearchDetails = this.getSharedSearchValue() || {};
        this.updateSharedSearch({ ...webSearchDetails, service: type, skip: 0 });
        let route = this.appService.redirectValueByLocale('deliveries', 'ROUTE');

        if (type == 'takeaway') {
            this.appService.redirect([route]);
        } else if (selectedLocationType == 'manual' || selectedLocationType == 'actual') {
            this.appService.redirect([route]);
        } else {
            // Not using houseRequired, to use premise addresses
            this.dialogsService.showAddressDialog({houseRequired: false}).subscribe((address: Address) => {
                if (!address) return;
                if (!address.location || !address.formatted_address) throw new Error('Cannot choose address as location');
                if (address.house) {
                    this.addressesService.saveAddress(address).subscribe(savedAddress => {
                        console.debug('=== WEB/CONTAINER/SERVICE === Going to set address default:', savedAddress);
                        this.addressesService.setDefault(savedAddress); // Currently not waiting for
                    }, err => {
                        console.error('Error saving address:', err);
                    });
                }
                this.locationService.chooseSpecifiedLocation(address.location, address.formatted_address);
                this.appService.redirect([route]);
            });
        }
    }
}
