import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-animation-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './animation-dialog.component.html',
  styleUrls: ['./animation-dialog.component.scss']
})
export class AnimationDialogComponent {
	mode: string = 'alert';
	constructor(
		public dialogRef: MatDialogRef<AnimationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data.audio) {
			var audio = new Audio(this.data.audio);
			audio.play();
		}
	}

}
