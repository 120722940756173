import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input() starId;
  @Input() rating;
  
  @Output() click: EventEmitter<number> = new EventEmitter();

  constructor() {
  }
  
  public starClassName = 'star-rating-blank';

  ngOnInit() {

    this.fillStar();

  }

  starClicked() {
    this.click.emit(this.starId);
  }

  fillStar() {
    if (this.rating >= this.starId) {
      this.starClassName = 'star-rating-filled';
    }
  }
}