<div class="module-bar trans wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{'short_code' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
        <!-- <mat-icon *ngIf="domain?.multiLanguageEnabled && !appService.isApp"
            [svgIcon]="appService.localeId == 'he-IL' ? 'en-language' : 'he-language'"
            (click)="translationService.changeUsedLanguage()"></mat-icon> -->
    </div>
</div>

<div class="module-content wl-app-primary-background-color  short-code" *ngIf="shortCode">
    <div class="top-empty-div"></div>
    <div class="short-code-container">
        <div class="explanation">
            <div class="flex-center flex-column" style="margin-bottom: 0.5rem;">
                <div style="margin-bottom: 0.3rem;"  class="icon-container">
                    <mat-icon [svgIcon]="'amount'"></mat-icon>
                    
                </div>
                <span>1. &nbsp; {{'short_code_flow.steps.first' | translate}}</span>
            </div>
            <div class="flex-center flex-column" style="margin-bottom: 0.5rem;">
                <div style="margin-bottom: 0.3rem;"  class="enter-code">
                    <mat-icon [svgIcon]="'enter_code'"></mat-icon>
                </div>
                <span>2. &nbsp; {{'short_code_flow.steps.second' | translate}}</span>
            </div>
            <div class="flex-center flex-column">
                <div style="margin-bottom: 0.3rem;" class="icon-container">
                    <mat-icon [svgIcon]="'pay'"></mat-icon>
                </div>
                <span>3. &nbsp; {{'short_code_flow.steps.third' | translate}}</span>
            </div>
        </div>

        <div class="code-container">
            <div>
                <div class="font-size-24 flex-center">{{'payment_code' | translate}}</div>
                <div class="flex row-reverse font-weight-bold">
                    <div class="font-size-32" *ngFor="let number of code">{{number}} &nbsp;</div>
                </div>
            </div>
            <div class="top-border"></div>

            <div class="flex row-reverse" style="margin-top: 1rem" *ngIf="expirationTime?.length">

                <span>{{'payment_code_expiration_till' | translate : {time:expirationTime} }}</span>
                <span> &nbsp;</span>
                <span >{{'payment_code_expiration' | translate : {minutes:shortCode.expirationWithin} }}</span>

            </div>
        </div>

        <div style="margin-top: 3rem;">
            <div>
                <span>{{'balance' | translate}}: &nbsp;</span>
                <span>{{customer?.points[0]?.prePaid | lcurrency}}</span>

            </div>
            <div class="flex-center" (click)="onQuickBalanceClick()"
                *ngIf="domain?.defaults?.giftCardShop?.topUpEnabled">
                <span class="quick-load-line">{{'quickLoad' | translate}}</span>
                <span> &nbsp;> </span>
            </div>
        </div>

    </div>
    <div class="empty-div-bottom"></div>

</div>