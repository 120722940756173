
<span class="fab-fixed-bottom animated fadeIn" *ngIf="showScrollUp">
    <button mat-fab color="primary" (click)="scrollToTop('#events-module-content')">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</span>

<div id="events-module-content" class="module-content" ngxDetectScroll (onScroll)="handleScroll($event)" [bottomOffset]="200" [topOffset]="200">
    <div class="filters-container">
        <div class="search-filter">
            <div
                [class.without-animation]="organizationsService.searchScreenNeedsScroll"
                [class.up]="scrollDirection == 'up' && !scrollIsReachingBottom"
                [class.down]="scrollDirection == 'down' && !scrollIsReachingTop"
            >
                <div class="input-group input-group-search wl-app-secondary-background-color">
                    <div class="input-group-prepend" *ngIf="searchQuery">
                        <button class="btn btn-default" type="button" (click)="clearSearch()">
                            <mat-icon class="no-margins">close</mat-icon>
                        </button>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="searchQuery" (input)="searchQuerySubject.next('')" [placeholder]="'occasions_search' | translate">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <mat-icon>search</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="secondary-filters-wrapper">
            <div class="tags-filter">
                <mat-form-field>
                    <mat-label>{{'occasions.hang_out_type' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedTagId" (selectionChange)="filterInput($event.value, 'tag')">
                        <mat-option [value]="0">{{ 'occasions.tags.everything' | translate }}</mat-option>
                        <mat-option *ngFor="let tag of tagsFilterValues" [value]="tag._id">
                            {{ tag.lang[appService.appConfig.locale.toLocaleLowerCase()].label | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="date-filter">
                <mat-form-field>
                    <mat-label>{{'occasions.occasion' | translate}}</mat-label>
                    <mat-select [(value)]="selectedDateId" (selectionChange)="filterInput($event.value, 'date')" placeholder="Mode">
                      <mat-option *ngFor="let date of dateFilterValues | orderBy: 'date.id'" [value]="date.id">
                        {{ 'occasions.dates.' + date.value | translate }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="show-more-sites" *ngIf="noMoreResults || loading">
        <div *ngIf="!loading && noMoreResults" class="loading-label">{{'MESSAGES.NO_MORE_RESULTS' | translate}}</div>
        <mat-spinner *ngIf="loading" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
    </div>
    <div *ngIf="siteEvents.length">
        <div class="sites-list">
            <event-card *ngFor="let event of filteredEvents; let i = index;" class="event-card" [index]="i" [siteEvent]="event" [tags]="tagsFilterValues"></event-card>
        </div>
    </div>
</div>