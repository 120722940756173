<div class="smart-banner-container">
    <div class="textual-area">
        <span class="textual-title">{{'app_smart_banner_title' | translate}}</span>
        <span class="textual-body">{{'app_smart_banner_body' | translate}}</span>
    </div>
    <div class="image-area">
        <img [src]="storeIcon" [alt]="storeIcon" (click)="navigateToApp()">
    </div>
    <button mat-icon-button class="close-banner" (click)="closeAndMarkAsWatched()">
        <mat-icon>close</mat-icon>
    </button>
</div>