import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { AppService } from '../../app.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { BookService } from '../../_core/book.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { SearchDetails } from '../../_core/OrganizationBucket';

import { get } from 'lodash-es';
import moment from 'moment';

@Component({
    selector: 'site-item',
    templateUrl: './site-item.component.html',
    styleUrls: ['./site-item.component.scss']
})
export class SiteItemComponent implements OnInit, OnDestroy {

    private _siteId: string;
    private _service: SearchDetails['service'];
    public areaDescriptions: any = {};
    public site: any;
    public lastSiteSubscription: Subscription;
    private locationSubscription: Subscription;
    public isLastOpened: boolean = false;

    // Badges data:
    public isPreOrderBadge: boolean = false;
    public startFromBadge: Date = null;
    public notAvailableBadge: boolean = false;
    public areThereBadges: boolean = false;

    public showDistance: boolean = false;

    public showPepperPay: boolean = false;
    public unit: string = this.appService.appConfig.locale === 'en-US' ? 'mile' : 'km';
    public imageCustomCss = {'border-radius.rem': 0.5};

    @Input() public single: boolean;
    @Input() set siteId(siteId: string) {
        let site = this.organizationsService.getOrganization(siteId);
        this._siteId = siteId;
        this.site = site;
        this.areaDescriptions = get(this.site, `bookingData.strings`, null);
        // Removed to prevent double network ouptut (maybe causes wrong output)
        // this.makeSiteServiceBadges(this.service, site);
    }
    @Input() public timeSlots;
    @Input() public inFocus: boolean = false;
    @Input() public showBadges: boolean = false;

    get siteId(): string { return this._siteId }
    siteAddressAriaLabel: string ='';

    @Input()
    set service(service: SearchDetails['service']) {
        if (!service) return;
        this._service = service;

        this.makeSiteServiceBadges(service, this.site);
    }
    get service(): SearchDetails['service'] { return this._service }

    constructor(
        public appService: AppService,
        private organizationsService: OrganizationsService,
        public utilsService: EntityUtilsService,
		public locationService: LocationService,
		public bookService: BookService,
    ) {}

    ngOnInit() {

        this.siteAddressAriaLabel = this.getSiteAddressAriaLabel();

        this.lastSiteSubscription = this.appService.lastSiteOpened.subscribe(lastSiteOpened => {
            this.isLastOpened = lastSiteOpened === this.siteId;
            setTimeout(() => {
                this.isLastOpened = false;
            });
        });
        this.locationSubscription = combineLatest([
            this.locationService.actualLocationAvailable,
            this.locationService.location,
        ]).subscribe(([actualLocationAvailable, location]: [boolean, LocationLabeled]) => {
            this.showDistance = actualLocationAvailable && location.actual;
        });
    }

    getSiteAddressAriaLabel() {
        const formattedDistance = this.site.formattedDistance;
        const addressTranslation = this.appService.translate('ACCESS.address');
        const distanceTranslation = formattedDistance
        ? formattedDistance === 'far_away'
        ? this.appService.translate(formattedDistance)
        : `${this.appService.translate(formattedDistance)} ${this.unit}`
        : '';

        const ariaLabel = `${addressTranslation} ${this.site.address} ${distanceTranslation}`;
        return ariaLabel;
    }

    ngOnDestroy() {
        if (this.lastSiteSubscription) this.lastSiteSubscription.unsubscribe();
        if (this.locationSubscription) this.locationSubscription.unsubscribe();
    }

    isShowBadge(type: string): boolean {
        if (!type) return;
        switch (type) {
            case "not_available_now":
                if (this.notAvailableBadge) return true;
                return false;
            case "preorder":
                if (this.notAvailableBadge) return false;
                else if (this.isPreOrderBadge) return true;
                return false;
            case "available_from":
                if (this.notAvailableBadge) return false;
                else if (this.startFromBadge && !this.isPreOrderBadge) return true;
                return false;
            default:
                return false;
        }
    }

    private makeSiteServiceBadges(service: SearchDetails['service'], site: any): void {
        this.notAvailableBadge = this.getNotAvailableBadge(service, site);
        this.isPreOrderBadge = this.isPreorder(service, site);
        this.startFromBadge = this.getStartFromBadge(service, site);
        this.areThereBadges = !!(this.isPreOrderBadge || this.startFromBadge || this.notAvailableBadge);
    }

    private isPreorder(service: SearchDetails['service'], site: any): boolean {

        if (!site) return false;

        return this.appService.isPreorderAvailableNow(site, service);

    }

    private getStartFromBadge(service: SearchDetails['service'], site: any): Date | null {

        return this.appService.getStartFromTime(site, service);

    }

    private getNotAvailableBadge(service: SearchDetails['service'], site: any): boolean {

        // Is the service is not "active"
        if (!get(site, `servicesInDetails.${service}.active`)) return true;

        // If the availability failed because of an error:
        if (
            !get(site, `servicesInDetails.${service}.availability.available`) &&
            get(site, `servicesInDetails.${service}.availability.availabilityCheckErr`)
        ) return true;

        let timestamp = get(site, `servicesInDetails.${service}.availability.availabilitySlotBeginAt`);
        if (!timestamp) return true; // That's sort of an error, always has to have "availabilitySlotBeginAt", even when available

        let beginTime = moment(timestamp);
        if (!beginTime.isValid()) return true; // That's sort of an error

        // If the begin time is tomorrow, that's not the badge:
        if (moment().day() !== moment(beginTime).day()) return true;

        return false;
    }

}
