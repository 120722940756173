import { Component, Inject, OnInit, OnDestroy, ViewContainerRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';

import { StyleService } from '../../_core/style.service';

// optional buttons callback options
export interface dialogButtonsCallbackTypes {
    reloadWebPage?: boolean;
    navigateToStoreRedirectLink?: boolean;
    resetShopProcess?: boolean;
    showDetailsWithNewBalance?: boolean;
}

export interface MainMessageDialogOptions {
    // visual options
    dialogType?: 'success' | 'info' | 'error';
    dialogCustomIcon?: string; // override default dialogType icons
    buttonsLayout?: 'row' | 'column';
    backdropClass?: string;
    // textual options
    dialogTitle?: string;
    dialogText?: string;
    dynamicContent?: boolean;
    htmlContent?: boolean;
    // primary button options
    primaryButtonText?: string;
    primaryButtonActions?: dialogButtonsCallbackTypes; // predefined callback options
    primaryButtonHidden?: boolean;
    // secondary button options
    secondaryButtonText?: string;
    secondaryButtonActions?: dialogButtonsCallbackTypes; // predefined callback options
    secondaryButtonAppearance?: 'button' | 'anchor';
    secondaryButtonHidden?: boolean;
    // modal actions
    disableAutoFocus?: boolean; // prevent focusing on buttons (causes a "jump" to the bottom when there's a Y-overflow)
    closeOnIdleTimeout?: boolean;
    closeOnBackdropClick?: boolean;
    hideXIcon?: boolean;

    /** @deprecated use primaryButtonHidden instead */
    hidePrimaryButton?: boolean;
    /** @deprecated use secondaryButtonHidden instead */
    hideSecondaryButton?: boolean;
    /** @deprecated dialog height is responsive now */
    dialogAutoHeight?: boolean;
}

@Component({
    selector: 'main-message-dialog',
    templateUrl: './main-message-dialog.component.html',
    styleUrls: ['./main-message-dialog.component.scss']
})
export class MainMessageDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        public dialogRef: MatDialogRef<MainMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: MainMessageDialogOptions,
        public styleService: StyleService,
        private viewContainerRef: ViewContainerRef,
    ) {
        this.handleDeprecatedProperties();
    }

    // rxjs timer subscription
    timerSubscription$: Subscription;
    htmlElement: any;

    ngOnInit() {
        // on dialog type "success" - count down 15 seconds and close the dialog
        if (this.dialogData?.dialogType == 'success' && this.dialogData?.closeOnIdleTimeout) {
            this.timerSubscription$ = timer(15000).subscribe(val => {
                this.primaryButtonClick();
            });
        }
    }

    // focus on the primary button, otherwise the focus will be hijacked by the offer's dialog's "scroll-to-error"
    ngAfterViewInit() {
        const mainButton = this.viewContainerRef.element?.nativeElement?.querySelector('.primary-action-button');
        mainButton?.focus({ preventScroll: true });
    }

    primaryButtonClick() {
        this.closeDialog({
            primaryButtonClick: true,
            buttonsCallbackOptions: this.dialogData.primaryButtonActions
        }); 
    }

    secondaryButtonClick() {
        this.closeDialog({
            secondaryButtonClick: true,
            buttonsCallbackOptions: this.dialogData.secondaryButtonActions
        }); 
    }

    closeDialog(options: any = {}) {
        this.dialogRef.close(options);
    }

    openLink(ev) {
        ev.preventDefault();
        if ((ev.srcElement.localName || ev.target.localName) === 'a') {
            // We're doing it that way beacuse we use innerHtml
            // so when the href opens in the app, it "captures" the whole screen
            let url = ev.target.attributes[1].nodeValue;
            if (url) {
                let params = {
                    redirectURL: url
                };
                if (window['cordova']) {
                    window.open(params.redirectURL, '_system');
                } else {
                    // When opening a window on a browser (especially in Safari Mobile), we must not specify the second argument ("name") because otherwise, the second time the user clicks the button - the window.open won't switch to the relevant tab (in Safari Mobile).
                    window.open(params.redirectURL);
                }
            }
        }
    }

    handleDeprecatedProperties() {
        // handle deprecated properties
        if (this.dialogData.hidePrimaryButton) this.dialogData.primaryButtonHidden = this.dialogData.hidePrimaryButton;
        if (this.dialogData.hideSecondaryButton) this.dialogData.secondaryButtonHidden = this.dialogData.hideSecondaryButton;
    }

    get dialogIconName() {
        return this.dialogData.dialogCustomIcon || `dialog-${this.dialogData.dialogType}`;
    }

    ngOnDestroy() {
        this.timerSubscription$?.unsubscribe();
    }
}
