import { Component, OnInit, Input } from '@angular/core';

import { AppService } from '../../../app.service';

@Component({
    selector: 'tabitpay-ticket-seated',
    templateUrl: './tabitpay-ticket-seated.component.html',
    styleUrls: ['./tabitpay-ticket-seated.component.scss']
})
export class TabitpayTicketSeatedComponent implements OnInit {
    @Input() $storage;

    processAnimation = this.appService.getAnimationPath('process');

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() { }

}
