import { Injectable, NgZone } from '@angular/core';
import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { MatDialog } from '@angular/material/dialog';
import { TabitpayExpayDialogComponent } from './dialogs/tabitpay-expay-dialog/tabitpay-expay-dialog.component';
import { get, each } from 'lodash-es';

@Injectable()
export class TabitpayExpayService {

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        public dialog: MatDialog,
        private ngZone: NgZone,
    ) {

    }

    showCreditGuardDialog(res) {
        let args: any = this.getURLParams({ search: `?${res.paymentUrl.split('?')[1]}` });
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                let dialogRef = this.dialog.open(TabitpayExpayDialogComponent, {
                    panelClass: 'full',
                    direction: this.appService.direction,
                    data: { redirectURL: res.paymentUrl, provider: 'CreditGuard' },
                    disableClose: true,
                    closeOnNavigation: false,
                    hasBackdrop: true,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    let mode = get(result, 'mode');
                    switch (mode) {
                        case "success":
                            resolve({ transactionId: args.txId });
                            break;
                        case "error":
                            reject({
                                type: 'PAYMENT_VALIDATION_ERROR',
                                message: this.appService.translate("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
                                serverError: {}
                            });
                            break;
                        default:
                            reject({ type: "CANCELED" });
                    }
                });
            });
        });
    }

    showMeshulamDialog(res) {
        if (window['cordova']) {
            if (this.appService.cordovaPlatform !== 'ios') {
                window.open(res.paymentUrl, '_system');
            } else {
                // Only for iOS
                if (window['cordova']?.InAppBrowser) {
                    window['cordova'].InAppBrowser.open(encodeURI(res.paymentUrl), '_system');
                }
            }
            return Promise.reject({ type: 'RESET' });
        }

        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                let dialogRef = this.dialog.open(TabitpayExpayDialogComponent, {
                    panelClass: 'full',
                    direction: this.appService.direction,
                    data: { redirectURL: res.paymentUrl, provider: 'Meshulam' },
                    disableClose: true,
                    closeOnNavigation: false,
                    hasBackdrop: true,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    let mode = get(result, 'mode');
                    switch (mode) {
                        case "success":
                            resolve(res);
                            break;
                        case "error":
                            reject({
                                type: 'PAYMENT_VALIDATION_ERROR',
                                message: this.appService.translate("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
                                serverError: {}
                            });
                            break;
                        default:
                            reject({ type: "CANCELED" });
                    }
                });
            });

        });

    }

    getURLParams(_location?) {
        let urlParams: any = {};
        if (!_location) _location = location;
        const sSearch = _location.search.substr(1).split("&");
        each(sSearch, ss => {
            const _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

}
