import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[accessible]'
})
export class AccessibleDirective implements OnInit, OnDestroy {
  private keyPressListenerTerminate: () => void;
  @Input() onClickCallback: Function = null;
  
  public constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef) {
    }

  ngOnInit(): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', '0');
    this.keyPressListenerTerminate = this.setKeyPressListener();
  }

  ngOnDestroy(): void {
    if (this.keyPressListenerTerminate) {
      this.keyPressListenerTerminate();
    }
  }

  private setKeyPressListener() {
    return this.renderer.listen(this.elementRef.nativeElement, 'keydown.enter', () => this.onKeyboardPress())
  }

  private onKeyboardPress() {
    if (this.onClickCallback) {
      this.onClickCallback();
    } else {
      this.elementRef.nativeElement.click()
    }
  }
} 




