<span class="fab-fixed-bottom animated fadeIn" *ngIf="showScrollUp">
    <button mat-fab color="primary" (click)="scrollToTop('#marketplace-module-content')">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</span>

<div id="marketplace-module-content" 
class="module-content" 
[class.banner-opened]="appService?.webAppSmartBannerOpened" 
[class.marketplace-app]="appService?.isApp" 
[class.market-on-android]="appService?.platformService?.ANDROID" 
ngxDetectScroll 
(onScroll)="handleScroll($event)"
[bottomOffset]="200"
[topOffset]="200"
>
    <div class="marketplace-title" *ngIf="!appService.isApp">{{ 'marketplace.results_page_title' | translate }}</div>
    <div class="marketplace-sub-title" [innerHTML]="'marketplace.results_page_description' | translate"></div>
    <div class="filter-search-container">
        <div class="filters-container" [class.sticky-header]="isTitleSticky" >
            <div>

                <button  class="tags-filter" mat-icon-button [matMenuTriggerFor]="marketplaceTags">
                    <mat-icon svgIcon="filter-icon"></mat-icon>
                </button>

                <mat-menu #marketplaceTags="matMenu">
                    <div class="marketplace-tags-panel">
                        <button mat-menu-item *ngFor="let tag of tags" > <!--[class]="selectedLanguageValue === language.value ? 'to-language-menu-selected':''"> -->
                            <mat-checkbox [checked]="isChecked(tag)" #checkbox (change)="onCheckboxChange($event, tag)" class="mat-menu-item">{{ getTagLabel(tag)}}</mat-checkbox>  
                        </button>
                    </div>
                </mat-menu>

            </div>    
            <app-selected-tag *ngIf="appService.isDesktop()" [filteredTags]="filteredTags"
                (remove)="removeTag($event)"></app-selected-tag>
    
            <div class="search-filter">
                <div [class.without-animation]="organizationsService.searchScreenNeedsScroll"
                    [class.up]="scrollDirection == 'up' && !scrollIsReachingBottom"
                    [class.down]="scrollDirection == 'down' && !scrollIsReachingTop">
                    <div class="input-group input-group-search wl-app-secondary-background-color">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <mat-icon>search</mat-icon>
                            </span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="searchQuery"
                            (input)="searchQuerySubject.next('')" [placeholder]="'marketplace.search_input' | translate">
                        <div class="input-group-prepend" *ngIf="searchQuery">
                            <button class="btn btn-default" type="button" (click)="clearSearch()">
                                <mat-icon class="no-margins">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-selected-tag
        *ngIf="!appService.isDesktop()"
        [filteredTags]="filteredTags"
        (remove)="removeTag($event)"
    ></app-selected-tag>

    <div *ngIf="matchingOrgs.length; else noMatch">
        <div class="sites-list">
            <marketplace-card *ngFor="let org of matchingOrgs" [site]="org"></marketplace-card>
        </div>
    </div>

    <ng-template #noMatch>
        <ng-container *ngIf="!noMoreResults && tags?.length">
            <div *ngFor="let org of filteredOrgs" [id]="org.tagId+'_marketplace'">
                <div [class.sticky-tag-title-header]="currentStickyTagId == org.tagId" [id]="'marketplace_title_'+org.tagId" class="subclass-wrapper">
                    <h3>{{ getTagById(org.tagId) }}</h3>
                </div>
                <div class="sites-list">
                    <marketplace-card
                        *ngFor="let organization of org.organizations"
                        class="event-card"
                        [site]="organization"
                    ></marketplace-card>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <div class="show-more-sites" *ngIf="noMoreResults || loading">
        <div *ngIf="!loading && noMoreResults" class="loading-label">{{ 'MESSAGES.NO_MORE_RESULTS' | translate }}</div>
        <mat-spinner *ngIf="loading" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
    </div>
</div>