import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ExternalLinkService } from '../../_core/external-link.service';
import { environment } from '../../../environments/environment';

import { get } from 'lodash-es';

@Component({
    selector: 'loyalty-terms-dialog',
    templateUrl: './loyalty-terms-dialog.component.html',
    styleUrls: ['./loyalty-terms-dialog.component.scss']
})

export class LoyaltyTermsDialogComponent implements OnInit {
    public privacyPolicyURL: string;
    public termsOfUseURL: string;
    public loyaltyTermsURL: string;
    public cordovaPlatform: string;
    public toggleConfirmSms: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public dialogRef: MatDialogRef<LoyaltyTermsDialogComponent>,
        public externalLinkService: ExternalLinkService,
    ) { }

    public appConfig: any = environment.appConfig;
    public primaryButtonText: string = 'APPROVE';

    public formGroup = new UntypedFormGroup({
        IsFilledJoinForm: new UntypedFormControl(false, [Validators.required]),
        IsConfirmSms: new UntypedFormControl(false),
    });

    ngOnInit() {
        this.privacyPolicyURL = get(this.dialogData, 'links.privacyPolicyURL', `https://www.tabitorder.com/privacy/${this.appConfig.locale === 'he-IL' ? 'he' : 'en'}`);
        this.termsOfUseURL = get(this.dialogData, 'links.termsOfUseURL', `https://www.tabitorder.com/terms/${this.appConfig.locale === 'he-IL' ? 'he' : 'en'}`);
        this.loyaltyTermsURL = get(this.dialogData, 'links.loyaltyTermsURL');
    }

    apply() {
        if (this.toggleConfirmSms && !this.formGroup.value.IsConfirmSms) {
            this.toggleConfirmSms = !this.toggleConfirmSms;
            this.dialogRef.close(this.formGroup.value);
        }
        else if (this.formGroup.value.IsConfirmSms) {
            this.dialogRef.close(this.formGroup.value);
        }
        else {
            this.toggleConfirmSms = !this.toggleConfirmSms;
        }
    }

    openLink(path: string) {

        let url: string;

        switch (path) {
            case "privacy":
                url = this.privacyPolicyURL;
                break;
            case "loyaltyTerms":
                url = this.loyaltyTermsURL;
                break;
            case "terms":
            default:
                url = this.termsOfUseURL;
                break;
        }

        this.externalLinkService.openExternalLink(url);
    }


}
