<button aria-label="close" class="close-button" mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
</button>

<app-sign-in-by-phone-verify-code-form
    [formModel]="data"
    [phone]="data.phone"
    [dialogMode]="true"
    [disabled]="authenticating || resending"
    (onResend)="resendCode()"
    (onSubmit)="verifyCode($event)"
></app-sign-in-by-phone-verify-code-form>
