<div class="under-construction-section">
    <div class="animation">
        <ng-lottie class="animation" [options]="{path: animationPath, autoplay: true, loop: true}"></ng-lottie>
    </div>
    <div class="message">{{ 'under_construction_message' | translate }}</div>
    <div class="spacer_btm"></div>
    <div class="footer-container">
        <img class="header-image" [src]="appService.base('assets/images/tabit-logo-dine-smart-dark.png')" (click)="open(tabitBusinessURL)"/>
        <div class="footer-topic footer-social-buttons">
            <div class="social-container">
                <button *ngIf="facebookURL" mat-mini-fab class="footer-topic-link social-button" (click)="open(facebookURL)">
                    <mat-icon svgIcon="facebook"></mat-icon>
                </button>
    
                <button *ngIf="instagramURL" mat-mini-fab class="footer-topic-link social-button" (click)="open(instagramURL)">
                    <mat-icon svgIcon="instagram"></mat-icon>
                </button>
    
                <button *ngIf="linkedinURL" mat-mini-fab class="footer-topic-link social-button" (click)="open(linkedinURL)">
                    <mat-icon svgIcon="linkedin"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

