
<form (ngSubmit)="apply(challengeForm)" #challengeForm="ngForm">
    <div mat-dialog-title class="d-flex justify-content-between align-items-center">
        <div class="h4">
            {{'PAY_WITH' | translate}}
        </div>
        <button aria-label="close" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>

        <div class="pb-3 text-center">
            <div class="btn-group" role="group" aria-label="pm group">
                <button type="button" class="btn btn-outline-tabit btn-gratuity {{opt.cssClass}}"
                        *ngFor="let opt of ui.paymentMethods"
                        [ngClass]="{'active': ccinfo.account == opt._id}"
                        (click)="setPaymentMethod(ccinfo, opt)">
                    {{opt.name | translate}}
                </button>
            </div>
        </div>

        <div [ngSwitch]="ccinfo.paymentMethod">
            <div *ngSwitchCase="'creditCard'">

                <mat-form-field>
                    <mat-label>{{'credit_card_number' | translate}}</mat-label>
                    <input [(ngModel)]="ccinfo.pan" name="cardnumber" id="cardNumber" matInput type="tel" required pattern="[0-9]*"
                           validCreditCard [ccInfo]="ccinfo" #cc="ngModel">
                    <mat-icon matSuffix (click)="ccinfo.pan = null" *ngIf="ccinfo.pan" class="mr-2">close</mat-icon>
                    <mat-icon matSuffix [svgIcon]="ccinfo && ccinfo.brand || 'cc'" (click)="ccinfo.pan = null"></mat-icon>
                </mat-form-field>

                <div class="row small-gutters">
                    <div class="col-5">
                        <mat-form-field>
                            <mat-label>{{'expiration' | translate}}</mat-label>
                            <input [(ngModel)]="ccinfo.expiration" name="etad" matInput placeholder="mm-yy" type="tel"
                                   pattern="^\d{2}-\d{2}$" maxlength="5" required validCardExpiration ccinfo="ccinfo" class="ltr-field text-center" [ccInfo]="ccinfo" #etad="ngModel">
                            <mat-icon matSuffix (click)="ccinfo.expiration = null" *ngIf="ccinfo.expiration">close</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-7">
                        <mat-form-field>
                            <input [(ngModel)]="ccinfo.cvv" name="cvv" matInput placeholder="{{'cvv' | translate}}" type="tel" required
                                   pattern="[0-9]*" class="text-center" maxlength="4">
                            <mat-icon matSuffix (click)="ccinfo.cvv = null" *ngIf="ccinfo.cvv">close</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <mat-form-field *ngIf="ccinfo.requireIdPhoneTrans">
                    <mat-label>{{'ID_CARD' | translate}}</mat-label>
                    <input [(ngModel)]="ccinfo.idCard" name="idCard" matInput type="tel" required pattern="[0-9]*"
                           validIdCard #idCard="ngModel">
                    <mat-icon matSuffix (click)="ccinfo.idCard = null" *ngIf="ccinfo.idCard">close</mat-icon>
                    <!--<mat-error *ngIf="idCard.dirty && idCard.errors?.creditCard">{{idCard.errors.creditCard | translate}}</mat-error>-->
                </mat-form-field>

            </div>
            <div *ngSwitchCase="'Cibus'">
                <mat-form-field>
                    <input [(ngModel)]="ccinfo.pan" name="cardnumber" id="cardNumber" matInput placeholder="{{'credit_card_number' | translate}}" type="tel" required
                           pattern="[0-9]*">
                    <mat-icon matSuffix (click)="ccinfo.pan = null" *ngIf="ccinfo.pan">close</mat-icon>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="'giftCard'">
                <mat-form-field>
                    <input [(ngModel)]="ccinfo.pan" name="cardnumber" id="cardNumber" matInput placeholder="{{'credit_card_number' | translate}}" type="tel" required
                           pattern="[0-9]*">
                    <mat-icon matSuffix (click)="ccinfo.pan = null" *ngIf="ccinfo.pan">close</mat-icon>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="'10bis'">
                <mat-form-field>
                    <input [(ngModel)]="ccinfo.pan" name="cardnumber" id="cardNumber" matInput placeholder="{{'credit_card_number' | translate}}" type="tel" required
                           pattern="[0-9]*">
                    <mat-icon matSuffix (click)="ccinfo.pan = null" *ngIf="ccinfo.pan">close</mat-icon>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="'TavHazahav'">
                <mat-form-field appearance="outline">
                    <input [(ngModel)]="ccinfo.pan" name="cardnumber" id="cardNumber" matInput placeholder="{{'credit_card_number' | tmpTranslate}}" type="tel" required
                           pattern="[0-9]*">
                    <mat-icon matSuffix (click)="ccinfo.pan = null" *ngIf="ccinfo.pan">close</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <ng-container *ngIf="enableSaveToWallet">
            <div class="mb-2">
                <mat-checkbox class="example-margin" name="updatePaymentInfo" [(ngModel)]="ui.updatePaymentInfo">
                    {{'SAVE_ACCOUNT_PAYMENT_INFO' | translate}}
                </mat-checkbox>
            </div>

            <mat-form-field *ngIf="ui.updatePaymentInfo">
                <input [(ngModel)]="ccinfo.comment" name="comment" matInput placeholder="{{'comments' | translate}}">
            </mat-form-field>
        </ng-container>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-between align-items-center pb-3">
        <button type="submit" [disabled]="!ccinfo.paymentMethod" mat-flat-button color="primary" class="rounded lg block">
            <div class="d-flex justify-content-between align-items-center">
                <span>{{'PAY' | translate}}</span>
                <span *ngIf="data.total" class="px-2">{{data.total | lcurrency}}</span>
                <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
            </div>
        </button>
    </div>
</form>