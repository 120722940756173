import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-user-review',
  templateUrl: './web-user-review.component.html',
  styleUrls: ['./web-user-review.component.scss']
})
export class WebUserReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
