
<div class="order-ticket p-3">
    <div class="order-ticket-body">
        <ng-container *ngIf="$storage.hasOffers">

            <div class="ticket-box text-center" *ngIf="!$storage.isDelivery">
                <h4>{{'PO.TABLE_BILL' | translate}} {{$storage.order.printData.variables.TABLE_NO}}</h4>
                <div class="mt-1">{{'PO.WAITER' | translate}} {{$storage.order.printData.variables.F_NAME}} {{$storage.order.printData.variables.L_NAME[0]}}</div>
                <hr />
            </div>
            <ng-container *ngIf="$storage.isOTC">
                <div class="ticket-box text-center">
                    <div>
                        {{'PO.ORDER_NO' | translate}}:&nbsp;<div class="inline">{{$storage.order.printData.variables.ORDER_NO}}</div>
                    </div>
                    <div>
                        {{'PO.ORDERER_NAME' | translate}}:&nbsp;<div class="inline">{{$storage.$orderer}}</div>
                    </div>
                    <div>
                        {{'PO.ORDERER_PHONE' | translate}}:&nbsp;<div class="inline">{{$storage.order.printData.variables.ORDERER_TEL}}</div>
                    </div>
                    <div *ngIf="$storage.$deliveryAddress">
                        {{'PO.ORDER_TO' | translate}}:&nbsp;<div class="inline">{{$storage.$deliveryAddress}}</div>
                    </div>
                    <hr />
                </div>
            </ng-container>

            <div class="ticket-box" *ngIf="$storage.showPrices">
                <table class="ticket-box-table _md">
                    <tr class="font-bold">
                        <th>{{'PO.ORDER_TOTAL' | translate}}</th>
                        <td>{{$storage.order.printData.variables.TOTAL_AMOUNT | number:'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="$storage.order.printData.variables.ORDER_ON_THE_HOUSE == '1'">
                        <th colspan="2">{{'PO.TICKET_OTH' | translate}}</th>
                    </tr>
                    <tr *ngIf="$storage.order.printData.variables.TOTAL_TIPS">
                        <th>{{'PO.ORDER_TIP' | translate}}</th>
                        <td>{{$storage.order.printData.variables.TOTAL_TIPS | number:'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="$storage.order.printData.variables.TOTAL_PAYMENTS">
                        <th>{{'PO.PAID' | translate}}</th>
                        <td>{{($storage.order.printData.variables.TOTAL_PAYMENTS - $storage.order.printData.variables.CHANGE) | number:'1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="$storage.order.printData.variables.CHANGE">
                        <th>{{'PO.CHANGE' | translate}}</th>
                        <td>{{$storage.order.printData.variables.CHANGE | number:'1.2-2'}}</td>
                    </tr>
                    <tr class="font-bold" *ngIf="$storage.order.printData.variables.BAL_DUE !== $storage.order.printData.variables.TOTAL_AMOUNT">
                        <th>{{'PO.REMAINED_PAY' | translate}}</th>
                        <td>{{$storage.order.printData.variables.BAL_DUE | number:'1.2-2'}}</td>
                    </tr>
                </table>
                <hr />
            </div>

            <div class="ticket-box text-center" *ngIf="$storage.order.payments?.length">
                <table class="ticket-box-table _sm">
                    <tr class="_base" *ngFor="let pm of $storage.order.payments">
                        <th style="vertical-align:top">
                            <div class=" mr-2">{{pm.name}}</div>
                        </th>
                        <td style="vertical-align:top">
                            <small class="inline mr-2" *ngIf="pm.tip">
                                (<span class="inline">{{'gratuity' | translate}}: </span>
                                {{pm.tip | lcurrency:'1.2-2'}})
                            </small>
                        </td>
                        <td style="vertical-align:top">{{pm.total | lcurrency:'1.2-2'}}</td>
                    </tr>
                </table>
                <hr />
            </div>

            <div class="ticket-box">
                <table class="ticket-box-table _sm">
                    <tbody>
                        <ng-container *ngIf="$storage.showPrices && $storage.order.printData.collections.ORDER_DISCOUNTS_LIST.length">
                            <tr class="_base" *ngFor="let discount of $storage.order.printData.collections.ORDER_DISCOUNTS_LIST">
                                <th colspan="2">{{discount.DISCOUNT_NAME || 'PO.TICKET_DISCOUNT' | translate}}</th>
                                <td>{{(discount.DISCOUNT_AMOUNT * -1) | number:'1.2-2'}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let offer of $storage.order.printData.collections.ORDERED_OFFERS_LIST">
                            <tr class="_base" *ngIf="!offer.OFFER_UNITS">
                                <th colspan="2">
                                    <span class="_counter">{{offer.OFFER_QTY}}</span>
                                    <span>{{offer.OFFER_NAME}}</span>
                                </th>
                                <td>
                                    <span *ngIf="offer.ON_THE_HOUSE != '1' && $storage.showPrices">{{offer.OFFER_AMOUNT | number:'1.2-2'}}</span>
                                    <span *ngIf="offer.ON_THE_HOUSE == '1' && $storage.showPrices">{{'PO.OTH' | translate}}</span>
                                </td>
                            </tr>
                            <tr class="_base" *ngIf="offer.OFFER_UNITS">
                                <th>
                                    <span class="_counter">{{offer.OFFER_QTY}}</span>
                                    <span>{{offer.OFFER_NAME}}</span>
                                </th>
                                <td class="px-3">{{offer.OFFER_UNITS | weight:$storage.order.printData.variables.BASIC_WEIGHT_UOM}}</td>
                                <td>
                                    <span *ngIf="offer.ON_THE_HOUSE != '1' && $storage.showPrices">{{(offer.OFFER_PRICE * offer.OFFER_UNITS) | number:'1.2-2'}}</span>
                                    <span *ngIf="offer.ON_THE_HOUSE == '1' && $storage.showPrices">{{'PO.OTH' | translate}}</span>
                                </td>
                            </tr>
                            <ng-container *ngFor="let offerItem of offer.ALL_ORDERED_ITEMS">
                                <tr *ngIf="offerItem.IS_LEADING_ITEM != '1'">
                                    <th colspan="2">
                                        <span class="_counter"></span>
                                        {{offerItem.ITEM_NAME}}
                                    </th>
                                    <td *ngIf="$storage.showPrices && offerItem.ITEM_PRICE">{{offerItem.ITEM_PRICE | number:'1.2-2'}}</td>
                                </tr>
                                <ng-container *ngIf="offerItem._hasMods">
                                    <ng-container *ngFor="let pMod of offerItem.MODIFIERS_LIST | filterBy:{'_show':true}">
                                        <tr *ngIf="!pMod.EXCLUDED">
                                            <th colspan="2"><span class="_counter-large"></span>{{pMod._name}}</th>
                                            <td *ngIf="pMod.MODIFIER_PRICE > 0 && $storage.showPrices">{{pMod.MODIFIER_PRICE | number:'1.2-2'}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <tr *ngIf="offerItem._hasModsExclude">
                                    <th colspan="3" class="text-danger">
                                        <span class="_counter-large"></span>{{'PO.WITHOUT' | translate}}:
                                        <span *ngFor="let pMod of offerItem.MODIFIERS_LIST | filterBy:{'_show':true, 'EXCLUDED':true}; let last=last">
                                            {{pMod.MODIFIER_NAME}}
                                            <span *ngIf="!last">, </span>
                                        </span>
                                    </th>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let discount of offer.ORDERED_OFFER_DISCOUNTS">
                                <tr *ngIf="$storage.showPrices">
                                    <th colspan="2"><span class="_counter-large"></span>{{'PO.ITEM_DISCOUNT' | translate}}</th>
                                    <td ng-if="$storage.showPrices">{{discount.DISCOUNT_AMOUNT * -1 | number:'1.2-2'}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <ng-container *ngIf="!$storage.hasOffers">
            <div class="text-center">
                <div style="max-width:260px; font-size:18px;" class="inline pt-4">
                    {{'preparing-food' | translate}}
                </div>
            </div>
            <ng-lottie [options]="{path: processAnimation, autoplay: true, loop: true }" [height]="300"></ng-lottie>
        </ng-container>

        <div *ngIf="$storage.siteLogoURL" class="ticket-box text-center">
            <hr />
            <img style="width:20%" src="{{$storage.siteLogoURL}}" />
        </div>

    </div>
</div>