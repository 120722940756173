<span class="consent-text-container">
    {{'i_approve_the' | translate}}

    <span
        class="loyalty-terms"
        *ngIf="loyaltyTermsURL"
    >
        <a class="terms"
            (click)="openLink('loyaltyTerms')"
            accessible
        >{{'club_terms' | translate}}</a>{{', '}}
    </span>

    <a
        class="privacy-policy terms"
        (click)="openLink('privacy')"
        accessible
    >
        {{'privacy_policy' | translate}}
    </a>
    {{'and' | translate}}
    <a
        class="terms-and-conditions terms"
        (click)="openLink('terms')"
        accessible
    >
        {{'terms_and_conditions' | translate}}
    </a>
</span>