import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '../app.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AnonymousAuthGuardService {

    constructor(
        public appService: AppService,
        public authService: AuthService,
        public storageService: StorageService,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isRedirectNeeded = window['cordova'] && !this.appService.isAuthUser();
        if (isRedirectNeeded) this.redirectToSignIn(next);
        if (this.storageService.getItem('token')) return true;
        else {
            if (!isRedirectNeeded) this.appService.startBlock();
            return this.authService.tokenReceived$
            .pipe(
                map(token => {
                    if (!isRedirectNeeded) this.appService.stopBlock();
                    if (!!token) return true;
                    else this.redirectToSignIn(next);
                })
            );
        }
    }

    redirectToSignIn(next) {
        this.appService.stopBlock();
        this.appService.landingUrl = '/'+next.url[0].path;
        this.appService.landingUrlParams = {queryParams: next.queryParams};
        //console.log('=== LANDING URL: ', next, state, this.appService.landingUrl, this.appService.landingUrlParams);
        this.appService.stopBlock();
        this.appService.redirect(['/sign-in']);
        return false;
    }
}
