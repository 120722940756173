import { Directive, Input, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Directive({
  selector: '[announcer]'
})
export class AnnouncerDirective implements OnInit {
  @Input('announcer') announcement: string;
  @Input() translatedAnnouncement: string = null;
  
  public constructor(
    public appService: AppService,
    private liveAnnouncer: LiveAnnouncer) {
    }

  ngOnInit(): void {
    if (this.translatedAnnouncement) {
        this.liveAnnouncer.announce(this.translatedAnnouncement);
    } else {
        this.liveAnnouncer.announce(this.appService.translate(this.announcement))
    }
  }
} 




