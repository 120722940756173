import { Injectable } from "@angular/core";
@Injectable()
export class WebArticle {
    articles = {
        'מסעדות-חורף': {
            id: 'מסעדות-חורף',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'המסעדות שהכי כיף לאכול בהן בחורף',
                subTitle: 'איפה הכי שווה לאכול ארוחה מנחמת במיוחד כשבחוץ קר וגשום?<br>אספנו את כל ההמלצות הכי טעימות שלנו',
                bottomText: 'גיל גוטקין | 16.1.2020',
                photoUrl: 'assets/images/restaurant-theme-1.jpg',
                photoText: ''
            }
        },
        'איפה-לחגוג-את-הוולנטיין': {
            id: 'איפה-לחגוג-את-הוולנטיין',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'איפה לחגוג את הוולנטיין?',
                subTitle: 'המון מסעדות מציעות תפריטים מיוחדים לחג האהבה, אז איך בוחרים איפה הכי כדאי לאכול? אספנו את המקומות הכי שווים לחגוג בהם את החג בזוג',
                bottomText: 'גיל גוטקין | 13.2.2020',
                photoUrl: 'assets/images/restaurant-theme-1.jpg',
                photoText: ''
            }
        },
        'המקומות-הכי-טעימים-בשוק-הכרמל': {
            id: 'המקומות-הכי-טעימים-בשוק-הכרמל',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'המקומות הכי טעימים בשוק הכרמל',
                subTitle: 'שוק הכרמל מלא במסעדות ודוכני אוכל מכל הסוגים, אבל באילו מהם הכי שווה לבקר? אספנו את הרשימה של המקומות האהובים עלינו.',
                bottomText: '25.2.2020 | גיל גוטקין',
                photoUrl: 'assets/images/restaurant-theme-1.jpg',
                photoText: ''
            }
        },
        'משלוחים-לימי-קורונה': {
            id: 'משלוחים-לימי-קורונה',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'משלוחים לימי קורונה',
                subTitle: 'בתקופה הקרובה לא נוכל יותר לשבת לאכול במסעדות, וכולנו עברנו לבשל ולאכול אוכל ביתי. אבל לפעמים בכל זאת מתחשק קצת להתפנק, ולא מעט מסעדות שהיה אפשר רק לאכול בהן במקום עברו לספק משלוחים בימים האחרונים. איזה מסעדות שוות ליד הבית שלכם עושות משלוחים? אספנו את כל השוות.',
                bottomText: '17.03.20 | גיל גוטקין',
                photoUrl: 'assets/images/articles/corona/braseri.jpg',
                photoText: ''
            }
        },
        'יחד-גם-בבידוד': {
            id:  'יחד-גם-בבידוד',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'יחד, גם בבידוד: מעבירים את המסיבה הביתה',
                subTitle: `המצב החדש העביר את חיי החברה שלנו לתקשורת דרך שיחות וידאו, אז עכשיו כשאין יציאות ומסיבות, פשוט מזמינים  משלוח עם אוכל מפנק, מתארגנים על דרינק, ופוגשים את כל החבר'ה דרך הזום`,
                bottomText: '1.4.20 | גיל גוטקין',
                photoUrl: 'assets/images/articles/together-alone/cover.jpg',
                photoText: ''
            }
        },
        'שלוש-ארוחות-ביום': {
            id:  'שלוש-ארוחות-ביום',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: 'שלוש ארוחות ביום?? מי חשב שזה כל כך מאתגר. טוב שאפשר גם להזמין',
                subTitle: `עכשיו, בימי הסגר, אנחנו מוצאים את עצמנו גרים במטבח ולא מפסיקים לבשל ולספק ארוחות לעצמנו ולילדים. כמה כיף שאפשר לגוון ולהתפנק מדי פעם במשלוח לכל המשפחה. הנה כמה מהמסעדות שחשבו איך לעזור לנו ברגעים האלו ועושות משלוחים לכל המשפחה, שיעזרו לכם לעבור את התקופה הזו בשלום`,
                bottomText: '1.4.20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/together-alone/threemeals.jpg',
                photoText: ''
            }
        },
        'יצירתיות-וטכנולוגיה-הם-שם-המשחק': {
            id: 'יצירתיות-וטכנולוגיה-הם-שם-המשחק',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: `יצירתיות וטכנולוגיה הם שם המשחק;\nמסעדנים משתפים כיצד התמודדו עם משבר הקורונה`,
                subTitle: 'החודשיים האחרונים שיתקו כמעט לגמרי את ענף המסעדנות, שהפסיק לעבוד ברגע אחד. גם בימים הקשים האלה, יש מסעדות שהצליחו לשנות פאזה ולהיערך למצב החדש במהירות, כדי להמשיך לפעול גם תחת ההגבלות החדשות',
                bottomText: '11.5.20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/corona-restaurants-stories/klompus.jpg',
                photoText: ''
            }
        },
        'המסעדות-שהכי-התגעגענו-אליהן-וסוף-סוף-נפתחות-מחדש': {
            id: 'המסעדות-שהכי-התגעגענו-אליהן-וסוף-סוף-נפתחות-מחדש',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: `המסעדות שהכי התגעגענו אליהן וסוף סוף נפתחות מחדש`,
                subTitle: 'השגרה חוזרת אלינו אט אט, והגיע גם תורן של המסעדות שכל כך חסרו לנו. מאיפה מתחילים? קשה לבחור, הנה כמה הצעות מעולות שאספנו',
                bottomText: '27.5.20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/back-to-business/shishko.jpg',
                photoText: ''
            }
        },
        'קבלו-את-המסעדות-שהכי-כיף-לשבת-בהן-בחוץ-בחודשים-החמים': {
            id: 'קבלו-את-המסעדות-שהכי-כיף-לשבת-בהן-בחוץ-בחודשים-החמים',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: `ערוכים לקיץ? קבלו את המסעדות שהכי כיף לשבת בהן בחוץ בחודשים החמים`,
                subTitle: 'אספנו כמה מהמסעדות שמתאימות בול ליציאה קיצית',
                bottomText: '15.6.20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/ready-for-the-summer/gouje_and_daniel.jpg',
                photoText: ''
            }
        },
        'לפעמים-לא-חייבים-לטוס-רחוק-בכדי-להרגיש-חול': {
            id: 'לפעמים-לא-חייבים-לטוס-רחוק-בכדי-להרגיש-חול',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: `לפעמים לא חייבים לטוס רחוק בכדי להרגיש חו"ל`,
                subTitle: 'אספנו עבורכם את המסעדות שיגרמו לכם להרגיש כאילו קפצתם לביקור קצר במדינה אחרת',
                bottomText: '1/7/20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/feeling-abroad/ita.jpg',
                photoText: ''
            }
        },
        'גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית': {
            id: 'גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית',
            backgroundColor: '#00C8C9',
            textColor: '#FFF',
            header: {
                title: `גרים בצפון? הכירו את המסעדות שמגיעות אליכם עד הבית`,
                subTitle: 'תרבות המשלוחים הצפונית הולכת וגדלה, במיוחד בימים אלו. אספנו עבורכם מסעדות עם משלוחים שווים במיוחד',
                bottomText: '9/8/20 | גיל גוטקין, Tabit',
                photoUrl: 'assets/images/articles/north/bbb.jpg',
                photoText: ''
            }
        },
        // 'אמריקן-אקספרס-מנה-עלינו': {
        //     id: 'אמריקן-אקספרס-מנה-עלינו',
        //     backgroundColor: '#00C8C9',
        //     textColor: '#FFF',
        //     header: {
        //         title: `מנה עלינו מהמשלוחים הכי טובים בעיר | מיוחד ללקוחות אמריקן אקספרס`,
        //         subTitle: '',
        //         bottomText: '',
        //         photoUrl: 'assets/images/articles/american-express/american-express.jpg',
        //         photoText: ''
        //     }
        // }
    };
}
