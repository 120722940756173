import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash-es';

import { AppService } from '../app.service';

@UntilDestroy()
@Component({
    selector: 'app-out-of-use',
    templateUrl: './out-of-use.component.html',
    styleUrls: ['./out-of-use.component.scss']
})
export class OutOfUseComponent implements OnInit {
    message: string = '';
    domain: any;

    constructor(private appService: AppService) { }

    ngOnInit(): void {
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            if (domain?.domainSettings?.active === true) {
                this.appService.redirect(['/home/dashboard']);
            }

            this.domain = domain;
        });
        this.getMessage();        
    }

    getMessage(): void {
        this.appService.domain
            .pipe((untilDestroyed(this)))
            .subscribe(domain => {
            if (domain?.translations[this.appService.localeId]?.outOfUseMessage) {
                this.message = domain.translations[this.appService.localeId].outOfUseMessage;
            } else if (domain?.domainSettings?.outOfUseMessage) {
                this.message = domain.domainSettings.outOfUseMessage;
            }

            this.message = this.message.replace(/\\n/g, '<br>');
        })
    }

    getMessageDirection(): { direction: string } {
        let direction = { direction: 'ltr' };

        if ((/[\u0590-\u05FF]/).test(this.message)) {
            direction = { direction: 'rtl' };
        }

        return direction;
    }

    getMediaSource(cacheBusting: boolean) {
        const source = this.appService.getValidBaseUrl(get(this.domain, 'signInMediaSource'), cacheBusting);

        return source;
    }
}

