<div class="header-items">
    <div *ngIf="order?.created" class="header-item">
        <mat-icon [svgIcon]="'time'"></mat-icon>
        <div class="time-item">
            <span>{{ 'ORDER_CREATION_TIME' | translate }}</span>
            <div>{{order.created | date: 'HH:mm' | AMPMConverter}}</div>
        </div>
    </div>
    <div class="header-item">
        <mat-icon [svgIcon]="'money'"></mat-icon>
        <span>{{ 'ORDER_TOTAL' | translate }}</span>
        <div>{{totalAmount | lcurrency}}</div>
    </div>
    <div *ngIf="order.deliveryInfo?.ETA" class="header-item">
        <mat-icon [svgIcon]="'eta-clock'"></mat-icon>
        <div class="time-item">
            <span>{{ 'order_tracker.ETA' | translate }}</span>
            <div>{{order.deliveryInfo?.ETA | date: 'HH:mm' | AMPMConverter}}</div>
        </div>
    </div>
</div>