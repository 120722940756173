import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'web-sites-header',
    templateUrl: './web-sites-header.component.html',
    styleUrls: ['./web-sites-header.component.scss']
})
export class WebSitesHeaderComponent implements OnInit {

    webSelectionBarFields = {
        text: false,
        area: true,
        date: false,
        hour: false,
        guests: false,
        tags: true,
        rating: false,
        price: false,
    };

    constructor() { }

    ngOnInit() {
    }

}
