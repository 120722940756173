import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { AppService } from '../../app.service';
import { ConfigurationsService } from '../../_core/configurations.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-update-form',
    templateUrl: './update-form.component.html',
    styleUrls: ['./update-form.component.scss'],
})
export class UpdateFormComponent implements OnInit {

    formGroup = new UntypedFormGroup({
        'firstName': new UntypedFormControl('', [Validators.required, Validators.pattern("^[- a-zA-Zא-ת]{2,}$")]), // For some reason \D doesn't work
        'lastName': new UntypedFormControl('', [Validators.required, Validators.pattern("^[- a-zA-Zא-ת]{2,}$")]),  // For some reason \D doesn't work
    });

    @Input() formModel = {firstName: '', lastName: '', email: ''};
    @Input() disabled: boolean = false;
    @Input() dialogMode: boolean = false;

    @Output() onSubmit = new EventEmitter<{}>();
    @Output() onBack = new EventEmitter<{}>();

    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;

    public hide = true;
    public domain: any;

    constructor(
        public appService: AppService,
        public configurationsService: ConfigurationsService,
    ) { }

    ngOnInit() {
        this.loadingCoreDataSubscription = this.appService.loadingCoreData.subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;
                });
            }
        })
     }

    _update(form) {
        if (form.valid) {
            this.onSubmit.emit(this.formModel);
        }
    }

    _back() {
        this.onBack.emit({});
    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
    }
}
