<web-header>
    <web-sites-header></web-sites-header>
</web-header>

<site-list-map
    [type]="'search'"
    [sites]="searching ? [] : orgs"
    [initialSearch]="orgs.length == 0 || searching ? true : false"
    [preventSearchMore]="preventSearchMore"
    (siteClick)="siteClick($event)"
    (scrollReachedBottom)="loadMore()"
></site-list-map>
