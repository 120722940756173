import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { NotificationsService } from '../../_core/notifications.service';

import { Subscription, timer } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

    @Input() notification: any;

    public date: any;

    private dateTimer = timer(0, 60 * 1000);
    private timerSubscription: Subscription;

    constructor(
        private notificationsService : NotificationsService,
    ) { }

    ngOnInit() {
        this.timerSubscription = this.dateTimer.subscribe(() => {
            this.date = moment(this.notification.date).fromNow()
        });
    }

    ngOnDestroy() {
        this.timerSubscription.unsubscribe();
    }

    deleteNotification() {
        this.notificationsService.deleteOneNotification(this.notification);
    }

}

