import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { TranslationService } from '../../_core/translation.service';

@Component({
	selector: 'app-list-dialog',
	templateUrl: './preferences-selection-dialog.component.html',
	styleUrls: ['./preferences-selection-dialog.component.scss']
})
export class PreferencesSelectionDialogComponent implements OnInit, OnDestroy {

    preferences: any[] = [];

	constructor(
		public dialogRef: MatDialogRef<PreferencesSelectionDialogComponent>,
        public appService: AppService,
        public translationService: TranslationService,
        private http: HttpClient,
		@Inject(MAT_DIALOG_DATA) public data: any,

	) {
		// Prevent from user to close the modal by using escape or clicking on the backdrop
        // 2020-02-03: Decided to allow the user to close, because in Tabit Web it feels like the user is "stuck" (in case he/she wants to cancel and not select anything)
        //dialogRef.disableClose = true;
	}

	ngOnInit() {
        this.http.get(`${this.data.rsv_url}/rsv/booking/areas`, { params: this.data.params }).subscribe(
            (availableAreas: any) => {
                if (Array.isArray(availableAreas) && availableAreas.length) {
                    this.data.rsv_preferences.forEach(preference => {
                        // Don't include the first_available option
                        if (preference.value == "first_available") return;

                        preference.disabled = !availableAreas.includes(preference.value);

                        this.preferences.push(preference);
                    });
                // Fallback to all preferences
                } else this.preferences = this.data.rsv_preferences;
            },
            // Fallback to all preferences
            (err) => this.preferences = this.data.rsv_preferences
        );
    }

	preferenceClick(selectedPreference) {
		this.dialogRef.close(selectedPreference.value);
	}

    ngOnDestroy() {
        this.translationService.reloadLang();
    }
}
