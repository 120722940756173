import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TabitpayPaymentDialogComponent } from '../dialogs/tabitpay-payment-dialog/tabitpay-payment-dialog.component';
import { AppService } from '../../../app.service';
import { TabitpayService } from '../tabit-pay.service';
import { DialogsService } from '../../../_core/dialogs.service';
import { DefaultCurrencyPipe } from '../../../_core/pipes';

import { get, each, cloneDeep, ceil } from 'lodash-es';

declare const $: any;

@Component({
    selector: 'tabitpay-checkout-full',
    templateUrl: './tabitpay-checkout-full.component.html',
    styleUrls: ['./tabitpay-checkout-full.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabitpayCheckoutFullComponent implements OnInit, OnDestroy {
    private orderSubscription: Subscription = null;
    @Input() options;
    @Output() onPayment = new EventEmitter<any>();

    @ViewChild('widgetsContent', { read: ElementRef, static: false }) public widgetsContent: ElementRef<any>;

    splitPaymentOptions: any = [];
    
    site: any = {
        amountText: '',
        amountVal: 0
    };

    spOption: any;
    $storage: any;
    PD: any;
    gratuity: any;
    partialPayment: any;
    manualPartial: any;
    total: any;
    hasCCInfo: any;
    payByOffersResult: any;
    
    partialPaymentActive: boolean;
    closing: boolean = false;
    paying: boolean = false;
    isSizeLG: boolean = false;

    balanceDue: number;
    orderTotal: number;
    menuWidth: number = 300;
    
    updateTimeout;
    roundPrecision = 0;
    currencyFormat = '1.0-0';

    constructor(
        public dialog: MatDialog,
        public appService: AppService,
        public orderService: TabitpayService,
        public dialogsService: DialogsService,
        public currencyPipe: DefaultCurrencyPipe,
        private ngZone: NgZone,
    ) {
        this.$storage = this.orderService.$storage;
        this.PD = this.$storage.order.printData;

        this.gratuity = { type: "x", amount: 0 };

        this.total = this.$storage.order.grandTotal || this.$storage.order.balanceDue;

        let currencyDenom = get(this.$storage.rosConfig.currencySettings, 'minimalTipDenomination', 100);

        switch (currencyDenom) {
            case 1:
                this.roundPrecision = 2;
                this.currencyFormat = '1.2-2';
                break;
            case 10:
                this.roundPrecision = 1;
                this.currencyFormat = '1.1-1';
                break;
        }

        // ----------------------------------------------------------------------->

        this.orderSubscription = this.orderService.orderChanged$.subscribe(() => {
            this.updateParams();
        });
        this.hasCCInfo = get(this.$storage, 'wallet.ccinfo') != null;
        this.initParams();
    }

    initParams() {
        const orderTotal = this.orderTotal = this.$storage.order.itemsTotal;
        this.balanceDue = this.$storage.order.balanceDue;
        let paymentsArray = [];
        each([1, 2, 3, 4], (val) => {
            let o: any = { split: val, text: val + '', amount: 0 };
            o.amount = ceil(orderTotal / val, this.roundPrecision);
            if (val == 1) {
                o.isText = true;
                o.amount = this.balanceDue;
                o.text = this.appService.translate("order_balance");
            } else {
                o.text = 1 + "/" + val;
                o.disabled = o.amount > (this.balanceDue + 1);
            }
            o.subText = o.amount;
            paymentsArray.push(o);
        });
        this.manualPartial = { isManual: true, text: this.appService.translate("other"), isText: true };
        paymentsArray.push(this.manualPartial);
        this.splitPaymentOptions = paymentsArray;

        if (!this.partialPayment) this.partialPayment = this.splitPaymentOptions[0];
    }

    updateParams() {
        window.clearTimeout(this.updateTimeout);
        this.updateTimeout = window.setTimeout(() => {
            if (this.closing) return;
            this.balanceDue = this.$storage.order.balanceDue;

            if (this.balanceDue === 0) {
                this.onPayment.emit({});
                return
            }

            if (!this.paying) {
                this.appService.toastr('MESSAGES.PAY_ORDER_CHANGED', { type: 'info' });
            }

            const orderTotal = this.orderTotal = this.$storage.order.itemsTotal;
            each(this.splitPaymentOptions, (pm) => {
                const split = pm.split;
                if (pm.isManual) {
                    if (pm == this.partialPayment) {
                        pm.amount = Math.min(pm.amount, this.balanceDue);
                    }
                } else if (split === 1) {
                    pm.amount = this.balanceDue;
                } else {
                    pm.amount = Math.ceil(orderTotal / split);
                    pm.disabled = pm.amount > (this.balanceDue + 1);
                }
                pm.subText = pm.amount;
            });
            this.setSplitPaymentOption(this.partialPayment, true);

        }, 500);
    }

    //UI  --------------------------------------------------------------------->

    ngOnInit() {
        this.isSizeLG = $(window).height() >= 731;
        window.setTimeout(() => {
            this.menuWidth = $("#pm-select-btn").outerWidth() || 300;
            if (this.widgetsContent) {
                let hElm = this.widgetsContent.nativeElement;
                if (hElm.scrollWidth > hElm.offsetWidth) {
                    $(hElm).parent().addClass('has-scroll');
                }
            }
        });
        // New mode (in order to support pay by item)
		if (this.options.subMode == 'tip') {
			this.$storage.paymentMode = 'tip';
		} else if (this.options.subMode == 'items') {
			this.$storage.paymentMode = 'byItem';
			this.payByOffers();
		} else {
			this.$storage.paymentMode = this.$storage.stepsMode ||  'full';
		}
    }

    ngOnDestroy() {
        this.orderSubscription.unsubscribe();
    }

    scrollPayers(op) {
        op *= -1;
        let diff = 150 * op;
        let newScroll = this.widgetsContent.nativeElement.scrollLeft + diff

        $(this.widgetsContent.nativeElement).animate({ scrollLeft: newScroll + "px" }, 200);
    }

    //START PARTIAL PAYMENT --------------------------------------------------------------------->

    payByOffers() {
        this.dialogsService.payByOffersDialog({
            $storage: this.$storage,
            balanceDue: this.balanceDue,
            selectedOffers: this.payByOffersResult
        }).then(ret => {
            const res:any = ret;
            if (get(res, 'selectedOffers[0]')) {
                this.payByOffersResult = res.selectedOffers;
                this.setOtherPayment(this.manualPartial, res.amount);
            }
        }).catch(err => { });
    }

    setOtherPayment(spo, amount) {
        spo.amount = amount;
        let diffAmount = spo.amount - this.balanceDue;
        if (diffAmount > 0) spo.amount = this.balanceDue;

        this.partialPayment = spo;
        this.partialPaymentActive = true;
        this.onPartialPaymentChange();

        if (diffAmount > 0) this.setValGratuity(diffAmount);
    }

    setSplitPaymentOption(spo, silent?) {
        if (spo.split) {
            this.partialPayment = spo;
            this.partialPaymentActive = true;
            this.onPartialPaymentChange();
            this.payByOffersResult = null;
        } else if (!silent) {
            this.dialogsService.showKeypadDialog(
                {
                    title: 'AMOUNT_TO_PAY',
                    currency: this.appService.currency,
                    maxVal: this.$storage.order.balanceDue,
                    response: { amount: this.partialPayment.amount, amountText: this.partialPayment.amount + "" },
                    enableDecimals: false
                }
            ).then(response => {
                if (response) {
                    this.payByOffersResult = null;
                    spo.amount = response["amount"];
                    this.partialPayment = spo;
                    this.partialPaymentActive = true;
                    this.onPartialPaymentChange();
                }
            }).catch((e) => { });
        }
    }

    onPartialPaymentChange() {
        if (this.partialPayment.amount > this.$storage.order.balanceDue) {
            this.partialPayment.amount = this.$storage.order.balanceDue;
        }
        if (this.gratuity.type == 'p') {
            this.gratuity.amount = this.orderService.calculateGratuityFromPercentage(this.gratuity.percent, this.partialPayment.amount);
        }
    }

    // PM ------------------------------------------------------------------------------------------->

    selectWalletPM(pm) {
        if (this.gratuity.amount && !pm.enableGratuity) {
            const message = this.appService.translate("MESSAGES.GRATUITY_NOT_ALLOWED_FOR_PM", { pm: pm.name });
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: message,
                    primaryButtonText: 'got_it'
                }).then(res => { }).catch(err => { });
            });
        } else {
            this.$storage.wallet.ccinfo = pm;
        }
    }

    // START GRATITY --------------------------------------------------------------------------------->

    checkGratuityPM(pm?) {
        if (!pm) {
            if (!this.hasCCInfo) return true;
            pm = this.$storage.wallet.ccinfo;
        }
        return true;
    }

    setManualGratuity() {
        if (!this.checkGratuityPM()) return;
        let amount = this.gratuity.type == 'm' && this.gratuity.amount || 0;
        let maxVal = this.$storage.order.itemsTotal;

        this.dialogsService.showKeypadDialog(
            {
                title: 'GRATUITY_AMOUNT',
                currency: this.appService.currency,
                maxVal: maxVal,
                response: { amount: amount, amountText: amount + "" },
                enableDecimals: false
            }
        ).then(response => {
            if (response) {
                this.setValGratuity(response["amount"]);
            }
        }).catch((e) => { });
    }

    setGratuity(_type, pVal) {
        if (_type != 'n' && !this.checkGratuityPM()) return;
        this.gratuity.type = _type;
        this.gratuity.percent = pVal;
        if (!isNaN(pVal) && pVal !== 0) {
            this.gratuity.amount = this.orderService.calculateGratuityFromPercentage(pVal, this.options.subMode == 'tip' ? this.orderTotal : this.partialPayment.amount);
        } else {
            delete this.gratuity.percent;
            delete this.gratuity.amount;
        }
    };

    setValGratuity(val) {
        var gratuity = this.gratuity;
        gratuity.type = 'm';
        gratuity.amount = val;
    };

    //SUBMIT PAYMENT ---------------------------------------------------------------------------->

    getPaymentCCInfo(data) {
        return new Promise((resolve, reject) => {
            if (!data.altPayment && this.hasCCInfo) resolve({ ccinfo: cloneDeep(this.$storage.wallet.ccinfo) });
            else {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    const dialogRef = this.dialog.open(TabitpayPaymentDialogComponent, {
                        width: "300px",
                        backdropClass: 'dialog-backdrop-darker',
                        panelClass: 'rounded-dialog',
                        direction: this.appService.direction,
                        disableClose: true,
                        data,
                        autoFocus: false
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result?.ccinfo) {
                            resolve({
                                ccinfo: result.ccinfo,
                                updatePaymentInfo: result.updatePaymentInfo,
                                altPayment: true,
                            });
                        } else {
                            reject();
                        }
                    });
                });
            }
        });
    }

    submit(altPayment?) {
        if (this.partialPayment.amount != this.$storage.order.balanceDue) {
            this.$storage.order.isPartialPayment = true;
            this.$storage.order.grandTotal = this.partialPayment.amount;
        } else {
            delete this.$storage.order.isPartialPayment;
            this.$storage.order.grandTotal = this.$storage.order.balanceDue;
        };

        //START GRATUITY SAVE
        var total = this.$storage.order.grandTotal;
        if (!this.$storage.enableGratuity) this.gratuity.type = 'c';
        var gratuity = this.gratuity;
        switch (gratuity.type) {
            case "x":
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'info',
                        dialogText: "MESSAGES.CONTINUE_WITHOUT_GRATUITY",
                        primaryButtonText: "CONTINUE_WITHOUT_GRATUITY",
                        secondaryButtonText: "back"
                    }).then(res => {
                        this.gratuity.type = "c";
                        this.submit(altPayment);
                    }).catch(err => { });
                });
                return;
                break;
            case "mm": //fixed amount
                gratuity.percent = gratuity.amount / total * 100
                break;
            case "m"://manual gratuity
                var amount = gratuity.amount;
                if (isNaN(amount) || amount == 0) {
                    gratuity.type == 'c';
                    gratuity.amount = 0;
                    gratuity.percent = 0;
                } else {
                    gratuity.percent = gratuity.amount / total * 100
                }
                break;
            case "c":
                gratuity.amount = 0;
                gratuity.percent = 0;
                break;
        }
        this.$storage.order.gratuity = gratuity;
        this.orderService.fixGratuity();

        let that = this;
        let args: any = { gratuityAmount: this.$storage.order.gratuityAmount };

        // check if the site is closed
        var partialAmount;

        if (this.$storage.order.isPartialPayment) {
            args.isPartialPayment = true;
            partialAmount = this.$storage.order.grandTotalPlusTip;
        };
        that.paying = true;
        let paymentAmount = this.currencyPipe.transform(this.$storage.order.grandTotalPlusTip);

        this.getPaymentCCInfo({
            altPayment,
            hasGratuity: this.gratuity.amount > 0,
            total: this.partialPayment.amount + this.gratuity.amount
        }).then((res: any) => {
            const ccinfo = res.ccinfo;
            if (!get(ccinfo, 'enableGratuity', true) && this.gratuity.amount) {
                return this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: this.$storage.gratuityPAWarning,
                        secondaryButtonHidden: true
                    });
                });
            }
            return that.orderService.checkForIdCard(ccinfo).then(function () {
                const ccdetails = get(that, 'ccinfo.customerDetails');
                if (ccdetails) ccinfo.customerDetails = ccdetails;
                if (partialAmount) ccinfo.amount = partialAmount;

                that.appService.startBlock({ text: "FINALIZING_ORDER", class: 'dark-block' });
                return that.orderService.checkForSignature(ccinfo).then(() => {
                    args.ccinfo = ccinfo;
                    args.updatePaymentInfo = res.updatePaymentInfo;
                    that.orderService.processPayment(args).then(handlePaySuccess, handlePayFail);
                });
            });
        }).catch(err => {
            that.paying = false;
        });

        function handlePaySuccess() {
            that.closing = true;
            that.appService.stopBlock({ class: 'dark-block' });
            that.$storage.wasPayment = true;
            that.onPayment.emit({ amount: paymentAmount, showMessage: true });
        }

        function handlePayFail(args) {
            that.appService.stopBlock({ class: 'dark-block' });

            // loyalty error dialog already active
            if (args?.code == 409903) return;

            if (args.phone) {
                that.orderService.showMessageWithPhone(args.message, args.phone).then(() => {
                    if (args.orderClosed) {
                        handlePaySuccess();
                    }
                });
            } else {
                if (args.type == 'CANCELED') return;
                if (args.type == 'RESET') {
                    that.onPayment.emit({ amount: 0, showMessage: false });
                    return;
                } that.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: args.message,
                    secondaryButtonHidden: true,
                }).then(() => {
                    if (args.orderClosed) {
                        handlePaySuccess();
                    }
                });
            }
        }
    }

}
