import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';

import { get, each, find, isString } from 'lodash-es';

declare const $: any;

@Component({
    selector: 'accessibility-menu',
    templateUrl: './accessibility-menu.component.html',
    styleUrls: ['./accessibility-menu.component.scss']
})

@UntilDestroy()
export class AccessibilityMenuComponent implements OnInit {

    public features = [
        // {"id":"monochrom", "icon":"icaccess-monochrome", "active": false, "class":"ac-gray"},
        {"id":"light-contrast", "icon":"contrast-dark", "active": false, "class":"ac-contrast-light"},
        {"id":"dark-contrast", "icon":"contrast-dark", "active": false, "class":"ac-contrast-dark"},
        {"id":"readable-font", "icon":"enlarge-font", "active": false, "class":"ac-font"},
        {"id":"big-white-curser", "icon":"bigger-cursor", "active": false, "class":"ac-pointer-white", last:true},
        {"id":"big-black-curser", "icon":"bigger-cursor", "active": false, "class":"ac-pointer-black", last:true},
        // {"id":"enlarge-frame", "icon":"icaccess-zoom-window", "active": false, "class":"ac-zoom"},
        // {"id":"highlight-links", "icon":"icaccess-link", "active": false, "class":"ac-links"},
        // {"id":"highlight-headers", "icon":"icaccess-heading", last:true},
        // {"id":"image-info", "icon":"icaccess-picture", last:true}
        //{"id":"enlarge-font", "icon":"ma-icon-4"},
        //{"id":"reduce-font", "icon":"ma-icon-5"},
    ];

    public menuActive: boolean = false;
    public hasAccess: boolean = false;
    public declarationURL: string;
    private element = 'accessibility-menu';

    constructor(
        public appService: AppService
    ) { }

    // enforce hiding the toggle button
    @Input() dialogViewMode?: boolean = false;

    ngOnInit() {
        // updates only "this.userDetails.wallet" that is required in other parts of the app
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            if (domain) {
                this.declarationURL = get(domain, 'links.accessibilityDeclarationURL');
            }
        });

        // mobile devices: remove the "pointer color" options
        if (this.appService.isMobile()) {
            this.features = this.features.filter(feature => {
                return !['big-white-curser', 'big-black-curser'].includes(feature.id);
            })
        }

        // cache the selection on localStorage (relevant when the menu accessed from dialog, cordova only for now)
        if (window['cordova']) {
            // merge the cached settings
            const cachedSettings = this.appService.getAccessibilityFeatures();
            if (cachedSettings) {
                cachedSettings.forEach(feature => {
                    if (feature.active) {
                        // invert the active expression so it'd get toggled
                        feature.active = false;
                        this.toggleFeature(feature);
                    }
                });
            }
        }
    }

    toggleActive() {
        this.menuActive = !this.menuActive;
        $(this.element).toggleClass('active');
    }

    removeAccess() {
        each(this.features, (feature) => {
            if (feature.active) this.removeFeatureClass(feature);
        });
        this.hasAccess = false;
        $(window).trigger("resize");
        this.toggleActive();
    }

    toggleFeature(feature: any) {
        if (feature.active) {
            this.removeFeatureClass(feature);
            this.hasAccess = find(this.features, {active: true}) != null;
        } else {
            switch(feature.id){
                case "big-white-curser":
                    this.removeFeatureClass("big-black-curser");
                break;
                case "big-black-curser":
                    this.removeFeatureClass("big-white-curser");
                break;
                case "monochrom":
                    this.removeFeatureClass("dark-contrast");
                    this.removeFeatureClass("light-contrast");
                break;
                case "light-contrast":
                    this.removeFeatureClass("dark-contrast");
                    this.removeFeatureClass("monochrom");
                break;
                case "dark-contrast":
                    this.removeFeatureClass("light-contrast");
                    this.removeFeatureClass("monochrom");
                break;
            }
            this.setFeatureClass(feature);
            this.hasAccess = true;
        }

        // on cordova - cache the selection on localStorage
        if (window['cordova']) {
            this.appService.setAccessibilityFeatures(this.features);
        }

        $(window).trigger("resize");
    }

    setFeatureClass(feature){
        // mutate "this.features"
        for (const item of this.features) {
            if (item.id == feature.id) {
                item.active = true;
                if (feature.class) $("body").addClass(feature.class);
                return;
            }
        }
    }

    removeFeatureClass(feature){
        if (isString(feature)) feature = find(this.features, { id: feature });
        if (!feature) return;
        // mutate "this.features"
        for (const item of this.features) {
            if (item.id == feature.id) {
                item.active = false;
                if (feature.class) $("body").removeClass(feature.class);
                return;
            }
        }
    }

    openLink() {
        if (window['cordova']) {
            window.open(this.declarationURL, '_system');
        } else {
            // When opening a window on a browser (especially in Safari Mobile), we must not specify the second argument ("name") because otherwise, the second time the user clicks the button - the window.open won't switch to the relevant tab (in Safari Mobile).
            window.open(this.declarationURL);
        }
    }
}
