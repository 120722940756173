import { trigger, style, animate, transition, animateChild, query, group } from '@angular/animations';

export const zoomOutAnimation = trigger('zoomOutAnimation', [
    transition(':leave', [
        query('.expanded', [
            animateChild()
        ], { optional: true }),
        animate('0.4s 0s ease-in', style({ transform: 'scale(0)' }))
    ]),
]);

export const routeAnimation = trigger('routeAnimation', [

    transition('signIn => *', [
        group([
            query(':leave', [
                // 2019-10-23 - We wanted to have a nice slide out (using transform: 'translateY(100%)') but the performance was poor (Angular loads both Enterer and Leaver components into memory as soon as the animation starts, and it causes hickups of the animations)
                // 2019-12-23 - Z-Index 6 is used so that the Sign In component will be "above" the header, footer and the No Location Indicator
                style({ position:'absolute', width:'100vw', height: '100vh', 'z-index': 6, opacity: 1 }),
                animate('250ms 0ms linear', style({ position:'absolute', width:'100vw', height: '100vh', 'z-index': 6, opacity: 0 })),
                // 2019-12-23 - Attempting to use TraslateX and prevent any performance hickups using group() and delays. Bottom line - performance wasn't good, so we switched back to Opacity only.
                //style({ position:'absolute', width:'100vw', height: '100vh', 'z-index': 6, transform: 'translateX(0)' }),
                //animate('200ms 100ms ease-in', style({ position:'absolute', width:'100vw', height: '100vh', 'z-index': 6, transform: 'translateX(100%)' })), // The delay is needed to let the "enterer" render itself behind the scenes
            ], { optional: true }),
            query(':enter', [
                // 2020-02-05 - Found out that this delay is not required. Works smoothly without it too.
                //animate('0ms 400ms linear'), // Just a delay to "ease the pain" (between the leaver and the enterer) from a performance pespective
                query('*', animateChild())
            ], { optional: true })
        ])
    ]),

    transition('* => signIn', [
        query(':leave', [
            style({ opacity: 1 }),
            animate('250ms 0s ease-out', style({ opacity: 0 }))
        ], { optional: true })
    ]),

    transition('* => siteDetails', [
        query(':enter', [
            style({ left: '100vw' })
        ], { optional: true }),
        group([
            query(':leave', [
                animate('0s 1s linear')
            ], { optional: true }), // Just delay the leaver, if exists
            query(':enter', [
                animate('250ms 0s ease-out', style({ left: 0 }))
            ], { optional: true }),
        ]),
    ]),

    transition('siteDetails => *', [
        // 2019-10-08 - The animation of the details-view sliding out occurs as a DOM animation inside the details component.
        // The reason is that when trying to do it as the commented block above - the performance of the :leave was poor (probably because Angular is loading the :enter component in parallel to the animation)
        query(':enter', [
            style({ opacity: 0 }),
            animate('150ms 0s linear', style({ opacity: 1 })),
            query('*', animateChild())
        ], { optional: true }),
    ]),

]);
