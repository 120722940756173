import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root'
})
export class CordovaFireMessagingService {

    public messages: Subject<any> = new Subject();

    private getTokenAndSubscribeToMessages: Observable<string> = new Observable((observer) => {
        const FirebasePlugin: any = window['FirebasePlugin'];
        if (!FirebasePlugin) return observer.error('Cordova firebase plugin doesn\'t present as a global, please don\'t suscribe');

        console.debug('=== CORDOVA/FIRE/SERVICE === Going to get the FCM token...');

        FirebasePlugin.getToken((fcmToken: string) => {

            if (!fcmToken) return observer.error(new Error('Empty FCM token received'));

            console.debug('=== CORDOVA/FIRE/SERVICE === Going to subscribe to messages...');

            FirebasePlugin.onMessageReceived((payload: any) => {

                this.ngZone.run(() => {
                    this.messages.next(payload);
                });

            }, (error: Error) => {

                error.message = `Error on receiveing message: ${error.message}`;
                observer.error(error);

            });

            observer.next(fcmToken);

        }, (error: Error) => {

            this.appService.setIsPushNotificationsDisabled(true);
            error.message = `Error getting token: ${error.message}`;
            observer.error(error);

        });

    });

    public requestToken: Observable<string> = new Observable((observer) => {
        const FirebasePlugin: any = window['FirebasePlugin'];
        if (!FirebasePlugin) return observer.error('Cordova firebase plugin doesn\'t present as a global, please don\'t suscribe');

        console.debug('=== CORDOVA/FIRE/SERVICE === Going to check if has permission...');

        FirebasePlugin.hasPermission((hasPermission: boolean) => {
            this.ngZone.run(() => {
                if (hasPermission) {
                    console.debug('=== CORDOVA/FIRE/SERVICE === Permission already granted...');
                } else {
                    console.debug('=== CORDOVA/FIRE/SERVICE === Going to ask for grant permission...');
                }

                if (!hasPermission) return FirebasePlugin.grantPermission((granted: boolean) => {

                    if (!granted) return observer.error(new Error('permission-blocked'));

                    this.getTokenAndSubscribeToMessages.subscribe(observer);

                }, (error: Error) => {

                    this.appService.setIsPushNotificationsDisabled(true);
                    error.message = `Error at grant permission: ${error.message}`;
                    observer.error(error);

                });

                this.getTokenAndSubscribeToMessages.subscribe(observer);
            });
        }, (error: Error) => {
            this.ngZone.run(() => {

                this.appService.setIsPushNotificationsDisabled(true);
                error.message = `Error checking if we have permission: ${error.message}`;
                observer.error(error);

            });
        });

    });

    constructor(
        private ngZone: NgZone,
        private appService: AppService,
    ) { }

}
