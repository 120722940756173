import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'dashboard-section',
    templateUrl: './dashboard-section.component.html',
    styleUrls: ['./dashboard-section.component.scss'],
    animations: [
        trigger('spinnerAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s 0.4s linear', style({ opacity: 1 })),
            ]),
        ]),
        trigger('noResultsCardAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.5s 0.4s linear', style({ opacity: 1 })),
            ]),
        ]),
    ],
    host: {class: 'wl-app-primary-background-color'}
})
export class DashboardSectionComponent implements OnInit, OnDestroy {

    @Input() public items: any = []; // Could be organizations, categories...
    @Input() public title: string;
    @Input() public moreLink: string[] = null;
    @Input() public qparams: any = {};
    @Input() public loading: boolean = true;
    @Input() public higher: boolean = false;

    preventFlickeringDelay:boolean = true;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.preventFlickeringDelay = false;
        }, 100); // This delay is necessary because we don't want the "no results" to flicker while the items are being populated too.
    }

    ngOnDestroy() {

    }

}
