<div class="notification-wrapper">
    <div class="notification-body">
        <div class="notification-title flex-grow-1 text-truncate">
            {{ notification.title }}
        </div>
        <div class="notification-message flex-grow-1 text-truncate">
            <div class="message">{{ notification.message }}</div> <div class="notification-time">{{ date }}</div>
        </div>
        <div class="notification-org-name">{{ notification.orgName }}</div>
    </div>
    <div class="delete-button" (click)="deleteNotification()">
        <mat-icon svgIcon="delete"></mat-icon>
    </div>
</div>
<div class="divider"></div>
