<div mat-dialog-content mat-dialog-close [class.dark-theme]="data.darkThemeWithCenteredText">
    <div *ngIf="!data.hideTitle" class="_title">
        <div class="{{data.titleClass}}">{{data.title | translate}}</div>
    </div>

    <div class="text-center" *ngIf="data.animation">
        <button mat-button [mat-dialog-close]="true">
            <ng-lottie [options]="{path: data.animation, autoplay: true, loop: data.loop || false, speed:data.speed }" [height]="data.height || 200"></ng-lottie>
        </button>
    </div>
    <span *ngIf="data.content">{{data.content | translate}}</span>
</div>

<div mat-dialog-actions *ngIf="!data.darkThemeWithCenteredText">
    <button type="button" mat-flat-button color="accent" [mat-dialog-close]="true" class="rounded">
        {{(data.confirmText || 'OK') | translate}}
    </button>
</div>
