<!-- Future Reservation Template -->
<div *ngIf="historyItem && type == 'future_reservation'" class="history-item" (click)="bookService.openRSV(historyItem)">
    <div class="_icon" [style.background-image]="appService.images.his_pay | safeStyle"></div>
    <div class="details-container flex-grow-1">
        <div class="history-site-name"><h3 *ngIf="siteName"> {{ siteName }} </h3></div>
        <div *ngIf="appService.localeId == 'he-IL'" class="date-time">{{ historyItem.reservation_details.reserved_from | date: 'd בMMM y, HH:mm': '': appService.localeId }}</div>
        <div *ngIf="['en-US', 'en-AU'].includes(appService.localeId)" class="date-time">{{ historyItem.reservation_details.reserved_from | date: 'MMM d y, HH:mm': '': appService.localeId }}</div>
        <div class="details">{{ _getFutureReservationDetails(historyItem) }}</div>
    </div>
    <div>
        <mat-icon>{{appService.nextIcon}}</mat-icon>
    </div>
</div>

<!-- Past Reservation Template -->
<div *ngIf="historyItem && type != 'future_reservation'" class="history-item">
    <div class="icon" [style.background-image]="historyItem._o.img | safeStyle"></div>
    <div class="details-container flex-grow-1" (click)="_redirectToSite()">
        <div class="history-site-name"><h3 *ngIf="siteName"> {{ siteName }} </h3></div>
        <div *ngIf="appService.localeId == 'he-IL'" class="date">{{ historyItem.date | date: 'd MMM y, HH:mm': '': appService.localeId }}</div>
        <div *ngIf="['en-US', 'en-AU'].includes(appService.localeId)" class="date">{{ historyItem.date | date: 'MMM d y, HH:mm': '': appService.localeId }}</div>
        <div class="details">
            <span>{{ historyItem.serviceType | translate }}</span>
            <span> | </span>
            <span>{{ historyItem.amount | lcurrency }}</span>
        </div>
    </div>
    <!-- Defined by reviewButtonEnabled logic -->
    <div *ngIf="!historyItem.reviewedAlready && reviewButtonEnabled" class="button-container" (click)="_redirectToUserReview(historyItem)">
        <img class="icon" [src]="appService.base('assets/images/svg/user-review.svg')" />
        <span>{{'user_review_how_was_it' | translate}}</span>
    </div>
    <!-- Only would appear at the my-history page -->
    <div *ngIf="withBill" class="button-container" (click)="historyItem.billId && dialogsService.openBillDialog(historyItem)">
        <img [src]="appService.base('assets/images/svg/bill_new.svg')" />
        <span>{{'view_bill' | translate}}</span>
    </div>
    <div *ngIf="(historyItem.reviewedAlready || !reviewButtonEnabled) && !withBill" class="button-container" (click)="_redirectToSite()">
        <mat-icon>{{ appService.nextIcon }}</mat-icon>
    </div>
</div>
