<form (ngSubmit)="addressSubmit()" #addAddressForm="ngForm">

    <mat-form-field>
        <mat-label>{{ 'address_type' | translate }}</mat-label>
        <mat-select name="addressType" [(value)]="addressType" required>
            <mat-option class="wl-ignore-theme" value="null" disabled>{{ 'please_select_type' | translate }}</mat-option>
            <mat-option class="wl-app-primary-background-color" value="apartment" selected>{{ 'apartment' | translate }}</mat-option>
            <mat-option class="wl-app-primary-background-color" value="office" selected>{{ 'office' | translate }}</mat-option>
            <mat-option class="wl-app-primary-background-color" value="house" selected>{{ 'house' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'please_type_address_full' | translate }}</mat-label>
        <input 
            matInput 
            name="free-address-text" 
            (input)="onFreeAddressTextChange($event)" 
            [(ngModel)]="addressFieldModel"
        />
        <!-- <mat-icon *ngIf="searchingAddress" class="waiting-animated" matSuffix>spinner</mat-icon> -->
        <mat-spinner [diameter]="20" strokeWidth="2" *ngIf="searchingAddress" class="waiting-animated" matSuffix color="accent"></mat-spinner>
        <mat-hint *ngIf="searchedAddressOnce && !googleAddressPredictions.length && !googleAddressPicked">{{ 'address_not_found' | translate }}</mat-hint>
    </mat-form-field>

    <mat-list class="suggested-addresses" *ngIf="googleAddressPredictions?.length">
        <mat-radio-group name="picked-address" [(ngModel)]="googleAddressPickedIndex" labelPosition="after">
            <mat-list-item
                class="suggested-address"
                *ngFor="let address of googleAddressPredictions; let i = index"
                (click)="googleAddressPickedIndex = i; chooseAddress(address)"
            >
                <mat-icon matListIcon><mat-radio-button [value]="i" labelPosition="after"></mat-radio-button></mat-icon>
                <div matLine>{{ address.formatted_address }}</div>
            </mat-list-item>
        </mat-radio-group>
    </mat-list>

    <div class="notes">
        <mat-form-field>
            <mat-label>{{ 'notes' | translate }}</mat-label>
            <input matInput type="text" name="notes" [(ngModel)]="notes" />
        </mat-form-field>
    </div>

    <!--
    <mat-form-field>
        <mat-label>עיר</mat-label>
        <input matInput type="text" name="city" [(ngModel)]="city" required disabled />
    </mat-form-field>

    <div class="street-house">
        <div class="street">
            <mat-form-field>
                <mat-label>רחוב</mat-label>
                <input matInput type="text" name="street" [(ngModel)]="street" required disabled />
            </mat-form-field>
        </div>
        <div class="house">
            <mat-form-field>
                <mat-label>בית</mat-label>
                <input matInput type="text" name="house" [(ngModel)]="house" required disabled />
            </mat-form-field>
        </div>
    </div>
    -->

    <div *ngIf="needsBuildingDetails()" class="entrance-floor-apartment-notes">
        <div class="entrance-floor-apartment-container">
            <div class="floor">
                <mat-form-field>
                    <mat-label>{{ 'floor' | translate }}</mat-label>
                    <input matInput type="tel" name="floor" [(ngModel)]="floor" required />
                </mat-form-field>
            </div>
            <div class="apartment" *ngIf="addressType != 'office'">
                <mat-form-field>
                    <mat-label>{{ 'apartment' | translate }}</mat-label>
                    <input matInput type="tel" name="apartment" [(ngModel)]="apartment" required />
                </mat-form-field>
            </div>
            <div class="entrance">
                <mat-form-field>
                    <mat-label>{{ 'entrance' | translate }}</mat-label>
                    <input matInput type="text" name="entrance" [(ngModel)]="entrance" />
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="notes">
            <mat-form-field>
                <mat-label>{{ 'notes' | translate }}</mat-label>
                <input matInput type="text" name="notes" [(ngModel)]="notes" />
            </mat-form-field>
        </div> -->
    </div>

    <div class="submit">
        <button type="submit" mat-flat-button color="primary" [disabled]="!validForm() || !googleAddressPicked">
            <mat-spinner *ngIf="googleAddressPickedIndex !== undefined && !googleAddressPicked" diameter="20" strokeWidth="2" class="light-grey"></mat-spinner>
            <span *ngIf="googleAddressPickedIndex === undefined || googleAddressPicked">{{ 'add_address' | translate}}</span>
        </button>
    </div>
</form>
