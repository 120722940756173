import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsLoaderService {
    private apiKey = environment.appConfig.googleKey;
    private language = 'iw';
    private libraries = 'places,geometry';
    public googleMapsLoaded = false;

    constructor() {
        this.loadScript();
    }

    loadScript(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!document.getElementById('google-maps-script')) {
                const script = document.createElement('script');
                script.id = 'google-maps-script';
                script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&language=${this.language}&libraries=${this.libraries}`;
                script.async = true;
                script.defer = true;
                script.onload = () => {
                    this.googleMapsLoaded = true;
                    resolve();
                }
                script.onerror = (error) => reject(error);
                document.head.appendChild(script);
            } else {
                resolve();
            }
        });
    }
}
