import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '../app.service';
import { AddressesService, Address } from './addresses.service';
import { getMapAreas, MAP_AREA, setMapAreas } from './static/map-areas';

import moment from 'moment';
import { get } from 'lodash-es';

const EARTH_RADIUS = 6378137;

export interface LocationLabeled {
    location: { lat: number, lng: number };
    label: string;
    actual: boolean;
    savedAddressId: string; // The saved address ID
    area: string; // If to search map bounds
    timestamp: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

    private watchingLocation: boolean = false;
    private errorEncountered: boolean = false;

    public hasLocation = false;
    public watchPositionID: any;

    public previousLocation: { lat: number, lng: number };

    public currentLocation: { lat: number, lng: number };

    public previousLocationTimestamp: any;

    // Before the actual location attempt, it still takes some time before "initial location" initialized:
    public locationInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // First Attempt of getting actual location:
    public firstActualLocation: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private chosenLocation: BehaviorSubject<LocationLabeled>;

    public location: Observable<LocationLabeled>;

    private actualLocation: BehaviorSubject<LocationLabeled> = new BehaviorSubject(null);

    public actualLocationAvailable: Observable<boolean> = this.actualLocation.pipe(map(actualLocation => !!actualLocation));

    private savedAddresses: Address[] = [];

    private kmToMileDivider = 0.621371;

    private domain: any;

    constructor(
        private appService: AppService,
        private addressesService: AddressesService,
        private ngZone: NgZone
    ) {
        this.getDefaultLocation();
        this.appService.domain.subscribe(domain => {
            if (domain) {
                this.domain = domain;
                this.getDefaultLocation();
            }
        });
    }

    public getDefaultLocation() {
        const defaultArea = this.getDefaultArea();

        // Location ALWAYS has to contain something, so starting with Tel Aviv.
        // This is not yet the "initial location". Wait for "locationInitialized". Could also be saved address
        if (!this.chosenLocation) {
            this.chosenLocation =  new BehaviorSubject({
                location: defaultArea.location,
                label: this.appService.translate('areas.' + defaultArea.key),
                actual: false,
                savedAddressId: null,
                area: defaultArea.key,
                timestamp: Date.now(),
            });
        } else {
            this.chosenLocation.next({
                location: defaultArea.location,
                label: defaultArea.customLabel || this.appService.translate('areas.' + defaultArea.key),
                actual: false,
                savedAddressId: null,
                area: defaultArea.key,
                timestamp: Date.now(),
            });
        }

        this.location = this.chosenLocation.asObservable();
        // Legacy, TODO: Remove.
        // It's in use at "Tabit Order" to2-address-alt.component.ts
        this.currentLocation = this.chosenLocation.getValue().location;
    }

    public getDefaultArea(): MAP_AREA {
        const defaultArea = this.getDefaultAreaFromDomainOrMapAreas();
        if (!defaultArea) throw new Error(`Expecting a default area with a specific key: "${defaultArea.key}"`);
        if (!defaultArea.location || !defaultArea.key) throw new Error('Default area is missing properties');
        return defaultArea;
    }

    public setMapAreas() {
        if (!this.isDomainHasAreas()) return;
        setMapAreas(this.appService.appConfig.locale, get(this.domain, 'defaults.areas'));
    }

    private getDefaultAreaFromDomainOrMapAreas(): MAP_AREA {
        const defaultArea = getMapAreas(this.appService.appConfig.locale).find(area => area.key === this.appService.appConfig.defaultArea);
        const defaultDomainArea = this.isDomainHasAreas() ? get(this.domain, 'defaults.areas').find(area => area.default) : null;

        // domains can have default areas & areas custom labels
        if (defaultDomainArea) {
            this.setMapAreas();
            const customLabel = this.domain?.translations[this.appService.appConfig.locale]?.areas[defaultDomainArea.key];
            if (customLabel) defaultDomainArea.customLabel = customLabel;
        }
        return defaultDomainArea || defaultArea;
    }

    private isDomainHasAreas() {
        const areas = get(this.domain, 'defaults.areas');
        return areas?.length;
    }

    private pickDefaultSavedAddress(addresses: Address[]) {
        if (!addresses || !addresses.length) return;

        return addresses.find(address => address.default) || addresses[addresses.length - 1];
    }

    private pickNearbySavedAddress(addresses: Address[], location: LocationLabeled['location']) {
        if (!addresses || !addresses.length) return;

        return addresses.find(address => this.getDistance(address.location, location) <= 200);
    }

    public startLocationManagement(): void {
        combineLatest([
            this.addressesService.loading,
            this.addressesService.addresses
        ]).subscribe(([addressesLoading, addresses]) => {
            if (addressesLoading) return;
            this.savedAddresses = addresses;

            if (!this.locationInitialized.getValue()) {

                console.debug('=== LOCATION/SERVICE === Got addresses after load:', addresses);

                let pickedAddress = this.pickDefaultSavedAddress(addresses);
                if (pickedAddress) this.chosenLocation.next(this.makeLabeledLocationFromAddress(pickedAddress));

                this.locationInitialized.next(true);

                this.startActualLocationManagement();
            }
        });
    }

    private startActualLocationManagement(): void {

        if (this.watchingLocation) return;
        this.watchingLocation = true;

        this.updatePosition((newActualLocation: { lat: number, lng: number }) => {

            // Update Position Callback function
            let actualLocationLabeled: LocationLabeled;
            let nearbyAddress: Address;
            let currentChosenLocation = this.chosenLocation.getValue();

            if (newActualLocation) {
                actualLocationLabeled = this.makeLabeledActualLocation(newActualLocation);
                console.debug('=== LOCATION/SERVICE === New Actual Location:', actualLocationLabeled);
                this.actualLocation.next(actualLocationLabeled);
            } else {
                console.debug('=== LOCATION/SERVICE === We gave up on actual location');
            }

            if (actualLocationLabeled) {
                nearbyAddress = this.pickNearbySavedAddress(this.savedAddresses, actualLocationLabeled.location);
            }

            if (this.firstActualLocation.getValue()) {
                // Updating the chosen location regularly IF the user choose Actual:
                // Using nearby saved address if any:
                if (actualLocationLabeled && currentChosenLocation.actual) {
                    if (nearbyAddress) {
                        console.debug('=== LOCATION/SERVICE === Actual location snaps to saved address, picking it!');
                        if (currentChosenLocation.savedAddressId !== nearbyAddress._id) {
                            this.chosenLocation.next(this.makeLabeledLocationFromAddress(nearbyAddress));
                        }
                    } else {
                        this.chosenLocation.next(actualLocationLabeled);
                    }
                }
            } else {
                // When first time actual location determined (or not, because we gave up):
                // Using nearby saved address if any:
                if (actualLocationLabeled) {
                    if (nearbyAddress) {
                        console.debug('=== LOCATION/SERVICE === Actual location is very close to saved address, picking it!');
                        if (currentChosenLocation.savedAddressId !== nearbyAddress._id) {
                            this.chosenLocation.next(this.makeLabeledLocationFromAddress(nearbyAddress));
                        }
                    } else {
                        this.chosenLocation.next(actualLocationLabeled);
                    }
                }

                this.firstActualLocation.next(true);
            }

            // Legacy, TODO: Remove. At the meantime it must be AFTER the all above next() calls.
            // It's in use at "Tabit Order" to2-address-alt.component.ts
            this.currentLocation = this.chosenLocation.getValue().location;

        });
    }

    private makeLabeledLocationFromAddress(address: Address): LocationLabeled {
        return {
            label: address.formatted_address,
            location: address.location,
            actual: false,
            savedAddressId: address._id,
            area: null,
            timestamp: Date.now(),
        };
    }

    private makeLabeledActualLocation(location: LocationLabeled['location']): LocationLabeled {
        return {
            label: 'around_you',
            location,
            actual: true,
            savedAddressId: null,
            area: null,
            timestamp: Date.now(),
        };
    }

    public chooseActualLocation() {
        let actualLocation = this.actualLocation.getValue();
        let currentChosenLocation = this.chosenLocation.getValue();
        let nearbyAddress = this.pickNearbySavedAddress(this.savedAddresses, actualLocation.location);
        if (nearbyAddress) {
            console.debug('=== LOCATION/SERVICE === Actual location chosen, and it\'s very close to a saved address. picking it!');
            if (currentChosenLocation.savedAddressId !== nearbyAddress._id) {
                this.chosenLocation.next(this.makeLabeledLocationFromAddress(nearbyAddress));
            }
        } else {
            this.chosenLocation.next(actualLocation);
        }
    }

    public chooseSpecifiedLocation(location: LocationLabeled['location'], label: string, area?: string, savedAddressId?: string) {
        this.chosenLocation.next({
            location,
            label,
            actual: false,
            savedAddressId,
            area,
            timestamp: Date.now(),
        });
    }

    /**
     * Update position. Non - RXJS function to determine actuall location od the user (GPS or browser)
     * @param {Function} cb callback which will run on receiving actual location OR deciding that no location is available
     */
    private updatePosition(cb: (location: { lat: number, lng: number }) => void) {
        if (window?.navigator?.geolocation && !this.watchPositionID) {
            // We proceed ONLY if there is NO watchPositionID (in order not to open 2 watchers)
            this.watchPositionID = window.navigator.geolocation.watchPosition(position => {
                let distance: number = 0;
                let actualLocation: { lat: number, lng: number } = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                this.hasLocation = true;
                this.errorEncountered = false;

                // Notify about location change only if the distance between previous and current locations is greater then 2km
                // let previousLocation = JSON.parse(localStorage.getItem('user_location'));
                if (this.previousLocation) {
                    distance = this.getDistance(actualLocation, this.previousLocation) / 1000;
                } else {
                    //console.log('No Previous Location - First Location Updated');
                    this.previousLocation = actualLocation;

                    this.previousLocationTimestamp = moment();

                    this.ngZone.run(() => {
                        cb(actualLocation);
                    });

                }

                if (distance >= 0.5 && moment().diff(this.previousLocationTimestamp) >= 5000) {
                    //console.log('Location Watch Handle: after 0.5 km and at least 5 seconds since the last update, UPDATING...');

                    this.previousLocation = actualLocation;

                    this.previousLocationTimestamp = moment();

                    this.ngZone.run(() => {
                        cb(actualLocation);
                    });

                    //console.log('watchPosition SUCCESS:', distance, this.currentLocation, this.previousLocation);
                } else {
                    //console.log('Location Watch Handle: but has not changed OR time since last update is too short, SKIPPING. distance:', distance, 'seconds:', moment().diff(this.previousLocationTimestamp) / 1000);
                }

            }, (error) => {
                /*
                let cachedActualLocation: { lat: number, lng: number };

                if (this.previousLocation) {
                    console.error('watchPosition ERROR:', error, 'Using last previous saved location:', cachedActualLocation);
                    cachedActualLocation = this.previousLocation;
                } else if (window.navigator.geolocation['lastPosition'] && window.navigator.geolocation['lastPosition'].coords) {
                    console.error('watchPosition ERROR:', error, 'Using last previous position by navigator.geolocation:', cachedActualLocation);
                    cachedActualLocation = {
                        lat: window.navigator.geolocation['lastPosition'].coords.latitude,
                        lng: window.navigator.geolocation['lastPosition'].coords.longitude,
                    };
                } else {
                    console.error('watchPosition ERROR:', error, 'No last position exists');
                    this.hasLocation = false;
                }
                */

                if (error.code === error.PERMISSION_DENIED && window['cordova']) {
                    // 2020-01-31: Decided NOT to show this alert, since we have a fallback now using the location-widget + we have the label at the top header which indicates that the geo-location is enabled/disabled.
                    /*
                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.alert({ content: "MESSAGES.PLEASE_ENABLE_LOCATION", confirmText: window['cordova'] ? "redirect_to_device_settings" : "understood" }).then(response => {
                            if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                                window['cordova'].plugins.diagnostic.switchToSettings((success: any) => {
                                    console.debug('locationAuthorization > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable location - SUCCESS: ', success);
                                }, (error: any) => {
                                    console.debug('locationAuthorization > Cordova Plugin Diagnostic> Redirecting to device settings so the user can enable location - ERROR: ', error);
                                });
                            }
                        }).catch(err => {
                            console.error('Error on popup:', err);
                        });
                    });
                    */
                }

                // 2020-02-12: Since we have the location-widget now, which a fallback to a "location preset" - we don't need to do any changes to the location-widget if the Watch Position fails.
                // So, we simply run the callback with null.
                // Otherwise, passing a previously cached position from here, into the callback, will trigger a chain-reaction which will lead to "this.chosenLocation.next()" to fire, and then the organizations on the screen will refresh.
                console.debug('watchPosition ERROR:', error);
                console.debug('watchPosition ERROR > this.errorEncountered:', this.errorEncountered);
                if (!this.errorEncountered) {
                    // We trigger the callback only when we first encounter an error.
                    // If the WatchPosition continuous to fire errors - we don't want to trigger the callback again and again.
                    this.errorEncountered = true;

                    this.ngZone.run(() => {
                        cb(null); // Could be null, then there is no cached actual location
                    });
                }

            },
            {
                maximumAge: 0,
                timeout: 20000, // Due to the use of high-accuracy, we need to let the watch-position more time to get the accurate position
                enableHighAccuracy: true
            }
            );

            console.debug('Watch Position ID:', this.watchPositionID);

        } else {

            console.error('watchPosition ERROR: *** Navigator.geoLocation does not exist ***');
            cb(null);

        }
    }

    public clearWatchPosition() {
        this.previousLocation = null;
        this.previousLocationTimestamp = null;
        this.watchPositionID = window.navigator.geolocation.clearWatch(this.watchPositionID);
    }

    public getChosenLocation() {
        return this.chosenLocation.getValue();
    }

    public getActualLocation() {
        return this.actualLocation.getValue();
    }

    // I've copied the distance functions from geolib:
    getDistance (
        from: { lat: number, lng: number },
        to: { lat: number, lng: number },
        accuracy: number = 1
    ) {
        accuracy =
            typeof accuracy !== 'undefined' && !isNaN(accuracy) ? accuracy : 1;

        const fromLat = from.lat;
        const fromLon = from.lng;
        const toLat = to.lat;
        const toLon = to.lng;

        const distance =
            Math.acos(
                this.normalizeACosArg(
                    Math.sin(this.toRad(toLat)) * Math.sin(this.toRad(fromLat)) +
                    Math.cos(this.toRad(toLat)) *
                    Math.cos(this.toRad(fromLat)) *
                    Math.cos(this.toRad(fromLon) - this.toRad(toLon))
                )
            ) * EARTH_RADIUS;

        return Math.round(distance / accuracy) * accuracy;
    };

    private normalizeACosArg (val: number): number {
        if (val > 1) {
            return 1;
        }
        if (val < -1) {
            return -1;
        }
        return val;
    };

    private toRad(value: number) {
        return (value * Math.PI) / 180;
    }

	public formattedDistance(distance: number): string {
        if (!distance) return;

        if (this.appService.appConfig.locale === 'en-US') distance = distance / this.kmToMileDivider;

        if (distance > 0 && distance <= 10) {
            return distance.toFixed(1) + ' ';
        } else if (distance > 10 && distance < 1000) {
            return distance.toFixed(0) + ' ';
        } else {
            return 'far_away';
        }
	}
}
