import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AppService } from '../../app.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';

declare const $: any;

@Component({
  selector: 'app-external-dialog',
  templateUrl: './external-dialog.component.html',
  styleUrls: ['./external-dialog.component.scss']
})
export class ExternalDialogComponent {
    @BlockUI() blockUI: NgBlockUI;

    // listen for external iframe messages
    @HostListener('window:message', ['$event']) onMessage(event) {
        if (event.data?.iframeEvent == 'sameHostIframeLoaded') {
            try {
                // get everything after the ? in the url
                const locationSearch = event.data?.fullUrl.match(/\?(.*)/g)[0]
                this.onFrameLoaded(null, locationSearch);
            } catch (error) { }
        }
    }

    trustedIframeUrl: SafeResourceUrl;

    constructor(
        public dialogRef: MatDialogRef<ExternalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer,
        public appService: AppService,
        public entityUtilsService: EntityUtilsService
    ) {
        this.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.redirectURL);
    }

    onFrameLoaded(ev, customUrl?) {
        try {
            let params = customUrl ? this.entityUtilsService.getCustomURLParams(null, customUrl) : this.entityUtilsService.getCustomURLParams(ev.currentTarget?.contentWindow?.location);

            if (params) {
                switch (this.data.provider) {
                    case "CreditGuard": ;
                    case "Meshulam":
                        switch (params.r) {
                            case "success":
                                this.dialogRef.close({ mode: "success" });
                                break;
                            case "error":
                                this.dialogRef.close({ mode: "error" });
                                break;
                            case "cancel":
                                this.dialogRef.close({ mode: "cancel" });
                                break;
                        }
                        break;
                    default:
                        this.dialogRef.close(params);
                }
            }
        } catch (e) { }
    }
}
