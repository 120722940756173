import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppService, Notification } from '../../app.service';
import { NotificationsService } from '../../_core/notifications.service';

@Component({
    selector: 'notifications-widget-opener',
    templateUrl: './widget-opener.component.html',
    styleUrls: ['./widget-opener.component.scss']
})
export class WidgetOpenerComponent implements OnInit {
    @Input() isBright: boolean = false;

    public notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

    constructor(
        private appService: AppService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit() {
        this.appService.notifications.subscribe(this.notifications);
    }

    setWidgetIsOpened() {
        this.notificationsService.setIsOpened(!this.notificationsService.checkWidgetState()); // True / False
    }
}
