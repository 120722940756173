import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-accessibility-dialog',
  templateUrl: './accessibility-dialog.component.html',
  styleUrls: ['./accessibility-dialog.component.scss']
})

export class AccessibilityDialogComponent {
    public declarationURL: string;

    // dialog passed data-scope
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appService: AppService,
    ) { }

}
