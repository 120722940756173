<div class="site-details-head">
    <web-seo-header [breadcrumbsHomeLink]="['/']" [breadcrumbsCurrentLink]="['/site/' + site?.seo[appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]" [breadcrumbsCurrentTitle]="site?.name"></web-seo-header>

    <div class="head-wrapper">
        <div
            tabindex="0"
            class="site-large-image"
            role="image"
            [title]="titleService.getTitle()"
            [style.background-image]="largeImage | safeStyle"
            [attr.aria-label]="'ACCESS.site-main-image' | translate"
        ></div>
        <site-title-desc *ngIf="site" [site]="site"></site-title-desc>
    </div>
</div>

<div class="site-details-page" *ngIf="site">
    <div class="page-wrapper">
        <div class="site-actions-and-location">
            <!-- <div *ngIf="site?.bookingData?.intro_localized[appService.localeId]" class="site-promo">
                {{site.bookingData.intro_localized[appService.localeId]}}
            </div> -->

            <site-actions [attr.aria-label]="'ACCESS.site_service' | translate" *ngIf="site" [site]="site" [disablePay]="true"></site-actions>
        </div>
        <div class="site-links-and-map">

            <site-links [attr.aria-label]="'ACCESS.contact-details' | translate" [attr.aria-details]="'ACCESS.contact-details' | translate" [site]="site"></site-links>

            <!-- 2020-06-08: Decided to remove due to Google costs considerations -->
            <!--
            <div *ngIf="site.location">
                <google-map
                    [center]="{ 'lat': 24, 'lng': 12 }"[latitude]="site.location.lat"
                    [longitude]="site.location.lng"
                    [zoom]="12.9"
                    zoomControl="true"
                    rotateControl="true"
                    mapTypeControl="true"
                    gestureHandling="cooperative"
                    streetViewControl="true"
                >
                    <map-marker
                        [center]="{ 'lat': 24, 'lng': 12 }"[latitude]="site.location.lat"
                        [longitude]="site.location.lng"
                        [iconUrl]="appService.images.marker_simple"
                        [animation]="'DROP'"
                    ></map-marker>
                </google-map>
            </div>
            -->
        </div>

        <site-info-tabs
            [site]="site"
            [sitePhotos]="sitePhotos"
            (photoClicked)="openGallery($event)"
            *ngIf="sitePhotos?.length ||
                site?.menus?.length ||
                site?.siteDetailsPage?.about ||
                site?.siteDetailsPage?.videoLink ||
                ((site?.googlePlaceDetails?.reviews?.length || tabitReviews.length) && (appService.googlePlaceDetailsSubject | async).showReviews)"
        ></site-info-tabs>

    </div>
</div>

<!-- The ngIf here is required in order to prevent a "flicker" when the page loads -->
<web-footer *ngIf="site"></web-footer>
