<div class="module-bar wl-app-primary-background-color" [class.active]="showFilters === 'open'">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="navigateBack()">
            <mat-icon>{{backIcon}}</mat-icon>
        </button>
        <div class="title flex-grow-1 title-nowrap">
            {{title | translate}}
        </div>
        <button *ngIf="!appService.skin" class="search mr-1" mat-icon-button (click)="toggleFiltersShelf()">
            <mat-icon class="search" svgIcon="filter"></mat-icon>
        </button>
        <button mat-icon-button (click)="navigateToMap()">
            <mat-icon class="map-icon" svgIcon="map view"></mat-icon>
        </button>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<div id="sites-drop-filters" class="drop-filters" [@slideFilters]="showFilters">
    <div>
        <!-- Ratings -->
        <div *ngIf="(filterFields | async).rating" class="ratings">
            <div class="filters-divider"></div>
            <div class="filters-title d-flex justify-content-center">{{'ratings' | translate}}</div>
                <div class="flex-container d-flex justify-content-center" margin="50px">
                    <div class="div-divider" @clearRankAnimation *ngIf="chooseRating">
                        <mat-chip selectable="true" color="accent" [removable]="true" (click)="clearRating()">
                            {{'clear' | translate}}
                            <mat-icon class="mat-chip-remove" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                        </mat-chip>
                    </div>
                    <ng-container *ngFor="let star of stars">
                        <star-rating [starId]="star" [rating]="rating" (click)="updateStars(star)"></star-rating>
                    </ng-container>
                </div>
        </div>

        <!-- Price Levels -->
        <div *ngIf="(filterFields | async).price" class="prices">
            <div class="filters-divider"></div>
            <div class="filters-title d-flex justify-content-center">{{'price' | translate}}</div>
            <div class="filters-container d-flex justify-content-center">
                <mat-chip-list>
                    <mat-chip
                        *ngFor="let price of prices"
                        selectable="true"
                        color="accent"
                        [selected]="selectedPrice > 0 && selectedPrice == price.id"
                        (click)="priceToggle(price.id)"
                    >{{price.value}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <!-- Only Tabit -->
        <div *ngIf="(filterFields | async).onlyTabit" class="tabit">
            <div class="filters-divider"></div>
            <div class="filters-title d-flex justify-content-center">{{'restaurant_type' | translate}}</div>
            <div class="filters-container d-flex justify-content-center">
                <mat-chip
                    selectable="true"
                    color="accent"
                    [selected]="onlyTabit"
                    (click)="tabitToggle(true)"
                >{{'tabit_restaurants' | translate}}
                </mat-chip>
            </div>
        </div>

        <!-- Availability -->
        <div *ngIf="(filterFields | async).availability" class="tabit">
            <div class="filters-divider"></div>
            <div class="filters-title d-flex justify-content-center">{{'availability' | translate}}</div>
            <div class="filters-container d-flex justify-content-center">
                <mat-chip
                    selectable="true"
                    color="accent"
                    [selected]="onlyAvailable"
                    (click)="availabilityToggle(true)"
                >{{'only_available' | translate}}
                </mat-chip>
            </div>
        </div>

        <!-- Areas -->
        <div *ngIf="(filterFields | async).area" class="areas">
            <div class="filters-divider"></div>
            <div class="filters-title d-flex justify-content-center">{{'areas_search_title' | translate}}</div>
            <div class="filters-container areas">
                <mat-chip-list>
                    <mat-chip
                        *ngFor="let area of mapAreas"
                        selectable="true"
                        color="accent"
                        [selected]="selectedArea && selectedArea.key == area.key"
                        (click)="areaToggle(area)"
                    >{{('areas.' + area.key) | translate}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <!-- Tags -->
        <div *ngIf="(filterFields | async).tags" class="tags">
            <div *ngFor="let group of groupedTags">
                <div class="filters-divider"></div>
                <div class="filters-title d-flex justify-content-center">{{group.name | translate}}</div>
                <div class="filters-container">
                    <mat-chip-list [multiple]="true">
                        <mat-chip
                            *ngFor="let tag of group.tagsArray"
                            selectable="true"
                            color="accent"
                            [selected]="selectedTags.indexOf(tag._id) >= 0"
                            (click)="tagToggle(tag._id)"
                        >{{tag.lang[appService.appConfig.locale.toLocaleLowerCase()].label}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="fixed-search wl-app-primary-background-color"
    [class.without-animation]="organizationsService.searchScreenNeedsScroll"
    [class.up]="scrollDirection == 'up' && !scrollIsReachingBottom"
    [class.down]="scrollDirection == 'down' && !scrollIsReachingTop"
>
    <div class="input-group input-group-search wl-app-secondary-background-color">
        <div class="input-group-prepend" *ngIf="searchQuery">
            <button class="btn btn-default" type="button" (click)="clearSearchText()">
                <mat-icon class="no-margins">close</mat-icon>
            </button>
        </div>
        <input type="text" class="form-control" [(ngModel)]="searchQuery" (input)="searchQuerySubject.next('')" maxlength="100" [placeholder]="'restaurants_search' | translate">
        <div class="input-group-append">
            <span class="input-group-text">
                <mat-icon>search</mat-icon>
            </span>
        </div>
    </div>

    <!-- <location-widget [houseRequired]="type == 'order' ? true : false"></location-widget> -->
    <!-- Decided to not use the houseRequired logic for now and return premise addresses also -->
    <location-widget [houseRequired]="false"></location-widget>

    <ng-content select="[isAddress]"></ng-content>

</div>

<span class="fab-fixed-bottom animated fadeIn" *ngIf="showScrollUp">
    <button mat-fab color="primary" (click)="scrollToTop('#sites-module-content')">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</span>

<div id="sites-module-content" class="module-content wl-app-primary-background-color" ngxDetectScroll (onScroll)="handleScroll($event)" [bottomOffset]="200" [topOffset]="200">
    <div class="search-spacer"></div>
    <div [@padList]="showFilters" class="wl-app-primary-background-color">

        <div class="filters-summary d-flex justify-content-center align-items-center" *ngIf="(selectedTags.length || chooseRating || selectedPrice || extendedDeliveryResults || onlyTabit || onlyAvailable) && showFilters === 'close'">
            <mat-chip-list class="center-chips" [multiple]="true">

                <!-- Selected query tags -->
                <mat-chip
                    *ngFor="let tagId of selectedTags"
                    selectable="true"
                    color="accent"
                    selected="true"
                    [removable]="true"
                    (click)="tagToggle(tagId)"
                > 
                    {{tagLabelsMap[tagId]}}
                    <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons mat-icon-no-color" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Selected query rating -->
                <mat-chip class="justify-content-center align-items-center"
                    *ngIf="chooseRating || selectedRating > 0"
                    selectable="true"
                    color="accent"
                    selected="false"
                    [removable]="true"
                    (click)="clearRating()"
                > 
                    <ng-container *ngFor="let star of stars">
                        <star-rating [starId]="star" [rating]="rating" class="small"></star-rating>
                    </ng-container>
                    <mat-icon class="mat-chip-remove" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Selected query price Levels -->
                <mat-chip
                    *ngIf="selectedPrice"
                    selectable="true"
                    color="accent"
                    selected="true"
                    [removable]="true"
                    (click)="priceToggle(selectedPrice)"
                >
                    {{appService.currency.repeat(selectedPrice)}}
                    <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons mat-icon-no-color" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Selected Only Tabit -->
                <mat-chip
                    *ngIf="onlyTabit"
                    selectable="true"
                    color="accent"
                    selected="true"
                    [removable]="true"
                    (click)="tabitToggle(false)"
                >
                    {{'tabit_restaurants' | translate}}
                    <mat-icon class="mat-chip-remove" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Selected Available Only -->
                <mat-chip
                    *ngIf="onlyAvailable"
                    selectable="true"
                    color="accent"
                    selected="true"
                    [removable]="true"
                    (click)="availabilityToggle(false)"
                >
                    {{'only_available' | translate}}
                    <mat-icon class="mat-chip-remove" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Extended results button pressed -->
                <mat-chip
                    *ngIf="extendedDeliveryResults"
                    selectable="true"
                    color="accent"
                    selected="true"
                    [removable]="true"
                    (click)="clearExtendedResults()"
                >
                    {{'extended_results' | translate}}
                    <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons mat-icon-no-color" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>

                <!-- Selected query area. Commented out since replaced by location widget. Don't delete, there is a prophecy... -->
                <!--
                <mat-chip
                    *ngIf="selectedArea"
                    selectable="true"
                    color="primary"
                    selected="true"
                    [removable]="true"
                    (click)="areaToggle(selectedArea)"
                >
                    {{('areas.' + selectedArea.key) | translate}}
                    <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons mat-icon-no-color" matchipremove="" role="img" aria-hidden="true">cancel</mat-icon>
                </mat-chip>
                -->

            </mat-chip-list>
        </div>

        <ng-content></ng-content>

    </div>
</div>
