
<block-ui>
	<div class="module-bar wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="goBack()">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate">{{reffSite?.name}}</span>
            <notifications-widget-opener></notifications-widget-opener>
            <button mat-button color="accent" (click)="close()">
                {{'Cancel' | translate}}
            </button>
        </div>
	</div>

	<div class="module-content  d-flex flex-column" *ngIf="!loading">
		<!--<div class="_header">
            <div>{{reffSite.name}}</div>
		</div>-->
		<!--AMOUNT STEP-->
		<mat-tab-group class="wiz-tabs full-height hide-headers" [(selectedIndex)]="step">
			<mat-tab label="Step 1">
				<div class="d-flex flex-column h-100">

					<div class="_step-desc">
						{{'input_pay_amount' | translate}}
					</div>
					<div class="flex-grow-1 text-center">
                        <div class="key-pad">
                            <div class="_value">
                                <div>&nbsp;{{site.amountText || '0.00'}}&nbsp;</div>
                                <div class="_currency">{{appService.currency}}</div>
                            </div>
                            <div class="row keys">
                                <div class="col-4 btn-keypad" *ngFor="let key of keys">
                                    <button color="primary" mat-fab (click)="setAmaountKey(key)">
                                        <ng-container *ngIf="key != 'back'">{{key}}</ng-container>
                                        <ng-container *ngIf="key == 'back'"><mat-icon>backspace</mat-icon></ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>
					</div>
                    <div class="p-3 pb-4">
                        <button mat-flat-button color="accent" class="lg rounded btn-block" type="button"  (click)="goToStep(1)">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{'continue' | translate}}</span>
                                <span *ngIf="site.amount" class="text-base">{{site.amount | lcurrency}}</span>
                                <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
                            </div>
                        </button>
                    </div>
				</div>
			</mat-tab>
			<!--PM STEP-->
			<mat-tab label="Step 2">
				<div class="d-flex flex-column h-100">

					<div class="flex-grow-1 text-near text-lg">
						<div class="px-3">

                            <div class="my-panel">
                                <div class="my-panel-header">{{'choose_payment' | translate}}</div>
                                <mat-nav-list class="pm-list no-margins">
                                    <a mat-list-item *ngFor="let payment of pms" (click)="setPM(payment)" class="pm-row" [class.selected]="site.pm == payment">
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <!--<mat-icon class="base mr-3 _highlight">{{site.pm == payment ? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>-->
                                            <mat-icon class="mr-3 lg">credit_card</mat-icon>
                                            <div class="_title flex-grow-1">{{'credit_no' | translate}}{{payment.displayPan}}</div>
                                            <strong class="text-xs">{{payment.comments}}</strong>
                                        </div>
                                    </a>
                                </mat-nav-list>
                            </div>

						</div>
					</div>
                    <div class="p-3 pb-4">

                        <button mat-flat-button color="accent" class="lg rounded btn-block" type="button" (click)="goToStep(2)" [class.loading]="loadingStep">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{'continue' | translate}}</span>
                                <span *ngIf="site.amount" class="text-base">{{site.amount | lcurrency}}</span>
                                <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
                            </div>
                        </button>

                    </div>
				</div>
			</mat-tab>
			<!--CODE STEP-->
			<mat-tab label="Step 3">
                <div class="d-flex flex-column h-100">

                    <!--<div class="_step-summary">
                        <span class="inline">{{site.amount | lcurrency}}</span>
                        <span class="inline px-2">|</span>
                        <span class="inline">{{'credit_no' | translate}}: {{site.pm.displayPan}}</span>
                    </div>-->

                    <ng-lottie [options]="{path: paybackAnimation, autoplay: true, loop: true}" [height]="300" *ngIf="step == 2"></ng-lottie>
                    <div class="flex-grow-1 transCode" style="position:relative; top:-50px" (click)="onSuccessfullPayment()">
                        <div class="_cap">{{'show_code_to_staff' | translate}}</div>
                        <div class="_val">{{site.paymentIntentCode}}</div>
                        <div class="_cap">
                            <div>{{'code_valid_time_1' | translate}}</div>
                            <div>{{'code_valid_time_2' | translate}}</div>
                        </div>
                    </div>
                </div>
			</mat-tab>
			<!--FINISH STEP-->
			<mat-tab label="Step 3">
				<div class="d-flex flex-column h-100 _end">
					<div class="_step-summary">
						<span class="inline">{{'paid' | translate}}</span>&nbsp;
						<span class="inline">{{site.amount | lcurrency}}</span>
						<span class="inline px-1"></span>
						<span class="inline">{{'credit_no' | translate}}: {{site.pm.displayPan}}</span>
					</div>
					<div class="flex-grow-1">
						<mat-icon class="check-icon xxl">check</mat-icon>
					</div>
					<div class="d-flex p-2">
						<button mat-icon-button>
							<mat-icon aria-label="facebook" svgIcon="facebook"></mat-icon>
						</button>
						<button mat-icon-button>
							<mat-icon aria-label="google_plus" svgIcon="google_plus"></mat-icon>
						</button>
						<button mat-icon-button>
							<mat-icon aria-label="linkedin" svgIcon="linkedin"></mat-icon>
						</button>
						<div class="flex-grow-1"></div>
						<button mat-button routerLink="/home">
							<mat-icon aria-label="Home" svgIcon="home" class="mr-1"></mat-icon>
							{{'to_main_page' | translate}}
						</button>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</block-ui>