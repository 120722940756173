<div class="web-article-container">
    <div class="article-header">
        <div class="article-header-content">
            <div class="article-header-upper-content">
                <div class="article-title">
                    {{article.header.title}}
                </div>

                <div class="article-subtitle" [innerHTML]="article.header.subTitle"></div>

                <div class="article-header-bottom-text">
                    {{article.header.bottomText}}
                </div>
            </div>
        </div>
    </div>

    <div class="article-main-content">

        <div class="article-header-photo-container">
            <div class="article-header-photo" [ngStyle]="{'background': 'url(' + article.header.photoUrl + ') no-repeat center center'}" alt="">
            </div>
        </div>

        <div class="article-header-photo-text" *ngIf="article.header.photoText">
            {{article.header.photoText}}
        </div>
        <div class="article-main-content-body">

            <ng-container *ngIf="article.id == 'מסעדות-חורף'">
                <a class="title" href="" (click)="externalRouting('/site/58c7afbf0fd96f220086e881')">
                    <h4>בראסרי, תל אביב</h4>
                </a>
                <p>לבראסרי תמיד כיף להגיע. משהו באווירה פה גורם לנו להרגיש כאילו קפצנו לפריז, והתפריט גם הוא קורץ ברובו לצרפת. אבל הסיבה העיקרית להגיע לכאן היא מרק העוף שלהם, שהוא התרופה המושלמת לכל דיכאון חורף. זה מתחיל ממרק עוף צח מאוד ומרוכז מאוד, עם טעמים עמוקים ומנחמים, וממשיך לאטריות המיוחדות שמכינים כאן, שמתובלות בטרגון והן קצת עבות, אבל טעימות בטירוף.</p>
                <a href="" (click)="externalRouting('/site/58c7afbf0fd96f220086e881')">הזמנת מקום בבראסרי &rsaquo;</a>
                <img (click)="externalRouting('/site/58c7afbf0fd96f220086e881')" src="https://ros-prd.s3.amazonaws.com/organization/icons/becf6ba84b977dcb07ecbae388b53e8a" />

                <a class="title" href="" (click)="externalRouting('/site/5a5e1b32f72aec29004b7d28')">
                    <h4>קלארו, תל אביב</h4>
                </a>
                <p>יש משהו כיפי בהגעה למסעדת קלארו ביום חורפי במיוחד. גם בזכות היופי של הבניין המשוחזר שהיא שוכנת בו, שבימי גשם נראה עוד יותר יפה מהרגיל, וגם כי כיף לשבת בחלל התחתון שלה, מתחת לאדמה, כשבחוץ הגשם שוטף הכל. סוג של מערת קסמים מודרנית, מעוצבת וטעימה במיוחד. אפשר לאכול פה בצהריים ובערב, אבל הכי כיף בחורף זה להתפנק פה עם בראנץ' מוגזם, שכולל מנות כמו בראנץ' בורגר עם בייקון ובצל מטוגן או מגש ריקוטת עזים חמה עם ביצים רכות, תרד, ירקות קלויים ובריוש.</p>
                <a href="" (click)="externalRouting('/site/5a5e1b32f72aec29004b7d28')">הזמנת מקום בקלארו &rsaquo;</a>
                <img (click)="externalRouting('/site/5a5e1b32f72aec29004b7d28')" src="https://scontent.fsdv2-1.fna.fbcdn.net/v/t1.0-9/41911029_1698633400262992_8903831447899471872_o.jpg?_nc_cat=102&_nc_oc=AQkcS3L4_--zxNr44pcy8r41l9LSXRQOcsiQW4EzBCRvm8l-Li38bm2FidfyOyR4cSU&_nc_ht=scontent.fsdv2-1.fna&oh=e68f68dff912cff2cb219f1fffd47171&oe=5E95E83A" />

                <a class="title" href="" (click)="externalRouting('/site/5bc2e85fe290560100342b58')">
                    <h4>L28, תל אביב</h4>
                </a>
                <p>במסעדת L28 מחליפים שף בכל חצי שנה, כדי לתת במה לשפים צעירים. בחודשים הקרובים מופקדת שם על המטבח השפית נאיפה מולא, שמכינה אוכל דרוזי נהדר שמשלב בין הישן לחדש, והוא אוכל מושלם לחורף, עם כל התבלינים ובשר הטלה. אל תוותרו על הקובה נייא שלה, שמגיעה גם עם תבשיל טלה חם ומלא בצל מטוגן או על תבשיל השישברק שלה, שפה הופך לסוג של מרק בסגנון בוייאבז, עם כיסונים עדינים וקלמרי פריך ומטוגן. מנת חורף מושלמת. חוץ מהאוכל, כיף גם לשבת כאן בימים גשומים במיוחד, כי המסעדה שקופה כולה.</p>
                <a href="" (click)="externalRouting('/site/5bc2e85fe290560100342b58')">הזמנת מקום ב-L28 &rsaquo;</a>
                <img (click)="externalRouting('/site/5bc2e85fe290560100342b58')" src="https://ros-prd.s3.amazonaws.com/organization/icons/775d09720e877ec11b0b00455b381cc2" />

                <a class="title" href="" (click)="externalRouting('/site/5c6d711fadf892412bb4f901')">
                    <h4>מנטנטן, תל אביב</h4>
                </a>
                <p>מרק הראמן היפני הוא גם ארוחה מלאה וטעימה אבל גם מרק נהדר, שמחמם גם את הגוף וגם את הנפש. במנטנטן מכינים את הראמן שהוא לא רק הכי טעים שטעמנו בארץ, אלא גם זה שהוא הכי חורפי, סמיך ומלא טעם. הציר פה מתבשל במשך שעות ארוכות והוא מרוכז וסמיך, האטריות נהדרות, ויש על השולחן את כל התוספות שצריך. אל תוותרו על קצת שמן צ'ילי, על שומשום מגורד ועל קצת שום טרי.</p>
                <a href="" (click)="externalRouting('/site/5c6d711fadf892412bb4f901')">הזמנת מקום במנטנטן &rsaquo;</a>
                <img (click)="externalRouting('/site/5c6d711fadf892412bb4f901')" src="https://ros-prd.s3.amazonaws.com/organization/icons/f57b63009b8d0371723008a255b9f460" />

                <a class="title" href="" (click)="externalRouting('/site/5ac080363df40912000b0e03')">
                    <h4>נישי, נתניה</h4>
                </a>
                <p>נכון שאוכל אסייתי הוא לא הדבר הראשון שעולה לראש כשחושבים על אוכל אסייתי, אבל בנישי מכינים מנת אגאדשי טופו ממכרת שהיא גם סופר טעימה וגם מספיק חמימה ומנחמת כדי להחשב כחורפית. קוביות קטנות של טופו מטוגן, פריכות וכיפיות, בקערה שמכילה ספק רוטב ספק מרק, שמבוסס על ציר דאשי יפני עם סויה ופטריות נמקו. זה חם, פריך, ופשוט אי אפשר להפסיק לאכול, תוך כדי שהרוטב מחמם את הבטן.</p>
                <a href="" (click)="externalRouting('/site/5ac080363df40912000b0e03')">הזמנת מקום בנישי &rsaquo;</a>
                <img (click)="externalRouting('/site/5ac080363df40912000b0e03')" src="https://ros-prd.s3.amazonaws.com/organization/icons/bb53120a77b79c99ac2b1e9b5ec68ed3.jpg" />

                <a class="title" href="" (click)="externalRouting('/site/578634ff96dd42230049ebfa')">
                    <h4>הלנה בנמל, קיסריה</h4>
                </a>
                <p>כבר אמרנו שנוף לים בחורף זה כיף גדול, ובהלנה בנמל יש גם נוף של המון ים וגם של עתיקות נמל קיסריה היפיפה, שיגרום לכם להרגיש כמו תיירים שמבקרים באי יווני מבודד. האוכל פה נהדר גם הוא, עם הרבה פירות ים ודגים, וכשכמעט מכל מושב במסעדה אפשר לראות את הים, קשה לחשוב על מקום טוב יותר להעביר בו ערב חורפי וגשום.</p>
                <a href="" (click)="externalRouting('/site/578634ff96dd42230049ebfa')">הזמנת מקום בהלנה בנמל &rsaquo;</a>
                <img (click)="externalRouting('/site/578634ff96dd42230049ebfa')" src="https://ros-prd.s3.amazonaws.com/organization/icons/34534f522409e6d32ac34c6bc6b209cd" />

                <a class="title" href="" (click)="externalRouting('/site/5a37e0799a4f893800575329')">
                    <h4>קיטשן מרקט, תל אביב</h4>
                </a>
                <p>הים שלנו יפה במיוחד בחורף, שהוא זועף בכחול כהה עם גלים גבוהים. אבל סתם לשבת בים זו לא ממש אופציה בחורף, אז הדבר הכי קרוב הוא לשבת בקיטשן מרקט, ששוכנת בקומה השניה של שוק הנמל, עם נוף מרהיב לים. יושבים בחלל הממוזג, אוכלים מול הים, ונהנים ממנו בלי כל הרטוב והקר. בשביל להנות ממנו כמו שצריך, הכי כדאי להגיע לכאן לעסקית בצהריים (יש גם עסקיות בסופי שבוע, אבל קחו בחשבון שהן יקרות יותר), כשעדיין יש אור בחוץ, ולהנות גם מהאוכל וגם מהים.</p>
                <a href="" (click)="externalRouting('/site/5a37e0799a4f893800575329')">הזמנת מקום בקיטשן מרקט &rsaquo;</a>
                <img (click)="externalRouting('/site/5a37e0799a4f893800575329')" src="https://ros-prd.s3.amazonaws.com/organization/icons/fe71eecb34858194141ceeb27d2b796f" />
            </ng-container>

            <ng-container *ngIf="article.id == 'איפה-לחגוג-את-הוולנטיין'">
                <a class="title" href="" (click)="externalRouting('/site/58f5c16f26974222004dc413')">
                    <h4>POMO, תל אביב</h4>
                </a>
                <p>במסעדת Pomo האיטלקית יחגגו את הוולנטיין דיי החל מיום חמישי בערב ועד יום שישי. בשעות הערב יוגשו מבחר מנות ספיישל לחג האוהבים ויוגשו לצד המנות הקבועות של המסעדה.  בין המנות: טונה עם תפוזי דם (74 ₪), ברוסקטה כבד אווז (82 ש"ח), קוקי סאן ז'ק וקרם ארטישוק (78 ש"ח) . חוץ מארוחת הערב, בשישי בבוקר יוגש הבראנץ' שהוא אופציה רומנטית וכיפית לא פחות. בין המנות בבראנץ': בוקנצ'יני תרד (62 ש"ח), אגז בנדיקט (68 ש"ח), מאפה פלורנטין (82 ש"ח), בייגל רוסט (59 ש"ח) ובגזרה המתוקה: פרנץ' טוסט שמוגש עם תותים ומסקרפונה (54 ש"ח). הבראנץ' יוגש בין השעות 12:00-09:00. מינימום הזמנה לאדם בשעות הערב: 150 ₪.</p>
                <a href="" (click)="externalRouting('/site/58f5c16f26974222004dc413')">להזמנת מקום ב-POMO &rsaquo;</a>
                <img (click)="externalRouting('/site/58f5c16f26974222004dc413')" [src]="appService.base('assets/images/articles/valentines/pomo.jpg')" alt="וולנטיין בפומו - קרדיט אפיק גבאי"/>

                <a class="title" href="" (click)="externalRouting('/site/5dcbd55f36c2e72f6f8e6962')">
                    <h4>רשת BBB</h4>
                </a>
                <p>ש מי שאומרים שארוחת המבורגר מושחתת ועסיסית היא הדבר הכי רומנטי שזוג יכול לעשות ולכן רשת מסעדות ההמבורגר BBB, משיקה שני קינוחים מתוקים ומפתים במיוחד שמשלבים שוקולד ו-וניל, מרכיבים הידועים כמעוררי תשוקה:פרופיטרולים – פרופיטרולים במילוי קרם פטיסייר, מוגשים עם גלידה בטעם וניל, קרמבל דקואז ורוטב קרמל (42 ש"ח). פונדנט ענק – עוגת שוקולד ענקית המוגשת עם שלושה כדורי גלידה בטעם וניל, קרמבל דקואז ורוטב שוקולד (72 ש"ח).</p>
                <a href="" (click)="externalRouting('/site/5dcbd55f36c2e72f6f8e6962')">להזמנת מקום בסניפי BBB &rsaquo;</a>
                <img (click)="externalRouting('/site/5dcbd55f36c2e72f6f8e6962')" [src]="appService.base('assets/images/articles/valentines/BBB.jpg')" alt="BBB - פרופיטרולים. צילום גליה אבירם"/>

                <a class="title" href="" (click)="externalRouting('/site/5778c376215a111e00375d4d')">
                    <h4>גרינברג ביסטרו</h4>
                </a>
                <p>לכבוד יום האהבה מציג יונתן רושפלד, השף של גרינברג ביסטרו תפריט שכולו מנות מיוחדות ורומנטיות. בין המנות: סשימי הרוצח – פרוסות המאצ'י וסויה סלק (68 ש"ח), ניוקי תפוחי אדמה ברוטב כמהין, אספרגוס בגריל וביצה עלומה (89 ש"ח ), פפר סטייק – מח עצם, פסטה צמות, אפונת שלג ורוטב בצל מעושן (186 ש"ח), פבלובה מפורקת- מרנג תות, גלידת תות שמנת, קולי תות חמצמץ, קרם מסקרפונה ופירות אדומים טריים (44 ש"ח) ועוד.</p>
                <a href="" (click)="externalRouting('/site/5778c376215a111e00375d4d')">להזמנת מקום בגרינברג ביסטרו &rsaquo;</a>
                <img (click)="externalRouting('/site/5778c376215a111e00375d4d')" [src]="appService.base('assets/images/articles/valentines/grinberg.jpg')" alt="גרינברג"/>

                <a class="title" href="" (click)="externalRouting('/site/5778c376215a111e00375d4d')">
                    <h4>רוזה פארקס</h4>
                </a>
                <p>בבר הטרנדי רוזה פארקס יודעים שלא כולם רוצים ארוחה גדולה כדי לחגוג, ומציעים דיל זוגי במחיר משתלם, שמי שרוצה יוכל להוסיף לו מנות נוספות.  במחיר של 180 ש"ח תקבלו בקבוק יין לבחירה, מנת שיירינג, שני צ'ייסרים וקינוח כיסוני נוטלה. ובתכלס מי צריך יותר מאלכוהול ונשנוש ליד בשביל ערב רומנטי?</p>
                <a href="" (click)="externalRouting('/site/5778c376215a111e00375d4d')">להזמנת מקום ברוזה פארקס &rsaquo;</a>
                <img (click)="externalRouting('/site/5778c376215a111e00375d4d')" [src]="appService.base('assets/images/articles/valentines/roza.jpg')" alt="רוזה פארקס"/>

                <a class="title" href="" (click)="externalRouting('/site/59dc6f96e3cacd23009d696c')">
                    <h4>מקס ברנר</h4>
                </a>
                <p>אין חיבור יותר מוצלח מאשר רומנטיקה ושוקולד, ולכן ברשת מקס ברנר מציעים שתי סוגי ארוחות זוגיות, למי שמחפש ארוחה מלאה ולמי שרוצים רק מתוקים. לזוגות שיגיעו רעבים מציעים ברשת ארוחה זוגית שכוללת שתי מנות עיקריות מתפריט האוכל של המסעדה, ואז קינוח זוגי מיוחד לבחירה מתוך מבחר שכולל פונדו שוקולד ענק עם מחבר תוספות או חגיגה של שלושה וופלים בלגיים עם תוספות או בלאגן שוקולד זוגי שכולל אמבט שוקולד חם והמון תוספות מתוקות או מנת טעימות זוגית שכוללת טעימות מהמון קינוחים או שלישיית סופלה שוקולד בטעמים בואנו, לוטוס ואגוזים או מנת טעימות של פנקייקים, תותים, בראוניז ועוד קינוחי תותים. יחד עם 2 כוסות קאווה או יין ומתנה מחנות השוקולד הארוחה תעלה 229 שקלים. מי שירצה רק את הקינוח יוכל לקבל אותו יחד עם שתי כוסות קאווה או יין ב-129 שקלים.</p>
                <a href="" (click)="externalRouting('/site/59dc6f96e3cacd23009d696c')">להזמנת מקום בסניפי מקס ברנר &rsaquo;</a>
                <img (click)="externalRouting('/site/59dc6f96e3cacd23009d696c')" [src]="appService.base('assets/images/articles/valentines/max-brenner.jpg')" alt="מקס ברנר"/>
            </ng-container>

            <ng-container *ngIf="article.id == 'המקומות-הכי-טעימים-בשוק-הכרמל'">
                <p>בשנים האחרונות הפך שוק הכרמל לאחד האיזורים הכי שוקקים בעיר מבחינה קולינרית. המוני מסעדות ודוכני אוכל חדשים נפתחים שם כמעט בכל שבוע, וכל מי שמבקר בשוק מכיר את העומס העצום של האפשרויות, שאפשר בקלות להתבלבל בו. אספנו את המקומות שאנחנו הכי אוהבים לאכול בהם בשוק, כדי שתוכלו ליצור לעצמם מעין סיור טעימות בפעם הבאה שתבקרו בו.</p>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/5dcbcf0336c2e72f6f8e652a')">
                    <h4>M25</h4>
                </a>
                <p>מסעדת הבשר של אנשי קצביית המיט מרקט והשף יונתן בורוביץ' היא אחד המקומות העסוקים והעמוסים בשוק. אפשר לשבת בו לנשנוש כיפי או לארוחת סטייקים מפנקת, והכל באווירת שוק קלילה ומחירים משתלמים, לפחות ביחס למסעדות בשר אחרות בעיר. העראייס שמגישים פה הוא אחד הטובים בארץ, וגם סלט העגבניות שמוגש לצידו, ואפשר למצוא פה גם מנות אחרות וטעימות לא פחות, חלקן עם חלקי בשר פחות מוכרים.</p>
                <a href="" (click)="externalRouting('/site/5dcbcf0336c2e72f6f8e652a')">M25, הכרמל 30, תל אביב</a>
                <img (click)="externalRouting('/site/5dcbcf0336c2e72f6f8e652a')" [src]="appService.base('assets/images/articles/hacarmel/m25-2.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/5dcbcf9336c2e72f6f8e6589')">
                    <h4>כרמל</h4>
                </a>
                <p>לא חסרים דוכני פיתות טעימים בעיר, אבל כרמל, של אנשי "דה באן" לשעבר, הוא אחד הטובים בהם. הבשר פה נצלה על גריל פחמים, יש מבחר סלטים גדול וטרי המאפשר לכל אחד לשים בפיתה רק מה שהוא אוהב. גם טבעונים יהנו פה, ממעורב טבעוני מצוין שמוגש בפיתה, וגם אוכלי בשר יהנו ממנו.</p>
                <a href="" (click)="externalRouting('/site/5dcbcf9336c2e72f6f8e6589')">כרמל, הלל הזקן 18, תל אביב</a>
                <img (click)="externalRouting('/site/5dcbcf9336c2e72f6f8e6589')" [src]="appService.base('assets/images/articles/hacarmel/karmel.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/5dcbcf4d36c2e72f6f8e655b')">
                    <h4>פיתה פנדה</h4>
                </a>
                <p>דוכן הפיתות שהיה למשך תקופה קצרה פופ אפ זמני, הפך לדוכן קבוע ולא בכדי. בדוכן פה מגישים רק שלושה סוגים של פיתות: אחת עם טרטר דג וירקות, השניה עם תבשיל סלופי ג'ו והשלישית, שהיא הפופולרית ביותר, היא פיתה של "פילה חציל בענן". מעין גרסה מודרנית של סביח, עם פרוסות חציל שמנמנות, ביצה קשה, סלט, המון ירק וצ'אטני סלק מעולה, הכל בפיתה רכה שעברה אידוי והפכה באמת לסוג של ענן. טיפ שלנו: אפשר לשלב פה בין הסלופי לבין החצילים, וזו הפיתה האהובה עלינו.</p>
                <a href="" (click)="externalRouting('/site/5dcbcf4d36c2e72f6f8e655b')">פיתה פנדה. מל"ן 47, תל אביב</a>
                <img (click)="externalRouting('/site/5dcbcf4d36c2e72f6f8e655b')" [src]="appService.base('assets/images/articles/hacarmel/panda.jpg')" />

                <a class="title default-cursor">
                    <h4>ללה - בית המאפה של לילי בן שלום</h4>
                </a>
                <p>השפית לילי בן שלום (ראצ'ה) פתחה את בית המאפה שלה ממש לא מזמן. היא מגישה פה המון סוגים של מאפים גאורגיים, אותן מכינות אופות גיאורגיות, חלקן בנות משפחה. אפשר לאכול פה את כל סוגי החצ'אפורי, לצד דרינקים שווים, וגם כמה מאפים וגרסאות פחות מוכרות, כמו למשל חצ'אפורי פאנובי, שהוא סוג של מאפה פריך בטירוף עם מילויים שונים, שמזכיר בורקס משוכלל ולא שמנוני.</p>
                <a class="default-cursor">ללה. חיים חבשוש 39, תל אביב</a>
                <img [src]="appService.base('assets/images/articles/hacarmel/lala.jpg')" />

                <a class="title default-cursor">
                    <h4>הכרמל 40</h4>
                </a>
                <p>דוכן הדגים החדש יחסית הזה הצליח להפוך כמעט בין לילה לאחד המקומות האהובים בשוק, בזכות מנה אחת: כריך הדיגיים. כריך בסגנון כריכי הבאליק אקמק התורכיים, עם פילה דניס שנצרב על הפלאנצ'ה, איולי זעפרן, בצל מטוגן וירקות טריים. כמה פשוט, ככה טעים. אפשר גם לאכול פה סביצ'ה, פירות ים מטוגנים וגם לשאול מה יש מיוחד היום ולקבל פירות ים טריים על הפלאנצ'ה, למשל.</p>
                <a class="default-cursor">הכרמל 40, הכרמל 40, תל אביב</a>
                <img [src]="appService.base('assets/images/articles/hacarmel/karmel40.jpg')" />

                <a class="title default-cursor">
                    <h4>הבוריקה שוק הכרמל</h4>
                </a>
                <p>כמעט בכל שוק בארץ מוכרים איזשהו סוג של בוריק, אבל בשוק הכרמל הפכו אותו לעניין של ממש. זה מתחיל בקולו הרם של בעלי הדוכן, קובי, שנשמע לאורך חצי מהשוק, וממשיך באוכל עצמו. הבוריק הרגיל, עם תפוחי אדמה וביצה רכה, שמוגש בפיתה עם ירקות וצ'רשי מעולה, טעים בטירוף בפני עצמו. שילוב של חם וקר, פריך ורך, שקשה לעמוד בפניו. אבל אם בא לכם קצת להשתולל, אפשר להזמין גם בוריק טוניסאי עם טונה, בוריק טלה עם בשר ועוד כמה גרסאות טעימות. מטיבי לכת יכולים גם לקנח בספינג' תפוח וטרי.</p>
                <a class="default-cursor">הבוריקה שוק הכרמל. הכרמל 42, תל אביב</a>
                <img [src]="appService.base('assets/images/articles/hacarmel/burika.jpg')" />

                <a class="title default-cursor">
                    <h4>דרקסלר</h4>
                </a>
                <p>הדוכן של השף בני דרקסלר מגיש מנות בשר בסלוף, סוג של פיתה תימנית שמזכירה לאפה עבה, ומצטיינת בספיגת טעמי ומיצי הבשר. הבשר פה איכותי במיוחד, הצלייה נעשית במקצוענות, ומשהו בשילוב של הכל ביחד בתוך פיתת הסאלוף יוצר ביס טעים במיוחד. כמעט בכל יום גם יש פה מנות מיוחדות שלא רשומות בתפריט, אז שווה לשאול.</p>
                <a class="default-cursor">דרקסלר. השומר 1, תל אביב</a>
                <img [src]="appService.base('assets/images/articles/hacarmel/dreksler.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/5dcbcf7a36c2e72f6f8e6578')">
                    <h4>איסאן</h4>
                </a>
                <p>בשוק הכרמל אפשר למצוא כמה וכמה דוכנים שמוכרים אוכל תאילנדי, אבל באיסאן, שהיא יותר מסעדה מדוכן, נמצא האוכל התאילנדי הכי טעים באיזור. למרות שמדובר במסעדה, היא תזכיר לכם יותר מסעדות בתאילנד מאשר בישראל, וגם המחירים מתונים יחסית, אם כי מעט יותר גבוהים מהנהוג בשוק. התפריט פה די רחב וכולל את כל הקלאסיקות של המטבח הזה, והאוכל מלא בטעמים, חריף ובועט, בלי להתנצל.</p>
                <a href="" (click)="externalRouting('/site/5dcbcf7a36c2e72f6f8e6578')">איסאן. רבי עקיבא 22, תל אביב</a>
                <img (click)="externalRouting('/site/5dcbcf7a36c2e72f6f8e6578')" [src]="appService.base('assets/images/articles/hacarmel/asian.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/5dcbcf5636c2e72f6f8e6560')">
                    <h4>שמואל</h4>
                </a>
                <p>אחד המקומות הוותיקים בגל המסעדות החדש של השוק, בשמואל מתמחים בעיקר במנות קבב בפיתה או בצלחת. הקבב פה באמת מוצלח, עם תיבול נהדר וטעם בשרי כיפי, ואנחנו דווקא הכי אוהבים אותו בגרסה המפורקת, שמגיע עם המון בצל ומנגולד מטוגנים והופכת לסוג של תבשיל בשרי טעים. יש פה גם מעורב נהדר, ואל תפספסו את התוספת של האורז עם האטריות הממכרת.</p>
                <a href="" (click)="externalRouting('/site/5dcbcf5636c2e72f6f8e6560')">שמואל. רבי מאיר 42, תל אביב</a>
                <img (click)="externalRouting('/site/5dcbcf5636c2e72f6f8e6560')" [src]="appService.base('assets/images/articles/hacarmel/shmuel.jpg')" />

                <a class="title default-cursor">
                    <h4>ארטה</h4>
                </a>
                <p>הגלידרייה הנהדרת הזו אמנם לא נמצאת ממש על השוק, אלא על נחלת בנימין הסמוכה, אבל מדובר בקינוח הכי טעים שאפשר לאכול באיזור, אז אנחנו חייבים לכלול אותה. על הגלידה פה אחראיים זוג עולים מאיטליה, שמכינים אותה מדי יום בחדר האחורי, והיא מצוינת. יש פה כמה טעמים קבועים כמו העוגה של מרקו, שכוללת פירות יער ושברי עוגה פריכה, או למשל טעם לחם וחמאה שנשמע מוזר אבל לא תאמינו כמה הוא ממכר. יש גם טעמים עונתיים ומתחלפים, כמו למשל אפרסק ולוונדר, ובכל ביקור תתקשו לבחור טעם או שניים.</p>
                <a class="default-cursor">ארטה. נחלת בנימין 11, תל אביב</a>
                <img [src]="appService.base('assets/images/articles/hacarmel/arta.jpg')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'משלוחים-לימי-קורונה'">

                <!-- section -->

                <br>
                <div class="title section-title">
                    <h2>השרון</h2>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/נונו-הוד-השרון')">
                    <h4>נונו הוד השרון</h4>
                </a>
                <p>המסעדה האיטלקית הפופולארית זמינה למשלוחים ולטייק אווי, ואפשר להזמין את כל התפריט האיטלקי שלה שכולל פסטות, פיצות ומנות נוספות, בשביל ארוחה איטלקית אמיתית ומושקעת בבית.</p>
                <a href="" (click)="externalRouting('/site/נונו-הוד-השרון')">להזמנה מנונו הוד השרון</a>
                <img (click)="externalRouting('/site/נונו-הוד-השרון')" [src]="appService.base('assets/images/articles/corona/nono.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/סבסטיאן')">
                    <h4>סבסטיאן</h4>
                </a>
                <p>אפשר להזמין באיסוף עצמי את כל תפריט המסעדה, ולהנות בבית מהשניצל המיתולוגי, מהמבורגר מעולה, כל מנות הפסטה והבשר או לפתוח שולחן של מנות ראשונות כיפיות.</p>
                <a href="" (click)="externalRouting('/site/סבסטיאן')">להזמנה מסבסטיאן</a>
                <!-- <img (click)="externalRouting('/site/סבסטיאן')" [src]="appService.base('assets/images/articles/corona/כרמל.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/nooch')">
                    <h4>נוץ'</h4>
                </a>
                <p>אם מתחשק לכם אוכל אסייתי או משלוח של סושי מושקע באיזור השרון, שירות המשלוחים ושירותי הטייק אווי של נוץ' ממשיכים לפעול, עם כל המוקפצים והרולים שלהם.</p>
                <a href="" (click)="externalRouting('/site/nooch')">להזמנה מנוץ'</a>
                <img (click)="externalRouting('/site/nooch')" [src]="appService.base('assets/images/articles/corona/nooch.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/טנדורי--1')">
                    <h4>טנדורי</h4>
                </a>
                <p>הטיול להודו אמנם לא ממש אפשרי עכשיו, אבל מטנדורי תוכלו להזמין את כל התבשילים ההודיים האפשריים, בשריים או צמחוניים, כדי להרגיש קצת בחופש. ואל תוותרו על לחם הנאן הממכר!</p>
                <a href="" (click)="externalRouting('/site/טנדורי--1')">למשלוחים גם בהרצליה וגם בתל אביב</a>

                <a class="title" href="" (click)="externalRouting('site/ביסטרו-bp-כביש-החוף')">
                    <h4>BP נתניה כביש החוף</h4>
                </a>
                <p>הביסטרו הכשר מציע תפריט עשיר, מגוון ומנחם של בשרים ועוד הפתעות במשלוח עד הבית</p>
                <a href="" (click)="externalRouting('site/ביסטרו-bp-כביש-החוף')">להזמנה מ-BP נתניה כביש החוף</a>

                <a class="title" href="" (click)="externalRouting('site/beef-bar-נתניה')">
                    <h4>ביף בר נתניה</h4>
                </a>
                <p>יפנקו אתכם במשלוח עם בשרים מכל הסוגים - סטייקים, בורגרים ואפילו כריכים שווים. כשרים.</p>
                <a href="" (click)="externalRouting('site/beef-bar-נתניה')">להזמנה מביף בר נתניה</a>

                <!-- <img (click)="externalRouting('https://www.benedict.co.il/טנדורי--1/')" [src]="appService.base('assets/images/articles/corona/ללה.jpg')" /> -->

                <!-- <a class="title" href="https://www.greco.co.il/delivery">
                    <h4>גרקו הרצליה</h4>
                </a>
                <p>אמנם כבר בעייתי לארח חברים לערב של בילוי, אבל גם במשפחה המצומצת אפשר לחגוג עם ערב יווני כיפי, בזכות משלוח מגרקו של מנות ראשונות שמחות וגם מנות בשר ועיקריות שירימו קצת את מצב הרוח.</p>
                <a href="https://www.greco.co.il/delivery">להזמנה מגרקו הרצליה</a> -->
                <!-- <img (click)="externalRouting('https://www.greco.co.il/delivery')" [src]="appService.base('assets/images/articles/corona/כרמל 40.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/nishi')">
                    <h4>נישי</h4>
                </a>
                <p>לנישי בנתניה יש תפריט אסייתי ענק שכולל מנות כמעט מכל היבשת, החל מכיסונים שווים, דרך מנות נודלס מוקפצות, מרקים ומבחר גדול של סושי, ואת כולם אפשר עכשיו להזמין הביתה.</p>
                <a href="" (click)="externalRouting('/site/nishi')">להזמנה מנישי</a>
                <!-- <img (click)="externalRouting('/site/nishi')" [src]="appService.base('assets/images/articles/corona/borika.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/calata-15')">
                    <h4>קלאטה 15</h4>
                </a>
                <p>המסעדה האיטלקית החדשה יחסית מציעה במשלוחים אוכל איטלקי אותנטי כמו פסטות בעבודת יד ופיצות בתנורים איטלקיים, ממש כמו ביקור קצר ברומא.</p>
                <a href="" (click)="externalRouting('/site/calata-15')">להזמנה מקלאטה 15</a>
                <img (click)="externalRouting('/site/calata-15')" [src]="appService.base('assets/images/articles/corona/kalata.jpg')" />

                <!-- section -->

                <br>
                <div class="title section-title">
                    <h2>ירושלים</h2>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/crave')">
                    <h4>קרייב</h4>
                </a>
                <p>בקרייב מכינים גרסאות כשרות וטעימות במיוחד לאוכל דיינרים אמריקאי ומקסיקני, וזה בעצם האוכל המנחם המושלם להזמין הביתה בימים שכאלה, עם המבורגרים, טקואים והמון צ'יפס.</p>
                <a href="" (click)="externalRouting('/site/crave')">להזמנה מקרייב</a>
                <!-- <img (click)="externalRouting('/site/crave')" [src]="appService.base('assets/images/articles/corona/איסאן.jpg')" /> -->

                <a class="title" (click)="externalRouting('https://beerbazaar.co.il/')">
                    <h4>ביר בזאר</h4>
                </a>
                <p>לא רק אוכל צריך בבית בימים האלה, ובביר בזאר מציעים משלוחים של מארזי בירות תוצרת ישראל, שיעזרו להעביר את הזמן ואת הבעסה.</p>
                <a (click)="externalRouting('https://beerbazaar.co.il/')">להזמנה מביר בזאר</a>
                <!-- <img (click)="externalRouting('https://beerbazaar.co.il/')" [src]="appService.base('assets/images/articles/corona/שמואל.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/חצות')">
                    <h4>חצות</h4>
                </a>
                <p>אין ירושלמי שלא מכיר את המעורב המיתולוגי של סטקיית חצות, ועכשיו אפשר לקבל אותו עד הבית בפיתה, או להזמין מבחר גדול אחר של בשרים או מנות בפיתה.</p>
                <a href="" (click)="externalRouting('/site/חצות')">להזמנה מחצות</a>
                <!-- <img (click)="externalRouting('/site/%D7%97%D7%A6%D7%95%D7%AA')" [src]="appService.base('assets/images/articles/corona/ארטה.jpg')" /> -->

                <!-- section -->

                <br>
                <div class="title section-title">
                    <h2>תל אביב</h2>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/רביבה-וסיליה-יו')">
                    <h4>רביבה וסיליה יו</h4>
                </a>
                <p>את האוכל והמאפים המופלאים של רביבה וסיליה לא צריך להציג, אבל עכשיו סוף סוף אפשר גם להזמין ממנו הביתה ולהנות מארוחה מושלמת שבסופה גם קינוחים מתוקים מעולים.</p>
                <a href="" (click)="externalRouting('/site/רביבה-וסיליה-יו')">להזמנה מרביבה וסיליה יו</a>
                <img (click)="externalRouting('/site/רביבה-וסיליה-יו')" [src]="appService.base('assets/images/articles/corona/reviva.jpg')" />

                <a class="title" (click)="externalRouting('https://www.greco.co.il/delivery')">
                    <h4>גרקו</h4>
                </a>
                <p>אמנם כבר בעייתי לארח חברים לערב של בילוי, אבל גם במשפחה המצומצת אפשר לחגוג עם ערב יווני כיפי, בזכות משלוח מגרקו של מנות ראשונות שמחות וגם מנות בשר ועיקריות שירימו קצת את מצב הרוח.</p>
                <a (click)="externalRouting('https://www.greco.co.il/delivery')">להזמנה מגרקו</a>
                <!-- <img (click)="externalRouting('https://www.greco.co.il/delivery')" [src]="appService.base('assets/images/articles/corona/כרמל.jpg')" /> -->

                <a class="title" (click)="externalRouting('https://www.tabitorder.com/?siteName=coffeebar#/start')">
                    <h4>בראסרי</h4>
                </a>
                <p>התפריט הצרפתי-אירופאי הקלאסי של הבראסרי הפך אותה לאחת המסעדות הכי אהובות בתל אביב, ועכשיו אפשר להזמין ממנו הביתה לארוחה מושחתת וטעימה במיוחד.</p>
                <a (click)="externalRouting('https://www.tabitorder.com/?siteName=coffeebar#/start')">להזמנה מבראסרי</a>
                <img (click)="externalRouting('https://www.tabitorder.com/?siteName=coffeebar#/start')" [src]="appService.base('assets/images/articles/corona/braseri.jpg')" />

                <br>
                <a class="title section-title" href="" [routerLink]="appService.redirectValueByLocale('deliveries', 'ROUTE')">
                    <h3>לכל המשלוחים הזמינים סביבך &rsaquo; </h3>
                </a>
                <br>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/bodega')">
                    <h4>בודגה</h4>
                </a>
                <p>אם תמיד רציתם לדעת למה כולם מתלהבים מצ'יזבורגר או המבורגר עם בייקון אבל הכשרות עצרה אתכם, בבודגה דואגים לכם לגרסאות כשרות לגמרי של כל המאכלים האמריקאים השווים, עם גבינה טבעונית ובייקון טלה.</p>
                <a href="" (click)="externalRouting('/site/bodega')">להזמנה מבודגה</a>
                <!-- <img (click)="externalRouting('https://www.tabitorder.com/?siteName=coffeebar#/start')" [src]="appService.base('assets/images/articles/corona/braseri.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/ca-phe-hanoi')">
                    <h4>ק-פה האנוי</h4>
                </a>
                <p>אין הרבה מסעדות אסייתיות כשרות בתל אביב, אז משלוח מק-פה האנוי הויאטנמית הוא בהחלט שיחוק אמיתי שכולל גם מנות ראשונות שוות, גם מוקפצים שונים וגם מנות באן או מרקים חורפיים ומנחמים.</p>
                <a href="" (click)="externalRouting('/site/ca-phe-hanoi')">להזמנה מק-פה האנוי</a>
                <img (click)="externalRouting('/site/ca-phe-hanoi')" src="https://ros-prd.s3.amazonaws.com/organization/icons/9fe4fd2cd9d1d6731029172720382fcc" />

                <a class="title" (click)="externalRouting('/site/pizza')">
                    <h4>Pizza</h4>
                </a>
                <p>בקבוצת מסעדות גרינברג באזורי חן, ביסטרו גרינברג, גרקו בתל אביב, PIZZA והג'לטריה , התגייסו המסעדן צביקי עשת יחד עם השפים יונתן רושפלד, אליאב גולדנברג וניצן רז ומציעים פתרון משתלם במשלוח עד הבית - ניתן להזמין מהתפריט הקבוע המלא של המסעדות, או לבחור "פמילי דינר"  ארוחות משפחתיות עד הבית המיועדות למשפחות או קבוצות קטנות.</p>
                <a (click)="externalRouting('/site/pizza')">להזמנה מ-Pizza</a>
                <!-- <img (click)="externalRouting('https://www.facebook.com/america.burgers/?ref=aymt_homepage_panel')" [src]="appService.base('assets/images/articles/corona/כרמל 40.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/shishi')">
                    <h4>SHI-SHI</h4>
                </a>
                <p>shishi הם הפתרון המושלם לארוחה מפנקת בריאה וטעימה, עם רולים גדולים של אורז שמגולגלים עם נייר אורז מסביבם, והמון מילויים שווים.</p>
                <a href="" (click)="externalRouting('/site/shishi')">להזמנה מ-SHI-SHI</a>
                <img (click)="externalRouting('/site/shishi')" src="https://ros-prd.s3.amazonaws.com/organization/icons/8fac33c4051146cbb3f8dfa4982ad261" />

                <!-- <a class="title" href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>התאילנדית בהר סיני</h4>
                </a>
                <p>אם אתם חובבי אוכל תאילנדי אותנטי וחריף, המנות של התאילנדית בהר סיני יעשו אתכם מאושרים באמת, עם כל הקלאסיקות התאילנדיות וגם כמה מנות מיוחדות ופחות מוכרות.</p>
                <a href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנה מהתאילנדית בהר סיני</a>
                <img (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" src="https://ros-prd.s3.amazonaws.com/organization/icons/51776d9e8e6d5ad82f8661b962673734" /> -->

                <a class="title" href="" (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')">
                    <h4>שואורום</h4>
                </a>
                <p>אוכל זה נחמד, אבל כולנו צריכים גם קצת מתוק כדי להעביר את הזמן והקינוחים האמריקאים של השואורום יכולים להיות המשלוח המושלם להרמת מצב הרוח</p>
                <a href="" (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')">להזמנה משואורום</a>
                <img (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')" src="https://ros-prd.s3.amazonaws.com/organization/icons/035b0de18be11cb3a67aafd4b7eda4e9" />

                <!-- section -->

                <br>
                <div class="title section-title">
                    <h2>חיפה</h2>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/chang-ba-חיפה')">
                    <h4>צ'אנג בה</h4>
                </a>
                <p>התאילנדית של חיפה מציעה במשלוחים את כל מבחר המנות שלה, שכוללות תבשילי קארי, מרקים, מנות נודלס שונות וגם מנות ראשונות פיקנטיות וכיפיות.</p>
                <a href="" (click)="externalRouting('/site/chang-ba-חיפה')">להזמנה מצ'אנג בה</a>
                <img (click)="externalRouting('/site/chang-ba-חיפה')" src="https://ros-prd.s3.amazonaws.com/organization/icons/80254ea47e7c617714fc974a6e09aa28" />

                <a class="title" href="" (click)="externalRouting('/site/ליבירה')">
                    <h4>ליבירה</h4>
                </a>
                <p>הדרך הכי טובה לשחזר יציאה לבילוי היא להזמין משלוח מליבירה שכולל גם את הבירות שלהם וגם אוכל בארים כיפי כמו כריכים, המבורגרים או נקניקיות מצוינות.</p>
                <a href="" (click)="externalRouting('/site/ליבירה')">להזמנה מליבירה</a>
                <img (click)="externalRouting('/site/ליבירה')" src="https://ros-prd.s3.amazonaws.com/organization/icons/5995d9aa08dceb88164e526696ed1d40" />

                <a class="title" href="" (click)="externalRouting('site/black-לב-המפרץ')">
                    <h4>Black - לב המפרץ</h4>
                </a>
                <p>רשת ההמבורגריות הפופולרית מציעה משלוחים לא רק של ההמבורגרים המושחתים שלה אלא גם מנות נוספות של השף צחי בוקששטר, גם למי שפחות מחבב בורגרים.</p>
                <a href="" (click)="externalRouting('site/black-לב-המפרץ')">להזמנה מ-Black - לב המפרץ</a>

                <!-- <a class="title" href="https://www.eissalon.co.il/online-ordering">
                    <h4>אייססלון</h4>
                </a>
                <p>משלוחי גלידה הם דבר די גאוני, והגלידות של אייססלון, עם הטעמים המיוחדים והמרכיבים הטבעיים הם הגלידות שהכי שווה להזמין ולהתנחם איתן מול הטלוויזיה</p>
                <a href="https://www.eissalon.co.il/online-ordering">להזמנה מאייססלון</a>
                <img (click)="externalRouting('https://www.eissalon.co.il/online-ordering')" [src]="appService.base('assets/images/articles/corona/ארטה.jpg')" /> -->

                 <!-- section -->

                 <br>
                <div class="title section-title">
                    <h2>הצפון</h2>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/uma-arburger-קרית-שמונה')">
                    <h4>אומה בורגר</h4>
                </a>
                <p>גם סושי, גם נודלס וגם המבורגרים מעולים? אם אצלכם בבית קשה לבחור מה מזמינים היום, באומה תוכלו להזמין מה שבא לכם בלי להתפשר.</p>
                <a href="" (click)="externalRouting('/site/uma-arburger-קרית-שמונה')">להזמנה מאומה בורגר</a>
                <img (click)="externalRouting('/site/uma-arburger-קרית-שמונה')" src="https://ros-prd.s3.amazonaws.com/organization/icons/40bda5f7fde28b19dedb2ed0be8432eb" />

                <a class="title" href="" (click)="externalRouting('/site/מקלות-וניל')">
                    <h4>מקלות וניל</h4>
                </a>
                <p>כבר אמרנו שקינוחים ומאפים הם הדבר הכי שווה ומנחם שיש, והמאפים של מקלות וניל מצטיינים במיוחד, בעיקר מאפי השמרים שלהם, שאפשר להזמין עכשיו הביתה.</p>
                <a href="" (click)="externalRouting('/site/מקלות-וניל')">להזמנה ממקלות וניל</a>
                <img (click)="externalRouting('/site/מקלות-וניל')" [src]="appService.base('assets/images/articles/corona/maklot-vanil.jpg')" />

                <br>
                <a class="title section-title" href="" [routerLink]="appService.redirectValueByLocale('deliveries', 'ROUTE')">
                    <h3>לכל המשלוחים הזמינים סביבך &rsaquo; </h3>
                </a>
                <br>

            </ng-container>

            <ng-container *ngIf="article.id == 'יחד-גם-בבידוד'">

                <br>

                <a class="title" href="" (click)="externalRouting('/site/מנהטן-דיינר--1')">
                    <h4>מנהטן דיינר, חיפה</h4>
                </a>
                <p>במנהטן דיינר יש את כל מה שצריך למסיבת אוכל מושחת עם החברים. המבורגרים, כנפיים, נקניקיות, כריכים מוגזמים והמון תוספות מטוגנות. פשוט כל אחד מזמין הביתה את השחיתות שמתאימה לו, ואפילו לא צריך לכבד את החברים.</p>
                <a href="" (click)="externalRouting('/site/מנהטן-דיינר--1')">להזמנת משלוח ממנהטן דיינר</a>
                <img (click)="externalRouting('/site/מנהטן-דיינר--1')" [src]="appService.base('assets/images/articles/together-alone/351.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/brasserie-mr')">
                    <h4>בראסרי, תל אביב</h4>
                </a>
                <p>האוכל של הבראסרי הוא הכי חו""ל שאפשר להזמין הביתה, עם אווירת פריז, ובעצם הכי קרוב שאפשר עכשיו להרגיש בטיול חברים לחול. יושבים מול הצלחות, מדמיינים שאתם מול האייפל, ומדברים רק על מה עושים בשאר הטיול המדומין. מערך המשלוחים שלהם משולב עם זה של הקופי בר, כך שהגיוון אפילו גדול עוד יותר.</p>
                <a href="" (click)="externalRouting('/site/brasserie-mr')">להזמנת משלוח מבראסרי</a>
                <img (click)="externalRouting('/site/brasserie-mr')" [src]="appService.base('assets/images/articles/together-alone/bra.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')">
                    <h4>שואורום בייקהאוס, תל אביב</h4>
                </a>
                <p>מכירים את מסיבות התה שילדים עושים עם הבובות שלהם? זה הזמן לעשות מסיבה כזו עם כל החברים הוירטואלים. מזמינים דונאטס או מאפים מושחתים אחרים, וכל אחד מסדר לעצמו צלחת בין ערביים מתוקה ותה.</p>
                <a href="" (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')">להזמנת משלוח משואורום</a>
                <img (click)="externalRouting('/site/the-showroom-bakehouse-פנקס')" [src]="appService.base('assets/images/articles/together-alone/showroom_bakehouse.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/גרקו')">
                    <h4>גרקו, תל אביב</h4>
                </a>
                <p>אם אתם יותר בקטע של חופשה יוונית, כל אחד יכול להזמין מגרקו מאזטים ומנה עיקרית, ולפתוח שולחן לעצמו. קצת מוזיקה ואתם באווירת אי יווני בשיחת הזום הבאה שלכם.</p>
                <a href="" (click)="externalRouting('/site/גרקו')">להזמנת משלוח מגרקו</a>
                <img (click)="externalRouting('/site/גרקו')" [src]="appService.base('assets/images/articles/together-alone/greco.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/mojo')">
                    <h4>מוג'ו, נתניה</h4>
                </a>
                <p>התפריט האסייתי של מוג'ו כל כך רחב, שכל אחד בחברה יכול להזמין סוג אחר לגמרי של מנה, סושי, נודלס, כיסונים, מרק או באנים, בלי שיהיה צריך לריב סוף סוף מאיפה מזמינים כדי שכולם יהיו מרוצים.</p>
                <a href="" (click)="externalRouting('/site/mojo')">להזמנת משלוח ממוג'ו</a>
                <img (click)="externalRouting('/site/mojo')" [src]="appService.base('assets/images/articles/together-alone/mojo.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/humongous-רופין')">
                    <h4>יומנגס</h4>
                </a>
                <p>יש מסעדה יותר מתאימה לארוחת חבר'ה מושחתת מאשר יומנגס? גם כשכל אחד יזמין לעצמו הביתה המבורגר עם המון תוספות מוגזמות וערימת צ'יפס ענקית, כמעט ולא תרגישו שאתם בעצם לא יושבים ביחד אלא כל אחד עם ההמבורגר של עצמו.</p>
                <a href="" (click)="externalRouting('/site/humongous-רופין')">להזמנת משלוח מיומנגס</a>
                <img (click)="externalRouting('/site/humongous-רופין')" [src]="appService.base('assets/images/articles/together-alone/hum.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/crave')">
                    <h4>Crave, ירושלים</h4>
                </a>
                <p>המסעדה המושלמת למי ששומר כשרות אבל בכל זאת מחפש אוכל אמריקאי מושחת עם גבינה (טבעונית, ולכן הכשרות נשמרת). ההזמנה שהכי מתאימה לחבורה מעורבת שחלקה שומרת כשרות וחלקה לא, וככה כולם יוצאים מרוצים.</p>
                <a href="" (click)="externalRouting('/site/crave')">להזמנת משלוח מ-Crave</a>
                <img (click)="externalRouting('/site/crave')" [src]="appService.base('assets/images/articles/together-alone/crave.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/barbis')">
                    <h4>ברביס, אילת</h4>
                </a>
                <p>גם אם תעשו שיחה ענקית עם מלא חברים וכל אחד יזמין הביתה המבורגר אחר לגמרי, לא תצליחו לכסות את כל תפריט ההמבורגרים הענק של ברביס. זה יכול להיות אחלה משחק, לנסות להזמין כמה שיותר מהתפריט בכל מפגש וירטואלי.</p>
                <a href="" (click)="externalRouting('/site/barbis')">להזמנת משלוח מברביס</a>
                <img (click)="externalRouting('/site/barbis')" [src]="appService.base('assets/images/articles/together-alone/barb.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/uma-bar-sushi')">
                    <h4>אומה בר & סושי, זכרון יעקב</h4>
                </a>
                <p>ם אם יש בחבורה מי שלא מת על סושי, יש סיכוי טוב שימצא מה לאכול באומה. יש פה מוקפצים, מנות מטוגנות ואפילו צ'יפס, וגם בסוגי הסושי השונים אפשר למצוא רולים בלי דגים או עם מילויים כמו פטריות מוקפצות או זוקיני מטוגן שכולם יאהבו.</p>
                <a href="" (click)="externalRouting('/site/uma-bar-sushi')">להזמנת משלוח מאומה סושי & בר</a>
                <img (click)="externalRouting('/site/uma-bar-sushi')" [src]="appService.base('assets/images/articles/together-alone/UMA.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/hamburger-gourmet-26')">
                    <h4>גורמה 26</h4>
                </a>
                <p>המבורגרים המושחתים של גורמה 26 הם ההמבורגר המושלם לאכול במסיבה וירטואלית. התוספות המוגזמות והמושחתות ישכיחו את הצרות ועכשיו גם אפשר לצרף אלכוהול במבצע 1+1 כדי להכניס לאווירת המסיבה עוד יותר. והכי כיף? אם נשאר לכם כסף בכרטיס הצהריים של העבודה, אפשר להשתמש בו.</p>
                <a href="" (click)="externalRouting('/site/hamburger-gourmet-26')">להזמנת משלוח מגורמה 26</a>
                <img (click)="externalRouting('/site/hamburger-gourmet-26')" [src]="appService.base('assets/images/articles/together-alone/87084355_1793566867449247_818660971806457856_o.jpg')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'שלוש-ארוחות-ביום'">

                <br>

                <a class="title" href="" (click)="externalRouting('/site/calata-15')">
                    <h4>קאלאטה 15, הרצליה</h4>
                </a>
                <p>במסעדה האיטלקית הצעירה מציעים חוץ מכל המנות מהתפריט הרגיל גם תפריטים מיוחדים למשפחות או קבוצות. אפשר להזמין ארוחות לארבעה, שישה או שמונה סועדים, שכוללות הרכב מובנה של מנות, על פי מחוזות שונים מאיטליה. שילוב של פיצה, פסטה ועוד מנות, שירכיבו ארוחה מלאה ומגוונת. מי שרוצה פחות לגוון, יכול גם להזמין מגש לזניה או פסטות שונות, גם הוא לארבעה, שישה או שמונה סועדים, שיאכלו כולם ביחד.</p>
                <a href="" (click)="externalRouting('/site/calata-15')">להזמנת משלוח מקאלאטה 15</a>
                <img (click)="externalRouting('/site/calata-15')" [src]="appService.base('assets/images/articles/together-alone/calata_15.jpg')" />

                <a class="title" href="" (click)="externalRouting('https://blackbarburger.co.il/deliveries/')">
                    <h4>רשת Black</h4>
                </a>
                <p>בהזמנה מרשת Black אפשר להרכיב בקלות ארוחה משפחתית שתתאים לכולם. בין מנות הילדים, מנות ההמבורגר ושאר המנות שכוללות גם מנות עוף וסלטים, כל אחד ימצא משהו שהוא אוהב, בלי שיהיה צריך לריב על מה מזמינים.</p>
                <a href="" (click)="externalRouting('https://blackbarburger.co.il/deliveries/')">להזמנת משלוח מ-Black</a>
                <img (click)="externalRouting('https://blackbarburger.co.il/deliveries/')" [src]="appService.base('assets/images/articles/together-alone/80720123_181064096341170_7425170309858097692_n.jpg')" />

                <a class="title" href="" (click)="externalRouting('https://burgus.co.il/משלוחים/')">
                    <h4>רשת BBB</h4>
                </a>
                <p>גם מהתפריט המגוון של BBB אפשר להרכיב ארוחה לכל המשפחה, שלא חייבת לכלול רק המבורגרים. הילדים יוכלו להנות ממנות ילדים, והגדולים יוכלו לבחור המבורגר מושחת או מנה קצת יותר בריאה ומאוזנת מהתפריט.</p>
                <a href="" (click)="externalRouting('https://burgus.co.il/משלוחים/')">להזמנת משלוח מ-BBB</a>
                <img (click)="externalRouting('https://burgus.co.il/משלוחים/')" [src]="appService.base('assets/images/articles/together-alone/30171109_1662765290439312_6780740520164027467_o.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/shishi')">
                    <h4>Shi SHi, תל אביב</h4>
                </a>
                <p>בשי-שי התארגנו גם כן עד דיל משפחתי מיוחד שכוללים מיקסים של הרולים הטעימים והמשביעים שלהם. אפשר אפילו לבחור בין מגש דגים, בשר, צמחוני או מיקס של כולם ביחד.</p>
                <a href="" (click)="externalRouting('/site/shishi')">להזמנת משלוח מ-Shi Shi</a>
                <img (click)="externalRouting('/site/shishi')" [src]="appService.base('assets/images/articles/together-alone/12003388_707898196009586_5748158060929684877_n.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/גרינברג-ביסטרו')">
                    <h4>גרינברג ביסטרו, תל אביב</h4>
                </a>
                <p>גם בגרינברג מציעים חוץ מהתפריט הרגיל תפריטי ארוחות שלמים שישביעו את כל המשפחה. אפשר לבחור בין ארוחת גרינברג שכוללת מנות ראשונות ועוף שלם במדורה עם תוספות, יחד עם חצי קילו גלידה לקינוח או ארוחת המבורגרים שכוללת כנפיים, המבורגרים, תוספות וסלטים יחד עם חצי קילו גלידה.</p>
                <a href="" (click)="externalRouting('/site/גרינברג-ביסטרו')">להזמנת משלוח מגרינברג ביסטרו</a>
                <img (click)="externalRouting('/site/גרינברג-ביסטרו')" [src]="appService.base('assets/images/articles/together-alone/90625348_2855353397889336_2929039151795798016_o.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/תל-יצחק-מסעדה')">
                    <h4>תל יצחק בייקרי, תל יצחק</h4>
                </a>
                <p>גם במשפחה צריכים להתפנק מדי פעם, ובקונדיטוריה של מסעדת תל יצחק אפשר להזמין עכשיו עוגות שמרים, עוגיות רוגעלך ובורקסים, כדי שתמיד יהיה משהו מתוק ושווה בבית</p>
                <a href="" (click)="externalRouting('/site/תל-יצחק-מסעדה')">להזמנת משלוח מתל יצחק בייקרי</a>
                <img (click)="externalRouting('/site/תל-יצחק-מסעדה')" [src]="appService.base('assets/images/articles/together-alone/86176360_1066821647011047_8100622059027038208_o.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/האחים')">
                    <h4>האחים, תל אביב</h4>
                </a>
                <p>בימים כתיקונם מסעדת האחים היא מסעדת על האש משודרגת, אבל עכשיו הם פתחו שירותי משלוח שהם מכנים "מכולת" ואפשר לקנות שם הביתה פינוקים שווים במיוחד כמו תבלינים, יינות, סלטים של המסעדה, מאפים ואפילו אוכל מוכן איכותי שרק צריך לחמם או לאפות בתנור</p>
                <a href="" (click)="externalRouting('/site/האחים')">להזמנת משלוח מהאחים</a>
                <img (click)="externalRouting('/site/האחים')" [src]="appService.base('assets/images/articles/together-alone/70600719_2521268644578252_1100106016890028032_o.jpg')" />

                <a class="title" href="" (click)="externalRouting('/site/okinawa')">
                    <h4>אוקינאווה, תל אביב</h4>
                </a>
                <p>מגשי הסושי של אוקינאווה יכולים להיות ארוחת פינוק משפחתית מושלמת, בעיקר אם צריך לחגוג אירוע מיוחד בבית. אם לא כולם אוהבים סושי, יש גם מגשי באנים עם מילויים שונים, מגש גיוזות, מגש שיפודי יאקיטורי ואפילו מגש שכולו אגרולים</p>
                <a href="" (click)="externalRouting('/site/okinawa')">להזמנת משלוח מאוקינאווה</a>
                <img (click)="externalRouting('/site/okinawa')" [src]="appService.base('assets/images/articles/together-alone/82364938_3454541964572572_1575021235371245568_n.jpg')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'מה-עושים-כשמתגעגעים-למסעדות'">

                <br>

                <a class="title" href="" (click)="externalRouting('/site/סינטה-בר')">
                    <h4>סינטה בר, חיפה</h4>
                </a>
                <p>בביסטרו החיפאי סינטה בר ממשיכים לבשל את התפריט של המסעדה ואפשר להזמין את כולו הביתה, כולל ההמבורגרים המעולים וגם מנות ראשונות, אוכל מבושל ואפילו מנות בשר מושחתות.</p>
                <a href="" (click)="externalRouting('/site/סינטה-בר')">להזמנת משלוח מסינטה בר</a>
                <img (click)="externalRouting('/site/סינטה-בר')" [src]="appService.base('assets/images/articles/passover/sinta_bar.png')" />

                <a class="title" href="" (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/magazzino-italiano')">
                    <h4>מגזינו, תל אביב</h4>
                </a>
                <p>עם האוכל של מגזינו לא תרגישו בכלל שפסח פה, ואפשר לחגוג עם פיצות, פסטות וכל המגוון האיטלקי. בנוסף, אסור לשכוח את מבחר העוגות והקינוחים של מגזינו, ששווים משלוח מפנק בפני עצמם</p>
                <a href="" (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/magazzino-italiano')">להזמנת משלוח ממגזינו</a>
                <!-- <img (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/magazzino-italiano')" [src]="appService.base('assets/images/articles/passover/magazino.png')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/זוזוברה-תל-אביב')">
                    <h4>זוזוברה, הרצליה, כפר סבא ותל אביב</h4>
                </a>
                <p>גם בזוזוברה תוכלו להזמין את כל מנות הנודלס האהובות מהתפריט, ממש כמו בימים רגילים, ולהתפנק על אוכל אסייתי שייתן קצת הרגשה של חופשה</p>
                <a href="" (click)="externalRouting('/site/זוזוברה-תל-אביב')">להזמנת משלוח מזוזוברה</a>
                <img (click)="externalRouting('/site/זוזוברה-תל-אביב')" [src]="appService.base('assets/images/articles/passover/zozobra.png')" />

                <a class="title" href="" (click)="externalRouting('/site/גרקו')">
                    <h4>גרקו, תל אביב</h4>
                </a>
                <p>אם אתם מחפשים קצת אווירה חופשה יוונית, אפשר להזמין מגרקו מאזטים ומנה עיקריות, ולפתוח שולחן בבית ממש כאילו אתם בחופשה על אי יווני קסום</p>
                <a href="" (click)="externalRouting('/site/גרקו')">להזמנת משלוח מגרקו</a>
                <!-- <img (click)="externalRouting('/site/גרקו')" [src]="appService.base('assets/images/articles/passover/greco.jpg')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/mojo')">
                    <h4>מוג'ו, נתניה</h4>
                </a>
                <p>כיף להזמין ממוג'ו כי התפריט האסייתי שלהם כל כך רחב, שכל אחד בחברה יכול להזמין סוג אחר לגמרי של מנה, סושי, נודלס, כיסונים, מרק או באנים, בלי שיהיה צריך לריב סוף סוף מאיפה מזמינים כדי שכולם יהיו מרוצים.</p>
                <a href="" (click)="externalRouting('/site/mojo')">להזמנת משלוח ממוג'ו</a>
                <!-- <img (click)="externalRouting('/site/mojo')" [src]="appService.base('assets/images/articles/passover/sinta_bar.png')" /> -->

                <a class="title" href="" (click)="externalRouting('https://www.garageburger.co.il/tel-aviv/')">
                    <h4>הגראז', גן ורדים</h4>
                </a>
                <p>אחרי כל האוכל של החג, בטוח שהמשפחה רוצה להתפנק על קצת אוכל אחר, כזה שיוצאים לאכול ביחד, כמו ההמבורגרים המיוחדים והמושחתים של הגראז', שכל אחד יכול למצוא בהם אחד לטעמו.</p>
                <a href="" (click)="externalRouting('https://www.garageburger.co.il/tel-aviv/')">להזמנת משלוח מהגראז'</a>
                <!-- <img (click)="externalRouting('https://www.garageburger.co.il/tel-aviv/')" [src]="appService.base('assets/images/articles/passover/sinta_bar.png')" /> -->

                <a class="title" href="" (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/cafe-noir')">
                    <h4>קפה נואר, תל אביב</h4>
                </a>
                <p>אין הרבה פינוקים יותר שווים מאשר השניצלים הענקיים של קפה נואר, ולהזמין אותם הביתה בחג זה כנראה באמת הפינוק הכי טוב שאתם יכולים לתת לעצמכם.</p>
                <a href="" (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/cafe-noir')">להזמנת משלוח מקפה נואר</a>
                <img (click)="externalRouting('https://wolt.com/he/isr/tel-aviv/restaurant/cafe-noir')" [src]="appService.base('assets/images/articles/passover/cafe_noir.png')" />

                <a class="title" href="" (click)="externalRouting('http://taqueria.co.il/Index.aspx?aspxerrorpath=/&_ga=2.179249892.156838380.1586684918-910695200.1579525675')">
                    <h4>טאקריה, תל אביב</h4>
                </a>
                <p>אוכל מקסיקני תמיד משמח ומשרה אווירה של מסיבה, אז למה שלא לעשות ערב מקסיקני באחד מימי החג, עם נאצ'וס, טאקוס וקצת מוזיקה משמחת?</p>
                <a href="" (click)="externalRouting('http://taqueria.co.il/Index.aspx?aspxerrorpath=/&_ga=2.179249892.156838380.1586684918-910695200.1579525675')">להזמנת משלוח מטאקריה</a>
                <!-- <img (click)="externalRouting('http://taqueria.co.il/Index.aspx?aspxerrorpath=/&_ga=2.179249892.156838380.1586684918-910695200.1579525675')" [src]="appService.base('assets/images/articles/passover/taqueria.png')" /> -->

                <a class="title" href="" (click)="externalRouting('/site/ze-sushi-בזל')">
                    <h4>ZE SUSHI</h4>
                </a>
                <p>סושי זה אוכל שכאילו נוצר לפסח, בלי בצקים וקמח כבר מראש, אז גם מי שלא אוכל חמץ יכול להתפנק בסושי שווה וחגיגי באחד מסניפי זה סושי</p>
                <a href="" (click)="externalRouting('/site/ze-sushi-בזל')">להזמנת משלוח מזה סושי</a>
                <img (click)="externalRouting('/site/ze-sushi-בזל')" [src]="appService.base('assets/images/articles/passover/ze_sushi.png')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'יצירתיות-וטכנולוגיה-הם-שם-המשחק'">

                <p>מגפת הקורונה פגעה כמעט בכל תחומי החיים שלנו, גם כאלה שמעולם לא חשבנו שייפגעו. תחום המסעדנות, כמו שאר תחומי התרבות והפנאי, הוא אחד הנפגעים הגדולים ביותר של המגפה, כזה שעדיין לא בטוח מתי ואם בכלל יצליח להתאושש.</p>
                <p>מיד בתחילת הסגר, כל המסעדות נאלצו לסגור את דלתותיהן בבת אחת, ולהפסיק לארח לקוחות. מי מהן שהיו עוסקות גם בזמן השגרה בכמויות גדולות של משלוחים עוד היו יכולות להמשיך ולספק אוכל, אבל הרוב הגדול של המסעדות מסתמך על לקוחות שיושבים במקום, או על טייק אווי, שגם הוא לא היה אפשרי למשך כמה שבועות.  חלק גדול מהמסעדות שעבדו עם שירותי משלוחים חיצוניים, כשהמשלוחים היו רק הכנסה נוספת להכנסה העיקרית שהיתה הלקוחות במסעדה, גילו שקשה מאוד להרוויח עם העמלות הגבוהות שחברות אלו גובות. דווקא חברות שנהגו להפעיל את כל מערך המשלוחים באופן עצמאי, הצליחו לצמצם משמעותית את עלויות המשלוחים. </p>
                <p>מסעדות שרצו לשרוד את המשבר והתקופה היו חייבות להיערך במהירות וביעילות למצב החדש, ולא מעט מסעדות מצאו פתרונות מגוונים ויחודיים.</p>

                <br>

                <p>מסעדת קלומפוס הצפונית, שנמצאת בגליל העליון, היא לא מסעדה שמורגלת בהוצאת משלוחים. למרות שהאוכל שהיא מגישה, המבורגרים ואוכל דיינרים אמריקאי מתאים מאוד למשלוח, תושבי הצפון, שגרים בישובים קטנים ומרוחקים, לא מורגלים בביצוע משלוחים אלא בישיבה במסעדות או מקסימום איסוף טייק אווי הביתה. ואכן, בקלומפוס ההכנסות כולן היו מבוססות על הזמנה במקום, קבלת זמזם והמתנה במקום לאכול המוכן והארוז.</p>
                <p>בקבוצת המסעדות של <a class="inline-article-link" href="" (click)="externalRouting('/site/קלומפוס')">קלומפוס</a> התחילו כבר כחודש לפני הגעת הוירוס תהליך של העברת מערכת הטייק אווי שלהם למערכת -Tabit Order. המערך עוד לא היה מוכן כשהתחיל המשבר, אבל יחד עם הצוות של Tabit האתר ומערך המשלוחים וההזמנות הוקם תוך ימים, ותושבי האזור היו יכולים לעשות הזמנות דרך האפליקצייה והאתר. "בשבועיים הראשונים בהם היה בלתי אפשרי לעשות טייק אווי, הקהל שלנו למד לעשות משלוחים", אומר אייל מקבוצת המסעדות. "אבל אחרי שבועיים, כשחזרו שירותי הטייק אווי, הקהל שלנו חזר ברובו לעשות איסוף עצמי, רק שעכשיו בזכות המערכת של Tabit הם עושים את זה מראש ובצורה דיגיטלית, במקום לבוא ולחכות ולהתקהל מול המסעדה. המעבר למערכת של Tabit עזר לנו להתמודד עם המשבר בצורה הכי טובה, כי ללא מערך דיגיטלי שגם במהירות לא היינו יכולים לספק שירותי טייק אווי על פי תקנות ההתקהלות. המערכת החדשה, שגם משולבת עם כל שאר המערכות שלנו של ההזמנות והבונים, גם עוזרת לסדר במטבח וחוסכת טעויות של הזמנות טלפוניות".</p>
                <!-- <a href="" (click)="externalRouting('/site/קלומפוס')">למסעדת קלומפוס </a> -->
                <br>
                <img (click)="externalRouting('/site/קלומפוס')" [src]="appService.base('assets/images/articles/corona-restaurants-stories/klompus.jpg')" />
                <img (click)="externalRouting('/site/קלומפוס')" [src]="appService.base('assets/images/articles/corona-restaurants-stories/klompus-1.jpg')" />

                <br>

                <p>ברשת המסעדות האסייתיות <a class="inline-article-link" href="" (click)="externalRouting('/site/זוזוברה-כפר-סבא')">זוזוברה</a> כבר היו מתורגלים בביצוע משלוחים. הרשת היא לקוחה ותיקה של המערכות של Tabit. כל מערך המשלוחים של המסעדות הוא פנימי, כולל כל השליחים. "גם בימי שגרה אחוז גדול מההזמנות שלנו הם במשלוחים", אומר עדי לוריא מהרשת. מיד עם פרוץ המשבר הרשת המשיכה לבצע משלוחים באופן רציף. "נפח המשלוחים שלנו, שכבר היה גדול, רק גדל עוד יותר", אומר לוריא. "מי שהיה רגיל להזמין המשיך לעשות את זה, והלקוחות שהיו מגיעים לשבת אצלנו עברו להזמין הביתה גם הם, דרך כרטיסי התן ביס והסיבוס שלהם, שהמשיכו לצבור כסף גם כשהם עובדים מהבית ולא מגיעים למשרד שקרוב למסעדות שלנו". בעזרת טאביט הצליחה הרשת להגדיל את אזורי המשלוח שלהם ולהגיע לערים מרוחקות קצת יותר ולקהל שרגיל לאכול אצלם אבל לא מגיע יותר למשרד. "היתרון של המערכת של Tabit הוא שהיא עובדת יחד עם מערכת ההזמנות במסעדה ועם המערכת במטבח, ולכן לא היה צריך להתמודד עם תפעול מערך חדש ושונה. בשגרה הזמנות המשלוחים אצלנו לא מגיעות ישירות לטבחים, כי לא תמיד ההזמנה היא מעכשיו לעכשיו. אבל בימים האלה, כשכל ההזמנות הן בעצם משלוח, מערכת ההזמנות חוברה בקלות למטבח, והכל עובד חלק".</p>
                <!-- <a href="" (click)="externalRouting('/site/זוזוברה-תל-אביב')">למסעדת זוזוברה </a> -->
                <br>
                <img (click)="externalRouting('/site/זוזוברה-תל-אביב')" [src]="appService.base('assets/images/articles/corona-restaurants-stories/zozobra.jpg')" />

                <br>

                <p>בניגוד למסעדות האלה, מסעדת "<a class="inline-article-link" href="" (click)="externalRouting('/site/אמא')">אמא </a>" הותיקה מירושלים לא היתה מתורגלת במערך משלוחים או טייק אווי מסודר. רוב לקוחות המסעדה קבועים, ואמנם היו לא מעט לקוחות שהיו לוקחים אוכל הביתה, אבל כל המערך לא היה מסודר ודיגיטלי בהתאם לדרישות החדשות. הם תכננו כבר לא מעט זמן להקים מערך משלוחים, ואפילו היה כבר אתר כמעט מוכן, אבל הרעיון היה מוקפא במשך כמה חודשים. "Tabit נרתמו במהירות להקמת המערך ומהר מאוד התחלנו לבצע טייק אווי", מסברת ליטל, ממנהלות המסעדה. "חלק גדול מהקהל שלנו מבוגר והיה פשוט רגיל להגיע, לשלם ולחכות לאוכל, אבל הפשטות של המערכת עזרה כמעט לכולם להתרגל לשיטת ההזמנות החדשה, וגם עכשיו כשכבר אפשר להגיע ולהזמין טייק אווי כמו פעם, חלק גדול מהם ממשיכים להזמין דרך האפליקציה במקום לחכות".</p>
                <!-- <a href="" (click)="externalRouting('/site/אמא')">למסעדת אמא </a> -->
                <br>
                <img (click)="externalRouting('/site/אמא')" [src]="appService.base('assets/images/articles/corona-restaurants-stories/mama.jpg')" />

                <br>

                <p>האחים אסף ויותם דוקטור, בעלי שלושת המסעדות <a class="inline-article-link" href="" (click)="externalRouting('/site/האחים')">האחים </a>, אייבי ודוק, עמדו בפני בעיה קשה יותר. "האוכל שלנו זה לא אוכל שמתאים למשלוחים", מספר יותם דוקטור". "כשהמשבר התחיל בעצם סגרנו את שלושת המסעדות ובעצם קיפלנו את הבסטה. אחרי כמה ימים של מחשבה עלה לנו רעיון של פתיחת סוג של מכולת דיגיטלית. מתוך הצורך של להביא פרנסה הביתה גם לנו, גם לעובדים שלנו וגם לספקים הקטנים שאנחנו עובדים איתם, הקמנו את המכולת שלנו, שהיא אפשרות גם הרבה יותר כלכלית למשלוח עבורנו, וגם מספקת שירות וחוויה שאין הרבה ממנה. גיליתי שיש את Tabit Order ובגלל שכבר עבדנו עם מערכת ההזמנות והסליקה שלהם הם תוך ימים בודדים הקימו את המערך, ללא עלות מבחינתנו, וחמישה ימים אחרי שהחלטנו על הפתיחה העסק כבר התחיל לפעול". המכולת מספקת סלטים שונים, כמה מנות מהמסעדות השונות שעברו התאמה למשלוח, ירקות מיוחדים, מאפים, מוצרי מזווה, יין ואפילו ערכות עשה זאת בעצמך להכנת מנות או מאכלים בבית. "תוך כדי שחשבנו על התפריט ועל ההערכות, Tabit כבר הקימו את המערך יחד איתנו", מסכם דוקטור את השיטה היחודית שבה הצליח להמשיך להפעיל את העסק גם בזמן המשבר החריג.</p>
                <!-- <a href="" (click)="externalRouting('/site/האחים')">למסעדת האחים </a> -->
                <br>
                <img (click)="externalRouting('/site/האחים')" [src]="appService.base('assets/images/articles/corona-restaurants-stories/brothers.jpg')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'המסעדות-שהכי-התגעגענו-אליהן-וסוף-סוף-נפתחות-מחדש'">

                <p>השגרה חוזרת אלינו אט אט, והגיע גם תורן של המסעדות שכל כך חסרו לנו. מאיפה מתחילים? קשה לבחור, הנה כמה הצעות מעולות שאספנו</p>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/שישקו')">
                    <h4>שישקו, תל אביב</h4>
                </a>
                <p>שישקו הוא המקום הכי טוב להעביר בו ערב של אלכוהול ומנות קטנות עם חברים, ואנחנו הכי מתגעגעים לאיקרה הנהדרת עם החלה הקטנה, לקציצות הפרסה הפריכות והממכרות שאף פעם אין מספיק מהן, וגם לקבב הנהדר, שהולך מצוין עם בירה.</p>
                <a href="" (click)="externalRouting('/site/שישקו')">להזמנת מקום בשישקו</a>
                <img (click)="externalRouting('/site/שישקו')" [src]="appService.base('assets/images/articles/back-to-business/shishko.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>ג'ורג' וג'ון, תל אביב</h4>
                </a>
                <p>המסעדה של מלון דריסקו והשף תומר טל היא אמנם לא מהמסעדות הוותיקות בעיר, אבל בתקופה הקצרה יחסית שהיא איתנו, היא הצליחה להכניס ללב שלנו כמה וכמה מנות שקשה לנו מאוד לחכות להן. המנה של הפסטה עם הסרטנים וקרם התירס מכבבת בחלומות שלנו, ולצידה גם מנות כמו הטרטר עם השומר או הניוקי תפוחי אדמה וריקוטה.</p>
                <a href="" (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בג'ורג' וג'ון</a>
                <img (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/back-to-business/George_and_john.jpg')" />

                <!-- נונו, כפר סבא, הרצליה והוד השרון -->
                <br>
                <a class="title" href="" (click)="externalRouting('/site/נונו-הרצליה')">
                    <h4>נונו-הרצליה, תל אביב</h4>
                </a>
                <p>מסעדות נונו היו עבורנו המקום המושלם לארוחות עם המשפחה, עם תפריט שכל אחד יכול למצוא בו משהו שהוא אוהב, ותמיד יהיה טעים. אנחנו מכורים לפיצות הנהדרות שלהם, ובעיקר לפיצה הסיציליאנית הלבנה, עם הדלעת החריפה והאנשובי. העובדה שיש גם סניפים צמודים של מאפיית מימי ומאפי השמרים המעולים שלהם רק מגבירה את החשק לרוץ לשם.</p>
                <a href="" (click)="externalRouting('/site/נונו-הרצליה')">להזמנת מקום בנונו הרצליה</a>
                <img (click)="externalRouting('/site/נונו-הרצליה')" [src]="appService.base('assets/images/articles/back-to-business/nono.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting(appService.redirectValueByLocale('book-a-table', 'ROUTE'))">
                    <h4> איזו מסעדה פנויה להיום בערב ? לחיפוש מסעדה >></h4>
                </a>

                <br>
                <a class="title" href="" (click)="externalRouting('/site/טריולה')">
                    <h4>טריולה, פתח תקווה</h4>
                </a>
                <p>אם כבר אוכל איטלקי, גם לאוכל של טריולה הספקנו לפתח געגועים. הכרובית באיולי טרטופו היא אחת המנות הראשונות האהובות עלינו וגם לא נוותר באף ארוחה על פסטת הפפרדלה עם האסאדו בבישול ארוך ופטריות.</p>
                <a href="" (click)="externalRouting('/site/טריולה')">להזמנת מקום בטריולה</a>
                <img (click)="externalRouting('/site/טריולה')" [src]="appService.base('assets/images/articles/back-to-business/triola.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/גקו-סטריט')">
                    <h4>ג'קו סטריט, ירושלים</h4>
                </a>
                <p>התגעגענו לשבת בג'קו סטריט עם המון דרינקים וכמה נשנושים שווים במיוחד כמו הפריקסה טונה אדומה שמשלב אוכל רחוב שאנחנו מתים עליו עם חומרי גלם מיוחדים או הקובה כבד אווז שאנחנו אוהבים בדיוק מאותן הסיבות.</p>
                <a href="" (click)="externalRouting('/site/גקו-סטריט')">להזמנת מקום בג'קו סטריט</a>
                <!-- <img (click)="externalRouting('/site/גקו-סטריט')" [src]="appService.base('assets/images/articles/back-to-business/shisko.jpg')" /> -->

                <br>
                <a class="title" href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>התאילנדית בסמטת סיני, תל אביב</h4>
                </a>
                <p>אוכל תאילנדי אותנטי הוא מצרך די נדיר בישראל, והתאילנדית בסמטת סיני מצליחה לבצע אותו נהדר, ובעיקר חריף בטירוף, ממש כמו בארץ המקור. סלט הפפאיה הקלאסי מוכן פה נהדר, ואנחנו גם מתים על מנת הקאו סוי שהיא שילוב של מנת קארי מנחמת עם מנת איטריות כיפית.</p>
                <a href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בתאילנדית בהר סיני</a>
                <!-- <img (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/back-to-business/George_and_john.jpg')" /> -->

                <br>
                <a class="title" href="" (click)="externalRouting('/site/jems-הרצליה')">
                    <h4>ג'מס, הרצליה</h4>
                </a>
                <p>מעטים הדברים שאנחנו מתגעגעים אליהם כמו הגעגועים לשבת עם חברים על המון בירה ואוכל מטוגן וכיפי. בג'מס לא רק שמכינים את הבירות לבד, והן נהדרות, אלא שלצידן מגישים גם תפריט מנות בר נהדרות כמו נקניקיות מיוחדות, המבורגרים וכל מה שצריך בשביל לספוג טוב יותר את כל הבירה.</p>
                <a href="" (click)="externalRouting('/site/jems-הרצליה')">להזמנת מקום בג'מס</a>
                <!-- <img (click)="externalRouting('/site/jems-הרצליה')" [src]="appService.base('assets/images/articles/back-to-business/triola.jpg')" /> -->

                <br>
                <a class="title" href="" (click)="externalRouting('/site/nishi')">
                    <h4>נישי, נתניה</h4>
                </a>
                <p>תנו לנו תפריט סושי ענק, מנות מוקפצות, באנים וכמה ראשונות טעימות, ואנחנו מרגישים הכי בשגרה שיש. אנחנו מכורים לכל סוגי הגיוזות עם המילויים השונים וגם לבאטר צ'יקן העשיר והנהדר שהרוטב שלו על אורז לבן עושה לנו את הארוחה.</p>
                <a href="" (click)="externalRouting('/site/nishi')">להזמנת מקום בנישי</a>
                <img (click)="externalRouting('/site/nishi')" [src]="appService.base('assets/images/articles/back-to-business/nishi.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting(appService.redirectValueByLocale('book-a-table', 'ROUTE'))">
                    <h4>לכל המסעדות >></h4>
                </a>

            </ng-container>

            <ng-container *ngIf="article.id == 'קבלו-את-המסעדות-שהכי-כיף-לשבת-בהן-בחוץ-בחודשים-החמים'">

                <p>אספנו כמה מהמסעדות שמתאימות בול ליציאה קיצית</p>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/גוז-ודניאל')">
                    <h4>ג'וז ודניאל, מושב בני ציון</h4>
                </a>
                <p>כשהמסעדה שלך ממוקמת בלב מושב ירוק, כבר נעשתה עבורך חצי מהעבודה. אבל בגוז' ודניאל הפכו את הגינה שלהם לפינת חמד של ממש, עם המון צמחיה וריהוט גן, שהופכים לתפאורה מושלמת לאוכל הנהדר כאן. גם ארוחות בוקר אפשר לאכול כאן בגינה, עם בריזה מאוד לא טיפוסית לקיץ הישראלי.</p>
                <a href="" (click)="externalRouting('/site/גוז-ודניאל')">להזמנת מקום בגוז' ודניאל</a>
                <img (click)="externalRouting('/site/גוז-ודניאל')" [src]="appService.base('assets/images/articles/ready-for-the-summer/gouje_and_daniel.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/מונדו-2000')">
                    <h4>מונדו 2000, תל אביב</h4>
                </a>
                <p>מונדו 2000 היא המרפסת הכי לוהטת בתל אביב בסופי השבוע. הנוף האורבני של פלורנטין, המוזיקה הנהדרת, הדרינקים ובעיקר הקהל הלוהט, הופכים כל בילוי קיצי פה למשהו שבטוח תרצו להעלות לסטורי.</p>
                <a href="" (click)="externalRouting('/site/מונדו-2000')">להזמנת מקום במונדו 2000</a>
                <img (click)="externalRouting('/site/מונדו-2000')" [src]="appService.base('assets/images/articles/ready-for-the-summer/mondo.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/calata-15')">
                    <h4>Calata 15, הרצליה</h4>
                </a>
                <p>המסעדה האיטלקית הזו אמנם ממוקמת באיזור התעשייה, אבל בשעות הערב, המרפסת היפה שלה לגמרי תשכיח מכם את זה. התאורה הרומנטית והצמחיה הופכים את המרפסת לסוג של גינה ביתית חמודה, ואין מיקום מושלם מזה לאוכל איטלקי כיפי ואמיתי.</p>
                <a href="" (click)="externalRouting('/site/calata-15')">להזמנת מקום בקלאטה 15</a>

                <br>
                <a class="title" href="" (click)="externalRouting(appService.redirectValueByLocale('book-a-table', 'ROUTE'))">
                    <h4>להזמנת מקום בכל המסעדות >></h4>
                </a>

                <br>
                <a class="title" href="" (click)="externalRouting('/site/ca-phe-hanoi')">
                    <h4>ק-פה האנוי, תל אביב</h4>
                </a>
                <p>החלק החיצוני והקטן של ק-פה האנוי אמנם ממוקם על המדרכה, אבל היא מתחת לבניין, כך שתמיד יש בו צל וקצת בריזה, והמיקום של המסעדה ממש ליד כיכר רבין הופך את הישיבה שם למושלמת לכל מי שאוהב להביט בעוברים והשבים, שתמיד מעניינים באיזור הזה.</p>
                <a href="" (click)="externalRouting('/site/ca-phe-hanoi')">להזמנת מקום בק-פה האנוי</a>
                <!-- <img (click)="externalRouting('/site/ca-phe-hanoi')" [src]="appService.base('assets/images/articles/ready-for-the-summer/mondo.jpg')" /> -->

                <br>
                <a class="title" href="" (click)="externalRouting('/site/hotel-montefiore')">
                    <h4>הוטל מונטיפיורי, תל אבי</h4>
                </a>
                <p>מי שרוצה לשבת בחוץ בהוטל מונטיפיורי יצטרך להילחם קשה על מקום, כי מדובר בסך הכל ב-4 שולחנות קטנים, שממוקמים במרפסות הקטנות של המסעדה. גם כאן, המיקום מושלם לצפייה בעוברים והשבים הרבים, והמרפסת הקטנה והצבעונית תגרום לכם להרגיש הכי חו"ל שאפשר כרגע.</p>
                <a href="" (click)="externalRouting('/site/hotel-montefiore')">להזמנת מקום בהוטל מונטיפיורי</a>
                <!-- <img (click)="externalRouting('/site/hotel-montefiore')" [src]="appService.base('assets/images/articles/ready-for-the-summer/mondo.jpg')" /> -->

                <br>
                <a class="title" href="" (click)="externalRouting('/site/שישקו')">
                    <h4>שישקו, תל אביב</h4>
                </a>
                <p>הרחבה מסביב לבית הכנסת הגדול היא כנראה המקום הכי עמוס ומגניב בתל אביב בשעות הערב, אז מה יותר טוב מלשבת בו בחוץ, להנות מכמה נשנושים ואלכוהול בשישקו, ולהרגיש שאתם חלק מההתרחשות העירונית הכי שווה שיש.</p>
                <a href="" (click)="externalRouting('/site/שישקו')">להזמנת מקום בשישקו</a>
                <img (click)="externalRouting('/site/שישקו')" [src]="appService.base('assets/images/articles/ready-for-the-summer/shishko.jpg')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/קיבוץ-על-החוף')">
                    <h4>קיבוץ על החוף, ראשון לציון</h4>
                </a>
                <p>אנחנו באמת צריכים פה הסבר? החוף של ראשון לציון הוא אחד החופים היפים והפחות מוכרים למי שגר מחוץ לעיר, ואין  יותר כיף מלשבת בקיץ על החוף עם דרינקים, אוכל קליל או אבטיח קר.</p>
                <a href="" (click)="externalRouting('/site/קיבוץ-על-החוף')">להזמנת מקום בקיבוץ על החוף</a>
                <img (click)="externalRouting('/site/קיבוץ-על-החוף')" [src]="appService.base('assets/images/articles/ready-for-the-summer/kibuttz.png')" />

                <br>
                <a class="title" href="" (click)="externalRouting('/site/פונדק-עין-כרם')">
                    <h4>פונדק עין כרם, ירושלים</h4>
                </a>
                <p>שכונת עין כרם הירושלמית היא אחת השכונות היפות בארץ ולפונדק עין כרם יש מרפסת גדולה שמשקיפה עליה ועל הנוף הירושלמי המהפנט. זה המקום המושלם להביא אליו תיירים, או להיות בו תיירים בעצמכם.</p>
                <a href="" (click)="externalRouting('/site/פונדק-עין-כרם')">להזמנת מקום בפונדק עין כרם</a>
                <img (click)="externalRouting('/site/פונדק-עין-כרם')" [src]="appService.base('assets/images/articles/ready-for-the-summer/pundak.jpg')" />

            </ng-container>

            <ng-container *ngIf="article.id == 'לפעמים-לא-חייבים-לטוס-רחוק-בכדי-להרגיש-חול'">

                <!-- <p>אספנו עבורכם את המסעדות שיגרמו לכם להרגיש כאילו קפצתם לביקור קצר במדינה אחרת</p>
                <br> -->

                <a class="title" href="" (click)="externalRouting('/site/אורי-בורי')">
                    <h4>אורי בורי, עכו</h4>
                </a>
                <p>החומות של עכו עם הים ברקע, יוצרות את אחד הנופים הכי חו"לים שיש אצלנו, ואורי בורי, המסעדה המושלמת לחובבי דגים, ממוקמת ממש מולן. היא משלימה את הנוף מצוין עם אוכל מעולה, מקומי, מחומרי גלם טריים, שהופך את כל עכו לסוג של חו"ל קטן ודי קרוב.</p>
                <a href="" (click)="externalRouting('/site/אורי-בורי')">להזמנת מקום באורי בורי</a>
                <img (click)="externalRouting('/site/אורי-בורי')" [src]="appService.base('assets/images/articles/feeling-abroad/uri_buri.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/איטלקייה-בפשפשים')">
                    <h4>איטלקיה בפשפשים, תל אביב</h4>
                </a>
                <p>את האוכל של האיטלקיה בפשפשים אנחנו אוהבים כבר שנים, בזכות הפשטות האיטלקית הכיפית שלו, אבל משהו במיקום שלה על פינת הרחוב בשוק הפשפשים, במיוחד בשעות הערב, מזכיר לנו את המסעדות האיטלקיות בפיאצות הגדולות שלהם, ועם כוס יין אדום ביום נעים, לגמרי אפשר לדמיין שקפצתם לביקור ברומא</p>
                <a href="" (click)="externalRouting('/site/איטלקייה-בפשפשים')">להזמנת מקום באיטלקיה בפשפשים</a>
                <img (click)="externalRouting('/site/איטלקייה-בפשפשים')" [src]="appService.base('assets/images/articles/feeling-abroad/ita.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/crave')">
                    <h4>Crave, ירושלים</h4>
                </a>
                <p>הדיינר הירושלמי הפופולרי שיגרום לכם להרגיש בארה"ב לערב אחד, יעשה את זה לא רק בזכות התפריט המאוד אמריקאי שלו, שכולל המון מנות מושחתות במיוחד וגם כמה מנות מקסיקניות בסגנון הטקס-מקס שכל כך פופולרי באמריקה, אלא גם בזכות הקהל, שגם כשאין תיירים, הוא עדיין ברובו דובר אנגלית.</p>
                <a href="" (click)="externalRouting('/site/crave')">להזמנת מקום ב-Crave</a>
                <img (click)="externalRouting('/site/crave')" [src]="appService.base('assets/images/articles/feeling-abroad/crave.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/port-cafe?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>פורט קפה, קיסריה</h4>
                </a>
                <p>הגן הלאומי של קיסריה תמיד מרגיש לנו קצת חו"ל, משהו בין העתיקות של יוון לנופים היפים של חופי תורכיה, והישיבה בפורט קפה, מול כל הנוף הזה, כבר תעשה את העבודה. ההצטרפות של השף עינב אזגורי למסעדה בחודשים האחרונים אומרת שעכשיו אפשר גם לאכול מול כל הנוף הזה גם אוכל מצוין ומעניין של שף מוערך, אז מה בעצם צריך יותר מזה?</p>
                <a href="" (click)="externalRouting('/site/port-cafe?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בפורט קפה</a>
                <img (click)="externalRouting('/site/port-cafe?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/feeling-abroad/port_cafe.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>ג'ורג' וג'ון, תל אביב</h4>
                </a>
                <p>המושבה האמריקאית בדרום תל אביב, שבה ממוקם מלון דריסקו בו שוכנת המסעדה, הוא כנראה אחד היפים בתל אביב. המבנה של המלון, שהיה בית מלון כבר לפני יותר מ-100 שנים, יזרוק אתכם מיד לתחושה של ביקור באחוזה עשירה בדרום ארה"ב. האוכל המצוין של השף תומר טל הוא רק הדובדבן שבקצפת.</p>
                <a href="" (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בג'ורג' וג'ון</a>
                <img (click)="externalRouting('/site/georgejohn?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/feeling-abroad/gg.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>התאילנדית בסמטת סיני</h4>
                </a>
                <p>במסעדה התאילנדית הזו לא ירחמו עליכם כמו בהרבה מסעדות תאילנדיות אחרות בארץ. האוכל פה חריף בטירוף, ממש כמו בארץ המקור, המנות אותנטיות לדרך ההכנה המקורית שלהם, וכל ארוחה פה היא טיול בזכרונות של הטיול לתאילנד.</p>
                <a href="" (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בתאילנדית בהר סיני</a>
                <!-- <img (click)="externalRouting('/site/the-thai-in-har-sinai?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/back-to-business/shishko.jpg')" /> -->
                <br>

                <a class="title" href="" (click)="externalRouting(appService.redirectValueByLocale('book-a-table', 'ROUTE'))">
                    <h4>להזמנת מקום בכל המסעדות >></h4>
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/nammos?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>נאמוס, הרצליה</h4>
                </a>
                <p>יש יותר חו"ל מאווירת הים והסירות במרינה של הרצליה? כמעט תמיד יש פה בריזה נעימה ורחש כיפי של גלים, והתפריט בנאמוס הוא הכי פינוק מיוחד בחו"ל. שילוב של אוכל אסייתי עם יציאות מקוריות, ואם אתם ממש בקטע של להתפנק כאילו חו"ל, יש כאן אפילו לובסטר בתפריט.</p>
                <a href="" (click)="externalRouting('/site/nammos?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום בנאמוס</a>
                <!-- <img (click)="externalRouting('/site/nammos?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/back-to-business/shishko.jpg')" /> -->
                <br>

                <a class="title" href="" (click)="externalRouting('/site/מציצים?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">
                    <h4>מציצים, תל אביב</h4>
                </a>
                <p>רבים מאיתנו נוסעים לחו"ל בשביל חופשת בטן-גב בחופים יפים, אז כנראה שהכי קרוב שיש לנו לזה הוא חוף מציצים והמסעדה שלו. קוקטיילים כיפים, ארוחות בוקר או ארוחה קלילה אחרת, אבטיח ושייקים, והכל ממש על החול. טיפה דמיון ואתם בחופשת החלומות שלכם באי אקזוטי.</p>
                <a href="" (click)="externalRouting('/site/מציצים?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')">להזמנת מקום במציצים</a>
                <!-- <img (click)="externalRouting('/site/מציצים?utm_source=article_tabitisrael&utm_medium=article_tabitisrael')" [src]="appService.base('assets/images/articles/back-to-business/shishko.jpg')" /> -->
                <br>
            </ng-container>

            <ng-container *ngIf="article.id == 'גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית'">

                <a class="title" href="" (click)="externalRouting('/site/פוקצה--2?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>פוקצ'ה, גן הצפון, הגושרים</h4>
                </a>
                <p>אחת המסעדות הכי ותיקות ואהובות בצפון הארץ, בפוקצ'ה אפשר למצוא את כל האוכל האיטלקי שכולם אוהבים, כמו פסטות ופיצות, אבל גם מי שלא אוהב אוכל איטלקי ימצא מה להזמין פה, בזכות מנות דג או בשר טעימות לא פחות. </p>
                <a href="" (click)="externalRouting('/site/פוקצה--2?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מפוקאצ'ה
                </a>
                <img (click)="externalRouting('/site/פוקצה--2?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/fookacha.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/bbb-צמח?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>BBB, צמח</h4>
                </a>
                <p>הסניף של BBB באיזור צמח מגיע לכל המקומות באיזור, גם אם אתם פשוט מסתלבטים באחד החופים הסמוכים. ומה יותר שווה ממשלוח של המבורגר חם ועסיסי ישירות אליכם על החוף?</p>
                <a href="" (click)="externalRouting('/site/bbb-צמח?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מ-BBB צמח
                </a>
                <img (click)="externalRouting('/site/bbb-צמח?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/bbb.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/מקלות-וניל?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>מקלות וניל, עפולה </h4>
                </a>
                <p>הקונדיטוריה הותיקה של עפולה שאפשר למצוא בה קינוחים שווים במיוחד ממש כמו פטיסרי בפריז, עושה גם משלוחים או טייק אווי של כל המתוק הזה, ויש להם בתפריט גם סלטים, פסטות ועוד מאכלים. משלוח מהם יסגור לכם גם את הפינה של הארוחה ובעיקר את הקינוח שמגיע אחריה.</p>
                <a href="" (click)="externalRouting('/site/מקלות-וניל?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח ממקלות וניל
                </a>
                <img (click)="externalRouting('/site/מקלות-וניל?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/vanile.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/תל-אביבה?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>תל אביבה, קריית שמונה</h4>
                </a>
                <p>תפריט המשלוחים של תל אביבה כיפי במיוחד כי הוא כלל את כל מה שאפשר לאכול במסעדה. זאת אומרת לא רק את הפסטות, הסלטים הכריכים או הפיצות אלא אפילו את ארוחות הבוקר השוות, ככה שאפשר להתפנק בבית בצורה מושלמת. ואם זה לא מספיק, הם שולחים אפילו את הקינוח אליכם הביתה.</p>
                <a href="" (click)="externalRouting('/site/תל-אביבה?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מתל אביבה
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/משלוחים')">
                    <h4>למציאת משלוחים בסביבתך >></h4>
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/קלומפוס?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>קלומפוס, גן הצפון, הגושרים</h4>
                </a>
                <p>אם אתם גרים בסביבה אתם בטח כבר מכירים את ההמבורגרים של קלומפוס, שנחשבים להכי טובים באיזור. אבל ידעתם שאפשר גם להזמין אותם הביתה? כל ההמבורגרים המיוחדים יכולים להגיע עד עליכם, בלי שתצטרכו לעזוב את המזגן. </p>
                <a href="" (click)="externalRouting('/site/קלומפוס?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מקלומפוס
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/ארטישוק?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>ארטישוק, עמק המעיינות </h4>
                </a>
                <p>גרים בעמק או רק קפצתם לבלות בעמק המעיינות - מארטישוק תוכלו להזמין אוכל איטלקי עד הבית או באיסוף עצמי, כולל פסטות וסלטים, אבל תקשיבו לנו ותתמקדו במאפים השווים שלהם שיוצאים מהטאבון: פיצות עם המון תוספות מגניבות או פוקצ'ות ממכרות שאי אפשר להפסיק לאכול. </p>
                <a href="" (click)="externalRouting('/site/ארטישוק?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מארטישוק
                </a>
                <img (click)="externalRouting('/site/ארטישוק?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/artichok.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/משלוחים')">
                    <h4>למציאת משלוחים בסביבתך >></h4>
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/פיצה-טראק?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>פיצה טראק, קיבוץ דפנה </h4>
                </a>
                <p>פיצה טראק אמנם לא עושים משלוחים, אבל הפיצה שלהם שווה הגעה ואיסוף עצמי, בזכות הבצק המצוין שלה, שהכי יזכיר לכם ביקור באיטליה, בלי להתרחק יותר מדי מהבית. </p>
                <a href="" (click)="externalRouting('/site/פיצה-טראק?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת טייק אווי מפיצה טראק
                </a>
                <img (click)="externalRouting('/site/פיצה-טראק?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/pizza.jpeg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/humongous-נשר?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>יומנגס, נשר</h4>
                </a>
                <p>מי לא מכיר את ההמבורגרים המוגזמים והמושחתים של יומנגס? כשמזמינים אותם הביתה אפשר להנות מהם בלי לחשוב על כמה הם מלכלכים אתכם, וליהנות לבד מכל התוספות או מערימות הצ'יפס המוגזמות שאיכשהו תמיד מתחסלות למרות הגודל. </p>
                <a href="" (click)="externalRouting('/site/humongous-נשר?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מיומנגס
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/משלוחים')">
                    <h4>למציאת משלוחים בסביבתך >></h4>
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/chang-ba-יקנעם?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>צ'אנג בה, יקנעם </h4>
                </a>
                <p>כמו בסניף הראשון בחיפה, גם צ'אנג בה ביקנעם מציעה אוכל תאילנדי אמיתי, כזה שישרוף לכם קצת בפה (אם רק תרצו, לא חייבים חריף). האוכל פה אותנטי במיוחד, ועובר משלוח נהדר, כך שהוא מרגיש ממש כמו במסעדה עצמה. </p>
                <a href="" (click)="externalRouting('/site/chang-ba-יקנעם?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מצ'אנג בה
                </a>
                <img (click)="externalRouting('/site/chang-ba-יקנעם?utm_source=article_northdelivery&utm_medium=article_northdelivery')" [src]="appService.base('assets/images/articles/north/chang-ba.jpg')" />
                <br>

                <a class="title" href="" (click)="externalRouting('/site/ביסטרו-bp-מפרץ-חיפה?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>BP, חיפה </h4>
                </a>
                <p>BP מפורסמים בזכות ההמבורגרים שלהם אבל אפשר לאכול פה גם כריכים נהדרים אחרים, מהמון סוגים. והשוס הכי גדול? דילים משפחתיים שיחסכו לכם כסף ויסגרו לכם ארוחה שלמה לכולם. </p>
                <a href="" (click)="externalRouting('/site/ביסטרו-bp-מפרץ-חיפה?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מ-BP
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/site/pasta-bar-נשר?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    <h4>פסטה בר, נשר </h4>
                </a>
                <p>מפסטה בר אפשר להזמין משלוח איטלקי שגרתי וטעים של פסטות או פיצות, אבל הכי כיף להזמין מהם את ערכות הארוחה השוות ולהפוך את הבית למטבח איטלקי אמיתי לערב אחד. מקבלים כדורי בצק מוכן, רטבים ואת כל התוספות, ומכינים ביחד, כל המשפחה, פיצות כמו שאוהבים. אין כמו הריח של פיצה שהכנתם לבד בבית! </p>
                <a href="" (click)="externalRouting('/site/pasta-bar-נשר?utm_source=article_northdelivery&utm_medium=article_northdelivery')">
                    להזמנת משלוח מפסטה בר
                </a>
                <br>

                <a class="title" href="" (click)="externalRouting('/משלוחים')">
                    <h4>למציאת משלוחים בסביבתך >></h4>
                </a>
                <br>

            </ng-container>

            <!-- <ng-container *ngIf="article.id == 'אמריקן-אקספרס-מנה-עלינו'"> -->

                <!-- section -->
                <!-- <br>
                <div class="title section-title">
                    <h2> מיוחד ללקוחות אמריקן אקספרס: נכנסים לאחת המסעדות המשתתפות בהטבה, בוחרים את המנה שאתם אוהבים (מהמנות המשתתפות), מכניסים את קוד ההטבה ו... מחכים לשליח. בתאבון!</h2>
                    <p>* למחזיקי כרטיס אמריקן אקספרס בלבד</p>
                </div>
                <br>

                <a class="title" href="" (click)="externalRouting('https://rewards.americanexpress.co.il/benefitsforall/restaurants-cafes/rest-4856/?utm_source=tabit&utm_medium=click&utm_campaign=mana')">
                    <h4>פרטים והורדת קופון הטבה - באתר אמריקן אקספרס >></h4>
                </a>
                <br>
                
                <a class="title">
                    <h4>טאיזו</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/2TXyp52')" [src]="appService.base('assets/images/articles/american-express/Taizu.jpg')" />

                <a class="title">
                    <h4>מגזינו</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/369folI')" [src]="appService.base('assets/images/articles/american-express/Magazzino.jpg')" />

                <a class="title">
                    <h4>ק-פה האנוי</h4>
                </a>
                <img (click)="externalRouting('https://www.tabitorder.com/?siteName=caphehanoi#/start')" [src]="appService.base('assets/images/articles/american-express/caphe-hanoi.jpg')" />

                <a class="title">
                    <h4>סרפינה</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/38kxJiF')" [src]="appService.base('assets/images/articles/american-express/Serafina.jpg')" />

                <a class="title">
                    <h4>קפה איטליה</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/3etapQE')" [src]="appService.base('assets/images/articles/american-express/Cafe-italia.jpg')" />

                <a class="title">
                    <h4>רביבה וסיליה המעדניה</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/38OAs46')" [src]="appService.base('assets/images/articles/american-express/reviva-and-silia.jpg')" />

                <a class="title">
                    <h4>קופי בר</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/38oLDQS')" [src]="appService.base('assets/images/articles/american-express/Coffeebar.jpg')" />


                <a class="title">
                    <h4>קיוטו</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/36iemUr')" [src]="appService.base('assets/images/articles/american-express/Kyoto.jpg')" />

                <a class="title">
                    <h4>המבורגר גורמה 26</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/36iVhlh')" [src]="appService.base('assets/images/articles/american-express/26-Hamburger.jpg')" />

                <a class="title">
                    <h4>סושיאל קלאב</h4>
                </a>
                <img (click)="externalRouting('https://bit.ly/3kefo8V')" [src]="appService.base('assets/images/articles/american-express/Social-Club.jpg')" />

                <a class="title" href="" (click)="externalRouting('https://rewards.americanexpress.co.il/benefitsforall/restaurants-cafes/rest-4856/?utm_source=tabit&utm_medium=click&utm_campaign=mana')">
                    <h4>לפרטים נוספים והורדת קופון הטבה באתר אמריקן אקספרס - לחצו כאן!</h4>
                </a>

            </ng-container> -->

        </div>
    </div>
    <!--
    <div class="article-bottom-content">
        <a [routerLink]="article.bottomLink" class="article-bottom-content-button">
            {{ 'WEB_CONTAINER.web_article.bottom_content_button' | translate }}
        </a>
    </div>
    -->
</div>

<web-footer></web-footer>