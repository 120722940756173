
<block-ui>
    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1">{{'my_history' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
        </div>
    </div>

    <div class="module-content wl-app-primary-background-color">
        <div class="generic-desc center wl-app-secondary-background-color" [@removeLoadHistoryButton]="!showFullHistoryButton" *ngIf="showFullHistoryButton">
            {{'search_history_description' | translate: descTitle}}
            <div class="show-more history">
                <button type="button" class="button" (click)="loadFullHistory()">{{'search_history' | translate}}</button>
            </div>
        </div>

        <mat-tab-group class="generic-tab-group">
            <mat-tab label="{{'by_date' | translate}}">
                <div class="sites">
                    <mat-list class="generic-list">
                        <ng-container *ngFor="let trackedOrder of trackedOrders | orderBy: 'created': true">
                            <tracked-order-item [siteName]="trackedOrder.orgName" [order]="trackedOrder"></tracked-order-item>
                        </ng-container>
                        <ng-container *ngFor="let site of sites | slice:0:(allHistoryResults ? sites.length : 30)">
                            <app-history-item type="past_reservation" [withBill]="true" [siteName]="site.name" [historyItem]="site"></app-history-item>
                        </ng-container>
                        <div *ngIf="sites.length > 30 && !allHistoryResults" class="show-more">
                            <button type="button" class="button" (click)="allHistoryResults = true">{{'show_more' | translate}}</button>
                        </div>
                    </mat-list>
                </div>
            </mat-tab>
            <mat-tab label="{{'by_restaurant' | translate}}">
                <mat-accordion displayMode="flat" class="no-margins generic-list-accordion">
                    <mat-expansion-panel *ngFor="let groupName of groupedSitesIncludeTrackedOrder">
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{groupName}}</mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-list class="generic-list">
                            <ng-container *ngFor="let trackedOrder of trackedOrders |orderBy: 'created': true">
                                <tracked-order-item *ngIf="trackedOrder.orgName == groupName" [order]="trackedOrder"></tracked-order-item>
                            </ng-container>
                            <ng-container *ngFor="let site of sites">
                                <app-history-item *ngIf="site.name == groupName" type="past_reservation" [withBill]="true" [historyItem]="site">
                                </app-history-item>
                            </ng-container>
                        </mat-list>

                    </mat-expansion-panel>
                </mat-accordion>

                <!--<mat-list class="generic-list">
                    <ng-container *ngFor="let group of groupedSites">
                        <h3 class="generic-list-group" matSubheader>{{group.name}}</h3>
                        <ng-container *ngFor="let site of group.sites">
                            <ng-container *ngTemplateOutlet="siteTemplate; context: {site:site}"></ng-container>
                        </ng-container>
                    </ng-container>
                </mat-list>-->
            </mat-tab>
        </mat-tab-group>

        <div class="keyboard-spacer"></div>
    </div>
</block-ui>
