<div class="two-columns">

    <div class="first-column" ngxDetectScroll (onScroll)="handleScroll($event)">
        <div class="sites-list" *ngIf="sites?.length">
            <mat-list class="generic-list">
                <site-item
                    [id]="'site-id-' + site._id"
                    [inFocus]="site._id == siteIdInFocus"
                    [siteId]="site._id"
                    [timeSlots]="showTimeSlots && site.time_slots"
                    [showBadges]="showBadges"
                    [service]="service"
                    (click)="siteItemClick(site._id)"
                    *ngFor="let site of sites"
                ></site-item>
            </mat-list>
        </div>

        <div class="show-more-sites">
            <div *ngIf="!preventSearchMore && !sites?.length" class="loading-label">{{'MESSAGES.LOADING_INITIAL_SITES' | translate}}</div>
            <div *ngIf="!preventSearchMore && sites?.length" class="loading-label">{{'MESSAGES.LOADING_SITES' | translate}}</div>
            <div *ngIf="preventSearchMore && sites?.length > 5" class="loading-label">{{'MESSAGES.NO_MORE_RESULTS' | translate}}</div>
            <div *ngIf="preventSearchMore && !sites?.length" class="loading-label">{{'MESSAGES.NO_ORGS_RESULTS' | translate}}</div>
            <mat-spinner *ngIf="!preventSearchMore" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
        </div>

    </div>
    <div class="map" *ngIf="(appService.isTablet() || appService.isDesktop()) && googleMapsLoaderService.googleMapsLoaded">
        <google-map height="100%" width="100%" [center]="currentLocation?.location" [zoom]="initialZoomLevel"
            [options]="mapOptions">
            <!-- External sites markers with zIndex 1 -->
            <map-marker *ngFor="let site of sites | filterBy: {external: false}" [position]="site?.location"
                [options]="{ icon: siteIcon(site), zIndex: 1 }" (mapClick)="clickedMarker(site._id)">
            </map-marker>
            <!-- Current location marker with zIndex 2 -->
            <map-marker *ngIf="!currentLocation?.area" [position]="currentLocation?.location"
                [options]="{ icon: getMapIconObj(), zIndex: 2 }">
            </map-marker>
        </google-map>
    </div>
</div>
