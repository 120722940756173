import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { orderBy } from 'lodash-es';

import { ORGANIZATION_BUCKET_TYPES } from '../../_core/OrganizationBucket';
import { AppService } from '../../app.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { EntityService } from '../../_core/entity.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
    selector: 'app-web-tiles-new-restaurants',
    templateUrl: './web-tiles-new-restaurants.component.html',
    styleUrls: ['./web-tiles-new-restaurants.component.scss']
})
export class WebTilesNewRestaurantsComponent implements OnInit, OnDestroy {

    private loadingSubscription: Subscription;
    private coreSubscription: Subscription;

    public loading = true;
    public loadingMoreSites = false;
    public sitesLoaded = false;

    private orgsSubscription: Subscription = null;

    newAtTabitOrgs: any[];

    constructor(
        public appService: AppService,
        private organizationsService: OrganizationsService,
        private entityService: EntityService,
        protected liveAnnouncer: LiveAnnouncer,
    ) { }

    ngOnInit() {
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) {
                this.entityService.subscribeToCoreData()
                .then(() => this.subscribeToOrgs());
            }
        })

        this.loadingSubscription = this.appService.loadingMetaData.subscribe(loadingState => {
            this.loading = loadingState;
        });

        if (!this.sitesLoaded) this.subscribeToOrgs();
        this.liveAnnouncer.announce(this.appService.translate('WEB_CONTAINER.book_a_table.new_restaurants_in_tabit'));
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
        this.orgsSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
    }

    siteClick(site) {
        this.appService.redirect(['/site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    subscribeToOrgs() {
        const newAtTabitOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.new];

        // One subscription for two organizations "TYPES":
        this.orgsSubscription = merge(

            newAtTabitOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.new, orgs }))),

        ).pipe(

            map(organizationsContainer => ({
                ...organizationsContainer,
                orgs: orderBy(organizationsContainer.orgs, ['distance'], ['asc'])
            }))

        ).subscribe((organizationsContainer: any) => {
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.new) {
                this.newAtTabitOrgs = organizationsContainer.orgs;
            }

            this.sitesLoaded = true;

        }, err => {
            this.sitesLoaded = false;
            console.error('error with incoming organizations:', err);
        });
    }

}
