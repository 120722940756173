import { Component, OnInit, Input } from '@angular/core';

import { BookService } from '../../_core/book.service';

@Component({
    selector: 'time-slots',
    templateUrl: './time-slots.component.html',
    styleUrls: ['./time-slots.component.scss']
})
export class TimeSlotsComponent implements OnInit {

    @Input() public siteId: any;
    @Input() public timeSlots: any;
    @Input() public areaDescriptions: any;

    constructor(
        public bookService: BookService
    ) { }

    ngOnInit() {
        if (this.timeSlots) {
            this.timeSlots = this.bookService.populateDisabledTimeSlots(this.timeSlots);
        }
    }
}
