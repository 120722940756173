import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    public setItem(itemName, item) {
        try {
            window.localStorage.setItem(itemName, item);
        } catch(err) {
            this.errorHandler('setItem', err);
        }
    }

    public getItem(itemName) {
        try {
            return window.localStorage.getItem(itemName);
        } catch(err) {
            this.errorHandler('getItem', err);
        }
    }

    public removeItem(itemName) {
        try {
            window.localStorage.removeItem(itemName);
        } catch(err) {
            this.errorHandler('removeItem', err);
        }
    }

    private errorHandler(op, err) {
        console.debug(`STORAGE SERVICE | Error in ${op} operation`, err);
    }

}
