<div class="window" (keydown)="handleKeyboardEvent($event)">
    <div
        class="tile"
        (swipeleft)="moveGallery(1)"
        (swiperight)="moveGallery(-1)"
    >
        <div class="close-button">
            <button aria-label="close" mat-button class="close" [mat-dialog-close]="true">
                <mat-icon [svgIcon]="'gc-close'"></mat-icon>
            </button>
        </div>
        <img [src]="selectedImage.photo_url">
        <span (click)="moveGallery(1)" *ngIf="showRightArrow" class="directional-arrow right">&rsaquo;</span>
        <span (click)="moveGallery(-1)" *ngIf="showLeftArrow" class="directional-arrow left">&rsaquo;</span>
    </div>
</div>