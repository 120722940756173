
<block-ui>
        <!-- Accessibility contrast -->
    <!-- <button *ngIf="showContrastButton" [attr.aria-pressed]="contrastFeature.active" mat-mini-fab class="contrast-controller" [ngClass]="{'contrast-relocation': appService.relocateContrastButton}" (click)="applyContrast()" [attr.aria-label]="'ACCESS.contrast-button' | translate">
        <mat-icon svgIcon="contrast"></mat-icon>
    </button> -->

    <special-messages></special-messages>
    <div class="main-app-component" [dir]="direction" [class.transparent-background-with-animation]="styleService.mainAppComponentTransparent">
        <div [@showNoLocationIndicator] *ngIf="!locationService.hasLocation && showNoLocationIndicator" class="no-location-indicator">
            <p (click)="cordovaPluginsService.locationServiceClick()" class="wl-cards-background-color">
                <!-- <mat-icon inline>location_disabled</mat-icon> -->
                {{ 'MESSAGES.ENABLE_LOCATION' | translate }}
            </p>
        </div>

        <div [@showNoNotificationsIndicator] *ngIf="userBlockedNotifications && showNoNotificationsIndicator && locationService.hasLocation" class="no-notifications-indicator">
            <p (click)="cordovaPluginsService.notificationServiceClick()">
                {{ 'MESSAGES.ENABLE_NOTIFICATIONS' | translate }}
            </p>
        </div>

        <div class="router-outlet-container" [@routeAnimation]="getAnimationTag(outlet)">
            <!-- <mat-icon matBadge="No Location" matBadgePosition="above after">home</mat-icon> -->
            <router-outlet #outlet="outlet"></router-outlet>
        </div>

        <footer-links *ngIf="bottomLinksVisible"></footer-links>
        <notifications-widget [class.without-bottom-links]="!bottomLinksVisible" [@slideWidget]="showWidget"></notifications-widget>
    </div>
</block-ui>
<!-- an empty svg filter to apply feColorMatrix color transform -->
<mat-icon svgIcon="svg-color-filter"></mat-icon>