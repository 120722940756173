import { Component, OnInit } from '@angular/core';
import { BookService } from '../../_core/book.service';

@Component({
    selector: 'web-events',
    templateUrl: './web-events.component.html',
    styleUrls: ['./web-events.component.scss']
})
export class WebEventsComponent implements OnInit {

    constructor(
        private bookService: BookService,
    ) { }

    ngOnInit() {
        this.bookService.startWithoutSite();
    }

}
