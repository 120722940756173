import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { AMPMConverterPipe } from '../../_core/pipes';

@Component({
    selector: 'site-links',
    templateUrl: './site-links.component.html',
    styleUrls: ['./site-links.component.scss']
})
export class SiteLinksComponent implements OnInit, OnDestroy {

    @Input() site: any;

    public showTimeslots: boolean = false;
    private locationSubscription: Subscription;
        public showDistance: boolean = false;
    public unit: string = this.appService.getUnitByLocale();

    constructor(
        private appService: AppService,
        public dialogsService: DialogsService,
        public locationService: LocationService,
        private ampmConverterPipe: AMPMConverterPipe,
    ) { }

    ngOnInit() {
        this.locationSubscription = combineLatest([
            this.locationService.actualLocationAvailable,
            this.locationService.location
        ]).subscribe(([actualLocationAvailable, locationLabeled]: [boolean, LocationLabeled]) => {
            this.showDistance = actualLocationAvailable && locationLabeled.actual;
        });
    }

    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
            }

    callPhone() {
        if (window['cordova']) {
            window.open('tel:' + (this.site.phone || this.site.reservation.methodValue), '_system');
        } else {
            window.location.href = 'tel:' + (this.site.phone || this.site.reservation.methodValue);
        }
    }

    activeSlotsTextParse(text: string) {
        return text.toString().split(/\s/g).map(chunk => this.ampmConverterPipe.transform(chunk)).join(' ');
    }

    public formatHoursRangeByLocale(from: string, to: string): string {
        return (`${this.ampmConverterPipe.transform(from)} - ${this.ampmConverterPipe.transform(to)}`);
    }
}
