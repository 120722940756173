<web-header>
    <web-delivery-header></web-delivery-header>
</web-header>

<site-list-map
    [type]="'order'"
    [sites]="searching ? [] : orgs"
    [initialSearch]="orgs.length == 0 || searching ? true : false"
    [preventSearchMore]="preventSearchMore"
    [extendedResultsButtonVisible]="showMoreResultsButton"
    [showBadges]="!usingExtendedResults"
    [service]="selectedService"
    (siteClick)="siteClick($event)"
    (scrollReachedBottom)="loadMore()"
    (extendedResultsClick)="extendedResultsClick()"
></site-list-map>
