<sites-search-tools
    type="search"
    [title]="title"
    [initialSearch]="initialSearch"
    [customFilterFields]="filterFields"
    (searchChanged)="searchChanged($event)"
    (searchTextCleared)="clearSearchText()"
    (scrollReachedBottom)="loadMore()"
>

    <div class="sites-list" *ngIf="!searching">
        <mat-list class="generic-list">
            <ng-container *ngFor="let site of orgs">
                <site-item [siteId]="site._id" (click)="siteClick(site)" (press)="sitePress(site, $event)"></site-item>
            </ng-container>
        </mat-list>
    </div>

    <div class="show-more-sites">
        <div *ngIf="!preventSearchMore && (!orgs.length || searching)" class="loading-label">{{'MESSAGES.LOADING_INITIAL_SITES' | translate}}</div>
        <div *ngIf="!preventSearchMore && !orgs.length && !searching" class="loading-label">{{'MESSAGES.LOADING_SITES' | translate}}</div>
        <div *ngIf="preventSearchMore && orgs.length > 5" class="loading-label">{{'MESSAGES.NO_MORE_RESULTS' | translate}}</div>
        <div *ngIf="preventSearchMore && !orgs.length" class="loading-label">{{'MESSAGES.NO_ORGS_RESULTS' | translate}}</div>
        <mat-spinner *ngIf="!preventSearchMore" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
    </div>

</sites-search-tools>
