
    <form (ngSubmit)="apply(challengeForm)" #challengeForm="ngForm">
        <div class="sms-dialog">
            <div class="close-btn">
                <button aria-label="{{'close' | translate}}" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div mat-dialog-content>
                <div class="_content mb-2">
                    <div style="white-space:pre-wrap">{{message}}</div>
                </div>
                <mat-form-field class="w-100 customer-number" *ngIf="phoneIsMissing">
                    <mat-label>{{'phone' | translate}}</mat-label>
                    <!-- We've changed the validation to be maxlength=37 in order to support long vouchers -->
                    <input type="tel" matInput style="border: none;" class="phone-input wl-app-primary-background-color" name="customerNumber"
                        [(ngModel)]="customerNumber" required>
                </mat-form-field>
                <mat-form-field class="w-100" *ngIf="!phoneIsMissing">
                    <mat-label>{{'verification_code'  | translate}}</mat-label>
                    <input autocomplete="off" matInput placeholder="{{'' | translate}}" [(ngModel)]="verificationCode" 
                        name="verificationCode" required type="tel" validPhoneNumber pattern="[0-9]*" [maxlength]="data.maxLength || 4" >
                </mat-form-field>
                
                </div>

            <div mat-dialog-actions>
                <button 
                    type="submit" 
                    mat-flat-button color="accent" 
                    class="rounded lg"
                    [ngClass]="{ 'disable-click': !validCodeLength }"
                    [disabled]="!phoneIsMissing && !validCodeLength">
                    <span *ngIf="!disabled">{{('continue') | translate}}</span>
                    <mat-spinner *ngIf="disabled" diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
                <div *ngIf="!phoneIsMissing">
                    <ng-container *ngIf="countdownValue$ | async as countdownValue">
                        <div
                            class="resend-code disable-click"
                            accessible
                        >
                            {{'did_not_arrive_resend' | translate}}
                            ({{ countdownValue }})
                        </div>
                    </ng-container>
                    <ng-container *ngIf="countdownDone">
                        <div
                            class="resend-code"
                            (click)="!disabled && resendCode()"
                            [ngClass]="{ 'disable-click' : disabled}"
                            accessible
                        >
                            {{'did_not_arrive_resend' | translate}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
    