import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../../_core/SiteListsBase';

import { LocationService } from '../../_core/location.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { ORGANIZATION_BUCKET_TYPES } from '../../_core/OrganizationBucket';
import { AppService } from '../../app.service';
import { WebContainerService } from '../web-container.service';
import { MetaService } from '../../_core/meta.service';
import { EntityService } from '../../_core/entity.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
    selector: 'app-web-sites',
    templateUrl: './web-sites.component.html',
    styleUrls: ['./web-sites.component.scss']
})
export class WebSitesComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.search) implements OnInit, OnDestroy {

    private baseSubscription: Subscription;
    private loadMoreSitesSubscription: Subscription;
    private webSelectionBarSearchStateSubscription: Subscription;
    private coreSubscription: Subscription;

    private searchInitiatedByUser: boolean = false;

    constructor(
        protected appService: AppService,
        private locationService: LocationService,
        protected organizationsService: OrganizationsService,
        private webContainerService: WebContainerService,
        private activatedRoute: ActivatedRoute,
        private metaService: MetaService,
        private entityService: EntityService,
        protected liveAnnouncer: LiveAnnouncer,
    ) { super(); }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        })

        let defaulSearchType = this.getSearchType();

        // React to "search" click on Web Selection Bar:
        this.webSelectionBarSearchStateSubscription = this.webContainerService.webSelectionSearchEvent.subscribe((searching: boolean) => {
            this.searchInitiatedByUser = searching;
        });

        this.initialize();

        // Subscribing to the loadMoreSites Obserable Subject (which decides that once the web-container reaches the bottom, on MOBILE devices, we need to load more sites)
        this.loadMoreSitesSubscription = this.webContainerService.loadMoreSitesObservable.subscribe(loadMoreSites => loadMoreSites ? this.loadMore() : false);

        this.baseSubscription = combineLatest([
            this.organizationsService.initialOrganizationsLoaded,
            this.locationService.location,
            this.activatedRoute.queryParams,
            this.webContainerService.getSharedSearch(defaulSearchType)
        ]).subscribe(([
            initialOrgsLoaded,
            newLocation,
            queryParams,
            searchDetailsFromShared
        ]) => {
            // console.log('=== WEB/SITES === News: initialOrgsLoaded:', initialOrgsLoaded, 'queryParams:', queryParams, 'searchDetailsFromShared:', searchDetailsFromShared, 'searchInitiatedByUser:', this.searchInitiatedByUser);
            if (!initialOrgsLoaded || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails(searchDetailsFromShared);

            // Since the location presets, the area is not looking at for URL params,
            // indeed we may need to bring it back:
            if (queryParams && queryParams.tag) {

                // When query params, the search will managed only by it (on related fields):
                if (nextSearchDetails.tags) delete nextSearchDetails.tags;

                let tagId = queryParams.tag;
                if (tagId) nextSearchDetails.tags = [tagId];
            }

            if (this.orgs.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, defaulSearchType)) {

                this.searching = false;
                this.manageScroll();

            } else {

                this.newSearch(nextSearchDetails, () => {
                    this.manageScroll();
                });
            }

        });

        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO(this.activatedRoute.pathFromRoot);
    }

    protected manageScroll(): void {

        // TODO: Bring the timeout to here:

        let defaulSearchType = ORGANIZATION_BUCKET_TYPES.search;

        // Note: When searching directly from the homepage - the webSelectionSearchEvent doesn't yet exist - so we must rely on the getMainSearchQuery() in order to determine if we have a search and we need to auto scroll to the results.
        if (this.organizationsService.getLastVisited(defaulSearchType)) {
            // Scroll to last visited org:
            this.webContainerService.scrollToLastVisitedOrg(this.organizationsService.getLastVisited(defaulSearchType));
        } else {
            // Scrolling the results into View
            this.webContainerService.scrollToResults(this.searchInitiatedByUser || this.webContainerService.getMainSearchQuery());
        }
    }

    siteClick(siteId: string) {
        let site = this.organizationsService.getOrganization(siteId);
        let defaulSearchType = ORGANIZATION_BUCKET_TYPES.search;
        this.organizationsService.setLastVisited(siteId, defaulSearchType);
        this.appService.redirect(['/site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    ngOnDestroy() {
        this.baseSubscription.unsubscribe();
        this.loadMoreSitesSubscription.unsubscribe();
        this.webSelectionBarSearchStateSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        this.cleanUp();
    }
}
