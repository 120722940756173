
<block-ui>

    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1">{{'my_benefits' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
        </div>
    </div>

    <div class="module-content wl-app-primary-background-color">
        <mat-tab-group class="generic-tab-group">
            <mat-tab label="{{'my_benefits' | translate}}">
                <mat-accordion displayMode="flat" class="no-margins generic-list-accordion">
                    <mat-expansion-panel *ngFor="let club of clubsData" [expanded]="appService.skin">
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{club.clubName}}</mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-list class="generic-list">
                            <benefit-card [club]="club" [points]="club.points[0].points" [benefit]="club.pointsBenefit" *ngIf="club.points[0] && club.points[0].points > 0"></benefit-card>
                            <benefit-card [club]="club" [prePaidPoints]="club.points[0].prePaid" [benefit]="club.pointsBenefit" *ngIf="loyaltyService.checkPrepaidFlow(club?.points[0]?.prePaid)"></benefit-card>
                            <benefit-card *ngFor="let benefit of club.benefits" [club]="club" [benefit]="benefit"></benefit-card>
                        </mat-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
        </mat-tab-group>

        <div class="keyboard-spacer"></div>
    </div>
</block-ui>
