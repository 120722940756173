<block-ui>
    <div class="module-bar trans">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="goBack()">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate" preserveTextDirectionByLocale fontSizeByTextLength>{{siteName}}</span>
            <notifications-widget-opener></notifications-widget-opener>
            <button mat-button color="accent" (click)="close(true)">{{(step === 3 ? 'close' : 'Cancel') | translate}}</button>
        </div>
    </div>
    <div class="module-content d-flex flex-column" *ngIf="!loading">
        <mat-tab-group class="wiz-tabs full-height hide-headers" [(selectedIndex)]="step" [animationDuration]="'0ms'">
            <mat-tab label="start" *ngIf="bookService.validBookingDetails(bookService.$storage)">
                <app-tabitbook-start (clickSubmit)="postTempReservation(null, bookService.$storage.site)" [editorToSet]="editorToSet" *ngIf="step === 0"></app-tabitbook-start>
            </mat-tab>

            <mat-tab label="alternate">
                <app-tabitbook-alt [options]="childOptions" *ngIf="step === 1"></app-tabitbook-alt>
            </mat-tab>

            <mat-tab label="details">
                <app-tabitbook-details [options]="childOptions" [go-to-start]="bindedGotoStart" *ngIf="step === 2"></app-tabitbook-details>
            </mat-tab>

            <mat-tab label="end">
                <app-tabitbook-end [options]="childOptions" *ngIf="step === 3"></app-tabitbook-end>
            </mat-tab>
        </mat-tab-group>
    </div>
</block-ui>