<div class="d-flex flex-column h-100">
    <div class="generic-desc text-center">{{'MESSAGES.ENTER_ORDER_NO_INFO' | translate}}</div>
    <div class="flex-grow-1 text-center">
        <div class="px-3 pt-3">
            <mat-form-field class="form-field-block-light">
                <mat-label>{{'enter_order_no' | translate}}</mat-label>
                <input [ngModel]="orderNO" (ngModelChange)="onOrderNoChange($event)" name="orderNumber" matInput required>
            </mat-form-field>
        </div>
    </div>
    <div class="p-3 pb-4">
        <button mat-flat-button color="primary" class="lg rounded btn-block"  type="button" (click)="lookUpOrder()" id="btn-goto-bill">
            {{'continue' | translate}}
        </button>
    </div>
</div>

