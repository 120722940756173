import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'order-tracker-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './order-tracker-header.component.html',
    styleUrls: ['./order-tracker-header.component.scss']
})
export class OrderTrackerHeaderComponent implements OnInit {

    @Input() public user: any;
    @Input() public org: any;
    @Input() public order: any;
    @Input() public tab: string;

    constructor() { }

    ngOnInit() { }

}
