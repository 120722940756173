import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../app.service';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

import { assignIn, get } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})

export class OrderTrackerService {

    public appConfig: any = environment.appConfig;
    public displayMode = new BehaviorSubject<string>('');

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private storageService: StorageService,
    ) { }

    public getOrderById(orderId: string, organizationId: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/orders/${orderId}`;

        const requestParams = assignIn({}, this.appService.appHttpOptions, {
            params: { organizationId }
        });

        return this.http.get(url, requestParams).pipe(
            catchError(err => {
                console.error('Handling error locally and rethrowing it...', err);
                return throwError(err);
            })
        );
    }

    public sendOrdererNotes(notes: string, organizationId: string, orderId: string, locationNotes?: string) {
        let formattedNote = (locationNotes && locationNotes.length > 0) ? this.updateNotesHour(notes, locationNotes) : this.updateNotesHour(notes);
        let url = `${this.appConfig.tabitBridge}/orders/notes`;
        return this.http.post(url, { notes: formattedNote, organizationId, orderId }, this.appService.appHttpOptions);
    }

    public setImHere(orderId: string) {
        let orderComments: any[] = JSON.parse(this.storageService.getItem('customer_order_comment')) || [];
        let index = orderComments.indexOf(orderId);
        if (index < 0) {
            orderComments.push(orderId);
        }
        // console.log('=== ORDER-TRACKER/SERVICE-setImHere ===', orderComments);

        this.storageService.setItem('customer_order_comment', JSON.stringify(orderComments));
    }

    public checkIfImHerePressed(orderId: string): boolean {
        let orderComments: any[] = JSON.parse(this.storageService.getItem('customer_order_comment')) || [];
        let index = orderComments.indexOf(orderId);
        if (index >= 0) {
            return true;
        }
        return false;
    }

    public setDisplayMode(mode) {
        this.displayMode.next(mode);
    }

    public getUserAddress(order) {
        let userAddress;
        const deliveryAddress = get(order, 'orderer.deliveryAddress');
        if (deliveryAddress) {
            if (deliveryAddress.formatted_address) userAddress = deliveryAddress.formatted_address;
            else {
                if (!deliveryAddress.house || !deliveryAddress.street) userAddress = deliveryAddress.city;
                else userAddress = deliveryAddress.street + ' ' + deliveryAddress.house + ' ,' + deliveryAddress.city
            }
        }

        return userAddress;
    }

    public getCourierLocation(orderId: string, organizationId: string, courierId?: string): Observable<any> {
        const url = `${this.appConfig.deliveryServiceURL}/orders/${orderId}/location`;

        const params = {
            organizationId,
        }

        if (courierId) params['courierId'] = courierId;

        return this.http.get(url, {
            ...this.appService.appHttpOptions,
            params
        }).pipe(
            catchError(err => {
                console.error('Handling error locally and rethrowing it...', err);
                return throwError(err);
            })
        );
    }

    private updateNotesHour(notes: string, locatioNotes?: string) {

        let formattedNote = notes.replace(/(\r\n|\n|\r)/gm, ' '); //Remove line breaks from notes
        let formattedLocatioNotes;
        if (locatioNotes) {
            formattedLocatioNotes = locatioNotes.replace(/(\r\n|\n|\r)/gm, ' ');
        }

        formattedNote = locatioNotes ? formattedNote + '\n' + formattedLocatioNotes : formattedNote;

        return formattedNote;
    }

    public getOrderAnimations() {
        const paths = {
            'donut-loader': this.appService.getAnimationPath('donut-loader'),
            'got-your-order': this.appService.getAnimationPath('got-your-order'),
            'cooking-food': this.appService.getAnimationPath('cooking-food'),
            'chef-cooking': this.appService.getAnimationPath('chef-cooking'),
            'food-prepared': this.appService.getAnimationPath('food-prepared'),
            'food-takeaway-order': this.appService.getAnimationPath('food-takeaway-order'),
            'delivery-guy': this.appService.getAnimationPath('delivery-guy'),
            'order-delivered': this.appService.getAnimationPath('order-delivered'),
            'thumb-up': this.appService.getAnimationPath('thumb-up'),
            'order-declined': this.appService.getAnimationPath('order-declined'),
        }

        return paths;
    }

}
