<div class="new-restaurants">
    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1">{{'new_in_tabit' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
        </div>
    </div>
    <app-web-tiles-new-restaurants></app-web-tiles-new-restaurants>
</div>