import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppService, ACTION_TYPES } from '../app.service';
import { environment } from '../../environments/environment';

import { get, each, mapKeys, camelCase } from 'lodash-es';
import { giftCardDetails, loyaltyGiftCardSettings } from '../gift-cards/gift-cards.service';

export type JoinChannelOriginType = 'TabitApp' | 'TabitOrderApp' | 'TabitOrder' | 'WhiteLabel' | 'TabitOrderWhiteLabel' | 'GiftCardShop';

export interface JoinChannelOrigin {
    type: JoinChannelOriginType
}

@Injectable({
    providedIn: 'root'
})

export class LoyaltyService {

    public appConfig: any = environment.appConfig;
    public domain: any;
    public gcsAccountGuid: any;

    public JoinChannelMap = [
        { 'TabitApp': 'BF10B302-8152-4E78-B496-7AE7A7513983' },
        { 'TabitOrderApp': '7D4EC032-D248-41D2-9105-29335CB66ADC' },
        { 'TabitOrder': '82C4F1A1-9F1F-43F2-A42B-26F3C885734C' },
        { 'WhiteLabel': 'F33C287F-FBE4-4922-88F6-CD4A97FF57A9' },
        { 'TabitOrderWhiteLabel': 'E812AD56-83B7-4D69-89E1-2F426F5C601A' },
        { 'GiftCardShop': '2A160C20-4117-46B1-B446-CC4B7B7A4C4E' },
    ];

    private loyaltyHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'env': this.loyaltyHeadersEnv,
            'JoinChannelGuid': this.appConfig.loyaltyJoinChannelGUID,
            'siteId': this.appConfig.tabitDefaultOrganizationId,
            'client_id': this.appConfig.tabitClientID // ROS Client ID
        }),
    };

    constructor(
        private http: HttpClient,
        private appService: AppService,
    ) {
        this.appService.domain.subscribe(domain => {
            this.domain = domain;
            this.gcsAccountGuid = get(this.domain, 'defaults.giftCardShop.giftCardShopAccountGuid') || get(this.domain, 'clubIds[0]');
        });
    }

    public topUpEnabled(): boolean {
        if (get(this.domain, 'defaults.giftCardShop.topUpEnabled', false) && this.gcsAccountGuid) return true;
        return false;
    }

    public redirectToGiftCards(flow, args?) {
        if (!flow) return throwError('No flow provided');
        let params;

        switch (flow) {
			case "topUp":
				params = {
                    accountGuid: this.gcsAccountGuid,
                    topUp: 1,
                }
                break;
            case "create-card":
                params = {
                    accountGuid: args?.accountGuid || this.gcsAccountGuid,
                }
                if (args?.campaign) params.campaign = args.campaign;
                if (args?.marketplace) params.marketplace = args.marketplace;

                break;
        }

        this.appService.redirect(['gift-cards'], { queryParams: params });
    }

    public getShortCode(cardNumber: string, accountGuid: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/get-short-code`;
        let params: any = {};

        return this.http.get(url, {
            headers: {
                Authorization: this.getLoyaltyHeaderValue('Authorization'),
                cardNumber,
                accountGuid
            },
            params
        }).pipe(map(response => {
            console.debug(response)
            return response;
        }));
    }

    public getCustomerBenefits(organizationId: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/organizations/${organizationId}/clubs`;
        let params: any = {};

        if (this.appService.skin) {
            params.accountGuid = this.getLoyaltyHeaderValue('accountGuid');
            params.isWhiteLabel = true;
        }

        return this.http.get<{ clubs: any[], org: any }>(url, {
            headers: {
                Authorization: this.getLoyaltyHeaderValue('Authorization'),
            },
            params
        }).pipe(map(response => {
            // console.log('=== LOYALTY-SERVICE/benefits response ===', response);
            let benefits = [];
            let points = [];
            let clubName = '';
            let notPointsBenefitsNumber = 0;
            let pointsNickname = '';

            if (response?.clubs) {
                each(response.clubs, club => {
                    if (club.error) return;
                    if (club.benefits) {
                        notPointsBenefitsNumber = club.benefits.filter(benefit => benefit.programBenefitTypeAlias != 'PointsBenefit').length;
                        benefits = benefits.concat(club.benefits.map(benefit => ({ ...benefit })));
                    }
                    if (club.balance && (club.balance.points > 0 || this.checkPrepaidFlow(club.balance.prePaid))) points.push(club.balance);
                    if (!points?.length && notPointsBenefitsNumber == 0) benefits = [];
                    if (club.benefits || club.balance) clubName = club.clubName;
                    pointsNickname = get(club, 'configurations.accountSettings.pointsNickName', '');
                });

                return {
                    clubName,
                    points,
                    benefits,
                    pointsNickname
                };
            } else return {};
        }));
    }

    public getCustomerBenefitsArray(organizationId: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/organizations/${organizationId}/clubs`;
        let params: any = {};

        if (this.appService.skin) {
            params.accountGuid = this.getLoyaltyHeaderValue('accountGuid');
            params.isWhiteLabel = true;
        }

        return this.http.get<{ clubs: any[], org: any }>(url, {
            headers: {
                Authorization: this.getLoyaltyHeaderValue('Authorization'),
            },
            params
        }).pipe(map(response => {
            // console.log('=== LOYALTY-SERVICE/benefits response ===', response);
            let clubs = [];
            if (response?.clubs) {
                each(response.clubs, club => {
                    let benefits = [];
                    let points = [];
                    let clubName = '';
                    let notPointsBenefitsNumber = 0;
                    let pointsNickname = '';
                    if (club.error) return;
                    if (club.benefits) {
                        notPointsBenefitsNumber = club.benefits.filter(benefit => benefit.programBenefitTypeAlias != 'PointsBenefit').length;
                        benefits = benefits.concat(club.benefits.map(benefit => ({ ...benefit })));
                    }
                    if (club.balance && (club.balance.points > 0 || this.checkPrepaidFlow(club.balance.prePaid))) points.push(club.balance);
                    if (!points?.length && notPointsBenefitsNumber == 0) benefits = [];
                    if (club.benefits || club.balance) clubName = club.clubName;
                    pointsNickname = get(club, 'configurations.accountSettings.pointsNickName', '');
                    clubs.push({
                        clubName,
                        points,
                        benefits,
                        pointsNickname
                    })
                });

                return clubs;
            } ;
        }));
    }

    public getCustomerClub(accountGuid: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/clubs/${accountGuid}`;
        let params: any = {};
        if (this.appService.skin) params.isWhiteLabel = true;

        return this.http.get<{ club: any }>(url, {
            headers: {
                Authorization: this.getLoyaltyHeaderValue('Authorization'),
            },
            params
        }).pipe(map(response => {
            // console.log('=== LOYALTY-SERVICE/club response ===', response);
            let benefits = [];
            let points = [];
            let clubName = '';
            let pointsNickname = '';

            if (response?.club) {
                let notPointsBenefitsNumber = 0;
                if (response.club?.benefits?.length) {
                    notPointsBenefitsNumber = response.club.benefits.filter(benefit => benefit.programBenefitTypeAlias != 'PointsBenefit').length;
                    benefits = benefits.concat(response.club.benefits.map(benefit => ({ ...benefit })));
                }
                if (response.club.balance?.points > 0 || this.checkPrepaidFlow(response.club.balance?.prePaid)) points.push(response.club.balance);
                // We must do it like this to prevent displaying empty club card
                // when there is only PointsBenefit benefit without any points
                if (!points?.length && notPointsBenefitsNumber == 0) benefits = [];
                if (response.club.benefits || response.club.balance) clubName = response.club.clubName;
                clubName = (response.club.clubName) ? response.club.clubName : 'benefits_club';
                pointsNickname = get(response.club, 'pointsNickname', '');

                return {
                    clubName,
                    points,
                    benefits,
                    pointsNickname
                };
            } else return {};
        }));
    }

    public getMultiAccountData(): Observable<any> {
        //TODO - That whole method is modifying the response horribly
        //just to display the data properly, we would need to change
        //it after Loyalty would send us benefits (Especially PointsBenefits)
        const url = `${this.appConfig.tabitBridge}/loyalty/multiAccountData`;

        return this.http.get<{ multiAccount: any }>(url, {
            headers: {
                Authorization: this.getLoyaltyHeaderValue('Authorization'),
            }
        }).pipe(map(response => {
            // console.log('=== LOYALTY-SERVICE/multiAccount ===', response.multiAccount);
            return this.setMultiAccountData(response);
        }));

    }

    private setMultiAccountData(response: any) {
        let multiAccount = [];

        response.multiAccount.forEach(account => {
            // console.log('=== LOYALTY-SERVICE/account ===', account);
            let responseAccount = {};
            let benefits = [];
            let points = [];
            let clubName = '';
            let pointsNickname = '';

            if (account?.vouchers?.length) {
                benefits = benefits.concat(account.vouchers.map(voucher => ({   programBenefitTypeAlias: 'Vouchers',
                                                                                description: voucher.name,
                                                                                moreInfo: { expired: voucher.expiration }
                                        })));
            }

            if (account.pointsBalance && (account.pointsBalance > 0 || account.prePaidBalance > 0)) points.push({ points: account.pointsBalance || 0, prePaid: account.prePaidBalance || 0 });
            clubName = account.accountName ? account.accountName : this.appService.translate('benefits_club');
            pointsNickname = account.pointsNickname || '';

            responseAccount = {
                                clubName,
                                pointsNickname,
                                points,
                                benefits,
            } || {};

            multiAccount.push(responseAccount);

        });

        return multiAccount || [];
    }

    public checkPrepaidFlow(prePaid): boolean {
        if (get(this.domain, 'defaults.giftCardShop.topUpEnabled', false)) return prePaid >= 0;
        return prePaid > 0;
    }

    // Get data of the gift card shop belonging to the given accountGuid.
    public getGiftCardsConfig(accountGuid: string): Observable<loyaltyGiftCardSettings> {
        const url = `${this.appConfig.tabitBridge}/loyalty/gift-cards/config/${accountGuid}`;

        return this.http.get<any>(url).pipe(map(response => {
            // turn response body keys to camelCase (Loyalty API returns data in upper first case)
            response = mapKeys(response, (v, k) => camelCase(k));
            return response;
        }));
    }

    public getGiftCardAccountGuid(domain: any): string {
        return get(domain, 'defaults.giftCardShop.giftCardShopAccountGuid') || get(domain, 'clubIds[0]') || '';
    }

    public getGiftCardDomainConfig(domain: any): string {
        return get(domain, 'defaults.giftCardShop', null);
    }

    // Get the gift card data for the landing page, from which the customer can use their gift card.
    public getGiftCardDetails(giftCardId: string): Observable<giftCardDetails> {
        const url = `${this.appConfig.tabitBridge}/loyalty/gift-cards/get-gift-card/${giftCardId}`;

        return this.http.get<any>(url).pipe(map(response => {
            // console.log('=== LOYALTY-SERVICE/getGiftCardDetails ===', response);

            if (get(response, 'ResponseData.0')) {
                // turn response body keys to camelCase (Loyalty API returns data in upper first case)
                response = mapKeys(response.ResponseData[0], (v, k) => camelCase(k));
                return response;
            } else {
                return {}
            }
        }));
    }

    // Creates a media exchange order in ROS and returns the payment method link and the order ID.
    public getGiftCardPaymentLink(requestBody: any, accountGuid: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/gift-cards/get-payment-link/${accountGuid}`;

        // Get the reCaptcha token asynchronously
        return this.appService.processRequestWithCaptcha(ACTION_TYPES.PAYMENT_LINK, url, requestBody).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // Purchase a gift card from the Loyalty gift card shop.
    public buyGiftCard(requestBody: any, accountGuid: string): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/gift-cards/buy-gift-card/${accountGuid}`;

        // Get the reCaptcha token asynchronously
        return this.appService.processRequestWithCaptcha(ACTION_TYPES.PAYMENT_SUBMIT, url, requestBody).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getLoyaltyHeaders(origin?: JoinChannelOrigin, specificSiteId?: string): any {
        // If no specific origin then we use the old logic..
        const type = this.appService.joinChannelOriginType || (origin ? origin.type : (this.appService.skin ? 'WhiteLabel' : 'TabitApp'));
        const siteId = specificSiteId ? specificSiteId : this.appConfig.tabitDefaultOrganizationId;

        // Here we handle the dynamic headers (JoinChannelGuid - by origin, siteId - by site (Only for TO))
        this.setLoyaltyHeaders('JoinChannelGuid', this.getJoinChannelValue(type));
        this.setLoyaltyHeaders('siteId', siteId);

        if (this.appService.skin) this.setLoyaltyHeaders('White-Label', this.appService.skin);
        if (this.domain?._id) this.setLoyaltyHeaders('domainId', this.domain?._id);

        return this.loyaltyHttpOptions;
    }

    public getLoyaltyHeadersForWL(origin?: JoinChannelOrigin, specificSiteId?: string): any {
        // If no specific origin then we use the old logic..
        const type = this.appService.joinChannelOriginType || (origin ? origin.type : 'WhiteLabel');
        const siteId = specificSiteId ? specificSiteId : this.appConfig.tabitDefaultOrganizationId;

        const loyaltyHttpOptions = {
            headers: new HttpHeaders({
                'Authorization': this.getLoyaltyHeaderValue('Authorization'),
                'Content-Type': 'application/json',
                'env': this.loyaltyHeadersEnv,
                'JoinChannelGuid': this.getJoinChannelValue(type),
                'siteId': siteId,
                'client_id': this.appConfig.tabitClientID, // ROS Client ID
                'accountGuid': this.getLoyaltyHeaderValue('accountGuid') || '',
                'White-Label': this.appService.skin || '',
            }),
        };

        return loyaltyHttpOptions;
    }

    public setLoyaltyHeaders(header: string, value: any) {
        if (!header || !value) throw new Error('No header to update');
        this.loyaltyHttpOptions.headers = this.loyaltyHttpOptions.headers.set(header, value);
    }

    public getLoyaltyHeaderValue(header: string) {
        if (!header) throw new Error('No header to return');
        return this.loyaltyHttpOptions.headers.get(header);
    }

    public getJoinChannelValue(type): string {
        const joinChannel = this.JoinChannelMap.find(channel => channel[type]);
        return joinChannel[type];
    }

    public getJoinChannelKey(value): JoinChannelOriginType {
        const entries = this.JoinChannelMap.reduce((acc, channel) => {
            return acc.concat(Object.entries(channel));
        }, []);

        const key = entries.find(([_, v]) => v === value)?.[0];

        return key;
    }

    public setBorderColor(type: string) {
        if (this.appService.skin) {
            // We return the color from the theme
            const benefitBorderColor = get(this.domain.theme, 'loyalty.benefitBorderColor');
            if (benefitBorderColor) return benefitBorderColor;

            switch (this.appService.skin) {
                case "kfc":
                    return '#e31e2d';
                case "shiou":
                    return '#b2cb07';
                case "foodba":
                    return '#e52528';
                case "biga":
                    return '#be984b';
                case "maison":
                    return '#eb5b25';
                case "aromatlv":
                    return '#EE1C25';
                case "greco":
                    return '#7DD7FF';
                case "arcaffe":
                    return '#F3F3F3';
                default:
                    return '#B1B2E7';
            }
        } else {
            switch (type) {
                case "Points":
                case "PointsBenefit":
                    return '#60d9db';
                case "BirthdayBenefit":
                    return '#53A9DF';
                case "AnniversaryBenefit":
                    return '#FFD455';
                case "JoinBenefit":
                case "RenewBenefit":
                    return '#FFB185';
                case "Vouchers":
                    return '#7882BA';
                case "PunchCard":
                    return '#BDF9D4';
                case "Other":
                case "PointsPrePaid":
                default:
                    return '#B1B2E7';
            }
        }
    }

    public setBorderByType(type: string) {
        const color = this.setBorderColor(type);
        return this.appService.localeId === 'he-IL' ? { 'border-right' : '0.5rem solid ' + color } : { 'border-left' : '0.5rem solid ' + color };
    }

    // Needed for deposit payment feature (RSV)
    public getLoyaltyVoucherDetails(accountGuid: string, orderId: string): Observable<any> {
        return this.http.get(`${this.appConfig.tabitBridge}/loyalty/gift-cards/get-voucher-details/${accountGuid}/${orderId}`);
    }

    // Needed for deposit payment feature (RSV)
    public unloadLoyaltyCard(accountGuid: string, orderId: string, organizationId: string, reservationId: string): Observable<any> {
        return this.http.post(`${this.appConfig.tabitBridge}/loyalty/gift-cards/unload-card`, { accountGuid, orderId, organizationId, reservationId });
    }

    // Needed for Anonymous Otp SMS Verification Code
    public getAnonymousOtpVerificationCode(requestBody): Observable<any> {
        const url = `${this.appConfig.tabitBridge}/loyalty/gift-cards/anonymousOTP`;

        // Get the reCaptcha token asynchronously
        return this.appService.processRequestWithCaptcha(ACTION_TYPES.PAYMENT_LINK, url, requestBody).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // Needed for Verification Otp SMS Code.
    public confirmOtp(payload: any, accountGuid: string, siteId: string): Observable<any> {
        return this.http.post(`${this.appConfig.tabitBridge}/loyalty/gift-cards/otp-manager/${accountGuid}/${siteId}`, payload);
    }

    get loyaltyHeadersEnv() {
        switch(this.appConfig.locale) {
            case "he-IL":
                return 'il';
            case "en-US":
                return environment.env == 'us-dmo' ? 'us-demo' : 'us'
            case "en-AU":
                return 'au';
            default:
                return 'il';
        }
    }

    public getMarketplaceOrganizations(): Observable<any> {
        return this.http.get<any>(`${this.appConfig.tabitBridge}/loyalty/marketplace`, this.loyaltyHttpOptions);
    }

    public getOrderProcessValidation(payload: any, accountGuid): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<{}>(`${this.appConfig.tabitBridge}/validation/${accountGuid}`, payload, this.appService.appHttpOptions)
                .subscribe(
                    (results: any) => {
                        resolve(results);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }
}
