<block-ui>
    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="close()">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate">{{'SERVICE_BUTTON.pay' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
            <button class="menu" mat-icon-button (click)="close()">
                <mat-icon class="close">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="preloader-scan-wrapper" (contextmenu)="simulateScanner($event)">
        <div class="preloader-scan" *ngIf="scanning">
            <zxing-scanner
                #scanner
                [enable]="true"
                [(device)]="selectedDevice"
                [formats]="formatsEnabled"
                (statusChange)="onStatusChange($event)"
                (scanSuccess)="extractFromQR($event)"
                style="width: 100%; height: 100%;"
            >
            </zxing-scanner>
            <div class="_info-bottom">
                <button mat-flat-button color="primary" (click)="manualCamera()">
                    <mat-icon aria-label="Flash">{{ toggleFlashIcon }}</mat-icon>
                </button>
                <button class="wl-cards-background-color" mat-flat-button (click)="gotoManualEntry()">{{'MESSAGES.TABIT_PAY_MANUAL_ENTER_FOR_SITE' | translate}}</button>
            </div>
            <div class="diode">
                <div class="laser"></div>
            </div>
        </div>
    </div>
</block-ui>