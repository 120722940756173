<div mat-dialog-content>
    {{ 'MESSAGES.THE_SERVICE_BEGIN_AT' | translate }} {{ getBeginTime() }}, {{ 'MESSAGES.SERVICE_PREORDER_DONT_WORRY' | translate }}
</div>
<div mat-dialog-actions>
    <button type="button" mat-flat-button color="accent" [mat-dialog-close]="true" class="rounded">
        {{ 'OK' | translate}}
    </button>
    <button type="button" mat-stroked-button color="accent" [mat-dialog-close]="false" class="rounded">
        {{ 'Cancel' | translate}}
    </button>
</div>
