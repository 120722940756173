import { Component, OnInit } from '@angular/core';

import { AppService } from '../app.service';

@Component({
    selector: 'app-new-restaurants',
    templateUrl: './new-restaurants.component.html',
    styleUrls: ['./new-restaurants.component.scss']
})
export class NewRestaurantsComponent implements OnInit {

    constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
