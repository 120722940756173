<div class="dialog-container">
    <div class="dialog-top-section">

        <div class="dialog-title">
            {{'loyalty-terms-dialog.title' | translate}}
            <span><mat-icon> sentiment_satisfied_alt</mat-icon></span>
        </div>
        <div class="dialog-text">
            {{'loyalty-terms-dialog.descriptionMain' | translate}}
        </div>
        <div class="dialog-text">
            {{'loyalty-terms-dialog.descriptionSecond' | translate}}
        </div>
    </div>

    <div class="dialog-bottom-section">
        <form (ngSubmit)="apply()" [formGroup]="formGroup">
            <div mat-dialog-content>
                <div class="mb-2">
                    <mat-checkbox class="example-margin" formControlName="IsFilledJoinForm">
                    </mat-checkbox>
                    <span class="consent-text-container">
                        {{'i_approve_the' | translate}}
                        <span class="loyalty-terms" *ngIf="loyaltyTermsURL">
                            <a (click)="openLink('loyaltyTerms')">{{'club_terms' | translate}}</a>{{', '}}
                        </span>
                        <a class="privacy-policy" (click)="openLink('privacy')">
                            {{'privacy_policy' | translate}}
                        </a>
                        {{'and' | translate}}
                        <a class="terms-and-conditions" (click)="openLink('terms')">
                            {{'terms_and_conditions' | translate}}
                        </a>
                    </span>
                </div>
                <div class="mb-2">
                    <mat-checkbox class="example-margin" formControlName="IsConfirmSms">
                    </mat-checkbox>
                    {{'approve_commercials' | translate}}
                </div>
            </div>
            <ng-container *ngIf="toggleConfirmSms && !formGroup.value.IsConfirmSms">
                <div class="dialog-text dialog-validation">
                    {{'loyalty-terms-dialog.validation' | translate}}
                </div>
            </ng-container>
            <div class="page-actions">
                <button 
                [disabled]="!formGroup.value.IsFilledJoinForm" 
                mat-flat-button 
                color="primary"
                class="rounded primary-action-button continue-button"
                type="submit">
                    {{ primaryButtonText | translate }}
                </button>
            </div>
        
        </form>

    </div>

</div>
