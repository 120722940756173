import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../app.service';

@Component({
    selector: 'multiple-choice',
    templateUrl: './multiple-choice.component.html',
    styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {

    selectedOption: any;

    constructor(
        public appService: AppService,
        public dialogRef: MatDialogRef<MultipleChoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.selectedOption = this.data?.options[0];
    }

    chooseOption(option) {
        this.selectedOption = option;
        this.dialogRef.close(option);
    }

}
