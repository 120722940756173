<div class="module-bar">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{'occasions.events' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<events-list></events-list>