<div class="header-tab-search" accessible (click)="toggleSearchFieldAndFocusOnInput()" [attr.aria-label]="'restaurants_search' | translate" [class.open]="searchFieldOpen" [@toggleHeaderSearchField]="searchFieldOpen ? (languageActive ? 'partly-opened' : 'opened') : 'closed'">
    <!-- Note: the "action" is required in order for the Mobile device browser to respect the input type "search" and show the "search" button on the keyboard -->
    <form action="" [formGroup]="searchForm" (ngSubmit)="search($event)">
        <input type="search" #searchQueryInput formControlName="searchQuery" [(ngModel)]="searchQuery" maxlength="100" class="header-tab-search-input" placeholder="{{'restaurants_search' | translate}}" (keydown.enter)="$event.stopPropagation()" (keyup)="handleSearchFieldKeyUp($event)" (click)="$event.stopPropagation()">
        <button type="submit" *ngIf="searchQuery && searchFieldOpen" class="header-tab-search-button" mat-button color="primary" (click)="$event.stopPropagation()">{{'WEB_CONTAINER.search_now' | translate}}</button>
    </form>
    <mat-icon *ngIf="!searchFieldOpen" [attr.aria-label]="'restaurants_search' | translate" class="header-tab-search-icon">search</mat-icon>
    <mat-icon *ngIf="!searchQuery && searchFieldOpen" [attr.aria-label]="'ACCESS.close' | translate" class="header-tab-search-icon">close</mat-icon>
    <mat-icon accessible *ngIf="searchQuery && searchFieldOpen" [attr.aria-label]="'ACCESS.close' | translate" class="header-tab-search-icon" (click)="clearSearchQuery($event)">close</mat-icon>
</div>
<div *ngIf="languageActive" class="header-tab-language language" accessible [attr.aria-label]="'WEB_CONTAINER.language_select' | translate">
    <div class="language-switcher">
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="module-bar-language-icon globe-icon" [svgIcon]="'globe'"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" aria-label="{{'ACCESS.language_screen_reader' | translate}}"
        accessible>
            <button mat-menu-item *ngFor="let language of filteredLanguages" (click)="changeUsedLanguage(language.value)" [class]="selectedLanguageValue === language.value ? 'to-language-menu-selected':''">
            {{ language.viewValue }}
            </button>
        </mat-menu>
    </div>
</div>