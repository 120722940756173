import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

import { SiteListsBase } from '../_core/SiteListsBase';
import { OrganizationsService } from '../_core/organizations.service';
import { SearchDetails, ORGANIZATION_BUCKET_TYPES } from '../_core/OrganizationBucket';
import { AppService } from '../app.service';
import { DialogsService } from '../_core/dialogs.service';
import { EntityService } from '../_core/entity.service';
import { LocationService } from '../_core/location.service';

import { LiveAnnouncer } from '@angular/cdk/a11y';

declare const $: any; // For the scroll we are using jQuery

@Component({
    selector: 'app-sites-search',
    templateUrl: './sites-search.component.html',
    styleUrls: ['./sites-search.component.scss']
})
export class SitesSearchComponent extends SiteListsBase(ORGANIZATION_BUCKET_TYPES.search) implements OnInit, OnDestroy {

    public title: string = 'search';
    public favoriteOrgs: any[] = [];
    public filterFields = {
        rating: true,
        price: true,
        onlyTabit: false, // Removed for now
        area: true,
        tags: true
    };

    private mainLoadingSubscription: Subscription;
    private coreSubscription: Subscription;

    constructor(
        protected appService: AppService,
        private locationService: LocationService,
        protected organizationsService: OrganizationsService,
        private dialogsService: DialogsService,
        private entityService: EntityService,
        private activatedRoute: ActivatedRoute,
        protected liveAnnouncer: LiveAnnouncer,
        private router: Router,
    ) { super() }

    ngOnInit() {

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData()
        })
        
        this.initialize();

        this.mainLoadingSubscription = combineLatest([
            this.organizationsService.initialOrganizationsLoaded,
            this.activatedRoute.queryParams,
            this.locationService.location
        ]).subscribe(([loaded, queryParams, newLocation]) => {
            if (!loaded || !newLocation) return;

            let nextSearchDetails = this.prepareNextSearchDetails();

            // Since the location presets, the area is not looking at for URL params,
            // indeed we may need to bring it back:
            if (queryParams && queryParams.tag) {

                // When query params, the search will managed only by it (on related fields):
                if (nextSearchDetails.tags) delete nextSearchDetails.tags;
                nextSearchDetails.tags = [queryParams.tag];
            }

            if (this.orgs.length && this.organizationsService.isSearchEqualToLastSearch(nextSearchDetails, this.getSearchType())) {

                this.searching = false;
                this.manageScroll();

            } else {

                this.newSearch(nextSearchDetails, () => {
                    this.manageScroll();
                });

            }

        });
    }

    ngOnDestroy() {
        this.coreSubscription.unsubscribe();
        this.mainLoadingSubscription.unsubscribe();
        this.cleanUp();

    }

    searchChanged(newSearchDetails: SearchDetails) {
        let searchDetails: SearchDetails = { ...newSearchDetails };
        this.newSearch(searchDetails);
    }

    siteClick(site) {
        this.organizationsService.searchScreenNeedsScroll = true;
        this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    sitePress(site, ev) {
        // 2019-09-23 - Deprecated per Barry's request
        // 2019-12-24 - Re-enabled because in Delivery the tap redirects directly to  Orderinsg, and we want to let the user the ability to go into the Site Details too.
        this.dialogsService.showSiteActionsDialog(site, ev, this.router);
    }

}
