<!--
<div class="corona-container">
    <div class="internal-container">
        <h1>תכלס אין כמו בבית...</h1>
        <h2>אז בא לך לבוא לקחת? אולי משלוח?</h2>
        
        <div class="image-container">
            <img [src]="appService.base('assets/images/iStock-182190331.jpg')" />
        </div>
        
        <div class="delivery-selection-bar">
            <div class="button takeaway" (click)="webContainerService.searchDelivery('takeaway', selectedLocationType)">איסוף עצמי</div>
            <div class="button delivery" (click)="webContainerService.searchDelivery('delivery', selectedLocationType)">משלוח</div>
        </div>
        <div class="location-widget-wrapper">
            <location-widget></location-widget>
        </div>
    </div>
</div>
-->

<web-header class="booking-web-header">
    <web-book-header webSearchType="booking"></web-book-header>
</web-header>

<site-list-map
    [type]="'booking'"
    [sites]="searching ? [] : orgs"
    [initialSearch]="orgs.length == 0 || searching ? true : false"
    [preventSearchMore]="preventSearchMore"
    [showTimeSlots]="true"
    (siteClick)="siteClick($event)"
    (scrollReachedBottom)="loadMore()"
></site-list-map>