<a [routerLink]="articleLink" class="article-container" [style.color]="webArticle.articles[articleId].textColor" [style.background-color]="webArticle.articles[articleId].backgroundColor">
    <div class="image-section" [ngStyle]="{'background-image':'url('+webArticle.articles[articleId].header.photoUrl+')'}"></div>
    <div class="text-section">
        <div class="text-section-title">
            {{webArticle.articles[articleId].header.title}}
        </div>
        <div class="text-section-content">
            {{webArticle.articles[articleId].header.subTitle}} ›››
        </div>
    </div>
</a>