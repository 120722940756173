<div class="benefit-container">
    <div class="animation-container" *ngIf="showBenefitAnimation">
        <ng-lottie (loopComplete)="closeAnimation()" [options]="{path: benefitAnimation, autoplay: true, loop: true }" [height]="300"></ng-lottie>
    </div>
    <div class="animation-container" *ngIf="showPointAnimation">
        <ng-lottie (loopComplete)="closeAnimation()" [options]="{path: pointsAnimation, autoplay: true, loop: true }" [height]="300"></ng-lottie>
    </div>
    <div class="benefit-title-container" [style.background-color]="setTitleColor()">
        <div class="title-header">
            <button aria-label="close" mat-button class="close" [mat-dialog-close]="true">
                <mat-icon>close</mat-icon>
            </button>
            <div class="expiration-container" *ngIf="showDate">
                <div class="_title">{{'valid_until' | translate}}</div>
                <div class="_date">{{ data.benefit.moreInfo.expired | date: 'd MMM y': '': appService.localeId}}</div>
            </div>
        </div>
        <div class="description-wrapper">
            <div class="icon" [style.backgroundImage]="icon | safeStyle"></div>
            <ng-container *ngIf="type === 'Points'">
                <ng-container *ngIf="data.prePaid">
                    <div class="benefit-title">{{data.points[0].prePaid}} {{currencySign}}</div>
                    <div class="benefit-title">{{details.headerForApp}}!</div>
                </ng-container>
                <ng-container *ngIf="!data.prePaid">
                    <div class="benefit-title">{{data.points[0].points}} {{pointsNickname}}</div>
                    <div class="benefit-title">{{details.headerForApp}}!</div>
                </ng-container>
            </ng-container>
            <div *ngIf="type != 'Points'" class="benefit-title">{{details.headerForApp | translate}}</div>
        </div>
    </div>
    <div class="header-data" *ngIf="details?.description">
        <div class="benefit-description" [class.topUp]="loyaltyService.topUpEnabled()" [style.background-color]="setAdditionalTitleColor()">
            {{details.description}}
        </div>
    </div>
    <div class="message-container" *ngIf="details?.messageForApp">
        <div class="message">{{details.messageForApp}}</div>
    </div>
    <div class="types-container" *ngIf="details.orderTypes?.length">
        <div class="type" *ngFor="let type of details.orderTypes">
            <mat-icon [svgIcon]="getIconByType(type)"></mat-icon>
            <div class="type-text">{{type | translate}}</div>
        </div>
    </div>
    <div class="separator-container">
        <div class="icon-separator" [style.background-image]="appService.images.benefit_waves | safeStyle"></div>
    </div>
    <!-- 27.12.2021 - Decided by Idan to depracate for now -->
    <!-- <div [@sitesAnimation] *ngIf="(sites | async)?.length" class="sites-list">
        <div class="title">{{'sites_nearby' | translate}}</div>
        <div class="site-row" *ngFor="let site of (sites | async) | filterBy: {_id: true} | orderBy: 'distance' | slice:0:4">
            <mat-icon class="_icon" svgIcon="map_pin_other"></mat-icon>
            <div class="site-info">
                <div class="name">{{site.name}}</div>
                <span *ngIf="site.formattedDistance === 'far_away'" class="distance">({{site.formattedDistance | translate}} {{'from_you' | translate}})</span>
                <span *ngIf="site.formattedDistance !== 'far_away'" class="distance">({{site.formattedDistance | translate}} {{unit | translate}} {{'from_you' | translate}})</span>
            </div>
        </div>
    </div>
    <div @spinnerAnimation *ngIf="!(sites | async)?.length && loadingSites" class="sites-spinner">
        <mat-spinner diameter="60" strokeWidth="5" class="light-grey"></mat-spinner>
    </div> -->
</div>
