<form (ngSubmit)="_verifyCode(verifyCodeForm)" #verifyCodeForm="ngForm" [formGroup]="formGroup">
    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
                <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div tabindex="0" class="title">{{'MESSAGES.enter_code_message' | translate}} {{phone}}</div>
            <mat-form-field appearance="outline">
                <mat-label> {{'enter_code' | translate}} </mat-label>
                <input  autocomplete="off"
                        matInput
                        [readonly]="disabled"
                        [(ngModel)]="formModel.code"
                        [maxlength]="4"
                        formControlName="codeControl"
                        type="tel"
                        #codeInputElement
                        required
                        autocomplete="one-time-code"
                        class="ltr-field">
                <mat-icon matSuffix (click)="formModel.code = null" *ngIf="formModel.code">close</mat-icon>
            </mat-form-field>

            <div class="pt-2">
                <button 
                    class="btn btn-lg btn-trans btn-block" 
                    [class.dialog-button]="dialogMode" 
                    [disabled]="!validCodeLength || disabled" 
                    [ngClass]="{ 'disable-click' : !validCodeLength }"
                    type="submit">
                    <span *ngIf="!disabled" >{{'CONTINUE' | translate}}</span>
                    <mat-spinner *ngIf="disabled" diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </div>

            <div class="pt-2">
                <ng-container *ngIf="countdownValue$ | async as countdownValue">
                    <div
                        class="resend-code disable-click"
                        accessible
                    >
                        {{'did_not_arrive_resend' | translate}}
                        ({{ countdownValue }})
                    </div>
                </ng-container>
                <ng-container *ngIf="countdownDone">
                    <div
                        class="resend-code"
                        (click)="!disabled && !resending && _resendCode()"
                        [ngClass]="{ 'disable-click' : disabled }"
                        accessible
                    >
                        {{'did_not_arrive_resend' | translate}}
                    </div>
                </ng-container>
            </div>
            <div class="back-btn pt-2" *ngIf="!dialogMode">
                <button type="button" class="btn btn-lg btn-link wl-ignore-theme" (click)="_back()">
                    &lsaquo; {{'back' | translate}}
                </button>
            </div>
        </div>
    </div>
</form>