import { Component, OnInit, Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../../app/app.service'
import { OnlineReservationsService } from '../../online-reservations/online-reservations.service';
import { DialogsService } from '../../_core/dialogs.service';

@Component({
	selector: 'app-list-dialog',
	templateUrl: './menu-selection-dialog.component.html',
	styleUrls: ['./menu-selection-dialog.component.scss']
})
export class MenuSelectionDialogComponent implements OnInit {

    menus: any[] = [];
    locale: String = '';
	error: any;

	constructor(
		public appService: AppService,
        public dialogRef: MatDialogRef<MenuSelectionDialogComponent>,
        public onlineReservationsService: OnlineReservationsService,
		private dialogsService: DialogsService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {}

	ngOnInit() {
		this.menus = this.data?.siteMenus
		this.error = this.data?.error;
    }

	menuClick(selectedMenu) {
		this.dialogsService.toggleActionFrame('menu', null, null, null, selectedMenu.methodValue);
        this.dialogRef.close();
	}

	getMenuLabel(menu) {
		return menu.label[this.appService.appConfig.locale] || Object.values(menu?.label)?.reduce((acc, label) => acc || label);
	}
}
