import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Injectable } from "@angular/core";
import { AppService } from "../../app.service";

@Injectable()

export class FormValidationAnnouncementService {
    constructor(
        public appService: AppService,
        private liveAnnouncer: LiveAnnouncer,
    ) {}

    /*
        * validateFormAndAnnounce iterate the form controls, and announce a summary of the missing fields
        * the function should be used inside the callback of the ngSubmit method
    */

    validateFormAndAnnounce(detailsForm: any, translationPath: string) {
        if (!detailsForm?.controls) {
            return;
        }
        
        const controls = detailsForm.controls;
        const invalidFields: string[] = Object.keys(controls).reduce((invalidFields, key) => {
            if (!controls[key].valid) {
                invalidFields.push(key);
            }

            return invalidFields;
        }, [])

        if (invalidFields.length > 0) {
            const invalidFieldsAnnouncement = invalidFields.reduce((message, invalidKey) => {
                return message.concat(this.appService.translate(translationPath.concat(".", invalidKey)), ',');
            }, this.appService.translate(translationPath + '.missing_fields'));
            
            this.liveAnnouncer.announce(invalidFieldsAnnouncement);
        }
    }
}