<div class="menu-selection-dialog">
    <h1 mat-dialog-title class="menu-dialog-title">{{(error ? 'contact-details.menu_error_dialog_title' : 'contact-details.choose_menu') | translate}}</h1>
    <div mat-dialog-content>
        <ng-container *ngIf="!error else error_dialog">
            <mat-action-list *ngIf="(menus?.length != 0) else no_menu_error">
                <button
                    mat-list-item class="menu-label list-item wl-cards-background-color"
                    *ngFor="let menu of menus"
                    (click)="menuClick(menu)">
                    {{getMenuLabel(menu)}}
                </button>
            </mat-action-list>
            <ng-template #no_menu_error>
                <div class="no-available-menu">
                    <div class="dialog-main-icon">
                        <mat-icon
                            class="dialog-error"
                            svgIcon="dialog-error"
                        ></mat-icon>
                    </div>
                    {{'contact-details.no_available_menu' | translate}}
                </div>
            </ng-template>
        </ng-container>
        <ng-template #error_dialog>
            <div class="error-dialog">
                <div class="dialog-main-icon">
                    <mat-icon
                        class="dialog-error"
                        svgIcon="dialog-error"
                    ></mat-icon>
                </div>
                {{'contact-details.menu_error_dialog_body' | translate}}
            </div>
        </ng-template>
    </div>
</div>