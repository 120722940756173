import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
    providedIn: 'root'
})
export class ExternalLinkService {

    constructor(
        private platformService: Platform,
    ) { }

    openExternalLink(url: string) {
        if (window['SafariViewController'] && this.platformService.IOS) {
            // For iOS we use the SafariViewController
            this.safariViewController(url, null);
        } else if (window['cordova']) {
            window.open(url, '_system');
        } else {
            window.open(url);
        }
    }

    public safariViewController(url, closeCallback?: Function) {
        if (window['SafariViewController']) {
            // Use the Safari View Controller Plugin
            window['SafariViewController'].show({
                // If the URL has Hebrew characters we must encode the URL, otherwise it won't work in the Safari Web Browser overlay
                // However, if the URL already includes special characters - we don't encode it, as it might become "double encoded" and make the URL corrupt
                url: /[א-ת]+/.test(url) ? encodeURI(url) : url,
                hidden: false,
                //animated: false,
                //transition: 'curl',
                enterReaderModeIfAvailable: false,
                tintColor: "#FFFFFF",
                barColor: "#FFFFFF",
                controlTintColor: "#333333"
            }, (result) => {
                if (result.event === 'opened') {
                    console.debug('SafariViewController Opened');
                } else if (result.event === 'loaded') {
                    console.debug('SafariViewController Loaded');
                } else if (result.event === 'closed') {
                    console.debug('SafariViewController Closed');
                    if (closeCallback) closeCallback();
                }
            });
        } else {
            // Fallback to the external system browser
            window.open(url, '_system');
        }
    }
}