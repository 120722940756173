<div 
role="link"
[attr.aria-disabled]="['disabled'].includes(timeSlot?.class_name) ? true : false"
class="time-slot" 
[ngClass]="timeSlot?.class_name" 
*ngFor="let timeSlot of timeSlots | orderBy: 'timestamp'" rel="nofollow"
accessible
(click)="bookService.timeSlotClick($event, timeSlot, areaDescriptions, siteId)">
    <!--
    // [ ! ] Important
    // The :not(:focus-within) is used by the parent element.
    // It is important in order to prevent the "parent" element from getting "highlighted" when clicking an inner element (such as the time-slots).
    // The focus-within works because we add tabindex="-1" to the inner elements (such as the time-slot and its children)
    -->
    <div>
        <div class="time" tabindex="-1">{{timeSlot.timestamp | date: 'HH:mm' | AMPMConverter}}</div>
        <div class="availability-indication" tabindex="-1" *ngIf="!timeSlot.standby && timeSlot.pending">{{'TGM.pending_approval' | translate}}</div>
        <div class="availability-indication standby-list" tabindex="-1" *ngIf="timeSlot.standby && !timeSlot.pending">{{'TGM.standby_list' | translate}}</div>
    </div>
</div>