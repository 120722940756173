import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslationService } from '../_core/translation.service';
import { MetaService } from '../_core/meta.service';
import { DialogsService } from '../_core/dialogs.service';
import { urlParamsWhitelist } from '../_core/static/url-params-whitelist';
import { MixpanelService } from '../_core/mixpanel.service';

import { AppService } from '../app.service';

import { environment } from '../../environments/environment';

import { get, pickBy, cloneDeep } from 'lodash-es';
import moment from 'moment';

interface siteDetails {
    menus: Array<Object>
}
@Injectable({
    providedIn: 'root'
})
export class OnlineReservationsService {

    private appConfig: any = environment.appConfig;

    private layout = new BehaviorSubject<string>('');
    public layout$ = this.layout.asObservable();

    private organizationConfig = new BehaviorSubject(null);
    public organizationConfig$ = this.organizationConfig.asObservable();

    private bookingConfig = new BehaviorSubject(null);
    public bookingConfig$ = this.bookingConfig.asObservable();

    private siteMenus = new BehaviorSubject(null);
    public siteMenus$ = this.bookingConfig.asObservable();

    public orgMainImage = '';

    public DAY_KEY_FORMAT = 'YYYY-MM-DD';

    private tgmApiUrlMap = new Map();

    private baseBookingConfig = null;

    public isILenv = /^il-.+/.test(environment.env);

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private ngZone: NgZone,
        private translationService: TranslationService,
        private metaService: MetaService,
        private dialogsService: DialogsService,
        private mixpanelService: MixpanelService
    ) { }

    public convertTimeToMoment(time: string, date: moment.Moment) {
        let momentTime = date.tz() ? moment.tz(time, 'HH:mm', date.tz()) : moment(time, 'HH:mm');
        momentTime.set({
            date: date.get('date'),
            month: date.get('month'),
            year: date.get('year')
        });

        // The time is after midnight - jump to next day
        if (this.isLateNight(momentTime)) momentTime.add(1, 'day');

        return momentTime;
    };

    public formatTime(time, type?): string {
        // 2018-10-15 - this function sometimes gets an ISO string timestamp and sometimes a time (hour)
        if (type == 'iso') {
            return moment(time).locale(this.appService.localeId).format(this.getTimeFormat());
        } else {
            return moment(time, 'HH:mm').locale(this.appService.localeId).format(this.getTimeFormat());
        }
    };

    public getTimeFormat(locale?): string {
        if (!locale) locale = this.appService.localeId;

        return (locale == 'he-IL' ? 'HH:mm' : 'h:mma');
    };

    public getDateFormat(locale?): string {
        if (!locale) locale = this.appService.localeId; //organizationConfig.default_locale;

        let dateFormat: string = (locale == 'en-US' ? 'M/D' : 'D/M');
        let customDateFormat: string = '';

        // Override the default date format if needed
        this.organizationConfig$.subscribe(orgConfig => {
            customDateFormat = get(orgConfig, `organization[${locale}].date_format`);
        });

        return customDateFormat || dateFormat;
    };

    public getDateAndTimeFormat (locale, timestamp) {
        return moment(timestamp).locale(locale).format((locale == 'he-IL' ? 'D [ב]MMMM, YYYY '  : 'MMMM D, YYYY ') + this.getTimeFormat(locale));
    };

    public formatDuration(duration) {
        if (!duration) return;

        let formattedDuration = null;
        let hoursOnlyFormat = 'h [' + this.appService.translate('hours') + ']';
        let hoursAndMinutesFormat = 'h [' + this.appService.translate('hours') + '] m [' + this.appService.translate('minutes') + ']';

        if (duration % 60 == 0) {
            formattedDuration = moment.duration(duration, 'minutes').format(hoursOnlyFormat);
        } else {
            formattedDuration = moment.duration(duration, 'minutes').format(hoursAndMinutesFormat);
        }

        return formattedDuration;
    };

    public formatDurationByDisplayUnit(durationInHours: number, displayUnit: string = 'hours') {
        if (!(durationInHours && ['hours', 'days'].includes(displayUnit))) return;

        if (displayUnit == 'days') durationInHours *= 24;
        return moment.duration(durationInHours, 'hours').format(`${displayUnit.substring(0, 1)} [${this.appService.translate(displayUnit)}]`);
    };

    public getDescriptionTimeLimitData(orgConfig: any, reservationDetails: any) {
        // Calculate reduced time in case reduction config exists (if it's empty, reduce by 0)
        let minutesToReduce = Array.isArray(get(orgConfig, 'date_time_until_format.reduction')) &&
        orgConfig.date_time_until_format.reduction.reduce((acc, config) => {
            if (reservationDetails.seats_count >= config.min_group_size) {
                return config.minutes;
            }
            return acc;
        }, 0);
        let untilTime = moment(reservationDetails.reserved_until).clone()
        if (minutesToReduce) untilTime.subtract(minutesToReduce, 'minutes');

        return {
            date_time_until: untilTime.format('HH:mm'),
            time_limit: this.formatDuration(moment(untilTime).diff(reservationDetails.reserved_from, 'minutes'))
        }
    };

    public isLateNight(referencePoint, includeDailyCutoff?): boolean {
        return referencePoint.isBetween(referencePoint.clone().startOf('day'), referencePoint.clone().startOf('day').add(5, 'hours'), null, includeDailyCutoff ? '[]' : '[)');
    };

    public getDepositConfigByShift(bookingConfig, orgConfig, refPoint, isOnline = false, advancedPayment = false) {
        const currentShift = this.getBookingWindowByTimestamp(isOnline ? bookingConfig.booking_windows : orgConfig.shifts, refPoint);
        const shiftCancellationPolicy = advancedPayment ? currentShift?.advanced_payment?.cancellation_policy : currentShift?.cc_deposit?.cancellation_policy;
        const orgCancellationPolicy = advancedPayment ? orgConfig.advanced_payment?.cancellation_policy : orgConfig.cc_deposit?.cancellation_policy;
        return {
            amount: advancedPayment ? (currentShift?.advanced_payment?.amount?.price_fixed || orgConfig.advanced_payment?.amount?.price_fixed) : (currentShift?.cc_deposit?.amount || orgConfig.cc_deposit?.amount),
            cancellation_policy: {
                hours_before_reserved_from: shiftCancellationPolicy?.hours_before_reserved_from || orgCancellationPolicy?.hours_before_reserved_from,
                display_unit: shiftCancellationPolicy?.display_unit || orgCancellationPolicy?.display_unit
            }
        }
    };

    public getBookingWindowByTimestamp(bookingWindows, referencePoint) {
        if (!(bookingWindows instanceof Map)) return;

        let timezone;
        this.organizationConfig$.subscribe(config => timezone = config?.timezone);
        let currentShift = null;
        let referencePointTime = moment.tz(referencePoint, "HH:mm", timezone);

        let workDayName = referencePointTime.locale('en').format("ddd").toLowerCase();
        let weekDayBookingWindows = (bookingWindows.get(workDayName) || bookingWindows.get('default'));
        if (!weekDayBookingWindows) return;

        // The time is after midnight - jump to next day
        if (this.isLateNight(referencePointTime)) referencePointTime.add(1, 'day');

        let firstBookingWindowStartPoint = null;
        // Convert BookingWindows hours into "moments"
        weekDayBookingWindows.forEach((shiftData, name) => {
            let from = this.convertTimeToMoment(shiftData.from, referencePoint);
            let to = this.convertTimeToMoment(shiftData.to, referencePoint);

            if (firstBookingWindowStartPoint) {
                // if "from" is after midnight, give it the next day date
                if (from.isBefore(firstBookingWindowStartPoint)) from.add(1, 'day');
            } else {
                firstBookingWindowStartPoint = from;
            }

            // For cases where only "to" is after midnight
            if (to.isBefore(from)) to.add(1, 'day');

            let shiftAdditions = {
                name,
                from,
                to
            };
            // check if current reference_point is in BookingWindows range / same as shift start
            if (referencePointTime.isBetween(from, to, null, '[]')) {
                currentShift = Object.assign(shiftData, shiftAdditions)
            }
        });

        return currentShift;
    };

    public getReservation(reservationId: string, orgConfig: any, includeOccasions?: boolean, includeCustomerDetails?: boolean, reservationModify?: boolean): Observable<any> {
        let params = new HttpParams().set('organization', orgConfig._id);
        // Add optional query params
        if (includeOccasions) params = params.append('include_occasions', '1');
        if (includeCustomerDetails) params = params.append('include_customer_details', '1');
        if (reservationModify) params = params.append('reservation_modify', '1');

        return this.http.get(`${this.getTgmApiUrl(orgConfig)}/rsv/management/${reservationId}`, { params });
    };

    public deleteReservation(orgConfig: object, reservationId: string, organizationId: string): Observable<any> {
        let params = new HttpParams().set('organization', organizationId);
        let tgmApiUrl = this.getTgmApiUrl(orgConfig);

        return this.http.delete(`${tgmApiUrl}/rsv/management/${reservationId}/${encodeURIComponent('customer_cancelled')}`, { params });
    };

    public async getSiteDetails(orgId, projection?: string[]): Promise<any> {
        const url = `${this.appConfig.tabitBridge}/organizations/by-id/${orgId}`;
        const projectionParam = projection ? JSON.stringify(projection) : undefined;

        let params = new HttpParams();

        if (projectionParam) {
            params = params.set('projection', projectionParam);
        }

        this.http.get<siteDetails>(url, { params, ...this.appService.appHttpOptions })
            .subscribe({
                next: siteDetails => {
                    this.siteMenus.next(siteDetails?.menus);
                },
                error: error => {
                    console.log(error);
                }
            });
    };

    public getOrganizationConfig(orgId): Observable<any> {
        return this.getTgmDataCrossEnv(orgId, `/rsv/management/organization-configuration/${orgId}`)
        .pipe(
            map(orgConfig => {
            // Google Tag Manger
            this.setSpecificSiteGTM(orgConfig);
            // Set Organization Facebook Pixel
            if (orgConfig.online_booking?.facebook_pixel_id) this.setSpecificSiteFBPixel(orgConfig.online_booking.facebook_pixel_id);
            // Set Organization Google Analytics ID
            if (orgConfig.online_booking?.google_analytics_id) this.setSpecificSiteGA(orgConfig.online_booking.google_analytics_id);
            // Set Analytics for General App Measurement ID
            if (this.appConfig.googleAnalyticsMeasurmentId) this.setSpecificSiteGA(this.appConfig.googleAnalyticsMeasurmentId);

            // Reduce load time
            if (orgConfig?.organization[this.appService.localeId]?.image) this.orgMainImage = orgConfig?.organization[this.appService.localeId]?.image;

            orgConfig._id = orgId;

            orgConfig.timezone = orgConfig.timezone || moment.tz.guess();

            this.organizationConfig.next(orgConfig);

            // Convert the booking_windows to a Map
            if (Array.isArray(orgConfig.shifts)) {
                try {
                    let shiftsMap = new Map(orgConfig.shifts);
                    shiftsMap.forEach((value: [], key, map) => map.set(key, new Map(value)));

                    orgConfig.shifts = shiftsMap;
                } catch (err) {
                    console.debug(err);
                }
            }

            // Only for US
            if (['en-US', 'en-AU'].includes(this.appConfig.locale)) {
                this.metaService.makeSEO(null, orgConfig.organization[this.appConfig.locale].title, 'online-reservations');
            };

            // Make sure to use the default_locale when the language button is hidden
            if (orgConfig.online_booking?.hide_language_button && this.appService.localeId != orgConfig.default_locale)
                this.translationService.changeUsedLanguage(orgConfig.default_locale);

            if (orgConfig?.strings?.rsv && orgConfig.strings.rsv[this.appService.localeId])
                this.appService.updateTranslations(this.appService.removeNullProperties(orgConfig.strings.rsv[this.appService.localeId]));

            return orgConfig;
        }));
    };

    public getAlternativeOrgDetails(alternativeOrgId): Observable<any> {
        let params = new HttpParams({
            fromObject: { 'projections[]': ['organization', 'online_booking.enabled', 'online_booking.future_reservation.enabled'] }
        });

        return this.getTgmDataCrossEnv(alternativeOrgId, `/rsv/management/organization-configuration/${alternativeOrgId}`, { params }).pipe(map(data => {
            if (!(data && data.organization && data.online_booking?.enabled && data.online_booking.future_reservation?.enabled)) return;

            return Object.assign({}, {
                id: alternativeOrgId,
                value: data.organization
            });
        }));
    };

    public getBookingConfig(orgId): Observable<any> {
        return this.getTgmDataCrossEnv(orgId, `/rsv/booking/configuration`, {
            params: new HttpParams().set('organization', orgId)
        }).pipe(map(response => {
            if (!response) return console.error('Failed to get booking config');
            // Preserve default config
            this.baseBookingConfig = JSON.parse(JSON.stringify(response));
            // Check if there is an occasion at the moment of landing
            let configOccasion = response?.occasions?.find(occasion => occasion?.occasion_details?.config?.online_booking && moment().isBetween(occasion.reservation_details.reserved_from, occasion.reservation_details.reserved_until, undefined, '[)'));
            if (configOccasion) return this.extendBookingConfig(configOccasion);

            return this.processBookingConfig(response);
        }));
    };

    public extendBookingConfig (configOccasion) {
        if (!Array.isArray(configOccasion?.occasion_details?.config?.online_booking?.booking_windows)) return;
        let extendedBookingConfig = cloneDeep(this.baseBookingConfig);

        // This is done to merge the special day updated booking window into the base booking windows, without overwriting existing windows (unless it's a matching day, in which case we want to apply the new config)
        for (let bookingWindow of configOccasion.occasion_details.config.online_booking.booking_windows) {
            let baseBookingWindow = extendedBookingConfig.booking_windows.find(baseBookingWindow => baseBookingWindow[0] == bookingWindow[0])
            if (baseBookingWindow) Object.assign(baseBookingWindow, bookingWindow);
            else extendedBookingConfig.booking_windows.push(bookingWindow);
        }
        return this.processBookingConfig(Object.assign({ config_extended_by_occasion_id: configOccasion._id }, extendedBookingConfig));
    }

    public resetBookingConfig () {
        return this.processBookingConfig(Object.assign({}, this.baseBookingConfig));
    }

    public processBookingConfig(bookingConfig) {
        // Parse ISO Strings into a Date object
        if (bookingConfig.dates_range) {
            if (bookingConfig.dates_range.min) bookingConfig.dates_range.min = new Date(bookingConfig.dates_range.min);
            if (bookingConfig.dates_range.max) bookingConfig.dates_range.max = new Date(bookingConfig.dates_range.max);
        }

        // Convert the booking_windows to a Map
        if (Array.isArray(bookingConfig.booking_windows)) {
            try {
                let bookingWindowsMap = new Map(bookingConfig.booking_windows);
                bookingWindowsMap.forEach((value: [], key, map) => map.set(key, new Map(value)));

                bookingConfig.booking_windows = bookingWindowsMap;
            } catch (err) {
                console.debug(err);
            }
        }

        let datePickerEnd;
        // Date picker end defined in days
        if (bookingConfig.date_picker_end_days_count) {
            datePickerEnd = moment().add(bookingConfig.date_picker_end_days_count, 'days');
            // Date picker end defined in month
        } else if (bookingConfig.date_picker_end_month_count) {
            datePickerEnd = moment().add(bookingConfig.date_picker_end_month_count, 'month');
        } else {
            // Date picker end default
            datePickerEnd = moment().add(4, 'month');
        }
        bookingConfig.date_picker_end = datePickerEnd;

        bookingConfig.phone_number_validation_pattern = bookingConfig.phone_number_validation_pattern ? new RegExp(bookingConfig.phone_number_validation_pattern) : this.setPatternByEnvLocale();

        // Handle party sizes list and default group size
        let { defaultGroupSize, partySizes } = this.getGroupSizes(bookingConfig);
        bookingConfig.default_group_size = defaultGroupSize;
        bookingConfig.party_sizes = partySizes;
        // Working hours should be based on "base" booking config
        bookingConfig.work_hours = this.getOrganizationWorkHours(this.baseBookingConfig);

        this.bookingConfig.next(bookingConfig);

        return bookingConfig;
    }

    public getGroupSizes(bookingConfig: any, customMaxGroupSize: any = '') {

        let existingSeatsCountSet = new Set<number>(); // A set containing all available seat counts according to seating_groups. Used for partySizes.

        bookingConfig.seating_groups?.forEach(group => {
            for (let size = (group?.size?.min || 1); size <= (group?.size?.max || group?.size?.min); size++) {
                existingSeatsCountSet.add(parseInt(size));
            }
        });

        let minGroupSize = isFinite(Math.min(...existingSeatsCountSet)) ? Math.min(...existingSeatsCountSet) : 1;

        let defaultGroupSize = bookingConfig.default_group_size || 2;
        if (existingSeatsCountSet.size && !existingSeatsCountSet.has(defaultGroupSize)) defaultGroupSize = minGroupSize;

        // Party sizes list
        let maxGroupSize, allowOpenEndedMaxGroupSize = false;

        if (customMaxGroupSize) {
            maxGroupSize = customMaxGroupSize;
            allowOpenEndedMaxGroupSize = true;
        } else {
            // Max group size was defined
            if (bookingConfig.max_group_size) {
                maxGroupSize = bookingConfig.max_group_size;
                allowOpenEndedMaxGroupSize = true;
            // Define max group size based on seating groups
            } else {
                maxGroupSize = isFinite(Math.max(...existingSeatsCountSet)) ? Math.max(...existingSeatsCountSet) : 2;
                let seatingGroupOfMaxSize = bookingConfig.seating_groups.find(group => group.size.max === maxGroupSize);
                if (!seatingGroupOfMaxSize?.size?.max) allowOpenEndedMaxGroupSize = true;
            }
        }

        // Building the list
        let partySizes = [];

        for (let groupSize = minGroupSize; groupSize <= maxGroupSize; groupSize++) {
            if (existingSeatsCountSet.has(groupSize) || !existingSeatsCountSet.size) {
                let groupOption = {
                    value: groupSize,
                    label_string: groupSize == 1 ? 'booking.search.person' : (groupSize == maxGroupSize && allowOpenEndedMaxGroupSize) ? 'booking.search.larger_party' : 'booking.search.people'
                };
                partySizes.push(groupOption);
            }
        }

        return {
            defaultGroupSize,
            partySizes
        }
    }

    public getTgmDataCrossEnv(orgId, url, options?, timeoutMs = 20000): Observable<any> {
        let bookingAPI = this.appConfig.bookingAPI;

        return new Observable(observer => {
            this.http.get(`${bookingAPI}${url}`, options)
                .pipe(timeout(timeoutMs))
                .subscribe({
                    next: (response) => { observer.next(response) },
                    error: (response) => {
                        if (response?.status == 404 && response?.error?.organization_environment?.api_url) {
                            // Preserve the right api url for later use
                            this.tgmApiUrlMap.set(orgId, response.error.organization_environment.api_url);
                            // Send request to the right api url
                            return this.http.get(`${response.error.organization_environment.api_url}${url}`, options)
                                .pipe(timeout(timeoutMs))
                                .subscribe(observer);
                        } else {
                            console.error('TGM API Request Failed', response);
                            observer.error(response);
                        }
                    },
                    complete: () => {
                        observer.complete();
                    }
                });
        });
    };

    public getTgmApiUrl(orgConfig): string {
        let preservedApiUrl = this.tgmApiUrlMap.get(orgConfig?._id);
        if (preservedApiUrl) return preservedApiUrl;

        let tgmApiUrl = this.appConfig.bookingAPI;

        if (!orgConfig || !orgConfig.environment) return tgmApiUrl;

        if (/beta/.test(orgConfig.environment)) tgmApiUrl = this.appConfig.bookingAPIBeta;

        return tgmApiUrl;
    };

    public setIsMinimalLayout(layout: string) {
        this.layout.next(layout);
    };

    public setPhoneNumber(orgConfiguration: any) {
        return get(orgConfiguration, `organization[${this.appService.localeId}].phone`);
    }

    public callPhone(phone) {
        if (!phone) return;
        if (window['cordova']) {
            window.open(`tel:${phone}`, '_system');
        } else {
            window.location.href = `tel:${phone}`;
        }
    };

    public async showMenu(trackingData: any) {
        if (!this.siteMenus) return;
        if (trackingData) this.mixpanelService.track('Menu Button Click', trackingData);
        this.siteMenus.value.length == 1 ? this.dialogsService.toggleActionFrame('menu', null, null, null, this.siteMenus.value[0].methodValue) : this.dialogsService.showMenuSelectionDialog({ siteMenus: this.siteMenus.value });
    };

    public toggleLanguage() {
        let orgConfig: any = {};

        this.translationService.setToggledLang('rsv');

        this.organizationConfig$.subscribe(config => orgConfig = config);

        if (orgConfig?.strings?.rsv && orgConfig.strings.rsv[this.appService.localeId])
            this.appService.updateTranslations(this.appService.removeNullProperties(orgConfig.strings.rsv[this.appService.localeId]));
    };

    public changeLanguage(locale, orgConfig) {
        if (!locale || !orgConfig || orgConfig.online_booking?.hide_language_button) return;

        // Make sure organization details exist under this locale
        if (orgConfig.organization[locale]) {
            if (locale != this.appService.localeId) {
                this.translationService.changeUsedLanguage(locale);
            }

            if (orgConfig?.strings?.rsv && orgConfig.strings.rsv[this.appService.localeId])
                this.appService.updateTranslations(this.appService.removeNullProperties(orgConfig.strings.rsv[this.appService.localeId]));
        }
    };

    private setSpecificSiteGTM(organizationConfig: any) {
        // Tabit's Google Tag Manager for the RSV
        if (organizationConfig.google_tag_manager_ids && organizationConfig.google_tag_manager_ids.rsv)
        this.appService.addSpecificSiteGTM(organizationConfig.google_tag_manager_ids.rsv);

        // Organization's Google Tag Manager for the RSV
        if (organizationConfig.online_booking && organizationConfig.online_booking.google_tag_manager_id)
        this.appService.addSpecificSiteGTM(organizationConfig.online_booking.google_tag_manager_id);
    };

    private setSpecificSiteFBPixel(pixelId: string) {
        if (!pixelId) return;

        this.appService.addSpecificSiteFBPixel(pixelId);
    }

    private setSpecificSiteGA(GAId: string) {
        if (!GAId) return;

        const script = document.getElementById(GAId);
        if (script) return;

        this.appService.addSpecificSiteGA(GAId);
    }

    private getTgmServerStatus(orgConfig: any): Observable<any> {
        return this.http.get(`${this.getTgmApiUrl(orgConfig)}/status`);
    };

    private setPatternByEnvLocale(): any {
        const phone_validation_pattern_il = /^\+?(\d{3}\d{7}|[^0]{1}\d{10,11}|0[^5]+\d{7})$/;
        const phone_validation_pattern_us = /^(1\s?)?((([0-9]{3}))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
        const phone_validation_pattern_au = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;

        switch (this.appConfig.locale) {
            case "en-US": {
                return phone_validation_pattern_us;
            }
            case "he-IL": {
                return phone_validation_pattern_il;
            }
            case "en-AU": {
                return phone_validation_pattern_au;
            }
            default: {
                return phone_validation_pattern_il;
            }
        }
    };

    public isWaitingListEnabled(orgConfig: any, bookingConfig: any): boolean {
        return !!(bookingConfig?.walked_in?.enabled && this.getBookingWindowByTimestamp(bookingConfig.booking_windows, moment.tz(orgConfig?.timezone)));
    };

    public checkServerTimeClientDiff(orgConfig: any): void {
        // Prevent time-zone alert for Google crawler
        if (/googlebot/i.test(navigator.userAgent)) return;

        this.getTgmServerStatus(orgConfig)
            .subscribe(serverStatus => {
                const serverTimeClientDiff = serverStatus.unix_time - Date.now();
                const significantTimeDiffInMinutes = 5;

                // Server and client UTC difference  - this means the client system clock might be off
                const serverTimeClientDiffInMinutes = Math.round(serverTimeClientDiff/1000/60)

                // Server and client timezone difference - this means the client might be in a different time zone than the organization
                // Negative offset means the timezone is ahead of UTC; positive value means the timezone is behind UTC
                const serverUTCOffset = moment.tz(orgConfig.timezone).utcOffset();
                const clientUTCOffset = (new Date()).getTimezoneOffset();
                const serverTimezoneClientDiffInMinutes = clientUTCOffset + serverUTCOffset;

                // The accumulated difference of the server and client time difference - this is for display purposes only
                // To calculate the overall diff, we subtract any time differences from the UTC getTimezoneOffset
                // A positive time diff means that regardless of timezone diff, the client is actually "closer" to the server time
                // A negative time diff means that regardless of timezone diff, the client is actually "further" from the server time
                const serverTimeClientDiffOverall = serverTimezoneClientDiffInMinutes - serverTimeClientDiffInMinutes;

                // If the server and client clock are in a different timezone or are significantly apart - show a warning
                if (serverTimezoneClientDiffInMinutes || Math.abs(serverTimeClientDiffInMinutes) > significantTimeDiffInMinutes) {
                    // Set Default timezone as organization timezone
                    moment.tz.setDefault(orgConfig.timezone);

                    this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                        this.appService.mainMessage({
                            dialogType: 'error',
                            dialogTitle: 'server_time_client_diff_dialog.title',
                            dialogText: this.appService.translate('server_time_client_diff_dialog.content', {time_diff: this.formatDuration(Math.abs(serverTimeClientDiffOverall))}),
                            primaryButtonText: 'server_time_client_diff_dialog.ok_button',
                            hideSecondaryButton: true
                        })
                    });
                }
            });
    };

    public getAdditionalUrlParams (params) {
        const rsvUrlParamsWhitelist = ['locale', 'layout', ...urlParamsWhitelist];
        return pickBy(params, (value, key) => value && rsvUrlParamsWhitelist.includes(key));
    };

    private getOrganizationWorkHours (bookingConfig) {
        let workHours = {};
        let bookingWindowsMap;

        if (!bookingConfig?.booking_windows) return workHours;

        // Convert bookingWindows to Map
        bookingWindowsMap = new Map(bookingConfig.booking_windows);
        bookingWindowsMap.forEach((value, key, map) => map.set(key, new Map(map.get(key))));

        // Generate Weekday Work Hours
        ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach((weekday) => {
            workHours[weekday] = this.getDailyWorkHours((bookingWindowsMap.get(weekday) || bookingWindowsMap.get('default')), bookingConfig.table_lookup_time_increments);
        });

        // Add Special Days work hours
        if (Array.isArray(bookingConfig?.occasions)) bookingConfig.occasions.forEach(occasion => {
            // Check if booking windows are defined for a special day
            if (!Array.isArray(occasion?.occasion_details?.config?.online_booking?.booking_windows?.[0]?.[1])) return;

            workHours[moment(occasion.reservation_details.reserved_from).format(this.DAY_KEY_FORMAT)] = this.getDailyWorkHours(new Map(occasion.occasion_details.config.online_booking.booking_windows[0][1]), bookingConfig.table_lookup_time_increments);
        });

        return workHours;
    }

    private getDailyWorkHours (weekdayBookingWindows, timeIncrement) {
        let workHours = [];

        if (weekdayBookingWindows?.size) weekdayBookingWindows.forEach(bookingWindow => {
            workHours = workHours.concat(this.generateWorkHoursList(bookingWindow.from, bookingWindow.to, timeIncrement));
        });

        // Sort work hours
        return workHours.sort(this.compareWorkHoursComparator);
    }

    private generateWorkHoursList (fromTime, toTime, timeIncrement) {
        let fromMoment = moment(fromTime, 'HH:mm');
        let toMoment = moment(toTime, 'HH:mm');
        let workHoursList = [fromMoment];

        if (toMoment.isBefore(fromMoment)) toMoment.add(1, 'day');

        // Build the hours list
        while (workHoursList[workHoursList.length - 1].clone().add(timeIncrement, 'minutes').isSameOrBefore(toMoment)) workHoursList.push(workHoursList[workHoursList.length - 1].clone().add(timeIncrement, 'minutes'));
        // Convert moment into formatted string
        return workHoursList.map((momentSegment) => momentSegment.format("HH:mm"));
    }

    private compareWorkHoursComparator(hourA, hourB) {
        hourA = moment(hourA, 'HH:mm');
        hourB = moment(hourB, 'HH:mm');

        let hourAisAfterMidnight = hourA.isBetween(hourA.clone().startOf('day'), hourA.clone().startOf('day').add(5, 'hours'), null, '[)');
        let hourBisAfterMidnight = hourB.isBetween(hourB.clone().startOf('day'), hourB.clone().startOf('day').add(5, 'hours'), null, '[)');

        if ((hourAisAfterMidnight && !hourBisAfterMidnight) || (!hourAisAfterMidnight && hourBisAfterMidnight)) {
            if (hourA.isBefore(hourB)) return 1;
            else if (hourA.isAfter(hourB)) return -1;
        }

        if (hourA.isBefore(hourB)) return -1;
        else if (hourA.isAfter(hourB)) return 1;

        return 0;
    }

    public getPreferenceDescription(preference, orgConfig, preferences) {
        // If multiple preferences exist, avoid returning a description
        if (preferences?.length > 1) return;
        return orgConfig?.strings?.rsv?.[this.appService.localeId]?.booking?.search?.[`${preference}_description`];
    }

    public allowToModify(reservation, bookingConfig) {
        return bookingConfig?.enabled &&
                bookingConfig.future_reservation?.enabled &&
                bookingConfig.future_reservation.allow_edit &&
                reservation?.type == 'future_reservation' &&
                reservation?.reservation_details &&
                !reservation?.archived
    }

    public isReservationNoShow(reservation, orgConfig) {
        // Consider the cc deposit cancellation policy over the default no show config
        return reservation?.cc_deposit?.config ?
        moment().add(reservation?.cc_deposit.config.cancellation_policy?.hours_before_reserved_from, 'hours').isAfter(reservation?.reservation_details?.reserved_from) :
        moment().subtract(orgConfig?.reservation_no_show_after_minutes, 'minutes').isAfter(reservation?.reservation_details?.reserved_from);
    }

    public onlineReservationsScrollTop(top = 0, behavior: ScrollBehavior = 'auto') {
        const rsvPageElement = document.querySelector('.rsv-page'); // The element that wraps the entire online reservation module
        if (rsvPageElement) rsvPageElement.scroll({ top, behavior});
    }

}
