<!-- <div [@fadeOut]="fadeOut" class="dark-theme"> -->
<div>
    <mat-tab-group class="wiz-tabs hide-headers" [(selectedIndex)]="step">
        <!-- RANK -->
        <mat-tab label="Step 0">

            <div *ngIf="loadingMetaData" class="spinner-container">
                <mat-spinner diameter="80" strokeWidth="5" class="light-grey"></mat-spinner>
            </div>

            <div *ngIf="!loadingMetaData" class="user-review-section">

                <h1>{{'user_review_hey' | translate}}{{user && user.firstName ? ' '+user.firstName : ''}}!</h1>
                <h1>{{'user_review_waiting_to_hear_from_you' | translate}}</h1>
                <h3>
                    <span class="name">{{org.name}}</span>
                    <span *ngIf="appService.localeId == 'he-IL'" class="date">{{date | date:'d בMMMM': '': appService.localeId}}</span>
                    <span *ngIf="['en-US', 'en-AU'].includes(appService.localeId)" class="date">{{date | date:'MMMM d': '': appService.localeId}}</span>
                </h3>

                <div class="rank">
                    <ng-lottie *ngFor="let rating of ratings" (click)="setStep(1, rating.id)" [options]="{path: rating.animation, autoplay: true, loop: true}"></ng-lottie>
                    <div *ngFor="let rating of ratings">{{rating.caption | translate}}</div>
                </div>
            </div>
        </mat-tab>

        <!-- TAGS -->
        <mat-tab label="Step 1">
            <div class="user-review-section">
                <div *ngIf="selectedAnimation">
                    <ng-lottie *ngIf="step==1" [options]="{path: selectedAnimation, autoplay: true, loop: true}"></ng-lottie>
                </div>
                <div [ngSwitch]="true">
                    <h1 *ngSwitchCase="ratingId > 3">{{'user_review_what_did_you_like' | translate}}</h1>
                    <div *ngSwitchCase="ratingId < 4">
                        <h1>{{'user_review_sorry_to_hear' | translate}}</h1>
                        <h2>{{'user_review_what_to_improve' | translate}}</h2>
                    </div>
                </div>

                <div class="inner-container">
                    <div class="button" *ngFor="let reason of reasons" [class.selected]="selectedReasons[reason._id]" (click)="reasonToggle(reason._id)">{{reason.caption[appService.localeId] | translate}}</div>
                </div>

                <button mat-button class="primary-button" [disabled]="sendButtonDisabled" (click)="saveReview(); setStep(2)">
                    <span>{{'send' | translate}}</span>
                </button>

                <button type="button" class="btn btn-lg btn-link back-button" (click)="setStep(0)">
                    &lsaquo; {{'back' | translate}}
                </button>
            </div>
        </mat-tab>

        <!-- THANKS and WANT TO COMMENT? -->
        <mat-tab label="Step 2">
            <div class="user-review-section">
                <ng-container *ngIf="ratingId > 3">
                    <h1>{{'user_review_thanks' | translate}} {{user.firstName}}!</h1>
                    <h2>{{'user_review_positive_thank_you_description' | translate}}</h2>
                </ng-container>

                <ng-container *ngIf="ratingId < 4">
                    <h1>{{'user_review_thanks_for_sharing' | translate}} {{user.firstName}}</h1>
                    <h2>{{'user_review_negative_thank_you_description' | translate}}</h2>
                </ng-container>

                <div class="inner-container animation">
                    <ng-lottie class="thumb-up" *ngIf="step == 2" [options]="{path: appService.base('/assets/animations/thumb-up.json'), autoplay: true, loop: false}" (domLoaded)="animationDomLoaded([0,75])" (animationCreated)="animationCreated($event)"></ng-lottie>
                </div>

                <button mat-button class="primary-button" (click)="saveReview(); setStep(3)">
                    <span>{{'user_review_add_comment' | translate}}</span>
                </button>
                <button type="button" *ngIf="urlReferrer" class="btn btn-lg btn-link back-button" (click)="redirectToReferrer()">
                    {{'user_review_end' | translate}}
                </button>
            </div>
        </mat-tab>

        <!-- COMMENT -->
        <mat-tab label="Step 3">
            <div class="user-review-section">
                <h1>{{'user_review_hey' | translate}}{{user?.firstName ? ' '+user.firstName : ''}}!</h1>
                <h2>{{'user_review_comment' | translate}}</h2>

                <div class="inner-container">
                    <textarea maxlength="250" [(ngModel)]='reviewText'></textarea>
                </div>

                <button mat-button class="primary-button" (click)="inputReviewText(); setStep(4)">
                    {{'send' | translate}}
                </button>
            </div>
        </mat-tab>

        <!-- THANK YOU -->
        <mat-tab label="Step 4">
            <div class="user-review-section">
                <ng-container *ngIf="ratingId > 3">
                    <h1>{{'user_review_thanks' | translate}} {{user.firstName}}!</h1>
                    <h2>{{'user_review_positive_thank_you_description' | translate}}</h2>
                </ng-container>

                <ng-container *ngIf="ratingId < 4">
                    <h1>{{'user_review_thanks_for_sharing' | translate}} {{user.firstName}}</h1>
                    <h2>{{'user_review_negative_thank_you_description' | translate}}</h2>
                </ng-container>

                <div class="inner-container animation">
                    <ng-lottie class="checkmark" *ngIf="step == 4" [options]="{path: appService.base('/assets/animations/checkmark.json'), autoplay: true, loop: false}"></ng-lottie>
                </div>
                <button *ngIf="urlReferrer" mat-button class="primary-button" (click)="redirectToReferrer()">
                    {{'user_review_end' | translate}}
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>