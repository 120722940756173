import { Component, OnInit, AfterViewInit, NgZone, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../app.service';
import { AuthService } from '../_core/auth.service';
import { EntityService } from '../_core/entity.service';
import { OrganizationsService } from '../_core/organizations.service';
import { DialogsService } from '../_core/dialogs.service';
import { StorageService } from '../_core/storage.service';
import { AccountService } from '../_core/account.service';
import { NotificationsService } from '../_core/notifications.service';
import { SignInByPhoneVerifyCodeFormComponent } from './sign-in-by-phone-verify-code-form/sign-in-by-phone-verify-code-form.component';

import { get } from 'lodash-es';

@UntilDestroy()
@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    host: {},
    animations: [
        trigger('fadeOut', [
            transition('* => fadeOut', [
                style({ opacity: 1 }),
                animate('200ms 0s linear', style({ opacity: 0 })),
            ])
        ])
    ]
})
export class SignInComponent implements OnInit, AfterViewInit {
    @Output() signInCompleted = new EventEmitter();
    @Input() dialogMode: boolean;
    @Input() joinChannelOrigin: any;
	@ViewChild(SignInByPhoneVerifyCodeFormComponent, { static: false }) verifyCodeForm: SignInByPhoneVerifyCodeFormComponent;

	touchid = window['plugins'] && window['plugins'].touchid ? window['plugins'].touchid : null;
	formModel = { firstName: '', lastName: '', phone: '', code: '', email: '', password: '', IsFilledJoinForm: false, IsConfirmSms: false };
	customer = { firstName: '', lastName: '', phone: '', email: '', IsFilledJoinForm: false, IsConfirmSms: false };

	authenticating: boolean = false;
	resending: boolean = false;
    wasInit: boolean;
	touchIDUsernameExists: boolean = false;

    loyaltySignInResponse: string = '';
    fadeOut: string = '';

	step: number = 1;

    domain: any;

    constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		public appService: AppService,
		public entityService: EntityService,
        private organizationsService: OrganizationsService,
        private ngZone: NgZone,
        private dialogService: DialogsService,
        private storageService: StorageService,
        private accountService: AccountService,
        private notificationsService: NotificationsService,
        private location: Location
	) {}

    ngOnInit() {
		this._validateTouchIDandGetUsername().then(_ => this.touchIDUsernameExists = true).catch(_ => { });
		this.appService.setStatusBarStyle('light');

        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            this.domain = domain;
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton.pipe(untilDestroyed(this)).subscribe((event) => {
                this.ngZone.run(() => {
                    // handle each steps differently
                    switch(this.step){
                        case 1:
                            this.appService.goBack();
                            break;
                        case 2: case 4: case 5:
                            this._setStep(1);
                            break;
                        default:
                            this.appService.goBack();
                            break;
                        }
                    return;
                });
            });
        }
	}

	ngAfterViewInit() {
		this.route.queryParams.subscribe(params => {
            if (this.wasInit) return;
			this.wasInit = true;
		});
    }

    _setStep(step) {
        this.authenticating = false;
        this.step = step;
    }

	_onSignUp() {
		this.loyaltySignInResponse = '';
		this.step = 4
	}

    _redirect() {
        const beginLoadTime = Date.now();

        this.accountService.getUserInfo(true).catch(err => {
			console.error('Error getting main data:', err);
			this.authenticating = false;
			// if (err?.staus == 403) this.appService.signOut();
		}).then(o => {

			this.appService.stopLoadingMetaData(); // Stop the 'loading' state
			this.organizationsService.loadAllOrgsTypes(); // This will reload organizations. This is needed for mark the as favorites +

            console.debug('After load data after sign in!');
            console.debug(
				'Loading time (including user data!): ' +
                `${((Date.now() - beginLoadTime) / 1000).toFixed(3)}s. Redirecting to page...`
            );
			
			if (this.customer.phone) {
				// Storing the "username" (mobile phone) - for use by the Keychain for TouchID / FaceID
				this.storageService.setItem('username', this.customer.phone);
			}

            // Animating the Tabs - fading out (before we trigger the redirect - because then it destroys the tabs and we won't see the animation)
            // Trying to use animateChild from within app-component didn't work either (Angular destroys the tabs before the execute the animation)
            this.fadeOut = 'fadeOut';

            // Send LogZ upon successful sign-in
            this.notificationsService.deviceId
            .pipe(untilDestroyed(this))
            .subscribe(deviceId => {
				deviceId ? this.appService.logger({ message: `Signed-in to Tabit on Device: ${deviceId} using ${this.customer?.phone} phone number` }) : this.appService.logger({ message: `Login Successful using ${this.customer?.phone} phone number` });
            });

			// broadcast the login event app-wide
			this.signInCompleted.emit();
			this.appService.userSignedIn.emit();

			if (!this.dialogMode) {
                setTimeout(() => {
                    // console.log('=== SIGN-IN/COMPONENT - _redirect: ', this.appService.landingUrl, this.appService.landingUrlParams);
                    if (this.appService.landingUrl && this.appService.landingUrl != this.appService.currentUrl) {
                        this.appService.redirect([this.appService.landingUrl], this.appService.landingUrlParams)
                            .catch(() => this.location.back())

                    } else {
                        this.appService.redirect(['/home/dashboard']);
                    }
                }, 200); // Needed in order to let the fade out finish
            }
		})
    }

	_onResetPassword(email: string) {
		this.authenticating = true;
		this.authService.resetPassword(email).then(res => {
            this.handleErrorCodes(null, 'confirmation_password_reset');
		}).catch(err => {
            this.handleErrorCodes(null, 'error_reset_password');
		}).then(res => {
			this.authenticating = false;
		});
	}

	_onSignInByPhoneSubmit(formModel) {
        if (this.verifyCodeForm) {
            this.verifyCodeForm.startResendCodeCountdown();
        }
        this.authenticating = true;

        if (formModel.phone == '0544301089') {
            this.authenticating = false;
            this.step = 2;
            if (formModel.phone) {
                this.customer.phone = formModel.phone;
            }
            return;
        }

        // joinChannelOrigin override
        if (this.joinChannelOrigin) {
            formModel.origin = this.joinChannelOrigin;
        }

		return this.authService.authMobile$(formModel).subscribe(
			(response: any) => {
				this.authenticating = false;
				if (response?.IsSuccess && response?.ResponseData) {
                    // logger
                    // Disabling temporarily to see if it affects the issue that seems like the zone-of-death in Cordova
                    //this.appService.logger({message:'Sign In - Verification Code Sent'});

					// We need to "remember" this so that we'll know to show the First and Last name form (step 5) so the user can update his/her details
					this.loyaltySignInResponse = response.ResponseData.LoginResult;

					if (response.ResponseData.LoginResult == 'EmailValidation') {
						// Redirecting to Sign in By Email Form
						this.step = 3;
					} else if (['NewCustomer', 'AppCustomerDeleted'].includes(response.ResponseData.LoginResult)) {
						// Redirecting to registration form
						this.step = 4;
					} else {
						// Redirecting to Verify Code Form (UserExistInLoyalty)
						this.step = 2;
					}

					if (formModel.phone) {
						this.customer.phone = formModel.phone;
					}
				}
			},
			(err: any) => {
                console.debug('_onSignInByPhoneSubmit > Error: ', err);
				this.authenticating = false;
                this.handleErrorCodes(err);
			}
		);
	}

	_onSignInByPhoneVerifyResend() {
		this.resending = true;
        const args = {
            phone: this.customer.phone
        };

        // joinChannelOrigin override
        if (this.joinChannelOrigin) {
            args['origin'] = this.joinChannelOrigin;
        }

		return this.authService.resendPicode$(args).subscribe(
			(response: any) => {
				// logger
                this.appService.logger({message:'Sign In - Verification Code Re-sent'});
				this.resending = false
			},
			(err: any) => {
				console.debug('_onSignInByPhoneVerifyResend > Error: ', err);
				this.resending = false
                this.handleErrorCodes(err);
			}
		);
	}

	_onSignInByPhoneVerifyCodeSubmit(formModel) {
        this.authenticating = true;

        // For Apple Store validation - 0529990043 //

        if (formModel.phone == '0544301089') {
            let response = {"IsSuccess":true,"Message":"Success","ResponseData":{"accessToken":"B566621F-B714-4C8B-941B-D9C596CCA2A6","refreshToken":"3D938CD1-C992-4177-B85B-E0364F565299"}};
            this._setHeadersAndGetROSToken({formModel, response});
            return;
        }

        // joinChannelOrigin override
        if (this.joinChannelOrigin) {
            formModel.origin = this.joinChannelOrigin;
        }

		return this.authService.signInByPhoneVerifyCode$(formModel).subscribe(
			(response: any) => {
				this._setHeadersAndGetROSToken({formModel, response});
			},
			(err: any) => {
				this.authenticating = false;
                console.debug('sign-in > _onSignInByPhoneVerifyCodeSubmit > signInByPhoneVerifyCode$ > Error:', err);
                this.handleErrorCodes(err);
			},
		);
	}

	_setHeadersAndGetROSToken({formModel, response}) {
		if (response?.IsSuccess && response?.ResponseData?.accessToken && response.ResponseData?.refreshToken) {

			// Storing the Loyalty Tokens and updating Headers for future requests
			this.authService.setHttpHeadersLoyaltyAccessToken(response.ResponseData.accessToken, response.ResponseData.refreshToken);

			// Storing the Loyalty Tokens in the device keychain
			if (this.customer.phone && this.touchid) {
				this.touchid.save(this.customer.phone, JSON.stringify({formModel, response}), () => {
					console.debug('sign-in > touchID > Saved Loyalty Tokens successfully in Keychain.');
				}, (err: any) => {
					console.debug('sign-in > touchID > Failed to save Loyalty Tokens in Keychain: ', err);
				});
            }

            /*
            // Used for allowing Apple Appsstore guys to review the app
            if (formModel.phone == '0544301089') {
                let response = {"IsSuccess":true,"Message":"Success","ResponseData":{"Access_token":"GyJMH1WLdPmh5HS32B92rEo6At6Y943R","Refresh_token":"oj1Js3pf61q3yIGp3ui2MQETdW29f6HjfCNuzwerouuqtfZ18jg1G3UAQVbaEYfQ"}};
                this.authService.setHttpHeadersAccessToken(response.ResponseData.Access_token, response.ResponseData.Refresh_token);
                this.formModel.firstName = 'אורן';
                this.formModel.lastName = 'עגיב'
                this._redirect();
                return;
            }
            */

			// Getting the ROS Token
			return this.authService.getROSToken$(formModel).subscribe(
				(response: any) => {
					if (response && response.IsSuccess && response.ResponseData && response.ResponseData.Access_token && response.ResponseData.Refresh_token) {
						console.debug('sign-in > _setHeadersAndGetROSToken > getROSToken$ > Next - Got ROS Tokens', response);
						this.authService.setHttpHeadersAccessToken(response.ResponseData.Access_token, response.ResponseData.Refresh_token);

						// Get Customer Details from Loyalty
						this.authService.getCustomer$().subscribe(
							(response: any) => {
								console.debug('sign-in > _setHeadersAndGetROSToken > getROSToken$ > getCustomer:', response);
								// Since the name might return with "*" due to ROS integration logics - we validate and remove any irrelevant chars.
								this.formModel.firstName = response.ResponseData.FirstName ? response.ResponseData.FirstName.replace(/[^a-zA-Zא-ת -]+/, '') : '';
								this.formModel.lastName = response.ResponseData.LastName ? response.ResponseData.LastName.replace(/[^a-zA-Zא-ת -]+/, '') : '';

                                // broadcast the login event app-wide
                                // this.appService.userSignedIn.emit();

							},
							(err: any) => {
								console.debug('sign-in > _setHeadersAndGetROSToken > getROSToken$ > getCustomer > Error:', err);
							}
						);

						// If the user existed in ROS - we redirect him to the first and last name form
						if (this.loyaltySignInResponse == 'EmailValidation') {
							this.authenticating = false;
							this.step = 5;
						} else {
							this._redirect();
						}

					} else {
						console.debug('sign-in > _setHeadersAndGetROSToken > getROSToken$ > Next: Got response but without ROS tokens:', response);
                        this.handleErrorCodes();
						this.authenticating = false;
					}
				},
                (err: any) => {
                    console.debug('sign-in > _setHeadersAndGetROSToken > getROSToken$ > Error:', err);
                    this.authenticating = false;
                    this.handleErrorCodes();
				}
			);
		} else {
			console.debug('sign-in > _onSignInByPhoneVerifyCodeSubmit > signInByPhoneVerifyCode$ > Next: Got response but without Loyalty tokens:', response);
			this.authenticating = false;
            this.handleErrorCodes();
		}
	}

	_onSignInByEmailSubmit(formModel) {
		this.authenticating = true;
		// Note: we need to add the customer phone number to the formModel argument object
		return this.authService.signInByEmail$({...formModel, phone:this.customer.phone}).subscribe(
			(response: any) => {
				this.authenticating = false;

				if (response && response.IsSuccess) {
					// Redirecting to the verification code form
					this.step = 2;
				} else {
                    this.handleErrorCodes(null, 'error_invalid_sign_in_credentials');
				}
			},
			(err: any) => {
				console.debug('sign-in > _onSignInByEmailSubmit > signInByEmail$ > Error:', err);
				this.authenticating = false;
                this.handleErrorCodes(null, 'error_invalid_sign_in_credentials');
			}
		);
	}

	_onSignUpSubmit(formModel) {
		this.authenticating = true;

        // joinChannelOrigin override
        if (this.joinChannelOrigin) {
            formModel.origin = this.joinChannelOrigin;
        }

		// Note: we need to add the customer phone number to the formModel argument object
		return this.authService.insertCustomer$({...formModel, phone:this.customer.phone}).subscribe(
			(response: any) => {
				console.debug('_onSignUpSubmit > insertCustomer$ > Success: ', response);
				this.authenticating = false;

				if (response && response.IsSuccess) {
					// A needed log to identify new user login
					this.appService.logger({ message: `New User ${this.customer.phone} Logged-In` });
					// Redirecting to the verification code form
					this.step = 2;
				} else {
                    this.handleErrorCodes();
				}
			},
			(err: any) => {
				console.debug('_onSignUpSubmit > insertCustomer$ > Error: ', err);
				this.authenticating = false;
				if (err.status == '409') {
					this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
						if (err.error?.Key == "autoAttachCard_noAvailableCards") {
                            this.handleErrorCodes(err);
						}
						else {
                            this.handleErrorCodes(null, 'error_invalid_sign_in_email_already_exists');
						}
					});
				} else {
                    this.handleErrorCodes();
				}
			}
		);
	}

	_onUpdateSubmit(formModel) {
		this.authenticating = true;

        // joinChannelOrigin override
        if (this.joinChannelOrigin) {
            formModel.origin = this.joinChannelOrigin;
        }

		// Note: we need to add the customer phone number to the formModel argument object
		return this.authService.updateCustomer$({...formModel, phone:this.customer.phone}).subscribe(
			(response: any) => {
				console.debug('_onSignUpSubmit > updateCustomer$ > Success: ', response);

				if (response?.IsSuccess) {
					this._redirect();
				} else {
					this.authenticating = false;
                    this.handleErrorCodes();
				}
			},
			(err: any) => {
				console.debug('_onSignUpSubmit > updateCustomer$ > Error: ', err);
				this.authenticating = false;
                this.handleErrorCodes();
			}
		);
	}

	clearTokens(): void {
		this.storageService.removeItem('token');
		this.storageService.removeItem('refreshToken');
	}

	_validateTouchIDandGetUsername(): Promise<string> {
		let that = this;
		return new Promise((resolve, reject) => {
			let localUsername = this.storageService.getItem('username');
			// The "username" must be a phone number
			if (!that.touchid || !localUsername || !/^\d+$/.test(localUsername)) return reject();
			that.touchid.has(localUsername, () => resolve(localUsername), () => reject());
		})
	}

	_authenticateByTouchID() {
		this.appService.closeAllToasts();

		let that = this;
		this.authenticating = true;
		this._validateTouchIDandGetUsername().then((localUsername: string) => {
			console.debug('Local username exits in device keychain.');
			that.touchid.verify(localUsername, 'Scan your fingerprint/face to login',
				(loyaltyTokensResponse: any) => {
					that.ngZone.run(() => {
						loyaltyTokensResponse = JSON.parse(loyaltyTokensResponse);
						that._setHeadersAndGetROSToken(loyaltyTokensResponse);
					});
				},
				(error: any) => {
					console.debug('ID NOT verified', error);
					that.ngZone.run(() => {
						this.touchIDUsernameExists = false;
						this.authenticating = false;
                        this.handleErrorCodes(null, 'error_invalid_touchid');
					});
				});
		}).catch(() => {
			console.debug('Local username does not exist in device keychain.');
		});
    }

    openLink(url: string) {
        this.dialogService.toggleActionFrame('link', null, null, window['cordova'], url);
    }

    getMediaSource(cacheBusting: boolean) {
        let source = this.appService.getValidBaseUrl(get(this.domain, 'signInMediaSource'), cacheBusting);
        if (!source) {
            source = this.appService.base('assets/videos/tabit-sign-in.mp4', cacheBusting);
        }
        return source;
    }

	handleErrorCodes(err?, message?) {
		let dialogText;
		let hideSecondaryButton = false;

		if (err) {
			const errorKey = get(err.error.error, 'Key');
			switch (errorKey) {
				case 'autoAttachCard_noAvailableCards':
					dialogText = 'error_invalid_cards_for_auto_attach';
					break;
				case 'CannotCreateNewPinCode':
					dialogText = 'MESSAGES.TOO_MANY_CODE_REQUESTS';
					hideSecondaryButton = true;
					break;
			}
		} else if (message) {
			dialogText = message;
		}

		if (!dialogText) {
			dialogText = 'error_general';
		}

		this.ngZone.run(() => {
			this.appService.mainMessage({
				dialogType: 'error',
				dialogTitle: 'error_title',
				dialogText,
				hideSecondaryButton,
			});
		});
	}
}
