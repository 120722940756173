import { NgModule } from '@angular/core';
import {
    TranslatePipe,
    SafeStylePipe,
    SafeURLPipe,
    DefaultCurrencyPipe,
    FilterPipe,
    VisualNumberPipe,
    PaymentExpiration,
    AMPMConverterPipe,
    WeightPipe,
    TranslateSiteTagsAsync,
    SittingsAreaDescriptionPipe
} from './pipes';

@NgModule({
    imports: [],
    declarations: [
        TranslatePipe,
        SafeStylePipe,
        SafeURLPipe,
        DefaultCurrencyPipe,
        FilterPipe,
        VisualNumberPipe,
        PaymentExpiration,
        AMPMConverterPipe,
        WeightPipe,
        TranslateSiteTagsAsync,
        SittingsAreaDescriptionPipe
    ],
    exports: [
        TranslatePipe,
        SafeStylePipe,
        SafeURLPipe,
        DefaultCurrencyPipe,
        FilterPipe,
        VisualNumberPipe,
        PaymentExpiration,
        AMPMConverterPipe,
        WeightPipe,
        TranslateSiteTagsAsync,
        SittingsAreaDescriptionPipe
    ],
    providers: [
        FilterPipe,
        DefaultCurrencyPipe,
        AMPMConverterPipe,
    ],
})
export class SharedModule { }
