<sites-search-tools
    type="order"
    title="SERVICE_BUTTON.order"
    [initialSearch]="initialSearch"
    [(extendedDeliveryResults)]="usingExtendedResults"
    [customFilterFields]="filterFields"
    (searchChanged)="searchChanged($event)"
    (searchTextCleared)="clearSearchText()"
    (scrollReachedBottom)="loadMore()"
>

    <!-- Not cleaning it up since Oren said that Barry will want it back -->
    <!--
    <div class="address-picker" isAddress>
        <mat-form-field floatLabel="never">
            <mat-label>כתובת למשלוח</mat-label>
            <mat-select [(value)]="selectedAddressLocation" (selectionChange)="onAddressPick()">
                <mat-option *ngIf="currentLocation" [value]="currentLocation">{{'my_location' | translate}}</mat-option>
                <mat-option *ngFor="let address of addresses" [value]="address.location">
                    {{ address.street }} {{ address.house }} {{ address.city }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-stroked-button color="accent" (click)="goToNewAddress()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    -->

    <mat-tab-group mat-stretch-tabs [selectedIndex]="services[selectedService].index" (selectedTabChange)="serviceSelected($event)">
        <mat-tab [disabled]="searching" *ngFor="let service of servicesArr; let i = index" [label]="service.label | translate"></mat-tab>
    </mat-tab-group>

    <div class="sites-list" *ngIf="!searching">
        <mat-list class="generic-list">
            <ng-container *ngFor="let site of orgs">
                <site-item
                    [siteId]="site._id"
                    [showBadges]="!usingExtendedResults"
                    [service]="selectedService"
                    (click)="siteClick(site)"
                    (press)="sitePress(site, $event)"
                ></site-item>
            </ng-container>
        </mat-list>
    </div>

    <div class="show-more-sites">
        <div *ngIf="!preventSearchMore && (!orgs.length || searching)" class="loading-label">{{'MESSAGES.LOADING_INITIAL_SITES' | translate}}</div>
        <div *ngIf="!preventSearchMore && orgs.length && !searching" class="loading-label">{{'MESSAGES.LOADING_SITES' | translate}}</div>
        <div *ngIf="(preventSearchMore && orgs.length > 5)" class="loading-label">{{'MESSAGES.NO_MORE_RESULTS' | translate}}</div>
        <div *ngIf="preventSearchMore && !orgs.length" class="loading-label">{{'MESSAGES.NO_ORGS_RESULTS' | translate}}</div>
        <!-- 01/08/2021 - Removed the more options button for now -->
        <!-- <div *ngIf="!appService.skin && preventSearchMore && extendedResultsButtonVisible && !usingExtendedResults">
            <div mat-dialog-actions>
                <button type="button" (click)="extendedResultsClick()" class="button extended-results">{{'MESSAGES.FOR_EXTENDED_RESULTS_PRESS_HERE' | translate}}</button>
            </div>
        </div> -->
        <mat-spinner *ngIf="!preventSearchMore" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
    </div>

</sites-search-tools>
