<div>
    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate">{{ 'SERVICE_BUTTON.book' | translate }}</span>
            <notifications-widget-opener></notifications-widget-opener>
        </div>
    </div>
    <div class="module-content" style="overflow: hidden;">
        <!--<div class="sub-title">{{ 'booking_description' | translate }}</div>-->
        <app-tabitbook-start *ngIf="!loadingMetaData" (clickSubmit)="getSitesForBooking()" [editorToSet]="editorToSet" [referrer]="'cross-orgs'"></app-tabitbook-start>
    </div>
</div>