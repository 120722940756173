<!-- Menu Template -->
<div *ngIf="menuItem" class="menu-item" (click)="openMenu(menuItem)" accessible>
    <div *ngIf="menuItem.isTabitOrderMenu" class="icon"
        [style.background-image]="appService.base('assets/images/menu_online.svg') | safeStyle"></div>
    <div *ngIf="!menuItem.isTabitOrderMenu" class="icon regular-menu"
        [style.background-image]="appService.base('assets/images/menu_offline.svg') | safeStyle"></div>
    <div class="details-container flex-grow-1">
        <h3> {{ menuItem.label[appService.localeId] || menuItem.label[appService.appConfig.locale] }} </h3>
    </div>
    <div>
        <mat-icon>{{ appService.nextIcon }}</mat-icon>
    </div>
</div>