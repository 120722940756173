import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppService } from '../../../../app/app.service';
import { Address, AddressesService, AddressesSearch } from '../../../../app/_core/addresses.service';

@Component({
    selector: 'address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {

    @Output() addressSubmitted = new EventEmitter<Address>();

    @ViewChild('addAddressForm', {static: false})    private theForm: NgForm;

    private addressTextChange: Subject<Event> = new Subject();
    private addressTextChangeSubscription: Subscription;

    // search field model
    public addressFieldModel: string;

    searchingAddress: boolean = false;
    searchedAddressOnce: boolean = false;
    enablePartialAddress: boolean = false;

    googleAddressPickedSuggestion: number = null;

    public googleAddressPredictions: {formatted_address: string, place_id: string}[] = [];
    public googleAddressPicked: boolean = false;
    public googleAddressPickedIndex: number;

    _id: string;
    addressType: 'apartment' | 'house' | 'office';
    city: string;
    street: string;
    house: number;
    entrance: string
    floor: string;
    apartment: string;
    location: {
        lat: number;
        lng: number;
    };
    notes: string;
    default: boolean;

    private addressesSearch: AddressesSearch;

    constructor(
        private addressesService: AddressesService,
        private ngZone: NgZone,
        private appService: AppService
    ) { }

    ngOnInit() {
        this.addressesSearch = this.addressesService.newAddressesSearch(['street_address', 'establishment', 'premise', 'locality', 'route']);
        this.addressTextChangeSubscription = this.addressTextChange.pipe(
            map(event => (<HTMLInputElement>event.target).value), // Typescript casting... https://stackoverflow.com/a/44321394/4645369
            filter(this.hasToBeginSearch.bind(this)),
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(addressFreeText => {

            if (!addressFreeText) {
                this.searchingAddress = false;
                return;
            }

            this.googleAddressPicked = false;

            forkJoin([
                this.addressesSearch.getGoogleAddressPredictions(addressFreeText),
                this.addressesService.getAddressFromBridgeByName(addressFreeText)
            ]).subscribe(([googleAddresses, bridgeAddresses]: [any, any]) => {
                let addressesPredictions = (googleAddresses).concat(bridgeAddresses || []);
                // console.debug('=== ADDRESS/FORM === response for google predictions:', addressesPredictions);
                this.ngZone.run(() => { // Required because otherwise Angular (for some reason) doessn't detect the change and doesn't display the results in the DOM.
                    this.googleAddressPickedIndex = undefined;
                    this.googleAddressPredictions = addressesPredictions;
                    if (!this.searchedAddressOnce) this.searchedAddressOnce = true;
                    this.searchingAddress = false;
                });

            }, err => {
                console.error('Searching address predicitons failed:', err);
                this.searchingAddress = false;
            });

        });

    }

    ngOnDestroy() {
        this.addressTextChangeSubscription.unsubscribe();
    }

    needsBuildingDetails() {
        return this.addressType === 'apartment' || this.addressType === 'office';
    }

    hasToBeginSearch(addressFreeText): boolean {
        if (addressFreeText?.length > 2) {
            this.searchedAddressOnce = false;
            this.searchingAddress = true;
            return true;
        } else {
            return false;
        }
    }

    onFreeAddressTextChange(event: Event) {
        this.addressTextChange.next(event);
    }

    chooseAddress(address: {formatted_address: string, place_id: string, isAdditionalAddress?: boolean, enablePartialAddres?: boolean}) {
        if (!address.isAdditionalAddress) {
            this.addressesSearch.getLocationForPlaceId(address.place_id, address.formatted_address).subscribe((finalAddresses: Address[]) => {
                //console.log('=== ADDRESS/FORM === There are final addresses:', finalAddresses);
                if (finalAddresses?.length && finalAddresses[0].house) {
                    this.googleAddressPicked = true;
                    Object.assign(this, finalAddresses[0]);

                    // display the selected address and close the addresses predictions
                    this.addressFieldModel = finalAddresses[0].formatted_address;
                    this.googleAddressPredictions = [];

                } else {
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'error_full_address_required',
                        dialogTitle: 'WHERE_CAN_WE_FIND_YOU',
                        primaryButtonText: 'continue',
                        hideSecondaryButton: true
                    });
                    this.googleAddressPicked = false;
                    this.googleAddressPickedIndex = undefined;
                }
            }, err => {
                console.error('Error getting selected address from google:', err);
            });
        } else {
            if (address) {
                this.enablePartialAddress = address.enablePartialAddres;
                this.googleAddressPicked = true;
                Object.assign(this, address);

                // display the selected address and close the addresses predictions
                this.addressFieldModel = address.formatted_address;
                this.googleAddressPredictions = [];
            } else {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'error_full_address_required',
                        dialogTitle: 'WHERE_CAN_WE_FIND_YOU',
                        primaryButtonText: 'continue',
                        hideSecondaryButton: true
                    });
                });
                this.googleAddressPicked = false;
                this.googleAddressPickedIndex = undefined;
            }
        }
    };

    validForm() {

        if (['apartment', 'office'].includes(this.addressType) && !this.floor)  return false;
        if (['apartment'].includes(this.addressType) && !this.apartment)  return false;

        if (this.enablePartialAddress) {
            return (
                this.addressType &&
                this.city &&
                this.street &&
                this.location
            );
        } else {
            return (
                this.addressType &&
                this.city &&
                this.street &&
                this.location &&
                this.house
            );
        }
    }

    addressSubmit() {
        this.addressSubmitted.emit({
            _id: this._id,
            addressType: this.addressType,
            city: this.city,
            street: this.street,
            house: this.house,
            entrance: this.entrance,
            floor: this.floor,
            apartment: this.apartment,
            default: !!this.default,
            location: this.location,
            notes: this.notes,
        });
        this.clear();
    }

    clear() {

        this.theForm.resetForm();

        this.searchedAddressOnce = false;

        this.addressFieldModel = null;

        this.googleAddressPredictions = [];

        this.addressType = null;
        this.city = null;
        this.street = null;
        this.house = null;
        this.entrance = null;
        this.floor = null;
        this.apartment = null;
        this.location = {
            lat: 0,
            lng: 0,
        };
        this.default = null;
    }

    pickSuggestedAddress($event: Event) {
        console.debug('Pick event:', $event);
    }
}
