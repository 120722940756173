import { Injectable, NgZone } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { each, cloneDeep } from 'lodash-es';
import { AppService } from '../app.service';
import { EntityService } from './entity.service';
import { AuthService } from './auth.service';
import { DialogsService } from './dialogs.service';

@Injectable({
    providedIn: 'root'
})

@UntilDestroy()
export class AccountService {
    @BlockUI() blockUI: NgBlockUI;

    public updatePassword: boolean = false;
    public updateWallet: boolean = false;
    public loading: boolean = true;
    public canScan: any;
    public accountStep: string = 'general';
    public pmStep: string = 'general';
    public paymentMethods = ["creditCard", "Cibus", "10bis"];
    // wallet is relevant only for the israeli domain
    public isWalletNeeded = this.entityService.isWalletNeeded;

    public userDetails: any = {
        name: '',
        email: '',
        phone: '',
        wallet: {
            payments: []
        },
        loyaltyCustomer: {}
    };

    public ccinfo: any = {
        paymentTypeView: "",
        paymentType: "",
        type: undefined
    };

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public authService: AuthService,
        public dialogsService: DialogsService,
        private ngZone: NgZone,
    ) {
        //Check if the domain exists and loaded
        this.appService.loadingCoreData
        .subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.appService.domain
                .subscribe(domain => {
                    const unauthorizedPaymentMethods = domain["unauthorizedPaymentMethods"];
                    if (unauthorizedPaymentMethods) this.filterPaymentMethodsAndChangView(unauthorizedPaymentMethods);
                });
            }
        })

        // updates only "this.userDetails.wallet" that is required in other parts of the app
        this.appService.populateUserWallet
            .pipe(untilDestroyed(this))
            .subscribe(updatedWallet => this.userDetails.wallet = updatedWallet);

        this.setInitialCcInfoState();
    }

    public filterPaymentMethodsAndChangView(unauthorizedPayment){
        //filter the Payment Methods and Chang the fields View (for ngSwitchCase) Based on the domain details
        this.paymentMethods = this.paymentMethods.filter(elem => !unauthorizedPayment.includes(elem));
        if (this.paymentMethods.length > 1) {
            this.ccinfo.paymentTypeView = this.paymentMethods[0];
        }
    }

    // Wallet section
    public canScanPM() {
        if (!window['CardIO']) return;
        window['CardIO'].canScan((canScan) => {
            this.ngZone.run(() => {
                this.canScan = canScan;
            });
        });

    }

    public getUserInfo(withAttributes?) {
        this.blockUI.start();
        return this.entityService.getUserInfo(this.isWalletNeeded, withAttributes)
        .then(response => {
            this.loading = false;
            this.userDetails = cloneDeep(response);
            if (!this.userDetails?.wallet?.payments.length) this.pmStep = "addpm";
        }).then(() => {
            this.blockUI.stop();
        }).catch(e => {
            console.error(e);
        });
    }

    public updateAccount(silent?) {
        this.blockUI.start();
        return this.entityService.updateAccount(this.userDetails)
        .then(response => {
            if (!silent) {
                //that.appService.toastr('MESSAGES.saved_successfully', { type: 'success' });
            }
            return this.refreshWallet()
            .then(() => {
                this.updatePasswordChanged();
                this.updatePassword = false;
                this.updateWallet = false;
            });
        }).then(() => {
            this.blockUI.stop();
        }).catch((e) => {
            console.error(e);
            this.blockUI.stop();
        });
    }

    updateCustomer(loyaltyTerms?: any) {
		return this.entityService.updateCustomer(this.userDetails, loyaltyTerms)
	}

    public refreshWallet() {
        this.blockUI.start();
        return this.entityService.getUserWallet()
        .then(response => {
            this.userDetails = cloneDeep(response);
        }).then(() => {
            this.blockUI.stop();
        }).catch(err => {
            console.error(err);
        });
    }

    public updatePasswordChanged() {
        delete this.userDetails.oldPassword;
        delete this.userDetails.newPassword;
    }

    public addPM(payment?: any) {
        this.blockUI.start();
        const paymentToSave = payment || this.ccinfo;
        return this.entityService.addPaymentMethodToWallet(paymentToSave)
        .then(response => {
            this.userDetails = cloneDeep(response);
            this.pmStep = 'general';
            this.ccinfo = {
                paymentTypeView: '',
                paymentType: '',
                type: undefined
            };
        }).then(() => {
            this.blockUI.stop();
        }).catch((e) => {
            console.error(e);
        })
    }

    public deletePM(pm, ev?) {
        if (ev) ev.stopPropagation();
        this.blockUI.start();
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            return this.appService.mainMessage({
                dialogType: 'info',
                dialogText: 'MESSAGES.Q_REMOVE_CARD'
            }).then(response => {
                return this.entityService.removePaymentFromWallet(pm._id)
                .then(() => {
                    //this.appService.toastr('MESSAGES.deleted_successfully', { type: 'success' });
                    return this.refreshWallet();
                }).then(() => {
                    this.blockUI.stop();
                }).catch((err) => {
                    if (!err?.secondaryButtonClick) console.error(err);
                    this.blockUI.stop();
                });
            }).catch(res => {
                if (!res?.secondaryButtonClick) console.error(res);
                this.blockUI.stop();
            });
        });
    }

    scanPM() {
        if (!window['CardIO']) return;
        // Documentation of the Cordova Card.io plugin can be found here:
        // https://www.npmjs.com/package/cordova-plugin-card-io
        window['CardIO'].scan({
            "requireExpiry": true,
            "requireCVV": false,
            "requirePostalCode": false,
            "suppressManual": true,
            "restrictPostalCodeToNumericOnly": false,
            "keepApplicationTheme": true,
            "requireCardholderName": false,
            "scanInstructions": '',
            "noCamera": false,
            "scanExpiry": true,
            "languageOrLocale": '',
            "guideColor": "#00C8C9",
            "suppressConfirmation": this.appService.cordovaPlatform == 'android' ? false : true, // The card.io plugin has a bug in Android, so we must have this flag set as FALSE for Android (otherwise it won't scan the card at all). https://github.com/card-io/card.io-Cordova-Plugin/issues/63
            "hideCardIOLogo": false,
            "useCardIOLogo": false,
            "suppressScan": false
        },
            (success: any) => {
                console.debug('My Wallet > Scan Payment Method > Success:', success);
                this.ngZone.run(() => {
                    if (success.cardNumber) {
                        this.ccinfo.pan = success.cardNumber;
                    }
                    if (success.expiryMonth && success.expiryYear) {
                        if (parseInt(success.expiryMonth) < 10) {
                            // Padding with leading 0
                            success.expiryMonth = '0'+success.expiryMonth;
                        }
                        this.ccinfo.expiration = success.expiryMonth+'-'+success.expiryYear.toString().substr(2,4);
                    }
                });
            },
            (err: any) => {
                console.debug('My Wallet > Scan Payment Method > Error:', err);
            },
        );
    }

    public paymentMethodChanged() {
        this.ccinfo = { paymentType: this.ccinfo.paymentType, paymentTypeView: this.ccinfo.paymentType };
        if (this.ccinfo.paymentType == 'creditCard') this.saveWalletPaymentInCreditGuard();
    }

    public pmDefaultChanged(pm) {
        if (pm.isDefault) return;
        this.userDetails.defaultWalletPayment = pm._id;
        each(this.userDetails.wallet.payments, _pm => {
            if (_pm != pm) _pm.isDefault = false;
            else _pm.isDefault = true;
        });
        this.updateAccount(true);
    }

    public saveWalletPaymentInCreditGuard(args?: any) {
        const that = this;
        this.appService.startBlock();

        const params = this.appService.convertQueryStringToQueryParams(window.location.href);
        const baseLocation = this.appService.getBaseLocation(params);

        const amount = args?.amount || 100;
        const baseURL = this.appService.base(baseLocation + "&r=", false);
        const direction = this.appService.localeId == 'he-IL' ? 'rtl' : 'ltr';
        const uiLang = this.appService.localeId == 'he-IL' ? 'heb' : 'eng';
        const textAlign = direction == 'rtl' ? 'right' : 'left';
        const paymentType = 'creditGuard';
        const iframeConfigurations = '{'
            +'   "uiCustomData":{'
            +'                   "uiLang": "' + uiLang + '", '
            +'                   "customStyle":"body {direction:' + direction + '; text-align:' + textAlign + '} #card-number, #cvv, .cg-message-input {direction:ltr; text-align:' + textAlign + '} #cg-ghost-btns {text-align: center !important;} #cg-tx-info {display: none !important;}",'
            +'                   "customText": {'
            // +'                                  "cg-popup-p":"' + this.appService.translate('EX_PAYMENT.popup-description') + '",'
            +'                                  "cg-popup-title": "' + this.appService.translate('EX_PAYMENT.wallet-popup-title') + '",'
            +'                                  "cg-sp-desc":"' + this.appService.translate('EX_PAYMENT.description') + '",'
            +'                                  "cg-submit-btn":"' + this.appService.translate('EX_PAYMENT.save') + '",'
            +'                                  "cg-form-title":"' + this.appService.translate('EX_PAYMENT.form-title') + '",'
            +'                                  "cg-clear":"' + this.appService.translate('EX_PAYMENT.clear') + '",'
            +'                                  "label-card-number":"' + this.appService.translate('EX_PAYMENT.credit_card_number') + '",'
            +'                                  "label-expYear": "' + this.appService.translate('EX_PAYMENT.expiration') + '",'
            +'                                  "cg-cancel":"' + this.appService.translate('EX_PAYMENT.cancel') + '",'
            +'                                  "cg-key-currency":"' + this.appService.translate('EX_PAYMENT.currency') + '",'
            +'                                  "cg-pd-title":"' + this.appService.translate('EX_PAYMENT.transaction_details') + '",'
            +'                                  "cg-amount-title":"' + this.appService.translate('EX_PAYMENT.total_amount') + '",'
            +'                                  "cg-popup-button":"' + this.appService.translate('EX_PAYMENT.click_here') + '"'
            +'                                 },'
            +'                   "paymentMethods": [{ "hidden": true, "type": "applepay" },{ "hidden": true, "type": "googlepay" }]'
            +'                  }'
            +'}'

        const initialRequestURL = '/online-shopper/customers/current/wallet/setup';
        const payload = { paymentType, amount, redirectUrlOnSuccess: `${baseURL}success`, iframeConfigurations };

        // Wallet payment - try to open iframe
        this.appService.logger({
            message: 'Wallet payment - open iframe',
            walletPayment: payload
        });

        return this.entityService.post(
            initialRequestURL,
            payload
        ).then(res => {
            // Wallet payment - got iframe link
            this.appService.logger({
                message: 'Wallet payment - got iframe link',
                walletPayment: res
            });

            this.dialogsService.showCreditGuardDialog(res)
            .then(res => {
                if (res?.mode == 'success') {
                    // Wallet payment - Got token from CG
                    this.appService.logger({
                        message: 'Wallet payment - got token from CG',
                        walletPayment: res
                    });

                    const payload = {
                        ownerName: this.appService.user?.loyaltyCustomer?.FirstName,
                        paymentType: 'creditGuard',
                        providerInfo: {
                            providerTransactionId: res.transactionId
                        }
                    };

                    // Wallet payment - Start of J5 payment
                    this.appService.logger({
                        message: 'Wallet payment - trying a J5 in ROS',
                        walletPayment: payload
                    });

                    this.addPM(payload)
                    .then(res => {
                        // Wallet payment - Successful transaction (saved payment)
                        this.appService.logger({
                            message: 'Wallet payment - trying a J5 in ROS',
                            walletPayment: payload
                        });

                        handleWalletStopBlock(res, false);
                    }).catch(err => {
                        handleWalletStopBlock(err);
                    })
                } else {
                    handleWalletStopBlock(res, false);
                }
            }).catch(err => {
                handleWalletStopBlock(err);
            });
        }).catch(err => {
            handleWalletStopBlock(err);
        });

        function handleWalletStopBlock(response, ifError: boolean = true) {
            that.setInitialCcInfoState();
            that.appService.stopBlock();
            console.debug(response);

            if (ifError) {
                // Wallet payment - Error in saving wallet payment
                that.appService.logger({
                    message: 'Wallet payment - Error saving wallet payment',
                    walletPayment: response
                });

                that.appService.mainMessage({
                    dialogType: 'error',
                    dialogTitle: '',
                    dialogText: that.appService.translate('error_client_credentials'),
                    primaryButtonText: 'CLOSE',
                    hideSecondaryButton: true
                });
            }
        }
    }

    public setInitialCcInfoState() {
        this.ccinfo = {
            paymentTypeView: "",
            paymentType: "",
            type: undefined
        };
    }

    public showExpDate(payment) {
        const shownExpDateTypes = ['creditCard', 'creditGuard', 'CreditGuard'];
        return shownExpDateTypes.includes(payment.paymentType);
    }

}
