

    <div class="module-bar trans wl-app-primary-background-color">
        <div class="_inner">
            <button mat-icon-button class="back-button" (click)="goBack()">
                <mat-icon>{{appService.backIcon}}</mat-icon>
            </button>
            <span class="title flex-grow-1 text-truncate" preserveTextDirectionByLocale fontSizeByTextLength>{{siteName}}</span>
            <notifications-widget-opener></notifications-widget-opener>
            <button class="closeBtn" mat-button color="accent" (click)="close((!$storage?.order || $storage?.order?.closed  == true) ? false :  true  )">
                <mat-icon class="close">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="module-content d-flex flex-column" *ngIf="!loading">
        <!--AMOUNT STEP-->
        <mat-tab-group class="wiz-tabs full-height hide-headers" [(selectedIndex)]="step">
            <mat-tab label="Step 1">
                <tabitpay-enter-order (enterOrder)="lookUpOrder($event)" (loadOrder)="loadOrder($event)" *ngIf="step === 0 && !$storage.freezeAutoOrderUpdate"></tabitpay-enter-order>
            </mat-tab>
            <!--PM STEP-->
        <mat-tab label="Step 2">
                <tabitpay-ticket (onContinue)="gotoPaymentClick($event)" *ngIf="$storage.order"></tabitpay-ticket>
            </mat-tab>

            <mat-tab label="Step 3">
                <tabitpay-checkout-full [options]="childOptions" (onPayment)="onPayment($event)" *ngIf="step == 2"></tabitpay-checkout-full>
            </mat-tab>

        </mat-tab-group>
    </div>
