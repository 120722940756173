<div *ngIf="domain?.signInBackgroundType === 'video'" class="video_container">
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" preload="auto">
        <source [src]="getMediaSource(false)" type="video/mp4">
    </video>
    <div class="overlay"></div>
</div>

<!-- In case signInImageBackground is true, we must have a proper image in background-image path -->
<div
    *ngIf="getMediaSource(true) && domain?.signInBackgroundType === 'image'"
    class="image-container"
    [style.background-image]="getMediaSource(true) | safeStyle"
></div>

<div class="container" [class.default-background]="!getMediaSource(true)">
    <div class="message-container">
        <div class="message" 
        [ngStyle]="getMessageDirection()">
        <markdown [data]="message">{{message}}</markdown>
        </div>
    </div>
</div>