


<div mat-dialog-title class="d-flex justify-content-between align-items-center dialog-title" style="margin-bottom:14px">
    <div class="h4 no-margins">
        {{data.title | translate}}
    </div>
    <button aria-label="close" mat-icon-button mat-dialog-close cdkFocusInitial>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content class="key-pad">
    <div class="_value" [ngClass]="{'text-danger': response.invalid}">
        <div>&nbsp;{{response.amountText}}&nbsp;</div>
        <div class="_currency">{{data.currency}}</div>
    </div>
    <div *ngIf="response.invalid" class="_error text-danger">{{ 'KEYPAD_DIALOG_WARNING' | translate: { maxVal: data.maxVal, currency: data.currency } }}</div>
    <div class="row keys">
        <div class="col-4 btn-keypad" *ngFor="let key of keys">
            <button mat-fab color="primary" (click)="setAmaountKey(key)" [disabled]="key == null">
                <ng-container *ngIf="key === null">{{key}}</ng-container>
                <ng-container *ngIf="key != 'back'">{{key}}</ng-container>
                <ng-container *ngIf="key == 'back'"><mat-icon>backspace</mat-icon></ng-container>
            </button>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <div class="text-center flex-grow-1">
        <button type="button" mat-stroked-button color="accent" [mat-dialog-close]="response.wasChanged || response.amount == 0 ? response : null" class="lg rounded btn-block b-drak" [disabled]="response.invalid">
            {{(data.confirmText || 'OK') | translate}}
        </button>
    </div>    
</div>


