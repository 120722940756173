

<div class="module-bar">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1">{{'my_preferences' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>



<div class="module-content">

    <div class="_inner">
        <div class="_box">
            <div class="_title">{{'my_preferred_tags' | translate}}</div>
                <div *ngFor="let group of groupedTags" class="tags">
                    <div class="filters-divider"></div>
                    <div class="filters-title d-flex justify-content-right">{{group.name | translate}}</div>
                    <div class="filters-container">
                        <mat-chip-list [multiple]="true">
                            <mat-chip  
                                [class.selected]="prefs.foods.indexOf(tag._id) != -1"
                                class="justify-content-center"
                                *ngFor="let tag of group.tagsArray"
                                selectable="true"
                                color="accent"
                                [selected]="prefs.foods.indexOf(tag._id) != -1"
                                (click)="tagToggle($event, 'foods', tag)"
                            >{{tag.lang[appService.appConfig.locale.toLocaleLowerCase()].label}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
        </div>

        <div class="filters-divider"></div>

        <div class="_box">
            <div class="_title">{{'my_preferred_allergies' | translate}}</div>
            <div>
                <div class="row row-sm">
                    <div class="col-3" *ngFor="let member of allergies">
                        <div class="img-chip" 
                              [class.selected]="prefs.allergies.indexOf(member._id) != -1"
                              (click)="categoryToggle($event, 'allergies', member)">
                            <div class="_avatar" [style.background-image]="member.image | safeStyle"></div>
                            <div class="_title">{{member.name}}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

