import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { AppService } from '../../app.service';
import { LoyaltyService } from '../../_core/loyalty.service';
import { DialogsService } from '../../_core/dialogs.service';

@Component({
    selector: 'benefit-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './benefit-card.component.html',
    styleUrls: ['./benefit-card.component.scss'],
    host: {class: 'wl-app-primary-background-color'}
})
export class BenefitCardComponent implements OnInit {

    @Input() points: any;
    @Input() prePaidPoints: any;
    @Input() benefit: any;
    @Input() club: any;
    @Input() showBenefits: boolean;
    public icon: string;
    public description: string;
    public pointsNickname: string;
    public currencySign: string;
    public isWhiteLabel: boolean = false;

    @HostListener('click', ['$event.target']) onClick() {
        if (this.isWhiteLabel || this.showBenefits) return this.showBenefitDetails();

    }

    private type: string;

    constructor(
        public appService: AppService,
        public loyaltyService: LoyaltyService,
        public dialogsService: DialogsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.appService.isWhiteLabel.subscribe(isWhiteLabel => this.isWhiteLabel = isWhiteLabel);
        this.setIcon();
        this.setDescription();
        this.setCurrencyForPrePaid();
        this.setPointsName();
        this.changeDetectorRef.detectChanges();
    }

    private setIcon() {
        this.type = (this.points || this.loyaltyService.checkPrepaidFlow(this.prePaidPoints)) ? 'Points' : this.benefit.programBenefitTypeAlias;
        switch (this.type) {
            case "Points":
                this.icon = this.appService.base(`assets/images/${this.prePaidPoints ? 'vouchers' : 'points'}.png`);
                break;
            case "BirthdayBenefit":
                this.icon = this.appService.base('assets/images/birthday.png');
                break;
            case "AnniversaryBenefit":
                this.icon = this.appService.base('assets/images/wedding.png');
                break;
            case "JoinBenefit":
            case "RenewBenefit":
                this.icon = this.appService.base('assets/images/join.png');
                break;
            case "PunchCard":
                this.icon = this.appService.base('assets/images/punch-card.png');
                break;
            case "PointsBenefit":
                this.icon = this.appService.base('assets/images/points.png');
                break;
            case "Other":
            case "Vouchers":
            default:
                this.icon = this.appService.base('assets/images/other.png');
                break;
        }
    }

    private setDescription() {
        switch (this.type) {
            case "BirthdayBenefit":
                this.description = this.appService.translate('brithday_benefit');
                break;
            case "AnniversaryBenefit":
                this.description = this.appService.translate('anniversary_benefit');
                break;
            case "JoinBenefit":
                this.description = this.appService.translate('join_benefit');
                break;
            case "RenewBenefit":
                this.description = this.appService.translate('renew_benefit');
                break;
            case "Vouchers":
                this.description = this.appService.translate('voucher_benefit');
                break;
            case "PunchCard":
                this.description = this.benefit?.moreInfo?.headerForApp ? this.benefit.moreInfo.headerForApp : this.appService.translate('punch_card');
                break;
            case "PointsBenefit":
                this.description = this.appService.translate('points_benefit');
                break;
            case "Other":
            default:
                this.description = this.benefit?.moreInfo?.headerForApp ? this.benefit.moreInfo.headerForApp : this.appService.translate('general_benefit');
                break;
        }
    }

    private setCurrencyForPrePaid() {
        this.currencySign = this.appService.appConfig.locale === 'he-IL' ? this.appService.currency : this.appService.us_currency;
    }

    private setPointsName() {
        if (!this.points) return;
        this.pointsNickname = this.club?.pointsNickname || this.appService.translate('points');
    }

    showBenefitDetails() {
        // We naviagte to topUp if needed
        if (this.topUpIfNeeded()) this.loyaltyService.redirectToGiftCards('topUp');
        else {
            this.setDetailsData();
            this.dialogsService.showBenefitDetailsDialog();
        };
    }

    setDetailsData() {
        if (this.points && this.benefit) return this.appService.benefitDetailsDataSubject.next({ points: this.club.points, benefit: this.benefit, pointsNickname: this.pointsNickname });
        if (this.points && !this.benefit) return this.appService.benefitDetailsDataSubject.next( { points: this.club.points } );
        if (this.loyaltyService.checkPrepaidFlow(this.prePaidPoints) && !this.benefit) return this.appService.benefitDetailsDataSubject.next( { prePaid: true, points: this.club.points } );
        if (this.loyaltyService.checkPrepaidFlow(this.prePaidPoints) && this.benefit) return this.appService.benefitDetailsDataSubject.next( { prePaid: true, points: this.club.points, benefit: this.benefit } );
        if (this.benefit) return this.appService.benefitDetailsDataSubject.next( { benefit: this.benefit, club: this.club.benefits } );
    }

    getPrePaidCaption(): string {
        if (this.loyaltyService.topUpEnabled()) {
            return (this.prePaidPoints || 0) < 500 ? 'immediate_topup' : 'immediate_realization';
        }
        return 'prePaid_realization';
    }

    topUpIfNeeded() {
        return this.appService.skin && this.loyaltyService.checkPrepaidFlow(this.prePaidPoints) && this.loyaltyService.topUpEnabled();
    }

}
