import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { TimerUtilsService } from '../../_core/timer.utils.service';

@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.scss']
})
export class SmsDialogComponent implements OnInit, OnDestroy {

    message: string;
    phoneIsMissing: boolean = false;
    disabled: boolean = false;
    customerNumber: string;
	verificationCode: string = '';
    countdownValue$ : Observable<number> = undefined;
    countdownDone: boolean = false;

    private destroy$ = new Subject();

	constructor(
        private ngZone: NgZone,
		public appService: AppService,
		public entityService: EntityService,
		public dialogRef: MatDialogRef<SmsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.phoneIsMissing = this.data.phoneIsMissing ? true : false;
		let messageKey = this.data.isRetry ? 'MESSAGES.PAYMENT_CHALLENGE_RETRY' : 'MESSAGES.PAYMENT_CHALLENGE';
        
        if (this.data.dialogMessage && !this.data?.isRetry) {
            this.message = this.appService.translate(this.data.dialogMessage, { phone: this.formatPhoneNumber(this.data.phone) });
        } else {
            this.message = this.appService.translate(messageKey, { phone: this.formatPhoneNumber(this.data.phone) });
        }
        if (this.phoneIsMissing && !this.data?.isRetry) {
            this.message = this.appService.translate('MESSAGES.PAYMENT_ENTER_PHONE');
        }
	}

    ngOnInit(): void {
        if (!this.phoneIsMissing) {
            this.startResendCodeCountdown();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

	apply(form) {
		if (!form.valid) return;
		this.dialogRef.close(this.customerNumber || this.verificationCode);
	}

    resendCode() {
        this.disabled = true;
        this.verificationCode = '';
        this.startResendCodeCountdown();

        const getVerificationCode = this.data.verificationCodeRequest;

        if (getVerificationCode) {
            getVerificationCode(this.data.phone)
            .catch(err => {
                const errorKey = get(err.error.error, "Key");
                let dialogText = 'error_general';

                if (errorKey === 'CannotCreateNewPinCode') {
                    dialogText = 'MESSAGES.TOO_MANY_CODE_REQUESTS'
                } else if (errorKey === 'InvalidPinCode') {
                    dialogText = 'MESSAGES.PHONE_VERIFICATION_FAILED';
                }

                console.debug('sms-dialog > resendCode > Error:', err);
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogTitle: 'error_title',
                        dialogText,
                        secondaryButtonHidden: true
                    });
                });
            })
            .finally(() => this.disabled = false);
        }
    }

    startResendCodeCountdown(): void {
        this.countdownDone = false;

        this.countdownValue$ = TimerUtilsService.startCountdownTimer(30, 1000, this.destroy$)
            .pipe(tap(value => {
                if (value === 0) {
                    this.countdownDone = true;
                }
            }));
    }

    formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return match[1] + '-' + match[2] + '' + match[3];
        }
        return phoneNumberString;
    }

    get validCodeLength(): boolean {
        return this.verificationCode?.length >= 4;
    }

}
