<form (ngSubmit)="_signUp()" [formGroup]="formGroup">
    <div class="d-flex flex-column content" [class.dialog-content]="dialogMode">
                <!-- <div class="icon-container" *ngIf="dialogMode">
            <mat-icon [svgIcon]="'spontani_title'"></mat-icon>
        </div> -->
        <div class="flex-grow-1">
            <div *ngIf="appService.skin" class="wl-title">
                <div>{{'MESSAGES.wl_sign_up_title' | translate}}</div>
                <!-- <div class="club-name">{{clubName}}</div> -->
            </div>
            <div *ngIf="!appService.skin" class="title">{{'MESSAGES.sign_up_message' | translate}}</div>

            <mat-form-field appearance="outline">
                <mat-label> {{'FIRST_NAME' | translate}} </mat-label>
                <input
                    (focus)="_onFocus($event)"
                    matInput
                    [readonly]="disabled"
                    type="text"
                    formControlName="firstName"
                    [required]="isRequiredField('firstName')"
                >
                <mat-icon matSuffix (click)="clearField('firstName')" *ngIf="formGroup.value.firstName">close</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label> {{'LAST_NAME' | translate}} </mat-label>
                <input
                    (focus)="_onFocus($event)"
                    matInput
                    [readonly]="disabled"
                    type="text"
                    formControlName="lastName"
                    [required]="isRequiredField('lastName')"
                >
                <mat-icon matSuffix (click)="clearField('lastName')" *ngIf="formGroup.value.lastName">close</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label> {{'email' | translate}} </mat-label>
                <input
                    (focus)="_onFocus($event)"
                    matInput
                    [readonly]="disabled"
                    type="email"
                    formControlName="email"
                    [required]="isRequiredField('email')"
                >
                <mat-icon matSuffix (click)="clearField('email')" *ngIf="formGroup.value.email">close</mat-icon>
            </mat-form-field>

            <!-- * domain-specific fields -->
            <ng-container *ngIf="domain?.defaults?.signupFormSettings?.extraFormFields as extraFormFields">
                <div class="extra-field-container" *ngFor="let extraFormField of extraFormFields">
                    <div class="extra-field">
                    <!-- birthDate -->
                    <ng-container *ngIf="extraFormField.name == 'birthDate'">
                    <mat-label>{{extraFormField.name | translate}}{{isRequiredField(extraFormField.name) ? ' *' : ''}}</mat-label>
                    <span class="date-error-message" *ngIf="birthDateErrorMessage">{{'SIGN-UP-DATE-ERROR' | translate}}</span>
                        <div class="date-field-container" style="display: flex;">
                            <mat-form-field appearance="outline">
                                <mat-label> {{'day' | translate}} </mat-label> 
                                <input
                                    (focus)="_onFocusDeleteContent($event,'birthDateDay','birthDate')"
                                    matInput
                                    [readonly]="disabled"
                                    type="tel"
                                    formControlName="birthDateDay"
                                    placeholder="DD"
                                    autocomplete="off"
                                    min="1" max="31"
                                    (change)="onDateChange('birthDate', false)"
                                    [required]="birthDateRequired"
                                    (keyup)="focusNextNumberOfCharacter($event, 2)"
                                    focusIndex="1"
                                >
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label> {{'MONTH' | translate}} </mat-label>
                                <input
                                    (focus)="_onFocusDeleteContent($event,'birthDateMonth','birthDate')"
                                    matInput
                                    [readonly]="disabled"
                                    type="tel"
                                    formControlName="birthDateMonth"
                                    placeholder="MM"
                                    autocomplete="off"
                                    min="1" max="12"
                                    (change)="onDateChange('birthDate', false)"
                                    [required]="birthDateRequired"
                                    (keyup)="focusNextNumberOfCharacter($event, 2)"
                                    focusIndex="2"
                                >
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label> {{'YEAR' | translate}} </mat-label>
                                <input
                                    (focus)="_onFocusDeleteContent($event,'birthDateYear','birthDate')"
                                    matInput
                                    [readonly]="disabled"
                                    type="tel"
                                    formControlName="birthDateYear"
                                    placeholder="YYYY"
                                    autocomplete="off"
                                    (change)="onDateChange('birthDate', false)"
                                    [required]="birthDateRequired"
                                    (keyup)="focusNextNumberOfCharacter($event, 4)"
                                    focusIndex="3"
                                >
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <!-- anniversary -->
                    <ng-container *ngIf="extraFormField.name == 'anniversary'">
                        <mat-label>{{extraFormField.name | translate}}{{isRequiredField(extraFormField.name) ? ' *' : ''}}</mat-label>
                        <span class="date-error-message" *ngIf="anniversaryErrorMessage">{{'SIGN-UP-DATE-ERROR' | translate}}</span>
                            <div class="date-field-container" style="display: flex;">
                                <mat-form-field appearance="outline">
                                    <mat-label> {{'day' | translate}} </mat-label> 
                                    <input
                                        (focus)="_onFocusDeleteContent($event,'anniversaryDay','anniversary')"
                                        matInput
                                        [readonly]="disabled"
                                        type="tel"
                                        formControlName="anniversaryDay"
                                        placeholder="DD"
                                        autocomplete="off"
                                        (change)="onDateChange('anniversary', false)"
                                        [required]="anniversaryRequired"
                                        (keyup)="focusNextNumberOfCharacter($event, 2)"
                                        focusIndex="4"
                                    >
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label> {{'MONTH' | translate}} </mat-label>
                                    <input
                                        (focus)="_onFocusDeleteContent($event,'anniversaryMonth','anniversary')"
                                        matInput
                                        [readonly]="disabled"
                                        type="tel"
                                        formControlName="anniversaryMonth"
                                        placeholder="MM"
                                        autocomplete="off"
                                        (change)="onDateChange('anniversary', false)"
                                        [required]="anniversaryRequired"
                                        (keyup)="focusNextNumberOfCharacter($event, 2)"
                                        focusIndex="5"
                                    >
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label> {{'YEAR' | translate}} </mat-label>
                                    <input
                                        (focus)="_onFocusDeleteContent($event,'anniversaryYear','anniversary')"
                                        matInput
                                        [readonly]="disabled"
                                        type="tel"
                                        formControlName="anniversaryYear"
                                        placeholder="YYYY"
                                        autocomplete="off"
                                        (change)="onDateChange('anniversary', false)"
                                        [required]="anniversaryRequired"
                                        (keyup)="focusNextNumberOfCharacter($event, 4)"
                                        focusIndex="6"
                                    >
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <!--
            <mat-form-field appearance="outline">
                <mat-label> {{'phone' | translate}} </mat-label>
                <input matInput [readonly]="disabled" [placeholder]="appService.translate('phone')" type="tel" [(ngModel)]="formGroup.value.phone" formControlName="phoneControl" required>
                <mat-icon matSuffix (click)=" null" *ngIf="formGroup.value.phone">close</mat-icon>
            </mat-form-field>
            -->

            <div class="disclaimer" *ngIf="!appService.skin">
                <consent-text></consent-text>
            </div>

            <!-- custom disclaimer for WL apps -->
            <ng-container *ngIf="appService.skin">
                <div class="disclaimer-container">
                    <mat-checkbox [checked]="false" formControlName="IsFilledJoinForm"></mat-checkbox>
                    <div class="disclaimer with-approval">
                        <consent-text></consent-text>
                    </div>
                </div>

                <div class="disclaimer-container" *ngIf="mailingConfirmShow">
                    <mat-checkbox [checked]="false" [disabled]="!mailingConfirmEditable && formGroup.value.IsConfirmSms" formControlName="IsConfirmSms"></mat-checkbox>
                    <div class="disclaimer with-approval">
                        {{'approve_commercials' | translate}}
                    </div>
                </div>
            </ng-container>

            <!-- login button -->
            <div class="pt-2">
                <ng-container *ngIf="!disabled">
                    <button *ngIf="appService.skin" [disabled]="isFormValidForMailingConfirmRequiredField()" class="login-button btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{'registration' | translate}}</button>
                    <button *ngIf="!appService.skin" class="login-button btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="submit">{{ ( !dialogMode ? 'registration' : 'SIGN_UP_TABIT') | translate}}</button>
                </ng-container>
                <button *ngIf="disabled" class="login-btn btn btn-lg btn-trans btn-block" [class.dialog-button]="dialogMode" type="button">
                    <mat-spinner diameter="24" strokeWidth="2" class="disabled-spinner" [class.light-grey]="dialogMode"></mat-spinner>
                </button>
            </div>

            <div class="pt-2" *ngIf="!dialogMode">
                <button type="button" class="btn btn-link btn-lg btn-block wl-ignore-theme" (click)="_back()" [disabled]="disabled">
                    {{'back' | translate}}
                </button>
            </div>
        </div>
    </div>
</form>
<div class="spacer_btm"></div>