import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppService } from './app.service';
import { MarketingService } from './_core/marketing-tools.service';
import { NativePaymentsService } from './_core/native-payments.service';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    public mainBundlesLoaded = new BehaviorSubject<boolean>(false);

    constructor(
        private appService: AppService,
        private marketingTools: MarketingService,
        private nativePaymentsService: NativePaymentsService,
    ) { }

    Init() {
        return new Promise<void>((resolve, reject) => {
            console.debug('App Init Service > Init Occurred');
            this.mainBundlesLoaded.next(true);

            try {
                this.nativePaymentsService.initExternalPayScripts();
                this.marketingTools.initFBPixel();
                this.initRecaptcha();
            } catch(err) {
                this.appService.logger({ message: 'App Init Service > Error In External Script load' });
            }

            resolve();
        });
    }

    public initRecaptcha() {
        if (environment.appConfig?.recaptchaSiteKey) {
            const recaptchaScript = document.createElement('script');
            recaptchaScript.async = true;
            recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.appConfig.recaptchaSiteKey}`;
            document.head.appendChild(recaptchaScript);
        }

        this.appService.toggleReCaptcha(true);
    }
}
