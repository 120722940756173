
<div class="wl-app-primary-background-color widget-content">
    <div class="wl-cards-background-color widget-header">
        <div class="widget-title">
            {{ 'NOTIFICATIONS_WIDGET.messages' | translate }}
        </div>
        <div class="widget-clear-button" (click)="clearMessages()">
            <mat-icon>clear_all</mat-icon>
            <span class="button-title">{{ 'NOTIFICATIONS_WIDGET.clear_all'  | translate }}</span>
        </div>
        <div class="widget-close-button" (click)="setIsOpened(false)">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="header-divider"></div>
    <div class="no-notifications-container" *ngIf="!(notifications | async)?.length">
        <ng-lottie [options]="{path: widgetAnimationPath, autoplay: true, loop: true}"></ng-lottie>
        <div class="message-container">
            <div class="_subtitle">{{'notifications.got_all_notifications' | translate}}</div>
            <div class="_message">{{'notifications.promise_to_inform' | translate}}</div>
        </div>
    </div>
    <div class="notifications-container" *ngIf="(notifications | async)?.length">
        <notification *ngFor="let notification of (notifications | async)" [notification]="notification"></notification>
    </div>
</div>
