import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { Address, AddressesService } from '../../_core/addresses.service';
import { Subscription } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';

@UntilDestroy()
@Component({
    selector: 'app-my-addresses',
    templateUrl: './my-addresses.component.html',
    styleUrls: ['./my-addresses.component.scss']
})
export class MyAddressesComponent implements OnInit, OnDestroy {

    @ViewChild('addressFormPanel', {static: false}) private addressFormPanel: MatExpansionPanel;
    @ViewChild('addressesPanel', {static: false}) private addressesPanel: MatExpansionPanel;

    public addresses: Address[];

    public loadingAddresses: boolean = true;

    private addressesSubscription: Subscription;
    private addressesLoadingSubscription: Subscription;

    public cameFrom: boolean;

    constructor(
        public appService: AppService,
        private addressesService: AddressesService,
        private route: ActivatedRoute,
        public ngZone: NgZone,
    ) {}

    ngOnInit() {

        let initialAction: string;

        this.route.paramMap.subscribe(params => {
            initialAction = params.get('initial-action');
            this.cameFrom = params.has('came-from');
        }).unsubscribe();

        this.addressesSubscription = this.addressesService.addresses.subscribe(addresses => {
            // console.log('Receive addresses:', JSON.stringify(addresses), addresses.length);
            this.addresses = addresses;
            setTimeout(() => { // Wait for the template to get out of panel "disabled state"
                if (!this.addresses.length || initialAction === 'new') {
                    this.addressesPanel.close();
                    this.addressFormPanel.open();
                } else {
                    this.addressesPanel.open();
                    this.addressFormPanel.close();
                }
            });
        });

        this.addressesLoadingSubscription = this.addressesService.loading.subscribe(loading => this.loadingAddresses = loading);

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
        this.appService.androidBackButton
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.appService.goBack()
                });
            });
        }

    }

    ngOnDestroy() {

        this.addressesSubscription.unsubscribe();
        this.addressesLoadingSubscription.unsubscribe();

    }

    saveAddress(address: Address) {
        this.addressesService.saveAddress(address).subscribe(() => {
            if (this.cameFrom) this.appService.goBack();
        }, err => {
            console.error('Error saving address:', err);
        });
    }

    deleteAddress(address: Address) {
        this.addressesService.deleteAddress(address);
    }

    changeDefault(change: MatRadioChange) {
        if (change?.value) this.addressesService.setDefault(change.value);
    }

}
