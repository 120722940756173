<div class="web-container-inner-wrapper" (click)="webMobileHeaderSearchOpened = false" ngxDetectScroll (onScroll)="handleScroll($event)" [class.app-smart-banner-open]="appService.webAppSmartBannerOpened">

    <mat-sidenav-container class="mobile-web-header-tabs-sidenav" [hidden]="appService.isDesktop()">
        <mat-sidenav position="start" #mobilewebTabsSidenav>
            <web-header-tabs></web-header-tabs>
        </mat-sidenav>
    </mat-sidenav-container>

    <div class="mobile-web-tabs-header-container" [class.mobile-sidenav-open]="webMobileTabsSidenavOpened" *ngIf="!appService.isDesktop()" [@WebAppSmartBannerOpened]="appService.webAppSmartBannerOpened ? 'opened' : 'closed'">

        <web-app-smart-banner></web-app-smart-banner>

        <div class="mobile-web-tabs-header-container-wrapper">
            <mat-icon
            *ngIf="!webBackButton"
            class="right-hand-action"
            (click)="webContainerService.toggle(true)"
            [@mobileWebHeaderSearchOpened]="webMobileHeaderSearchOpened ? 'opened' : 'closed'"
        >menu</mat-icon>

        <mat-icon
            *ngIf="webBackButton"
            class="right-hand-action"
            (click)="navigateBack()"
            [@mobileWebHeaderSearchOpened]="webMobileHeaderSearchOpened ? 'opened' : 'closed'"
        >{{appService.backIcon}}</mat-icon>

        <img (click)="appService.redirect(['/'])" [class.search-open]="webMobileHeaderSearchOpened" class="mobile-web-header-image" [src]="getMediaPath()" [@mobileWebHeaderSearchOpened]="webMobileHeaderSearchOpened ? 'opened' : 'closed'" />
        <app-web-header-search [class.opened]="webMobileHeaderSearchOpened" [(searchFieldOpen)]="webMobileHeaderSearchOpened" (click)="$event.stopPropagation()"></app-web-header-search>
        <div class="mobile-web-header-spacer"></div>
        </div>
    </div>

    <div class="web-page" [class.mobile]="!appService.isDesktop()" [class.mobile-sidenav-open]="webMobileTabsSidenavOpened">
        <web-header-tabs *ngIf="appService.isDesktop()"></web-header-tabs>
        <div [@routeAnimation]="getAnimationTag(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
    </div>

</div>
