import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';

@Component({
    selector: 'app-tabitbook-alt',
    templateUrl: './tabitbook-alt.component.html',
    styleUrls: ['./tabitbook-alt.component.scss']
})
export class TabitbookAltComponent implements OnInit {
    @Input() options;

    $storage: any;


    constructor(
        public appService: AppService,
        public bookService: BookService,
    ) {
        this.$storage = this.bookService.$storage;
    }

    selectAltSlot(area, slot) {
        this.options.selectAltSlot(area, slot);
    }

    addToWaitingList() {
        this.options.postTempReservation(true);
    }

    ngOnInit() {

    }

}
