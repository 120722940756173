import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { StyleService } from '../../../_core/style.service';
import { AppService } from '../../../app.service';
import { TabitpayService } from '../tabit-pay.service';

declare const $: any;

@Component({
    selector: 'tabitpay-enter-order',
    templateUrl: './tabitpay-enter-order.component.html',
    styleUrls: ['./tabitpay-enter-order.component.scss']
})
export class TabitpayEnterOrderComponent implements OnInit, OnDestroy {
    @Input() options;
    @Output() enterOrder = new EventEmitter<any>();
    @Output() loadOrder = new EventEmitter<any>();

	$storage: any;
	orderNO: string;

    constructor(
        public appService: AppService,
        public orderService: TabitpayService,
        public styleService: StyleService,
    ) {
        this.$storage = this.orderService.$storage;
    }

    ngOnInit() {

	}

	onOrderNoChange(newValue) {
		this.orderNO = newValue.toUpperCase();
	}

    extractFromQR(url) {
        this.closeScanner();
        let args: any = {};
        let that = this;
        url.split("?")[1].split("&").forEach((val, index) => {
            let valArr = val.split("=");
            args[valArr[0]] = valArr[1]
        });
        if (args.site && args.oid) {
            that.closeScanner();
            that.loadOrder.emit(args);
        }
    }

    ngOnDestroy() {
        this.styleService.mainAppComponentTransparent = false;
    }

    lookUpOrder(ev?) {
        this.closeScanner();
        this.enterOrder.emit(this.orderNO);
    }

    closeScanner() {
        try {
            this.styleService.mainAppComponentTransparent = false;
        } catch (e) {
            alert(e.description)
        }
    }


}
