<form #messageForm="ngForm">

    <button aria-label="close" *ngIf="closeButtonActive" class="close-button" mat-icon-button mat-dialog-close cdkFocusInitial>
        <mat-icon>close</mat-icon>
    </button>

    <ng-container [ngSwitch]="mode">
        <div class="title" *ngIf="data.title">
            {{data.title | translate}}
        </div>

        <div mat-dialog-content *ngSwitchCase="'prompt'">
            <div *ngIf="data.content" class="_content">
                <div class="{{data.contentClass}}">{{data.content | translate}}</div>
            </div>

            <ng-container [ngSwitch]="data.template">

                <ng-container *ngSwitchCase="'idCard'">
                    <mat-form-field class="w-100">
                        <mat-label>{{'ID_CARD' | translate}}</mat-label>
                        <input [(ngModel)]="value" name="idCard" matInput type="tel" required pattern="[0-9]*"
                               validIdCard #idCard="ngModel">
                        <!--<mat-error *ngIf="idCard.dirty && idCard.errors?.creditCard">{{idCard.errors.creditCard | translate}}</mat-error>-->
                    </mat-form-field>
                </ng-container>

                <ng-container *ngSwitchCase="'textInput'">
                    <div class="text-container">
                        <textarea id="address-description" maxlength="250" [(ngModel)]="value" [ngModelOptions]="{standalone: true}" required></textarea>
                        <mat-error *ngIf="hasError && !value">
                            {{ data.errorText || 'please_enter_value' | translate}}
                        </mat-error>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <mat-form-field class="w-100" *ngIf="!data.numeric">
                        <mat-label>{{data.label  | translate}}</mat-label>
                        <input autocomplete="off" matInput placeholder="{{data.palceholder | translate}}" [(ngModel)]="value" name="value" required>
                    </mat-form-field>
                    <mat-form-field class="w-100" *ngIf="data.numeric">
                        <mat-label>{{data.label  | translate}}</mat-label>
                        <input autocomplete="off" matInput placeholder="{{data.palceholder | translate}}" [(ngModel)]="value" name="value" required type="number" pattern="[0-9]*" inputmode="numeric" [maxlength]="data.maxLength || 4">
                    </mat-form-field>
                </ng-container>

            </ng-container>
        </div>

        <!-- <div mat-dialog-content *ngSwitchCase="'signature'" class="p-0 border-top border-bottom">
            <div *ngIf="data.content" class="p-3">{{data.content | translate}}</div>

            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
        </div> -->

        <div mat-dialog-content *ngSwitchDefault>
            <div class="text-center" *ngIf="data.animation">
                <ng-lottie [options]="{path: data.animation, autoplay: true, loop: false}" [height]="200"></ng-lottie>
            </div>
            <div *ngIf="data.content && (!data.template || data.template !== 'dynamicContent')" class="_content-sm" style="white-space: pre-wrap;">{{data.content | translate}}</div>
            <div *ngIf="data.template === 'dynamicContent' && data.content.length">
                <div class="dynamic-content" *ngFor="let item of data.content">
                    <div class="item">
                        <markdown [data]="item.text">{{item.text}}</markdown>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container [ngSwitch]="mode">
        <div mat-dialog-actions *ngSwitchCase="'alert'">
            <button *ngIf="!data.buttonImage" type="button" mat-flat-button color="accent" [mat-dialog-close]="true" class="rounded">
                {{(data.confirmText || 'OK') | translate}}
            </button>
            <button *ngIf="data.buttonImage" type="button" mat-button [mat-dialog-close]="true">
                <img [src]="data.buttonImage" />
            </button>
        </div>

        <div mat-dialog-actions *ngSwitchCase="'confirm'">
            <button type="button" mat-stroked-button color="accent" [mat-dialog-close]="false" class="rounded">
                {{(data.cancelText || 'Cancel') | translate}}
            </button>
            <button type="button" mat-flat-button color="accent" [mat-dialog-close]="true" class="rounded">
                {{(data.confirmText || 'OK') | translate}}
            </button>
        </div>

        <div mat-dialog-actions *ngSwitchCase="'prompt'">
            <button type="button" mat-stroked-button color="accent" [mat-dialog-close]="false" class="rounded secondary-button">
                {{(data.cancelText || 'Cancel') | translate}}
            </button>
            <button type="button" mat-flat-button color="accent" (click)="confirmPrompt(messageForm)" [disabled]="hasError" class="rounded primary-button">
                {{(data.confirmText || 'OK') | translate}}
            </button>
        </div>

        <div mat-dialog-actions *ngSwitchCase="'signature'">
            <button type="button" mat-stroked-button color="accent" (click)="clear()" class="rounded">
                {{'clear' | translate}}
            </button>
            <button type="button" mat-stroked-button color="accent" [mat-dialog-close]="false" class="rounded">
                {{(data.cancelText || 'Cancel') | translate}}
            </button>
            <button type="button" mat-flat-button color="accent" [mat-dialog-close]="value" class="rounded">
                {{(data.confirmText || 'OK') | translate}}
            </button>
        </div>


    </ng-container>
</form>