<div class="spinner-container" *ngIf="!siteFromBridge">
    <mat-spinner diameter="80" strokeWidth="5" class="light-grey"></mat-spinner>
</div>
<div class="main-container">
    <div class="more-info-container" [style.max-height]="moreInfoContainerElementHeight + 'px'" *ngIf="siteFromBridge">
        <div class="more-info-title-container">
            <div class="title-header">
                <button aria-label="close" mat-button class="close" [mat-dialog-close]="true">
                    <mat-icon [svgIcon]="'gc-close'"></mat-icon>
                </button>
            </div>
            <div class="description-wrapper">

            </div>
        </div>

        <div class="header-data" *ngIf="siteFromBridge?.name || stylesAndKosherTags?.length"
            [class.about-missing]="!siteFromBridge?.siteDetailsPage?.about && siteImages.length">
            <div class="more-info-title-container" *ngIf="siteFromBridge?.name">
                <div class="_title">{{ siteFromBridge?.name }}</div>
            </div>
            <div class="site-tags" *ngIf="stylesAndKosherTags?.length">
                {{stylesAndKosherTags.slice(0, 5).join(' | ')}}
            </div>
        </div>
        <div class="body-info-container">
            <div class="message-container" *ngIf="siteFromBridge?.siteDetailsPage?.about">
                <div class="message" [style.max-height]="messageElementHeight+ 'px'">{{siteFromBridge?.siteDetailsPage?.about}}
                </div>
            </div>

            <div class="bottom-aria-container">
                <div class="site-images-container" *ngIf="siteImages?.length">
                    <ng-container *ngFor="let img of siteImages | slice:0:imagesSliceNumber | orderBy: index">
                        <div class="img-container">
                            <marketplace-image (click)="marketplaceService.openGallery(siteImages, img?.index)" [borderRadius]="'1rem'" [imageAlt]="img.alt || 'image'"
                                class="card-img dark-theme" [imageUrl]="img.photo_url">
                            </marketplace-image>
                        </div>
                    </ng-container>
                </div>

                <div class="site-icons-container" *ngIf="accessTags?.length || (dialogData?.giftCardSite?.BusinessAddress || siteFromBridge?.address) || (dialogData?.giftCardSite?.Phone || siteFromBridge?.phone)">
                    <div class="more-info-accessible-container" *ngIf="accessTags.length">
                        <mat-icon [svgIcon]="'gc-warning'"></mat-icon>
                        {{accessTags.slice(0, 5).join(' | ')}}
                    </div>
                    <div class="more-info-address-container" *ngIf="dialogData?.giftCardSite?.BusinessAddress || siteFromBridge?.address">
                        <mat-icon [svgIcon]="'gc-location'"></mat-icon>
                        <button class="btn location">
                            {{ dialogData?.giftCardSite?.BusinessAddress || siteFromBridge?.address }}
                        </button>
                    </div>
                    <div class="more-info-phone-number-container" *ngIf="dialogData?.giftCardSite?.Phone || siteFromBridge?.phone">
                        <mat-icon [svgIcon]="'gc-vector'"></mat-icon>
                        <button class="phone-number btn" (click)="phoneClick(dialogData?.giftCardSite?.Phone || siteFromBridge?.phone)">
                            {{ dialogData?.giftCardSite?.Phone || siteFromBridge?.phone }}
                        </button>
                    </div>
                </div>
                <div class="more-info-action-btn-container" *ngIf="siteFromBridge">
                    <button type="button" class="button" (click)="redirectToGiftCard()">
                        {{ 'marketplace.button_text' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>