import { Component, OnInit, OnDestroy, EventEmitter, NgZone, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { AddressesService, Address, AddressesSearch } from '../../_core/addresses.service';

@Component({
    selector: 'app-address-dialog',
    templateUrl: './address-dialog.component.html',
    styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit, OnDestroy {
    addressTextChange: EventEmitter<Event> = new EventEmitter();

    private addressTextChangeSubscription: Subscription;
    public googleAddressPredictions: {formatted_address: string, place_id: string}[] = [];
    public googleAddressPicked: Address;
    public googleAddressPickedIndex: number;

    public searchingAddress: boolean = false;
    public searchedAddressOnce: boolean = false;

    private addressesSearch: AddressesSearch;

    constructor(
        public dialogRef: MatDialogRef<AddressDialogComponent>,
        private appService: AppService,
        public addressesService: AddressesService,
        public ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {
        this.addressesSearch = this.setAddressSearch();
        this.addressTextChangeSubscription = this.addressTextChange.pipe(
            map(event => (<HTMLInputElement>event.target).value), // Typescript casting... https://stackoverflow.com/a/44321394/4645369
            filter(this.hasToBeginSearch.bind(this)),
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe(addressFreeText => {

            if (!addressFreeText) {
                this.searchingAddress = false;
                return;
            }

            this.googleAddressPicked = undefined;

            forkJoin([
                this.addressesSearch.getGoogleAddressPredictions(addressFreeText),
                this.addressesService.getAddressFromBridgeByName(addressFreeText)
            ]).subscribe(([googleAddresses, bridgeAddresses]: [any, any]) => {
                this.googleAddressPredictions = (googleAddresses).concat(bridgeAddresses || []);
                // console.debug('=== /ADDRESS/DIALOG === response for google predictions:', this.googleAddressPredictions);
                this.ngZone.run(() => { // Required because otherwise Angular (for some reason) doessn't detect the change and doesn't display the results in the DOM.
                    this.googleAddressPickedIndex = undefined;
                    if (!this.searchedAddressOnce) this.searchedAddressOnce = true;
                    this.searchingAddress = false;
                });
            }, err => {
                console.error('Searching address predicitons failed:', err);
                this.searchingAddress = false;
            });
        });
    }

    setAddressSearch() {
        if (!this.data.houseRequired) return this.addressesService.newAddressesSearch(['street_address', 'establishment', 'premise', 'locality', 'route']);
        return this.addressesService.newAddressesSearch(['street_address', 'route']);
    }

    hasToBeginSearch(addressFreeText): boolean {
        if (addressFreeText?.length > 2) {
            this.searchedAddressOnce = false;
            this.searchingAddress = true;
            return true;
        } else {
            return false;
        }
    }

    onFreeAddressTextChange(event: Event) {
        this.addressTextChange.next(event);
    }

    chooseAddress(address: {formatted_address: string, place_id: string, isAdditionalAddress?: boolean, enablePartialAddress?: boolean}) {
        // console.log('=== ADDRESS/DIALOG === address picked:', address);
        if (!address.isAdditionalAddress) {
            this.addressesSearch.getLocationForPlaceId(address.place_id, address.formatted_address).subscribe((finalAddresses: Address[]) => {
                // console.log('=== ADDRESS/DIALOG === final address from google:', finalAddresses[0]);
                if (this.data.houseRequired == true) {
                    if (finalAddresses?.length && finalAddresses[0].house) {
                        this.googleAddressPicked = finalAddresses[0];
                    } else {
                        this.errorDialog('full_address');
                    }
                } else if (finalAddresses?.length) {
                    this.googleAddressPicked = finalAddresses[0];
                }
            }, err => {
                console.error('Error getting selected address from google:', err);
                this.errorDialog('invalid_address');
                this.googleAddressPicked = undefined;
            });
        } else {
            if (address) {
                let convertedAddress = this.convertAddress(address);
                if (this.data.houseRequired == true && !address.enablePartialAddress) {
                    if (convertedAddress.house) {
                        this.googleAddressPicked = convertedAddress;
                    } else {
                        this.errorDialog('full_address');
                    }
                } else {
                    this.googleAddressPicked = convertedAddress;
                }
            } else {
                console.error('Error getting selected address from Bridge');
            }
        }
    };

    errorDialog(type: string) {
        let dialogTitle: string, dialogText: string;

        switch (type) {
            case 'full_address':
                dialogTitle = 'WHERE_CAN_WE_FIND_YOU';
                dialogText = 'error_full_address_required'
            break;
            case 'invalid_address': 
                dialogTitle = 'error_title';
                dialogText = 'error_invalid_address';
            break;
        }

        this.appService.mainMessage({
            dialogType: 'error',
            dialogText,
            dialogTitle,
            primaryButtonText: 'continue',
            hideSecondaryButton: true
        });

        this.googleAddressPickedIndex = undefined;
    }

    convertAddress(address: any): Address {
        const convertedAddress: Address = {
            ...address,
            formatted_address: address.formatted_address,
            location: address.location,
            house: address.house,
            street: address.street,
            city: address.city
        };

        return convertedAddress;
    }

    ngOnDestroy() {
        this.addressTextChangeSubscription.unsubscribe();
    }

}
