import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { AppService } from '../../app.service';

@Component({
    selector: 'tracked-order-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './tracked-order-item.component.html',
    styleUrls: ['./tracked-order-item.component.scss'],
    host: {class: 'wl-cards-background-color'}
})
export class TrackedOrderItemComponent implements OnInit {

    @Input() public order: any;
    @Input() siteName:string;
    
    public amount: number;
    public image: string;
    public name: string;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
        this.amount = this.order.totals.totalAmount / 100;
        this.image = this.order.serviceType == 'takeaway' ? this.appService.base('assets/images/his_ta.svg') : this.appService.base('assets/images/his_delivery.svg')
    }

    _redirectToOrderTracker() {
        let queryParams = {
            'order-id': this.order.id,
            'organization-id': this.order.organization,
            'displayMode': 'full'
        };

        if (this.order.onlySummaryDisplay) queryParams.displayMode = 'summary';

        this.appService.redirect(['/app-order-tracker'], { queryParams });
    }

}
