import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../app.service';

@Component({
    selector: 'app-gallery-dialog',
    templateUrl: './gallery-dialog.component.html',
    styleUrls: ['./gallery-dialog.component.scss']
})
export class GalleryDialogComponent implements OnInit {
    public selectedImage: any;
    public showLeftArrow: boolean;
    public showRightArrow: boolean;

    private isKeyEventHandled = false;
    private keyEventCooldownDuration = 250;

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft' && this.showLeftArrow) {
            this.moveGallery(-1);
        } else if (event.key === 'ArrowRight' && this.showRightArrow) {
            this.moveGallery(1);
        }
    }

    constructor(
        public appService: AppService,
        public dialogRef: MatDialogRef<GalleryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.selectedImage = this.data.images.find(image => image.index == this.data.index);
    }

    ngOnInit() {
        this.appService.setStatusBarStyle('light');
        this.setDirectionArrows();
    }

    moveGallery(op) {
        // If the key event is already handled or within the cooldown period, return
        if (this.isKeyEventHandled) return;

        const newIndex = this.data.index + op;
        if (newIndex < 0 || newIndex >= this.data.images.length) return;

        // Set isKeyEventHandled to true and start the cooldown
        this.isKeyEventHandled = true;
        setTimeout(() => {
            this.isKeyEventHandled = false;
        }, this.keyEventCooldownDuration);

        this.data.index = newIndex;
        this.setDirectionArrows();
        this.selectedImage = this.data.images.find(image => image.index == newIndex);
    }

    ngOnDestroy() {
        this.appService.setStatusBarStyle('dark');
    }

    setDirectionArrows() {
        if (!this.data.images || this.data.images.length <= 1) {
            return false; // If there's only one image or no images, don't show arrows
        }

        const currentIndex = this.data.index;
        const imagesLength = this.data.images.length;

        // Show left arrow if the current index is greater than 0
        this.showLeftArrow = currentIndex > 0;

        // Show right arrow if the current index is less than the total number of images minus 1
        this.showRightArrow = currentIndex < (imagesLength - 1);
    }

}
