<block-ui>
	<div class="module-bar">
		<div class="_inner wl-app-primary-background-color">
			<button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
				<mat-icon>{{appService.backIcon}}</mat-icon>
			</button>
            <span class="title flex-grow-1">{{'my_account' | translate}}</span>
            <notifications-widget-opener></notifications-widget-opener>
			<!--<button class="menu" mat-icon-button (click)="appService.toggleAppSidenav()">
				<mat-icon class="search">menu</mat-icon>
			</button>-->
		</div>
	</div>
	<div class="module-content wl-app-primary-background-color">
		<div class="generic-desc wl-cards-background-color">{{'my_account_description' | translate}}</div>
        <div class="accordion-container spacer-bottom">
            <mat-accordion *ngIf="accountService?.userDetails?.loyaltyCustomer">
                <mat-expansion-panel [expanded]="accountService.accountStep === 'general'" (opened)="accountService.accountStep = 'general'">
                    <mat-expansion-panel-header class="wl-app-primary-background-color">
                        <mat-panel-title class="font-bold">{{appService.translate('personal_information')}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <form (ngSubmit)="submitForm(accountForm)" #accountForm="ngForm">
                        <mat-form-field>
                            <input [(ngModel)]="accountService.userDetails.loyaltyCustomer.FirstName" name="FirstName" matInput [maxlength]="maxlength" placeholder="{{'first_name' | translate}}" required>
                        </mat-form-field>
                        <mat-form-field>
                            <input [(ngModel)]="accountService.userDetails.loyaltyCustomer.LastName" name="LastName" matInput [maxlength]="maxlength" placeholder="{{'last_name' | translate}}" required>
                        </mat-form-field>
                        <mat-form-field>
                            <input [(ngModel)]="accountService.userDetails.loyaltyCustomer.Email" name="Email" type="email" [maxlength]="maxlength" email matInput placeholder="{{'email' | translate}}" required class="ltr-field">
                        </mat-form-field>
                        <mat-form-field>
                            <input [(ngModel)]="accountService.userDetails.loyaltyCustomer.Mobile" name="Mobile" type="tel" matInput placeholder="{{'mobile' | translate}}" disabled>
                        </mat-form-field>

                         <!-- * domain-specific fields -->
                        <ng-container *ngIf="domain?.defaults?.signupFormSettings?.extraFormFields as extraFormFields">
                            <div class="extra-field-container" *ngFor="let extraFormField of extraFormFields">

                                <!-- birthDate -->
                                <div>
                                    <ng-container *ngIf="extraFormField.name == 'birthDate'">
                                        <mat-label class="mat-hint">{{extraFormField.name | translate}}{{isRequiredField(extraFormField.name, accountForm) ? ' *' : ''}}</mat-label>
                                        <span class="date-error-message" *ngIf="birthDateErrorMessage">{{'SIGN-UP-DATE-ERROR' | translate}}</span>
                                            <div class="date-field-container" style="display: flex;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label> {{'YEAR' | translate}} </mat-label>
                                                    <input
                                                        (focus)="_onFocusDeleteContent($event,'birthDateYear', 'birthDate', accountForm)"
                                                        [disabled]="birthDateEditable"
                                                        name="birthDateYear"
                                                        matInput
                                                        [readonly]="false"
                                                        type="tel"
                                                        [(ngModel)]="birthDateYear"
                                                        placeholder="YYYY"
                                                        autocomplete="off"
                                                        (keyup)="focusNextNumberOfCharacter($event, 4, accountForm)"
                                                        (change)="onDateChange('birthDate', false, accountForm, 'birthDateYear')"
                                                        tabIndex="6"
                                                        [required]="birthDateRequired"
                                                    >
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <mat-label> {{'MONTH' | translate}} </mat-label>
                                                    <input
                                                        (focus)="_onFocusDeleteContent($event,'birthDateMonth', 'birthDate', accountForm)"
                                                        [disabled]="birthDateEditable"
                                                        name="birthDateMonth"
                                                        matInput
                                                        [readonly]="false"
                                                        type="tel"
                                                        [(ngModel)]="birthDateMonth"
                                                        placeholder="MM"
                                                        autocomplete="off"
                                                        min="1" max="12"
                                                        (keyup)="focusNextNumberOfCharacter($event, 2, accountForm)"
                                                        (change)="onDateChange('birthDate', false, accountForm, 'birthDateMonth')"
                                                        tabIndex="5"
                                                        [required]="birthDateRequired"
                                                    >
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <mat-label> {{'day' | translate}} </mat-label> 
                                                    <input
                                                        (focus)="_onFocusDeleteContent($event, 'birthDateDay', 'birthDate', accountForm)"
                                                        [disabled]="birthDateEditable"
                                                        name="birthDateDay"
                                                        matInput
                                                        [readonly]="false"
                                                        type="tel"
                                                        [(ngModel)]="birthDateDay"
                                                        placeholder="DD"
                                                        autocomplete="off"
                                                        min="1" max="31"
                                                        (keyup)="focusNextNumberOfCharacter($event, 2, accountForm)"
                                                        (change)="onDateChange('birthDate', false, accountForm, 'birthDateDay')"
                                                        tabIndex="4"
                                                        [required]="birthDateRequired"
                                                    >
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                
                                <!-- anniversary -->
                                <ng-container *ngIf="extraFormField.name == 'anniversary'">
                                    <mat-label class="mat-hint">{{extraFormField.name | translate}}{{isRequiredField(extraFormField.name, accountForm) ? ' *' : ''}}</mat-label>
                                    <span class="date-error-message" *ngIf="anniversaryErrorMessage">{{'SIGN-UP-DATE-ERROR' | translate}}</span>
                                        <div class="date-field-container" style="display: flex;">
                                            <mat-form-field appearance="outline">
                                                <mat-label> {{'YEAR' | translate}} </mat-label>
                                                <input
                                                    (focus)="_onFocusDeleteContent($event,'anniversaryYear', 'anniversary', accountForm)"
                                                    [disabled]="anniversaryEditable"
                                                    matInput
                                                    [readonly]="false"
                                                    type="tel"
                                                    name="anniversaryYear"
                                                    [(ngModel)]="anniversaryYear"
                                                    placeholder="YYYY"
                                                    autocomplete="off"
                                                    (keyup)="focusNextNumberOfCharacter($event, 4,accountForm)"
                                                    (change)="onDateChange('anniversary', false, accountForm, 'anniversaryYear')"
                                                    [required]="anniversaryRequired"
                                                    tabIndex="9"
                                                >
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label> {{'MONTH' | translate}} </mat-label>
                                                <input
                                                    (focus)="_onFocusDeleteContent($event,'anniversaryMonth', 'anniversary', accountForm)"
                                                    [disabled]="anniversaryEditable"
                                                    matInput
                                                    [readonly]="false"
                                                    type="tel"
                                                    name="anniversaryMonth"
                                                    [(ngModel)]="anniversaryMonth"
                                                    placeholder="MM"
                                                    autocomplete="off"
                                                    (keyup)="focusNextNumberOfCharacter($event, 2, accountForm)"
                                                    (change)="onDateChange('anniversary', false, accountForm, 'anniversaryMonth')"
                                                    tabIndex="8"
                                                    [required]="anniversaryRequired"
                                                >
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label> {{'day' | translate}} </mat-label> 
                                                <input
                                                    (focus)="_onFocusDeleteContent($event,'anniversaryDay', 'anniversary', accountForm)"
                                                    [disabled]="anniversaryEditable"
                                                    matInput
                                                    [readonly]="false"
                                                    type="tel"
                                                    name="anniversaryDay"
                                                    [(ngModel)]="anniversaryDay"
                                                    placeholder="DD"
                                                    autocomplete="off"
                                                    (keyup)="focusNextNumberOfCharacter($event, 2, accountForm)"
                                                    (change)="onDateChange('anniversary', false, accountForm, 'anniversaryDay')"
                                                    tabIndex="7"
                                                    [required]="anniversaryRequired"
                                                >
                                            </mat-form-field>
                                        </div>
                                </ng-container>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="appService.skin">
                            <div class="mb-2 confirm-club" *ngIf="accountService?.userDetails?.loyaltyCustomer?.IsFilledJoinForm">
                                <mat-checkbox class="example-margin" [(ngModel)]="accountService.userDetails.loyaltyCustomer.IsFilledJoinForm" name="IsFilledJoinForm" disabled >
                                </mat-checkbox>
                                <span class="consent-text-container">
                                    {{'i_approve_the' | translate}}
                                    <span class="loyalty-terms" *ngIf="loyaltyTermsURL">
                                        <a (click)="openLink('loyaltyTerms')">{{'club_terms' | translate}}</a>{{', '}}
                                    </span>
                                    <a class="privacy-policy" (click)="openLink('privacy')">
                                        {{'privacy_policy' | translate}}
                                    </a>
                                    {{'and' | translate}}
                                    <a class="terms-and-conditions" (click)="openLink('terms')">
                                        {{'terms_and_conditions' | translate}}
                                    </a>
                                </span>
                            </div>
                            <div class="mb-2" *ngIf="smsConfirmShow && accountService?.userDetails?.loyaltyCustomer">
                                <mat-checkbox class="example-margin" [required]="smsConfirmRequired" [disabled]="!smsConfirmEditable && IsConfirmSmsDisabled" [(ngModel)]="accountService.userDetails.loyaltyCustomer.IsConfirmSms" name="IsConfirmSms">
                            </mat-checkbox>
                            {{'approve_sms' | translate}}
                            </div>
                            <div class="mb-2" *ngIf="mailingConfirmShow && accountService?.userDetails?.loyaltyCustomer">
                                <mat-checkbox class="example-margin" [required]="mailingConfirmRequired" [disabled]="!mailingConfirmEditable && IsConfirmMailDisabled" [(ngModel)]="accountService.userDetails.loyaltyCustomer.IsConfirmMail" name="IsConfirmMail">
                            </mat-checkbox>
                            {{'approve_mail' | translate}}
                            </div>
                        </ng-container>

                        <div class="text-far mt-4">
                            <button type="submit" class="submit-my-account-button" mat-flat-button color="primary" [disabled]="!accountForm.valid">
                                {{'update_account'  | translate}}
                            </button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
	</div>
</block-ui>