import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';

import { each, map, round, sumBy } from 'lodash-es';

import { AppService } from '../../../../app.service';

@Component({
  selector: 'app-tabitpay-offer-select',
  templateUrl: './tabitpay-offer-select.component.html',
  styleUrls: ['./tabitpay-offer-select.component.scss']
})
export class TabitpayOfferSelectComponent {
    $storage: any;
    total: number = 0;

    offers: {
        id: string,
        name: string;
        price: number;
        selected: boolean;
    }[];

    @ViewChild('list') list: MatSelectionList;

    constructor(
        public appService: AppService,
        public dialogRef: MatDialogRef<TabitpayOfferSelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.$storage = this.data.$storage;
        const selectedOffers = map(this.data.selectedOffers, o => o.id);
        this.offers = []; let id = 0;
        each(this.$storage.order.printData.collections.ORDERED_OFFERS_LIST, (offer, index) => {
            const base_id = `${++id}`;
            let total = 0, roundTo = 2;//this.appService.regionArgs.decimals;
            for (let i = 0; i < offer.OFFER_QTY; i++) {
                if (offer.ON_THE_HOUSE) continue;
                let realPrice = offer.OFFER_AMOUNT / offer.OFFER_QTY;
                let discounts = sumBy(offer.ORDERED_OFFER_DISCOUNTS, 'DISCOUNT_AMOUNT') / offer.OFFER_QTY;
                let price = round(realPrice - discounts, roundTo);


                if (price <= 0) continue;
                total += price;

                const id = `${base_id}_${i}`;
                const selected = selectedOffers.includes(id);
                if (i > 0 && i == (offer.OFFER_QTY - 1)) {
                    if (offer.OFFER_AMOUNT < total) price += round(total - offer.OFFER_AMOUNT, roundTo)
                }
                const _offer: any = {
                    id: id,
                    name: offer.OFFER_NAME,
                    price: price,
                    selected: selected
                }
                if (discounts) _offer.originalPrice = round(realPrice, roundTo);
                this.offers.push(_offer);
                if (selected) {
                    this.total += _offer.price;
                }
            }
        });
    }

    cancel() {
        this.dialogRef.close();
    }
    onOfferSelected(list, ev) {
        if (ev) ev.stopPropagation();
        setTimeout(() => {
            let total = 0;
            list.selectedOptions.selected.forEach(item => {
                if (item.value.price) {
                    total += item.value.price;
                }
            });
            this.total = total;
        }, 100);
    }

    apply() {
        this.dialogRef.close({
            amount: this.total,
            selectedOffers: this.list.selectedOptions.selected.map(o => o.value),
        });
    }

}
