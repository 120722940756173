import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { isFunction, union, isEqual, omit } from 'lodash-es';

import { LocationLabeled } from './location.service';

export enum ORGANIZATION_BUCKET_TYPES {
    nearbyAll = 1,
    nearbyTabit,
    favorites,
    search,
    searchOnMap,
    new,
    booking,
    order,
    homeOrder,
    tabitpay,
    extra, // History orgs, Future Reservation orgs, etc. (these orgs are not shown in the lists)
    pepperPay,
    nearbySubGroup,
}

export interface SearchDetails {
    service?: 'tabitpay' | 'delivery' | 'takeaway';
    query?: string;
	skip?: number;
	bounds?: {};
    booking?: {
        timestamp: string,
        seats_count: string
    };
    tags?: string[];
    rating?: number;
    price?: number;
    externalDeliveryLink?: boolean;
    onlyTabit?: boolean;
    onlyAvailable?: boolean;
    hasLeumiPayment?: boolean;
}

export interface OrgsChange {
    orgs?: any[];
    type: ORGANIZATION_BUCKET_TYPES;
    clear?: boolean;
    prepared?: boolean;
}

interface OrgsIdsChange {
    ids: string[];
    clear?: boolean;
}

export class OrganizationBucket {

    private type: ORGANIZATION_BUCKET_TYPES;
    public name: string;

    private syncToLocalStorage: boolean = false;

    private searchDetails: SearchDetails = null;
    private preSearchDetails: BehaviorSubject<SearchDetails> = new BehaviorSubject(null);
    private usingExtendedResults: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private nextSkip: BehaviorSubject<number> = new BehaviorSubject(0);
    private preventSearchMore: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private lastSearchTimeStamp: number = 0;

    private organizationGetter: (id: string) => any;
    private locationGetter: () => LocationLabeled;
    private searchMethod: (searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES, searchLocation: LocationLabeled) => Observable<any[]>;

    private ids: BehaviorSubject<string[]> = new BehaviorSubject([]);

    public orgsSubject = this.ids.pipe(
        map(orgIds => (orgIds && orgIds.length) ? orgIds.map(id => this.getOrganization(id)) : []),
    );

    public preSearchDetailsObservable = this.preSearchDetails.asObservable();
    public usingExtendedResultsObservable = this.usingExtendedResults.asObservable();
    public nextSkipObservable = this.nextSkip.asObservable();
    public preventSearchMoreObservable = this.preventSearchMore.asObservable();

    private lastVisited: string = null;

    constructor(
        type: ORGANIZATION_BUCKET_TYPES,
        name: string,
        organizationGetter: (id: string) => any,
        locationGetter: () => LocationLabeled,
        searchMethod: (searchDetails: SearchDetails, type: ORGANIZATION_BUCKET_TYPES, searchLocation: LocationLabeled) => Observable<any[]>,
    ) {
        if (!isFunction(organizationGetter)) throw new Error('Cannot create organization bucket, bad organization getter');
        if (!isFunction(locationGetter)) throw new Error('Cannot create organization bucket, bad location getter');
        if (!isFunction(searchMethod)) throw new Error('Cannot create organization bucket, bad search method');
        this.type = type;
        this.name = name;
        this.organizationGetter = organizationGetter;
        this.locationGetter = locationGetter;
        this.searchMethod = searchMethod;
    }

    private getOrganization(id: string): any {
        return this.organizationGetter(id);
    }

    public getCurrentIds(): string[] {
        return this.ids.getValue();
    }

    public num(): number {
        return this.ids.getValue().length;
    }

    public error(err: Error) {
        this.ids.error(err);
    }

    public update(orgsIdsChange: OrgsIdsChange) {
        if (orgsIdsChange.clear) {
            this.ids.next(orgsIdsChange.ids);
        } else {
            this.ids.next(union(this.ids.getValue(), orgsIdsChange.ids));
        }

        if (this.syncToLocalStorage) {
            this.saveOrgsAtLocalStorage();
        }

    }

    public setLastVisited(orgId: string): void {
        this.lastVisited = orgId;
    }

    public getLastVisited(): string {
        if (this.lastVisited && this.getCurrentIds().indexOf(this.lastVisited) >= 0) return this.lastVisited;
        return null;
    }

    public search(searchDetails: SearchDetails, searchLocation: LocationLabeled) {
        if (!searchDetails) throw new Error('use clearSearch() to clear orgs search');
        this.searchDetails = searchDetails;
        this.lastSearchTimeStamp = searchLocation.timestamp;
        return this.searchMethod(this.searchDetails, this.type, searchLocation);
    }

    public clearSearch() {
        this.searchDetails = null;
    }

    public getSearchDetails(): SearchDetails {
        return this.searchDetails;
    }

    public setPreSearchDetails(searchDetails: SearchDetails) {
        this.preSearchDetails.next(searchDetails);
    }

    public isPreSearchEqualToLastSearch(searchDetails: SearchDetails): boolean {
        if (this.locationGetter().timestamp !== this.lastSearchTimeStamp) return false;
        return isEqual(omit(this.searchDetails, 'skip'), omit(searchDetails, 'skip'));
    }

    public setIfUsingExtendedResults(value: boolean): void {
        this.usingExtendedResults.next(value);
    }

    public setNextSkip(value: number): void {
        this.nextSkip.next(value);
    }

    public setPreventSearchMore(value: boolean): void {
        this.preventSearchMore.next(value);
    }

    public setSyncToLocalStorage(value: boolean): void {
        this.syncToLocalStorage = value;
    }

    private saveOrgsAtLocalStorage() {
        this.orgsSubject.pipe(first()).subscribe(orgs => {
            let key = this.getBucketLocalStorageName(this.type);
            // console.log(`=== BUCKET: '${this.name}' === Will save now orgs at local storage: '${key}'`);
            if (orgs?.length) window.localStorage.setItem(key, JSON.stringify(orgs));
        });
    }

    public getOrgsFromLocalStorage(type?: ORGANIZATION_BUCKET_TYPES): any[] {
        let key = this.getBucketLocalStorageName(type || this.type);
        try {
            return JSON.parse(window.localStorage.getItem(key));
        } catch (err) {
            console.error(`Bad localStorage item: ${key}`);
            return null;
        }
    }

    private getBucketLocalStorageName(type: ORGANIZATION_BUCKET_TYPES): string {
        return `state__${ORGANIZATION_BUCKET_TYPES[type]}Orgs`;
    }

    public resaveOrgsAtLocalStorage(orgs) {
        const key = this.getBucketLocalStorageName(this.type);
        window.localStorage.setItem(key, JSON.stringify(orgs));
    }

}
