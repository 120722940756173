<div class="site-title-desc-container">
    <h1 class="title" tabindex="0">
        <!-- <img *ngIf="!site.external" class="tabit-badge" [src]="tabitBadgeSrc" /> -->
        {{site.name || ('restaurants' | translate)}}
    </h1>

    <div *ngIf="site.subtitle"
        tabindex="0"
        class="description">
        <h3 class="description-inner">{{site.subtitle}}</h3>
    </div>

    <div role="region" class="site-categories" *ngIf="site.tags.length"
    tabindex="0" [attr.aria-label]="('WEB_CONTAINER.booking_bar.tags' | translate)">
        <span class="category-name" *ngFor="let tag of this?.site?.tags | translateSiteTagsAsync; let last = last;">
            {{tag}}{{ last ? '' : ' | ' }}
        </span>
    </div>

    <div tabindex="0" class="rating" *ngIf="site.googlePlaceDetails?.rating && (appService.googlePlaceDetailsSubject | async).showRating">
        <rating [site]="site"></rating>
        <div class="by-google" dir="ltr">by
            <img class="google-icon" [src]="googleIconSrc" />
        </div>
    </div>

    <div tabindex="0" #siteDescription *ngIf="site.description" class="description long">
        <div class="description-inner">{{site.description}}</div>
    </div>
</div>