<div @eventCardAnimation class="event-card-box" [class.animate]="index <= 3" (click)="toggleFlip()" [@flipState]="flip">
    <div class="event-card_side event-card_front">
        <div class="img-container">
            <div class="card-img dark-theme" [style.background-image]="(siteEvent.image || org.largeImage || appService.images.default_site_image) | safeStyle"></div>
            <div class="promo-overlay" *ngIf="promoText"></div>
            <div class="time-promo" *ngIf="promoText">
                <mat-icon class="_clock" svgIcon="wall_clock"></mat-icon>
                <div class="_promo_text">{{promoText | translate}}</div>
            </div>
            <div class="front-tags-container">
                <div class="tag" *ngFor="let tag of siteEvent.tags | slice:0:3;">
                    {{tag.lang[appService.appConfig.locale.toLocaleLowerCase()].label}}
                </div>
            </div>
        </div>
        <div class="card-body d-flex align-items-start">
            <div class="text-truncate">
                <div class="card-title flex-grow-1 text-truncate">{{siteEvent.occasion_details.title}}</div>
                <div class="card-name text-truncate">{{org.name}}</div>
                <div class="rating-container" *ngIf="org.googlePlaceDetails && org.googlePlaceDetails.rating">
                    <rating [site]="org" [oneStar]="true"></rating>
                </div>
                <div class="card-time flex-grow-1 text-truncate">{{frontTime}}</div>
                <div class="address-container">
                    <div class="card-address flex-grow-1 text-truncate" *ngIf="org.address">{{org.address}}</div>
                </div>
            </div>
        </div>
        <div class="front-button-container">
            <ng-container *ngIf="siteEvent.reservation_details.reserved_from">
                <button *ngIf="isReservationActive" type="button" (click)="bookEvent($event, timestamp, org._id)" class="button">{{'book_a_place' | translate}}</button>
                <button *ngIf="!isReservationActive" type="button" (click)="_redirectToSite()" class="button">{{'to_restaurant' | translate}}</button>
            </ng-container>
        </div>
    </div>
    <div class="event-card_side event-card_back">
        <div class="back-tags-container">
            <div class="tag back" *ngFor="let tag of siteEvent.tags | slice:0:3;">
                {{tag.lang[appService.appConfig.locale.toLocaleLowerCase()].label}}
            </div>
        </div>
        <div class="description-container">
            <div class="_card-title flex-grow-1 text-truncate">{{siteEvent.occasion_details.title}}</div>
            <div class="card-long-description flex-grow-1" *ngIf="longDescription">{{longDescription}}</div>
            <div class="card-name text-truncate" *ngIf="!longDescription">{{org.name}}</div>
            <div class="card-time flex-grow-1 text-truncate">{{backTime.upperCaption}}</div>
            <div class="card-time flex-grow-1 text-truncate" *ngIf="backTime.lowerCaption">{{backTime.lowerCaption}}</div>
        </div>
        <div class="back-button-container">
            <button type="button" (click)="_redirectToSite()" class="button">{{'to_restaurant' | translate}}</button>
        </div>
    </div>
</div>