
<div class="module-bar wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/profile/my-profile'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1" style="font-size:1.5rem;">{{'account_removal' | translate}}</span>
        <notifications-widget-opener></notifications-widget-opener>
        <!--<button class="menu" mat-icon-button (click)="appService.toggleAppSidenav()">
            <mat-icon class="search">menu</mat-icon>
        </button>-->
    </div>
</div>

<div class="module-content wl-app-primary-background-color">
    <div class="_body spacer-bottom-small">
        <div class="_links">
            <div class="_link">
                <div class="smaller">
                    {{'REMOVE_ACCOUNT.disclaimer_1' | translate}} 
                </div>
            </div>
            <div class="_link">
                <div class="smaller">
                    {{'REMOVE_ACCOUNT.disclaimer_2' | translate}} 
                </div>
            </div>
            <div class="_link">
                <div class="smaller">
                    {{'REMOVE_ACCOUNT.disclaimer_3' | translate}} 
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <div (click)="deleteCustomer()" class="delete-button">
                <button>
                    {{'remove_account' | translate}}
                </button>
            </div>
            <div class="cancel-button" (click)="appService.redirect(['/profile/my-profile'])">
                {{ 'CANCEL' | translate }}
            </div>
        </div>
    </div>
</div>
