import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'marketplace-image',
  templateUrl: './marketplace-image.component.html',
  styleUrls: ['./marketplace-image.component.scss']
})
export class MarketplaceImageComponent implements OnInit {

    // optional inputs
    @Input('imageUrl') public imageUrl?: string;
    @Input('imageAlt') public imageAlt?: string;
    @Input('borderRadius') public borderRadius?: string;
    @Output() imageLoaded = new EventEmitter<any>();

    // hide the image until we set a stable src
    bootSequenceDone: boolean = false;
    // indicates an image loading error occurred
    invalidImageUrlFlag: boolean = false;

    constructor(
        private appService: AppService
    ) {}

    ngOnInit(): void {

    }

    // image loaded successfully
    imageLoadSuccessEvent() {
        this.bootSequenceDone = true;
        this.imageLoaded.emit(true);
    }

    // error loading the image
    imageLoadErrorEvent() {
        this.imageUrl = this.appService.images.default_site_image;
    }

}
