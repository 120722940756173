<form
    (ngSubmit)="apply(challengeForm)"
    #challengeForm="ngForm"
>
    <div
        mat-dialog-title
        class="d-flex justify-content-between align-items-center"
    >
        <div class="h6">
            {{ (data?.customTitle || 'PAYMENT_INFO') | translate }}
        </div>
        <button
            mat-icon-button
            mat-dialog-close
            [attr.aria-label]="'ACCESS.close' | translate"
            (click)="cancel()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <div [ngSwitch]="ccinfo.paymentMethod">

            <!--USED FOR UPDATING WALLET PAYMENT WITH MISSING ID OR CVV-->
            <div *ngSwitchCase="'wallet'">
                <!-- wallet card cvv -->
                <mat-form-field *ngIf="ccinfo.requireCVV">
                    <input
                        [(ngModel)]="ccinfo.cvv"
                        name="cvv"
                        matInput
                        placeholder="{{'cvv' | translate}}"
                        type="tel"
                        required
                        pattern="[0-9]*"
                        maxlength="4"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.cvv = null"
                        *ngIf="ccinfo.cvv"
                    >close</mat-icon>
                </mat-form-field>

                <!-- wallet card t.z -->
                <mat-form-field *ngIf="ccinfo.requireIdCard">
                    <mat-label>{{'ID_CARD' | translate}}</mat-label>
                    <input
                        [(ngModel)]="ccinfo.idCard"
                        name="idCard"
                        matInput
                        type="tel"
                        required
                        pattern="[0-9]*"
                        validIdCard
                        #idCard="ngModel"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.idCard = null"
                        *ngIf="ccinfo.idCard"
                    >close</mat-icon>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'creditCard'">
                <!-- card number -->
                <mat-form-field>
                    <mat-label>{{'credit_card_number' | translate}}</mat-label>
                    <input
                        [(ngModel)]="ccinfo.pan"
                        name="cardnumber"
                        id="cardNumber"
                        matInput
                        type="tel"
                        required
                        pattern="[0-9]*"
                        validCreditCard
                        [ccInfo]="ccinfo"
                        autocomplete="cc-number"
                        #cc="ngModel"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.pan = null"
                        *ngIf="ccinfo.pan"
                        class="mr-2"
                    >close</mat-icon>
                    <mat-icon
                        matSuffix
                        [svgIcon]="ccinfo && ccinfo.brand || 'cc'"
                        (click)="ccinfo.pan = null"
                    ></mat-icon>
                </mat-form-field>

                <!-- card expiration -->
                <div class="row small-gutters">
                    <div class="col-5">
                        <mat-form-field>
                            <mat-label>{{'expiration' | translate}}</mat-label>
                            <input
                                [(ngModel)]="ccinfo.expiration"
                                name="etad"
                                matInput
                                placeholder="mm-yy"
                                type="tel"
                                maxlength="5"
                                required
                                validCardExpiration
                                ccinfo="ccinfo"
                                class="ltr-field text-center"
                                autocomplete="cc-exp"
                                [ccInfo]="ccinfo"
                                #etad="ngModel"
                            >
                            <mat-icon
                                matSuffix
                                (click)="ccinfo.expiration = null"
                                *ngIf="ccinfo.expiration"
                            >close</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-7">
                        <!-- card cvv -->
                        <mat-form-field>
                            <input
                                [(ngModel)]="ccinfo.cvv"
                                name="cvv"
                                matInput
                                placeholder="{{'cvv' | translate}}"
                                type="tel"
                                required
                                pattern="[0-9]*"
                                class="text-center"
                                maxlength="4"
                            >
                            <mat-icon
                                matSuffix
                                (click)="ccinfo.cvv = null"
                                *ngIf="ccinfo.cvv"
                            >close</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <!-- card t.z -->
                <mat-form-field *ngIf="data.requireIdPhoneTrans">
                    <mat-label>{{'ID_CARD' | translate}}</mat-label>
                    <input
                        [(ngModel)]="ccinfo.idCard"
                        name="idCard"
                        matInput
                        type="tel"
                        required
                        pattern="[0-9]*"
                        validIdCard
                        #idCard="ngModel"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.idCard = null"
                        *ngIf="ccinfo.idCard"
                    >close</mat-icon>
                    <!--<mat-error *ngIf="idCard.dirty && idCard.errors?.creditCard">{{idCard.errors.creditCard | translate}}</mat-error>-->
                </mat-form-field>
            </div>

            <!-- Cibus -->
            <div *ngSwitchCase="'Cibus'">
                <mat-form-field>
                    <input
                        [(ngModel)]="ccinfo.pan"
                        name="cardnumber"
                        id="cardNumber"
                        matInput
                        placeholder="{{'CIBUS_CARD_NUMBER' | translate}}"
                        type="tel"
                        required
                        pattern="[0-9]*"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.pan = null"
                        *ngIf="ccinfo.pan"
                    >close</mat-icon>
                </mat-form-field>
            </div>

            <!-- TavHazahav -->
            <div *ngSwitchCase="'TavHazahav'">
                <mat-form-field>
                    <input
                        [(ngModel)]="ccinfo.pan"
                        name="cardnumber"
                        id="cardNumber"
                        matInput
                        placeholder="{{'credit_card_number' | translate}}"
                        type="tel"
                        required
                        pattern="[0-9]*"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.pan = null"
                        *ngIf="ccinfo.pan"
                    >close</mat-icon>
                </mat-form-field>
            </div>

            <!-- 10bis -->
            <div *ngSwitchCase="'10bis'">
                <mat-form-field>
                    <input
                        [(ngModel)]="ccinfo.pan"
                        name="cardnumber"
                        id="cardNumber"
                        matInput
                        placeholder="{{'10bis_card_number' | translate}}"
                        type="tel"
                        required
                        pattern="[0-9]*"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.pan = null"
                        *ngIf="ccinfo.pan"
                    >close</mat-icon>
                </mat-form-field>
            </div>
            <!-- loyaltyPM -->
            <div *ngSwitchCase="'loyaltyPM'">
                <mat-form-field>
                    <input
                        [(ngModel)]="ccinfo.pan"
                        name="cardnumber"
                        id="cardNumber"
                        matInput
                        placeholder="{{'loyaltypm_card_number' | translate}}"
                        type="tel"
                        required
                        pattern="[0-9]*"
                    >
                    <mat-icon
                        matSuffix
                        (click)="ccinfo.pan = null"
                        *ngIf="ccinfo.pan"
                    >close</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <ng-container *ngIf="enableSaveInWallet">
            <div class="mb-2">
                <mat-checkbox
                    name="updateWallet"
                    [(ngModel)]="ccinfo.updatePaymentInfo"
                >
                    {{'SAVE_ACCOUNT_PAYMENT_INFO' | translate}}
                </mat-checkbox>
            </div>
            <mat-form-field *ngIf="ccinfo.updatePaymentInfo">
                <input
                    [(ngModel)]="ccinfo.comment"
                    name="comment"
                    matInput
                    placeholder="{{'comments' | translate}}"
                >
            </mat-form-field>
        </ng-container>

    </div>

    <div
        mat-dialog-actions
        class="d-flex justify-content-between align-items-center pb-3"
    >
        <button
            type="submit"
            mat-flat-button
            color="accent"
            class="rounded lg block"
        >
            <div class="d-flex justify-content-between align-items-center">
                <span>{{'ADD_PAYMENT' | translate}}</span>
                <span
                    *ngIf="data.total"
                    class="px-2"
                >{{data.total | lcurrency}}</span>
                <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
            </div>
        </button>
    </div>
</form>