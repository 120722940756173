import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppService, Notification } from '../../app.service';
import { NotificationsService } from '../../_core/notifications.service';


@Component({
    selector: 'notifications-widget',
    templateUrl: './notifications-widget.component.html',
    styleUrls: ['./notifications-widget.component.scss'],
})

export class NotificationsWidgetComponent implements OnInit {

    notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

    showWidget: 'open' | 'close' = 'close';
    notificationsWidgetPaused: boolean = false;

    widgetAnimationPath = this.appService.getAnimationPath('new-mail');

    constructor (
        public appService : AppService,
        private notificationsService : NotificationsService,
    ) { }

    ngOnInit() {
        this.notificationsService.isOpened.subscribe(opened => {
            this.showWidget = opened ? 'open' : 'close';
        });

        this.notificationsService.notificationWidgetPause.subscribe(paused => {
            this.notificationsWidgetPaused = paused;
        });

        this.appService.notifications
        // .pipe(
        //     map(notifications => {
        //         console.log('=== NOTIFICATIONS/WIDGET === Got Array:', notifications);
        //         return notifications;
        //     })
        // )
        .subscribe(this.notifications);
    }

    clearMessages() {
        this.appService.deleteAllNotifications();
        this.setIsOpened(false);
    }

    setIsOpened(opened: boolean) {
        this.notificationsService.setIsOpened(opened);
    }
}
