
<div class="module-bar trans wl-app-primary-background-color">
    <div class="_inner">
        <div class="profile-header-icons">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        </div>
        <span class="title flex-grow-1">{{'profile' | translate}}</span>
        <div class="profile-header-icons global-notification">
            <notifications-widget-opener></notifications-widget-opener>
            <div *ngIf="domain?.multiLanguageEnabled">
                <div class="language-switcher">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon class="module-bar-language-icon globe-icon" [svgIcon]="'globe'"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let language of filteredLanguages" (click)="changeUsedLanguage(language.value)" [class]="selectedLanguageValue === language.value ? 'to-language-menu-selected':''">
                        {{ language.viewValue }}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="module-content wl-app-primary-background-color" *ngIf="currentUser">
    <div class="_top wl-app-secondary-background-color">
        <div class="_inner">
            <div *ngIf="!appService.skin" class="_avatar" [style.background-image]="currentUser.avatar | safeStyle"></div>
            <div class="_title d-flex align-items-center" routerLink="/profile/my-account">
                <div class="_row">
                    <span [class.without_span]="!appService.showNotifyIcon()">{{currentUser?.loyaltyCustomer?.FirstName}} {{currentUser?.loyaltyCustomer?.LastName}}</span>
                    <div class="_button">
                        <mat-icon class="edit-icon" [svgIcon]="'edit-profile'"></mat-icon>
                        <span *ngIf="appService.showNotifyIcon()">{{'update_details' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="_body spacer-bottom-small">
        <div class="contact-details-container wl-app-primary-background-color">
            <div class="button feedback" (click)="openFeedbackLink('issues-feedback')" [class.contact-active]="isContactActive">
                {{ 'issues-feedback-link' | translate }}
            </div>
            <div *ngIf="isContactActive" class="button contact" (click)="openLink('contact')">
                {{ 'contact_us' | translate }}
            </div>
        </div>
        <div class="_links">
            <div class="_link my-history" routerLink="/profile/my-history">
                <!-- <div class="_img mr-0">
                    <mat-icon svgIcon="bill_new"></mat-icon>
                </div> -->
                <div>{{'my_history' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link my-benefits" routerLink="/profile/my-benefits">
                <div>{{'my_benefits' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div *ngIf="!appService.skin" class="_link my-preferences" routerLink="/profile/my-preferences">
                <!-- <div class="_img mr-0">
                    <mat-icon svgIcon="favorites"></mat-icon>
                </div> -->
                <div>{{'my_preferences' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div *ngIf="entityService.isWalletNeeded" class="_link my-wallet" routerLink="/profile/my-wallet">
                <!-- <div class="_img mr-0">
                    <mat-icon svgIcon="wallet"></mat-icon>
                </div> -->
                <div>{{'my_wallet' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link my-addresses" routerLink="/profile/my-addresses">
                <!-- <div class="_img mr-0">
                    <mat-icon svgIcon="address"></mat-icon>
                </div> -->
                <div>{{'my_addresses' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link accessibilty-menu" (click)="dialogService.showAccessibilityDialog()">
                <div>{{'ACCESS.ACCESSIBILITY' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link accessibilty-menu" *ngIf="isAccessibilitySiteAvailable()" (click)="openAccessibilitySite()">
                <div class="smaller">{{'ACCESS.ACCESSIBILITY_SITE' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link accessibilty-menu" (click)="appService.openAccessibilityDeclaration()">
                <div class="smaller">{{'ACCESS.ACCESSIBILITY_STATEMENT' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" (click)="openLink('privacy')">
                <div class="smaller">{{'privacy_policy' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" (click)="openLink('terms')">
                <div class="smaller">{{'terms_of_service' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" (click)="openLink('loyaltyTerms')" *ngIf="domain?.links?.loyaltyTermsURL">
                <div class="smaller">{{'club_terms' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" routerLink="/profile/remove-account" *ngIf="domain?.removeAccountEnabled">
                <div class="smaller">{{ 'account_removal' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" routerLink="/test-scanner" *ngIf="appService.isTestEnv">
                <div class="smaller">{{ 'Scanner Test' }}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>
            <div class="_link" (click)="appService.signOut(true)">
                <div class="smaller">{{'logout' | translate}}</div>
                <mat-icon class="lg">{{appService.nextIcon}}</mat-icon>
            </div>

            <div class="_link version">
                <!-- <div class="_img mr-0">
                    <mat-icon svgIcon="write"></mat-icon>
                </div> -->
                <div class="smaller">
                    {{'version' | translate}} 
                    <small class="ltr">{{appService.appConfig.version}} ({{appService.appConfig.last_update}})</small>
                </div>
                <!--<mat-icon class="lg">more_horiz</mat-icon>-->
            </div>
        </div>

    </div>
</div>






