import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { AppService } from '../app.service';

import mixpanel from 'mixpanel-browser';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    appConfig: any = environment.appConfig;
    private initialized: boolean = false;

    constructor(
        private appService: AppService
    ) { }

    public initialize() {
        if (this.initialized) return;

        try {
            mixpanel.init(this.appConfig.mixpanel.projectToken, { persistence: 'localStorage' });
            this.initialized = true;
        } catch (err) {
            console.error('Mixpanel init error, not running', err);
        }

    };

    public track(eventName: string, data: any = {}) {
        if (!this.appConfig.mixpanel?.enabled) return;
        if (!this.initialized) this.initialize();

        const additionalData = {
            device_type: this.appService?.detectDevice(),
            version: this.appConfig?.version
        }

        try {
            mixpanel.track(eventName, Object.assign(data, additionalData));
        } catch (err) {
            console.error('Mixpanel track error', err);
        }
    }

    
}