<img
    class="generic-image-tag"
    [class.no-image]="invalidImageUrlFlag"
    [class.image-loaded]="bootSequenceDone"
    [src]="imageUrl"
    [ngStyle]="imageCustomCss"
    (load)="imageLoadSuccessEvent()"
    (error)="imageLoadErrorEvent()"
    loading="lazy"
    [alt]="imageAlt || 'item-image'"
>