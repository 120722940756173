import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AppService } from '../app.service';

declare const $: any;
interface SeoData {
    title: string;
    subtitle: string;
    seo: {
        [key: string]: {
            path: string,
            metaTitle: string,
            metaDescription: string
        }
    }
    ogTitle: string,
    ogDescription: string,
    script?: any;
}

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(
        public appService: AppService,
        private titleService: Title,
        private metaService: Meta,
        private http: HttpClient,
    ) { }

    public setSeoData(data: SeoData) {
        this.setSeoTitle(data);
        this.setSeoDescription(data);
        if (data.script) this.setSeoScript(data.script);
        if (this.appService.skin) this.appService.overrideMetaData();
    }

    public makeSEO(pathFromRoot?: any, moreDetails?: any, overridePath?: string) { //Path is optional for root home page
        let urlIdentifier;
        if (pathFromRoot) urlIdentifier = this.getUrlIdentifierFromPathFromRoot(pathFromRoot);
        else urlIdentifier = !overridePath ? 'root' : overridePath; // Only for home page or overridePath
        this.getPage$(urlIdentifier, moreDetails).subscribe((data: any) => {
            if (!data) return console.error('Error get page SEO, bad response');
            if (urlIdentifier == 'site') {
                this.appService.webSeoTitle.next(data.seo[this.appService.appConfig.locale.toLocaleLowerCase()].metaTitle);
            }
            this.setSeoData(data);
        }, (err: any) => {
            console.error('Error get page SEO', err);
        });
    }

    public getUrlIdentifierFromPathFromRoot(pathFromRoot: any) {
        const urlIdentifiers = pathFromRoot.map(activatedRoute => activatedRoute.snapshot.url).filter(urlSegments => urlSegments.length).map(([firstUrlSegment]) => firstUrlSegment.path);

        return urlIdentifiers[0];
    }

    public getPage$(urlIdentifier: String, moreDetails?: any): Observable<any> {
        let url = `${this.appService.appConfig.tabitBridge}/page`;
        let params: any = { urlIdentifier, observe: 'response' };
        if (moreDetails) params.moreDetails = moreDetails;
        return this.http.post<SeoData>(url, params, this.appService.appHttpOptions);
    }

    public removeSeoScript() {
        let script = document.getElementById('structured-data');
        if (script) document.head.removeChild(script);
    }

    private setSeoTitle(data) {
        // We want that only the root path would have different title
        let title = this.appService.skin && data.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier == 'root' ?
                                        this.setTitleForWL()
                                        :
                                        data.seo[this.appService.appConfig.locale.toLocaleLowerCase()].metaTitle;
        this.titleService.setTitle(title);
    }

    private setSeoDescription(data) {
        if (data.seo[this.appService.appConfig.locale.toLowerCase()].metaDescription) {
            this.metaService.updateTag(
                { name: 'description', content: data.seo[this.appService.appConfig.locale.toLocaleLowerCase()].metaDescription },
                `name='description'`
            );
        }
    }

    private setSeoScript(script?) {
        const seoScriptScript = document.createElement('script');
        seoScriptScript.setAttribute('id', 'structured-data');
        seoScriptScript.setAttribute('type', 'application/ld+json');
        document.head.appendChild(seoScriptScript);
        document.getElementById("structured-data").innerHTML = JSON.stringify(script);
    }

    private setTitleForWL(): string {
        if (this.appService.skin == 'foodba') return this.appService.translate(`${this.appService.skin.toUpperCase()}.seo_title`);

        return this.appService.skin.toUpperCase();
    }
}
