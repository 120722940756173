<div *ngIf="webSearchType == 'home'" class="header-tab-content-row selection-bar">
    <div *ngIf="!appService.skin" class="header-tab-titles" accessible>
        <h1 class="header-tab-title">
            {{ 'WEB_HOME.H1_TITLE' | translate }}<br>
        </h1>
        <h2 class="header-tab-subtitle">
            {{ 'WEB_HOME.H2_TITLE' | translate }}
        </h2>
    </div>
    <div *ngIf="appService.skin" class="header-tab-titles" accessible>
        <ng-container *ngIf="appService.skin == 'foodba'">
            <h1 class="header-tab-title">
                {{ 'FOODBA.title' | translate }}
            </h1>
            <h2 class="header-tab-subtitle">
                {{ 'FOODBA.sub_title' | translate }}
            </h2>
        </ng-container>
    </div>
    <div class="header-tab-content-row home-header-tab-content-row selection-bar">
        <web-selection-bar [webSearchType]="'booking'" [isHome]="true"  [searchButtonStringKey]="'WEB_CONTAINER.find_table'"></web-selection-bar>
        <!-- Corona updates - Online booking -->
        <!-- <div *ngIf="webHomeServicesDomainConfig?.book?.visible" class="button booking" (click)="webContainerService.redirectToBooking()">{{ 'DASHBOARD.BUTTONS.book' | translate }}</div>
        <div *ngIf="webHomeServicesDomainConfig?.takeaway?.visible" class="button takeaway" (click)="webContainerService.searchDelivery('takeaway', selectedLocationType)">{{ 'DASHBOARD.BUTTONS.takeaway' | translate }}</div>
        <div *ngIf="webHomeServicesDomainConfig?.delivery?.visible" class="button delivery" (click)="webContainerService.searchDelivery('delivery', selectedLocationType)">{{ 'DASHBOARD.BUTTONS.delivery' | translate }}</div> -->
    </div>
   <!--  <div class="location-widget-wrapper">
         Decided not to use houseRequired, and return premise addresses 
        <location-widget [houseRequired]="false"></location-widget>
        <location-widget [houseRequired]="webSearchType == 'home' ? false : false"></location-widget>
    </div> -->
</div>

<div *ngIf="webSearchType == 'booking'" class="header-tab-content-row selection-bar">
    <div class="header-tab-titles" accessible>
        <h1 class="header-tab-title">
            {{ 'WEB_BOOK.H1_TITLE' | translate }}
        </h1>
        <h2 class="header-tab-subtitle">
            {{ 'WEB_BOOK.H2_TITLE' | translate }}
        </h2>
    </div>

    <web-selection-bar [webSearchType]="webSearchType" [searchButtonStringKey]="'WEB_CONTAINER.find_table'"></web-selection-bar>
</div>
