import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { find, forEach, isEmpty, keyBy, sortBy, filter } from 'lodash-es';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { LoyaltyService } from './loyalty.service';
import { isEqual } from 'lodash-es';
import { AppService } from '../app.service';
import { DialogsService } from '../_core/dialogs.service';
import { GiftCardsService } from '../gift-cards/gift-cards.service';

@Injectable()
export class MarketplaceService {
    constructor(
        private storageService: StorageService,
        private loyaltyService: LoyaltyService,
        private appService: AppService,
        private dialogsService: DialogsService,
        private giftCardsService: GiftCardsService,
    ) {}

    public getMarketplaceOrganizations(): Observable<any> {
        return new Observable<any>(observer => {
            const organizations = this.getOrganizationsFromLocalStorage();

            if (!isEmpty(organizations)) observer.next(organizations);

            this.manageLocalStorageData(organizations)
            .subscribe((orgs) => {
                if (isEmpty(organizations)) observer.next(orgs);
                observer.complete();
            });
        });
    }

    private getOrganizationsFromServer(): Observable<any> {
        return this.loyaltyService.getMarketplaceOrganizations()
        .pipe(
            switchMap(marketplace => of(marketplace.organizations))
        )
    }

    private getOrganizationsFromLocalStorage() {
        const organizations = JSON.parse(this.storageService.getItem('state__marketplace'));
        if(!isEmpty(organizations)) return this.shuffleOrganizations(organizations);
        return;
    }

    private manageLocalStorageData(localData): Observable<any> {
        return this.getOrganizationsFromServer().pipe(
            map(serverData => {
                if(!isEmpty(serverData)){
                    if (isEmpty(localData)) {
                        this.storageService.setItem('state__marketplace', JSON.stringify(serverData));
                    } else if (!isEqual(serverData, localData)) {
                        this.storageService.removeItem('state__marketplace');
                        this.storageService.setItem('state__marketplace', JSON.stringify(serverData));
                    }  
                }
                return serverData;
            })
        );
    }

    // Sort all organizations by tags for easy filtering
    public sortAndOrderOrganizationsByTags(organizations, tags) {
        const sortedOrgs = [];

        // Sort organizations by tags
        forEach(organizations, organization => {
            forEach(organization.tags, tag => {
                let tagSet = find(sortedOrgs, { tagId: tag._id });

                if (!tagSet) {
                    tagSet = { tagId: tag._id, organizations: [] };
                    sortedOrgs.push(tagSet);
                }

                tagSet.organizations.push(organization);
            });
        });

        // Order by tag's index
        const tagIndexMap = keyBy(tags, '_id');
        const organizationsWithIndex = filter(sortedOrgs, item => tagIndexMap[item.tagId]?.index && item.organizations.length > 0);
        const sortedOrgsByTagIndex = sortBy(organizationsWithIndex, item => tagIndexMap[item.tagId]?.index);

        return sortedOrgsByTagIndex;
    }

    // Get an array of organizations and return it shuffled
    private shuffleOrganizations(orgs: any[]) {
        let currentIndex = orgs.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [orgs[currentIndex], orgs[randomIndex]] = [orgs[randomIndex], orgs[currentIndex]];
        }

        return orgs;
    }

    public getTagLabel(tag) {
        if (tag?.lang && !isEmpty(tag.lang)) {
            if (tag.lang[this.appService?.localeId.toLocaleLowerCase()]?.label) {
                return tag.lang[this.appService?.localeId.toLocaleLowerCase()]?.label;
            }
            return tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()]?.label;
        }
    }

    redirectToGiftCard(accountGuid) {
            if (!accountGuid) return;
            let marketplaceUrl = `/gift-cards/?accountGuid=${accountGuid}&marketplace=1&campaign=GIFTIT`;
            this.giftCardsService.logger(false, 'Initiate redirect from marketplace', {}, { marketplace: true, campaign: 'GIFTIT' });

            if (window['cordova']) marketplaceUrl = `${this.appService.appConfig.tabitWebURL}${marketplaceUrl}`;
            this.dialogsService.toggleActionFrame('marketplace', null, null, window['cordova'], marketplaceUrl);
    }

    openGallery(images, index: number) {
        if (!images?.length) return;

        this.dialogsService.showGalleryDialog({ images, index });
    }
}