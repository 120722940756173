



<form #bookForm="ngForm" style="display:inline;">
    <div class="d-flex flex-column h-100 wl-app-primary-background-color" *ngIf="bookService.validBookingDetails($storage)">
        <div class="pb-3 px-2 wl-app-primary-background-color">
            <tabitbook-selection-bar [parent]="'app-tabitbook-start'" [$storage]="$storage" [(editor)]="editor" [set-when-no-editor]="true" (onEditor)="setEditor($event)"></tabitbook-selection-bar>
        </div>

        <div id="book-selector-container" class="flex-grow-1 scroll-y spacer-bottom editor-scroll" [ngSwitch]="editor" *ngIf="editor">

            <div *ngSwitchCase="'diners'" class="editor-list wl-app-primary-background-color">
                <div
                    class="diner-label"
                    *ngFor="let diner of $storage.bookingDiners"
                    (click)="selectDiner(diner)"
                    [class.active]="diner == $storage.bookForm.diners"
                >
                    {{diner.text}}
                </div>
            </div>
            <div *ngSwitchCase="'date'" class="editor-list wl-app-primary-background-color">
                <ng-container *ngFor="let date of $storage.bookingDates">
                    <div class="day-to-pick wl-app-primary-background-color" (click)="selectDate(date)" [class.active]="date == $storage.bookForm.date" [class.occasion-blocked]="bookService.isDayBlocked(date)" *ngIf="date.enabled">
                        <div class="day-label">
                            {{date.date | date:'E, d MMM': '': appService.localeId}}
                        </div>
                        <div class="occasions-container">
                            <div *ngFor="let occasion of date.occasions; let i = index" class="occasion" [class.first]="i === 0">
                                <ng-container *ngIf="occasion.occasion_details.online_booking.show">
                                    <div class="occasion-type" [ngClass]="occasion.occasion_details.occasion_type"></div>
                                    <div class="occasion-text">
                                        <span class="time-frame">{{ occasionText(occasion, date.date) }}</span>
                                        <span class="occasion-title">{{ occasion.occasion_details.title }}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!date.enabled" class="text-muted">
                        <div class="day-label">{{date.date | date:'E, d MMM': '': appService.localeId}}</div>
                    </div>
                </ng-container>
            </div>
            <div *ngSwitchCase="'time'" class="editor-list wl-app-primary-background-color">
                <div
                    class="time-to-pick"
                    *ngFor="let time of $storage.bookingTimes"
                    (click)="selectTime(time)"
                    [class.active]="time == $storage.bookForm.time"
                    [class.occasion-blocked]="bookService.isSlotBlocked(time)"
                >
                    <div class="time-label">
                        {{time.text | AMPMConverter}}
                    </div>
                    <div class="occasions-container">
                        <div *ngFor="let occasion of time.occasions" class="occasion">
                            <ng-container *ngIf="occasion.occasion_details.online_booking.show">
                                <div class="occasion-type" [ngClass]="occasion.occasion_details.occasion_type"></div>
                                <div class="occasion-text">
                                    <span class="occasion-title">{{ occasion.occasion_details.title }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'preference'" class="editor-list wl-app-primary-background-color">
                <div
                    class="pref-label"
                    *ngFor="let pref of $storage.bookingPreferences"
                    (click)="selectPreference(pref)"
                    [class.active]="pref == $storage.bookForm.preference"
                >
                    {{pref.text}}
                </div>
            </div>

        </div>

        <div class="flex-grow-1 spacer-bottom" *ngIf="!editor"></div>

        <!--START BOTTOM ACTION-->
        <div class="reserve-button">
            <button
                class="lg rounded btn-block"
                mat-flat-button
                color="primary"
                type="submit"
                (click)="submit(bookForm, $event)"
                [disabled]="bookService.isDayBlocked($storage.bookForm.date) || bookService.isSlotBlocked($storage.bookForm.time)"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <span>{{ (referrer == 'cross-orgs' ? 'TGM.FIND_ME_ARESTAURANT' : 'TGM.FIND_ME_APLACE') | translate}}</span>
                    <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
                </div>
            </button>
        </div>
        <!--END BOTTOM ACTION-->
    </div>

</form>



