import { Component, OnInit, NgZone } from '@angular/core';

import { AppService } from '../../app.service';
import { AccountService } from '../../_core/account.service';
import { DialogsService } from '../../_core/dialogs.service';
import { ExternalLinkService } from '../../_core/external-link.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@angular/common';

import { get } from 'lodash-es';

@UntilDestroy()
@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
    host: {
        'class': 'host-default',
    },
})
export class MyAccountComponent implements OnInit {

    public birthDate: number;
    public birthDateYear: number;
    public birthDateMonth: number;
    public birthDateDay: number;
    public anniversary: number;
    public anniversaryYear: number;
    public anniversaryMonth: number;
    public anniversaryDay: number;
    public birthDateErrorMessage: string = '';
    public anniversaryErrorMessage: string = '';
    public birthDateRequired: boolean = false;
    public birthDateEditable: boolean = true;
    public anniversaryEditable: boolean = false;
    public anniversaryRequired: boolean = false;
    public birthDateMin: Date;
    public birthDateMax: Date;

    public anniversaryMin: Date;
    public anniversaryMax: Date;

    public privacyPolicyURL: string;
    public termsOfUseURL: string;
    public loyaltyTermsURL: string;
    public domain: any;
    public IsConfirmSmsDisabled: boolean = false;
    public IsConfirmMailDisabled: boolean = false;

    public maxlength: string = '50';

    public mailingConfirmRequired = false;
    public mailingConfirmEditable = false;
    public mailingConfirmShow = false;

    public smsConfirmRequired = false;
    public smsConfirmEditable = false;
    public smsConfirmShow = false;

    constructor(
        private ngZone: NgZone,
        private location: Location,
		public appService: AppService,
		public accountService: AccountService,
        public dialogsService: DialogsService,
        public externalLinkService: ExternalLinkService,
    ) { }

    ngOnInit() {
        const currentYear = new Date().getFullYear();
        this.birthDateMin = new Date(currentYear - 100, 0, 1);
        this.birthDateMax = new Date(currentYear - 18, 0, 1);
        this.anniversaryMin = new Date(currentYear - 100, 0, 1);
        this.anniversaryMax = new Date();

		this.accountService.getUserInfo().then(() => {
            let loyaltyCustomer = this.accountService.userDetails?.loyaltyCustomer;
            this.IsConfirmSmsDisabled = loyaltyCustomer?.IsConfirmSms;
            this.IsConfirmMailDisabled = loyaltyCustomer?.IsConfirmMail;
            if (loyaltyCustomer?.BirthDate) {
                this.birthDateYear = loyaltyCustomer.BirthDate.substring(0, 4);
                this.birthDateMonth = loyaltyCustomer.BirthDate.substring(5, 7);
                this.birthDateDay = loyaltyCustomer.BirthDate.substring(8, 10);
            }
            if (loyaltyCustomer?.Anniversary) {
                this.anniversaryYear = loyaltyCustomer.Anniversary.substring(0, 4);
                this.anniversaryMonth = loyaltyCustomer.Anniversary.substring(5, 7);
                this.anniversaryDay = loyaltyCustomer.Anniversary.substring(8, 10);
            }

            if (this.domain?.defaults?.signupFormSettings?.extraFormFields) {
                const extraFields = this.domain.defaults?.signupFormSettings?.extraFormFields;
                // dynamically set required fields
                extraFields.forEach(extraField => {
                    if (extraField.name == 'birthDate') {
                        this.birthDateRequired = extraField.required;
                        this.birthDateEditable = !extraField.editable;
                    }
                    if (extraField.name == 'anniversary') {
                        this.anniversaryRequired = extraField.required;
                        this.anniversaryEditable = !extraField.editable;
                    }
                });
            }

        });

        this.appService.domain
        .subscribe(domain => {
            this.domain = domain;
            this.privacyPolicyURL = get(this.domain, 'links.privacyPolicyURL');
            this.termsOfUseURL = get(this.domain, 'links.termsOfUseURL');
            this.loyaltyTermsURL = get(this.domain, 'links.loyaltyTermsURL');

            if (this.domain?.defaults?.signupFormSettings?.extraFormFields) {
                const extraFields = this.domain.defaults?.signupFormSettings?.extraFormFields;
                // dynamically set required fields
                const isConfirmSms = extraFields.find(extraField => extraField.name == 'IsConfirmSms');
                if (isConfirmSms) {
                    this.smsConfirmShow = true;
                    this.smsConfirmRequired = isConfirmSms.required;
                    this.smsConfirmEditable = isConfirmSms.editable;
                }
                const isConfirmMail = extraFields.find(extraField => extraField.name == 'IsConfirmMail');
                if (isConfirmMail) {
                    this.mailingConfirmShow = true;
                    this.mailingConfirmRequired = isConfirmMail.required;
                    this.mailingConfirmEditable = isConfirmMail.editable;
                }

                
            }
        });

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.location.back()
                    });
                });
        }

    }

    isFieldEditable(extraField, loyaltyField) {
        // allow editing if field is empty, even if it isn't editable
        return extraField.editable || extraField.editableOnce || (!loyaltyField && !extraField.editable);
    }

    submitForm(form) {

        if (form?.controls?.anniversaryDay) this.onDateChange('anniversary', true, form);
        if (form?.controls?.birthDateDay) this.onDateChange('birthDate', true, form);

        if (form.valid) { // if any of the extra-signup-fields was empty & not editable, user can edit it until "save" is clicked.
            this.domain.defaults?.signupFormSettings?.extraFormFields.forEach(extraField => {
                extraField.editableOnce = false;
            });
            this.accountService.updateCustomer();
            this.IsConfirmSmsDisabled = this.accountService.userDetails.loyaltyCustomer.IsConfirmSms;
            this.IsConfirmMailDisabled = this.accountService.userDetails.loyaltyCustomer.IsConfirmMail;
        }
    }

    markAsTouched(form) {

    }

    _onFocus($event) {
        // In certain mobile devices - the screen is too small - and the last (email) field is not seen on screen.
        // In some devices - the screen doesn't automatically scroll up when focusing on an element - so the user sometimes doesn't understand that he needs to close the keyboard in order to see the next field.
        // So, we force a scroll into view so that the currently in-focus field will be positioned in the middle of the screen, and so theh next field will become visible for the user.
        $event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    _onFocusDeleteContent($event, fieldName, mainDateField, form) {
        this._onFocus($event);
        form.controls[fieldName].setValue('');
        form.controls[fieldName].updateValueAndValidity();
        this.onDateChange(mainDateField, false, form);
    }

    onDateChange(mainDateField, onSubmitForm, form, fieldName?) {
        if (fieldName && this.validateMaxNumber(fieldName, form)) return
        this[mainDateField + 'ErrorMessage'] = '';
        let year = this.getStringDate(mainDateField + 'Year', null, form);
        let month = this.getStringDate(mainDateField + 'Month', null, form);
        let day = this.getStringDate(mainDateField + 'Day', null, form);
        if (year && month && day) {
            let fullDate = new Date(year + '-' + (month) + '-' + day);
            if (this.validateDates(mainDateField, fullDate, year, month, day, form)) this.setDateSuccess(mainDateField, fullDate, form);
            else this.setDateError(mainDateField, form);
        }
        else if (onSubmitForm && (year || month || day)) this.setDateError(mainDateField, form);
        if (year == '' && month == '' && day == '') {
            if (mainDateField == 'anniversary') this.accountService.userDetails.loyaltyCustomer.Anniversary = '';
            if (mainDateField == 'birthDate') this.accountService.userDetails.loyaltyCustomer.BirthDate = '';
        }
    }

    getStringDate(fieldName, numberToString , form) {
        let zeroString = '0';
        if (fieldName) {
            let dateField = form.controls[fieldName].value ? form.controls[fieldName].value.toString() : '';
            return dateField && dateField.length < 2 ? zeroString.concat(dateField) : dateField;
        }
        else {
            let dateField = numberToString.toString();
            return dateField && dateField.length < 2 ? zeroString.concat(dateField) : dateField;
        }
    }

    setDateSuccess(fieldName, fullDate, form) {
        form.controls[fieldName + 'Year'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Month'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Day'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Year'].updateValueAndValidity();
        form.controls[fieldName + 'Month'].updateValueAndValidity();
        form.controls[fieldName + 'Day'].updateValueAndValidity();
        let dateISOString = fullDate.toISOString();
        if(dateISOString.includes('.000')) dateISOString = dateISOString.replace('.000', '');
        if (fieldName == 'anniversary') this.accountService.userDetails.loyaltyCustomer.Anniversary = dateISOString;
        if (fieldName == 'birthDate') this.accountService.userDetails.loyaltyCustomer.BirthDate = dateISOString;
    }

    validateDates(fieldName, fullDate, year, month, day, form) {
        return (fullDate.getTime() && this.getStringDate(null, fullDate.getFullYear(), form) === year && this.getStringDate(null, fullDate.getMonth() + 1, form) === month && this.getStringDate(null, fullDate.getDate(), form) === day && fullDate > this[fieldName + 'Min'] && fullDate < this[fieldName + 'Max'])
    }

    validateMaxNumber(fieldName, form) {
        return false
        // not working for now
        if (fieldName.includes('Day') && form?.controls[fieldName].value && form?.controls[fieldName].value > 31) {
            form.controls[fieldName].setValue('');
            form.controls[fieldName].updateValueAndValidity();
            return true;
        }
        if (fieldName.includes('Month') && form?.controls[fieldName].value && form?.controls[fieldName].value > 12) {
            form.controls[fieldName].setValue('');
            form.controls[fieldName].updateValueAndValidity();
            return true;
        }
        if (fieldName.includes('Year') && form?.controls[fieldName].value) {
            let fullDate = new Date(form?.controls[fieldName].value + '-' + '01' + '-' + '01');
            if (fullDate < this[fieldName.replace('Year', '') + 'Min'] || fullDate > this[fieldName.replace('Year', '') + 'Max']) {
                form.controls[fieldName].setValue('');
                form.controls[fieldName].updateValueAndValidity();
                return true;
            }

        }
        return false
    }
    setDateError(fieldName, form) {
        form.controls[fieldName + 'Year'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Month'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Day'].setErrors({ 'incorrect': null });
        form.controls[fieldName + 'Year'].setErrors({ 'incorrect': true });
        form.controls[fieldName + 'Month'].setErrors({ 'incorrect': true });
        form.controls[fieldName + 'Day'].setErrors({ 'incorrect': true });
        this[fieldName + 'ErrorMessage'] = 'SIGN-UP-DATE-ERROR';
    }

    focusNextNumberOfCharacter(element, character, form?) {
        if (element.target.value.length == character) {
            let focusedElement = <HTMLInputElement>document.querySelectorAll('input[tabIndex="' + (element.target.tabIndex + 1) + '"]')[0];
            if (focusedElement?.value) focusedElement = <HTMLInputElement>document.querySelectorAll('.extra-field-container input[ng-reflect-model=""]')[0]
            let isFocus = false;
            if (!focusedElement || focusedElement.value) this.focusOnBtn();
            if (focusedElement && !focusedElement?.value) {
                focusedElement.focus();
                isFocus = true;
            }
            if (!isFocus) {
                this.onDateChange('anniversary', true, form);
                this.onDateChange('birthDate', true, form);
            }
        }
    }

    focusOnBtn() {
        document.querySelectorAll('button').forEach((button, index) => {
            if (button.className.includes('submit-my-account-button')) {
                document.querySelectorAll('button')[index].focus()
            }
        });
    }

    isRequiredField(fieldName, form) {
        if(!form?.controls[fieldName]) return false
        return form.controls[fieldName].errors && form.controls[fieldName].errors.required;
    }

    parseISOString(s) {
        var b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
      }

    openLink(path: string) {

        let url: string;

        switch (path) {
          case "privacy":
            url = this.privacyPolicyURL;
            break;
          case "loyaltyTerms":
            url = this.loyaltyTermsURL;
            break;
          case "terms":
          default:
            url = this.termsOfUseURL;
            break;
        }

        this.externalLinkService.openExternalLink(url);
    }
}
