<div class="dashboard-panel-header d-flex align-items-center">
    <div class="flex-grow-1 title">{{ title | translate }}</div>
    <div class="d-flex align-items-center more wl-ignore-theme" *ngIf="moreLink" (click)="appService.redirect(moreLink, { queryParams: qparams })">
        <span>{{ 'more' | translate }}</span>
        <mat-icon class="base">{{ appService.nextIcon }}</mat-icon>
    </div>
</div>

<div class="dashboard-panel">
    <div class="dashboard-panel-body" [class.higher]="higher">
        <div class="card-deck">
            <div class="spinner-container" *ngIf="loading && !items">
                <mat-spinner @spinnerAnimation diameter="80" strokeWidth="5" class="light-grey"></mat-spinner>
            </div>

            <ng-content *ngIf="items?.length || title == 'extra_services'"></ng-content>

            <div @noResultsCardAnimation *ngIf="(!items?.length) && title == 'order_subtitle' && !loading && !preventFlickeringDelay" class="card card-site mr-2 wl-app-secondary-background-color">
                <div *ngIf="title == 'order_subtitle'" class="no-items">
                    <div class="no-items-label">{{ 'no_deliveries' | translate }}</div>
                    <button mat-stroked-button (click)="appService.redirect(['/home/sites'], { queryParams: { topic:'search' } })">{{ 'to_all_sites' | translate }}</button>
                </div>
            </div>

            <div @noResultsCardAnimation *ngIf="(!items?.length) && title == 'my_favorites' && !loading && !preventFlickeringDelay" class="card card-site mr-2 wl-app-secondary-background-color">
                <div *ngIf="title == 'my_favorites'" class="no-items">
                    <div class="no-items-label">{{ 'no_favorites' | translate }}</div>
                    <button mat-stroked-button (click)="appService.redirect(['/home/sites'], { queryParams: { topic:'search' } })">{{ 'to_all_sites' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>