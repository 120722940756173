import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { orderBy, get, isEmpty } from 'lodash-es';

import { ORGANIZATION_BUCKET_TYPES } from '../../_core/OrganizationBucket';
import { AppService } from '../../app.service';
import { EntityService } from "../../_core/entity.service";
import { DialogsService } from "../../_core/dialogs.service";
import { OrganizationsService } from '../../_core/organizations.service';
import { WebContainerService } from '../web-container.service';

import { LocationService } from '../../_core/location.service';
import { getMapAreas, MAP_AREA } from '../../_core/static/map-areas';
import { TagsService, Tag } from '../../_core/tags.service';
import { OccasionsService } from '../../_core/occasions.service';
import { BookService } from '../../_core/book.service';
import { MetaService } from '../../_core/meta.service';
import { MarketplaceService } from '../../_core/marketplace.service';

import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-web-home',
    templateUrl: './web-home.component.html',
    styleUrls: ['./web-home.component.scss']
})
export class WebHomeComponent implements OnInit, OnDestroy {

    private searchSubscription: Subscription;
    private loadingSubscription: Subscription;
    private orgsSubscription: Subscription = null;
    private marketplaceSubscription: Subscription;

    public loading = true;
    public loadingMoreSites = false;
    public sitesLoaded = false; 

    nearbyAllOrgs: any[];
    nearbyTabitOrgs: any[];
    newAtTabitOrgs: any[];
    homeOrderOrgs: any[];
    marketplaceOrgs: any[] = [];
    shuffledHomeOrderOrgs: any[];
    
    mapAreas: MAP_AREA[];

    public foodTags: Tag[];
    public occasionTags: Tag[];

	private tagsSubscription: Subscription;
    private eventsSubscription: Subscription;
    private coreSubscription: Subscription;

    public sitesEvents: any = [];
    private eventsLimit: string = '6';
    private timeFrom = moment().startOf('day');
    private timeTo = moment(this.timeFrom).add(30, 'days');

    public domain: any;
    public webHomeSectionsDomainConfig: any;
    public webHomeServicesDomainConfig: any;

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        public marketplaceService: MarketplaceService,
        private entityService: EntityService,
        private locationService: LocationService,
        private organizationsService: OrganizationsService,
        private webContainerService: WebContainerService,
        private tagsService: TagsService,
        private occasionsService: OccasionsService,
        private bookService: BookService,
        private metaService: MetaService,
    ) {
        this.mapAreas = getMapAreas(this.appService.appConfig.locale);
    }

    ngOnInit() {
        // Domain subscription
        this.appService.domain
        .pipe(untilDestroyed(this))
        .subscribe(domain => {
            if (domain) {
                this.domain = domain;
                if (this.appService.isShowSplashScreen(domain)) this.appService.splashScreensDialog(domain.links?.splashScreenImageURL);

                this.webHomeSectionsDomainConfig = this.appService.getWebHomeSectionsConfig(domain);
                if (this.webHomeSectionsDomainConfig?.events?.visible) {
                    this.eventsSubscription = this.occasionsService.getOccasions(this.timeFrom, this.timeTo, this.eventsLimit).subscribe(occasions => {
                        this.sitesEvents = occasions;
                    });
                }
    
                if (this.webHomeSectionsDomainConfig?.marketplace?.visible) {
                    this.marketplaceSubscription = this.marketplaceService.getMarketplaceOrganizations()
                    .subscribe(orgs => this.marketplaceOrgs = orgs);
                }
            }
        });

        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations
        .subscribe(subscribed => {
            if (!subscribed) {
                this.entityService.subscribeToCoreData()
                .then(() => this.subscribeToOrgs());
            } 
        });

        this.bookService.startWithoutSite();

        this.loadingSubscription = this.appService.loadingMetaData.subscribe(loadingState => {
            this.loading = loadingState;
        });

        // To validate if the location subscription was done
        if (!this.sitesLoaded) this.subscribeToOrgs();

        this.searchSubscription = this.webContainerService.webSelectionSearchEvent.subscribe((searchState: boolean) => {
            this.loading = searchState;
        });

        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO();

        this.tagsSubscription = this.tagsService.tagsData$
        .subscribe(tags => {
            if (tags?.length) {
                this.foodTags = tags?.filter(tag => tag.type !== 'occasions' && tag.showOnHomeView === true);
                this.occasionTags = tags?.filter(tag => tag.type === 'occasions');
            }
        });
    }

    subscribeToOrgs() {

        // const nearbyAllOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.nearbyAll];
        const nearbyTabitOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.nearbyTabit];
        const newAtTabitOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.new];
        const homeOrderOrgsObservable: Observable<any[]> = this.organizationsService.data[ORGANIZATION_BUCKET_TYPES.homeOrder];
        
        // One subscription for two organizations "TYPES":
        this.orgsSubscription = merge(

            // nearbyAllOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.nearbyAll, orgs }))),
            nearbyTabitOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.nearbyTabit, orgs }))),
            newAtTabitOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.new, orgs }))),
            homeOrderOrgsObservable.pipe(map(orgs => ({ type: ORGANIZATION_BUCKET_TYPES.homeOrder, orgs }))),

        ).pipe(

            map(organizationsContainer => ({
                ...organizationsContainer,
                orgs: orderBy(organizationsContainer.orgs, ['distance'], ['asc'])
            }))

        ).subscribe((organizationsContainer: any) => {
            // if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.nearbyAll) this.nearbyAllOrgs = organizationsContainer.orgs;
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.nearbyTabit) this.nearbyTabitOrgs = organizationsContainer.orgs;
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.new) this.newAtTabitOrgs = organizationsContainer.orgs;
            if (organizationsContainer.type === ORGANIZATION_BUCKET_TYPES.homeOrder) {
                // 2020-03-26: Since on a Desktop the user is usually at the same place - we shuffle the delivery restaurants
                this.homeOrderOrgs = organizationsContainer.orgs;
                this.shuffledHomeOrderOrgs = this.homeOrderOrgs.map((a) => ({sort: Math.random(), value: a})).sort((a, b) => a.sort - b.sort).map((a) => a.value);
            }

            this.sitesLoaded = true;

        }, err => {
            this.sitesLoaded = false;
            console.error('error with incoming organizations:', err);
        });

        this.searchSubscription = this.webContainerService.webSelectionSearchEvent.subscribe((searchState: boolean) => {
            this.loading = searchState;
        });

        // Get and set the SEO data for changing the meta data of each page that uses the functionality
        this.metaService.makeSEO();

        this.tagsSubscription = this.tagsService.tagsData$.subscribe(tags => {
            this.foodTags = tags?.filter(tag => tag.type !== 'occasions' && tag.showOnHomeView === true);
            this.occasionTags = tags?.filter(tag => tag.type === 'occasions');
        });

        // 22/07/2021 - Decided to remove the occasions request for now
        // if (this.showEvents) {
        //     this.eventsSubscription = this.occasionsService.getOccasions(this.timeFrom, this.timeTo, this.eventsLimit).subscribe(occasions => {
        //         this.sitesEvents = occasions;
        //     });
        // }
    }

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
        this.orgsSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
        this.tagsSubscription.unsubscribe();
        this.coreSubscription.unsubscribe();
        if(this.marketplaceSubscription) this.marketplaceSubscription.unsubscribe();
        if (this.eventsSubscription) this.eventsSubscription.unsubscribe();
    }

    siteClick(site) {
        this.appService.redirect(['/site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }

    areaClick(area: any) {
        if (!area || !area.key || !area.location) throw new Error('Cannot pick area, bad area object');
        let route = this.appService.redirectValueByLocale('restaurants', 'ROUTE');
        this.locationService.chooseSpecifiedLocation(area.location, this.appService.translate('areas.' + area.key), area.key);
        this.appService.redirect([route]);
    }
}
