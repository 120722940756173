import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../../../src/app/app.service';
import { LocationService } from '../../_core/location.service';
import { DialogsService } from '../../_core/dialogs.service';
import { getMapAreas } from '../../_core/static/map-areas';

import { get } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
    selector: 'web-footer',
    templateUrl: './web-footer.component.html',
    styleUrls: ['./web-footer.component.scss']
})
export class WebFooterComponent implements OnInit, OnDestroy {

    public mapAreas: any[] = [];

    public showArticles: boolean = false;

    public facebookURL: string;
    public instagramURL: string;
    public linkedinURL: string;
    public privacyPolicyURL: string;
    public termsOfUseURL: string;
    public domainSubscription: Subscription;
    public domain: any;

    // isHebrew: boolean = false;

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        private locationService: LocationService,
    ) { }

    ngOnInit() {
        this.domainSubscription = this.appService.domain.subscribe((domain: any) => {
            this.domain = domain;

            // Dynamic domain configuration
            const domainSettings = get(domain, 'defaults.serviceConfiguration.webHomeSections', {});
            this.showArticles = get(domainSettings, 'articles.visible', true);
        });

        this.facebookURL = get(this.appService, 'appConfig.facebookURL', 'https://www.facebook.com/tabitisrael');
        this.instagramURL = get(this.appService, 'appConfig.instagramURL', 'https://www.instagram.com/eat.with.tabit');
        this.linkedinURL = get(this.appService, 'appConfig.linkedinURL', 'https://www.linkedin.com/company/tabit---restaurant-technologies/');

        this.mapAreas = getMapAreas(get(this.appService, 'appConfig.locale', this.appService.localeId));
    }

    public open(link, external?, area?) {
        // external routing
        if (external) {
            switch (link) {
            case "privacy":
                link = get(this.domain, 'links.privacyPolicyURL');
                break;
            case "terms":
                link = get(this.domain, 'links.termsOfUseURL');
                break;
            case "accessabilityPolicy":
                link = get(this.domain, 'links.accessibilityDeclarationURL');
                break;
            }

            window.open(link);
        // area routing
        } else if (area) {
            this.areaClick(area);
        // internal page routing
        } else {
            this.appService.redirect(link[0], link[1]);
        }
    }

    areaClick(area: any) {
        if (!area || !area.key || !area.location) throw new Error('Cannot pick area, bad area object');
        let route = this.appService.redirectValueByLocale('restaurants', 'ROUTE');
        this.locationService.chooseSpecifiedLocation(area.location, this.appService.translate('areas.' + area.key), area.key);
        this.appService.redirect([route]);
    }

    getMediaPath() {
        let path = this.appService.getValidBaseUrl(get(this.domain, 'webFooterImagePath'));
        if (!path) {
            path = this.appService.base('assets/images/tabit_white_yellow_ribbon.svg');
        }
        return path;
    }

    ngOnDestroy() {
        this.domainSubscription.unsubscribe();
    }
}



