import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';
import { OccasionsService } from '../../_core/occasions.service';
import { each } from 'lodash-es';

@Component({
	selector: 'app-tabitbook-start',
	templateUrl: './tabitbook-start.component.html',
	styleUrls: ['./tabitbook-start.component.scss']
})
export class TabitbookStartComponent implements OnInit {
	@Input() editorToSet;
	@Input() referrer;
	@Output() clickSubmit = new EventEmitter<boolean>();

	$storage: any;
	bookForm: any
	wallet: any;
	step: any = 'start';
    editors_with_preference: string[] = [ 'date', 'time', 'diners', 'preference' ]; // This is the editors order
    editors: string[] = [ 'date', 'time', 'diners' ]; // This is the editors order
	editor: string = '';

	constructor(
        public datePipe: DatePipe,
		public appService: AppService,
		public bookService: BookService,
		public occasionsService: OccasionsService,
	) {
		this.$storage = this.bookService.$storage;
		this.bookForm = this.$storage.bookForm;
	}

	submit(form, ev?) {
        this.clickSubmit.emit();
	}

	selectDiner(diner) {
		this.$storage.bookForm.diners = diner;
		this.$storage.bookFormChanged.diners = true;
        this.setNextEditor();
	}

	selectDate(date) {
        if (this.bookService.isDayBlocked(date)) return;
		this.$storage.bookForm.date = date;
        this.$storage.bookFormChanged.date = true;

		let form = this.$storage.bookForm;
		let newSlots = date.slots;
		let newSlot;

		let currentTime = form.time.value;

		let diff = 2440;
		each(newSlots, slot => {
			let _diff = Math.abs(slot.value - currentTime);
			if (_diff < diff) {
				diff = _diff;
				newSlot = slot;
			}
		});

		this.$storage.bookingTimes = newSlots;
		form.time = newSlot;
        this.setNextEditor();
	}

	selectTime(time) {
        if (this.bookService.isSlotBlocked(time)) return;
		this.$storage.bookForm.time = time;
        this.$storage.bookFormChanged.time = true;
        this.setNextEditor();
	}

	selectPreference(pref) {
		this.$storage.bookForm.preference = pref;
        this.$storage.bookFormChanged.preference = true;
        this.setNextEditor();
	}

    private getEditorsAndIndex() {
        let editors = (this.$storage.enablePreferences ? this.editors_with_preference : this.editors);
        let index = editors.indexOf(this.editor);
        if (index < 0) throw new Error(`no editor at list: '${this.editor}'`);
        return { editors, index };
    }

    isFirstEditor() {
        let { index } = this.getEditorsAndIndex();
        return index === 0;
    }

    setNextEditor() {
        if (!this.editorToSet) return;

        let { editors, index } = this.getEditorsAndIndex();
        this.setEditor(editors[index + 1] || null);
    }

    setPrevEditor() {
        let { editors, index } = this.getEditorsAndIndex();
        if (index === 0) return null;
        this.setEditor(editors[index - 1]);
    }

	setEditor(mode) {
		this.editor = mode;

		// Scroll to the selected item
		setTimeout(() => {
			let selectedListItem = document.querySelector(".editor-list .active");
			if (selectedListItem) selectedListItem.scrollIntoView();
			else this.appService.scrollToTop("#book-selector-container");
		});
	}

    occasionText(occasion, date) {
        let text = '';
        let showFrom = this.occasionsService.showFrameEdge(date, occasion, 'from');
        let showUntil = this.occasionsService.showFrameEdge(date, occasion, 'until');

        if (showFrom) text += `${this.datePipe.transform(occasion.reservation_details.reserved_from, 'HH:mm')} `;

        if (showUntil) {
            if (showFrom) text += '- ';
            else text += this.appService.translate('general_time_to');
            text += `${this.datePipe.transform(occasion.reservation_details.reserved_until, 'HH:mm')} `;
        }
        return text.trim();
    }

	ngOnInit() {
        if (this.editorToSet) this.setEditor(this.editorToSet);
	}

}
