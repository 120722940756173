

<div class="heart-on" *ngIf="animating === 'to-on'">
    <ng-lottie [options]="{path: onAnimation, loop: false, autoplay: false}" width="55px" height="55px" (domLoaded)="animationDomLoaded([25,51])" (animationCreated)="animationCreated($event)" (complete)="animationCompleted($event)"></ng-lottie>
</div>
<div class="heart-off" *ngIf="animating === 'to-off'">
    <ng-lottie [options]="{path: offAnimation, loop: false, autoplay: false}" width="46px" height="46px" (domLoaded)="animationDomLoaded([0,60])" (animationCreated)="animationCreated($event)" (complete)="animationCompleted($event)"></ng-lottie>
</div>

<button class="_favorite" mat-icon-button *ngIf="showStatic">
    <mat-icon aria-label="Favorite" *ngIf="site.isFavorite" color="accent" class="text-highlight" [class.white]="white" svgIcon="likeactive"></mat-icon>
    <mat-icon aria-label="Favorite" *ngIf="!site.isFavorite" [class.white]="white" svgIcon="like"></mat-icon>
</button>