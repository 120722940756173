<div class="module-bar trans wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="goBack()">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <span class="title flex-grow-1" preserveTextDirectionByLocale fontSizeByTextLength>
            {{ title }}
        </span>
    </div>
</div>

<div class="module-content">
    <order-tracker></order-tracker>
</div>