import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init as initApm } from '@elastic/apm-rum';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const protocol = 'https://';

initApmAgent();

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.debug(err));
};

function setHostForSubDomain() {
    // First we'll check if the string start is similar to the url itself
    const subDomain = window.location.hostname.match(/([^.]+).+/)[1];
    if (isNotSubDomain(subDomain)) {
        // We are in the root domain. So we redirect to https://tabitisrael.co.il (in IL) or https://tabit.us/ (in US)
        // Example: https://mytabit.co.il/ => https://tabitisrael.co.il/
        // Example: https://mytabit.com/ => https://tabit.us/
        window.location.href = protocol + getHostNameByLocale();
    } else {
        // We have sub-domain
        // Example: https://kisu.mytabit.co.il => https://kisu.mytabit.co.il/tabit-order/kisu
        // Example: https://kisu.mytabit.com => https://kisu.mytabit.com/tabit-order/kisu
        window.location.href = protocol + window.location.hostname + '/tabit-order/' + subDomain;
        console.debug('=== Sub domain redirect to: ', window.location.href);
    }
}

function getHostNameByLocale() {
    const url = (/co.il/.test(window.location.hostname)) ? 'tabitisrael.co.il' : 'tabit.us';
    return url;
}

function isNotSubDomain(pattern): boolean {
    // Example: https://mytabit.co.il => sub-domain = mytabit
    // If mytabit includes in the possible hosts array then it's not a sub-domain
    // On the contrary, if https://kisu.mytabit.co.il => sub-domain = kisu
    // Kisu won't be included in the possible hosts, so it's a sub-domain
    const possibleHostsWithousPrefix = ['tabitisrael', 'tabit', 'mytabit'];
    return possibleHostsWithousPrefix.includes(pattern);
}

// check if this instance of app is within an iframe, meaning there was an iframe redirect from an external payment window
if (window.parent?.window) {
    console.debug('=== MAIN.TS | EVENT DETECTED ===', window.location?.href);
    window.parent.window.postMessage({
        iframeEvent: 'sameHostIframeLoaded',
        fullUrl: window.location?.href
    }, '*')
}

function initApmAgent() {
    try {
        initApm({
            // Set custom APM Server URL (default: http://localhost:8200)
            serverUrl: environment.appConfig.apmServer,
            serviceName: 'tabit-app',
            // Set the service version (required for source map feature)
            serviceVersion: environment.appConfig.version,
            // Set the service environment
            environment: environment.env,
            // Needed for managing sample rate
            centralConfig: true,
            // This is the default value; here just for potentially disabling
            distributedTracing: true,
            // Adds trace headers to requests to the following hosts
            distributedTracingOrigins: [/^.*\.(tabit-dev\.com|tabit-int\.com|tabit-stage\.com|tabit\.cloud)$/]
        });
    } catch(err) {
        console.debug('Error in initApmAgent');
    }

    console.debug('initApmAgent Occurred');
}

if (typeof window['cordova'] !== 'undefined') {
    document.addEventListener('deviceready', () => {
        bootstrap();
        // Marking the App as "loaded" for the Cordova Wrapper
        window['appAlreadyLoaded'] = true;
    }, false);
} else {
    // If we are not on "https://tabitisrael.co.il/" we redirect there.
    if (window.location.hostname == 'www.tabitisrael.co.il' || window.location.hostname == 'www.tabitisrael.com' || window.location.hostname == 'tabitisrael.com') {
        window.location.href = protocol + 'tabitisrael.co.il' + window.location.pathname + window.location.search + window.location.hash;
    }

    // Redirect logic for sub-domain
    if (/mytabit/.test(window.location.hostname) && !/tabit-order/.test(window.location.href)) {
        setHostForSubDomain();
    }

    bootstrap();
}