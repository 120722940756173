

<div class="bar-wrapper d-flex" [ngClass]="parent" [class.standby]="$storage.reservation && $storage.reservation.standby_reservation">
    <div class="flex-grow-1 d-flex flex-column">
        <div class="d-flex">
            <div
                class="dv-btn flex-grow-1"
                (click)="setEditor('date')"
                [ngClass]="{'active': editor == 'date', 'blocked': bookService.isDayBlocked($storage.bookForm && $storage.bookForm.date), 'changed': $storage.bookFormChanged && $storage.bookFormChanged.date}"
            >
                <div>
                    <small>{{$storage.bookForm && $storage.bookForm.date.date | date:'EEEE': '': appService.localeId}}</small>
                    {{ $storage.bookForm && $storage.bookForm.date.date | date:'M / d': '': appService.localeId }}
                </div>
            </div>
            <div
                class="dv-btn flex-grow-1"
                (click)="setEditor('time')"
                [ngClass]="{'active': editor == 'time', 'blocked': bookService.isSlotBlocked($storage.bookForm && $storage.bookForm.time), 'changed': $storage.bookFormChanged && $storage.bookFormChanged.time}"
            >
                <div>
                    <small>{{'TGM.TIME' | translate}}</small>
                    {{ $storage.bookForm && $storage.bookForm.time.text | AMPMConverter}}
                </div>
            </div>
        </div>
        <div class="dv-btn" (click)="setEditor('preference')" [ngClass]="{'active':editor == 'preference', 'changed': $storage.bookFormChanged && $storage.bookFormChanged.preference}" *ngIf="$storage.enablePreferences && $storage.bookForm.preference.text">
            <div>
                <small>{{'area' | translate}}</small>
                {{ $storage.bookForm && $storage.bookForm.preference.text }}
            </div>
        </div>
    </div>
    <div class="dv-btn diners" (click)="setEditor('diners')" [ngClass]="{'active': editor == 'diners', 'changed': $storage.bookFormChanged && $storage.bookFormChanged.diners}">
        <div>
            <small>{{'TGM.DINERS' | translate}}</small>
            {{ $storage.bookForm && $storage.bookForm.diners.textSmall }}
        </div>
    </div>
</div>