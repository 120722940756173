<h1 mat-dialog-title class="d-flex a-center">
    <span class="flex-grow-1">{{ 'EX_PAYMENT.dialog_title' | translate }}</span>
    <button mat-icon-button mat-dialog-close cdkFocusInitial>
        <mat-icon aria-label="Close Dialog">close</mat-icon>
    </button>
</h1>

<div mat-dialog-content>
    <block-ui>
        <iframe id="add_payment" name="add_payment" frameborder="0" scrolling="no" class="embed-responsive-item panel"
            (load)="onFrameLoaded($event)" src="/assets/adyen/embed.html"></iframe>
    </block-ui>
</div>