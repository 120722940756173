<h1 mat-dialog-title class="d-flex a-center">
	<span class="flex-grow-1">{{'EX_PAYMENT.dialog_title' | translate}}</span>
	<button (click)="closeDialog()" mat-icon-button mat-dialog-close cdkFocusInitial>
		<mat-icon aria-label="Close Dialog">close</mat-icon>
	</button>
</h1>

<div mat-dialog-content>
	<iframe id="add_payment" class="embed-responsive-item panel" name="add_payment" frameborder="0" scrolling="no"></iframe>
	<form id="send_token" action="" method="post" target="add_payment" style="display:none">
		<input type="hidden" name="token" value="{{data.token}}" />
	</form>
</div>

<div class="provider-logo">
    <img [src]="appService.base('assets/images/authorize.png')" alt="authorize">
</div>
