<div mat-dialog-title>
    <div class="d-flex justify-content-between">
        <div class="flex-grow-1 d-flex align-items-center justify-content-center text-xl">
            <span>{{'pay_by_items_title' | tmpTranslate}}</span>
        </div>
        <button mat-icon-button (click)="cancel()" class="dialog-close-button">
            <mat-icon aria-label="Close Dialog">close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content class="border-top" style="padding:0 ">
    <ng-container>
        <mat-selection-list #list class="mat-selection-list-reverse">
            <mat-list-option *ngFor="let offer of offers" [selected]="offer.selected" [class.list-option-selected]="offer.selected" [value]="offer" (click)="onOfferSelected(list, $event)">
                <div style="display:flex; align-items:center" [class.list-option-selected]="offer.selected">
                    <span class="flex-grow-1 ml-4 text-ellipsis" style="font-weight: 500">{{offer.name}}</span>
                    <span style="text-decoration:line-through; font-size:0.8em; margin-inline-end: 15px" *ngIf="offer.originalPrice">{{offer.originalPrice | lcurrency}}</span>
                    <span style="min-width: 81px; text-align: right;">{{offer.price | lcurrency}}</span>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </ng-container>
</div>

<div class="dialog-actions border-top">
    <div class="_balance-info" [class.text-danger]="this.total > this.data.balanceDue">
        <div class="mr-3">{{'PO.REMAINED_PAY' | tmpTranslate}}:</div>
        <div>{{this.data.balanceDue | lcurrency:'1.2-2'}}</div>
    </div>

    <div class="p-3 pb-4">
        <div class="mat-single-button lg rounded shadow">
            <button mat-flat-button color="primary" type="button" (click)="apply()" [disabled]="!total">
                <div class="d-flex justify-content-between align-items-center">
                    <span>{{'PAY' | translate}}</span>
                    <span *ngIf="total">{{total | lcurrency}}</span>
                    <mat-icon class="_next">{{appService.nextIcon}}</mat-icon>
                </div>
            </button>
        </div>
    </div>
</div>

