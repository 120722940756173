<div mat-dialog-title class="">
    <div class="d-flex justify-content-between">
        <div class="h3">
            {{'PASS_ORDER_TITLE' | translate}}
        </div>
        <button aria-label="close" mat-icon-button (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <block-ui>
        <form (ngSubmit)="passByPhone(passOrderForm)" #passOrderForm="ngForm">

            <div class="alert alert-info" style="font-size:13px; font-weight:600">{{'PASS_ORDER_BY_PHONE_MESSAGE' | translate}}</div>

            <mat-form-field color="accent">
                <input matInput [placeholder]="'PASS_ORDER_BY_PHONE_CAPTION' | translate" type="tel" [(ngModel)]="formModel.phone" name="phone" required>
                <mat-icon matSuffix (click)="formModel.phone = null" *ngIf="formModel.phone">close</mat-icon>
            </mat-form-field>

            <div class="text-far">
                <button mat-flat-button color="primary">{{'PASS_ORDER_BY_PHONE_SEND' | translate}}</button>
            </div>

            <div class="mt-4 text-center" *ngIf="qrCodeUrl">
                <div class="text-info mb-3" style="font-size:13px; font-weight:600">{{'PASS_ORDER_BY_PHONE_QR_MESSAGE' | translate}}</div>

                <div class="inline">
                    <qrcode [qrdata]="qrCodeUrl" [size]="256" [level]="'M'"></qrcode>
                </div>
            </div>

        </form>
    </block-ui>
</div>
