import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { HttpClient } from '@angular/common/http';

import { get } from 'lodash-es';

declare const $: any;

interface ApplePayPaymentRequest {
    countryCode: string;
    currencyCode: string;
    merchantCapabilities: string[];
    supportedNetworks: string[];
    total: ApplePayLineItem;
}

interface ApplePayLineItem {
    label: string;
    type: string;
    amount: string;
}

declare const ApplePaySession: any; // Declare ApplePaySession as any

// Google Pay Variables
declare var google: any;

const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
};

const allowedGoogleCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];
// const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
const allowedCardAuthMethods = ["CRYPTOGRAM_3DS"]; // We can not allow PAN_ONLY since it allows unsupported devices to process unsecured payments
const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedGoogleCardNetworks
    }
};

const NO_PAYMENT_MESSAGE = 'No payment to process';

@Injectable({
    providedIn: 'root'
})
export class NativePaymentsService {
    public paymentsClient: any;

    constructor(
        private appService: AppService,
        private http: HttpClient,
    ) { }

    public initExternalPayScripts() {
        this.initApplePayScript();
        this.initApplePayButtonScript();
        this.initGooglePayButtonScript();
    }

    public showNativePaymentButtons($storage) {
        const showButtons = !['US', 'AU'].includes($storage.region) && !this.appService.isApp && $storage.enableNativePayments;
        return showButtons;
    }

    public initApplePaymentRequest(paymentData) {
        return new Promise((resolve, reject) => {
            if (!paymentData.payment) return reject({ message: NO_PAYMENT_MESSAGE, type: 'Apple'});

            // Create an instance of ApplePaySession
            const paymentRequest: ApplePayPaymentRequest = {
                countryCode: this.appService.appConfig.countryCode,
                currencyCode: this.appService.appConfig.currencyCode,
                merchantCapabilities: ['supports3DS'],
                supportedNetworks: ['visa', 'masterCard', 'amex'],
                total: {
                    label: paymentData.$storage.caption || 'Tabit',
                    type: 'final',
                    amount: paymentData.payment.amount,
                },
            };

            const session = new ApplePaySession(1, paymentRequest);
            // Begin the Apple Pay session
            session.begin();

            this.getAppleValidation()
            .then(validation => {
                const { displayName, merchantSessionIdentifier } = validation;

                if (displayName && merchantSessionIdentifier) {
                    session.completeMerchantValidation(validation);
                } else {
                    if (session) session.cancel();
                    return reject(`getAppleValidation returned error: ${validation}`)
                }

                session.onpaymentauthorized = (event) => {
                    let validationStatus;
                    if (event?.payment?.token) {
                        // console.log('=== onpaymentauthorized ===', event?.payment?.token);
                        validationStatus = ApplePaySession.STATUS_SUCCESS;
                    } else {
                        validationStatus = ApplePaySession.STATUS_FAILURE;
                    }
                    // Finalize the payment (STATUS_SUCCESS/STATUS_FAILURE)
                    session.completePayment(validationStatus);

                    switch(validationStatus) {
                        case ApplePaySession.STATUS_SUCCESS:
                            resolve(event?.payment?.token);
                        case ApplePaySession.STATUS_FAILURE:
                            reject('Paymend not authorized');
                    }
                };

                session.oncancel = (event) => {
                    console.log('=== oncancel ===', event);
                    resolve(null);
                  // Handle the user's cancellation and call session.abort
                };
            });
        })
    }

    public initApplePayButtonScript() {
        const applePayButtonScript = document.createElement('script');
        applePayButtonScript.setAttribute('src', 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js');
        document.head.appendChild(applePayButtonScript);
    }

    public getAppleValidation(): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.http.post<{}>(`${this.appService.appConfig.tabitBridge}/finance/apple/get-session-verification`, {
				domainName: window.location.host || window.location.hostname,
			}).subscribe(
				(response: any) => {
					resolve(response);
				},
				(err) => {
					reject(err)
				}
			);
		});
    }

    public initGooglePayButtonScript() {
        const googlePayButtonScript = document.createElement('script');
        googlePayButtonScript.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js');
        document.head.appendChild(googlePayButtonScript);
    }

    private initApplePayScript() {
        if (this.appService.appConfig.applePaySdkURL) {
            const applePayScript = document.createElement('script');
            applePayScript.setAttribute('src', this.appService.appConfig.applePaySdkURL);
            document.head.appendChild(applePayScript);
        }
    }

    public appendGooglePayButton(className: string) {
        this.paymentsClient = new google.payments.api.PaymentsClient({ environment: this.appService.appConfig.isDev ? 'TEST' : 'PRODUCTION' });
        // Code that uses the `google` object
        const isReadyToPayRequest: any = Object.assign({}, baseRequest);
        isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
        this.paymentsClient.isReadyToPay(isReadyToPayRequest)
        .then(response => {
            if (response.result) {
                appendGooglePayButton(this.paymentsClient);
            // add a Google Pay payment button
            }
        }).catch(err => {
            // show error in developer console for debugging
            console.error(err);
            return Promise.reject(err);
        });

        const appendGooglePayButton = (paymentsClient: any) => {
            const googlePayButton = paymentsClient.createButton({
                buttonColor: 'white',
                buttonType: 'short',
                buttonSizeMode: 'fill',
                onClick: () => { },
                allowedPaymentMethods: []
            });
            // console.log(googlePayButton);
            $(document).ready(() => {
                $(className).append(googlePayButton);
            });
        };
    }

    public initGooglePayPayment(paymentData) {
        if (!paymentData.payment) throw { message: NO_PAYMENT_MESSAGE, type: 'Google'};
        const gatewayMerchantId = get(paymentData, 'account.merchantNumber', '0882819014');
        const tokenizationSpecification = {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                'gateway': 'hyp',
                'gatewayMerchantId': gatewayMerchantId
            }
        };

        const cardPaymentMethod = Object.assign({ tokenizationSpecification }, baseCardPaymentMethod );

        const paymentDataRequest: any = Object.assign({}, baseRequest);
        const totalPrice = paymentData.payment.amount.toString();

        paymentDataRequest.transactionInfo = {
            totalPriceStatus: 'FINAL',
            totalPrice,
            currencyCode: this.appService.appConfig.currencyCode,
            countryCode: this.appService.appConfig.countryCode,
        };

        const merchantId = this.appService.appConfig.isDev ? gatewayMerchantId : 'BCR2DN4TRLGLT4I2';
        paymentDataRequest.merchantInfo = {
            merchantName: paymentData.$storage?.caption || 'Tabit',
            merchantId,
        };

        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];

        return this.paymentsClient.loadPaymentData(paymentDataRequest)
        .then(paymentData => {
            if (!paymentData.paymentMethodData?.tokenizationData?.token) throw 'No token received';
            // if using gateway tokenization, pass this token without modification
            const paymentToken = paymentData.paymentMethodData?.tokenizationData?.token;
            return paymentToken;
        }).catch(err => {
            // show error in developer console for debugging
            console.error(err);
            throw err;
        });
    }

    public handlePaymentError(err) {
        const dialogText = err?.message == NO_PAYMENT_MESSAGE ? 'NATIVE_PAYMENT.error_dialog_no_amount_description' : 'NATIVE_PAYMENT.error_dialog_description';
        const dialogTitle = err?.message == NO_PAYMENT_MESSAGE ? 'error_title' : 'NATIVE_PAYMENT.error_dialog_title';
        return this.appService.mainMessage({
            dialogType: 'error',
            dialogTitle: this.appService.translate(dialogTitle),
            dialogText: this.appService.translate(dialogText, { type: err?.type || 'Apple/Google' }),
            primaryButtonText: 'OK',
            secondaryButtonHidden: true,
        });
    }
}