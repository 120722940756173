<div class="module-bar wl-app-primary-background-color">
    <div class="_inner">
        <button mat-icon-button class="back-button" (click)="appService.redirect(['/home'])">
            <mat-icon>{{appService.backIcon}}</mat-icon>
        </button>
        <div class="title flex-grow-1">
            {{'SERVICE_BUTTON.pay_generic' | translate}}
        </div>
        <button class="menu" mat-icon-button [routerLink]="['/sites/pay']">
            <mat-icon svgIcon="search"></mat-icon>
        </button>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<div class="app-bottom-aligned">
    <ng-lottie [options]="{path: paybackAnimation, autoplay: true, loop: true}" [height]="128"></ng-lottie>
</div>

<div class="module-content wl-app-primary-background-color">
    <div>
        <div class="p-3">
            {{'MESSAGES.PAY_INFO' | translate}}
        </div>

        <div class="dashboard-panel">
            <div class="dashboard-panel-body">
                <div class="card-deck">
                    <site-card *ngFor="let org of orgs | slice:0:15" [siteId]="org._id" (click)="siteClick(org)"></site-card>
                </div>
            </div>
        </div>
    </div>
</div>

