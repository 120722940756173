<div class="d-flex flex-column h-100 scroll-y spacer-bottom">
    <div class="generic-desc text-center py-3">
        <div class="pb-3" (click)="options.gotoStart()">
            <tabitbook-selection-bar [$storage]="$storage"></tabitbook-selection-bar>
        </div>
        {{$storage.resAlt.desc}}
    </div>
    <div class="flex-grow-1">
        <div *ngFor="let date of $storage.resAlt.alternative_results" class="border-bottom px-2 py-3">
            <h4>{{date.title_timestamp | date}}</h4>
            <div *ngIf="date.time_slots_by_area" class="pt-2">
                <table>
                    <tr *ngFor="let area of date.time_slots">
                        <td style="vertical-align:top" class="p-1 font-bold">{{$storage.preferencesMap[area.name]}}</td>
                        <td>
                            <button *ngFor="let slot of area.time_slots | orderBy" mat-stroked-button class="no-shadow m-1" (click)="selectAltSlot(area,slot)">
                                {{slot | date:'HH:mm': '': appService.localeId}}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngIf="!date.time_slots_by_area" class="pt-3">
                <button *ngFor="let slot of date.time_slots | orderBy" mat-stroked-button class="no-shadow m-1" (click)="selectAltSlot(null,slot)">
                    {{slot | date:'HH:mm': '': appService.localeId}}
                </button>
            </div>
        </div>
        <ng-container *ngIf="$storage.site && $storage.site.future_reservation && $storage.site.future_reservation.standby && $storage.site.future_reservation.standby.enabled">
            <div class="text-center px-2 py-3">
                {{bookService.translate('booking.search.standby_reservation_description')}}
            </div>
            <div class="p-3">
                <button mat-flat-button color="primary" class="lg rounded btn-block" type="button" (click)="addToWaitingList()">
                    {{bookService.translate('booking.search.button_label.standby_reservation')}}
                </button>
            </div>
        </ng-container>

    </div>


</div>