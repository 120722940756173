import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'web-delivery-header',
    templateUrl: './web-delivery-header.component.html',
    styleUrls: ['./web-delivery-header.component.scss']
})
export class WebDeliveryHeaderComponent implements OnInit {

    webSelectionBarFields = {
        text: true,
        service: true,
        area: true,
        date: false,
        hour: false,
        guests: false,
        tags: true,
        rating: false, // 14.08.2022 - Decided to deprecate for now
        price: false, // 14.08.2022 - Decided to deprecate for now
    };

    constructor() { }

    ngOnInit() {
    }

}
