export const orderingStrings_arIL = {
    "TO-ADD": "الإضافة",
    "TO-UPDATE": "التحديث",
    "JUST-CHECKING": "فقط نفحص",
    "ADD": "أضف",
    "UPDATE": "حدّث",
    "ORDER-TOTAL": "ملخص الطلبية",
    "CASH": "أموال نقدية",
    "PAYUP": "تابع للدفع",
    "THANK_YOU": "شكرًا جزيلاً!",
    "AMOUNT_TO_PAY": "المبلغ للدفع",
    "TIP": "الإكرامية",
    "PAYED": "تم الدفع",
    "CONTINUE": "المتابعة",
    "TOTAL_IN_TIP": "إجمالاً يشمل الإكرامية",
    "PARTIAL_PAY_IN_TIP": "الدفع الجزئي يشمل الإكرامية",
    "CLEAR": "امسح",
    "CLEAR_SELECTION": "امسح التحديد",
    "APPROVE": "التاكيد",
    "PAY": "الدفع",
    "CANCEL": "الإلغاء",
    "NO_THANKS": "لا, شكرًا",
    "REMAINED_PAY": "المبلغ المتبقي للدفع",
    "TABLE_BILL": "فاتورة للطاولة",
    "WAITER": "النادل",
    ";": "للدفع",
    "SAVE_CARD_INFO": "حفظ تفاصيل الدفع",
    "TOTAL": "إجمالاً",
    "CARD_HOLDER_NAME": "اسم صاحب البطاقة",
    "CARD_NUMBER": "رقم البطاقة",
    "SIGNIN": "تسجيل الدخول",
    "SIGNIN_INFO": "أدخل تفاصيل التعرف",
    "VALIDATING_CREDENTIALS": "جاري فحص المعطيات...",
    "WELCOME_BACK": "مرحبًا",
    "BACK_TO_MAIN_SCREEN": "العودة إلى الشاشة الرئيسية",
    "SELECT_EXISTING_CARD": "الاختيار من بطاقة قائمة",
    "ADD_NEW_CARD": "أضف بطاقة جديدة",
    "SAVE_CARD_AS_DEFAULT": "حفظ البطاقة كإعداد افتراضي",
    "ORDER_TOTAL": "إجمالي مبلغ الطلبية",
    "ORDER_TIP": "الإكرامية",
    "PAID": "تم الدفع",
    "PERFORMING_PAYMENT": "جاري الدفع...\nيرجى عدم إغلاق النافذة",
    "FINALIZING_ORDER": "جاري العمل على ذلك...\nيرجى عدم إغلاق الصفحة",
    "ENTER_ORDER_N": "أدخلوا رمز الطلبية",
    "REQUIERED": "التعبئة إلزامية",
    "REQUIERED_CHECK": "التوسيم إلزامي",
    "PASSWORDS_DONT_MATCH": "كلمات المرور غير متطابقة",
    "PASSWORDS_MIN_LENGTH": "يجب أن تكون كلمة المرور من 6 رموز على الأقل",
    "INVALID_CREDIT_NUMBER": "رقم البطاقة غير صالح",
    "INVALID_CVV": "CVV غير صالح",
    "INVALID_CARD_EXPIRATION": "تاريخ انتهاء الصلاحية غير صالح",
    "INVALID_EMAIL": "البريد الإلكتروني غير صالح",
    "EMAIL_FOR_INVOCE": "EMAIL لإرسال فاتورة",
    "CARD_EXPIRATION": "تاريخ انتهاء الصلاحية",
    "CARD_HOLDER_CELL": "هاتف صاحب البطاقة",
    "CARD_HOLDER_MAIL": "البريد الإلكتروني الخاص بصاحب البطاقة",
    "Q_SHARING_BILL": "للدفع الجزئي",
    "Q_TIP_AMOUNT": "كم إكرامية ينبغي إضافتها؟",
    "Q_HOW_MUCH_PAY": "ما هو المبلغ الذي تريد دفعه؟",
    "Q_HOW_MUCH_SPLIT": "كم عدد الأشخاص الذي تريد التقسيم بينهم؟",
    "Q_REGISTARED": "هل سجلت الدخول سابقًا؟ تسجيل الدخول",
    "Q_NEW_REGISTARED": "زبون جديد؟ التسجيل",
    "Q_INVOICE_BY_MAIL": "تريد الحصول على فاتورة عبر البريد الإلكتروني؟",
    "Q_REMOVE_CARD": "حذف هذه البطاقة؟",
    "Q_REMOVE_PAYMENT": "حذف هذا الدفع؟",
    "SIGNIN_REGISTER": "التسجيل / تسجيل الدخول",
    "END": "النهاية",
    "ENTER_TIP": "اطبع الإكرامية",
    "ENTER_AMOUNT": "اطبع المبلغ",
    "SPLIT_AMOUNT": "تقسيم المبلغ",
    "YOUR_AMOUNT_TO_PAY": "مبلغك للدفع؟",
    "PRESS_HERE": "اضغط هنا",
    "OTHER": "مبلغ آخر",
    "SENDING_INVOICE": "جاري إرسال الفاتورة...",
    "CHALLENGE_CODE": "رمز التحقق",
    "ENTER_CHALLENGE_CODE": "اطبع رمز التحقق",
    "REMEMBER_ME_ON_PHONE": "اذكرني على هذا الجهاز",
    "BILL_AMOUNT": "مبلغ الفاتورة",
    "PAY_WITH_ONOTHER_CARD": "ادفع ببطاقة أخرى",
    "PAYMENT_METHOD": "وسيلة دفع",
    "SELECT_PAYMENT_METHOD": "تحديد وسيلة الدفع",
    "CREDIT_CARD": "بطاقة ائتمان",
    "CREDIT": "الائتمان",
    "creditCard": "الائتمان",
    "CIBUS": "سيبوس",
    "cibus": "سيبوس",
    "TENBIS": "تِن بيس",
    "10bis": "تِن بيس",
    "Credit": "بطاقة ائتمان",
    "Ten Bis": "تِن بيس",
    "cash": "أموال نقدية",
    "EDIT_ACCOUNT_SETTINGS": "تعديل إعدادات الحساب",
    "MY_ACCOUNT": "حسابي",
    "SIGN_OUT": "تسجيل الخروج",
    "MY_ACCOUNT_DETAILS": "تفاصيل حسابي",
    "EDIT_PAYMENT_INFO": "تعديل وسيلة الدفع",
    "DELETE_CARD": "حذف البطاقة",
    "SET_DEFAULT_CARD": "حدد كإعداد افتراضي",
    "YES_DELETE": "نعم، احذف",
    "OFFER_REMARKS": "ملاحظات للوجبة",
    "ENTER_OFFER_REMARKS": "ملاحظات للوجبة",
    "DELETE_PAYMENT": "حذف الدفعة",
    "CONFIRM_CELL": "Confirm Phone",
    "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your mobile phone",
    "USE_ENTERED_PHONE": "Send to:",
    "ENTER_ALTERNATE_PHONE": "Use other:",
    "EMAIL_NOT_FOUND": "Email غير موجود على النظام",
    "GRATUITY": "الإكرامية",
    "WITHOUT_GRATUITY": "بدون إكرامية",
    "ORDER_SCHEDULING": "متى يناسبك تلقي الطلبية؟",
    "ORDER_SCHEDULING_TA": "متى يناسبك تلقي الطلبية؟",
    "ORDER_SCHEDULING_DELIVERY": "متى يناسبك تلقي الطلبية؟",
    "THROTTLING_DISCLAIMER": "أقرب الأوقات المتاحة لهذا الطلب هي",
    "ORDER_SCHEDULING_SCREEN_ANNOUNCEMENT": "تم فتح نافذة منبثقة لتحديد وقت التوصيل أو وقت التسليم",
    "SELECT_SERVICE_TYPE": "نوع الخدمة",
    "CLOSE": "مغلق",
    "YES": "نعم",
    "NO": "لا",
    "REMOVE": "الإزالة",
    "SEND_ORDER": "إرسال الطلبية",
    "TRACK_YOUR_ORDER": "لتتبع طلبيتك",
    "CONTINUE_ORDERING": "تابع للطلبية",
    "ADD_ITEM": "إضافة بند",
    "SELECT_ITEM": "حددوا أحد البنود",
    "UPDATE_ITEM": "تحديث البند",
    "TOPPINGS": "إضافات للوجبة",
    "DECISIONS": "القرارات",
    "ITEM_CHANGES": "تعديلات على الوجبة",
    "SELECTION_GROUP_SELECT": "(حددوا {{count}} بنود)",
    "SELECTION_GROUP_SELECT_SINGLE": "(حددوا بندًا واحدًا)",
    "SELECTION_GROUP_SELECT_MIN": "(حددوا على الأقل {{count}} بنود)",
    "SELECTION_GROUP_SELECT_SINGLE_MIN": "(حددوا على الأقل بندًا واحدًا)",
    "GROUP_MOD_SELECT_ERROR": "حددوا على الأقل {{count}} إضافات من: {{group}}",
    "GROUP_MOD_SELECT_SINGLE_ERROR": "حددوا على الأقل إضافة واحدة من: {{group}}",
    "GROUP_SELECT_ERROR": "حددوا على الأقل {{count}} بنود من: {{group}}",
    "GROUP_SELECT_SINGLE_ERROR": "حددوا على الأقل بندًا واحدًا من: {{group}}",
    "SELECTION_GROUP_OPTIONAL_MAX": "اختياري، حددوا ما يصل إلى {{count}} بنود",
    "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "اختياري، حددوا ما يصل إلى بند واحد",
    "SELECTION_GROUP_DIALOG_ERROR": "حدث خطأ في تحميل البند، يرجى تكرار المحاولة أو الاستفسار مع المطعم",
    "CHECKOUT": "للدفع",
    "Q_REMOVE_OFFER_FROM_BASKET": "تريد إزالة البند؟",
    "ENTER_DELIVERY_ADDRESS": "العنوان للإرسال",
    "ENTER_DELIVERY_ADDRESS_ALT": "مرحبًا، فقط للتحقق من الوصول إليك…",
    "SELECT_ADDRESS": "تحديد عنوان",
    "DELETE_ADDRESS_VALIDATE": "حذف العنوان؟",
    "BRANCH": "المطعم",
    "ADDRESS": "العنوان",
    "PHONE": "الهاتف",
    "ENTRANCE": "المدخل",
    "FLOOR": "الطابق",
    "apartment": "الشقة",
    "ENTER_STREET_INCLUDING_NUMBER": "أدخلوا العنوان الكامل",
    "MISSING_STREET_NUMBER": "ناقص رقم المنزل",
    "START_ORDER": "المتابعة للطلبية",
    "SELECT_CITY": "حددوا المدينة",
    "SELECT_DELIVERY_CITY": "حددوا المدينة التي يتم الإرسال إليها",
    "SELECT_BRANCH": "حددوا المطعم",
    "ORDER_MENU": "قائمة الطعام",
    "takeaway": "الاستلام الذاتي",
    "eatin": "الطلب والجلوس",
    "delivery": "الإرسال",
    "seated": "الجلوس في المطعم",
    "RESET_ORDER": "بدء الطلب مجددًا",
    "ORDER_FROM": "الطلب من الفرع",
    "ORDER_TO": "الإرسال إلى العنوان",
    "CONTACT_INFO": "التفاصيل الشخصية",
    "CONTACT_ALCOHOL_DECLARATION": "أبلغ من العمر أكثر من {{age}} وطالما يلزم الأمر سأبرز بطاقة للتحقق من هويتي لغرض شراء الكحول",
    "PAYMENT_INFO": "تفاصيل الائتمان",
    "FIRST_NAME": "الاسم الشخصي",
    "LAST_NAME": "اسم العائلة",
    "CELL": "الخلوي",
    "EMAIL": "Email",
    "REVIEW_YOUR_ORDER": "تفاصيل الطلبية",
    "ADD_ITEMS": "إضافة بنود",
    "UPDATE_ITEMS": "تحديث بنود",
    "ORDER_SENT_SUCCESS": "تم إرسال الطلبية بنجاح",
    "ORDER_NEED_ATTENDANCE": "تم إرسال الطلبية مع خطأ",
    "CALL_RESTAURANT_TO_COMPLETE_ORDER": "يرجى الاتصال بالمطعم",
    "ORDER_NUMBER": "رقم الطلبية",
    "START_NEW_ORDER": "بدء طلبية جديدة",
    "ORDER_ITEMS": "تفاصيل الطلبية",
    "ORDER_WILL_BE_SENT_TO": "سيتم إرسال الطلبية إلى العنوان",
    "ESTIMATED_DELIVERY_TIME": "وقت الوصول حوالي",
    "ESTIMATED_PREPARATION_TIME": "وقت الوصول حوالي",
    "ESTIMATED_SUPPLY_TIME": "وقت الإمداد حتى",
    "MINUTES": "الدقائق",
    "MINUTES_SHORT": "الدقائق",
    "PREPARATION_TIME": "وقت التحضير",
    "ORDER_TYPE": "نوع الطلبية",
    "PLEASE_SELECT": "يرجى التحديد",
    "PLACEMENT": "الخيارات",
    "NUMBER_OF_PEOPLE": "عدد الزبائن",
    "READ_AND_APPROVE_CONTRACT": "قرأت وأوافق",
    "READ_AND_APPROVE_CONTRACT_C": "للاطلاع على شروط الاستخدام",
    "READ_AND_APPROVE_CONTRACT_D": "للاطلاع على سياسة الخصوصية",
    "READ_CONTRACT": "إقرأ تفاصيل الاستخدام...",
    "PLEASE_APPROVE_CONTRACT": "يرجى الموافقة على شروط الاستخدام",
    "USAGE_CONRACT": "شروط الاستخدام",
    "PRIVACY_CONRACT": "سياسة الخصوصية",
    "ADDRESS_REMARKS": "ملاحظات للساعي",
    "LOADING_MENU": "جاري تحميل قائمة الطعام...",
    "MOD_WITH": "الإضافات",
    "MOD_WITHOUT": "بدون",
    "PASSWORD": "كلمة مرور",
    "CONFIRM_PASSWORD": "أدخل كلمة المرور مجددًا",
    "FORGOT_PASSWORD": "نسيت كلمة المرور...",
    "RESTORE_PASSWORD": "استرجاع كلمة المرور",
    "INCLUDE_SILVERWARE": "إضافة أدوات المائدة المستخدمة لمرة واحدة",
    "CURBSIDE_PICKUP": "الطلب للسيارة",
    "CURBSIDE_PICKUP_DESC": "صفوا من فضلكم نموذج السيارة، ولونه ورقم لوحة ترخيضه",
    "AUTHENTICATING": "جاري التحقق من التفاصيل",
    "SIGNED_IN_AS": "سجل الدخول باسم",
    "PAY_WITH": "ادفع بواسطة",
    "PAY_WITH_SAVED_PMS": "وسائل الدفع محفوظة",
    "SELECT_ALTERNATE_PYMENT": "حددوا وسيلة دفع أخرى",
    "SIGHN_IN_EMAIL": "Email للتحقق من الهوية",
    "SIGHN_IN_PASSWORD": "كلمة مرور للتحقق من الهوية",
    "UPDATE_PAYMENT_INFO": "تحديث وسائل الدفع",
    "SAVE_ACCOUNT_PAYMENT_INFO": "حفظ وسيلة الدفع في حسابك",
    "UPDATE_ACCOUNT": "تحديث الحساب",
    "ADD_PAYMENT_INFO": "إضافة وسيلة دفع",
    "UPDATING_ACCOUNT": "جاري تحديث حسابك...",
    "UPDATE_SIGNIN_INFO": "حدّث التفاصيل المعرّفة",
    "PASS_MIN_LENGTH_ERROR": "يجب أن تتضمن كلمة المرور على الأقل 6 رموز",
    "OLD_PASSWORD": "كلمة مرور قديمة",
    "NEW_PASSWORD": "كلمة مرور جديدة",
    "BRANCH_TIME_0": "أوقات عمل الخدمة {{t0}}",
    "BRANCH_TIME_1": "أوقات عمل الخدمة {{t0}} و {{t1}}",
    "BRANCH_TIME_CONFIRM_0": "نحن مغلقون حاليًا. سنجدد العمل بين الساعات {{t0}}",
    "BRANCH_TIME_CONFIRM_1": "نحن مغلقون حاليًا. سنجدد العمل بين الساعات {{t0}} و {{t1}}",
    "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "الخدمة لم تعد متاحة. سنجدد العمل بين الساعات {{t0}}",
    "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "الخدمة لم تعد متاحة. سنجدد العمل بين الساعات {{t0}} و {{t1}}",
    "CONFIRM": "التأكيد",
    "ORDER_SUCCESS": "تأكيد الطلبية",
    "MIN-ORDER-PRICE": "مبلغ الطلبية الأدنى",
    "DELIVERY-PRICE": "رسوم الإرسال",
    "TOTAL-ITEMS": "إجمالي عدد البنود",
    "TOTAL-TO-PAY": "إجمالي مبلغ الدفع",
    "WITHOUT": "بدون",
    "CONTINUE_TO_PAYMENTINFO": "المتابعة لتفاصيل الدفع",
    "PAYMENT": "الدفع",
    "BRANCHES": "الفروع",
    "SERVER_ERROR": "خطأ الخادم",
    "ERROR": "حطأ",
    "PAYMENT_ERROR": "خطأ الدفع",
    "PARTIAL_PAYMENT_ERROR": "خطأ الدفع",
    "NAME": "الاسم",
    "REGISTERING": "حملة التسجيل",
    "REGISTER": "التسجيل",
    "RESET_PASSWORD": "إعادة ضبط كلمة المرور",
    "RESET_PASSWORD_INFO": "أدخل كلمة مرور جديدة واضغط على صفر",
    "ORDER_HISTORY": "تاريخ الطلبيات",
    "OFFERS_HISTORY": "حربت طلبيات سابقًا",
    "SELECT_ORDER_METHOD": "يرجى تحديد خدمة",
    "ORDER_METHOD": "شكل الطلب",
    "ORDER_FIXED": "حساب",
    "SET_QUANTITY": "أدخل الكمية",
    "CELL_PATTERN": "סלולרי צריך להתחיל ב 05",
    "CELL_MINLENGTH": "الخلوي ينبغي أن يتضمن 10 رموز",
    "VIEW_MENU": "مشاهدة قائمة الطعام",
    "ORDER_SERVICE_CLOSED": "خدمة الطلبيات مغلقة",
    "ESTIMATED_TIME": "الوقت المقدر",
    "SET_ORDER_DELAY": "متى يناسبك تلقي الطلبية؟",
    "DELAY_TO": "أجّل للساعة",
    "SET_ORDER_DELAY_TO_HOUR": "أجّل تنفيذ الطلبية للساعة",
    "ASAP": "أبكر ما يمكن",
    "ENTER_CVV": "CVV",
    "ENTER_CVV_MESSAGE": "CVV للبطاقة: {{card}}",
    "ENTER_IDCARD": "بطاقة الهوية",
    "ENTER_IDCARD_MESSAGE": "بطاقة الهوية للبطاقة: {{card}}",
    "PRMARY_PAYMENT_METHOD": "إعداد افتراضي",
    "ADD_PAYMENT": "إضافة دفع",
    "SPLIT_PAYMENT": "افصل الدفع",
    "WALLET_PAYMENT": "محفظة, {{type}}",
    "SPLIT_PAYMENTS_DIFF_ERROR": "المدفوعات لا تتلخص في {{total}}, هناك فارق بواقع {{diff}}",
    "BACK_TO_SITE": "بالعودة إلى الموقع",
    "BACK_TO_APP": "العودة إلى التطبيق",
    "FREE_DELIVERY_FROM": "الإرسال المجاني من سعر",
    "ADD_REMAINNING_AMOUNT": "أكمل الدفع لمبلغ الطلبية",
    "PAYMENTS_EXCEED_TOTAL_BY": "إجمالي المدفوعات أكبر من مبلغ الطلبية بواقع",
    "PAYMENT_AMOUNT": "مبلغ الدفع",
    "ENTER_FIRST_PAYMENT_AMOUNT": "أدخل مبلغًا للدفع الأول",
    "EXCESS_PAYMENT": "رصيد الدفع",
    "CLICK_TO_REMOVE_EXCESS_AMOUNT": "انقر للخصم من المبلغ الفائض",
    "CANCEL_SPLIT_PAYMENT": "إلغاء تقسيم الدفع",
    "APPLY_PAYMENTS": "تأكيد المدفوعات",
    "SINGLE_PAYER": "دافع فردي",
    "MULTIPLE_PAYERS": "عدد من الدافعين",
    "SPLIT_ORDER_BETWEEN_PAYERS": "التقسيم على عدد من الدافعين",
    "CALL_RESTAURANT": "الاتصال بالمطعم",
    "SELECT_TABLE": "إلى أين ينبغي تقديم الطعام",
    "TABLE_NO": "رقم الطاولة",
    "TABLE_NO_ORDER": "رقم الطاولة {{no}}",
    "ENTER_ORDER_NO": "إدخال رقم الطلبية",
    "ADD_GRATUITY_TO_ORDER": "إكرامية",
    "ADD_GRATUITY_TO_COURIER": "تريد إضافة إكرامية؟",
    "ORDER_TOTAL_WITHOUT_GRATUITY": "المبلغ الإجمالي للدفع بدون الإكرامية",
    "ORDER_TOTAL_WITH_GRATUITY": "المبلغ الإجمالي للدفع يشمل الإكرامية: {{total}}",
    "GRATUITY_AMOUNT": "مبلغ الإكرامية",
    "GRATUITY_PERCENT": "النسبة المئوية للإكرامية",
    "ENTER_GRATUITY_AMOUNT": "إدخال مبلغ الإركامية",
    "CONTINUE_TO_GRATUITY": "المتابعة لإدخال الإكرامية",
    "REFRESH_ORDER": "تحديث الطلبية",
    "MONTH": "الشهر",
    "YEAR": "العام",
    "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "إدخال مبلغ للدفع، لا يشمل الإكرامية",
    "ENTER_AMOUNT_TO_PAY": "المبلغ للدفع",
    "FULL_PAYMENT": "الدفع الكامل",
    "PARTIAL_PAYMENT": "الدفع الجزئي",
    "AMOUNT_EXEEDS_BALANCE_DUE": "المبلغ أكبر من رصيد الدفع",
    "MAX_TO_USE": "لديك {{amount}} {{type}} يمكنك الاستفادة منها",
    "EXCEEDED_CASH_AMOUNT": "المبلغ الأقصى للدفع النقدي هو {{amount}} شيكل",
    "POINTS": "النقاط",
    "CANNOT_PAY_TIP": "لا يمكن ترك إكرامية مع وسيلة الدفع هذه",
    "MY_WALLET": "محفظتي:",
    "OTHER_TENDERTYPES": "غير ذلك:",
    "NEW_PAYMENT": "دفعة جديدة",
    "REMAINDER_NOT_EXISTS": "لم يبقَ رصيد للدفع، ينبغي تعديل مبالغ الدفعات",
    "BACK": "العودة",
    "PASS_ORDER": "تتقاسمون حسابًا؟",
    "PASS_ORDER_TITLE": "التحويل إلى صديق",
    "PASS_ORDER_BY_PHONE_MESSAGE": "لتحويل الرابط عبر رسالة نصية ينبغي إدخال رقم هاتف",
    "PASS_ORDER_BY_PHONE_CAPTION": "رقم خلوي",
    "PASS_ORDER_BY_PHONE_SEND": "إرسال دعوة",
    "PASS_ORDER_BY_PHONE_QR_MESSAGE": "أو مسح الرمز الشريطي",
    "PASS_ORDER_SENT_SUCCESS": "تم إرسال الطلبية بنجاح",
    "CANNOT_SEND_ORDER_BY_SMS": "نعتذر لاستحالة إرسال الرابط حاليًا.",
    "WORK_HOURS": "أوقات العمل",
    "RESTAURANT_WEBSITE": "موقع المطعم",
    "SELECT_ONE": "حددوا واحدًا",
    "DELIVERY_ADDRESS": "العنوان للإرسال",
    "DELIVERY_DETAILS": "تفاصيل الإرسال",
    "ADDRESS_DETAILS": "تفاصيل العنوان",
    "BRANCH_DETAILS": "تفاصيل المطعم",
    "ADDITIONAL_OPTIONS": "المزيد من الخيارات",
    "MINUTES_VAL": "{{m}} دقائق",
    "ADDITIONAL_OFFERS": "الإضافات والصلصات",
    "SELECT_ADDITIONAL_OFFERS": "اختاروا الإضافات والصلصات",
    "CONTINUE_TO_CONTACTINFO": "المتابعة لتفاصيل الاتصال",
    "CC_CUSTOMER_NAME": "الاسم",
    "CC_CUSTOMER_ID": "رقم الهوية / رقم الشركة الخاصة",
    "ID_CARD": "بطاقة الهوية",
    "ORDERER_NAME": "اسم الجهة الطالبة",
    "ORDERER_PHONE": "الهاتف",
    "ORDER_NO": "رقم الطلبية",
    "ORDER_TAGS": {
        "BREAKFAST": "الإفطار",
        "LUNCH": "الغداء",
        "DINNER": "العشاء",
        "WORK": "العمل"
    },
    "ENTER_LOCALITY": "اسم المدينة/ البلدة",
    "LOCALITY_TITLE": "اسم المدينة/ البلدة",
    "STREET_TITLE": "اسم الشارع",
    "HOUSE_TITLE": "رقم المنزل",
    "TITLE": "{{name}} الإرساليات - TabitOrder",
    "TITLE_1": "{{name}} الإرساليات",
    "TITLE_2": "{{name}} - TabitOrder",
    "PAYMENT_IN_PROGRESS": "جاري الدفع...\nيرجى عدم إغلاق النافذة",
    "CREATING_ORDER": "جاري إرسال الصفقة...",
    "LOADING_ORDER": "جاري تحميل الصفقة...",
    "INVALID_AMOUNT": "مبلغ الدفع غير صالح",
    "INVALID_AMOUNT_RANGE": "مبلغ صالح بين {{min}} و {{max}}",
    "INVALID_AMOUNT_MIN": "مبلغ الحد الأدنى {{min}}",
    "INVALID_AMOUNT_MAX": "مبلغ الحد الأقصى {{max}}",
    "BACK_TO_MENU": "العودة إلى قائمة الطعام",
    "ORDER_NOTES": "ملاحظات الطلبية",
    "Q_SAVEPM_1": "معنيون بتقصير فترة الطلبية؟",
    "Q_SAVEPM_2": "ניתן לשמור את אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
    "Q_SAVEPM_3": "תשלום ושמירה",
    "Q_SAVEPM_4": "תשלום ללא שמירה",
    "DISH_CALCULATOR": "حاسبة الوجبات",
    "MAKE_ADDITIONAL_PAYMENT": "المتابعة لدفع آخر",
    "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "الدفع الإضافي بهذا الجهاز’آ",
    "DINER": "الزبون",
    "GENERAL_DINER": "وسط الطاولة",
    "ADDRESS_WITHOUT_HOUSE": "عنوان بدون رقم منزل",
    "TAX_AMOUNT": "الضرائب",
    "ADDRESS_TYPE": "نوع العنوان",
    "VALID_UNTILL": "ساري المفعول حتى",
    "COMMENT": "ملاحظة",
    "LEAVE_ORDER_OUTSIDE": "اتركوا الرزمة خارج الباب",
    "LEAVE_ORDER_OUTSIDE_CASH": "إذن تعالوا نحافظ على المسافة😊- ستتاح الدفعلات الرقمية فقط",
    "LEAVE_ORDER_OUTSIDE_COMMENT": "اتركوا خارج الباب",
    "arr_join": " و",
    "SIGN_OUT_MESSAGE": {
        "TITLE": "لحظة قبل الوداع",
        "TEXT": "فقط نتأكد من أنك كنت تريد تسجيل الخروج",
        "PRIMARY_BUTTON": "نعم",
        "SECONDARY_BUTTON": "لا، أريد المتابعة"
    },
    "MESSAGES": {
        "PLACE-MAP-MARKER": "يرجى وضع الخارطة في أقرب مكان ممكن من العنوان",
        "MAX_ORDER_OFFERS_CONT": "لم يعد هناك مكان في السلة…\nلا يمكن طلب أكثر من {{max}} بنود في الطلبية",
        "MAX_CAT_OFFERS_CONT": "لا يمكن طلب أكثر من {{max}} بنود من قائمة الطعام {{target}}",
        "MAX_SAME_DISH_COUNT": "لا يمكن طلب أكثر من {{max}} {{target}}",
        "SIGNIN-EMAIL-EX": "أدخل عنوان البريد الإلكتروني الذي سجلت به سابقًا",
        "SIGNIN-EMAIL-EX-HELP": "سيتم إرسال رمز التحقق إلى الهاتف الجوال المسند لعنوان البريد الإلكتروني",
        "SIGNIN-CHALLENGE-EX": "تم إرسال رمز التحقق إلى الهاتف",
        "SIGNIN-CHALLENGE-EX-HELP": "لم تحصل على الرمز؟ <b>أرسلوا مجددًا</b>",
        "SAVE_INFO": "حفظ تفاصيل الاتصال والدفع",
        "SAVE_INFO_END": "سجلوا للطلب السهل والسريع في المستقبل",
        "SAVE_INFO_PAYMENT": "حفظ تفاصيل الدفع",
        "SIGNIN_CHALLENGE": "تم إرسال رمز إجابة إليك من خلال SMS، يرجى إدخاله ثم الضغط على تسجيل الدخول",
        "PAYMENT_CHALLENGE": "تم إرسال رمز تحقق إلى \n{{phone}}\nمن خلال SMS",
        "PAYMENT_ENTER_PHONE": "فقط التحقق السريع من الهاتف ثم سنتابع",
        "PAYMENT_OTP": "أرسلنا إليك رمز تحقق إلى \n {{phone}}",
        "ROOM_CHARGE_CHALLENGE": "تم إرسال رمز التحقق إلى الرقم الذي ينتهي بالأرقام {{number}}",
        "TRANSACTION_SUCCESS": "تم إنجاز الدفع بنجاح",
        "ORDER_CLOSED": "تم دفع الطلبية بأكملها",
        "INVALID_ORDER": "لم يتم العثور على الطلبية",
        "PAYMENT_FAILED": "فشل الدفع",
        "INVALID_PIN": "الرمز الذي تم إدخاله غير متطابق",
        "SIGN_FOR_PAYMENT_CONFIRM": "لتأكيد الدفع وقّع هنا",
        "SIGNATURE_MANDATORY": "لكي نستطيع تأكيد وسيلة الدفع، يجب التوقيع!",
        "WAITER_NOTIFIED": "لقد تلقى فريقنا إشارة وسيصل إليك قريبًا!",
        "HAPPY": "سيسرنا مشاهدتكم مجددًا",
        "INVOICE_SENT": "تم إرسال فاتورة إليك عبر البريد الإلكتروني",
        "ITEM_ADDED_TO_BASKET": "تمت إضافة بند إلى السلة",
        "NO_BASKET_ITEMS": "لا توجد هناك بنود في السلة",
        "CONFIRM_ORDER_FROM_BRANCH": "لقد اخترت الطلب من فرع: {{branch}}",
        "FINDING_THE_BRANCH_NEAREST_TO_YOU": "يعثر على أقرب فرع من منزلكم",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "لم يتم العثور على فرع يخدم العنوان: {{address}}",
	    "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE": "نحن حاليًا نقوم بشحن الطلبات المستقبلية فقط إلى هذا العنوان",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "نحن نقوم حاليًا بالشحن إلى هذا العنوان",
        "NO_BRANCH_SERVING_ADDRESS": "المطعم لا ينفذ الإرساليات إلى هذا العنوان",
        "CONFIRM_RESET_ORDER": "إعادة بدء الطلبية؟",
        "DO_YOU_APPROVE_CONTRACT": "هناك موافقة على شروط الاستخدام؟",
        "DO_YOU_APPROVE_CONTRACT_TITLE": "لحظة قبل أن نتابع",
        "CONDITIONS_PRIMARY_BUTTON_TEXT": "نعم",
        "CONDITIONS_SECONDARY_BUTTON_TEXT": "الخروج",
        "RESTORE_PASSWORD_1": "يرجى إدخال الـ Email الذي استخدمته للتعرف على هويتك.",
        "SIGNIN_SUCCESS": "مرحبًا {{name}}، لقد سجلت الدخول بنجاح إلى النظام",
        "FORGOTPASS_SUCCESS": "تم إرسال تعليمات تسجيل الدخول إلى عنوان الـ Email الذي أدخلتموه",
        "ACCOUNT_UPDATE_SUCCESS": "تم تحديث حسابك بنجاح!",
        "TITLE_HUNGRY": "يؤسفنا إبلاغك",
        "BRANCH_DISABLED_NOW": "بأن الخدمة مغلقة حاليًا.\n {{t}}.",
        "BRANCH_DISABLED_NOW_ERROR": "الخدمة لم تعد متاحة. سنجدد العمل بين الساعات {{t}}.",
        "BRANCH_DISABLED_NOW_DELAYED_ENABLED": "الخدمة مغلقة حاليًا. يمكن إجراء الطلبية لتاريخ آخر.",
        "BRANCH_CLOSED_TODAY": "هذه الخدمة غير نشطة اليوم",
        "BRANCH_CLOSED_NOW": "سيتم فتح الخدمة عند الساعة {{t}}, لكن لا داعي للقلق,\nيمكن إجراء طلب مبكر والذي سيدخل إلى التحضير بمجرد فتح الخدمة.",
        "MIN-ORDER-PRICE": "الحد الأدنى من الطلبية المرسلة هو {{t}}\n(لا يشمل رسوم الإرسال).\nينبغي إضافة بنود إلى الطلبية",
        "SERVER_ERROR": "وقع خطأ عند معالجة الطلب,\nאيرجى الاتصال بالمطعم",
        "PAYMENT_ERROR": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل.",
        "PAYMENT_ERROR_TITLE": "خطأ الدفع",
        "REGISTER_SUCCESS": "سجلت بنجاح",
        "PASSWORD_RESET_SUCCESS": "تمت إعادة ضبط كلمة المرور بنجاح",
        "CUSTOMER_ENTITYALREADYEXISTS": "زبون لديه البريد الإلكتروني المعيّن موجود فعلاً على النظام",
        "PHONE_VERIFICATION_FAILED": "يبدو أن الرمز غير صحيح، يرجى تكرار المحاولة.",
        "PHONE_VERIFICATION_FAILED_TITLE": "لحظة...",
	    "TOO_MANY_CODE_REQUESTS": "تم حظر حسابك مؤقتًا لأنك وصلت إلى الحد الأقصى لعدد المحاولات المسموح بها.\nيرجى المحاولة مرة أخرى لاحقًا.",
        "VOUCHER_ALREADY_USED": "يبدو أن القسيمة قد تم استردادها بالفعل",
        "SERVICE_UNAVAILABLE": "هذه الخدمة غير نشطة حاليًا، يرجى تكرار المحاولة في وقت لاحق",
        "SERVICE_UNAVAILABLE_THROTTLING": "نحن حاليًا بكامل طاقتنا. يرجى إعادة تشغيل طلبك في فترة زمنية أخرى.",
        "SERVICE_UNAVAILABLE THROTTLING With_PHONE": "نحن حاليًا بكامل طاقتنا.\يرجى الاتصال بالمطعم ({{phone}})",
        "IG_CANT_SELECT_MORE_THEN": "يرجى تحديد ما يصل إلى {{max}} بنود",
        "MOD_CANT_SELECT_MORE_THEN": "يرجى تحديد ما لا يتجاوز {{max}} إضافات!",
        "MOD_PLEASE_SELECT_AT_LEAST": "ينبغي تحديد على الأقل {{min}} إضافات!",
        "MOD_PLEASE_SELECT_AT_LEAST_ONE": "ينبغي تحديد إضافة واحدة على الأقل!",
        "CANNOT_PAY_TRAINING_MODE": "النظام في وضع التمرن. لا يمكن إتمام الصفقة. يرجى الاتصال بالمطعم\n {{phone}}",
        "ARE_YOU_SURE": "هل أنت متأكد",
        "DISABLED_REGION_TITLE": "خدمة الإرساليات غير متاحة",
        "DISABLED_REGION_MESSAGE": "نحن لا نصل حاليًا إلى هذا العنوان.\nيرجى تكرار المحاولة لاحقًا",
        "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "حاليًا نحن نرسل الطليات الفورية فقط إلى هذا العنوان",
        "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "حاليًا نحن نرسل الطليات المستقبلية فقط إلى هذا العنوان",
        "ENABLED_REGION_TIME_MESSAGE": "سنعود للعمل بين الساعات {{t}}",
        "ENABLED_REGION_DAY_MESSAGE": "سنعود للعمل {{d}}, بين الساعات {{t}}",
        "OUT_OF_REACH": "يؤسفنا إبلاغك",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "بأن أحد أو أكثر من الدفعات التي أدخلتها فشلت. يرجى الاتصال بالمطعم لإكمال الطلبية.\n {{phone}}",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "أحد أو أكثر من الدفعات التي أدخلتها فشلت. يرجى التوجه إلى النادل/ة لمتابعة المعالجة",
        "ORDER_CHANGED_AFTER_HANDOFF": "نلفت انتباهكم!\nقد تم إرسال الطلبية من المطعم فعلاً، لم يتم حفظ التعديلات الجديدة.\nيرجى الاتصال بالمطعم لإجراء التعديل: {{phone}}",
        "SELECTTABLE_INFO": "يرجى إدخال رقم الطاولة التي تريد الطلب إليها",
        "ENTERORDER_INFO": "يرجى إدخال رقم الطلبية التي تريد الدفع نظيرها.",
        "INVALID_TABLE": "الطاولة غير موجودة",
        "ORDER_RELOAD_NEEDED": "يرجى الانتباه! مبلغ الدفع تم تعديله!",
        "ORDER_HELD_BY_SERVER": "نحن نعتذر، لكن لا يمكن الدفع في هذه اللحظة. يرجى تكرار المحاولة بعد عدة دقائق",
        "SESSION_VALIDATION_FAILED": "وقع خطأ في معالجة الطلب، يرجى تحديث الصفحة وتكرار المحاولة",
        "PAY_ORDER_CHANGED": "تم تعديل الطلبية! يرجى مراجعة الطلبية.",
        "PAYMENT_ACCEPTED_SUCCESSFULLY": "تم تنفيذ الدفع بنجاح",
        "PAYMENT_ACCEPTED": "تم تنفيذ الدفع بنجاح",
        "ITEM_OUTOF_STOCK": "الوجبة التي حددتها غير موجودة في المخزون حاليًا لكن هناك العديد من الخيارات غيرها!",
        "ITEM_OUTOF_STOCK_TITLE": "للأسف نفدت",
        "ITEM_OUTOF_STOCK_BUTTON": "العودة إلى قائمة الطعام",
        "OUT_OF_STOCK": "الوجبات التالية نفدت من المخزون خلال الطلب:\n {{items}}\n سيرنا اقتراح خيارات أخرى عليك من قائمة الطعام",
        "GRATUITY_CASH_DISABLE": "لا يمكن الدفع بأموال نقدية إذا ما كانت هناك إكرامية",
        "ENABLE_GRATUITY_CREDIT_ONLY": "يمكن الدفع ببطاقة ائتمان فقط إذا ما كانت هناك إكرامية",
        "ORDER_ITEMS_UNAVALABLE": "للأسف البنود التالية غير متاحة حاليًا للطلب:",
        "ORDER_TIMEOUT": "انتهت صلاحية الطلبية! يرجى بدء الطلبية من جديد",
        "CONFIRM_ADDRESS": "العنوان الذي تم إدخاله هو:\n<b>{{address}}</b>\nهل تريد المتابعة؟",
        "CONFIRM_ADDRESS_SITE": "ستصل الإرسالية من فرع: {{a}}\nإلى العنوان: {{b}}",
        "PROBLEM_IN_PAYMENT_INPUT": "وقع خطأ في استيعاب تفاصيل الدفع\nيرجى إعادة إدخال تفاصيل الدفع\nفي حال استمرت المشكلة، يرجى الاتصال بالمطعم هاتفيًا: \n {{phone}}",
        "MEAL_CALC_FACTOR_DESC": "يتم حساب الخصم على كل بند حسب وزنه النسبي في الطلبية",
        "MEAL_CALC_ACTION_DESC": "ينبغي توسيم الوجبات للدفع",
        "MEAL_CALC_TOTAL_DESC": "إجمالي الوجبات لا يمكن أن يتجاوز المبلغ المتبقي للدفع",
        "EX_PAYMENT_ERROR": "وقع خطأ في التحويل إلى مزود الدفع",
        "EX_PAYMENT_AUTHRIZATION_ERROR": "وقع خطأ في تأكيد الدفع من المزود",
        "LOCALITY_WO_STREETS": "بالنسبة للبلدة {{locality}} لا يتم تعريف أسماء الشوارع\nهل تريد تحديد هذه البلدة باعتبارها وجهة إرسالية؟",
        "SERVICE_END_WARN": "سيتم إغلاق الخدمة بعد {{t}} دقائق. من المستحسن استكمال الطلبية قبل ذلك",
        "JUST_SO_YOU_KNOW": "يهمنا الإبلاغ",
        "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "الموافقة",
        "SUMMARY_SENT_BY_MAIL": "خلاصة الطلبية تم إرسالها إلى بريدك الإلكتروني",
        "CONTINUE_WITHOUT_GRATUITY": "المتابعة بدون الإكرامية؟",
        "LOYALTY_BENEFIT_TIMEOUT_ERROR": "مرحبًا، لقد مر بعض الوقت...\nسنحتاج للاستفادة من امتيازاتك مجددًا",
        "LOYALTY_BENEFIT_HANDOFF_ERROR": "لا يمكن الدفع بالبطاقة المنتهية بالأرقام {{num}} في هذه الطلبية.\nللمزيد من المعلومات يرجى التوجه إلى المطعم",
        "LOYALTY_BENEFIT_CONFLICT_ERROR": "אופס..\nيبدو أن هناك مشكلة أو أن الامتياز قد تمت الاستفادة منها فعلاً.\nيرجى تكرار المحاولة",
        "CARD_VALIDATION_MISMATCH": "Card validation mismatch",
        "CARD_VALIDATION_ACTION_NEEDED": "مطلوب إجراء إضافي لاستكمال العملية",
        "ORDER_ITEMS_VALIDATION_ERROR": "لا يمكن طلب البنود المحددة.\nيرجى الاتصال بالمطعم لمتابعة الطلبية",
        "SIGNIN_SISABLED": "Sign in/Registration is temporarily disabled. you can still place orders as usual by entering your contact info.",
        "GRATUITY_NOT_ALLOWED_FOR_PM": "في هذا المطعم لا يمكن إضافة إكرامية بوسيلة الدفع هذه.\nينبغي تحديد وسيلة دفع أخرى أو إلغاء إضافة الإكرامية.",
    },
    "EX_PAYMENT": {
        "dialog_title": "الموافقة على الصفقة",
        "description": "هذه الصفقة تُستخدم باعتبارها موافقة على الصفقة للدفع على الموقع. سيتم الدفع بالفعل فقط بعد استكمال العملية على الصفحة الرئيسية للمصلحة التجارية. يجب أولاً تأكيد صحة جميع التفاصيل الموجودة في هذه الصفحة وتعبئة تفاصيل الائتمان كما هو مطلوب.",
        "popup-title": "تم تأكيد تنفيذ الصفقة",
        "form-title": "تفاصيل بطاقة الائتمان",
        "popup-description": "الآن سنتحول إلى الصفحة الخاصة بالمصلحة التجارية",
        "submit-btn": "المتابعة",
        "clear": "إعادة الضبط",
        "credit_card_number": "رقم البطاقة",
        "expiration": "صلاحية البطاقة",
        "cancel": "الإلغاء",
        "currency": "العملة:",
        "transaction_details": "تفاصيل الصفقة",
        "total_amount": "المبلغ",
        "month": "الشهر",
        "year": "العام",
        "click_here": "اضغط هنا للانتقال"
    },
    "SERVER_MESSAGES": {
        "OrderLockedByAnotherUser": "Payment failed. Check is handled by another user",
        "INVALID_USER_ORPASS": "اسم المستخدم أو كلمة المرور غير صحيحين",
        "UPDATE_ACCOUNT_ERROR": "لم تستطع تحديث حسابك",
        "EMAIL_NOT_FOUND": "Email غير موجود على النظام",
        "EMAIL_IN_USE": "Email موجود على النظام! يرجى تحديد Email مختلف",
        "INVALID_ORDER_ID": "يبدو أن الطلبية قد تمت إزالتها",
        "FIREBASE_DOWN": "الخدمة غير متاحة",
        "SHVA_OFFLINE": "يبدو أن هناك مشكلة مع الخصومات الائتمانية حاليًا.\nنوصي بالدفع بوسيلة دفع مختلفة، أو إذا الجوع لم يكن يزعجنا كثيرًا، تكرار المحاولة بعد بضع دقائق.",
        "PAYMENT_QUOTA_EXCEEDED": "لا يوجد هناك رصيد يكفي على البطاقة({{v}})",
        "SMS_PHONE_ERROR": "لا يمكن إرسال رسالة نصية إلى الرقم الذي تم إدخاله {{phone}}.\nيرجى الاتصال بالمطعم",
        "CREDIT_GUARD_EX_ERROR": "وقع خطأ في معالجة الطلب، يرجى تكرار المحاولة."
    },
    "CREDIT_GUARD": {
        "ERROR_CODE_001": "البطاقة محجوبة، يرجى الاتصال بشركة الائتمان (الرمز 001).",
        "ERROR_CODE_002": "البطاقة مسروقة، يرجى الاتصال بشركة الائتمان (الرمز 002).",
        "ERROR_CODE_003": "وقع خطأ، يرجى الاتصال بشركة الائتمان (الرمز 003).",
        "ERROR_CODE_004": "هناك رفض من شركة الائتمان، الصفقة لم يتم اعتمادها (الرمز 004).",
        "ERROR_CODE_006": "بطاقة الهوية او ثلاثة الأرقام على ظهر البطاقة غير صحيحة، يرجى تكرار المحاولة (الرمز 006).",
        "ERROR_CODE_010": "الصفقة غير معتمدة للتنفيذ، يرجى التحقق الزائد. ينبغي الاتصال بشركة الائتمان (الرمز 010).",
        "ERROR_CODE_033": "رقم البطاقة الذي تم إدخاله غير صحيح، يرجى تكرار المحاولة (الرمز 033).",
        "ERROR_CODE_036": "البطاقة منتهية الصلاحية (الرمز 036).",
        "ERROR_CODE_039": "رقم البطاقة الذي تم إدخاله غير صحيح، يرجى تكرار المحاولة (الرمز 039).",
        "ERROR_CODE_173": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 173).",
        "ERROR_CODE_200": "لن نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 200).",
        "ERROR_CODE_316": "فشل واجهة خارجية. يرجى الاتصال بشركة الائتمان (الرمز 316).",
        "ERROR_CODE_358": "لم يتم العثور على تفاصيل حساسة على قاعدة البيانات (الرمز 358).",
        "ERROR_CODE_401": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 401).",
        "ERROR_CODE_405": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 405).",
        "ERROR_CODE_414": "لم نستطع إنجاز الدفع، نوصي بالتأكد من صحة كافة التفاصيل (الرمز 414).",
        "ERROR_CODE_520": "لا يوجد هناك إذن بإنجاز الصفقة، يرجى الاتصال بشركة الائتمان (الرمز 520).",
        "ERROR_CODE_599": "الخدمة الرمزية لدى الجهة المصدرة لبطاقة الائتمان غير متاحة، يرجى الاتصال بشركة الائتمان (الرمز 599).",
        "ERROR_CODE_695": "بطاقة الائتمان من هذا النوع غير مدعومة، يرجى الدفع ببطاقة أخرى (الرمز 695).",
        "ERROR_CODE_900": "تم حجب الصفقة عقب عدم الالتزام بصفقة آمنة (الرمز 900).",
    },
    "_LEUMIPAY": {
        "payment_error": "الدفع بواسطة PAY. فشل!\nيرجى تكرار المحاولة",
        "min_order_amount_error": "للدفع بـ PAY يجب أن يتجاوز مبلغ بنود الطلبية 50 شيكل",
        "contrat": "للائحة PAY الداخلية..."
    },
    "SLIP": {
        "OTH": "OTH",
        "ITEM_DISCOUNT": "הנחת פריט",
        "TICKET_DISCOUNT": "خصم الحساب",
        "TICKET_OTH": "نتحمل كل شيء",
        "CHANGE": "إكرامية\\فائض"
    },
    "GROUP_VALIDATION": {
        "SELECT_ONE": "حددوا بندًا واحدًا",
        "SELECT_SOME": "حددوا {{min}} بنود",
        "SELECT_ATLEAST_ONE": "حددوا على الأقل بندًا واحدًا",
        "SELECT_ATLEAST_SOME": "حددوا على الأقل {{min}} بنود",
        "SELECT_UPTO_ONE": "حددوا ما يصل إلى بند واحد",
        "SELECT_UPTO_SOME": "حددوا ما يصل إلى {{max}} بنود",
        "SELECT_BETWEEN": "حددوا بين {{min}} و {{max}} بنود"
    },
    "ACCESS": {
        "ACCESSIBILITY": "لائحة طعام يسهل الوصول إليها",
        "ACCESSIBILITY_IN_PROGRESS": "الموقع يمر بعملية إتاحة الوصول السهل إليه",
        "CLEAR_ACCESSIBILITY_FEATURES": "امسح خيارات الوصول السهل",
        "monochrom": "مونوخروم",
        "light-contrast": "التباين المضيء",
        "dark-contrast": "التباين الداكن",
        "enlarge-font": "تكبير الخط",
        "reduce-font": "تصغير الخط",
        "readable-font": "الخط المقروء",
        "big-white-curser": "علامة كبيرة بيضاء",
        "big-black-curser": "علامة كبيرة سوداء",
        "enlarge-frame": "التكبير",
        "highlight-links": "إبراز الروابط",
        "highlight-headers": "إبراز العناوين",
        "image-info": "وصف للصور"
    },
    "ADDRESS_TYPES": {
        "apartment": "الشقة",
        "office": "المكتب",
        "house": "منزل خاص"
    },
    "OFFER_BADGES": {
        "new": "جديد",
        "sale": "حملة"
    },
    "_WEIGHT": {
        "units": "الوحدة",
        "price_per_weight": "الثمن لـ {{unit}} = {{price}}",
        "item_weight": "وزن البند",
        "kg": "كلغ (kg)",
        "gr": "غرام (gr)",
        "lb": "رطل (lb)",
        "oz": "للأونصة (oz)",
        "l": "لتر (l)",
        "ml": "ميليغرام (ml)",
        "GRAM": "غرام"
    },
    "_BENEFITS": {
        "BirthdayBenefit": "امتياز تاريخ الولادة",
        "AnniversaryBenefit": "امتياز تاريخ العرس",
        "JoinBenefit": "امتياز الانضمام",
        "RenewBenefit": "امتياز التجديد",
        "Vouchers": "القسيمة",
        "General": "الامتياز",
        "Other": "الامتياز",
        "PointsBenefit": "مراكمة النقاط"
    },
    "_LOYALTY": {
        "cancel_benefits": "إلغاء الخصومات",
        "card_balance": "بطاقة قابلة للشحن",
        "connect": "افحصوا لي",
        "continue": "المتابعة",
        "expires": "ساري المفعول حتى",
        "good_to_see_you_title": "يسرنا مشاهدتك",
        "member_not_found_title": "لم نعثر عليك",
        "comps_extra_text": "تمت إضافة التعويض للطلبية ويمكن الاستفادة منه.",
        "member_not_found": "لم نعثر عليك في قائمة أعضائنا",
        "member_not_found_PRIMARY_BUTTON_TEXT": "التأكيد",
        "member_expired": "العضوية منتهية الصلاحية، لا يمكن الاستفادة من الامتيازات",
        "of": "من",
        "points_balance": "رصيد {{points}}",
        "points_balance_header": "رصيد النقاط",
        "points_balance_cap": "لديك {{x}} {{points}} للاستفادة الفورية",
        "basket_compensation": "منها {{x}} شيكل تعويض مالي",
        "punch_card_offer": "تبقى {{from}} من {{to}}",
        "review_benefits": "ملخص الخصومات",
        "review_points": "الاستفادة من {{points}}",
        "signout": "سجل الخروج",
        "skip": "تخطَ",
        "cancel": "الإلغاء",
        "no_benefits_redeemed": "في هذه الصفقة لم تتم الاستفادة من الامتيازات!",
        "redeemed_benefits_title": "في هذه الصفقة ستتم الاستفادة من الامتيازات التالية",
        "total_benefits": "إجمالي الخصومات",
        "voucher_gtreeting": "مرحبًا، لديك قسيمة؟",
        "voucher_inline_gtreeting": "لديك قسيمة؟",
        "voucher_gtreeting_info": "تريد التحقق من الامتيازات؟\nربما تستحق شيئًا مربحًا",
        "voucher_placeholder": "رقم القسيمة",
        "voucher_member_greeting": "امتيازاتي",
        "voucher_benefits_title": "امتيازاتي",
        "voucher_signin_error": "لم يتم العثور على قسيمة مع هذا الرقم",
        "club_gtreeting": "تعالوا نتحقق من وجود امتيازات لدينا أو العضوية في النادي",
        "club_inline_gtreeting": "اطبعوا رمز القسيمة أو عضو النادي",
        "club_gtreeting_info": "تريد التحقق من الامتيازات؟\nربما تستحق شيئًا مربحًا",
        "club_placeholder": "رقم الهاتف/رمز القسيمة",
        "member_greeting": "مرحبًا {{name}}",
        "club_benefits_title": "امتيازاتي",
        "club_signin_error": "لم يتم العثور على عضو نادي أو قسيمة مع هذا الرقم",
        "signin_error_register": "لم يتم العثور على رقمك في قائمة أعضائنا<br/><br/><a target=_blank href=xxx>للانضمام إلى النادي</a>",
        "select_club": "حددوا النادي",
        "benefits_title": "امتيازاتي",
        "no_benefits_title": "يبدو أن ليست امتيازات متاحة حاليًا",
        "register": "الانضمام",
        "to_promotion_disclaimer": "الخصم النهائي سيتم حسابه قبل الدفع",
        "auth_title": "مطلوب رمز تحقق",
        "auth_sub_title": "رمز التحقق تم إرساله إلى الهاتف",
        "auth_code": "رمز التحقق",
        "auth_resend": "أرسلوا لي مجددًا",
        "benefit_types": "صالح لدى {{val}} فقط",
        "max_redeemable_points": "الحد الأقصى للاستفادة",
        "my_benefits": "امتيازاتي",
        "connect_info": "رقم الهاتف أو رقم البطاقة",
        "greeting": "مرحبًا {{name}}!",
        "greeting_voucher": "مرحبًا!",
        "balance": "الرصيد",
        "hey_greeting": "مرحبًا {{name}}!",
        "hey_greeting_pointsStore": "مرحبًا {{name}}, لديك {{points}} {{pointName}}",
        "hey": "مرحبًا!",
        "sum_to_use": "ما هو المبلغ الذي تريد استخدامه؟",
        "select_benefits_title": "مرحبًا {{name}}\nماذا تريده اليوم؟",
        "select_benefits": "تحرير الامتيازات",
        "select_benefits_max": "يمكن الاستفادة مما يصل إلى {{val}} امتيازات في الطلبية",
        "benefits_max_in_this_order": "أنت مدعو للاستمتاع بمزايا <b>{{val}}</b> من اختيارك",
        "benefits_max_in_this_order_single": "أنت مدعو للاستمتاع بالمزايا التالية:",
        "select_benefits_desc": "ينبغي التأكد من كون البنود المدرجة على الامتيازات في الطلبية.\nستتم الاستفادة من الامتيازات قبل الدفع",
        "selected_not_redeemed": "لم تتحقق شروط الحصول على الامتيازات التالية:",
        "selected_not_redeemed_desc": "يجب التأكد من محتوى الطلبية أو تحديد امتياز آخر",
        "CLUB_BENEFITS_ON_NEXT_STAGE": "سيتم حساب امتيازات النادي في المرحلة التالية",
        "GETTING_BENEFITS": "جاري حساب امتيازات النادي...",
        "POINTS_COMPS_PAY": "تعويض مالي",
        "POINTS_AND_COMPS_PAY": "وتعويض مالي",
        "POINTS_COMPS": "لديك تعويض مالي بمبلغ {{amount}} {{currency}} متاح للاستفادة منه 🎁",
        "POINTS_WAITING": "لديك {{amount}} {{caption}} يمكنك الاستفادة منه 🎁",
        "POINTS_AND_COMPS": "لديك {{points}} نقاط للاستفادة منها (منها {{amount}} {{currency}} تعويض مالي) 🎁",
        "voucher_entered": "تم إدخال قسيمة",
        "registration_terms": "التسجيل هو أيضًا موافقة على تلقي رسائل SMS أحيانًا بشأن الامتيازات والحملات المربحة. يمكن تسجيل الخروج من النشرة الإخبارية في كل لحظة",
        "regCostMessage": "الانضمام إلى النادي منوط بدفع {{currency}}{{price}} ستضاف إلى سلة البنود في طلبيتك",
        "confirmDeleteOfferJoin": "إلغاء الانضمام إلى النادي؟",
        "confirmDeleteOfferRenewal": "إلغاء تجديد العضوية في النادي؟",
        "confirmDeleteOfferJoinDescription": "إلغاء الانضمام إلى النادي سيمنع الحصول على الامتيازات",
        "confirmDeleteOfferRenewalDescription": "إلغاء تجديد النادي سيمنع الحصول على الامتيازات",
        "removeOfferJoinButton": "إلغاء الانضمام",
        "removeOfferRenewalButton": "إلغاء التجديد",
        "keepOfferJoinButton": "المتابعة من نفس المكان",
        "keepOfferRenewalButton": "المتابعة من نفس المكان",
        "regTitle": "أعضاء النادي يستفيدون أكثر!",
        "regDetailsDescription": "لم نعثر على رقم هاتفك {{phone}} في قائمة أعضائنا. لقد حان الوقت للانضمام والاستفادة من الامتيازات!",
        "regCancel": "الإلغاء",
        "continueReg": "الانضمام إلى النادي",
        "regRenewMessage": "تجديد النادي منوط بدفع {{currency}}{{price}}, والتي ستضاف إلى سلة البنود في طلبيتك",
        "regPromptTitle": "لم نعثر على الرقم {{phone}} في نادينا.\nلقد حان الوقت للانضمام!",
        "regPromptPrice": "الانضمام إلى النادي يكلف {{currency}}{{price}}",
        "regUpdateNessage": "يرجى التأكد من صحة التفاصيل التي تم إدخالها",
        "registerButton": "الانضمام",
        "continueWithoutRenewing": "المتابعة بدون تجديد",
        "continueWithoutReg": "المتابعة بدون انضمام",
        "renewMembership": "تجديد النادي",
        "phoneNumber": "رقم الجوال",
        "textForConfirmSms": "إنني أسمح للنادي بإرسال مواد ترويجية إليّ بين الفينة والأخرى من خلال الرسائل النصية. سأستطيع إزالة نفسي من النشرة الإخبارية في كل وقت.",
        "textForConfirmClubRules": "إنني أوافق على اللائحة الداخلية للنادي",
        "regRenewPromptPrice": "تجديد النادي منوط بدفع {{currency}}{{price}}",
        "regRenewTitle": "الوقت يطير عندما نستمتع بالطعام!",
        "regRenewBody": "لقد حان الوقت لتجديد العضوية في نادينا",
        "regSoonExpiredRenewBody": "صلاحية العضوية في النادي انتهت في {{date}}.\nمن أجل الاستمرار في الاستفادة من امتيازات النادي، يمكن تجديد العضوية الآن حتى!",
        "regSoonExpiredSignOut": "صلاحية العضوية في النادي انتهت في {{date}}.",
        "termsContinue": "إلى الأمام للامتيازات",
        "termsBody": "يبدو أنك لم تستكمل بعدُ التسجيل في النادي \n عدة تفاصيل أخيرة لتحصل على الامتيازات :)",
        "termsFirstContinue": "استكمال التفاصيل",
        "termsTitle": "مرحبًا {{name}}",
        "first_name": "الاسم الشخصي",
        "last_name": "اسم العائلة",
        "email": "عنوان البريد الإلكتروني",
        "zehut": "بطاقة الهوية",
        "phone": "رقم الجوال",
        "anniversary": "تاريخ العرس",
        "birth_date": "تاريخ الولادة",
        "regErrorTitle": "حدث خطأ ما",
        "regError": "تعذر استكمال عملية الانضمام، يرجى الاتصال بالمطعم",
    },
    "_GIFTCARD": {
        "title": "لديك بطاقة قابلة للشحن أو بطاقة هدية قابلة للاستفادة؟",
        "gift_card": "بطاقة هدية",
        "select_card": "حددوا البطاقة",
        "please_select_card": "يرجى تحديد البطاقة",
        "edit_card": "تحديث البطاقة",
        "add_card": "التأكيد",
        "number": "رقم البطاقة",
        "amount": "المبلغ للدفع",
        "balance": "الرصيد في البطاقة",
        "max": "الحد الأقصى للاستفادة في هذه الطلبية",
        "cvv": "CVV",
        "cvv_required": "يجب تعبئة CVV",
        "invalid_cvv": "رقم الـ CVV الذي تم إدخاله خاطئ",
        "inuse_error": "لا يمكن الاستفادة من نفس البطاقة أكثر من مرة واحدة في الطلبية",
        "balance_error": "لا يوجد هناك رصيد في البطاقة",
        "notfound_error": "تفاصيل البطاقة خاطئة أو البطاقة غير واردة على النظام",
        "delete_prompt": "إزالة البطاقة من الطلبية؟",
        "no_order_amount": "الدفعات تغطي الطلبية بالفعل، لا حاجة لدفع إضافي 😎",
        "reset_split_payments_prompt": "إضافة أو تحديث البطاقة سيبدأ فصل المدفوعات! المتابعة؟"
    },
    "_DELAYED": {
        "title": "توقيت الطلبية",
        "service_caption": "لتاريخ آخر",
        "supply_time": "تاريخ التزويد",
        "select_date": "يرجى تحديد تاريخ التزويد",
        "select_slot": "يرجى تحديد وقت التزويد",
        "service_today": "طلبية لليوم",
        "service_future": "طلبية مستقبلية",
        "for_hour": "للساعة {{val}}",
        "for_date": "اليوم {{val}}",
        "between": "بين الساعات",
        "at_hour": "عند الساعة",
        "select_slot_prompt": "ينبغي تحديد وقت التزويد",
        "service_disabled_today": "الخدمة غير متاحة حاليًا!"
    },
    "_OFFER_HISTORY": {
        "title": "كيفية تحضير الوجبة؟",
        "description": "لقد حددتها سابقًا",
        "continue": "حددوا التعديلات مجددًا",
        "select": "تحديد الوجبة"
    },
    "_EXTERNAL_DELIVERY": {
        "est_title": "Delivery Details",
        "est_apply": "Continue",
        "est_cancel": "Cancel",
        "delivery_time": "Estimated delivery time",
        "delivery_price": "Delivery price",
        "estimate_fail_message": "Delivery estimate failed",
        "validation_fail_message": "Delivery validation failed",
        "OUT_OF_DELIVERY_SERVICE_TIME": "Delivery is unavailable at this time",
        "ADDRESS_OUTSIDE_OF_DELIVERY_AREA": "We are sorry, delivery is not available for your address: {{address}}",
        "INVALID_PHONE": "Phone Number is invalid, please provide a valid phone number",
        "PHONE_NO_PSTN": "Phone Number is invalid, please provide a valid phone number",
        "ALCOHOL_NOT_DELIVERABLE": "Restaurant does not allow alcohol delivery. Remove alcohol from cart before proceeding to payment",
        "UNKNOWN_ERROR": "An error occured, please contact the restaurant"
    },
    "_EXTRA_INFO": {
        "delivery_ETA": "وقت الوصول حتى",
        "takeaway_ETA": "وقت التحضير حتى",
        "TAKEAWAY_FROM": "من أين يتم الاستلام؟",
        "min_order_price": "الحد الأدنى للإرسالية",
        "free_delivery_from": "الإرسال المجاني بدءًا بـ",
        "delivery_fee": "رسوم الإرسالية",
        "up_to": "حوالي",
        "asap_takeaway_ETA": "وقت التحضير",
        "asap_delivery_ETA": "وقت الوصول",
        "future_delivery_ETA": "توقيت الطلبية",
        "delayed_delivery_ETA": "الموعد المقدر",
    },
    "SIGN_IN_REGISTER": "تسجيل الدخول/التسجيل",
    "SIGN_IN_REGISTER_TABIT": "الاتصال بـ Tabit",
    "SIGN_UP_TABIT": "التسجيل لـ Tabit",
    "BASKET": {
        "INTERIM_AMOUNT": "المبلغ المرحلي",
        "TOTAL_ITEMS": "مبلغ البنود",
        "FREE_DELIVERY_FROM_PREFIX": "الإرسالية مجانية فوق",
        "FREE_DELIVERY_FROM_SUFFIX": "!",
        "MINIMUM_ORDER": "الحد الأدنى للطلبية {{price}}",
        "TO_FREE_DELIVERY": "المزيد {{amount}}",
        "FREE_DELIVERY": "الإرسال مجاني!",
        "CONGRATULATIONS": "التحيات, ",
        "DELIVERY_ON_US": "الإرسال على حسابنا!",
        "TAKEAWAY_FROM": "الاستلام من",
        "DELIVERY_TO": "الإرسال إلى",
        "EDIT_ORDER": "تحديث الطلبية",
        "DOES_NOT_INCLUDE_DELIVERY_FEE": "(لا يشمل رسوم الإرسال)",
        "DOES_NOT_INCLUDE_DELIVERY_FEE_AND_LOYALTY_ITEM": "(لا يشمل رسوم الإرسال {{item}})"
    },
    "BOTTOM_SHEET": {
        "pay_with_point": "الدفع بالنقاط",
        "regular_payment": "الدفع العادي",
    },
    "MENU_MESSAGES": "الرسائل",
    "YOUR_DELIVERY_ORDER_TO": "طلبيتك إلى",
    "YOUR_TA_ORDER_FROM": "طلبيتك من",
    "AMOUNT_LEFT": "تبقت {{amount}}",
    "ORDER_MINIMUM": "للحد الأدنى من الطلبية",
    "REMINDER_AMOUNT": "تبقى {{amount}} للدفع",
    "MY_ORDER": "طلبيتي",
    "ORDER_SENT_SUCCESSFULLY": "تم إرسال طلبيتك بنجاح!",
    "ORDER_SENT_INFO_TA": "طلبية الاستلام الذاتي رقم {{number}}, מ{{site}} ({{address}})",
    "ORDER_SENT_INFO_DELIVERY": "طلبية الإرسال رقم {{number}}, إلى العنوان {{address}}",
    "DYNAMIC_ORDER_SUCCESS_MESSAGE": "طلبية {{service}} رقم {{number}} تم استلامها بنجاح!",
    "DYNAMIC_ORDER_FAIL_MESSAGE": "الطلبية {{service}} رقم {{number}} تم إرسالها إلى المطعم مع خطأ دفع",
    "EATIN_SUCCESS_MESSAGE": "الطلبية رقم {{number}} تم إرسالها بنجاح!",
    "ORDER_WILL_BE_READY_AT": "ستكون جاهزة بحلول {{time}}.",
    "FUTURE_ORDER_WILL_BE_READY_AT": "ستكون جاهزة بحلول {{time}}",
    "FUTURE_ORDER_WILL_BE_READY": "تمت مزامنتها -{{time}}.",
    "ORDER_WILL_BE_DELIVERED_AT": "ستصل حتى {{time}}.",
    "FUTURE_ORDER_WILL_BE_DELIVERED_AT": "ستكون جاهزة حتى {{time}}",
    "ASAP_FUTURE_ORDER_WILL_BE_DELIVERED_AT": "تمت مزامنتها -{{time}}.",
    "ENJOY_YOUR_MEAL": "بالهناء والشفاء!",
    "SLIDE_TO_PAY": "انزلقوا للدفع",
    "JUST_PAY": "تأكيد الدفع",
    "SLIDE_TO_SUBMIT": "انزلقوا للإرسال",
    "NATIVE_PAYMENT": {
        "error_dialog_title": "لا يمكن معالجة طلبك حاليًا.",
        "error_dialog_description": "حدد وسيلة دفع أخرى",
        "error_dialog_no_amount_description": "للدفع من خلال {{type}} Pay ينبغي الضغط على X على سطر الدفع وتكرار المحاولة",
    },
};
