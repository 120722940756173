<div class="marketplace-card-box" [class.dashboard]="isDashboard" [class.floating-box]="site.floatingBox">
    <div class="marketplace-card-container">
        <!-- <div class="logo-container">
            <div class="card-img dark-theme" [style.background-image]="site?.Logo | safeStyle"></div>
        </div> -->
        <div class="img-container">
            <marketplace-image
                [imageAlt]="site?.RestaurantName" 
                class="card-img dark-theme" 
                [imageUrl]="siteImage">
            </marketplace-image>
        </div>
        <div class="card-body d-flex align-items-start">
            <div class="description-container">
                <div class="card-title flex-grow-1" *ngIf="site?.RestaurantName">
                    {{ site.RestaurantName }}
                </div>

                <div class="address-container">
                    <div class="card-address flex-grow-1" *ngIf="site?.BusinessAddress">
                        {{ site.BusinessAddress }}
                    </div>
                    <!-- <div class="card-phone flex-grow-1" *ngIf="site?.Phone">
                        {{ site.Phone }}
                    </div> -->
                </div>
            </div>
        </div>
        <div class="button-container">
            <button type="button" class="button" (click)="redirectToGiftCard()">
                {{ 'marketplace.button_text' | translate }}
            </button>
        </div>
        <div class="button-container underline">
            <button type="button" class="btn" (click)="openMoreInformation()">
                {{ 'marketplace.more_information' | translate }}
            </button>
        </div>
    </div>
</div>