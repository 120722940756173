import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Subject, merge, BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { omit, get } from 'lodash-es';
import { AppService, Notification } from '../app.service';
import { CordovaFireMessagingService } from './cordova-fire-messaging.service';

export interface UnifiedMessagePayload {
    data: {
        event: string;
        lifeCycleName: string;
        serviceType: 'takeaway' | 'delivery';
        deliveredAt: string;
        title: string;
        message: string;
        orgName: string;
    },
    notification: {
        title: string;
        body: string;
        image?: string;
    }
}

@Injectable({
    providedIn: 'root'
})

export class NotificationsService {

    private appConfig: any = environment.appConfig;
    private deviceIdSubject: BehaviorSubject<string> = new BehaviorSubject(null);
    public deviceId = this.deviceIdSubject.asObservable();
    private notificationWidgetPauseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public notificationWidgetPause = this.notificationWidgetPauseSubject.asObservable();
    private isOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isOpened = this.isOpenedSubject.asObservable();
    private internalNotification: Subject<Notification> = new Subject();
    public fireBaseRawNotification: any;
    public fireBaseNotification: Observable<Notification>;
    private isPublicVapidKeyUsed: boolean = false;

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
        private angularFireMessaging: AngularFireMessaging,
        private cordovaFireMessaging: CordovaFireMessagingService
    ) {
        this.fireBaseRawNotification = window['cordova'] ? this.cordovaFireMessaging.messages.pipe(map((cordovaPayload: any) => {
            console.debug('=== NOTIFICATIONS/SERVICE === Got firebase cordova payload:', cordovaPayload);
            let data = omit(cordovaPayload, ['aps', 'messageType']);
            return { data, notification: get(cordovaPayload, 'aps.alert', {}) };
        })) : this.angularFireMessaging.messages.pipe(tap((webPayload: any) => {
            console.debug('=== NOTIFICATIONS/SERVICE === Got firebase web payload:', webPayload);
        }) as any);

        this.fireBaseNotification = this.fireBaseRawNotification.pipe(map((unifiedMessagePayload: UnifiedMessagePayload) => {
            if (!unifiedMessagePayload.data) throw new Error('Firebase message malformed, problem with map');
            return {
                type: 'text',
                title: unifiedMessagePayload.data.title || get(unifiedMessagePayload, 'notification.title'),
                message: unifiedMessagePayload.data.message || get(unifiedMessagePayload, 'notification.body'),
                orgName: unifiedMessagePayload.data.orgName,
                date: new Date(),
            }
        }));

        merge(
            this.fireBaseNotification,
            this.internalNotification,
        ).subscribe((notification: Notification) => {
            console.debug('=== NOTIFICATIONS/SERVICE === Got New Notification:', notification);
            if (!this.isOpenedSubject.getValue() && !this.notificationWidgetPauseSubject.getValue()) this.setIsOpened(true);
            let newNotifications = this.appService.notificationsGetValue(true);
            newNotifications.push(notification);
            this.appService.setNotificationsSubject(newNotifications);
            //TODO - Add widget notifications number

        }, error => {
            console.error('Notifications service subscriber error:', { error });
        });

        // setInterval(() => this.sendInternalNotification({ title: 'order', message: 'fired' }), 15 * 1000);
    }

    public initWebFirebaseMessaging() {
        this.angularFireMessaging.requestToken.subscribe({
            next: (token) => {
                this.registerDeviceForNotifications(token);
                if (token) localStorage.setItem('fcmPermissionKey', token);
                this.appService.setIsPushNotificationsDisabled(false);
                this.isPublicVapidKeyUsed = true;
                console.debug('=== NOTIFICATIONS/SERVICE === Web firebase messaging initialized:', token);
            },
            error: (error) => {
                if (error.message.match(/permission-blocked/)) this.onWebDenyPushNotificationsPermissions();
                else this.onWebErrorEnablingPushNotifications(error);
            }
        });
    }

    public initCordovaFirebaseMessaging() {
        this.cordovaFireMessaging.requestToken.subscribe({
        // 14.05.2023 - Blocked this logic for now. it seems that isRemoteNotificationsEnabled return false even though the plugin succeded to register
        // We must check the device configuration, otherwise (for Android specificaly) FirebasePlugin generates token
        // even though the user blocked notifications
        // if (window['cordova']?.plugins?.diagnostic) {
        //     window['cordova'].plugins.diagnostic.isRemoteNotificationsEnabled((enabled: any) => {
        //         if (enabled) {
        //             this.cordovaFireMessaging.requestToken.subscribe((token) => {
        //                 this.registerDeviceForNotifications(token);
        //                 if (token) localStorage.setItem('fcmPermissionKey', token);
        //                 this.appService.setIsPushNotificationsDisabled(false);
        //                 console.debug('=== NOTIFICATIONS/SERVICE ===  Cordova firebase messaging initialized:', token);

        //             }, (error) => {
        //                 if (error.message.match(/permission-blocked/)) this.onCordovaDenyPushNotificationsPermissions();
        //                 else this.onCordovaErrorEnablingPushNotifications(error);
        //             });
        //         } else {
        //             this.onCordovaDenyPushNotificationsPermissions();
        //         }
        //     }, (error: any) => {
        //         console.debug('Notifications - ERROR: ', error);
        //         this.onCordovaErrorEnablingPushNotifications(error);
        //     });
        // } else {
        //     this.onCordovaDenyPushNotificationsPermissions();
        // }

            next: (token) => {
                this.registerDeviceForNotifications(token);
                if (token) localStorage.setItem('fcmPermissionKey', token);
                this.appService.setIsPushNotificationsDisabled(false);
                console.debug('=== NOTIFICATIONS/SERVICE === Cordova firebase messaging initialized:', token);
            },
            error: (error) => {
                if (error.message.match(/permission-blocked/)) this.onCordovaDenyPushNotificationsPermissions();
                else this.onCordovaErrorEnablingPushNotifications(error);
            }
        });
    }

    private onWebDenyPushNotificationsPermissions(): void {
        console.error('Firebase Web push notifications permission denied');
        this.appService.setIsPushNotificationsDisabled(true);
        this.deleteDeviceForNotifications();
    }

    private onWebErrorEnablingPushNotifications(error: Error): void {
        console.error('Error initializing web firebase messaging', { error });
        this.appService.setIsPushNotificationsDisabled(true);
        this.deleteDeviceForNotifications();
    }

    private onCordovaDenyPushNotificationsPermissions(): void {
        console.error('Firebase Cordova push notifications permission denied');
        this.appService.setIsPushNotificationsDisabled(true);
        this.deleteDeviceForNotifications();
    }

    private onCordovaErrorEnablingPushNotifications(error: Error): void {
        console.error('Error initializing cordova firebase messaging', { error });
        this.appService.setIsPushNotificationsDisabled(true);
        this.deleteDeviceForNotifications();
    }

    public registerDeviceForNotifications(fcmPermissionKey: string, loyaltyCustomer?: any) {
        if (!fcmPermissionKey) {
            const token = localStorage.getItem('fcmPermissionKey');
            if (token && token !== 'null') this.deleteDeviceForNotifications();
            throw new Error('Token is missing or corrupt for registering device');
        }

        let params = { fcmPermissionKey };
        if (loyaltyCustomer) params['customerId'] = loyaltyCustomer.CustomerId;

        return this.httpClient.post<{ device: any }>(`${this.appConfig.tabitBridge}/devices`, params, this.appService.appHttpOptions).subscribe(result => {
            if (result?.device?._id) {
                console.debug('=== NOTIFICATIONS/SERVICE === Device registered:', result.device._id);
                this.deviceIdSubject.next(result.device._id);
            }
        }, err => {
            console.error('Error registering device', err);
        });
    }

    public connectDeviceToOrders(deviceId: string, orderIds: string[]) {
        let url = `${this.appConfig.tabitBridge}/devices/${deviceId}/order-ids`;
        return this.httpClient.post<{ device: any }>(url, { orderIds }).subscribe(result => {
            if (result?.device?.orderIds) {
                console.debug('=== NOTIFICATIONS/SERVICE === Device Connected to order:', result.device.orderIds[result.device.orderIds.length - 1]);
            }
        }, err => {
            console.error('Error connecting device to orders', err);
        });
    }

    public deleteDeviceForNotifications() {
        const fcmPermissionKey = localStorage.getItem('fcmPermissionKey');
        if (!fcmPermissionKey) return;

        return this.httpClient.delete(`${this.appConfig.tabitBridge}/devices/${fcmPermissionKey}`, this.appService.appHttpOptions).subscribe((result: any) => {
            if (result?._id) {
                console.debug('=== NOTIFICATIONS/SERVICE === Device deleted:', result._id);
                this.deviceIdSubject.next(null);
            }
        }, err => {
            console.error('Error deleting device', err);
        });
    }

    public setIsOpened(opened: boolean) {
        this.isOpenedSubject.next(opened);
    }

    public sendInternalNotification(notification: Notification) {
        this.internalNotification.next(notification);
    }

    public deleteOneNotification(notification: any) {
        let notifications = this.appService.notificationsGetValue(false);
        let index = notifications.indexOf(notification);
        if (index >= 0) {
            notifications.splice(index, 1);
            if (!notifications.length) {
                this.setIsOpened(false); // Close the widget if the last notification deleted
            }
            this.appService.setNotificationsSubject(notifications);
        }
    }

    public setNotificationWidgetPause(pause: boolean) {
        this.notificationWidgetPauseSubject.next(pause);
    }

    public initFireBaseMessaging() {
        if (!window['cordova']) {
            if (this.appConfig.firebaseConfig?.projectId) {
                this.initWebFirebaseMessaging();
            } else {
                console.error('Firebase config missing from environment!');
            }
        } else {
            if (window['FirebasePlugin']) {
                this.initCordovaFirebaseMessaging();
            } else {
                console.error('Cordova firebase plugin doesn\'t present as a global');
            }
        }
    }

    public checkWidgetState() {
        return this.isOpenedSubject.getValue();
    }

    public get getDeviceId() {
        return this.deviceIdSubject.getValue();
    }
}
