import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppService } from '../../../app.service';
import { NotificationsService } from '../../../_core/notifications.service';
import { OrderTrackerService } from '../../../_core/order-tracker.service';

@Component({
    selector: 'app-order-tracker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app-order-tracker.component.html',
    styleUrls: ['./app-order-tracker.component.scss']
    })
export class AppOrderTrackerComponent implements OnInit, OnDestroy {

    public title;
    public displayModeSubscription: Subscription;

    constructor(
        public appService : AppService,
        public notificationsService : NotificationsService,
        public orderTrackerService: OrderTrackerService,
    ) { }

    ngOnInit() {
        this.displayModeSubscription = this.orderTrackerService.displayMode.subscribe(mode => {
            this.title = (['full', ''].includes(mode)) ? this.appService.translate('order_tracker.track_order') : this.appService.translate('order_tracker.my_order');
        });
    }

    goBack() {
        this.appService.redirect(['/home/dashboard']);
    }

    ngOnDestroy() {
        this.displayModeSubscription.unsubscribe();
    }
}
