import { NgModule } from '@angular/core';
import { AccessibleDirective } from './accessible/accessible.directive';
import { AnnouncerDirective } from './announcer/announcer.directive';
import { FormValidationAnnouncementService } from './form-validation-announcement/form-validation-announcement.service';

@NgModule({
    declarations: [AccessibleDirective, AnnouncerDirective],
    exports: [AccessibleDirective, AnnouncerDirective],
    providers: [FormValidationAnnouncementService],
    imports: [],
})

export class AccessabilitySupportModule { }
