import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
    selector: 'rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

    @Input() site;
    @Input() oneStar: boolean = false;

    constructor(
        public appService: AppService,
    ) { }

    ngOnInit() {
    }

}
