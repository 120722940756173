import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { BookService } from '../../_core/book.service';

@Component({
    selector: 'app-events',
    templateUrl: './app-events.component.html',
    styleUrls: ['./app-events.component.scss']
})
export class AppEventsComponent implements OnInit {

    constructor(
        public appService: AppService,
        private bookService: BookService,
    ) { }

    ngOnInit() {
        this.bookService.startWithoutSite();
    }

}
