<!-- header tabs labels -->
<div class="header-tabs-labels">

    <mat-icon class="right-hand-action" (click)="webContainerService.toggle(false)" *ngIf="!appService.isDesktop()">menu</mat-icon>

    <div class="header-tabs-items">
        <div class="header-tab-label header-logo" tabindex="-1" *ngIf="appService.isDesktop()" routerLink="/" >                         
            <img class="tab-image" accessible [src]="getMediaPath()" alt="{{ 'WEB_CONTAINER.start_logo' | translate }}" role="link"/>
        </div>
        <div class="header-tab-label" tabindex="-1" routerLink="/" *ngIf="!appService.isDesktop()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <h2 accessible>{{ 'WEB_CONTAINER.home' | translate }}</h2>
        </div>
        <!-- Corona updates - Online booking -->
        <div *ngIf="webHomeServicesDomainConfig?.book?.visible" tabindex="-1" class="header-tab-label booking" routerLink="{{appService.getTranslatedRoute('book-a-table')}}" routerLinkActive="active">
            <h2 accessible>{{ 'WEB_CONTAINER.book_a_table.tab_title' | translate }}</h2>
        </div>
        <div *ngIf="webHomeServicesDomainConfig?.delivery?.visible" tabindex="-1" class="header-tab-label" routerLink="{{appService.getTranslatedRoute('deliveries')}}" routerLinkActive="active">
            <h2 role="link" accessible [attr.aria-label]="'WEB_CONTAINER.deliveries.tab_title_screen_reader' | translate">{{ 'WEB_CONTAINER.deliveries.tab_title' | translate }}</h2>
        </div>
        <div class="header-tab-label restaruants" tabindex="-1" routerLink="{{appService.getTranslatedRoute('restaurants')}}" routerLinkActive="active">
            <h2 role="link" accessible>{{ 'WEB_CONTAINER.restaurants.tab_title' | translate }}</h2>
        </div>
        <div *ngIf="webHomeSectionsDomainConfig?.marketplace?.visible" class="header-tab-label" tabindex="-1" routerLink="/gift-it" routerLinkActive="active">
            <h2 role="link" accessible>{{ 'WEB_CONTAINER.marketplace.tab_title' | translate }}</h2>
        </div>

        <div class="header-tab-label two-rows" tabindex="-1" #myButton (click)="businessInviteRedirect()">
            <h3 role="link" accessible [attr.aria-label]="'business_invite_restaurant_owner_screen_reader' | translate">{{ 'business_invite_restaurant_owner_seo' | translate }} </h3>
            <!-- <h4>{{ 'business_invite_join_us' | translate }}</h4> -->   
        </div>

        <div *ngIf="!appService.isDesktop()" class="header-tab-label accessibility-label" (click)="appService.openAccessibilityDeclaration()">
            <h3>{{ 'ACCESS.ACCESSIBILITY_STATEMENT' | translate }}</h3>
            <div class="accessibility-logo">
                <mat-icon [svgIcon]="'accessibility-icon'"></mat-icon>
            </div>
            <!-- <h4>{{ 'business_invite_join_us' | translate }}</h4> -->
        </div>
    </div>

    <app-web-header-search [searchFieldOpen]="false" *ngIf="appService.isDesktop()" tabindex="-1"></app-web-header-search>

</div>

