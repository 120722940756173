<web-header class="home-web-header" webSearchType="home">
    <web-book-header webSearchType="home"></web-book-header>
</web-header>

<div>
    <div class="web-loading-container" *ngIf="loading && !newAtTabitOrgs.length">
        <mat-spinner diameter="60" strokeWidth="4" class="light-grey"></mat-spinner>
    </div>

    <web-tiles-container *ngIf="(!loading && marketplaceOrgs?.length) && webHomeSectionsDomainConfig?.marketplace?.visible" showMoreButton="true" [tilesContainerLink]="'/gift-it'" tilesContainerTitle="{{'WEB_CONTAINER.marketplace.home_page_section_title' | translate}}" screenReaderLabel="{{'WEB_CONTAINER.marketplace.home_page_section_title' | translate}}">
        <marketplace-card *ngFor="let org of (marketplaceOrgs | slice:0:3)" [site]="org"></marketplace-card>
    </web-tiles-container>

    <web-tiles-container *ngIf="webHomeSectionsDomainConfig?.events?.visible && sitesEvents?.length" [showMoreButton]="true" class="main-events" [tilesContainerLink]="appService.redirectValueByLocale('events', 'ROUTE')" tilesContainerTitle="{{'events_and_benefits' | translate}}">
        <event-card *ngFor="let siteEvent of sitesEvents; let i = index;" [index]="i" [siteEvent]="siteEvent" [tags]="occasionTags"></event-card>
    </web-tiles-container>

    <web-tiles-container *ngIf="!loading && shuffledHomeOrderOrgs.length && webHomeSectionsDomainConfig?.homeOrderOrgs?.visible" showMoreButton="true" [tilesContainerLink]="appService.redirectValueByLocale('deliveries', 'ROUTE')" tilesContainerTitle="{{'order_subtitle' | translate}}">
        <site-card *ngFor="let org of (shuffledHomeOrderOrgs | slice:0:(appService.isDesktop() ? 6 : 3))" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </web-tiles-container>

    <web-article-preview *ngIf="webHomeSectionsDomainConfig?.articles?.visible" articleLink="/article/גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית" class="main-web-article-preview" articleId="גרים-בצפון?-הכירו-את-המסעדות-שמגיעות-אליכם-עד-הבית"></web-article-preview>

    <web-tiles-container *ngIf="(!loading && newAtTabitOrgs.length) && webHomeSectionsDomainConfig?.newAtTabitOrgs?.visible" showMoreButton="true" class="main-new-at-tabit" [tilesContainerLink]="appService.redirectValueByLocale('new-at-tabit', 'ROUTE')" tilesContainerTitle="{{ 'WEB_CONTAINER.book_a_table.new_restaurants_in_tabit' | translate}}" screenReaderLabel="{{'WEB_CONTAINER.book_a_table.new_restaurants_in_tabit_screen_reader' | translate }}">
        <site-card *ngFor="let org of (newAtTabitOrgs | orderBy: 'liveAt': true | slice:0:3)" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </web-tiles-container>

    <web-tiles-container *ngIf="webHomeSectionsDomainConfig?.foodTags?.visible" class="main-kitchen-categories" [ngClass]="{mobile: !appService.isDesktop()}" tilesContainerType="main-kitchen-categories" [tilesContainerLink]="appService.redirectValueByLocale('restaurants', 'ROUTE')" tilesContainerTitle="{{'WEB_CONTAINER.book_a_table.kitchens' | translate}}">
        <food-category-item [ngClass]="{mobile: !appService.isDesktop()}" *ngFor="let tag of foodTags" [tagId]="tag._id" web="true" [customTag]="tag" accessible></food-category-item>
    </web-tiles-container>

    <web-tiles-container *ngIf="webHomeSectionsDomainConfig?.mainAreas?.visible" class="main-areas" [class.mobile]="!appService.isDesktop()" tilesContainerType="main-areas" [tilesContainerLink]="appService.redirectValueByLocale('restaurants', 'ROUTE')" tilesContainerTitle="{{'WEB_CONTAINER.book_a_table.restaurants_by_areas' | translate}}">
        <div class="area-slot" *ngFor="let area of (mapAreas| slice:0:6); let i = index" [ngStyle]="{'background-image': 'url(assets/images/areas/'+ area.key +'.png)'}" (click)="areaClick(area)" accessible>
            <span>{{ ('areas.' + area.key) | translate}}</span>
        </div>
    </web-tiles-container>

    <web-tiles-container *ngIf="(!loading && nearbyTabitOrgs.length)" showMoreButton="true" class="main-near-me" [tilesContainerLink]="appService.redirectValueByLocale('nearest-restaurants', 'ROUTE')" tilesContainerTitle="{{'WEB_CONTAINER.book_a_table.near_me' | translate}}">
        <site-card *ngFor="let org of (nearbyTabitOrgs | slice:0:6)" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </web-tiles-container>

    <web-footer></web-footer>
</div>