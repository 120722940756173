import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

import { AppService } from '../app.service';
import { AuthService } from '../_core/auth.service';
import { EntityUtilsService } from '../_core/entity.utils.service';
import { EntityService } from '../_core/entity.service';
import { DialogsService } from '../_core/dialogs.service';
import { LocationService, LocationLabeled } from '../_core/location.service';
import { OrganizationsService } from '../_core/organizations.service';
import { ORGANIZATION_BUCKET_TYPES } from '../_core/OrganizationBucket';
import { StyleService } from '../_core/style.service';

import { keyBy, values } from 'lodash-es';

@Component({
    selector: 'app-near-me',
    templateUrl: './near-me.component.html',
    styleUrls: ['./near-me.component.scss'],
    host: {
        'class': 'host-default',
    },
    animations: [
        trigger('focusSite', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('0.15s', style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('0.15s', style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
        trigger('animateSwitchSites', [
            state('true', style({ transform: 'scale(1)' })),
            state('false', style({ transform: 'scale(1)' })),
            transition('false <=> true', animate('0.15s', keyframes([
                style({ transform: 'scale(1)' }),
                style({ transform: 'scale(0.8)' }),
                style({ transform: 'scale(1)' }),
            ]))),
        ])
    ]
})
export class NearMeComponent implements OnInit, OnDestroy {

    moduleBarElement: HTMLElement;
    centerMapBtn: HTMLElement;

    parametersObservable: any;
    orgs: any[] = [];
    currentLocation: any = {};
    map: any;
    searchMoreTimeout: any = null;
    queryParams: any = {};

    orgsSubscription: Subscription = null;

    startCounter: number = 0
    sitesCounter: number = 10;
    initialZoomLevel: number = 12.9;
    previousZoomLevel: number = null;
    currentZoomLevel: number = this.initialZoomLevel;

    showScrollUp: boolean = false;
    siteAnimationSwitch: boolean = true;
    sitesLoaded: boolean = false;
    loadOrgsAfterBounds: boolean = true;
    darkMode: boolean = false;
    loaded: boolean = false;

    darkModeMapBackgroundColor: string = '#242F3E';
    lightModeMapBackgroundColor: any;
    siteIdInFocus: String = null;

    iconUrlMarkerCurrent: String = "";

    mapStyleDark: google.maps.MapTypeStyle[] = [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": this.darkModeMapBackgroundColor
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": this.darkModeMapBackgroundColor
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#263c3f"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6b9a76"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#38414e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#212a37"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9ca5b3"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#1f2835"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#f3d19c"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2f3948"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#515c6d"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        }
      ];

    constructor(
        public appService: AppService,
        public authService: AuthService,
        public entityService: EntityService,
        public utilsService: EntityUtilsService,
        public organizationsService: OrganizationsService,
        public dialogsService: DialogsService,
        public locationService: LocationService,
        public styleService: StyleService,
        public route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.lightModeMapBackgroundColor = { url: this.appService.images.marker_current, scaledSize: { height: 50, width: 50 } };

        combineLatest([
            this.appService.subscribedToLocationAndGotOrganizations,
            this.route.queryParams,
        ]).subscribe(([subscribed, queryParams]) => {
             if (!subscribed) {
                this.entityService.subscribeToCoreData()
                .then(() => this.subscribeToOrgs());
            }
            this.queryParams = queryParams;
        });

        this.moduleBarElement = document.getElementById("nearme-module-bar");
        this.centerMapBtn = document.getElementById("btn-center-map");

        this.locationService.location.subscribe((newLocation: LocationLabeled) => {
            this.currentLocation = newLocation.location;
        });

        if (!this.sitesLoaded) this.subscribeToOrgs();
    }

    ngOnDestroy() {
        this.orgsSubscription.unsubscribe();
        const bucketTypes: any = ORGANIZATION_BUCKET_TYPES || {};
        if (this.queryParams) this.organizationsService.clearSearch(bucketTypes.searchOnMap);
    }

    subscribeToOrgs(): void {
      const bucketTypes: any = ORGANIZATION_BUCKET_TYPES || {};
      this.orgsSubscription = this.organizationsService.data[bucketTypes.searchOnMap].pipe(
          map((orgs: any[]) => orgs.filter(org => org.location?.lat && org.location?.lng))
      ).subscribe({
          next: (organizations: any[]) => {
              // To prevent the flick on the markers, we need to mutate them...
              const orgs = keyBy(this.orgs, '_id');
              organizations.forEach(org => {
                  if (orgs[org._id]) {
                      Object.assign(orgs[org._id], org);
                  } else {
                      orgs[org._id] = org;
                  }
              });
              this.orgs = values(orgs);
              this.sitesLoaded = true;
          },
          error: (err: any) => {
              this.sitesLoaded = false;
              console.error('Error with incoming search organizations:', err);
          }
      });
  }

    searchWithinMapBounds(bounds?: google.maps.LatLngBounds) {
        const bucketTypes: any = ORGANIZATION_BUCKET_TYPES || {};
        const mapSearchType = bucketTypes.searchOnMap;

        if (!bounds) bounds = this.map.getBounds();
        if (!bounds) return;

        const searchDetails = {
            ...(this.organizationsService.getSearchDetails(mapSearchType) || {}),
            bounds: JSON.stringify(bounds.toJSON()),
            ...this.queryParams,
        };

        return this.organizationsService.search(searchDetails, mapSearchType).subscribe();
    }

    centerMap() {
        if (this.map) {
            this.appService.scrollToTop('#nearme-content');
            window.setTimeout(() => {
                const point = this.currentLocation;
                this.map.setCenter({ lat: point.lat, lng: point.lng });
            }, 100);
        }
    }

    siteIcon(site) {
      return {
        url: this.siteIdInFocus === site._id ? this.appService.images.marker_dark : this.appService.images.marker_light,
        scaledSize: new google.maps.Size(32, 42) 
      };
    }

    clickedMarker(site) {
        if (this.siteIdInFocus) this.siteAnimationSwitch = !this.siteAnimationSwitch;
        this.siteIdInFocus = site;
    }

    onMapReady(map) {
        this.map = map;
        let oldBounds = this.organizationsService.searchedMapBounds;

        // If there are oldBounds, return there and don't load org for now.
        // TODO: This behaviour has to change, when we will discard temp orgs
        // There are few prophets who foretold that it will never happen.
        if (oldBounds) {

            this.loadOrgsAfterBounds = false;
            this.map.fitBounds(oldBounds);
            this.organizationsService.searchedMapBounds = oldBounds;

        }

        // There is no need to trigger an organizations load here,
        // Because we are assuming that the will be always a boundsChange event, which triggers it.
        // TODO: This is: Not looking very stable + adding a delay of the debounce until the markers appears

    }

    searchWithinMapBoundsWithDebounce(bounds: google.maps.LatLngBounds) {
        if (this.searchMoreTimeout) window.clearTimeout(this.searchMoreTimeout);
        this.searchMoreTimeout = setTimeout(() => {

            // console.log('Map bounds changed, debounced:', bounds);

            if (this.isNewBoundsIn(this.organizationsService.searchedMapBounds, bounds)) this.loadOrgsAfterBounds = false;
            if (this.loadOrgsAfterBounds) this.searchWithinMapBounds(bounds);
            this.loadOrgsAfterBounds = true;
            this.organizationsService.searchedMapBounds = bounds;

        }, 600);
    }

    onMapClick() {
        this.siteIdInFocus = null;
    }

    // We need to use onBoundsChanged because if we use only the onZoomChange, then we the user only moves the map (without zoom) - the markers don't get updtated.
    onBoundsChanged(bounds: google.maps.LatLngBounds) {
        this.searchWithinMapBoundsWithDebounce(bounds);
    }

    navigateBack() {
        this.appService.goBack();
    }

    isNewBoundsIn(oldBounds: google.maps.LatLngBounds, newBounds: google.maps.LatLngBounds): boolean {
        if (!oldBounds || !newBounds) return false;
        if (oldBounds.equals(newBounds)) return true;
        if (oldBounds.contains(newBounds.getNorthEast()) && oldBounds.contains(newBounds.getSouthWest())) {
            // console.log('Bound are in, not requesting...');//, oldBounds.toJSON(), newBounds.toJSON());
            return true;
        }
        return false;
    };

    siteClick(siteId: any) {
        let site = this.organizationsService.getOrganization(siteId);
        if (!site) return console.error('No site found at loaded organizations:', siteId);
        this.organizationsService.searchScreenNeedsScroll = true;
        this.appService.redirect(['/app-site', site.seo[this.appService.appConfig.locale.toLocaleLowerCase()].urlIdentifier]);
    }
}
