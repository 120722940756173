import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { EntityUtilsService } from '../../_core/entity.utils.service';

@Component({
    selector: 'site-info-tabs',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './site-info-tabs.component.html',
    styleUrls: ['./site-info-tabs.component.scss']
})
export class SiteInfoTabsComponent implements OnInit {

    appConfig: any = environment.appConfig;

    @Input() site: any;
    @Input() sitePhotos: any[] = [];
    @Output() photoClicked: EventEmitter<number> = new EventEmitter();

    public siteDetailsPage;
    public trustedIframeUrl: SafeResourceUrl;

    constructor(
        public utilsService: EntityUtilsService,
        public appService: AppService,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {
        this.setAboutData();
    }

    setAboutData() {
        this.siteDetailsPage = this.site.siteDetailsPage;
        if (this.siteDetailsPage?.videoLink) {
            try {
                this.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.siteDetailsPage.videoLink);
            } catch (err) {
                // Not a proper url probably
                console.debug(err);
            }
        }
    }

}
