<div class="card card-site mr-2 wl-cards-background-color">
    <div class="card-img-top dark-theme" [style.background-image]="setImage() | safeStyle">
    </div>
    <div class="card-body d-flex align-items-start">
        <div class="flex-grow-1 text-truncate">
            <div class="card-title text-truncate">{{ getTitle() }}</div>
        </div>
    </div>
    <!-- <div class="card-footer d-flex align-items-start">
        <div class="card-address flex-grow-1 text-truncate">{{'_GIFTCARD.gift_card' | translate}}</div>
    </div> -->
</div>