import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { environment } from '../environments/environment';

import { SignInComponent } from './sign-in/sign-in.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SitesOrderTaComponent } from './sites-order-ta/sites-order-ta.component';
import { SitesSearchComponent } from './sites-search/sites-search.component';
import { SitesSearchPayComponent } from './sites-search-pay/sites-search-pay.component';
import { NearMeComponent } from './near-me/near-me.component';
import { AppSiteDetailsComponent } from './app-site-detail/app-site-details.component';
import { WebSiteDetailsComponent } from './web-site-details/web-site-details.component';

import { SitesPayComponent } from './order/sites-pay/sites-pay.component';
import { QRScannerComponent } from './order/qr-scanner/qr-scanner.component';
import { TestScannerComponent } from './order/test-scanner/test-scanner.component';
import { TabitPayComponent } from './order/tabit-pay/tabit-pay.component';

import { QuickPayComponent } from './order/quick-pay/quick-pay.component';

import { MyProfileComponent } from './profile/my-profile/my-profile.component';
import { MyAccountComponent } from './profile/my-account/my-account.component';
import { MyWalletComponent } from './profile/my-wallet/my-wallet.component';
import { MyAddressesComponent } from './profile/my-addresses/my-addresses.component';
import { MyPreferencesComponent } from './profile/my-preferences/my-preferences.component';
import { MyRankComponent } from './profile/my-rank/my-rank.component';
import { MyHistoryComponent } from './profile/my-history/my-history.component';
import { MyBenefitsComponent } from './profile/my-benefits/my-benefits.component';
import { RemoveAccountComponent } from './profile/remove-account/remove-account.component';

import { TabitBookComponent } from './tabit-book/tabit-book.component';
import { TabitbookFromSitesComponent } from './tabit-book/tabitbook-from-sites/tabitbook-from-sites.component';
import { TabitbookChooseSiteComponent } from './tabit-book/tabitbook-from-sites/tabitbook-choose-site/tabitbook-choose-site.component';

import { WebContainerComponent } from './web-container/web-container.component';
import { WebHomeComponent } from './web-container/web-home/web-home.component';
import { WebBookingResultsComponent } from './web-container/web-booking-results/web-booking-results.component';
import { WebDeliveryComponent } from './web-container/web-delivery/web-delivery.component';
import { WebSitesComponent } from './web-container/web-sites/web-sites.component';

import { FoodCategoriesComponent } from './food-categories/food-categories.component';
import { NewRestaurantsComponent } from './new-restaurants/new-restaurants.component';

import { AuthGuardService as AuthGuard } from './_core/auth-guard.service';
import { AnonymousAuthGuardService as AnonymousAuthGuard } from './_core/anonymous-auth-guard.service';

import { WebArticleComponent } from './web-container/web-article/web-article.component';
import { WebTilesNewRestaurantsComponent } from './web-container/web-tiles-new-restaurants/web-tiles-new-restaurants.component';
import { WebTilesNearMeComponent } from './web-container/web-tiles-near-me/web-tiles-near-me.component';
import { WebUserReviewComponent } from './user-reviews/web-user-review/web-user-review.component';
import { AppUserReviewComponent } from './user-reviews/app-user-review/app-user-review.component';
import { WebOrderTrackerComponent } from './components/order-tracker/web-order-tracker/web-order-tracker.component';
import { AppOrderTrackerComponent } from './components/order-tracker/app-order-tracker/app-order-tracker.component';
import { AppEventsComponent } from './events/app-events/app-events.component';
import { WebEventsComponent } from './web-container/web-events/web-events.component';
import { WebMarketplaceComponent } from './web-container/web-marketplace/web-marketplace.component';

import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { ShortCodeComponent } from './loyalty/short-code/short-code.component';
import { OutOfUseComponent } from './out-of-use/out-of-use.component';
import { AppMarketplaceComponent } from './marketplace/app-marketplace/app-marketplace.component';

const routes: Routes = [

    // =========================================================================================
    // Tabit web app routes
    // NOTICE - We have duplicate routes (for hard-coded hebrew routes) to support ==>
    // multi-language environments
    // =========================================================================================
    {
        path: '', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebHomeComponent,
            },
        ]
    },
    {
        path: 'הזמן-מקום', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebBookingResultsComponent,
            },
        ]
    },
    {
        path: 'book-a-table', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebBookingResultsComponent,
            },
        ]
    },
    {
        path: 'משלוחים', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebDeliveryComponent,
            },
        ]
    },
    {
        path: 'deliveries', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebDeliveryComponent,
            },
        ]
    },
    {
        path: 'מסעדות', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true },
        children: [
            {
                path: '',
                component: WebSitesComponent,
            },
        ]
    },
    {
        path: 'restaurants', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true },
        children: [
            {
                path: '',
                component: WebSitesComponent,
            },
        ]
    },
    {
        path: 'gift-it', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBarOnlyInWeb: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebMarketplaceComponent,
            },
        ]
    },
    {
        path: 'מסעדות-חדשות-טאביט', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebTilesNewRestaurantsComponent,
            }
        ]
    },
    {
        path: 'new-at-tabit', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebTilesNewRestaurantsComponent,
            }
        ]
    },
    {
        path: 'מסעדות-קרובות-אלי', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebTilesNearMeComponent,
            }
        ]
    },
    {
        path: 'nearest-restaurants', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebTilesNearMeComponent,
            }
        ]
    },
    // WebSiteDetails //
    {
        path: 'site/:id', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, webBackButton: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebSiteDetailsComponent,
                data: { animationTag: 'siteDetails' }
            },
        ]
    },
    // WebSiteDetails END //
    {
        path: 'article/:articleId', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, webBackButton: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebArticleComponent,
                data: { animationTag: 'siteDetails' }
            },
        ]
    },
    {
        path: 'web-user-review', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, webBackButton: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebUserReviewComponent,
                data: { animationTag: 'siteDetails' }
            },
        ]
    },
    {
        path: 'web-order-tracker', component: WebOrderTrackerComponent, data: { hideNavBar: true },
    },
    {
        path: 'under-construction', component: UnderConstructionComponent, data: { hideNavBar: true },
    },
    {
        path: 'אירועים', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, webBackButton: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebEventsComponent,
                data: { animationTag: 'siteDetails' }
            },
        ]
    },
    {
        path: 'events', component: WebContainerComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, webBackButton: true, navigateToSitesOnSearch: true },
        children: [
            {
                path: '',
                component: WebEventsComponent,
                data: { animationTag: 'siteDetails' }
            },
        ]
    },

    {
        path: 'sign-in', component: SignInComponent, data: { hideNavBar: true, animationTag: 'signIn', handlesAndroidBack: true }
    },

    {
        path: 'app-site/:id', component: AppSiteDetailsComponent, data: { animationTag: 'siteDetails', hideNavBar: false, handlesAndroidBack: true }
    },

    {
        path: 'home', redirectTo: '/home/dashboard', pathMatch: 'full', data: { showNoLocationIndicator: true, showNoNotificationsIndicator: true, animationTag: 'dashboard' }
    },

    {
        path: 'home/near-me', component: NearMeComponent,
    },

    {
        path: 'app-user-review', component: AppUserReviewComponent, data: { hideNavBar: false }
    },

    {
        path: 'app-order-tracker', component: AppOrderTrackerComponent, data: { hideNavBar: false }
    },

    // =========================================================================================
    // Search sites interfaces:
    // =========================================================================================
    {
        path: 'home/favorites', component: SitesSearchComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order', component: SitesOrderTaComponent, data: { handlesAndroidBack: true }
    },
    {
        path: 'home/sites', component: SitesSearchComponent,
    },
    {
        path: 'sites/pay', component: SitesSearchPayComponent,
    },
    // =========================================================================================

    {
        path: 'home/tags', component: FoodCategoriesComponent, canActivate: [AuthGuard]
    },

    {
        path: 'home/new-at-tabit', component: NewRestaurantsComponent,
    },

    {
        path: 'home/dashboard', component: DashboardComponent, data: { showNoLocationIndicator: true, showNoNotificationsIndicator: true, animationTag: 'dashboard' }
    },

    {
        path: 'book', component: TabitbookFromSitesComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },

    {
        path: 'book/results', component: TabitbookChooseSiteComponent, canActivate: [AuthGuard]
    },

    {
        path: 'tabit-book', component: TabitBookComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'app-events', component: AppEventsComponent,
    },
    {
        path: 'app-gift-it', component: AppMarketplaceComponent,
    },

    // =========================================================================================
    // Tabit Pay paths:
    // =========================================================================================
    {
        path: 'pay', redirectTo: '/pay/scanner', pathMatch: 'full'
    },
    {
        path: 'pay/scanner', component: QRScannerComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true, hideNavBar: window.innerHeight <= 600 ? true : false } // 2020-01-05: Showing NavBar per Idan's request; But on small screens, we must hide it (otherwise it will overlap the buttons there)
    },
    // Test Scanner
    {
        path: 'test-scanner', component: TestScannerComponent, data: { handlesAndroidBack: true, hideNavBar: window.innerHeight <= 600 ? true : false } // 2020-01-05: Showing NavBar per Idan's request; But on small screens, we must hide it (otherwise it will overlap the buttons there)
    },
    {
        path: 'pay/sites', component: SitesPayComponent, canActivate: [AuthGuard]
    },
    {
        path: 'pay/quick-pay', component: QuickPayComponent, canActivate: [AuthGuard]
    },
    {
        path: 'pay/tabit-pay', component: TabitPayComponent, data: { handlesAndroidBack: true }
    },
    {
        path: 'short-code', component: ShortCodeComponent, canActivate: [AuthGuard]
    },

    // =========================================================================================
    // Tabit Profile paths:
    // =========================================================================================
    {
        path: 'profile', redirectTo: '/profile/my-profile', pathMatch: 'full', data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-profile', component: MyProfileComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-account', component: MyAccountComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-wallet', component: MyWalletComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-addresses', component: MyAddressesComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-preferences', component: MyPreferencesComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-history', component: MyHistoryComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/my-rank', component: MyRankComponent, canActivate: [AuthGuard]
    },
    {
        path: 'profile/my-benefits', component: MyBenefitsComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
    {
        path: 'profile/remove-account', component: RemoveAccountComponent, canActivate: [AuthGuard], data: { handlesAndroidBack: true }
    },
        // =========================================================================================
    // Out of use path:
    // =========================================================================================
    {
        path: 'out-of-use', component: OutOfUseComponent, data: { hideNoLocationIndicator: true, hideNavBar: true, handlesAndroidBack: false },
    },
    // =========================================================================================
    // Tabit Order paths:
    // =========================================================================================
    {   // We must let anonymous users the option to enter TO without login at WEB
        // path: 'tabit-order', canActivate: window['cordova'] ? [AuthGuard] : [AnonymousAuthGuard], data: { handlesAndroidBack: true, hideNavBar: true }, loadChildren: () => import('./tabit-order/tabit-order.module').then(m => m.TabitOrderModule)
        path: 'tabit-order', canActivate: [AnonymousAuthGuard], data: { handlesAndroidBack: true, hideNavBar: true }, loadChildren: () => import('./tabit-order/tabit-order.module').then(m => m.TabitOrderModule)
    },

    // =========================================================================================
    // =========================================================================================
    // Online Reservations paths:
    // =========================================================================================
    {
        path: 'online-reservations',
        data: { handlesAndroidBack: true },
        canActivate: [AnonymousAuthGuard],
        loadChildren: () => import('./online-reservations/online-reservations.module').then(m => m.OnlineReservationsModule)
    },
    {
        path: 'הזמנת-מקום',
        data: { handlesAndroidBack: true },
        canActivate: [AnonymousAuthGuard],
        loadChildren: () => import('./online-reservations/online-reservations.module').then(m => m.OnlineReservationsModule)
    },

    // =========================================================================================
    // Gift card shop paths:
    // =========================================================================================
    { path: 'gift-cards', loadChildren: () => import('./gift-cards/gift-cards.module').then(m => m.GiftCardsModule) },

];

@NgModule({
    // 2019-11-27 - Can't use an expression to define the useHash TRUE for Cordova and FALSE for Web. So we use TRUE for now.
    imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: environment.appConfig.useHash
})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
