import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, throwError } from 'rxjs';
import { tap, timeout, retry, map } from 'rxjs/operators';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { extend, isObject } from 'lodash-es';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { LoyaltyService } from '../_core/loyalty.service';

import moment from 'moment';

@Injectable()
export class AuthService {

	private tokenReceivedEvent = new EventEmitter();
	public tokenReceived$ = this.tokenReceivedEvent.asObservable();

	// Refresh Token Subject tracks the current token, or is null if no token is currently available (e.g. refresh pending).
	public tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	public appConfig: any = environment.appConfig;
	public domainSubscription: Subscription;
	public domain: any;

	public refreshTokenInProgress: boolean = false;

	constructor(
		private http: HttpClient,
		private appService: AppService,
		private loyaltyService: LoyaltyService,
	) {
		this.domainSubscription = this.appService.domain.subscribe((domain: any) => {
			this.domain = domain;
			if (domain?.clubIds?.length) {
				this.loyaltyService.setLoyaltyHeaders('accountGuid', domain.clubIds[0]);
				console.debug('auth.service - App Service loyaltyHttpOptions > accountGuid', this.loyaltyService.getLoyaltyHeaderValue('accountGuid'));
			}
		});
	}

	getHeadersAsObj() {
		let headers = {};
		this.appService.appHttpOptions.headers.keys().forEach(key => headers[key] = this.appService.appHttpOptions.headers.get(key));
		return headers;
	}

	public isAuthenticated(): boolean {
		const token = localStorage.getItem('token');
		const loyaltyToken = localStorage.getItem('loyaltyToken');
		return token != null && token != '' && loyaltyToken != null && loyaltyToken != '';
	}

	public checkToken(): boolean {
		let loyaltyToken = localStorage.getItem('loyaltyToken');
		let loyaltyRefreshToken = localStorage.getItem('loyaltyRefreshToken');

		let token = localStorage.getItem('token');
		let refreshToken = localStorage.getItem('refreshToken');

		if (loyaltyToken && loyaltyRefreshToken && token && refreshToken) {
			console.debug('appService.checkToken - Loyalty Access Token exists: ', loyaltyToken);
			console.debug('appService.checkToken - Loyalty Refresh Token exists: ', loyaltyRefreshToken);
			console.debug('appService.checkToken - Access Token exists: ', token);
			console.debug('appService.checkToken - Refresh Token exists: ', refreshToken);
			this.setHttpHeadersLoyaltyAccessToken(loyaltyToken, loyaltyRefreshToken);
			this.setHttpHeadersAccessToken(token, refreshToken);
			return true;
		} else {
			return false;
		}
	}

	public setHttpHeadersAccessToken(token: string, refreshToken?: string): void {
		this.setReceivedTokenEvent(token);
		this.appService.appHttpOptions.headers = this.appService.appHttpOptions.headers.set('Authorization', `Bearer ${token}`);
		console.debug('authService.appHttpOptions.headers - Authorization', this.appService.appHttpOptions.headers.get('Authorization'));

		// Storing the token in the local storage
        if (localStorage) {
            localStorage.setItem('token', token);
            // Storing the refresh token in the local storage
            if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
        }
	}

	public setHttpHeadersLoyaltyAccessToken(token: string, refreshToken: string): void {
		this.loyaltyService.setLoyaltyHeaders('Authorization', `Bearer ${token}`);
		console.debug('authService.loyaltyHttpOptions.headers - Authorization', this.loyaltyService.getLoyaltyHeaderValue('Authorization'));

        if (localStorage) {
            // Storing the token in the local storage
            localStorage.setItem('loyaltyToken', token);
    
            // Storing the refresh token in the local storage
            localStorage.setItem('loyaltyRefreshToken', refreshToken);
        }
	}

	public refreshLoyaltyToken$(): Observable<any> {
        const accessToken = localStorage.getItem('loyaltyToken');
        const refreshToken = localStorage.getItem('loyaltyRefreshToken');

        if (!accessToken || !refreshToken) throw throwError('No token provided for refresh/renew operation');

		return this.http.post<{}>(`${this.appConfig.tabitLoyaltyAPI}/auth/renew`, {
			accessToken,
			refreshToken,
		}, this.loyaltyService.getLoyaltyHeaders())
			.pipe(
				timeout(30000),
				retry(3),
				tap(response => console.debug('Refresh Loyalty Token Response:', response))
			);
	}

	public refreshToken$(): Observable<any> {
		return this.http.post<{}>(`${this.appConfig.tabitAPI}/oauth2/token`, {
			client_id: this.appConfig.tabitClientID,
			grant_type: 'refresh_token',
			refresh_token: localStorage.getItem('refreshToken'),
		})
			.pipe(
				timeout(30000),
				retry(3),
				tap(response => console.debug('Refresh Token Response:', response))
			);
	}

	public refreshPublicToken$(): Observable<any> {
		return this.http.post<{}>(`${this.appConfig.tabitAPI}/oauth2/token`, {
			client_id: this.appConfig.tabitClientID,
			grant_type: 'client_credentials',
		})
			.pipe(
				timeout(30000),
				retry(3),
				tap(response => console.debug('Refresh Public Token Response:', response))
			);
	}

	private generateVerificationCodeMessage(): String {
		// [ ! ] Note: the phrasing is very important! Not all phrasing will be detected by iOS and parsed for automatic injection through the keyboard.
		// For Android:
		// Due to the Google SMS Retriever logic - we must add the <#> at the beginning...
		// https://www.npmjs.com/package/cordova-plugin-sms-retriever

		return this.appService.cordovaPlatform == 'android' ? "\n\n\n" + this.appService.SMSRetrieverHash : '';
	}

	public authMobile$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		if (!this.domain?.brand) {
			return this.http.post<{}>(`${this.appConfig.tabitBridge}/loyalty/login/mobile`, {
				mobile: args.phone,
				customMessageSuffix: this.generateVerificationCodeMessage(),
			}, this.loyaltyService.getLoyaltyHeaders(origin))
				.pipe(
					timeout(30000),
				);
		} else { //White Label
			return this.http.post<{}>(`${this.appConfig.tabitBridge}/loyalty/login/mobile/whitelabel`, {
				mobile: args.phone,
				customMessageSuffix: this.generateVerificationCodeMessage(),
			}, this.loyaltyService.getLoyaltyHeaders(origin))
				.pipe(
					timeout(30000),
				);
		}
	}

	public resendPicode$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		const body = {
			mobile: args.phone,
			customMessageSuffix: this.generateVerificationCodeMessage(),
		}
		return this.http.post<{}>(`${this.appConfig.tabitBridge}/loyalty/login/customer/resenddotp`,
			body,
			this.loyaltyService.getLoyaltyHeaders(origin))
			.pipe(
				timeout(30000),
			);
	}

	public signInByPhoneVerifyCode$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		const body = {
			mobile: args.phone,
			pinCode: args.code
		}
		return this.http.post<{}>(`${this.appConfig.tabitBridge}/loyalty/login/mobile/pincode`,
			body,
			this.loyaltyService.getLoyaltyHeaders(origin))
			.pipe(
				timeout(30000),
			)
	}

	public insertCustomer$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		const requestBody: any = {
			Mobile: args.phone,
			FirstName: args.firstName,
			LastName: args.lastName,
			Email: args.email,
			customMessageSuffix: this.generateVerificationCodeMessage(),
		};

		// loyalty api expects non-ISO date format of YYYY-MM-DD
		if (args.birthDate && moment(args.birthDate).isValid()) requestBody.BirthDate = moment(args.birthDate).format('YYYY-MM-DD');
		if (args.anniversary && moment(args.anniversary).isValid()) requestBody.Anniversary = moment(args.anniversary).format('YYYY-MM-DD');

        if (this.appService.skin) {
            if (typeof args.IsFilledJoinForm !== 'undefined') requestBody.IsFilledJoinForm = args.IsFilledJoinForm ? 1 : 0;
            if (typeof args.IsConfirmSms !== 'undefined') requestBody.IsConfirmSms = args.IsConfirmSms ? 1 : 0;
        }

        const url = `${this.appConfig.tabitBridge}/loyalty/login/customer/createandsendotp`;
        return this.http.post<{}>(
            url,
            requestBody,
            this.loyaltyService.getLoyaltyHeaders(origin)
        ).pipe(
            timeout(30000),
        )
	}

	public updateCustomer$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		let requestBody: any = {};

		if (args.loyaltyTerms){
			requestBody = {
				IsConfirmSms: args.isConfirmSms,
				IsConfirmMail: args.isConfirmMail,
				IsFilledJoinForm: args.isFilledJoinForm,
			};
		} else {
			requestBody = {
				FirstName: args.firstName,
				LastName: args.lastName,
				Email: args.email,
				IsConfirmSms: args.isConfirmSms,
				IsConfirmMail: args.isConfirmMail,
				IsFilledJoinForm: args.isFilledJoinForm,
			};
			// loyalty api expects non-ISO date format of YYYY-MM-DD
			if (args.birthDate && moment(args.birthDate).isValid()) requestBody.BirthDate = moment(args.birthDate).format('YYYY-MM-DD');
			if (args.anniversary && moment(args.anniversary).isValid()) requestBody.Anniversary = moment(args.anniversary).format('YYYY-MM-DD');
		}

		return this.http.put<{}>(`${this.appConfig.tabitLoyaltyAPI}/customer`, requestBody, this.loyaltyService.getLoyaltyHeaders(origin))
		.pipe(
			timeout(30000),
            map((loyaltyResponse: any) => {
                // Get Customer Details from Loyalty
                this.getCustomer$().subscribe(
                    (response: any) => {
                        // I did it in a NOT the correct way.
                        // This is a terrible state-depend-on side effect.
                        // BUT since the better way is to move all of the appService.user state to decent observables, and we are not going to now etc...
                        if (!this.appService.user) {
                            this.appService.user = {};
                        }
                        this.appService.user.loyaltyCustomer = response.ResponseData;
                        if (this.appService.user?.loyaltyCustomer?.Email?.length) this.appService.emailValidationNeeded = false;
                        this.appService.updatePrivateStore('user', this.appService.user);
                    },
                    (err: any) => {
                        console.debug('auth-service > updadeCustomer > getCustomer > Error:', err);
                    }
                );
                return loyaltyResponse;
            })
		)
	}

	public getCustomer$(): Observable<any> {
		return this.http.get(`${this.appConfig.tabitLoyaltyAPI}/customer`, this.loyaltyService.getLoyaltyHeaders())
			.pipe(
				timeout(30000),
			)
	}

	public getROSToken$(args: any): Observable<any> {
        const origin = args.origin || undefined;

		return this.http.get(`${this.appConfig.tabitLoyaltyAPI}/auth/rostoken`, this.loyaltyService.getLoyaltyHeaders(origin))
			.pipe(
				timeout(30000),
			);
	}

	public signInByEmail$(args) {
		return this.http.post<{}>(`${this.appConfig.tabitLoyaltyAPI}/auth/email`, {
			Mobile: args.phone,
			UserName: args.email,
			Password: args.password,
		}, this.loyaltyService.getLoyaltyHeaders())
			.pipe(
				timeout(30000),
			);
	}

	public signUp(args) {
		return this.getCredentials()
        .then(() => {
			return this._signUP(args);
		})
	}

	private _signUP(args: any) {
		return new Promise((resolve, reject) => {
			this.http.post<{}>(`${this.appConfig.tabitAPI}/online-shopper/customers`, {
				client_id: this.appConfig.tabitClientID,
				grant_type: 'password',
				name: args.name,
				phone: args.phone,
				email: args.email,
				password: args.password,
			}, this.appService.appHttpOptions).subscribe(
				(response: any) => {
					resolve(response);
				},
				(err) => {
					reject(err)
				}
			);
		});
	}

	public resetPassword(args) {
		return this.getCredentials().then(res => {
			return this._resetPassword(args);
		})
	}
	private _resetPassword(email: string) {
		return new Promise((resolve, reject) => {
			this.http.delete<{}>(`${this.appConfig.tabitAPI}/online-shopper/customers/${email}/password`, extend(
				{},
				this.appService.appHttpOptions,
				{
					headers: extend({}, this.getHeadersAsObj(), {
						'ros-organization': this.appConfig.tabitDefaultOrganizationId
					}),
				}
			)).subscribe(
				(response: any) => {
					resolve(response);
				},
				(err) => {
					reject(err)
				}
			);
		});
	}

	public getCredentials(setOrgScope?) {
		let request = {
			client_id: this.appConfig.tabitClientID,
			grant_type: 'client_credentials',
			scope: 'online-account'
		}
		if (setOrgScope) {
			request.scope = `online-account organization/${this.appConfig.tabitDefaultOrganizationId}`;
		}
		return new Promise((resolve, reject) => {
			this.http.post<any>(`${this.appConfig.tabitAPI}/oauth2/token`, request, this.appService.appHttpOptions).subscribe(
				(response: any) => {
					if (response?.access_token) {
						this.setHttpHeadersAccessToken(response.access_token, response.refresh_token);
						resolve(true);
					} else {
						reject({});
					}
				},
				(err: any) => {
					this.appService.handleHttpError<{}>('clientCredentials');
					if (isObject(err)) err['status'] = -999;
					reject(err)
				}
			)
		});
	}

	public getAnonymousToken() {
		const request = {
			client_id: this.appConfig.tabitClientID,
			grant_type: 'client_credentials'
		}

		return this.http.post<any>(`${this.appConfig.tabitAPI}/oauth2/token`, request, this.appService.appHttpOptions).subscribe(
			(response: any) => {
				if (response?.access_token) {
					console.debug('authService - Anonymous Token Authorization Generated:', response.access_token);
					this.setHttpHeadersAccessToken(response.access_token);
				}
			},
			(err: any) => {
				this.appService.handleHttpError<{}>('clientCredentials');
				if (isObject(err)) err['status'] = -999;
			}
		)
	}

	public setReceivedTokenEvent(token) {
		this.tokenReceivedEvent.emit(token);
	}

	public isAnonymousTokenGenerated(): boolean {
		if (localStorage.getItem('token') && !localStorage.getItem('refreshToken')) return true;
		return false;
	}

	public addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
	}

    public deleteLoyaltyUser$(): Observable<any> {
		return this.http.delete<{}>(`${this.appConfig.tabitLoyaltyAPI}/auth/appDeleteCustomer`, this.loyaltyService.getLoyaltyHeaders())
			.pipe(
				timeout(30000),
				tap(response => {
                    console.debug('Delete user from loyalty Response:', response);
                })
			);
	}

    public deleteUserFromBridge$(): Observable<any> {
        return this.http.delete<{}>(`${this.appConfig.tabitBridge}/customers/current/meta`, this.appService.appHttpOptions)
            .pipe(
                timeout(30000),
                tap(response => {
                    console.debug('Delete meta data from Bridge:', response);
                })
            );
	}
}
