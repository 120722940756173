import { Component, OnInit, ElementRef } from '@angular/core';

import { AppService } from '../../app.service';

@Component({
  selector: 'app-my-rank',
  templateUrl: './my-rank.component.html',
    styleUrls: ['./my-rank.component.scss'],
    host: {
        'class': 'host-default',
    },
})
export class MyRankComponent implements OnInit {

	constructor(
		elementRef: ElementRef,
		public appService: AppService,
	) {

	}

  ngOnInit() {
  }

}
