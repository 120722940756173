import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { map as _map, groupBy, get } from 'lodash-es';

import { environment } from '../../environments/environment';
import { AppService, Domain } from '../app.service';

export interface Tag {
    id: string;
    _id: string;
    type: string;
    brand: string;
    lang: {
        [key: string]: {
            label: string
        }
    }
    organizations_count: number
    image: string
    showOnHomeView: boolean
    showOnTagsView: boolean
    isFavorite: boolean,
    index?: number
}

interface StringsMap {
    [key: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    private TAGS_URL = `${environment.appConfig.tabitBridge}/tags`;
    
    private _tagsData$ = new BehaviorSubject<Tag[]>(null);
    private _tags$ = this.http.get<Tag[]>(this.TAGS_URL, this.appService.appHttpOptions).pipe(
        map((tags) => this.prepareTags(tags)), 
        tap((tags) => this.setTagsAndSubGroupTags(tags)), 
        tap((tags) => this._tagsData$.next(tags)));
    private cuisineTagsMap: StringsMap = {};
    
    public subGroupTags: any = [];

    constructor (
        private appService: AppService,
        private http: HttpClient,
    ) { }

    public get tagsData$() {
        return this._tagsData$.asObservable();
    }

    public updateTags(tags: Tag[]) {
        this._tagsData$.next(tags);
    }

    public getTags() {
        if (this._tagsData$.value === null) {
            return this._tags$;
        }
        return this.tagsData$
    }

    public getTagName(tagId: string) {
        return this.cuisineTagsMap[tagId];
    }

    public getSubGroupTagId(tagName: string) {
        const relevantTag = this.subGroupTags.find(tag => tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label == tagName);
        return relevantTag.id || '';
    }

    // The user account data could be available only AFTER the above,
    // so need to expose that to update the tags with favorites again.
    public prepareFoodPersonalPrefs() {

        let tags = this._tagsData$.getValue();

        if (!tags?.length) return;

        let favs = get(this, 'appService.account.preferences.foods', []);

        if (!favs?.length) return;

        this._tagsData$.next(tags.map(tag => ({
            ...tag,
            isFavorite: favs.indexOf(tag._id) >= 0,
        })));

    }

    public makeTagLabelsMap(tags: Tag[]): StringsMap {
        let tagLabelsMap = {};
        tags.forEach(tag => {
            if (['occasions', 'sub-group'].includes(tag.type)) return;
            tagLabelsMap[tag._id] = tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label;
        });
        return tagLabelsMap;
    }

    public makeGroupedTags(tags: Tag[]): { name: string, tagsArray: Tag[] }[] {
        return _map(groupBy(tags?.filter(tag => !['occasions', 'menus', 'sub-group'].includes(tag.type)), 'type'), (tagsArray, type) => {
            return { name: type, tagsArray };
        });
    }

    public addOrgsToSubGroupTags(orgs) {
        if (!this.subGroupTags?.length) return;

        this.subGroupTags.forEach(subGroupTag => {
            const orgsBySubGroup = orgs.filter(org => {
                return org.tags.find(tag => tag == subGroupTag._id);
            });
            subGroupTag.orgs = orgsBySubGroup;
        });
    }

    public getTagLabel(tagId) {
        let tagLabel = '';

        if (!tagId) return tagLabel;
        this._tagsData$.getValue().map(tag => {
            if (tag._id == tagId) tagLabel = tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label;
        });

        return tagLabel;

    }

    private prepareTags(tags: Tag[]) {
        let favs = get(this, 'appService.account.preferences.foods', []);

        return tags.map(tag => ({

            ...tag,
            image: tag.image || this.appService.images.default_cat_image,
            isFavorite: favs.indexOf(tag._id) >= 0,

        }));
    }

    private setTagsAndSubGroupTags (tags: Tag[]): void {
        const domain = this.appService.getDomain();
        tags?.forEach(tag => {
            if (tag.type === 'cuisine') this.cuisineTagsMap[tag._id] = tag.lang[this.appService.appConfig.locale.toLocaleLowerCase()].label;
            if (domain?.brand && tags?.length) this.subGroupTags = tags?.filter(tag => tag.brand === domain.brand);
        });
    }
}
