import { AppService } from './../app.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';

import { SearchDetails, ORGANIZATION_BUCKET_TYPES } from './OrganizationBucket';
import { OrganizationsService } from './organizations.service';

declare const $: any; // For the scroll we are using jQuery

export function SiteListsBase(searchType: ORGANIZATION_BUCKET_TYPES) {
    return class SiteListsBaseClass {

        public orgs: any[] = [];

        protected searchHook: (searchDetails: SearchDetails) => void;
        protected orgsArrivedHook: (orgs: any[]) => void;

        public initialSearch: SearchDetails;

        public searchSkip: number = 0;
        public nextSkip: number = 0;
        public searching: boolean = true;
        public searchingMore: boolean = false;
        public preventSearchMore: boolean = false;

        private organizationsServiceSubscription: Subscription;
        private nextSkipSubscription: Subscription;
        private organizationsSubscription: Subscription;
        private preventMoreSubscription: Subscription;

        protected organizationsService: OrganizationsService;
        protected liveAnnouncer: LiveAnnouncer;
        protected appService: AppService;
        protected pickRelevantSearchDetails(searchDetails: SearchDetails) {

            let pickedSearchDetails: SearchDetails = {};
            if (!searchDetails) return pickedSearchDetails;
            if (searchDetails.query) pickedSearchDetails.query = searchDetails.query;
            if (searchDetails.tags && searchDetails.tags.length) pickedSearchDetails.tags = searchDetails.tags;
            if (searchDetails.rating) pickedSearchDetails.rating = searchDetails.rating;
            if (searchDetails.price) pickedSearchDetails.price = searchDetails.price;
            if (searchDetails.externalDeliveryLink === true) pickedSearchDetails.externalDeliveryLink = searchDetails.externalDeliveryLink;
            if (searchDetails.onlyTabit === true) pickedSearchDetails.onlyTabit = searchDetails.onlyTabit;
            if (searchDetails.onlyAvailable === true) pickedSearchDetails.onlyAvailable = searchDetails.onlyAvailable;

            return pickedSearchDetails;

        }

        protected initialize () {

            let lastSearchDetails = this.organizationsService.getSearchDetails(searchType);
            this.searchSkip = lastSearchDetails && lastSearchDetails.skip;
            this.initialSearch = this.pickRelevantSearchDetails(lastSearchDetails);

            this.organizationsSubscription = this.organizationsService.data[searchType].subscribe(organizations => {

                this.orgs = organizations;
                if (this.orgsArrivedHook) this.orgsArrivedHook(organizations);

            }, err => {

                console.error('Error on receiving new orgs for list:', err);

            });

            this.nextSkipSubscription = this.organizationsService.nextSkip(searchType).subscribe(nextSkip => {
                this.nextSkip = nextSkip;
            });

            this.preventMoreSubscription = this.organizationsService.preventSearchMore(searchType).subscribe(prevent => {
                this.preventSearchMore = prevent;
            });

        }

        protected cleanUp () {
            if (this.organizationsServiceSubscription) this.organizationsServiceSubscription.unsubscribe();
            if (this.organizationsSubscription) this.organizationsSubscription.unsubscribe();
            if (this.nextSkipSubscription) this.nextSkipSubscription.unsubscribe();
            if (this.preventMoreSubscription) this.preventMoreSubscription.unsubscribe();
        }

        protected prepareNextSearchDetails(searchDetails?: SearchDetails, service?: SearchDetails['service']) {

            let nextSearchDetails: SearchDetails = {};

            let lastSearchDetails = this.organizationsService.getSearchDetails(searchType) || {};

            Object.assign(nextSearchDetails, searchDetails || this.pickRelevantSearchDetails(lastSearchDetails));

            let searchService = (
                searchDetails ? (searchDetails.service || lastSearchDetails.service || service) : service
            );

            if (searchService) nextSearchDetails.service = searchService;

            return nextSearchDetails;

        }

        protected manageScroll() {
            if (this.organizationsService.searchScreenNeedsScroll && this.organizationsService.searchScreenScrollPosition) {
                setTimeout(() => {
                    $('#sites-module-content').scrollTop(this.organizationsService.searchScreenScrollPosition);
                    setTimeout(() => {
                        this.organizationsService.searchScreenNeedsScroll = false;
                    }, 300);
                }, 0);
            }
        }

        public loadMore(cb?: (orgsFromSearch: any[]) => void) {
            // console.log('=== SiteListsBase - loadMore', this.searching, this.searchingMore, this.preventSearchMore);

            if (this.searching || this.searchingMore || this.preventSearchMore) return;

            this.searchingMore = true;
            this.searchSkip = this.nextSkip;

            this.search().subscribe(orgsFromSearch => {
                this.searchingMore = false;
                if (cb) cb(orgsFromSearch);
            }, err => {
                console.error('Error searching more organizations:', err);
            });
        }

        protected search(searchDetails?: SearchDetails) {

            if (searchDetails) searchDetails = { ...searchDetails };
            else searchDetails = { ...(this.organizationsService.getSearchDetails(searchType) || {}) };
            if (this.searchSkip) searchDetails.skip = this.searchSkip;
            if (this.searchHook) this.searchHook(searchDetails);
            
            return this.organizationsService.search(searchDetails, searchType);
            
        }
        
        protected newSearch(searchDetails: SearchDetails, cb?: (orgsFromSearch: any[]) => void) {
            this.searching = true;
            this.preventSearchMore = false;
            this.searchSkip = 0;
            
            this.search(searchDetails).subscribe(orgsFromSearch => {
                this.searching = false;
                if (cb) cb(orgsFromSearch);
                this.liveAnnouncer.announce(this.appService.translate('number_of_restaurants_found_screen_reader') + this.orgs.length)

            }, err => {
                console.error('Error searching organizations:', err);
                // 2020-03-29 - If we get back an error (e.g. when offline) - the user will see a Modal with a proper "network connection alert" (triggered by the refresh-token-interceptor.ts)
                // And we want the spinner to keep spinninig in the background.
                // Otherwise (if we set the this.searching to FALSE here) - the user will see the previous list.
                // This is problematic, since (for example) when you switch between the Takeaway tab and the Delivery tab - you'll end up with the Takeaaway results, but the selected tab will be the Delivery tab.
                //this.searching = false;
            });
        }

        public clearSearchText(service?: SearchDetails['service']) {

            let searchDetails: SearchDetails = { ...(this.organizationsService.getSearchDetails(searchType) || {}), skip: 0 };

            if (service) searchDetails.service = service;

            if (searchDetails.query) delete searchDetails.query;

            this.newSearch(searchDetails);

        }

        protected getSearchType(): ORGANIZATION_BUCKET_TYPES {
            return searchType;
        }

    }

}
