<div class="future-reservations">
    <div class="future-reservation" [ngClass]="{'single-reservation': reservations?.length == 1, 'first-reservation': reservations?.length > 1 && i == 0, 'standby': reservation.standby_reservation, 'pending': reservation.pending_approval }" *ngFor="let reservation of reservations; index as i" (click)="bookService.openRSV(reservation)">
        <ng-container *ngTemplateOutlet="parent == 'dashboard' || true ? globalReservationTemplate : siteSpecificReservationTemplate; context: {reservation: reservation}"></ng-container>
    </div>
</div>

<!-- Reservation Summary Template -->
<ng-template #siteSpecificReservationTemplate let-reservation="reservation">
    <div @reservationCardAnimation class="site-specific-reservation-container">
        <!-- Date -->
        <div class="reservation-details date">
            <div class="label">
                {{reservation.reservation_details.reserved_from | date:'EEEE': '': appService.localeId}}</div>
            <div class="value">{{reservation.reservation_details.reserved_from | date:'M / d': '': appService.localeId}}</div>
        </div>
        <!-- Time -->
        <div class="reservation-details time">
            <div class="label">{{'TGM.TIME' | translate}}</div>
            <div class="value">{{reservation.reservation_details.reserved_from | date: 'HH:mm' | AMPMConverter }}</div>
        </div>
        <!-- Guests Number -->
        <div class="reservation-details guests-number">
            <div class="label">{{'TGM.DINERS' | translate}}</div>
            <div class="value">{{reservation.reservation_details.seats_count}}</div>
        </div>
        <!-- Preference -->
        <div class="reservation-details preference" *ngIf="reservation.reservation_details.preference">
            <div class="label">{{'area' | translate}}</div>
            <div class="value">{{'booking.search.' + reservation.reservation_details.preference | translate}}</div>
        </div>
    </div>
</ng-template>

<!-- Site Item Template -->
<ng-template #globalReservationTemplate let-reservation="reservation">
    <div class="global-reservation-container wl-cards-background-color" *ngIf="organizationsService.getOrganization(reservation.organization) || reservation.fullOrganization">
        <div class="site-image" [style.background-image]="(organizationsService.getOrganization(reservation.organization) ? (organizationsService.getOrganization(reservation.organization).largeImage || appService.images.default_site_image) : (reservation.fullOrganization.largeImage || appService.images.default_site_image)) | safeStyle"></div>
        <div class="site-reservation">
            <div class="main-data-container">
                <div class="title-container">
                    <div class="title" [class.availability-alert]="checkAlertForTitle(reservation)">{{ setTitle(reservation) }}</div>
                    <div class="_address">{{organizationsService.getOrganization(reservation.organization) ? organizationsService.getOrganization(reservation.organization).address : reservation.fullOrganization.address}}</div>
                </div>
                <div class="icon-container" *ngIf="parent == 'dashboard' && (reservation.isMissingDeposit || reservation.isMissingDepositPayment)">
                    <mat-icon [svgIcon]="'get-coin'"></mat-icon>
                </div>
            </div>
            <div class="availability-alert" *ngIf="parent == 'dashboard' && isPendingApproval(reservation)">{{'TGM.pending_approval' | translate}}</div>
            <div class="availability-alert" *ngIf="parent == 'dashboard' && isStandbyList(reservation)">{{'TGM.standby_list' | translate}}</div>
            <div class="availability-alert" *ngIf="parent == 'dashboard' && reservation.isMissingDeposit">{{'TGM.missing_deposit' | translate}}</div>
            <div class="availability-alert" *ngIf="parent == 'dashboard' && reservation.isMissingDepositPayment">{{'TGM.missing_deposit_payment' | translate}}</div>
            <div class="divider"></div>
            <div class="reservation-details">
                <span>{{reservation.reservation_details.reserved_from | date: 'EEEE d/M': '': appService.localeId}}</span>
                <span class="separator">|</span>
                <span>{{reservation.reservation_details.reserved_from | date: 'HH:mm' | AMPMConverter }}</span>
                <span class="separator">|</span>
                <span>{{reservation.reservation_details.seats_count}}p</span>
            </div>
        </div>
    </div>
</ng-template>